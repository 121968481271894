import React from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { Captcha } from 'primereact/captcha';

const CaptchaVerification = ({ setDisabledCaptchaProp }) => {

    const onResponseCaptcha = () => {
        // console.log("Hubo un cambio");
        setDisabledCaptchaProp(false)
    }
    const onExpiredCaptcha = () => {
        // console.log("Expiró");
        setDisabledCaptchaProp(true)

    }

    return (

        <div>
            <Captcha siteKey="6LfetAQiAAAAAAILu_shRcFIFT0xAio3BjqSaKO0" language="es" onResponse={onResponseCaptcha} onExpire={onExpiredCaptcha}/>
        </div>


    );
}
export default CaptchaVerification