import React, { useState, useEffect } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom'
import Services from '../../service/Services';
import ConfirmBtn from './ConfirmBtn'

const AbrirMesCerrado = () => {

    const [servicioUsuarios, setServicioUsuarios] = useState([]);
    const [listaCierres, setListaCierres] = useState([]);

    const getServices = new Services();

    useEffect(() => {
        getServices.getUsuarios().then(data => setServicioUsuarios(data));
        getServices.getCierres().then(data => setListaCierres(data))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /* console.log(listaCierres) */
    var mensajeMesAbierto = false
    var arrayfechas = []

    listaCierres.forEach(element => {
        if (element.estado === 'C') {
            const date = new Date(element.fechaFinalCierre);
            const month = date.toLocaleString('es-CO', { month: 'long' });
            const year = date.getFullYear()
            arrayfechas.push({
                id: element.id,
                fechaFinalCierre: element.fechaFinalCierre,
                fechaInicialCierre: element.fechaInicialCierre,
                mesAño: month + '-' + year
            })
        } else if (element.estado === 'A') {
            mensajeMesAbierto = true
        } else {
            mensajeMesAbierto = false
        }
    });

    const [selectedMesAño, setSelectedMesAño] = useState({
        id: '',
        fechaFinalCierre: '',
        fechaInicialCierre: '',
        mesAño: ''
    });

    const mesesAños = arrayfechas.map(item => {
        const av = { id: item.id, fechaFinalCierre: item.fechaFinalCierre, fechaInicialCierre: item.fechaInicialCierre, mesAño: item.mesAño }
        return av
    })

    const onMesesAñosChange = (e) => {
        setSelectedMesAño(e.value);
    }

    const [selectedUsuarios, setSelectedUsuarios] = useState(null);

    const usuarios = servicioUsuarios.map(item => {
        const av = { name: item.usuario, id: item.idUsuario }
        return av
    })

    
	const [itemsSelectedUsers, setItemsSelectedUsers] = useState(0);
    const onUsuariosChange = (e) => {
        setItemsSelectedUsers(e.value.length)
        setSelectedUsuarios(e.value);
    }

    const [selectUser, setSelectUser] = useState(null);
    var message

    const Alert = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                </div>
            );
        }

        return (
            <div>
                <Button label="Guardar" onClick={() => onClick('displayConfirmation')} className="p-button-outlined p-button-success"
                    tooltip="Guardar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                />
                <div className="dialog-demo">
                    <div className="card">
                        <Dialog className="modalConfirm" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>{message}</span>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        )
    }

    const mesAño = selectedMesAño.mesAño
    const idCierre = selectedMesAño.id

    var showUsers
    var idUsuarios = []

    if (selectUser === 'selectUser') {
        showUsers = true
        if (selectedUsuarios !== null) {
            if (selectedUsuarios.length > 0) {
                selectedUsuarios.forEach(element => {
                    idUsuarios.push(element.id)
                })
            }
        }
    } else if (selectUser === 'selectAllUser') {
        showUsers = false
        servicioUsuarios.forEach(element => {
            idUsuarios.push(element.idUsuario)
        })
    }

    var showAlert

    if (selectUser === null) {
        showAlert = true
        message = 'Sí abrirá el mes cerrado, debe seleccionar un funcionario o todos los funcionarios'
    } else {
        if (selectUser === 'selectUser') {
            if (selectedUsuarios !== null) {
                if (selectedUsuarios.length <= 0) {
                    showAlert = true
                    message = 'Debe seleccionar al menos un funcionario'
                } else {
                    showAlert = false
                }
            } else {
                showAlert = true
                message = 'Debe seleccionar al menos un funcionario'
            }
        } else {
            showAlert = false
        }

    }

    const limpiarCampos = () => {
        setSelectedUsuarios(null)
        setSelectUser(null)
        setSelectedMesAño({
            id: '',
            fechaFinalCierre: '',
            fechaInicialCierre: '',
            mesAño: ''
        })
    }

    const BotonRegresar = () => {
        return (
            <div className="p-field">
                {btnRegresar
                    ?
                    <Button label="Regresar" className="p-button-outlined p-button-secondary regresar"
                        tooltip="Regresar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={() => limpiarCampos()} style={{ marginLeft: '5px' }}
                    />
                    :
                    <Link to="/registroActividades/inicio" className="regresar" style={{ marginLeft: '5px' }}>
                        <Button label="Regresar" className="p-button-outlined p-button-secondary regresar"
                            tooltip="Regresar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                        />
                    </Link>
                }
            </div>
        )
    }

    var btnRegresar = false

    if (mesAño !== '' || selectedUsuarios !== null || selectUser !== null) {
        btnRegresar = true
    } else {
        btnRegresar = false
    }

    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <form style={{ display: "contents" }}>
                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels" >Selecionar mes<span className="require">*</span></label>
                        <Dropdown value={selectedMesAño} options={mesesAños} onChange={onMesesAñosChange} name="mesAño" optionLabel="mesAño"
                            placeholder="Seleccione un mes para abrir" emptyMessage="No se encuentran resultados" style={{ marginBottom: '15px' }}
                        />
                        <div className="p-field-radiobutton">
                            <RadioButton inputId="selectAllUser" name="selectAllUser" value="selectAllUser" onChange={(e) => setSelectUser(e.value)} checked={selectUser === 'selectAllUser'} />
                            <label htmlFor="selectAllUser">Todos los Funcionarios</label>
                        </div>
                        <div className="p-field-radiobutton">
                            <RadioButton inputId="selectUser" name="selectUser" value="selectUser" onChange={(e) => setSelectUser(e.value)} checked={selectUser === 'selectUser'} />
                            <label htmlFor="selectUser">Seleccionar Funcionario</label>
                        </div>
                        {
                            showUsers ?
                                <>
                                    <label className="labels" htmlFor="users">Usuarios <span className="require">*</span></label>
                                    <MultiSelect value={selectedUsuarios} options={usuarios} inputId="users" onChange={onUsuariosChange} optionLabel="name" name="usuarios"
                                        placeholder="Seleccione Usuarios"  maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedUsers} Elementos Seleccionados`} filter
                                    />
                                </>
                                :
                                ""
                        }
                    </div>
                </form>
            </div>
            <div className="p-formgroup-inline">

                {
                    showAlert ?
                        <Alert />
                        :
                        <ConfirmBtn
                            mensaje={mensajeMesAbierto ? "Se van a cerrar todos los meses abiertos anteriormente" : "¿Está seguro de reabrir el mes seleccionado?"}
                            accion="abrirMes"
                            idUsuariosProp={idUsuarios}
                            idCierreProp={idCierre}
                        />
                }
                <BotonRegresar />
            </div>
        </div>
    )
}

export default AbrirMesCerrado