import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { EstandarFormatoFecha, SumarDias } from '../Funciones';
import BtnUpdateDatailOther from './BtnUpdateDetailOther';
import ConfirmBtn from './ConfirmBtn';
import BtnAddService from './BtnAddService';


const OtrosiServicio = ({ idOtherProp, otherProp, idContractProp, dateStartValidityProp, dateEndValidityProp, dateStartOtherShowProp, dateEndOtherShowProp,
    creationDateProp, createdByProp, servicesProp, setRunRenderProp, runRenderProp, permissionsContractCreateProp, permissionsContractUpdateProp, permissionsContractDeleteProp }) => {

    const [otherService, setOtherService] = useState([]);

    useEffect(() => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;

        var newData = servicesProp.map(item => {
            var newDateStart
            var newDateEnd
            if (gmtHours < 0) {
                newDateStart = SumarDias(new Date(item.fechainiciovigencia), 1)
                if (item.fechafinvigencia === null) {
                    newDateEnd = null
                } else {
                    newDateEnd = SumarDias(new Date(item.fechafinvigencia), 1)
                }
            } else {
                newDateStart = new Date(item.fechainiciovigencia)
                if (item.fechafinvigencia === null) {
                    newDateEnd = null
                } else {
                    newDateEnd = new Date(item.fechafinvigencia)
                }
            }
            return {
                estado: item.estado,
                fechafinvigencia: EstandarFormatoFecha(newDateEnd),
                fechainiciovigencia: EstandarFormatoFecha(newDateStart),
                sendDateStart: item.fechainiciovigencia,
                sendDateEnd: item.fechafinvigencia,
                id: item.id,
                servicio: item.servicio
            }
        })

        setOtherService(newData)

    }, [servicesProp]) // eslint-disable-line react-hooks/exhaustive-deps

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const acctions = (rowData) => {
        /* console.log(rowData) */
        return (
            <>
                {permissionsContractUpdateProp ?
                    <BtnUpdateDatailOther
                        idOtherProp={idOtherProp}
                        otherProp={otherProp}
                        idContractProp={idContractProp}
                        dateStartProp={dateStartValidityProp}
                        dateEndProp={dateEndValidityProp}
                        idOtherServiceProp={rowData.id}
                        idServiceProp={rowData.servicio.id}
                        serviceProp={rowData.servicio.descripcion}
                        dateStartValidityProp={rowData.sendDateStart}
                        dateEndValidityProp={rowData.sendDateEnd}
                        dateStartOtherShowProp={dateStartOtherShowProp}
                        dateEndOtherShowProp={dateEndOtherShowProp}
                        creationDateProp={creationDateProp}
                        createdByProp={createdByProp}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                    />
                    :
                    ""
                }
                {permissionsContractDeleteProp ?
                    <ConfirmBtn
                        tittle="Confirmar Eliminación"
                        menssage="¿Está seguro de eliminar este servicio?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        action="UpdateOther"
                        label=""
                        classProp="p-button-danger"
                        icon="pi pi-trash"
                        tooltipProp="Eliminar"
                        idOtherProp={idOtherProp}
                        otherProp={otherProp}
                        idContractProp={idContractProp}
                        dateStartProp={dateStartValidityProp}
                        dateEndProp={dateEndValidityProp}
                        statusProp={1}
                        creationDateProp={creationDateProp}
                        createdByProp={createdByProp}
                        OtherServiceStatusProp={3}
                        dateStartServiceProp={rowData.sendDateStart}
                        dateEndServiceProp={rowData.sendDateEnd}
                        idOtherServiceProp={rowData.id}
                        idServiceProp={rowData.servicio.id}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                        updateDetailProp={true}
                        deleteDetailProp={true}
                    />
                    :
                    ""
                }

            </>
        )
    }
    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };


    const header = (
        <div className="headerTable">
            {permissionsContractCreateProp ?
                <BtnAddService
                    servicesProp={servicesProp}
                    idOtherProp={idOtherProp}
                    otherProp={otherProp}
                    idContractProp={idContractProp}
                    dateStartProp={dateStartValidityProp}
                    dateEndProp={dateEndValidityProp}
                    dateStartOtherShowProp={dateStartOtherShowProp}
                    dateEndOtherShowProp={dateEndOtherShowProp}
                    creationDateProp={creationDateProp}
                    createdByProp={createdByProp}
                    addServiceContract={false}
                    setRunRenderProp={setRunRenderProp}
                    runRenderProp={runRenderProp}
                />
                :
                ""
            }
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                    onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                />
            </span>
        </div>
    );

    const dt = useRef(null);

    return (
        <>
            <Button icon="pi pi-eye" className=" p-button-secondary" style={{ marginTop: '5px', marginLeft: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Servicios asociados" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Otrosi y servicios asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={otherService} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay servicios registrados para este otrosi" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="servicio.descripcion" header="Servicio" />
                            <Column className="columna" field="fechainiciovigencia" header="Fecha inicio vigencia" />
                            <Column className="columna" field="fechafinvigencia" header="Fecha fin vigencia" />
                            <Column header="Acciones" body={acctions} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default OtrosiServicio