import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import BtnActualizarVigencias from './BtnActualizarVigencias';
import { SumarDias } from '../Funciones';
import { EstandarFormatoFecha } from '../Funciones';

const VerClientesProyectos = ({ projectsProp, usuarioProp, redirectProp, valueUniqueProp, setRedirectProp,
    runRenderProp, setRunRenderProp, apellidoProp, idCargoProp, creadoPorProp, empresaProp, fechaCreacionProp,
    nombreProp, numeroDocumentoProp, idPersonInterestedProp, idTipoDocumentoProp, newUsuarioProp,
    getDataProjectsProp,runRenderBlockProp, setRunRenderBlockProp,individualMonthReportProp, periodDateStartProp, periodDateEndProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const [newProjects, setNewProjects] = useState([])
    useEffect(() => {
        var newProyectosProp = projectsProp.map(item => {
            var status

            if (item.estado === 1) {
                status = "Vigente"
            }
            else if (item.estado === 0) {
                status = "No vigente"
            }

            var d = new Date()
            var gtmHours = -d.getTimezoneOffset() / 60
            var formatDateStart, formatDateEnd

            if (item.fechainiciovigencia) {
                if (gtmHours < 0) {
                    formatDateStart = SumarDias(new Date(item.fechainiciovigencia), +1)
                }
                else {
                    formatDateStart = new Date(item.fechainiciovigencia)
                }
            } else {
                formatDateStart = null
            }

            if (item.fechafinvigencia) {
                if (gtmHours < 0) {
                    formatDateEnd = SumarDias(new Date(item.fechafinvigencia), +1)
                }
                else {
                    formatDateEnd = new Date(item.fechafinvigencia)
                }
            } else {
                formatDateEnd = null
            }


            return {
                fechacreacion: item.fechacreacion,
                fechainiciovigencia: formatDateStart && EstandarFormatoFecha(formatDateStart),
                fechafinvigencia: item.fechafinvigencia === null ? item.fechafinvigencia : EstandarFormatoFecha(formatDateEnd),
                sendDateStart: item.fechainiciovigencia,
                sendDateEnd: item.fechafinvigencia,
                id: item.id,
                proyecto: item.proyecto,
                estado: item.estado,
                newEstado: status
            }
        })

        setNewProjects(newProyectosProp)
    }, [projectsProp])

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setRedirectProp(false)
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    const Acciones = (rowData) => {
        return (
            <div>
                <BtnActualizarVigencias
                    usuarioProp={usuarioProp}
                    idProp={rowData.id}
                    idProyectoProp={rowData.proyecto.id}
                    dateStartProp={rowData.sendDateStart}
                    dateStartProjectProp={rowData.proyecto.fechainiciovigencia}
                    dateEndProp={rowData.sendDateEnd}
                    dateEndProjectProp={rowData.proyecto.fechafinvigencia}
                    runRenderProp={runRenderProp}
                    setRunRenderProp={setRunRenderProp}
                    apellidoProp={apellidoProp}
                    idCargoProp={idCargoProp}
                    creadoPorProp={creadoPorProp}
                    empresaProp={empresaProp}
                    fechaCreacionProp={fechaCreacionProp}
                    nombreProp={nombreProp}
                    numeroDocumentoProp={numeroDocumentoProp}
                    idPersonInterestedProp={idPersonInterestedProp}
                    idTipoDocumentoProp={idTipoDocumentoProp}
                    newUsuarioProp={newUsuarioProp}
                    getDataProjectsProp={getDataProjectsProp}
                    iconProp="pi pi-link"
                    bgRedProp="p-button-secondary"
                    isDeleteProp={false}
                    headerProp="Actualizar fecha de inicio y fin"
                    classNameProp="modal"
                    toolTipProp="Actualizar fecha de asociación"
                    runRenderBlockProp={runRenderBlockProp}
                    setRunRenderBlockProp={setRunRenderBlockProp}
                    individualMonthReportProp={individualMonthReportProp}
                />

                <BtnActualizarVigencias
                    usuarioProp={usuarioProp}
                    idProp={rowData.id}
                    idProyectoProp={rowData.proyecto.id}
                    dateStartProp={rowData.sendDateStart}
                    dateStartProjectProp={rowData.proyecto.fechainiciovigencia}
                    dateEndProp={rowData.sendDateEnd}
                    dateEndProjectProp={rowData.proyecto.fechafinvigencia}
                    runRenderProp={runRenderProp}
                    setRunRenderProp={setRunRenderProp}
                    apellidoProp={apellidoProp}
                    idCargoProp={idCargoProp}
                    creadoPorProp={creadoPorProp}
                    empresaProp={empresaProp}
                    fechaCreacionProp={fechaCreacionProp}
                    nombreProp={nombreProp}
                    numeroDocumentoProp={numeroDocumentoProp}
                    idPersonInterestedProp={idPersonInterestedProp}
                    idTipoDocumentoProp={idTipoDocumentoProp}
                    newUsuarioProp={newUsuarioProp}
                    getDataProjectsProp={getDataProjectsProp}
                    iconProp="pi pi-trash"
                    bgRedProp="p-button-danger"
                    isDeleteProp={true}
                    headerProp="Eliminar proyecto asociado a la persona interesada"
                    classNameProp="modalConfirm"
                    toolTipProp="Eliminar"
                    runRenderBlockProp={runRenderBlockProp}
                    setRunRenderBlockProp={setRunRenderBlockProp}
                    individualMonthReportProp={individualMonthReportProp}
                />

            </div>
        )
    }

    useEffect(() => {
        if (redirectProp === true) {
            if (usuarioProp === valueUniqueProp) {
                onClick('displayBasic')
            }
        }
    }, [redirectProp, usuarioProp, valueUniqueProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Clientes proyectos asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={newProjects} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para esta persona interesada" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="proyecto.cliente.razonsocial" header="Cliente" />
                            <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                            <Column className="columna" sortable field="fechainiciovigencia" header="Fecha Inicio de Vigencia" />
                            <Column className="columna" sortable field="fechafinvigencia" header="Fecha Fin de Vigencia" />
                            <Column className="columna" sortable field="newEstado" header="Estado" />
                            <Column className='columna' sortable header="Acciones" body={Acciones} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerClientesProyectos