import React from 'react'
import DataTable from '../componets/Cargos/DataTable'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import '../assets/css/Sidebar.css'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ConsultarCargos = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }
   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol


    if (permisos[7].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Consultar Cargos</span>
    )
    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">

                    <h1 >Consultar Cargos</h1>
                </div>
                <div className="tabla">
                    <DataTable
                        permisosProp={permisos}
                    />
                </div>
            </div>
        </div>

    )

}

export default ConsultarCargos