import React/* , { useState, useEffect } */ from 'react'
import { View, Text } from '@react-pdf/renderer'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

const InterestedPersonTable = ({ interestedPersonsProp, idProjectProp, stylesProp, listInterestedPersonProp }) => {
    var newInterestedPersonsProp = interestedPersonsProp.filter(function (object) {
        var idProject = object.proyecto
        return parseInt(idProjectProp) === parseInt(idProject)
    })

    var newArray = []
    newInterestedPersonsProp.forEach(element => {
        var result = listInterestedPersonProp.filter(obj => obj.id === element.id)
        result.forEach(x => {
            newArray.push(x)
        })
    });
    
    var showArray = newArray.map(item => {
        return{
            ...item,
            nombrecompleto: item.nombre + ' ' + item.apellido
        }
    })
    
    const tableInterestedPerson = {}
    const uniqueInterestedPerson = showArray.filter(function (object) {
        return tableInterestedPerson.hasOwnProperty(object.id) ? false : (tableInterestedPerson[object.id] = true)
    })
    return (
        <View>
            <Text style={stylesProp.subtitle2}>
                6. Personas interesadas.
            </Text>
            <Table
                data={uniqueInterestedPerson}
            >
                <TableHeader textAlign={"center"}>
                    <TableCell style={stylesProp.textTable}>
                        Nombre
                    </TableCell>
                    <TableCell style={stylesProp.textTable}>
                        Cargo
                    </TableCell>
                    <TableCell style={stylesProp.textTable}>
                        Empresa
                    </TableCell>
                </TableHeader>
                <TableBody textAlign={"center"}>
                    <DataTableCell getContent={(r) => r.nombrecompleto} style={stylesProp.textCell} />
                    <DataTableCell getContent={(r) => r.cargo.cargo} style={stylesProp.textCell} />
                    <DataTableCell getContent={(r) => r.empresa} style={stylesProp.textCell} />
                </TableBody>
            </Table>
        </View>
    )
}

export default InterestedPersonTable