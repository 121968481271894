import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from '../pages/Login'
import Home from '../pages/Home'
import NotFound from '../pages/NotFound'
import ConsultarUsuarios from '../pages/ConsultarUsuario'
import ConsultarClientes from '../pages/Clientes'
import ConsultarProyectos from '../pages/Proyectos'
import ConsultarAplicaciones from '../pages/Aplicaciones'
import ConsultarAreasNegocio from '../pages/AreasNegocio'
import ConsultarTiposActividad from '../pages/TiposActividad'
import ConsultarCargos from '../pages/Cargos'
import ConsultarActividades from '../pages/ConsultarActividades'
import ConsultarRoles from '../pages/Roles'
import ExportData from '../pages/ExportData'
import ConsultarCalendario from '../pages/ConsultarCalendario'
import ParametrizarCalendario from '../pages/ParametrizarCalendario'
import AbrirMes from '../pages/AbrirMes'
import CerrarMes from '../pages/CerrarMes'
import Historico from '../pages/Historico'
import CargaTrabajoProyecto from '../pages/DashBoards/CargaTrabajoProyecto'
import DetalleTipoActividad from '../pages/DashBoards/DetalleTipoActividad'
import TipoActividadFuncionario from '../pages/DashBoards/TipoActividadFuncionario'
import RecuperarContraseña from '../pages/RecuperarContraseña'
import RestablecerContraseña from '../pages/RestablecerContraseña'
import InformeMensualIndividual from '../pages/InformeMensualIndividual'
import PersonasInteresadas from '../pages/PersonasInteresadas'
import Objetivos from '../pages/Objetivos'
import Contratos from '../pages/Contratos'
import Entregables from '../pages/Entregables'
import equipoTrabajo from '../pages/EquipoTrabajo'
import Servicios from '../pages/Servicios'
import TiposProyectos from '../pages/TipoProyecto'
import OrganigramaEquipoTrabajo from '../pages/OrganigramaEquipoTrabajo'
import DetallePendientes from '../pages/DetallePendientes'
import UsuariosClientes from '../pages/UsuariosClientes'
import ConsultarProveedores from '../pages/Proveedores'

import NuevoDetalleTipoActividad from '../pages/NuevosDashboards/NuevoDetalleTipoActividad'
import NuevoTipoActividadFuncionario from '../pages/NuevosDashboards/NuevoTipoActividadFuncionario'

import ExportDataProveedor from '../pages/ExportDataProveedor'
import CargarImagen from '../pages/CargarImagen'


/// Definición de rutas para el sistema 
/// path: Url donde se carga el componente 
/// exact: Busca exactamente la ruta que este en path
/// componet: Carga el componente importado 

function Router() {
    
    return (

        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/registroActividades" component={Login} />
                <Route exact path="/registroActividades/inicio" component={Home} />
                <Route exact path="/registroActividades/consultarUsuarios" component={ConsultarUsuarios} />
                <Route exact path="/registroActividades/clientes" component={ConsultarClientes} />
                <Route exact path="/registroActividades/proyectos" component={ConsultarProyectos} />
                <Route exact path="/registroActividades/aplicaciones" component={ConsultarAplicaciones} />
                <Route exact path="/registroActividades/areasNegocio" component={ConsultarAreasNegocio} />
                <Route exact path="/registroActividades/exportarDatos" component={ExportData} />
                <Route exact path="/registroActividades/tiposActividades" component={ConsultarTiposActividad} />
                <Route exact path="/registroActividades/cargos" component={ConsultarCargos} />
                <Route exact path="/registroActividades/consultarActividades/:indicador" component={ConsultarActividades} />
                <Route exact path="/registroActividades/roles" component={ConsultarRoles} />
                <Route exact path="/registroActividades/consultarCalendario" component={ConsultarCalendario} />
                <Route exact path="/registroActividades/parametrizarCalendario" component={ParametrizarCalendario} />
                <Route exact path="/registroActividades/abrirMes" component={AbrirMes} />
                <Route exact path="/registroActividades/cerrarMes" component={CerrarMes} />
                <Route exact path="/registroActividades/historico" component={Historico} />
                <Route exact path="/registroActividades/SobrecargaTrabajoProyecto" component={CargaTrabajoProyecto} />
                <Route exact path="/registroActividades/detalleTipoActividad" component={DetalleTipoActividad} />
                <Route exact path="/registroActividades/tipoActividadFuncionario" component={TipoActividadFuncionario} />
                <Route exact path="/registroActividades/detalleTipoActividadProveedor" component={NuevoDetalleTipoActividad} />
                <Route exact path="/registroActividades/tipoActividadFuncionarioProveedor" component={NuevoTipoActividadFuncionario} />
                <Route exact path="/registroActividades/informeMensualIndividual" component={InformeMensualIndividual} />
                <Route exact path="/registroActividades/personasInteresadas" component={PersonasInteresadas} />
                <Route exact path="/registroActividades/objetivos" component={Objetivos} />
                <Route exact path="/registroActividades/contratos" component={Contratos} />
                <Route exact path="/registroActividades/entregables" component={Entregables} />
                <Route exact path= "/registroActividades/equipoTrabajo" component={equipoTrabajo}/>
                <Route exact path="/registroActividades/servicios" component={Servicios} />
                <Route exact path="/registroActividades/recuperarContrasena" component={RecuperarContraseña} />
                <Route exact path="/registroActividades/restablecerContrasena" component={RestablecerContraseña} />
                <Route exact path="/registroActividades/tiposProyectos" component={TiposProyectos} />
                <Route exact path="/registroActividades/Organigrama" component={OrganigramaEquipoTrabajo} />
                <Route exact path="/registroActividades/detallePendientes" component={DetallePendientes} />
                <Route exact path="/registroActividades/usuariosClientes" component={UsuariosClientes} />
                <Route exact path="/registroActividades/proveedores" component={ConsultarProveedores} />
                <Route exact path="/registroActividades/exportarDatosProveedor" component={ExportDataProveedor} />
                <Route exact path="/registroActividades/cargarImagenCorporativa" component={CargarImagen} />
                <Route exact path="/singOff" component={Login} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router