import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import BreadCrumb from "../componets/Home/BreadCrumb";
import SidebarMenu from "../componets/Home/MenuSidebar/sidebarMenu";
import Organigrama from "../componets/OrganigramaEquipoTrabajo/Organigrama";

const cookies = new Cookies();

const OrganigramaEquipoTrabajo = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[24].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Organigrama Operacional Linexperts Consultoría Empresarial S.A.S.</span>
    )
    return (

        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />

            <div className="content">

                <BreadCrumb
                    home={home}
                    consultar={consultar}
                />

                <div className="container">
                    <h1 >Organigrama Operacional Linexperts Consultoría Empresarial S.A.S.</h1>
                </div>

                <div className="tabla">
                    <Organigrama />
                </div>

            </div>
        </div>
    )

}

export default OrganigramaEquipoTrabajo

