
import React, { useState, useEffect, useRef } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { addLocale } from 'primereact/api';
import Chart1 from './Chart1';
import Chart2 from './Chart2';
import Chart3 from './Chart3';
import Services from '../../../../service/Services';
import '../../../../assets/css/Forms.css'
import '../../../../assets/css/DialogDemo.css'
import axios from 'axios';
import { CambiarFormatoFecha } from '../../../Funciones';
import Cookies from 'universal-cookie';
import { UseMedia } from '../../../Funciones';

const cookies = new Cookies();

const RegistradorActividades = () => {
    const [projectsSession, setProjectsSession] = useState([]);
    const [clientsUserSession, setClientsUserSession] = useState([]);
    const [servicioTipoActividad, setServicioTipoActividad] = useState([]);

    const getServices = new Services();
    const [itemsSelectedClient, setItemsSelectedClient] = useState(0);
    const [loading, setLoading] = useState(false)
    const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones

    useEffect(() => {
        setLoading(true)
        getServices.getClientesProyectosUsuario().then((data) => {
            if (data !== undefined) {

                const sendClients = []

                data.forEach(element => {
                    var selectedClients = {}
                    selectedClients.id = parseInt(element.cliente.id)
                    selectedClients.name = element.cliente.nombre
                    sendClients.push(selectedClients)
                });
                const tableClientes = {}
                const uniqueClients = sendClients.filter(function (object) {
                    return tableClientes.hasOwnProperty(object.id) ? false : (tableClientes[object.id] = true)
                })
                setClientsUserSession(uniqueClients)
                setSelectedCliente(uniqueClients.map(item => {
                    return {
                        id: item.id,
                        name: item.name
                    }
                }))
                setItemsSelectedClient(uniqueClients.length)

                const tableProjects = {}
                const uniqueProjects = data.filter(function (object) {
                    return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
                })

                setProjectsSession(uniqueProjects.map((item) => {
                    return {
                        nombreproyecto: item.nombre,
                        id: parseInt(item.id),
                        cliente: item.cliente
                    }
                }))
                setSelectedProyecto(uniqueProjects.map((item) => {
                    return {
                        name: item.nombre,
                        id: parseInt(item.id),
                    }
                }))
                setItemsSelectedProject(uniqueProjects.length)
                setLoading(false)
            }
        });
        getServices.getTipoActividades().then(data => {
            if (data !== undefined) {
                setServicioTipoActividad(data)
            }
        });

        // Función de limpieza para cancelar la solicitud cuando el componente se desmonta o cambia de pestaña
        return () => {
            if (source) {
                getServices.cancelRequest()
            }

        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toast = useRef(null);

    //Obtener datos del select tipo de clientes

    const [selectedCliente, setSelectedCliente] = useState([]);

    const clientes = clientsUserSession.map(item => {
        const av = { name: item.name, id: item.id }
        return av
    })



    const onClienteChange = (e) => {
        setItemsSelectedClient(e.value.length)
        setSelectedCliente(e.value);
        setSelectedProyecto([])
    };


    const [selectedProyecto, setSelectedProyecto] = useState([]);

    const proyectos = projectsSession.map(item => {
        const av = { name: item.nombreproyecto, id: item.id }
        return av
    })

    const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
    const onProyectoChange = (e) => {
        setItemsSelectedProject(e.value.length)
        setSelectedProyecto(e.value);
    };

    // Seleccionar tipo de actividad 

    const [selectedTipoActividad, setSelectedTipoActividad] = useState([]);
    const tipoActividades = servicioTipoActividad.map(item => {
        const av = { name: item.nombretipoactividad, id: item.id }
        return av
    })

    const [itemsSelectedTypeActivity, setItemsSelectedTypeActivity] = useState(0);
    const onTipoActividadChange = (e) => {
        setItemsSelectedTypeActivity(e.value.length)
        setSelectedTipoActividad(e.value);
    };

    // Fechas primer día y fecha actual 

    const dateFirtsDay = new Date()
    var firstDay = new Date(dateFirtsDay.getFullYear(), dateFirtsDay.getMonth(), 1)

    var dateCurrent = new Date()

    //Seleccionar Fechas de inicio y fin 

    const [dateStart, setDateStart] = useState(firstDay);
    const [dateEnd, setDateEnd] = useState(dateCurrent);


    /*console.log(dateEnd) */

    const onDateStartChange = (e) => {
        let date = e.value
        setDateStart(date)
    }

    const onDateEndChange = (e) => {
        let date = e.value
        setDateEnd(date)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    const usuario = cookies.get('numeroDocumento')

    // petición al servicio 

    const [dashBoardData, setDashBoardData] = useState([])

    // Procesos para obtener los ids según se seleccionen

    // obtener todos los ids
    var idsClientesChange = []
    if (selectedCliente !== null) {
        selectedCliente.forEach(element => {
            idsClientesChange.push(parseInt(element.id))
        })
    }
    /* console.log(idsClientesChange) */
    // obtener todos los ids
    var idsProyectosChange = []
    if (selectedProyecto !== null) {
        selectedProyecto.forEach(element => {
            idsProyectosChange.push(parseInt(element.id))
        })
    }
    // obtener todos los ids
    var idsTipoActividadesChange = []
    if (selectedTipoActividad !== null) {
        selectedTipoActividad.forEach(element => {
            idsTipoActividadesChange.push(element.id)
        })
    }


    useEffect(() => {
         
        const getDashBoardDates = async () => {
            const fechaIngresoUsuario = cookies.get('fechaIngreso')

            const historicoHoras = sessionStorage.getItem("historicoHoras");
            const newHistoricoHoras = JSON.parse(historicoHoras)

            const url = process.env.REACT_APP_BASE_URL + `/v1/api/report/dashBoard`

            const newDateStart = CambiarFormatoFecha(dateStart)

            const newDateEnd = CambiarFormatoFecha(dateEnd)

            let json = JSON.stringify(
                {
                    fecFinal: newDateEnd,
                    fecInicial: newDateStart,
                    idClientes: idsClientesChange,
                    idProyectos: idsProyectosChange,
                    idTipoActividades: idsTipoActividadesChange,
                    idUsuario: usuario,
                    fecIngresoUsuario: fechaIngresoUsuario,
                    historicoHoras: newHistoricoHoras
                }
            )
            /* console.log(json) */

            await axios.post(url, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    },
                    cancelToken: source.token, // Usa la fuente de cancelación actual
                })
                .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Consulta satisfactoria', detail: response.data.message, life: 6000 });
                    setDashBoardData(response.data)
                })
                .catch(function (error) {
                    if (axios.isCancel(error)) {
                        /*   console.log('La solicitud fue cancelada:', error.message); */
                    } else {
                        if (toast.current !== null) {
                            toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                            //console.log(error.response)
                            setDashBoardData([])
                        }
                    }

                })

        }

        if (dateStart !== null && dateEnd !== null) {
            if (dateStart > dateEnd) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'La fecha incial no puede ser mayor a la fecha final', life: 6000 });
            } else {
                // Llama a getDashBoardDates cuando se terminen de enviar los datos
                if (loading === false) {
                    getDashBoardDates();
                }
            }
        }

        // Función de limpieza para cancelar la solicitud cuando el componente se desmonta o cambia de pestaña
        return () => {
            if (source) {
                source.cancel();
            }
        };

    }, [dateStart, dateEnd, selectedCliente, selectedProyecto, selectedTipoActividad, loading])  // eslint-disable-line react-hooks/exhaustive-deps

    var setData

    if (dashBoardData.length === 0) {
        setData = false
    } else {
        setData = true
    }


    let small = UseMedia("(max-width: 1300px)");

    let show = UseMedia("(max-width: 755px)")


    return (
        <div className="">
            <Toast ref={toast} />
            <div className="p-fluid p-formgrid p-grid">
                <form style={{ display: 'contents' }}  >
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" >Fecha Inicio<span className="require">*</span></label>
                        <Calendar value={dateStart} onChange={onDateStartChange} locale="es" placeholder="Seleccione la fecha de inicio" dateFormat="dd-mm-yy" />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" >Fecha Fin<span className="require">*</span></label>
                        <Calendar value={dateEnd} onChange={onDateEndChange} locale="es" placeholder="Seleccione la fecha de fin" dateFormat="dd-mm-yy" />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels" >Cliente</label>
                        <MultiSelect value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="name"
                            placeholder="Seleccione un cliente" maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`}
                            filter
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels" >Proyecto</label>
                        <MultiSelect value={selectedProyecto} options={proyectos} onChange={onProyectoChange} name="proyecto" optionLabel="name" filter
                            placeholder="Seleccione un proyecto" maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels" >Tipo actividad</label>
                        <MultiSelect value={selectedTipoActividad} options={tipoActividades} onChange={onTipoActividadChange} name="tipoActividad" optionLabel="name" filter
                            placeholder="Seleccione un tipo de actividad" maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTypeActivity} Elementos Seleccionados`}
                        />
                    </div>
                </form>
                <div className="p-formgroup-inline">
                    <div className="p-field">
                        <label className="labels" style={{ marginLeft: '10px' }}>Horas  reportadas: <span>{dashBoardData.horasReportadas}</span></label>
                    </div>
                     {
                        
                            <div className="p-field">
                                <label className="labels">Horas pendientes por registrar: <span>{dashBoardData.horasPendiente}</span></label>
                            </div>
                        
                    } 
                </div>
            </div>
            <div className="p-grid">
                <div className={small ? "p-col-12" : "p-col-6"}>
                    <Panel header="Clientes">
                        {setData
                            ?
                            <Chart1
                                dashBoardDataProp={dashBoardData}
                            />
                            :
                            "No hay datos para graficar"
                        }

                    </Panel>
                </div>
                <div className={small ? "p-col-12" : "p-col-6"}>
                    <Panel header="Proyectos">
                        {setData
                            ?
                            <Chart2
                                dashBoardDataProp={dashBoardData}
                            />
                            :
                            "No hay datos para graficar"
                        }
                    </Panel>
                </div>
                <div className="p-col-12 ">
                    {show
                        ?
                        ""
                        :
                        <Panel header="Cliente - Proyecto - Tipo Actividad">
                            {setData
                                ?
                                <Chart3
                                    dashBoardDataProp={dashBoardData}
                                />
                                :
                                "No hay datos para graficar"
                            }
                        </Panel>
                    }

                </div>
            </div>
        </div>

    )


}
export default RegistradorActividades