import React, { useState, useEffect } from "react";
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import '../../assets/css/estilo.css'
import ConfirmBtn from "./ConfirmBtn";
import { CambiarFormatoFecha, SumarDias } from "../Funciones"



const Btnfecha = ({ dateStartProp, dateEndProp, dateStartProjectProp, dateEndProjectProp, usuarioProp, 
    idProp, idProyectoProp, runRenderProp, setRunRenderProp, estadoProp }) => {
    const [validateChange, setValidateChange] = useState(false)

    const [date1, setDate1] = useState(null)

    const onDateStartChange = (e) => {
        setDate1(e.value)
        setValidateChange(true)

    }

    const [date2, setDate2] = useState(null)

    const onDateEndChange = (e) => {
        setDate2(e.value)
        setValidateChange(true)
    }

    var d = new Date()
    var gtmHours = -d.getTimezoneOffset() / 60

    useEffect(() => {

        const dateStartChange = new Date(dateStartProp)

        if (dateStartProp) {
            if (gtmHours < 0) {
                setDate1(SumarDias(dateStartChange, +1))
            }
            else {
                setDate1(dateStartChange)
            }
        } else {
            setDate1(null)
        }


        if (dateEndProp !== null) {

            const dateEndChange = new Date(dateEndProp)

            if (gtmHours < 0) {
                setDate2(SumarDias(dateEndChange, +1))
            }
            else {
                setDate2(dateEndChange)
            }
        }
        else {
            setDate2(null)
        }

    }, [dateStartProp, dateEndProp, gtmHours])


    const [displayBasic, setDisplayBasic] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            const dateStartChange = new Date(dateStartProp)

            if (gtmHours < 0) {
                setDate1(SumarDias(dateStartChange, +1))
            }
            else {
                setDate1(dateStartChange)
            }

            if (dateEndProp !== null) {

                const dateEndChange = new Date(dateEndProp)

                if (gtmHours < 0) {
                    setDate2(SumarDias(dateEndChange, +1))
                }
                else {
                    setDate2(dateEndChange)
                }
            }
            else {
                setDate2(null)
            }

            setValidateChange(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header='Advertencia' visible={displayConfirmation}
                    modal style={{}} footer={renderFooter('displayConfirmation')}
                    onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    //Validaciones
    var createPermission = false
    var dateStartError = false
    var messageDateStart = ''

    function validarRangoFechaProyecto() {
        var newDateStart = CambiarFormatoFecha(date1)
        var newDateEnd = CambiarFormatoFecha(date2)

        if (newDateStart >= dateStartProjectProp && newDateStart <= dateEndProjectProp &&
            newDateEnd >= dateStartProjectProp && newDateEnd <= dateEndProjectProp) {
            dateStartError = false
            messageDateStart = ""
        }
        else {
            dateStartError = true
            messageDateStart = "Las fechas establecidas están fuera del rango de las fechas del proyecto"
        }
    }

    function validarFechaInicio() {
        var newDateStart = CambiarFormatoFecha(date1)
        if (newDateStart < dateStartProjectProp || newDateStart > dateEndProjectProp) {
            dateStartError = true
            messageDateStart = "La fecha de inicio de vinculación, no está dentro del rango de las fechas del proyecto"
        }
    }

    if (date1 !== null && date2 !== null) {
        if (date1 > date2) {
            dateStartError = true
            messageDateStart = "La fecha de inicio debe ser menor a la fecha fin"
        }
        else {
            validarRangoFechaProyecto()
        }
    }

    if (date1 !== null && date2 === null) {
        validarFechaInicio()
    }

    if (date1 !== null && dateStartError === false) {
        createPermission = true
    }

    //Enviar datos por props

    
    var sendArrayProject = [{
        id: idProp,
        estado: date2 !== null ? CambiarFormatoFecha(date2) < CambiarFormatoFecha(new Date()) ? 0 : 1 :1,
        fechainiciovigencia: date1 === null ? date1 : CambiarFormatoFecha(date1),
        fechafinvigencia: date2 === null ? date2 : CambiarFormatoFecha(date2),
        proyecto: {
            id: idProyectoProp
        }

    }]

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    createPermission ?
                        <ConfirmBtn
                            titulo='Vinculación de fechas'
                            mensaje='¿Desea registrar las fechas ingresadas?'
                            accion='ActualizarVigencia'
                            label='Guardar'
                            icono='pi pi-save'
                            updateDateProp={true}
                            usuarioProp={usuarioProp}
                            sendArrayProjectProp={sendArrayProject}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicOfficialProp={setDisplayBasic}
                            estadoProp={estadoProp}
                        />
                        :
                        ''
                }
            </div>
        );
    }

    return (
        <>
            <Button icon='pi pi-link'  tooltip="Actualizar fecha de asociación sin generar histórico" className="p-button-secondary" id="vincular-fecha" onClick={() => onClick('displayBasic')} />
            <Dialog className="modal" header="Vincular fecha de inicio y fin" visible={displayBasic}
                onHide={() => onHide('displayBasic')} style={{ width: '80vw' }} footer={renderFooter('displayBasic')}>

                <div className="p-fluid p-grid p-formgrid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="basic">Fecha de inicio<span className="require">*</span></label>
                        <Calendar className={dateStartError ? "p-invalid" : ""} placeholder="Seleccione una fecha" dateFormat="dd-mm-yy" locale="es" value={date1} onChange={onDateStartChange}></Calendar>
                        <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="basic">Fecha fin</label>
                        <Calendar dateFormat="dd-mm-yy" locale="es" placeholder="Seleccione una fecha" value={date2} onChange={onDateEndChange}></Calendar>
                    </div>
                </div>
            </Dialog>

        </>
    );
}
export default Btnfecha





