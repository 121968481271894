import React, { useState } from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import '../assets/css/Sidebar.css'
import DataTable from '../componets/DetallePendientes/DataTable'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const DetallePendientes = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }
    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[25].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Detalle de pendientes</span>
    )

    const [lastHistory, setLastHistory] = useState();
    const [showTittle, setShowTittle] = useState(true);

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}
                />
                <div className="container">
                    <h1>Detalle de pendientes</h1>
                </div>
                <div className="container">
                    {showTittle? <h1>Periodo del {lastHistory !== undefined ? lastHistory.rango : ''}</h1> : <h1>No hay pendientes</h1>}
                </div>
                <div className="tabla">
                    <DataTable
                        setLastHistoryProp={setLastHistory}
                        setShowTittleProp={setShowTittle}
                    />
                </div>
            </div>
        </div>
    )
}

export default DetallePendientes