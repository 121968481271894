import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const BtnActualizarCargo = ({ idCargoProp, cargoProp, creadoporProp, fechacreacionProp, cargosProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [inputId, setInputId] = useState({
        id: idCargoProp
    })


    //Obtener datos del input cargo

    const [inputCargo, setInputCargo] = useState({
        cargo: cargoProp
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onCargoChange = (event) => {

        setInputCargo({
            ...inputCargo,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }
    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setInputId({
            id: idCargoProp
        })
        setInputCargo({
            cargo: cargoProp
        })

    }, [idCargoProp, cargoProp])


    //Almacenamos los valores para enviarlos por props

    const idCargo = inputId.id
    const cargo = inputCargo.cargo
    const usuario = cookies.get('usuario')


    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {


            setInputCargo({
                cargo: cargoProp
            })

            setValidarCambio(false)


            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idCargoProp={idCargo}
                        usuarioProp={usuario}
                        cargoProp={cargo}
                        creadoporProp={creadoporProp}
                        fechacreacionProp={fechacreacionProp}
                    />
                    : ""
                }

            </div>
        );
    }
    //validaciones
    var cargoError = false
    var mensajeCargo = ''
    
    if (cargo === '') {
        cargoError = true
        mensajeCargo = 'Debes ingresar el nombre del cargo '
    } else if (cargo.length < 3) {
        cargoError = true
        mensajeCargo = 'El nombre cargo debe ser mayor a 3 dígitos'
    } else if (cargo.length > 100) {
        cargoError = true
        mensajeCargo = 'El nombre cargo debe ser menor a 100 dígitos'
    }


    const [errorDuplicated, setErrorDuplicated] = useState(false)
    useEffect(() => {

        let arrItemsDuplicated = []
        if (inputCargo.cargo !== cargoProp) {
            cargosProp.forEach(element => {
                let cargoNameCreated = removeAccents(element.cargo)
                let newInputCargo = removeAccents(inputCargo.cargo)
                if (cargoNameCreated.toUpperCase() === newInputCargo.toUpperCase()) {
                    arrItemsDuplicated.push(newInputCargo)
                }
            })
        }
        if (arrItemsDuplicated.length !== 0) {
            setErrorDuplicated(true)
            toast.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Cargo pre-existente' });
        } else {
            setErrorDuplicated(false)
        }
    }, [inputCargo]); // eslint-disable-line react-hooks/exhaustive-deps

    //Validar que no hay ningun error
    var permisoActualizar = false
    if (cargoError === false && errorDuplicated === false) {
        permisoActualizar = true
    }

    return (

        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar Cargo" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Id Cargo </label>
                            <InputText id="id" type="text" name="id" disabled value={idCargo} />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cargo<span className="require">*</span></label>
                            <InputText id="cargo" type="text" name="cargo" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onCargoChange} value={cargo}
                                placeholder="Registre el nombre del cargo"
                                className={cargoError || errorDuplicated ? "p-invalid" : ""} 
                            />
                            <p className="mensajeError">{cargoError ? mensajeCargo : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarCargo