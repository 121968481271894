import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import DataTable from '../Objetivos/DataTable'
import Services from "../../service/Services";
import axios from "axios";
import { CambiarFormatoFecha, isValidDate } from "../Funciones";

const Block8 = ({ permissionsProp, getProjectsProp, getClientsProp, setSelectedObjectiveProp, selectedObjectiveProp, objectMonthlyReportDataProp, readReportProp, listReportsUsersProp,
    reloadObjectiveProp, setReloadObjectiveProp, runRenderProp, listAchievementProp, listDeliverableProp, monthlyReportDataAllDataProp,
    selectedAchievementProp, selectedDeliverableProp, loadingProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp,
    setValidateObjectiveNotCurrentProp, setServicesObjectivesProp,
    showAllDataProp, allDataProp, servicesWorkTeamProp, servicesClientsProjectUsersProp, projectAssignmentsProp, dateStartPeriodProp, dateEndPeriodProp, runRenderProp2 }) => {

    var condition = false

    if (projectAssignmentsProp !== null) {
        if (projectAssignmentsProp.length !== 0) {
            condition = true
        }
    }
    const [sendObjectivesExport, setSendObjectivesExport] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [disabledIndex, setDisabledIndex] = useState(false);
    const [servicesObjectives, setServicesObjectives] = useState([]);
    const [servicesClientProjectUsers, setServicesClientProjectUsers] = useState([]);
    const [runRenderBlock, setRunRenderBlock] = useState(0);
    useEffect(() => {
        setServicesClientProjectUsers(servicesClientsProjectUsersProp)
    }, [servicesClientsProjectUsersProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const source = axios.CancelToken.source()
        getServices.getObjectives().then(data => {
            if (data !== undefined) {
                setServicesObjectives(data)
                setServicesObjectivesProp(data)
            }
        })
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [runRenderBlock, objectMonthlyReportDataProp]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (showAllDataProp === true) {
            let newAllDataProp = []
            // Nuevo array para almacenar los datos filtrados           
            const newDataFiltered = [];
            allDataProp.forEach(item => {
                var workTeamUser = []
                servicesWorkTeamProp.forEach(element => {
                    element.lider.forEach(x => {
                        if (x.colaborador.idusuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartPeriodProp),
                                CambiarFormatoFecha(dateEndPeriodProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                    element.usuarios.forEach(x => {
                        if (x.usuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartPeriodProp),
                                CambiarFormatoFecha(dateEndPeriodProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                })

                var period = JSON.parse(item.periodo)
                let getObjetives = JSON.parse(item.objetivos)
                let newGetObjectivesFilter = []
                getObjetives.forEach(i => {
                    const filter = servicesObjectives.filter(obj => {
                        return parseInt(obj.id) === parseInt(i.id)
                    })
                    filter.forEach(x => {
                        newGetObjectivesFilter.push(x)
                    })
                })
                getObjetives.forEach(go => {
                    newGetObjectivesFilter.forEach(y => {
                        const result = y.proyectos.filter(pr => {
                            return parseInt(pr.proyecto.id) === parseInt(go.proyecto) && parseInt(go.id) === parseInt(y.id)
                        })
                        result.forEach(z => {
                            newAllDataProp.push({
                                cliente: item.cliente.razonsocial,
                                periodo: new Date(period.dateEnd).toLocaleString("es-US", { month: "long" }) + ' ' + new Date(period.dateEnd).getFullYear(),
                                equipoTrabajo: workTeamUser.length !== 0 ? workTeamUser[0].nombreequipotrabajo : '',
                                nombreColaborador: item.usuario.nombre + ' ' + item.usuario.apellido,
                                objetivo: y.descripcion,
                                fechainicio: z.fechainiciovigencia,
                                fechafin: z.fechafinvigencia,
                                cumplimiento: z.porcentajecumplimiento,
                                proyecto: z.proyecto.nombreproyecto,
                                id: y.id
                            })
                        })
                    })
                })
            })

            const uniqueCombos = {};
            // Recorrer el array original y filtrar los datos
            
            for (const data of newAllDataProp) {
                const comboKey = `${data.nombreColaborador}-${data.proyecto}-${data.objetivo}`;

                if (!uniqueCombos[comboKey]) {
                    uniqueCombos[comboKey] = true;
                    newDataFiltered.push(data);
                }
            }

            setSendObjectivesExport(newDataFiltered)
        }

    }, [runRenderBlock, showAllDataProp, allDataProp, servicesObjectives, servicesWorkTeamProp, getClientsProp, dateStartPeriodProp, dateEndPeriodProp])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setActiveIndex(0)
    }, [getProjectsProp])

    useEffect(() => {
        if (loadingProp === true) {
            setDisabledIndex(true)
        } else {
            setDisabledIndex(false)
        }
    }, [runRenderProp, loadingProp])

    const [objectives, setObjectives] = useState([]);
    const [projectsUserSession, setProjectsUserSession] = useState([]);

    const getServices = new Services();


    useEffect(() => {

        if (servicesObjectives.length !== 0) {
            setObjectives(servicesObjectives)
        }
        if (servicesClientProjectUsers.length !== 0) {
            setProjectsUserSession(servicesClientProjectUsers)
        }

    }, [runRenderBlock, servicesObjectives, servicesClientProjectUsers]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div >
                <h3 className="labels" style={{ marginBottom: '10px' }}>{showAllDataProp ? 'Objetivos' : 'Objetivos asignados a proyectos'}</h3>
                <span className="text">Son los objetivos en los cuales se ha trabajado en el período del informe y se asignarán a cada proyecto trabajado</span>
            </div>
            <div className="tabview-demo">
                <div className="card">
                    {
                        showAllDataProp === true ?
                            <div className="datatable-responsive-demo">
                                <div className="card">
                                    <DataTable
                                        permissionsProp={permissionsProp}
                                        monthlyReportDataAllDataProp={monthlyReportDataAllDataProp}
                                        showAllDataProp={showAllDataProp}
                                        sendObjectivesExportProp={sendObjectivesExport}
                                        loadingProp={loadingProp}
                                        servicesObjectivesProp={servicesObjectives}
                                        fullTableProp={false}
                                    />
                                </div>
                            </div>
                            :
                            <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                                {condition ?
                                    projectAssignmentsProp.map((element, i) => {
                                        var panel = (
                                            <TabPanel header={element.project} disabled={disabledIndex} key={i}>
                                                <div className="datatable-responsive-demo">
                                                    <div className="card">
                                                        <DataTable
                                                            permissionsProp={permissionsProp}
                                                            fullTableProp={true}
                                                            individualMonthReportProp={true}
                                                            idProjectProp={element.id}
                                                            projectProp={element.project}
                                                            dateStartValidityProp={element.dateStartValidity}
                                                            dateEndValidityProp={element.dateEndValidity}
                                                            getClientsProp={getClientsProp}
                                                            setSelectedObjectiveProp={setSelectedObjectiveProp}
                                                            selectedObjectiveProp={selectedObjectiveProp}
                                                            objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                                                            readReportProp={readReportProp}
                                                            listReportsUsersProp={listReportsUsersProp}
                                                            reloadObjectiveProp={reloadObjectiveProp}
                                                            setReloadObjectiveProp={setReloadObjectiveProp}
                                                            setDisabledIndexProp={setDisabledIndex}
                                                            disabledIndexProp={disabledIndex}
                                                            runRenderBlockProp={runRenderBlock}
                                                            setRunRenderBlockProp={setRunRenderBlock}
                                                            objectivesProp={objectives}
                                                            projectsUserSessionProp={projectsUserSession}
                                                            listAchievementProp={listAchievementProp}
                                                            listDeliverableProp={listDeliverableProp}
                                                            monthlyReportDataAllDataProp={monthlyReportDataAllDataProp}
                                                            selectedAchievementProp={selectedAchievementProp}
                                                            selectedDeliverableProp={selectedDeliverableProp}
                                                            loadingProp={loadingProp}
                                                            dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                                                            dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                                                            setValidateObjectiveNotCurrentProp={setValidateObjectiveNotCurrentProp}
                                                            showAllDataProp={showAllDataProp}
                                                            projectAssignmentsProp={projectAssignmentsProp}
                                                            runRenderProp2={runRenderProp2}
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        )
                                        return panel
                                    })
                                    :
                                    <TabPanel header="Seleccione un proyecto">
                                        {/* <div className="datatable-responsive-demo">
                                    <div className="card">
                                        <DataTable
                                            permissionsProp={permissionsProp}
                                            individualMonthReportProp={true}
                                            fullTable={false}
                                        />
                                    </div>
                                </div> */}
                                    </TabPanel>
                                }
                            </TabView>
                    }
                </div>
            </div>
        </>
    )
}

export default Block8