import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import 'primeicons/primeicons.css';
import '../../assets/css/Login.css';
import '../../assets/css/Sidebar.css'
import IMG from '../../assets/images/Logo_Timevalue _transpa_sin_slogan.png';
import { useForm } from "react-hook-form";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Services from '../../service/Services';
import axios from 'axios';
import Cookies from 'universal-cookie';
import CaptchaVerification from './Captcha';
import { GetParameterByName } from '../Funciones';

const cookies = new Cookies();

const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/login/'

const Index = ({ setPermissionsProp }) => {

    const [code, setCode] = useState('')
    const [message, setMessage] = useState('')
    const [messageModal, setMessageModal] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [aceptar, setAceptar] = useState('')
    const [serviceUser, setServiceUser] = useState([]);
    const getService = new Services()
    useEffect(() => {
        const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        getService.getUsernameAndTypeBlockOfUser().then(data => {
            if (data !== undefined) {
                setServiceUser(data)
            }
        })

        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const toast = useRef(null);

    const { register, errors, handleSubmit } = useForm();

    let history = useHistory();

    var urlParam = GetParameterByName('tipoNotificacion');

    function handleClick() {
        if (urlParam !== '') {
            history.push("/registroActividades/detallePendientes?tipoNotificacion=" + urlParam);
        } else {
            history.push("/registroActividades/inicio");
        }
    }

    const obtenerDatos = async (username, password) => {
        /* const urlData = url + username + "/" + password */
        const json = JSON.stringify({ usuario: username, contrasena: password });
        setSendEmail(false)
        await axios.post(url, json,
            {
                headers:
                {

                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(response => {
                if (response.data) {
                    var respuesta = response.data
                    var correoUsuario = username
                    cookies.set('idColaborador', respuesta.idColaborador, { path: "/" })
                    cookies.set('idUsuario', respuesta.idUsuario, { path: "/" })
                    cookies.set('code', respuesta.code, { path: "/" })
                    cookies.set('message', respuesta.message, { path: "/" })
                    sessionStorage.setItem("permissions", respuesta.message)
                    sessionStorage.setItem("mostrarHorasAdicionales", respuesta.mostrarHorasAdicionales)
                    sessionStorage.setItem("token", respuesta.token)
                    sessionStorage.setItem("historicoHoras", JSON.stringify(respuesta.historicohoras))
                    cookies.set('usuario', correoUsuario, { path: "/" })
                    cookies.set('cargo', respuesta.cargo, { path: "/" })
                    cookies.set('nombre', respuesta.nombre, { path: "/" })
                    cookies.set('apellido', respuesta.apellido, { path: "/" })
                    cookies.set('numeroDocumento', respuesta.numdocumento, { path: "/" })
                    cookies.set('tipoDocumento', respuesta.tipodocumento, { path: "/" })
                    cookies.set('cierreActivo', respuesta.blnAddActivity, { path: "/" })
                    cookies.set('fechaIngreso', respuesta.fecIngreso, { path: "/" })
                    cookies.set('tipoUsuario', respuesta.tipoUsuario, { path: "/" })
                    cookies.set('plusPowerBi', respuesta.pluspowerbi, { path: "/" })
                    cookies.set('idProveedor', respuesta.proveedor?.id, { path: "/" })
                }
                if (response.status === 200) {
                    if (response.data.code === "0") {
                        window.location.href = "/registroActividades/restablecerContrasena"
                    } else if (response.data.code === '3') {
                        setMessage(response.data.message)
                        setCode("3")
                    } else if (response.data.code === '2') {
                        if (response.data.message === 'Exceso de intentos fallidos') {
                            setMessageModal("Señor usuario usted se encuentra bloqueado, ha superado el máximo de intentos para ingresar al sistema, diríjase a olvide mi contraseña para continuar con el proceso")
                            setShowModal(true)
                        } else {
                            setMessageModal("Señor usuario usted se encuentra bloqueado por " + response.data.message + ", debe comunicarse con el administrador para continuar con el proceso")
                            setShowModal(true)
                        }
                    } else {
                        handleClick()
                        setShowModal(false)
                    }
                }
                /* //console.log(response.data) */
            })
            .catch(function (error) {
                if (error.response.data === '') {
                    setMessage('Usuario o contraseña incorrectos')
                    setCode("2")
                } else if (error.response.data.mensaje === 'Usuario bloqueado por exceso de intentos') {
                    setMessageModal("Señor usuario usted se encuentra bloqueado, ha superado el máximo de intentos para ingresar al sistema, diríjase a olvide mi contraseña para continuar con el proceso")
                    setShowModal(true)
                } else {
                    setMessage(error.response.data.mensaje)
                    setCode("2")
                }

            })
    }
    const [inputUser, setInputUser] = useState({
        usuario: ''
    })

    const userChange = (e) => {

        setInputUser({
            ...inputUser,
            [e.target.name]: e.target.value
        })

    }

    const [password, setPassword] = useState()

    useEffect(() => {
        setMessageModal("")
        setShowModal(false)
    }, [inputUser, password]) // eslint-disable-line react-hooks/exhaustive-deps

    const usuario = inputUser.usuario
    const [validateInput, setValidateInput] = useState(false)
    const [validateMessage, setValidateMessage] = useState('')
    const [sendEmail, setSendEmail] = useState(false)

    const validateUserState = (user) => {
        var filterUser = serviceUser.filter(function (object) {
            return object.usuario === user
        })

        if (filterUser.length !== 0) {
            filterUser.forEach(element => {
                if (element.estado === 2) {
                    if (element.tipobloqueo.descripcion !== 'Exceso de intentos fallidos') {
                        toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'No puede recuperar la contraseña debido a que se encuentra bloquedo por un administrador', life: 8000 });
                    } else {
                        setShowModal(true)
                        setMessageModal('Señor usuario se enviará un correo para recuperar la contraseña al correo registrado')
                        setSendEmail(true)
                    }
                }
                else {
                    setShowModal(true)
                    setMessageModal('Señor usuario se enviará un correo para recuperar la contraseña al correo registrado')
                    setSendEmail(true)
                }
            })
        } else {
            setShowModal(true)
            setMessageModal('Señor usuario se enviará un correo para recuperar la contraseña al correo registrado')
            setSendEmail(true)
        }

    }

    const RecuperarContraseña = async () => {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/sendEmail?user=' + usuario
        await axios.post(url,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(response => {
                //console.log(response.data)
                toast.current.show({ severity: 'success', summary: 'Validacion Satisfactoria', detail: "Información enviada exitosamente al correo electrónico registrado", life: 6000 });

            })
            .catch(function (error) {
                if (error.response.status === 404) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 3000 });
                }
            })

    }

    const recoverPassword = () => {
        if (usuario === '') {
            setValidateInput(true)
            setValidateMessage('Debe ingresar un correo electronico')
        } else if (usuario.length <= 5) {
            setValidateInput(true)
            setValidateMessage('Debe ingresar un usuario de más de 5 caracteres')
        } else if (usuario.length > 60) {
            setValidateInput(true)
            setValidateMessage('Debe ingresar un usuario de menos de 60 caracteres')
        } else if (usuario.length >= 5 && usuario.length < 60) {
            var expReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
            var esValido = expReg.test(usuario)
            if (esValido === false) {
                setValidateInput(true)
                setValidateMessage('El correo ingresado no es válido')
            } else {
                setValidateInput(false)
                setValidateMessage('')
                validateUserState(usuario)
                /* setShowModal(true)
                setMessageModal('Señor usuario se enviará un correo para recuperar la contraseña al correo registrado') */
            }
        } else {
            setValidateInput(false)
            setValidateMessage('')
        }

    }

    const onSubmit = async (data, e) => {
        const username = data.usuario
        const password = data.contraseña
        obtenerDatos(username, password)

        const aceptar = () => {
            return (
                <button onClick={() => {

                    e.target.reset()
                    setMessage('')
                    setCode("")
                    setAceptar('')
                    setShowModal(false)
                }} className="aceptar">  Aceptar</button>
            )
        }

        setAceptar(aceptar)
    }
    var botonaceptar = ""
    if (code === "2" || code === "3") {
        botonaceptar = aceptar
    }

    const ModalAdvertencia = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(showModal);

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false)
        }
        const sendEmailUser = (name) => {
            RecuperarContraseña()
            dialogFuncMap[`${name}`](false);
            setShowModal(false)
        }

        const renderFooter = (name) => {
            return (
                <div>
                    {
                        sendEmail
                            ?
                            <>
                                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" autoFocus />
                                <Button label="Aceptar" icon="pi pi-check" onClick={() => sendEmailUser(name)} autoFocus />
                            </>
                            :
                            <Button label="Aceptar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
                    }

                </div>
            );
        }

        return (
            <>
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}
                    breakpoints={{ '960px': '75vw' }} style={{ width: '30vw' }}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>{messageModal}</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const [validateCaptcha, setDisabledCaptcha] = useState(true)

    return (
        <div className="bodyLogin">
            <ModalAdvertencia />
            <Toast ref={toast} />
            <section className="main">
                <div className="login-container">
                    <img src={IMG} alt="Logotipo TimeValue" className='imgMenu4' /><br/><br/>
                    <div className="separator"></div>
                    <p className="title">Iniciar Sesión</p>

                    <form className="login-form" onSubmit={handleSubmit(onSubmit)} >
                        <div className="form-control">
                            <i className="pi pi-fw pi-user icon"></i>
                            <input className={validateInput || errors?.usuario ? "inputsError" : "inputs"} type="email" placeholder="Usuario" name="usuario" onChange={userChange}
                                ref={
                                    register({
                                        required: { value: true, message: 'Debes ingresar un usuario' }
                                    })
                                }

                            />
                            <p className="mensajeError"> {validateInput ? validateMessage : errors?.usuario?.message} </p>
                        </div>
                        <div className="form-control">
                            <i className="pi pi-fw pi-lock icon"></i>
                            <input className={errors?.contraseña ? "inputsError" : "inputs"} type="password" placeholder="Contraseña" name="contraseña"

                                ref={
                                    register({
                                        required: { value: true, message: 'Debes ingresar una contraseña' },
                                        minLength: {
                                            value: 4,
                                            message: 'La contraseña debe tener más de 4 caracteres'
                                        }
                                    })
                                }
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <p className="mensajeError"> {errors?.contraseña?.message} </p>
                        </div>
                        <p className="mensajeErrorLogin">{message}</p>
                        {botonaceptar ? (
                       <button className="mensajeErrorButton">{botonaceptar}</button>
                       ) : null}
                       

                        <CaptchaVerification
                            setDisabledCaptchaProp={setDisabledCaptcha}
                        />

                        <Link to="" className="welcome-message" onClick={recoverPassword}>¿Olvidaste tu contraseña?</Link>

                        {/* <button  className="submit">Iniciar</button> */}
                        <button disabled={validateCaptcha} className={validateCaptcha ? 'submit2' : 'submit'}>Iniciar</button>

                    </form>

                </div>
            </section>
        </div>

    )

}

export default Index
