import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ConfirmBtn from './ConfirmBtn.js'
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';

/* import { Link} from 'react-router-dom' */

const cookies = new Cookies();



const BtnActualizarCliente = ({ tipoDocumentoProp, numeroDocumentoProp, nombreProp, dominioProp, idClienteProp, creadoporProp, fechacreacionProp, renderProp,
    setRenderProp, linkPowerBiProp }) => {

    const [servicioTipoDocumento, setServicioTipoDocumento] = useState([]);
    const getServices = new Services();
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        if (displayBasic) {
            getServices.getTiposDocumento().then(data => setServicioTipoDocumento(data));
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps


    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    // Selects

    //Obtener datos del select tipo de documento


    const [selectedTipoDoc, setSelectedTipoDoc] = useState({
        tipoDocumento: tipoDocumentoProp,
    });

    const tipoDocs = servicioTipoDocumento.map(item => {
        const av = { tipoDocumento: item.nombretipodocumento }
        return av
    })
    const [validarCambio, setValidarCambio] = useState(false)

    const onTipoDocChange = (e) => {
        setSelectedTipoDoc(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del input número de documento 

    const [inputNumeroDocumento, setInputNumeroDocumento] = useState({
        numeroDocumento: numeroDocumentoProp
    })

    const onNumeroDocumentoChange = (event) => {

        setInputNumeroDocumento({
            ...inputNumeroDocumento,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input nombre

    const [inputNombre, setInputNombre] = useState({
        nombre: nombreProp
    })

    const onNombreChange = (event) => {

        setInputNombre({
            ...inputNombre,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input de dominio

    const [inputDominio, setInputDominio] = useState({
        dominio: dominioProp
    })

    const onDominioChange = (event) => {

        setInputDominio({
            ...inputDominio,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setSelectedTipoDoc({
            tipoDocumento: tipoDocumentoProp
        })
        setInputNumeroDocumento({
            numeroDocumento: numeroDocumentoProp
        })
        setInputNombre({
            nombre: nombreProp
        })

        setInputDominio({
            dominio: dominioProp
        })

    }, [tipoDocumentoProp, nombreProp, numeroDocumentoProp, dominioProp])


    //Almacenamos los valores para enviarlos por props

    const tipoDocumento = selectedTipoDoc.tipoDocumento
    const idTipoDocumento = selectedTipoDoc.id
    const nombre = inputNombre.nombre
    const dominio = inputDominio.dominio
    const numeroDocumento = inputNumeroDocumento.numeroDocumento
    const idCliente = idClienteProp
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            setSelectedTipoDoc({
                tipoDocumento: tipoDocumentoProp
            })
            setInputNumeroDocumento({
                numeroDocumento: numeroDocumentoProp
            })
            setInputNombre({
                nombre: nombreProp
            })

            setInputDominio({
                dominio: dominioProp
            })

            setValidarCambio(false)

            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        tipoDocumentoProp={tipoDocumento}
                        numeroDocumentoProp={numeroDocumento}
                        idTipoDocumentoProp={idTipoDocumento}
                        nombreProp={nombre}
                        dominioProp={dominio}
                        idClienteProp={idCliente}
                        creadoporProp={creadoporProp}
                        fechacreacionProp={fechacreacionProp}
                        usuarioProp={usuario}
                        showDataProp={true}
                        updateDatailprop={false}
                        renderProp={renderProp}
                        setRenderProp={setRenderProp}
                        setDisplayBasicProp={setDisplayBasic}
                        linkPowerBiProp={linkPowerBiProp}
                    />
                    : ""
                }

            </div>
        );
    }



    //validaciones para tipo de docemento
    var tipoDocumentoError = false
    var mensajeTipoDocumento = ''
    if (tipoDocumento === '') {
        tipoDocumentoError = true
        mensajeTipoDocumento = 'Debe ingresar un tipo de documento '
    }
    //validar número de documento 
    var numeroDocumentoError = false
    var mensajeNumeroDocumento = ''
    if (numeroDocumento === '') {
        numeroDocumentoError = true
        mensajeNumeroDocumento = 'Debe ingresar un número de documento'
    } else if (numeroDocumento.length < 3) {
        numeroDocumentoError = true
        mensajeNumeroDocumento = 'El número de documento debe ser mayor a tres dígitos'
    } else if (numeroDocumento.length > 20) {
        numeroDocumentoError = true
        mensajeNumeroDocumento = 'El número de documento debe ser menor a 20 dígitos'
    }
    if (tipoDocumento !== 'Pasaporte' && tipoDocumento !== "Cédula Extranjería") {
        if (isNaN(numeroDocumento) === true) {
            numeroDocumentoError = true
            mensajeNumeroDocumento = 'Este tipo de documento no puede contener letras'
        }
    }
    //validaciones para nombre
    var nombreError = false
    var mensajeNombre = ''
    if (nombre === '') {
        nombreError = true
        mensajeNombre = 'Debe ingresar un nombre / razón social'
    } else if (nombre.length <= 1) {
        nombreError = true
        mensajeNombre = 'Debe ingresar un nombre / razón de más de 1 caracteres'
    } else if (nombre.length > 100) {
        nombreError = true
        mensajeNombre = 'Debe ingresar un nombre / razón de menos de 100 caracteres'
    }

    var dominioError = false
    var mensajeDominio = ''
    if (dominio !== null && dominio !== undefined) {
        if (dominio.length <= 5 && dominio !== '') {
            dominioError = true
            mensajeDominio = 'El dominio debe ser mayor a 5 dígitos'
        } else if (dominio.length > 100) {
            dominioError = true
            mensajeDominio = 'El dominio debe ser menor a 100 dígitos'
        } else if (dominio.length > 5 && dominio.length < 100) {
            if ((dominio.match(/^[^.]+\.[^.]+(\.[^.]+)*$/)) === null) {
                dominioError = true
                mensajeDominio = 'El dominio ingresado no es válido'
            }
        }
    }

    //Validar que no hay ningun error
    var permisoActualizar = false

    if (tipoDocumentoError === false && nombreError === false && numeroDocumentoError === false && dominioError === false && dominioError === false && dominio !== null) {
        permisoActualizar = true
    }

    //validar cuando es pasaporte 
    var validarPasaporte = false

    if (selectedTipoDoc.tipoDocumento === "Pasaporte" || selectedTipoDoc.tipoDocumento === "Cédula Extranjería") {
        validarPasaporte = true
    }


    return (

        <>

            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar Cliente" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" htmlFor="tipoDoc">Tipo documento<span className="require">*</span></label>
                            <Dropdown value={selectedTipoDoc} options={tipoDocs} onChange={onTipoDocChange} name="tipoDocumento" optionLabel="tipoDocumento"
                                placeholder="Seleccione tipo de documento"
                                className={tipoDocumentoError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{tipoDocumentoError ? mensajeTipoDocumento : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" htmlFor="numeroDocumento">Número de Documento<span className="require">*</span></label>
                            <InputText id="numeroDocumento" type="text" name="numeroDocumento" onChange={onNumeroDocumentoChange} keyfilter={validarPasaporte ? "alphanum" : "pint"} value={numeroDocumento}
                                className={numeroDocumentoError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{numeroDocumentoError ? mensajeNumeroDocumento : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" htmlFor="nombre">Nombre / Razón social<span className="require">*</span></label>
                            <InputText id="nombre" type="text" name="nombre" keyfilter={/^[^<>*!"=°|(#$%)+._/?¡¿'{}]+$/} onChange={onNombreChange} value={nombre}
                                className={nombreError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{nombreError ? mensajeNombre : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Dominio<span className="require">*</span></label>
                            <InputText id="dominio" type="text" name="dominio" keyfilter="email" onChange={onDominioChange} value={dominio}
                                placeholder="Ingrese el dominio del correo del cliente, ejemplo: dominio.com"
                                className={dominioError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dominioError ? mensajeDominio : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarCliente