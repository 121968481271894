import React, { useRef, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import ConfirmBtn from './ConfirmBtn.js'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';
const cookies = new Cookies();

const BtnActualizarRol = ({ idRolProp, rolProp, estadoProp, permisosProp, creadoporProp, fechacreacionProp, checkedNotificationProp, servicioProp }) => {

    let toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');
    const dt = useRef(null);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [inputId, setInputId] = useState({
        id: idRolProp
    })


    //Obtener datos del input rol

    const [inputRol, setInputRol] = useState({
        rol: rolProp
    })
    const [validateChange, setValidateChange] = useState(false)
    const onRolChange = (event) => {

        setInputRol({
            ...inputRol,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }


    //Obtener datos del select tipo de estados

    var estadoR = ""
    if (estadoProp === 0) {
        estadoR = "Inactivo"
    }

    if (estadoProp === 1) {
        estadoR = "Activo"
    }

    const [selectedEstado, setSelectedEstado] = useState({
        estado: estadoR
    });
    const estados = [
        { estado: "Activo" }, //Activo
        { estado: "Inactivo" }, //Inactivo

    ];

    const onEstadoChange = (e) => {
        setSelectedEstado(e.value);
        setValidateChange(true)

    }

    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setInputId({
            id: idRolProp
        })
        setInputRol({
            rol: rolProp
        })
        setSelectedEstado({
            estado: estadoR
        })

        setCheckedNotification(checkedNotificationProp)

    }, [idRolProp, estadoR, rolProp, checkedNotificationProp]) // eslint-disable-line react-hooks/exhaustive-deps


    //Almacenamos los valores para enviarlos por props

    const idRol = inputId.id
    const rol = inputRol.rol
    const estado = selectedEstado.estado
    const usuario = cookies.get('usuario')


    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setInputRol({
                rol: rolProp
            })

            setValidateChange(false)

            setCheckedUserRead(permisoUserRead)
            setCheckedUserCreate(permisoUserCreate)
            setCheckedUserUpdate(permisoUserUpdate)
            setCheckedUserDelete(permisoUserDelete)
            setCheckedUserSelectAll(selectAllUser)
            setCheckedActivityRead(permisoActivityRead)
            setCheckedActivityCreate(permisoActivityCreate)
            setCheckedActivityUpdate(permisoActivityUpdate)
            setCheckedActivityDelete(permisoActivityDelete)
            setCheckedActivitySelectAll(selectAllActivity)
            setCheckedExportDataRead(permisoExportDataRead)
            setCheckedExportDataAll(permisoExportDataAll)
            setCheckedExportDataSelectAll(selectAllExportData)
            setCheckedClientRead(permisoClientRead)
            setCheckedClientCreate(permisoClientCreate)
            setCheckedClientUpdate(permisoClientUpdate)
            setCheckedClientDelete(permisoClientDelete)
            setCheckedClientSelectAll(selectAllClient)
            setCheckedProjectRead(permisoProjectRead)
            setCheckedProjectCreate(permisoProjectCreate)
            setCheckedProjectUpdate(permisoProjectUpdate)
            setCheckedProjectDelete(permisoProjectDelete)
            setCheckedProjectSelectAll(selectAllProject)
            setCheckedAplicationRead(permisoAplicationRead)
            setCheckedAplicationCreate(permisoAplicationCreate)
            setCheckedAplicationUpdate(permisoAplicationUpdate)
            setCheckedAplicationDelete(permisoAplicationDelete)
            setCheckedAplicationSelectAll(selectAllAplication)
            setCheckedBusinessAreasRead(permisoBusinessAreasRead)
            setCheckedBusinessAreasCreate(permisoBusinessAreasCreate)
            setCheckedBusinessAreasUpdate(permisoBusinessAreasUpdate)
            setCheckedBusinessAreasDelete(permisoBusinessAreasDelete)
            setCheckedBusinessAreasSelectAll(selectAllBusinessAreas)
            setCheckedActivityTypeRead(permisoActivityTypeRead)
            setCheckedActivityTypeCreate(permisoActivityTypeCreate)
            setCheckedActivityTypeUpdate(permisoActivityTypeUpdate)
            setCheckedActivityTypeDelete(permisoActivityTypeDelete)
            setCheckedActivityTypeSelectAll(selectAllActivityType)
            setCheckedPositionRead(permisoPositionRead)
            setCheckedPositionCreate(permisoPositionCreate)
            setCheckedPositionUpdate(permisoPositionUpdate)
            setCheckedPositionDelete(permisoPositionDelete)
            setCheckedPositionSelectAll(selectAllPosition)
            setCheckedRolRead(permisoRolRead)
            setCheckedRolCreate(permisoRolCreate)
            setCheckedRolUpdate(permisoRolUpdate)
            setCheckedRolDelete(permisoRolDelete)
            setCheckedRolSelectAll(selectAllRol)
            setCheckedCalendarRead(permisoCalendarRead)
            setCheckedCalendarManage(permisoCalendarManage)
            setCheckedCalendarSelectAll(selectAllCalendar)
            setCheckedDownloadZip(permisoDescargarZip)
            setCheckedDownloadZipSelectAll(permisoDescargarZip)
            setCheckedRunClose(permisoRunClose)
            setCheckedCloseOpen(permisoCloseOpen)
            setCheckedNotification(checkedNotificationProp)
            setCheckedClosesSelectAll(selectAllCloses)
            setCheckedHistorical(permisoHistorico)
            setCheckedHistoricalSelectAll(permisoHistorico)
            setCheckedDBActivityLogger(permisoDBActivityLogger)
            setCheckedDBWorkload(permisoDBWorkload)
            setCheckedDBDetailTypeActivity(permisoDBDetailTypeActivity)
            setCheckedDBDetailTypeActivityUser(permisoDBDetailTypeActivityUser)
            setCheckedDBSelectAll(selectAllDB)
            setCheckedDBReadPowerBi(permisoDBReadPowerBi)
            setCheckedDBPowerBiClient(permisoDBPowerBiClient)
            setCheckedDBPlusPowerBi(permisoDBPlusPowerBi)
            setCheckedDBPlusPowerBiSelectAll(selectAllDBPowerBi)
            setCheckedNoveltyAnotherOfficial(permisoNoveltyAnotherOfficial)
            setCheckedNoveltyAnotherOfficialBSelectAll(selectAllNoveltyAnotherOfficial)
            setCheckedindividualMonthlyReportRead(permisoIndividualMonthlyReport)
            setCheckedindividualMonthlyReportReadOtherOfficial(permisoIndividualMonthlyReportOtherOfficial)
            setCheckedindividualMonthlyReportReopen(permisoIndividualMonthlyReopen)
            setCheckedindividualMonthlyReportSelectAll(selectAllIndividualMonthlyReport)
            setCheckedInterestedPersonRead(permisoInterestedPersonRead)
            setCheckedInterestedPersonCreate(permisoInterestedPersonCreate)
            setCheckedInterestedPersonUpdate(permisoInterestedPersonUpdate)
            setCheckedInterestedPersonDelete(permisoInterestedPersonDelete)
            setCheckedInterestedPersonSelectAll(selectAllInterestedPerson)
            setCheckedObjectiveRead(permisoObjectiveRead)
            setCheckedObjectiveCreate(permisoObjectiveCreate)
            setCheckedObjectiveUpdate(permisoObjectiveUpdate)
            setCheckedObjectiveDelete(permisoObjectiveDelete)
            setCheckedObjectiveSelectAll(selectAllObjective)
            setCheckedContractRead(permisoContractRead)
            setCheckedContractCreate(permisoContractCreate)
            setCheckedContractUpdate(permisoContractUpdate)
            setCheckedContractDelete(permisoContractDelete)
            setCheckedContractSelectAll(selectAllContract)
            setCheckedDeliverableRead(permisoDeliverableRead)
            setCheckedDeliverableCreate(permisoDeliverableCreate)
            setCheckedDeliverableUpdate(permisoDeliverableUpdate)
            setCheckedDeliverableDelete(permisoDeliverableDelete)
            setCheckedDeliverableSelectAll(selectAllDeliverable)
            setCheckedServiceRead(permisoServiceRead)
            setCheckedServiceCreate(permisoServiceCreate)
            setCheckedServiceUpdate(permisoServiceUpdate)
            setCheckedServiceDelete(permisoServiceDelete)
            setCheckedServiceSelectAll(selectAllService)
            setCheckedWorkTeamRead(permisoWorkTeamRead)
            setCheckedWorkTeamCreate(permisoWorkTeamCreate)
            setCheckedWorkTeamUpdate(permisoWorkTeamUpdate)
            setCheckedWorkTeamDelete(permisoWorkTeamDelete)
            setCheckedWorkTeamSelectAll(selectAllWorkTeam)
            setCheckedTypeProjectRead(permisoTypeProjectRead)
            setCheckedTypeProjectCreate(permisoTypeProjectCreate)
            setCheckedTypeProjectUpdate(permisoTypeProjectDelete)
            setCheckedTypeProjectDelete(permisoTypeProjectDelete)
            setCheckedTypeProjectSelectAll(selectAllTypeProject)
            setCheckedOrgRead(permisoOrganizationChart)
            setCheckedOrgSelectAll(permisoOrganizationChart)
            setCheckedDetailPendingRead(permisoDetailPendingRead)
            setCheckedDetailPendingSelectAll(permisoDetailPendingRead)
            setCheckedUserClientRead(permisoUserClientRead)
            setCheckedUserClientSelectAll(permisoUserClientRead)
            setCheckedSupplierRead(permisoSupplierRead)
            setCheckedSupplierCreate(permisoSupplierCreate)
            setCheckedSupplierUpdate(permisoSupplierDelete)
            setCheckedSupplierDelete(permisoSupplierDelete)
            setCheckedSupplierSelectAll(selectAllSupplier)
            setCheckedFilterSupplierNDB1(permisoFilterSupplierNDB1)
            setCheckedFilterSupplierNDB2(permisoFilterSupplierNDB2)
            setCheckedFilterSupplierExportData(permisoFilterSupplierExportData)
            setCheckedFilterSupplierSelectAll(selectAllFilterSupplier)
            setCheckedDBReadPowerBiFilterSupplier(permisoDBReadPowerBiFilterSupplier)
            setCheckedDBPowerBiFilterSupplierClient(permisoDBPowerBiFilterSupplierClient)
            setCheckedDBPlusPowerBiFilterSupplier(permisoDBPlusPowerBiFilterSupplier)
            setDisplayBasic(false)
            setErrorDuplicated(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        );
    }

    const mostrar = JSON.parse(permisosProp);
    const permisos = mostrar.rol

    ////// Usuarios
    var permisoUserRead
    if (permisos[0].consultar === "true") {
        permisoUserRead = true
    } else {
        permisoUserRead = false
    }
    var permisoUserCreate
    if (permisos[0].permisos[0] === "Crear") {
        permisoUserCreate = true
    } else {
        permisoUserCreate = false
    }
    var permisoUserUpdate
    if (permisos[0].permisos[1] === "Editar") {
        permisoUserUpdate = true
    } else {
        permisoUserUpdate = false
    }
    var permisoUserDelete
    if (permisos[0].permisos[2] === "Eliminar") {
        permisoUserDelete = true
    } else {
        permisoUserDelete = false
    }
    var selectAllUser
    if (permisoUserRead === true && permisoUserCreate === true && permisoUserUpdate === true && permisoUserDelete === true) {
        selectAllUser = true
    }

    const [checkedUserRead, setCheckedUserRead] = useState(permisoUserRead);
    const [checkedUserCreate, setCheckedUserCreate] = useState(permisoUserCreate);
    const [checkedUserUpdate, setCheckedUserUpdate] = useState(permisoUserUpdate);
    const [checkedUserDelete, setCheckedUserDelete] = useState(permisoUserDelete);
    const [checkedUserSelectAll, setCheckedUserSelectAll] = useState(selectAllUser);

    const onUserReadChange = (e) => {
        if (checkedUserCreate === true || checkedUserUpdate === true || checkedUserDelete === true) {
            setCheckedUserRead(true)
        } else {
            setCheckedUserRead(e)
        }

        setValidateChange(true)

    }
    const onUserCreateChange = (e) => {
        setCheckedUserCreate(e)
        if (checkedUserRead === false) {
            setCheckedUserRead(e)
        }

        setValidateChange(true)

    }
    const onUserUpdateChange = (e) => {
        setCheckedUserUpdate(e)
        if (checkedUserRead === false) {
            setCheckedUserRead(e)
        }
        setValidateChange(true)

    }
    const onUserDeleteChange = (e) => {
        setCheckedUserDelete(e)
        if (checkedUserRead === false) {
            setCheckedUserRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllUserChange = (e) => {
        setCheckedUserSelectAll(e)

        setCheckedUserRead(e)
        setCheckedUserCreate(e)
        setCheckedUserUpdate(e)
        setCheckedUserDelete(e)
        setValidateChange(true)
    }

    ////// Actividades
    var permisoActivityRead
    if (permisos[1].consultar === "true") {
        permisoActivityRead = true
    } else {
        permisoActivityRead = false
    }
    var permisoActivityCreate
    if (permisos[1].permisos[0] === "Crear") {
        permisoActivityCreate = true
    } else {
        permisoActivityCreate = false
    }
    var permisoActivityUpdate
    if (permisos[1].permisos[1] === "Editar") {
        permisoActivityUpdate = true
    } else {
        permisoActivityUpdate = false
    }
    var permisoActivityDelete
    if (permisos[1].permisos[2] === "Eliminar") {
        permisoActivityDelete = true
    } else {
        permisoActivityDelete = false
    }

    var selectAllActivity
    if (permisoActivityRead === true && permisoActivityCreate === true && permisoActivityUpdate === true && permisoActivityDelete === true) {
        selectAllActivity = true
    }

    const [checkedActivityRead, setCheckedActivityRead] = useState(permisoActivityRead);
    const [checkedActivityCreate, setCheckedActivityCreate] = useState(permisoActivityCreate);
    const [checkedActivityUpdate, setCheckedActivityUpdate] = useState(permisoActivityUpdate);
    const [checkedActivityDelete, setCheckedActivityDelete] = useState(permisoActivityDelete);
    const [checkedActivitySelectAll, setCheckedActivitySelectAll] = useState(selectAllActivity);

    const onActivityReadChange = (e) => {
        if (checkedActivityCreate === true || checkedActivityUpdate === true || checkedActivityDelete === true) {
            setCheckedActivityRead(true)
        } else {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }
    const onActivityCreateChange = (e) => {
        setCheckedActivityCreate(e)
        if (checkedActivityRead === false) {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }
    const onActivityUpdateChange = (e) => {
        setCheckedActivityUpdate(e)
        if (checkedActivityRead === false) {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }
    const onActivityDeleteChange = (e) => {
        setCheckedActivityDelete(e)
        if (checkedActivityRead === false) {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllActivityChange = (e) => {
        setCheckedActivitySelectAll(e)

        setCheckedActivityRead(e)
        setCheckedActivityCreate(e)
        setCheckedActivityUpdate(e)
        setCheckedActivityDelete(e)
        setValidateChange(true)
    }


    ////exportar datos
    var permisoExportDataRead
    if (permisos[2].consultar === "true") {
        permisoExportDataRead = true
    } else {
        permisoExportDataRead = false
    }
    var permisoExportDataAll
    if (permisos[2].exportarTodos === "true") {
        permisoExportDataAll = true
    } else {
        permisoExportDataAll = false
    }

    var selectAllExportData
    if (permisoExportDataRead === true && permisoExportDataAll === true) {
        selectAllExportData = true
    }

    const [checkedExportDataRead, setCheckedExportDataRead] = useState(permisoExportDataRead);
    const [checkedExportDataAll, setCheckedExportDataAll] = useState(permisoExportDataAll);
    const [checkedExportDataSelectAll, setCheckedExportDataSelectAll] = useState(selectAllExportData);

    const onExportDataReadChange = (e) => {
        if (checkedExportDataAll === true) {
            setCheckedExportDataRead(true)
        } else {
            setCheckedExportDataRead(e)
        }
        setValidateChange(true)

    }
    const onExportDataAllChange = (e) => {
        setCheckedExportDataAll(e)
        if (checkedExportDataRead === false) {
            setCheckedExportDataRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllExportDataChange = (e) => {
        setCheckedExportDataSelectAll(e)

        setCheckedExportDataRead(e)
        setCheckedExportDataAll(e)

        setValidateChange(true)
    }


    //////// Clientes
    var permisoClientRead
    if (permisos[3].consultar === "true") {
        permisoClientRead = true
    } else {
        permisoClientRead = false
    }
    var permisoClientCreate
    if (permisos[3].permisos[0] === "Crear") {
        permisoClientCreate = true
    } else {
        permisoClientCreate = false
    }
    var permisoClientUpdate
    if (permisos[3].permisos[1] === "Editar") {
        permisoClientUpdate = true
    } else {
        permisoClientUpdate = false
    }
    var permisoClientDelete
    if (permisos[3].permisos[2] === "Eliminar") {
        permisoClientDelete = true
    } else {
        permisoClientDelete = false
    }
    var selectAllClient
    if (permisoClientRead === true && permisoClientCreate === true && permisoClientUpdate === true && permisoClientDelete === true) {
        selectAllClient = true
    }

    const [checkedClientRead, setCheckedClientRead] = useState(permisoClientRead);
    const [checkedClientCreate, setCheckedClientCreate] = useState(permisoClientCreate);
    const [checkedClientUpdate, setCheckedClientUpdate] = useState(permisoClientUpdate);
    const [checkedClientDelete, setCheckedClientDelete] = useState(permisoClientDelete);
    const [checkedClientSelectAll, setCheckedClientSelectAll] = useState(selectAllClient);

    const onClientReadChange = (e) => {
        if (checkedClientCreate === true || checkedClientUpdate === true || checkedClientDelete === true) {
            setCheckedClientRead(true)
        } else {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }
    const onClientCreateChange = (e) => {
        setCheckedClientCreate(e)
        if (checkedClientRead === false) {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }
    const onClientUpdateChange = (e) => {
        setCheckedClientUpdate(e)
        if (checkedClientRead === false) {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }
    const onClientDeleteChange = (e) => {
        setCheckedClientDelete(e)
        if (checkedClientRead === false) {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllClientChange = (e) => {
        setCheckedClientSelectAll(e)

        setCheckedClientRead(e)
        setCheckedClientCreate(e)
        setCheckedClientUpdate(e)
        setCheckedClientDelete(e)
        setValidateChange(true)
    }

    ////// Proyectos
    var permisoProjectRead
    if (permisos[4].consultar === "true") {
        permisoProjectRead = true
    } else {
        permisoProjectRead = false
    }
    var permisoProjectCreate
    if (permisos[4].permisos[0] === "Crear") {
        permisoProjectCreate = true
    } else {
        permisoProjectCreate = false
    }
    var permisoProjectUpdate
    if (permisos[4].permisos[1] === "Editar") {
        permisoProjectUpdate = true
    } else {
        permisoProjectUpdate = false
    }
    var permisoProjectDelete
    if (permisos[4].permisos[2] === "Eliminar") {
        permisoProjectDelete = true
    } else {
        permisoProjectDelete = false
    }
    var selectAllProject
    if (permisoProjectRead === true && permisoProjectCreate === true && permisoProjectUpdate === true && permisoProjectDelete === true) {
        selectAllProject = true
    }

    const [checkedProjectRead, setCheckedProjectRead] = useState(permisoProjectRead);
    const [checkedProjectCreate, setCheckedProjectCreate] = useState(permisoProjectCreate);
    const [checkedProjectUpdate, setCheckedProjectUpdate] = useState(permisoProjectUpdate);
    const [checkedProjectDelete, setCheckedProjectDelete] = useState(permisoProjectDelete);
    const [checkedProjectSelectAll, setCheckedProjectSelectAll] = useState(selectAllProject);

    const onProjectReadChange = (e) => {
        if (checkedProjectCreate === true || checkedProjectUpdate === true || checkedProjectDelete === true) {
            setCheckedProjectRead(true)
        } else {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }
    const onProjectCreateChange = (e) => {
        setCheckedProjectCreate(e)
        if (checkedProjectRead === false) {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }
    const onProjectUpdateChange = (e) => {
        setCheckedProjectUpdate(e)
        if (checkedProjectRead === false) {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }
    const onProjectDeleteChange = (e) => {
        setCheckedProjectDelete(e)
        if (checkedProjectRead === false) {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllProjectChange = (e) => {
        setCheckedProjectSelectAll(e)

        setCheckedProjectRead(e)
        setCheckedProjectCreate(e)
        setCheckedProjectUpdate(e)
        setCheckedProjectDelete(e)
        setValidateChange(true)
    }

    ////Aplicación
    var permisoAplicationRead
    if (permisos[5].consultar === "true") {
        permisoAplicationRead = true
    } else {
        permisoAplicationRead = false
    }
    var permisoAplicationCreate
    if (permisos[5].permisos[0] === "Crear") {
        permisoAplicationCreate = true
    } else {
        permisoAplicationCreate = false
    }
    var permisoAplicationUpdate
    if (permisos[5].permisos[1] === "Editar") {
        permisoAplicationUpdate = true
    } else {
        permisoAplicationUpdate = false
    }
    var permisoAplicationDelete
    if (permisos[5].permisos[2] === "Eliminar") {
        permisoAplicationDelete = true
    } else {
        permisoAplicationDelete = false
    }
    var selectAllAplication
    if (permisoAplicationRead === true && permisoAplicationCreate === true && permisoAplicationUpdate === true && permisoAplicationDelete === true) {
        selectAllAplication = true
    }

    const [checkedAplicationRead, setCheckedAplicationRead] = useState(permisoAplicationRead);
    const [checkedAplicationCreate, setCheckedAplicationCreate] = useState(permisoAplicationCreate);
    const [checkedAplicationUpdate, setCheckedAplicationUpdate] = useState(permisoAplicationUpdate);
    const [checkedAplicationDelete, setCheckedAplicationDelete] = useState(permisoAplicationDelete);
    const [checkedAplicationSelectAll, setCheckedAplicationSelectAll] = useState(selectAllAplication);

    const onAplicationReadChange = (e) => {
        if (checkedAplicationCreate === true || checkedAplicationUpdate === true || checkedAplicationDelete === true) {
            setCheckedAplicationRead(true)
        } else {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }
    const onAplicationCreateChange = (e) => {
        setCheckedAplicationCreate(e)
        if (checkedAplicationRead === false) {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }
    const onAplicationUpdateChange = (e) => {
        setCheckedAplicationUpdate(e)
        if (checkedAplicationRead === false) {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }
    const onAplicationDeleteChange = (e) => {
        setCheckedAplicationDelete(e)
        if (checkedAplicationRead === false) {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllAplicationChange = (e) => {
        setCheckedAplicationSelectAll(e)

        setCheckedAplicationRead(e)
        setCheckedAplicationCreate(e)
        setCheckedAplicationUpdate(e)
        setCheckedAplicationDelete(e)
        setValidateChange(true)
    }

    /////// Areas de negocio
    var permisoBusinessAreasRead
    if (permisos[6].consultar === "true") {
        permisoBusinessAreasRead = true
    } else {
        permisoBusinessAreasRead = false
    }
    var permisoBusinessAreasCreate
    if (permisos[6].permisos[0] === "Crear") {
        permisoBusinessAreasCreate = true
    } else {
        permisoBusinessAreasCreate = false
    }
    var permisoBusinessAreasUpdate
    if (permisos[6].permisos[1] === "Editar") {
        permisoBusinessAreasUpdate = true
    } else {
        permisoBusinessAreasUpdate = false
    }
    var permisoBusinessAreasDelete
    if (permisos[6].permisos[2] === "Eliminar") {
        permisoBusinessAreasDelete = true
    } else {
        permisoBusinessAreasDelete = false
    }
    var selectAllBusinessAreas
    if (permisoBusinessAreasRead === true && permisoBusinessAreasCreate === true && permisoBusinessAreasUpdate === true && permisoBusinessAreasDelete === true) {
        selectAllBusinessAreas = true
    }

    const [checkedBusinessAreasRead, setCheckedBusinessAreasRead] = useState(permisoBusinessAreasRead);
    const [checkedBusinessAreasCreate, setCheckedBusinessAreasCreate] = useState(permisoBusinessAreasCreate);
    const [checkedBusinessAreasUpdate, setCheckedBusinessAreasUpdate] = useState(permisoBusinessAreasUpdate);
    const [checkedBusinessAreasDelete, setCheckedBusinessAreasDelete] = useState(permisoBusinessAreasDelete);
    const [checkedBusinessAreasSelectAll, setCheckedBusinessAreasSelectAll] = useState(selectAllBusinessAreas);

    const onBusinessAreasReadChange = (e) => {
        if (checkedBusinessAreasCreate === true || checkedBusinessAreasUpdate === true || checkedBusinessAreasDelete === true) {
            setCheckedBusinessAreasRead(true)
        } else {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }
    const onBusinessAreasCreateChange = (e) => {
        setCheckedBusinessAreasCreate(e)
        if (checkedBusinessAreasRead === false) {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }
    const onBusinessAreasUpdateChange = (e) => {
        setCheckedBusinessAreasUpdate(e)
        if (checkedBusinessAreasRead === false) {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }
    const onBusinessAreasDeleteChange = (e) => {
        setCheckedBusinessAreasDelete(e)
        if (checkedBusinessAreasRead === false) {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllBusinessAreasChange = (e) => {
        setCheckedBusinessAreasSelectAll(e)

        setCheckedBusinessAreasRead(e)
        setCheckedBusinessAreasCreate(e)
        setCheckedBusinessAreasUpdate(e)
        setCheckedBusinessAreasDelete(e)
        setValidateChange(true)
    }


    ////// tipo de actividad
    var permisoActivityTypeRead
    if (permisos[7].consultar === "true") {
        permisoActivityTypeRead = true
    } else {
        permisoActivityTypeRead = false
    }
    var permisoActivityTypeCreate
    if (permisos[7].permisos[0] === "Crear") {
        permisoActivityTypeCreate = true
    } else {
        permisoActivityTypeCreate = false
    }
    var permisoActivityTypeUpdate
    if (permisos[7].permisos[1] === "Editar") {
        permisoActivityTypeUpdate = true
    } else {
        permisoActivityTypeUpdate = false
    }
    var permisoActivityTypeDelete
    if (permisos[7].permisos[2] === "Eliminar") {
        permisoActivityTypeDelete = true
    } else {
        permisoActivityTypeDelete = false
    }
    var selectAllActivityType
    if (permisoActivityTypeRead === true && permisoActivityTypeCreate === true && permisoActivityTypeUpdate === true && permisoActivityTypeDelete === true) {
        selectAllActivityType = true
    }

    const [checkedActivityTypeRead, setCheckedActivityTypeRead] = useState(permisoActivityTypeRead);
    const [checkedActivityTypeCreate, setCheckedActivityTypeCreate] = useState(permisoActivityTypeCreate);
    const [checkedActivityTypeUpdate, setCheckedActivityTypeUpdate] = useState(permisoActivityTypeUpdate);
    const [checkedActivityTypeDelete, setCheckedActivityTypeDelete] = useState(permisoActivityTypeDelete);
    const [checkedActivityTypeSelectAll, setCheckedActivityTypeSelectAll] = useState(selectAllActivityType);

    const onActivityTypeReadChange = (e) => {
        if (checkedActivityTypeCreate === true || checkedActivityTypeUpdate === true || checkedActivityTypeDelete === true) {
            setCheckedActivityTypeRead(true)
        } else {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }
    const onActivityTypeCreateChange = (e) => {
        setCheckedActivityTypeCreate(e)
        if (checkedActivityTypeRead === false) {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }
    const onActivityTypeUpdateChange = (e) => {
        setCheckedActivityTypeUpdate(e)
        if (checkedActivityTypeRead === false) {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }
    const onActivityTypeDeleteChange = (e) => {
        setCheckedActivityTypeDelete(e)
        if (checkedActivityTypeRead === false) {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllActivityTypeChange = (e) => {
        setCheckedActivityTypeSelectAll(e)

        setCheckedActivityTypeRead(e)
        setCheckedActivityTypeCreate(e)
        setCheckedActivityTypeUpdate(e)
        setCheckedActivityTypeDelete(e)
        setValidateChange(true)
    }

    ////// cargos
    var permisoPositionRead
    if (permisos[8].consultar === "true") {
        permisoPositionRead = true
    } else {
        permisoPositionRead = false
    }
    var permisoPositionCreate
    if (permisos[8].permisos[0] === "Crear") {
        permisoPositionCreate = true
    } else {
        permisoPositionCreate = false
    }
    var permisoPositionUpdate
    if (permisos[8].permisos[1] === "Editar") {
        permisoPositionUpdate = true
    } else {
        permisoPositionUpdate = false
    }
    var permisoPositionDelete
    if (permisos[8].permisos[2] === "Eliminar") {
        permisoPositionDelete = true
    } else {
        permisoPositionDelete = false
    }
    var selectAllPosition
    if (permisoPositionRead === true && permisoPositionCreate === true && permisoPositionUpdate === true && permisoPositionDelete === true) {
        selectAllPosition = true
    }

    const [checkedPositionRead, setCheckedPositionRead] = useState(permisoPositionRead);
    const [checkedPositionCreate, setCheckedPositionCreate] = useState(permisoPositionCreate);
    const [checkedPositionUpdate, setCheckedPositionUpdate] = useState(permisoPositionUpdate);
    const [checkedPositionDelete, setCheckedPositionDelete] = useState(permisoPositionDelete);
    const [checkedPositionSelectAll, setCheckedPositionSelectAll] = useState(selectAllPosition);

    const onPositionReadChange = (e) => {
        if (checkedPositionCreate === true || checkedPositionUpdate === true || checkedPositionDelete === true) {
            setCheckedPositionRead(true)
        } else {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }
    const onPositionCreateChange = (e) => {
        setCheckedPositionCreate(e)
        if (checkedPositionRead === false) {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }
    const onPositionUpdateChange = (e) => {
        setCheckedPositionUpdate(e)
        if (checkedPositionRead === false) {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }
    const onPositionDeleteChange = (e) => {
        setCheckedPositionDelete(e)
        if (checkedPositionRead === false) {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllPositionChange = (e) => {
        setCheckedPositionSelectAll(e)

        setCheckedPositionRead(e)
        setCheckedPositionCreate(e)
        setCheckedPositionUpdate(e)
        setCheckedPositionDelete(e)
        setValidateChange(true)
    }


    ///roles
    var permisoRolRead
    if (permisos[9].consultar === "true") {
        permisoRolRead = true
    } else {
        permisoRolRead = false
    }
    var permisoRolCreate
    if (permisos[9].permisos[0] === "Crear") {
        permisoRolCreate = true
    } else {
        permisoRolCreate = false
    }
    var permisoRolUpdate
    if (permisos[9].permisos[1] === "Editar") {
        permisoRolUpdate = true
    } else {
        permisoRolUpdate = false
    }
    var permisoRolDelete
    if (permisos[9].permisos[2] === "Eliminar") {
        permisoRolDelete = true
    } else {
        permisoRolDelete = false
    }
    var selectAllRol
    if (permisoRolRead === true && permisoRolCreate === true && permisoRolUpdate === true && permisoRolDelete === true) {
        selectAllRol = true
    }
    const [checkedRolRead, setCheckedRolRead] = useState(permisoRolRead);
    const [checkedRolCreate, setCheckedRolCreate] = useState(permisoRolCreate);
    const [checkedRolUpdate, setCheckedRolUpdate] = useState(permisoRolUpdate);
    const [checkedRolDelete, setCheckedRolDelete] = useState(permisoRolDelete);
    const [checkedRolSelectAll, setCheckedRolSelectAll] = useState(selectAllRol);

    const onRolReadChange = (e) => {
        if (checkedRolCreate === true || checkedRolUpdate === true || checkedRolDelete === true) {
            setCheckedRolRead(true)
        } else {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }
    const onRolCreateChange = (e) => {
        setCheckedRolCreate(e)
        if (checkedRolRead === false) {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }
    const onRolUpdateChange = (e) => {
        setCheckedRolUpdate(e)
        if (checkedRolRead === false) {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }
    const onRolDeleteChange = (e) => {
        setCheckedRolDelete(e)
        if (checkedRolRead === false) {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllRolChange = (e) => {
        setCheckedRolSelectAll(e)

        setCheckedRolRead(e)
        setCheckedRolCreate(e)
        setCheckedRolUpdate(e)
        setCheckedRolDelete(e)
        setValidateChange(true)
    }


    ///Calendario
    var permisoCalendarRead
    if (permisos[10].consultarCalendario === "true") {
        permisoCalendarRead = true
    } else {
        permisoCalendarRead = false
    }
    var permisoCalendarManage
    if (permisos[10].permisos[0] === "Parametrizar") {
        permisoCalendarManage = true
    } else {
        permisoCalendarManage = false
    }

    var selectAllCalendar
    if (permisoCalendarRead === true && permisoCalendarManage === true) {
        selectAllCalendar = true
    }
    const [checkedCalendarRead, setCheckedCalendarRead] = useState(permisoCalendarRead);
    const [checkedCalendarManage, setCheckedCalendarManage] = useState(permisoCalendarManage);
    const [checkedCalendarSelectAll, setCheckedCalendarSelectAll] = useState(selectAllCalendar);

    const onCalendarReadChange = (e) => {
        if (checkedCalendarManage === true) {
            setCheckedCalendarRead(true)
        } else {
            setCheckedCalendarRead(e)
        }
        setValidateChange(true)

    }
    const onCalendarManageChange = (e) => {
        setCheckedCalendarManage(e)
        if (checkedCalendarRead === false) {
            setCheckedCalendarRead(e)
        }
        setValidateChange(true)
    }

    const onSelectAllCalendarChange = (e) => {
        setCheckedCalendarSelectAll(e)

        setCheckedCalendarRead(e)
        setCheckedCalendarManage(e)
        setValidateChange(true)
    }

    ///Descargar Zip
    var permisoDescargarZip
    if (permisos[11].DescargarZip === "true") {
        permisoDescargarZip = true
    } else {
        permisoDescargarZip = false
    }

    const [checkedDownloadZip, setCheckedDownloadZip] = useState(permisoDescargarZip);
    const [checkedDownloadZipAll, setCheckedDownloadZipSelectAll] = useState(permisoDescargarZip);

    const onDownloadZipChange = (e) => {

        setCheckedDownloadZip(e)

        setValidateChange(true)

    }
    const onSelectAllDownloadZipChange = (e) => {
        setCheckedDownloadZipSelectAll(e)

        setCheckedDownloadZip(e)
        setValidateChange(true)
    }

    ///Procesos de cierre
    var permisoRunClose
    if (permisos[12].permisos[1] === "CierreDefinitivo") {
        permisoRunClose = true
    } else {
        permisoRunClose = false
    }

    var permisoCloseOpen
    if (permisos[12].permisos[0] === "AbrirMes") {
        permisoCloseOpen = true
    } else {
        permisoCloseOpen = false
    }

    var selectAllCloses
    if (permisoRunClose === true && permisoCloseOpen === true) {
        selectAllCloses = true
    }

    const [checkedRunClose, setCheckedRunClose] = useState(permisoRunClose);
    const [checkedCloseOpen, setCheckedCloseOpen] = useState(permisoCloseOpen);
    const [checkedNotification, setCheckedNotification] = useState(checkedNotificationProp);
    const [checkedClosesSelectAll, setCheckedClosesSelectAll] = useState(selectAllCloses);

    const onRunCloseChange = (e) => {
        if (checkedCloseOpen === true) {
            setCheckedRunClose(true)
        } else {
            setCheckedRunClose(e)
        }
        setValidateChange(true)

    }
    const onCloseOpenChange = (e) => {
        setCheckedCloseOpen(e)
        if (checkedCloseOpen === false) {
            setCheckedRunClose(e)
        }
        setValidateChange(true)
    }

    const onNotificationChange = (e) => {
        setCheckedNotification(e)
        setValidateChange(true)
    }

    const onSelectAllClosesChange = (e) => {
        setCheckedClosesSelectAll(e)
        setCheckedRunClose(e)
        setCheckedCloseOpen(e)
        setValidateChange(true)
    }

    ///Historico
    var permisoHistorico
    if (permisos[14].Historico === "true") {
        permisoHistorico = true
    } else {
        permisoHistorico = false
    }
    const [checkedHistorical, setCheckedHistorical] = useState(permisoHistorico);
    const [checkedHistoricalAll, setCheckedHistoricalSelectAll] = useState(permisoHistorico);

    const onHistoricalChange = (e) => {
        setCheckedHistorical(e)
        setValidateChange(true)
    }
    const onSelectAllHistoricalChange = (e) => {
        setCheckedHistoricalSelectAll(e)
        setCheckedHistorical(e)
        setValidateChange(true)
    }

    ///DashBoards
    var permisoDBActivityLogger
    if (permisos[13].DashBoards === "true") {
        permisoDBActivityLogger = true
    } else {
        permisoDBActivityLogger = false
    }
    var permisoDBWorkload
    if (permisos[13].permisos[0] === "DB1") {
        permisoDBWorkload = true
    } else {
        permisoDBWorkload = false
    }
    var permisoDBDetailTypeActivity
    if (permisos[13].permisos[1] === "DB2") {
        permisoDBDetailTypeActivity = true
    } else {
        permisoDBDetailTypeActivity = false
    }
    var permisoDBDetailTypeActivityUser
    if (permisos[13].permisos[2] === "DB3") {
        permisoDBDetailTypeActivityUser = true
    } else {
        permisoDBDetailTypeActivityUser = false
    }
    var selectAllDB
    if (permisoDBActivityLogger === true && permisoDBWorkload === true && permisoDBDetailTypeActivity === true && permisoDBDetailTypeActivityUser === true) {
        selectAllDB = true
    } else {
        selectAllDB = false
    }
    const [checkedDBActivityLogger, setCheckedDBActivityLogger] = useState(permisoDBActivityLogger);
    const [checkedDBWorkload, setCheckedDBWorkload] = useState(permisoDBWorkload);
    const [checkedDBDetailTypeActivity, setCheckedDBDetailTypeActivity] = useState(permisoDBDetailTypeActivity);
    const [checkedDBDetailTypeActivityUser, setCheckedDBDetailTypeActivityUser] = useState(permisoDBDetailTypeActivityUser);
    const [checkedDBSelectAll, setCheckedDBSelectAll] = useState(selectAllDB);

    const onDBActivityLoggerChange = (e) => {

        setCheckedDBActivityLogger(e)

        setValidateChange(true)

    }
    const onDBWorkloadChange = (e) => {
        setCheckedDBWorkload(e)

        setValidateChange(true)

    }
    const onDBDetailTypeActivityChange = (e) => {
        setCheckedDBDetailTypeActivity(e)

        setValidateChange(true)

    }
    const onDBDetailTypeActivityUserChange = (e) => {
        setCheckedDBDetailTypeActivityUser(e)

        setValidateChange(true)

    }

    const onSelectAllDBChange = (e) => {
        setCheckedDBSelectAll(e)

        setCheckedDBActivityLogger(e)
        setCheckedDBWorkload(e)
        setCheckedDBDetailTypeActivity(e)
        setCheckedDBDetailTypeActivityUser(e)
        setValidateChange(true)
    }

    ////// Permisos adicionales para powerbi

    var permisoDBReadPowerBi
    if (permisos[22].DBPowerBI === "true") {
        permisoDBReadPowerBi = true
    } else {
        permisoDBReadPowerBi = false
    }
    var permisoDBPlusPowerBi
    if (permisos[22].permisos[0] === "plusPowerBi") {
        permisoDBPlusPowerBi = true
    } else {
        permisoDBPlusPowerBi = false
    }
    var permisoDBPowerBiClient
    if (permisos[22].permisos[1] === "powerBiClient") {
        permisoDBPowerBiClient = true
    } else {
        permisoDBPowerBiClient = false
    }
    var selectAllDBPowerBi
    if (permisoDBReadPowerBi === true && permisoDBPowerBiClient === true && permisoDBPlusPowerBi === true) {
        selectAllDBPowerBi = true
    } else {
        selectAllDBPowerBi = false
    }
    const [checkedDBReadPowerBi, setCheckedDBReadPowerBi] = useState(permisoDBReadPowerBi);
    const [checkedDBPowerBiClient, setCheckedDBPowerBiClient] = useState(permisoDBPowerBiClient);
    const [checkedDBPlusPowerBi, setCheckedDBPlusPowerBi] = useState(permisoDBPlusPowerBi);
    const [checkedDBPlusPowerBiSelectAll, setCheckedDBPlusPowerBiSelectAll] = useState(selectAllDBPowerBi);

    const onDBReadPowerBiChange = (e) => {
        setCheckedDBReadPowerBi(e)
        setValidateChange(true)
    }

    const onDBPowerBiClientChange = (e) => {
        setCheckedDBPowerBiClient(e)
        setValidateChange(true)
    }

    const onDBPlusPowerBiChange = (e) => {
        setCheckedDBPlusPowerBi(e)
        setValidateChange(true)
    }

    const onSelectAllDBPlusPowerBiChange = (e) => {
        setCheckedDBReadPowerBi(e)
        setCheckedDBPlusPowerBi(e)
        setCheckedDBPowerBiClient(e)
        setCheckedDBPlusPowerBiSelectAll(e)
        setValidateChange(true)
    }


    ////// novedades otro funcionario 
    var permisoNoveltyAnotherOfficial
    if (permisos[1].permisos[3] === "NovedadOtroFucionario") {
        permisoNoveltyAnotherOfficial = true
    } else {
        permisoNoveltyAnotherOfficial = false
    }

    var selectAllNoveltyAnotherOfficial
    if (permisoNoveltyAnotherOfficial === true) {
        selectAllNoveltyAnotherOfficial = true
    }

    const [checkedNoveltyAnotherOfficial, setCheckedNoveltyAnotherOfficial] = useState(permisoNoveltyAnotherOfficial);
    const [checkedNoveltyAnotherOfficialSelectAll, setCheckedNoveltyAnotherOfficialBSelectAll] = useState(selectAllNoveltyAnotherOfficial);

    const onNoveltyAnotherOfficialChange = (e) => {
        setCheckedNoveltyAnotherOfficial(e)
        setValidateChange(true)

    }

    const onSelectAllNoveltyAnotherOfficialChange = (e) => {
        setCheckedNoveltyAnotherOfficial(e)
        setCheckedNoveltyAnotherOfficialBSelectAll(e)
        setValidateChange(true)
    }

    ///Informe mensual individual
    var permisoIndividualMonthlyReport
    if (permisos[15].consultar === "true") {
        permisoIndividualMonthlyReport = true
    } else {
        permisoIndividualMonthlyReport = false
    }
    var permisoIndividualMonthlyReportOtherOfficial
    if (permisos[15].permisos[0] === "true") {
        permisoIndividualMonthlyReportOtherOfficial = true
    } else {
        permisoIndividualMonthlyReportOtherOfficial = false
    }
    var permisoIndividualMonthlyReopen
    if (permisos[15].permisos[1] === "true") {
        permisoIndividualMonthlyReopen = true
    } else {
        permisoIndividualMonthlyReopen = false
    }

    var selectAllIndividualMonthlyReport
    if (permisoIndividualMonthlyReport === true && permisoIndividualMonthlyReportOtherOfficial === true && permisoIndividualMonthlyReopen === true) {
        selectAllIndividualMonthlyReport = true
    }

    const [checkedindividualMonthlyReportRead, setCheckedindividualMonthlyReportRead] = useState(permisoIndividualMonthlyReport);
    const [checkedindividualMonthlyReportReadOtherOfficial, setCheckedindividualMonthlyReportReadOtherOfficial] = useState(permisoIndividualMonthlyReportOtherOfficial);
    const [checkedindividualMonthlyReportReopen, setCheckedindividualMonthlyReportReopen] = useState(permisoIndividualMonthlyReopen);
    const [checkedindividualMonthlyReportSelectAll, setCheckedindividualMonthlyReportSelectAll] = useState(selectAllIndividualMonthlyReport);

    const onindividualMonthlyReportReadChange = (e) => {
        setCheckedindividualMonthlyReportRead(e)
        setValidateChange(true)
    }

    const onindividualMonthlyReportReadOtherOfficialChange = (e) => {
        setCheckedindividualMonthlyReportRead(e)
        setCheckedindividualMonthlyReportReadOtherOfficial(e)
        setValidateChange(true)
    }

    const onindividualMonthlyReportReopenChange = (e) => {
        setCheckedindividualMonthlyReportRead(e)
        setCheckedindividualMonthlyReportReopen(e)
        setValidateChange(true)
    }

    const onSelectAllindividualMonthlyReportChange = (e) => {
        setCheckedindividualMonthlyReportSelectAll(e)
        setCheckedindividualMonthlyReportRead(e)
        setCheckedindividualMonthlyReportReadOtherOfficial(e)
        setValidateChange(true)
    }

    ///Personas interesadas
    var permisoInterestedPersonRead
    if (permisos[16].consultar === "true") {
        permisoInterestedPersonRead = true
    } else {
        permisoInterestedPersonRead = false
    }
    var permisoInterestedPersonCreate
    if (permisos[16].permisos[0] === "Crear") {
        permisoInterestedPersonCreate = true
    } else {
        permisoInterestedPersonCreate = false
    }
    var permisoInterestedPersonUpdate
    if (permisos[16].permisos[1] === "Editar") {
        permisoInterestedPersonUpdate = true
    } else {
        permisoInterestedPersonUpdate = false
    }
    var permisoInterestedPersonDelete
    if (permisos[16].permisos[2] === "Eliminar") {
        permisoInterestedPersonDelete = true
    } else {
        permisoInterestedPersonDelete = false
    }
    var selectAllInterestedPerson
    if (permisoInterestedPersonRead === true && permisoInterestedPersonCreate === true && permisoInterestedPersonUpdate === true && permisoInterestedPersonDelete === true) {
        selectAllInterestedPerson = true
    }

    const [checkedInterestedPersonRead, setCheckedInterestedPersonRead] = useState(permisoInterestedPersonRead);
    const [checkedInterestedPersonCreate, setCheckedInterestedPersonCreate] = useState(permisoInterestedPersonCreate);
    const [checkedInterestedPersonUpdate, setCheckedInterestedPersonUpdate] = useState(permisoInterestedPersonUpdate);
    const [checkedInterestedPersonDelete, setCheckedInterestedPersonDelete] = useState(permisoInterestedPersonDelete);
    const [checkedInterestedPersonSelectAll, setCheckedInterestedPersonSelectAll] = useState(selectAllInterestedPerson);

    const onInterestedPersonReadChange = (e) => {
        if (checkedInterestedPersonCreate === true || checkedInterestedPersonUpdate === true || checkedInterestedPersonDelete === true) {
            setCheckedInterestedPersonRead(true)
        } else {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }
    const onInterestedPersonCreateChange = (e) => {
        setCheckedInterestedPersonCreate(e)
        if (checkedInterestedPersonRead === false) {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }
    const onInterestedPersonUpdateChange = (e) => {
        setCheckedInterestedPersonUpdate(e)
        if (checkedInterestedPersonRead === false) {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }
    const onInterestedPersonDeleteChange = (e) => {
        setCheckedInterestedPersonDelete(e)
        if (checkedInterestedPersonRead === false) {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllInterestedPersonChange = (e) => {
        setCheckedInterestedPersonSelectAll(e)

        setCheckedInterestedPersonRead(e)
        setCheckedInterestedPersonCreate(e)
        setCheckedInterestedPersonUpdate(e)
        setCheckedInterestedPersonDelete(e)
        setValidateChange(true)
    }

    /// Objetivos
    var permisoObjectiveRead
    if (permisos[17].consultar === "true") {
        permisoObjectiveRead = true
    } else {
        permisoObjectiveRead = false
    }
    var permisoObjectiveCreate
    if (permisos[17].permisos[0] === "Crear") {
        permisoObjectiveCreate = true
    } else {
        permisoObjectiveCreate = false
    }
    var permisoObjectiveUpdate
    if (permisos[17].permisos[1] === "Editar") {
        permisoObjectiveUpdate = true
    } else {
        permisoObjectiveUpdate = false
    }
    var permisoObjectiveDelete
    if (permisos[17].permisos[2] === "Eliminar") {
        permisoObjectiveDelete = true
    } else {
        permisoObjectiveDelete = false
    }
    var selectAllObjective
    if (permisoObjectiveRead === true && permisoObjectiveCreate === true && permisoObjectiveUpdate === true && permisoObjectiveDelete === true) {
        selectAllObjective = true
    }

    const [checkedObjectiveRead, setCheckedObjectiveRead] = useState(permisoObjectiveRead);
    const [checkedObjectiveCreate, setCheckedObjectiveCreate] = useState(permisoObjectiveCreate);
    const [checkedObjectiveUpdate, setCheckedObjectiveUpdate] = useState(permisoObjectiveUpdate);
    const [checkedObjectiveDelete, setCheckedObjectiveDelete] = useState(permisoObjectiveDelete);
    const [checkedObjectiveSelectAll, setCheckedObjectiveSelectAll] = useState(selectAllObjective);

    const onObjectiveReadChange = (e) => {
        if (checkedObjectiveCreate === true || checkedObjectiveUpdate === true || checkedObjectiveDelete === true) {
            setCheckedObjectiveRead(true)
        } else {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }
    const onObjectiveCreateChange = (e) => {
        setCheckedObjectiveCreate(e)
        if (checkedObjectiveRead === false) {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }
    const onObjectiveUpdateChange = (e) => {
        setCheckedObjectiveUpdate(e)
        if (checkedObjectiveRead === false) {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }
    const onObjectiveDeleteChange = (e) => {
        setCheckedObjectiveDelete(e)
        if (checkedObjectiveRead === false) {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllObjectiveChange = (e) => {
        setCheckedObjectiveSelectAll(e)
        setCheckedObjectiveRead(e)
        setCheckedObjectiveCreate(e)
        setCheckedObjectiveUpdate(e)
        setCheckedObjectiveDelete(e)
        setValidateChange(true)
    }

    /// Contratos
    var permisoContractRead
    if (permisos[18].consultar === "true") {
        permisoContractRead = true
    } else {
        permisoContractRead = false
    }
    var permisoContractCreate
    if (permisos[18].permisos[0] === "Crear") {
        permisoContractCreate = true
    } else {
        permisoContractCreate = false
    }
    var permisoContractUpdate
    if (permisos[18].permisos[1] === "Editar") {
        permisoContractUpdate = true
    } else {
        permisoContractUpdate = false
    }
    var permisoContractDelete
    if (permisos[18].permisos[2] === "Eliminar") {
        permisoContractDelete = true
    } else {
        permisoContractDelete = false
    }
    var selectAllContract
    if (permisoContractRead === true && permisoContractCreate === true && permisoContractUpdate === true && permisoContractDelete === true) {
        selectAllContract = true
    }
    const [checkedContractRead, setCheckedContractRead] = useState(permisoContractRead);
    const [checkedContractCreate, setCheckedContractCreate] = useState(permisoContractCreate);
    const [checkedContractUpdate, setCheckedContractUpdate] = useState(permisoContractUpdate);
    const [checkedContractDelete, setCheckedContractDelete] = useState(permisoContractDelete);
    const [checkedContractSelectAll, setCheckedContractSelectAll] = useState(selectAllContract);
    const onContractReadChange = (e) => {
        if (checkedContractCreate === true || checkedContractUpdate === true || checkedContractDelete === true) {
            setCheckedContractRead(true)
        } else {
            setCheckedContractRead(e)
        }
        setValidateChange(true)
    }
    const onContractCreateChange = (e) => {
        setCheckedContractCreate(e)
        if (checkedContractRead === false) {
            setCheckedContractRead(e)
        }
        setValidateChange(true)
    }
    const onContractUpdateChange = (e) => {
        setCheckedContractUpdate(e)
        if (checkedContractRead === false) {
            setCheckedContractRead(e)
        }
        setValidateChange(true)
    }
    const onContractDeleteChange = (e) => {
        setCheckedContractDelete(e)
        if (checkedContractRead === false) {
            setCheckedContractRead(e)
        }
        setValidateChange(true)
    }
    const onSelectAllContractChange = (e) => {
        setCheckedContractSelectAll(e)
        setCheckedContractRead(e)
        setCheckedContractCreate(e)
        setCheckedContractUpdate(e)
        setCheckedContractDelete(e)
        setValidateChange(true)
    }

    /// Entregables
    var permisoDeliverableRead
    if (permisos[19].consultar === "true") {
        permisoDeliverableRead = true
    } else {
        permisoDeliverableRead = false
    }
    var permisoDeliverableCreate
    if (permisos[19].permisos[0] === "Crear") {
        permisoDeliverableCreate = true
    } else {
        permisoDeliverableCreate = false
    }
    var permisoDeliverableUpdate
    if (permisos[19].permisos[1] === "Editar") {
        permisoDeliverableUpdate = true
    } else {
        permisoDeliverableUpdate = false
    }
    var permisoDeliverableDelete
    if (permisos[19].permisos[2] === "Eliminar") {
        permisoDeliverableDelete = true
    } else {
        permisoDeliverableDelete = false
    }
    var selectAllDeliverable
    if (permisoDeliverableRead === true && permisoDeliverableCreate === true && permisoDeliverableUpdate === true && permisoDeliverableDelete === true) {
        selectAllDeliverable = true
    }

    const [checkedDeliverableRead, setCheckedDeliverableRead] = useState(permisoDeliverableRead);
    const [checkedDeliverableCreate, setCheckedDeliverableCreate] = useState(permisoDeliverableCreate);
    const [checkedDeliverableUpdate, setCheckedDeliverableUpdate] = useState(permisoDeliverableUpdate);
    const [checkedDeliverableDelete, setCheckedDeliverableDelete] = useState(permisoDeliverableDelete);
    const [checkedDeliverableSelectAll, setCheckedDeliverableSelectAll] = useState(selectAllDeliverable);

    const onDeliverableReadChange = (e) => {
        if (checkedDeliverableCreate === true || checkedDeliverableUpdate === true || checkedDeliverableDelete === true) {
            setCheckedDeliverableRead(true)
        } else {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }
    const onDeliverableCreateChange = (e) => {
        setCheckedDeliverableCreate(e)
        if (checkedDeliverableRead === false) {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }
    const onDeliverableUpdateChange = (e) => {
        setCheckedDeliverableUpdate(e)
        if (checkedDeliverableRead === false) {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }
    const onDeliverableDeleteChange = (e) => {
        setCheckedDeliverableDelete(e)
        if (checkedDeliverableRead === false) {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllDeliverableChange = (e) => {
        setCheckedDeliverableSelectAll(e)
        setCheckedDeliverableRead(e)
        setCheckedDeliverableCreate(e)
        setCheckedDeliverableUpdate(e)
        setCheckedDeliverableDelete(e)
        setValidateChange(true)
    }

    /// Servicios

    var permisoServiceRead
    if (permisos[20].consultar === "true") {
        permisoServiceRead = true
    } else {
        permisoServiceRead = false
    }
    var permisoServiceCreate
    if (permisos[20].permisos[0] === "Crear") {
        permisoServiceCreate = true
    } else {
        permisoServiceCreate = false
    }
    var permisoServiceUpdate
    if (permisos[20].permisos[1] === "Editar") {
        permisoServiceUpdate = true
    } else {
        permisoServiceUpdate = false
    }
    var permisoServiceDelete
    if (permisos[20].permisos[2] === "Eliminar") {
        permisoServiceDelete = true
    } else {
        permisoServiceDelete = false
    }

    var selectAllService
    if (permisoServiceRead === true && permisoServiceCreate === true && permisoServiceUpdate === true && permisoServiceDelete === true) {
        selectAllService = true
    }

    const [checkedServiceRead, setCheckedServiceRead] = useState(permisoServiceRead);
    const [checkedServiceCreate, setCheckedServiceCreate] = useState(permisoServiceCreate);
    const [checkedServiceUpdate, setCheckedServiceUpdate] = useState(permisoServiceUpdate);
    const [checkedServiceDelete, setCheckedServiceDelete] = useState(permisoServiceDelete);
    const [checkedServiceSelectAll, setCheckedServiceSelectAll] = useState(selectAllService);

    const onServiceReadChange = (e) => {
        if (checkedServiceCreate === true || checkedServiceUpdate === true || checkedServiceDelete === true) {
            setCheckedServiceRead(true)
        } else {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }
    const onServiceCreateChange = (e) => {
        setCheckedServiceCreate(e)
        if (checkedServiceRead === false) {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }
    const onServiceUpdateChange = (e) => {
        setCheckedServiceUpdate(e)
        if (checkedServiceRead === false) {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }
    const onServiceDeleteChange = (e) => {
        setCheckedServiceDelete(e)
        if (checkedServiceRead === false) {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllServiceChange = (e) => {
        setCheckedServiceSelectAll(e)
        setCheckedServiceRead(e)
        setCheckedServiceCreate(e)
        setCheckedServiceUpdate(e)
        setCheckedServiceDelete(e)
        setValidateChange(true)
    }

    //Equipos De Trabajo
    var permisoWorkTeamRead
    if (permisos[21].consultar === "true") {
        permisoWorkTeamRead = true
    } else {
        permisoWorkTeamRead = false
    }
    var permisoWorkTeamCreate
    if (permisos[21].permisos[0] === "Crear") {
        permisoWorkTeamCreate = true
    } else {
        permisoWorkTeamCreate = false
    }
    var permisoWorkTeamUpdate
    if (permisos[21].permisos[1] === "Editar") {
        permisoWorkTeamUpdate = true
    } else {
        permisoWorkTeamUpdate = false
    }
    var permisoWorkTeamDelete
    if (permisos[21].permisos[2] === "Eliminar") {
        permisoWorkTeamDelete = true
    } else {
        permisoWorkTeamDelete = false
    }

    var selectAllWorkTeam
    if (permisoWorkTeamRead === true && permisoWorkTeamCreate === true && permisoWorkTeamUpdate === true && permisoWorkTeamDelete === true) {
        selectAllWorkTeam = true
    }

    const [checkedWorkTeamRead, setCheckedWorkTeamRead] = useState(permisoWorkTeamRead);
    const [checkedWorkTeamCreate, setCheckedWorkTeamCreate] = useState(permisoWorkTeamCreate);
    const [checkedWorkTeamUpdate, setCheckedWorkTeamUpdate] = useState(permisoWorkTeamUpdate);
    const [checkedWorkTeamDelete, setCheckedWorkTeamDelete] = useState(permisoWorkTeamDelete);
    const [checkedWorkTeamSelectAll, setCheckedWorkTeamSelectAll] = useState(selectAllWorkTeam);

    const onWorkTeamReadChange = (e) => {

        if (checkedWorkTeamCreate === true || checkedWorkTeamUpdate === true || checkedWorkTeamDelete === true) {
            setCheckedWorkTeamRead(true)
        } else {
            setCheckedWorkTeamRead(e)
        }

        setValidateChange(true)

    }

    const onWorkTeamCreateChange = (e) => {
        setCheckedWorkTeamCreate(e)
        if (checkedWorkTeamRead === false) {
            setCheckedWorkTeamRead(e)
        }
        setValidateChange(true)

    }

    const onWorkTeamUpdateChange = (e) => {
        setCheckedWorkTeamUpdate(e)
        if (checkedWorkTeamRead === false) {
            setCheckedWorkTeamRead(e)
        }
        setValidateChange(true)

    }

    const onWorkTeamDeleteChange = (e) => {
        setCheckedWorkTeamDelete(e)
        if (checkedWorkTeamRead === false) {
            setCheckedWorkTeamRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllWorkTeamChange = (e) => {
        setCheckedWorkTeamSelectAll(e)
        setCheckedWorkTeamRead(e)
        setCheckedWorkTeamCreate(e)
        setCheckedWorkTeamUpdate(e)
        setCheckedWorkTeamDelete(e)
        setValidateChange(true)
    }

    //Tipos de proyectos
    var permisoTypeProjectRead
    if (permisos[23].consultar === "true") {
        permisoTypeProjectRead = true
    } else {
        permisoTypeProjectRead = false
    }
    var permisoTypeProjectCreate
    if (permisos[23].permisos[0] === "Crear") {
        permisoTypeProjectCreate = true
    } else {
        permisoTypeProjectCreate = false
    }
    var permisoTypeProjectUpdate
    if (permisos[23].permisos[1] === "Editar") {
        permisoTypeProjectUpdate = true
    } else {
        permisoTypeProjectUpdate = false
    }
    var permisoTypeProjectDelete
    if (permisos[23].permisos[2] === "Eliminar") {
        permisoTypeProjectDelete = true
    } else {
        permisoTypeProjectDelete = false
    }



    const [checkedTypeProjectRead, setCheckedTypeProjectRead] = useState(permisoTypeProjectRead);
    const [checkedTypeProjectCreate, setCheckedTypeProjectCreate] = useState(permisoTypeProjectCreate);
    const [checkedTypeProjectUpdate, setCheckedTypeProjectUpdate] = useState(permisoTypeProjectUpdate);
    const [checkedTypeProjectDelete, setCheckedTypeProjectDelete] = useState(permisoTypeProjectDelete);


    var selectAllTypeProject
    if (checkedTypeProjectRead === true && checkedTypeProjectCreate === true && checkedTypeProjectUpdate === true && checkedTypeProjectDelete === true) {
        selectAllTypeProject = true
    }

    const [checkedTypeProjectSelectAll, setCheckedTypeProjectSelectAll] = useState(selectAllTypeProject);
    const onTypeProjectReadChange = (e) => {

        if (checkedTypeProjectCreate === true || checkedTypeProjectUpdate === true || checkedTypeProjectDelete === true) {
            setCheckedTypeProjectRead(true)
        } else {
            setCheckedTypeProjectRead(e)
        }

        setValidateChange(true)
    }

    const onTypeProjectCreateChange = (e) => {
        setCheckedTypeProjectCreate(e)
        if (checkedTypeProjectRead === false) {
            setCheckedTypeProjectRead(e)
        }
        setValidateChange(true)

    }

    const onTypeProjectUpdateChange = (e) => {
        setCheckedTypeProjectUpdate(e)
        if (checkedTypeProjectRead === false) {
            setCheckedTypeProjectRead(e)
        }
        setValidateChange(true)
    }

    const onTypeProjectDeleteChange = (e) => {
        setCheckedTypeProjectDelete(e)
        if (checkedTypeProjectRead === false) {
            setCheckedTypeProjectRead(e)
        }
        setValidateChange(true)
    }

    const onSelectAllTypeProjectChange = (e) => {
        setCheckedTypeProjectSelectAll(e)
        setCheckedTypeProjectRead(e)
        setCheckedTypeProjectCreate(e)
        setCheckedTypeProjectUpdate(e)
        setCheckedTypeProjectDelete(e)
        setValidateChange(true)
    }

    //Organigrama

    var permisoOrganizationChart
    if (permisos[24].consultar === "true") {
        permisoOrganizationChart = true
    } else {
        permisoOrganizationChart = false
    }

    const [checkedOrgRead, setCheckedOrgRead] = useState(permisoOrganizationChart);
    const [checkedOrgSelectAll, setCheckedOrgSelectAll] = useState(permisoOrganizationChart);

    const onOrgChange = (e) => {

        setCheckedOrgRead(e)

        setValidateChange(true)

    }
    const onSelectAllOrgChange = (e) => {
        setCheckedOrgSelectAll(e)

        setCheckedOrgRead(e)
        setValidateChange(true)
    }

    //detalle de pendientes
    var permisoDetailPendingRead
    if (permisos[25].consultar === "true") {
        permisoDetailPendingRead = true
    } else {
        permisoDetailPendingRead = false
    }

    const [checkedDetailPendingRead, setCheckedDetailPendingRead] = useState(permisoDetailPendingRead);
    const [checkedDetailPendingSelectAll, setCheckedDetailPendingSelectAll] = useState(permisoDetailPendingRead);

    const onDetailPendingChange = (e) => {
        setCheckedDetailPendingRead(e)
        setValidateChange(true)
    }

    const onSelectAllDetailPendingChange = (e) => {
        setCheckedDetailPendingSelectAll(e)
        setCheckedDetailPendingRead(e)
        setValidateChange(true)
    }

    //Consultar usuarios de clientes

    var permisoUserClientRead
    if (permisos[26].consultar === "true") {
        permisoUserClientRead = true
    } else {
        permisoUserClientRead = false
    }

    const [checkedUserClientRead, setCheckedUserClientRead] = useState(permisoUserClientRead);
    const [checkedUserClientSelectAll, setCheckedUserClientSelectAll] = useState(permisoUserClientRead);

    const onUserClientChange = (e) => {
        setCheckedUserClientRead(e)
        setValidateChange(true)
    }

    const onSelectAllUserClientChange = (e) => {
        setCheckedUserClientSelectAll(e)
        setCheckedUserClientRead(e)
        setValidateChange(true)
    }

    //Proveedores
    var permisoSupplierRead
    if (permisos[27].consultar === "true") {
        permisoSupplierRead = true
    } else {
        permisoSupplierRead = false
    }
    var permisoSupplierCreate
    if (permisos[27].permisos[0] === "Crear") {
        permisoSupplierCreate = true
    } else {
        permisoSupplierCreate = false
    }
    var permisoSupplierUpdate
    if (permisos[27].permisos[1] === "Editar") {
        permisoSupplierUpdate = true
    } else {
        permisoSupplierUpdate = false
    }
    var permisoSupplierDelete
    if (permisos[27].permisos[2] === "Eliminar") {
        permisoSupplierDelete = true
    } else {
        permisoSupplierDelete = false
    }

    var selectAllSupplier
    if (permisoSupplierRead === true && permisoSupplierCreate === true && permisoSupplierUpdate === true && permisoSupplierDelete === true) {
        selectAllSupplier = true
    }

    const [checkedSupplierRead, setCheckedSupplierRead] = useState(permisoSupplierRead);
    const [checkedSupplierCreate, setCheckedSupplierCreate] = useState(permisoSupplierCreate);
    const [checkedSupplierUpdate, setCheckedSupplierUpdate] = useState(permisoSupplierUpdate);
    const [checkedSupplierDelete, setCheckedSupplierDelete] = useState(permisoSupplierDelete);

    const [checkedSupplierSelectAll, setCheckedSupplierSelectAll] = useState(selectAllSupplier);
    const onSupplierReadChange = (e) => {

        if (checkedSupplierCreate === true || checkedSupplierUpdate === true || checkedSupplierDelete === true) {
            setCheckedSupplierRead(true)
        } else {
            setCheckedSupplierRead(e)
        }

        setValidateChange(true)
    }

    const onSupplierCreateChange = (e) => {
        setCheckedSupplierCreate(e)
        if (checkedSupplierRead === false) {
            setCheckedSupplierRead(e)
        }
        setValidateChange(true)

    }

    const onSupplierUpdateChange = (e) => {
        setCheckedSupplierUpdate(e)
        if (checkedSupplierRead === false) {
            setCheckedSupplierRead(e)
        }
        setValidateChange(true)
    }

    const onSupplierDeleteChange = (e) => {
        setCheckedSupplierDelete(e)
        if (checkedSupplierRead === false) {
            setCheckedSupplierRead(e)
        }
        setValidateChange(true)
    }

    const onSelectAllSupplierChange = (e) => {
        setCheckedSupplierSelectAll(e)
        setCheckedSupplierRead(e)
        setCheckedSupplierCreate(e)
        setCheckedSupplierUpdate(e)
        setCheckedSupplierDelete(e)
        setValidateChange(true)
    }

    //Filtros Proveedores
    var permisoFilterSupplierNDB1
    if (permisos[28].permisos[1] === "NDB1") {
        permisoFilterSupplierNDB1 = true
    } else {
        permisoFilterSupplierNDB1 = false
    }
    var permisoFilterSupplierNDB2
    if (permisos[28].permisos[2] === "NDB2") {
        permisoFilterSupplierNDB2 = true
    } else {
        permisoFilterSupplierNDB2 = false
    }
    var permisoFilterSupplierExportData
    if (permisos[29].consultar === "true") {
        permisoFilterSupplierExportData = true
    } else {
        permisoFilterSupplierExportData = false
    }

    var selectAllFilterSupplier
    if (permisoFilterSupplierNDB1 === true && permisoFilterSupplierNDB2 === true && permisoFilterSupplierExportData === true) {
        selectAllFilterSupplier = true
    }

    const [checkedFilterSupplierNDB1, setCheckedFilterSupplierNDB1] = useState(permisoFilterSupplierNDB1);
    const [checkedFilterSupplierNDB2, setCheckedFilterSupplierNDB2] = useState(permisoFilterSupplierNDB2);
    const [checkedFilterSupplierExportData, setCheckedFilterSupplierExportData] = useState(permisoFilterSupplierExportData);
    const [checkedFilterSupplierSelectAll, setCheckedFilterSupplierSelectAll] = useState(selectAllFilterSupplier);

    const onFilterSupplierNDB1Change = (e) => {
        if (checkedFilterSupplierNDB2 === true || checkedFilterSupplierExportData === true) {
            setCheckedFilterSupplierNDB1(true)
        } else {
            setCheckedFilterSupplierNDB1(e)
        }
        setValidateChange(true)
    }

    const onFilterSupplierNDB2Change = (e) => {
        setCheckedFilterSupplierNDB2(e)
        setValidateChange(true)
    }

    const onFilterSupplierExportDataChange = (e) => {
        setCheckedFilterSupplierExportData(e)
        setValidateChange(true)
    }

    const onSelectAllFilterSupplierChange = (e) => {
        setCheckedFilterSupplierSelectAll(e)
        setCheckedFilterSupplierNDB1(e)
        setCheckedFilterSupplierNDB2(e)
        setCheckedFilterSupplierExportData(e)
        setValidateChange(true)
    }

    // Permisos para powerbi con filtro de proveedores
    var permisoDBReadPowerBiFilterSupplier
    if (permisos[30].DBPowerBISupplier === "true") {
        permisoDBReadPowerBiFilterSupplier = true
    } else {
        permisoDBReadPowerBiFilterSupplier = false
    }
    var permisoDBPlusPowerBiFilterSupplier
    if (permisos[30].permisos[0] === "plusPowerBiSupplier") {
        permisoDBPlusPowerBiFilterSupplier = true
    } else {
        permisoDBPlusPowerBiFilterSupplier = false
    }
    var permisoDBPowerBiFilterSupplierClient
    if (permisos[30].permisos[1] === "powerBiSupplierClient") {
        permisoDBPowerBiFilterSupplierClient = true
    } else {
        permisoDBPowerBiFilterSupplierClient = false
    }
    var selectAllDBPowerBiFilterSupplier
    if (permisoDBReadPowerBiFilterSupplier === true && permisoDBPowerBiFilterSupplierClient === true && permisoDBPlusPowerBiFilterSupplier === true) {
        selectAllDBPowerBiFilterSupplier = true
    } else {
        selectAllDBPowerBiFilterSupplier = false
    }
    const [checkedDBReadPowerBiFilterSupplier, setCheckedDBReadPowerBiFilterSupplier] = useState(permisoDBReadPowerBiFilterSupplier);
    const [checkedDBPowerBiFilterSupplierClient, setCheckedDBPowerBiFilterSupplierClient] = useState(permisoDBPowerBiFilterSupplierClient);
    const [checkedDBPlusPowerBiFilterSupplier, setCheckedDBPlusPowerBiFilterSupplier] = useState(permisoDBPlusPowerBiFilterSupplier);
    const [checkedDBPlusPowerBiFilterSupplierSelectAll, setCheckedDBPlusPowerBiFilterSupplierSelectAll] = useState(selectAllDBPowerBiFilterSupplier);

    const onDBReadPowerBiFilterSupplierChange = (e) => {
        setCheckedDBReadPowerBiFilterSupplier(e)
        setValidateChange(true)
    }

    const onDBPowerBiFilterSupplierClientChange = (e) => {
        setCheckedDBPowerBiFilterSupplierClient(e)
        setValidateChange(true)
    }

    const onDBPlusPowerBiFilterSupplierChange = (e) => {
        setCheckedDBPlusPowerBiFilterSupplier(e)
        setValidateChange(true)
    }

    const onSelectAllDBPlusPowerBiFilterSupplierChange = (e) => {
        setCheckedDBReadPowerBiFilterSupplier(e)
        setCheckedDBPlusPowerBiFilterSupplier(e)
        setCheckedDBPowerBiFilterSupplierClient(e)
        setCheckedDBPlusPowerBiFilterSupplierSelectAll(e)
        setValidateChange(true)
    }

    /// Permisos para el menú de cargar imagen corporativa

    let permisoReadUploadImage =false
    if (permisos[31].consultar === "true") {
        permisoReadUploadImage = true
    } else {
        permisoReadUploadImage = false
    }

    const [checkedBDReadUploadImage, setCheckedBDReadUploadImage] = useState(permisoReadUploadImage);
    const [checkedBDUploadImageSelectAll, setCheckedBDUploadImageSelectAll] = useState(permisoReadUploadImage);

    const onChangeCheckedReadUploadImage = (e) => {
        setCheckedBDReadUploadImage(e)
        setValidateChange(true)
    }
    
    const onChangeCheckedUploadImageSelectAll = (e) => {
        setCheckedBDReadUploadImage(e)
        setCheckedBDUploadImageSelectAll(e)
        setValidateChange(true)
    }
    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idRolProp={idRol}
                        usuarioProp={usuario}
                        rolProp={rol}
                        estadoProp={estado}
                        creadoporProp={creadoporProp}
                        checkedUserReadProp={checkedUserRead}
                        checkedUserCreateProp={checkedUserCreate}
                        checkedUserUpdateProp={checkedUserUpdate}
                        checkedUserDeleteProp={checkedUserDelete}
                        checkedActivityReadProp={checkedActivityRead}
                        checkedActivityCreateProp={checkedActivityCreate}
                        checkedActivityUpdateProp={checkedActivityUpdate}
                        checkedActivityDeleteProp={checkedActivityDelete}
                        checkedExportDataReadProp={checkedExportDataRead}
                        checkedExportDataAllProp={checkedExportDataAll}
                        checkedClientReadProp={checkedClientRead}
                        checkedClientCreateProp={checkedClientCreate}
                        checkedClientUpdateProp={checkedClientUpdate}
                        checkedClientDeleteProp={checkedClientDelete}
                        checkedProjectReadProp={checkedProjectRead}
                        checkedProjectCreateProp={checkedProjectCreate}
                        checkedProjectUpdateProp={checkedProjectUpdate}
                        checkedProjectDeleteProp={checkedProjectDelete}
                        checkedAplicationReadProp={checkedAplicationRead}
                        checkedAplicationCreateProp={checkedAplicationCreate}
                        checkedAplicationUpdateProp={checkedAplicationUpdate}
                        checkedAplicationDeleteProp={checkedAplicationDelete}
                        checkedBusinessAreasReadProp={checkedBusinessAreasRead}
                        checkedBusinessAreasCreateProp={checkedBusinessAreasCreate}
                        checkedBusinessAreasUpdateProp={checkedBusinessAreasUpdate}
                        checkedBusinessAreasDeleteProp={checkedBusinessAreasDelete}
                        checkedActivityTypeReadProp={checkedActivityTypeRead}
                        checkedActivityTypeCreateProp={checkedActivityTypeCreate}
                        checkedActivityTypeUpdateProp={checkedActivityTypeUpdate}
                        checkedActivityTypeDeleteProp={checkedActivityTypeDelete}
                        checkedPositionReadProp={checkedPositionRead}
                        checkedPositionCreateProp={checkedPositionCreate}
                        checkedPositionUpdateProp={checkedPositionUpdate}
                        checkedPositionDeleteProp={checkedPositionDelete}
                        checkedRolReadProp={checkedRolRead}
                        checkedRolCreateProp={checkedRolCreate}
                        checkedRolUpdateProp={checkedRolUpdate}
                        checkedRolDeleteProp={checkedRolDelete}
                        checkedCalendarReadProp={checkedCalendarRead}
                        checkedCalendarManageProp={checkedCalendarManage}
                        checkedDownloadZipProp={checkedDownloadZip}
                        checkedRunCloseProp={checkedRunClose}
                        checkedCloseOpenProp={checkedCloseOpen}
                        checkedNotificationProp={checkedNotification}
                        checkedHistoricalProp={checkedHistorical}
                        checkedDBActivityLoggerProp={checkedDBActivityLogger}
                        checkedDBWorkloadProp={checkedDBWorkload}
                        checkedDBDetailTypeActivityProp={checkedDBDetailTypeActivity}
                        checkedDBDetailTypeActivityUserProp={checkedDBDetailTypeActivityUser}
                        checkedDBReadPowerBiProp={checkedDBReadPowerBi}
                        checkedDBPowerBiClientProp={checkedDBPowerBiClient}
                        checkedDBPlusPowerBiProp={checkedDBPlusPowerBi}
                        checkedNoveltyAnotherOfficialProp={checkedNoveltyAnotherOfficial}
                        checkedindividualMonthlyReportReadProp={checkedindividualMonthlyReportRead}
                        checkedindividualMonthlyReportReadOtherOfficialProp={checkedindividualMonthlyReportReadOtherOfficial}
                        checkedindividualMonthlyReportReopenProp={checkedindividualMonthlyReportReopen}
                        checkedInterestedPersonReadProp={checkedInterestedPersonRead}
                        checkedInterestedPersonCreateProp={checkedInterestedPersonCreate}
                        checkedInterestedPersonUpdateProp={checkedInterestedPersonUpdate}
                        checkedInterestedPersonDeleteProp={checkedInterestedPersonDelete}
                        fechacreacionProp={fechacreacionProp}
                        checkedObjectiveReadProp={checkedObjectiveRead}
                        checkedObjectiveCreateProp={checkedObjectiveCreate}
                        checkedObjectiveUpdateProp={checkedObjectiveUpdate}
                        checkedObjectiveDeleteProp={checkedObjectiveDelete}
                        checkedContractReadProp={checkedContractRead}
                        checkedContractCreateProp={checkedContractCreate}
                        checkedContractUpdateProp={checkedContractUpdate}
                        checkedContractDeleteProp={checkedContractDelete}
                        checkedDeliverableReadProp={checkedDeliverableRead}
                        checkedDeliverableCreateProp={checkedDeliverableCreate}
                        checkedDeliverableUpdateProp={checkedDeliverableUpdate}
                        checkedDeliverableDeleteProp={checkedDeliverableDelete}
                        checkedServiceReadProp={checkedServiceRead}
                        checkedServiceCreateProp={checkedServiceCreate}
                        checkedServiceUpdateProp={checkedServiceUpdate}
                        checkedServiceDeleteProp={checkedServiceDelete}
                        checkedWorkTeamReadProp={checkedWorkTeamRead}
                        checkedWorkTeamCreateProp={checkedWorkTeamCreate}
                        checkedWorkTeamUpdateProp={checkedWorkTeamUpdate}
                        checkedWorkTeamDeleteProp={checkedWorkTeamDelete}
                        checkedTypeProjectReadProp={checkedTypeProjectRead}
                        checkedTypeProjectCreateProp={checkedTypeProjectCreate}
                        checkedTypeProjectUpdateProp={checkedTypeProjectUpdate}
                        checkedTypeProjectDeleteProp={checkedTypeProjectDelete}
                        checkedOrgReadProp={checkedOrgRead}
                        checkedDetailPendingReadProp={checkedDetailPendingRead}
                        checkedUserClientReadProp={checkedUserClientRead}
                        checkedSupplierReadProp={checkedSupplierRead}
                        checkedSupplierCreateProp={checkedSupplierCreate}
                        checkedSupplierUpdateProp={checkedSupplierUpdate}
                        checkedSupplierDeleteProp={checkedSupplierDelete}
                        checkedFilterSupplierNDB1Prop={checkedFilterSupplierNDB1}
                        checkedFilterSupplierNDB2Prop={checkedFilterSupplierNDB2}
                        checkedFilterSupplierExportDataProp={checkedFilterSupplierExportData}
                        checkedDBReadPowerBiFilterSupplierProp={checkedDBReadPowerBiFilterSupplier}
                        checkedDBPowerBiFilterSupplierClientProp={checkedDBPowerBiFilterSupplierClient}
                        checkedDBPlusPowerBiFilterSupplierProp={checkedDBPlusPowerBiFilterSupplier}
                        checkedBDReadUploadImageProp={checkedBDReadUploadImage}

                    />
                    : ""
                }

            </div>
        );
    }
    //validaciones
    var rolError = false
    var mensajeRol = ''

    if (rol.length < 3 && rol !== '') {
        rolError = true
        mensajeRol = 'El nombre del rol debe ser mayor a 3 dígitos'
    } else if (rol.length > 100) {
        rolError = true
        mensajeRol = 'El nombre del rol debe ser menor a 100 dígitos'
    }

    const [errorDuplicated, setErrorDuplicated] = useState(false)

    //Validar que no se cree un rol igual a uno creado
    useEffect(() => {
        if (inputRol.rol !== rolProp) {
            let arrItemsDuplicated = []
            servicioProp.forEach(element => {
                let rolNameCreated = removeAccents(element.nombre)
                let newInputRol = removeAccents(inputRol.rol)
                if (newInputRol.toUpperCase() === rolNameCreated.toUpperCase()) {
                    arrItemsDuplicated.push(element)
                }
            })

            if (arrItemsDuplicated.length !== 0) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Rol pre-existente' });
                setErrorDuplicated(true)
            } else {
                setErrorDuplicated(false)
            }
        }
    }, [inputRol]); // eslint-disable-line react-hooks/exhaustive-deps
    //Validar que no hay ningun error
    var permisoActualizar = false

    if (rolError === false && rol !== '' && errorDuplicated === false) {
        if (checkedUserRead !== false || checkedUserCreate !== false || checkedUserUpdate !== false || checkedUserDelete !== false ||
            checkedActivityRead !== false || checkedActivityCreate !== false || checkedActivityUpdate !== false || checkedActivityDelete !== false ||
            checkedExportDataRead !== false || checkedExportDataAll !== false ||
            checkedClientRead !== false || checkedClientCreate !== false || checkedClientUpdate !== false || checkedClientDelete !== false ||
            checkedProjectRead !== false || checkedProjectCreate !== false || checkedProjectUpdate !== false || checkedProjectDelete !== false ||
            checkedAplicationRead !== false || checkedAplicationCreate !== false || checkedAplicationUpdate !== false || checkedAplicationDelete !== false ||
            checkedBusinessAreasRead !== false || checkedBusinessAreasCreate !== false || checkedBusinessAreasUpdate !== false || checkedBusinessAreasDelete !== false ||
            checkedActivityTypeRead !== false || checkedActivityTypeCreate !== false || checkedActivityTypeUpdate !== false || checkedActivityTypeDelete !== false ||
            checkedPositionRead !== false || checkedPositionCreate !== false || checkedPositionUpdate !== false || checkedPositionDelete !== false ||
            checkedRolRead !== false || checkedRolCreate !== false || checkedRolUpdate !== false || checkedRolDelete !== false ||
            checkedCalendarRead !== false || checkedCalendarManage !== false ||
            checkedDownloadZip !== false || checkedDownloadZipAll !== false ||
            checkedRunClose !== false || checkedCloseOpen !== false || checkedNotification !== false ||
            checkedHistorical !== false || checkedHistoricalAll !== false ||
            checkedDBActivityLogger !== false || checkedDBWorkload !== false || checkedDBDetailTypeActivity !== false || checkedDBDetailTypeActivityUser !== false ||
            checkedDBReadPowerBi !== false ||
            checkedDBPowerBiClient !== false ||
            checkedDBPlusPowerBi !== false ||
            checkedNoveltyAnotherOfficial !== false ||
            checkedindividualMonthlyReportRead !== false ||
            checkedindividualMonthlyReportReadOtherOfficial !== false ||
            checkedindividualMonthlyReportReopen !== false ||
            checkedInterestedPersonRead !== false ||
            checkedInterestedPersonCreate !== false ||
            checkedInterestedPersonUpdate !== false ||
            checkedInterestedPersonDelete !== false ||
            checkedObjectiveRead !== false ||
            checkedObjectiveCreate !== false ||
            checkedObjectiveUpdate !== false ||
            checkedObjectiveDelete !== false ||
            checkedContractRead !== false ||
            checkedContractCreate !== false ||
            checkedContractUpdate !== false ||
            checkedContractDelete !== false ||
            checkedDeliverableRead !== false ||
            checkedDeliverableCreate !== false ||
            checkedDeliverableUpdate !== false ||
            checkedDeliverableDelete !== false ||
            checkedServiceRead !== false ||
            checkedServiceCreate !== false ||
            checkedServiceUpdate !== false ||
            checkedServiceDelete !== false ||
            checkedWorkTeamRead !== false ||
            checkedWorkTeamCreate !== false ||
            checkedWorkTeamUpdate !== false ||
            checkedWorkTeamDelete !== false ||
            checkedTypeProjectRead !== false ||
            checkedTypeProjectCreate !== false ||
            checkedTypeProjectUpdate !== false ||
            checkedTypeProjectDelete !== false ||
            checkedOrgRead !== false ||
            checkedDetailPendingRead !== false ||
            checkedUserClientRead !== false ||
            checkedSupplierRead !== false ||
            checkedSupplierCreate !== false ||
            checkedSupplierUpdate !== false ||
            checkedSupplierDelete !== false ||
            checkedFilterSupplierNDB1 !== false ||
            checkedFilterSupplierNDB2 !== false ||
            checkedFilterSupplierExportData !== false ||
            checkedDBReadPowerBiFilterSupplier !== false ||
            checkedDBPowerBiFilterSupplierClient !== false ||
            checkedDBPlusPowerBiFilterSupplier !== false ||
            checkedBDReadUploadImage !==false
        ) {
            permisoActualizar = true
        } else {
            permisoActualizar = false
        }
    }

    const User = 'Parametrizar usuario'
    const UserRead = <Checkbox checked={checkedUserRead} onChange={e => onUserReadChange(e.checked)} />
    const UserCreate = <Checkbox checked={checkedUserCreate} onChange={e => onUserCreateChange(e.checked)} />
    const UserUpdate = <Checkbox checked={checkedUserUpdate} onChange={e => onUserUpdateChange(e.checked)} />
    const UserDelete = <Checkbox checked={checkedUserDelete} onChange={e => onUserDeleteChange(e.checked)} />
    const UserSelectAll = <Checkbox checked={checkedUserSelectAll} onChange={e => onSelectAllUserChange(e.checked)} />

    const Activity = 'Parametrizar actividades'
    const ActivityRead = <Checkbox checked={checkedActivityRead} onChange={e => onActivityReadChange(e.checked)} />
    const ActivityCreate = <Checkbox checked={checkedActivityCreate} onChange={e => onActivityCreateChange(e.checked)} />
    const ActivityUpdate = <Checkbox checked={checkedActivityUpdate} onChange={e => onActivityUpdateChange(e.checked)} />
    const ActivityDelete = <Checkbox checked={checkedActivityDelete} onChange={e => onActivityDeleteChange(e.checked)} />
    const ActivitySelectAll = <Checkbox checked={checkedActivitySelectAll} onChange={e => onSelectAllActivityChange(e.checked)} />

    const ExportData = 'Exportar datos'
    const ExportDataRead = <Checkbox checked={checkedExportDataRead} onChange={e => onExportDataReadChange(e.checked)} />
    const ExportDataAll = <Checkbox checked={checkedExportDataAll} onChange={e => onExportDataAllChange(e.checked)} tooltip="Exportar para todos" />
    const ExportDataSelectAll = <Checkbox checked={checkedExportDataSelectAll} onChange={e => onSelectAllExportDataChange(e.checked)} />

    const Client = 'Parametrizar cliente'
    const ClientRead = <Checkbox checked={checkedClientRead} onChange={e => onClientReadChange(e.checked)} />
    const ClientCreate = <Checkbox checked={checkedClientCreate} onChange={e => onClientCreateChange(e.checked)} />
    const ClientUpdate = <Checkbox checked={checkedClientUpdate} onChange={e => onClientUpdateChange(e.checked)} />
    const ClientDelete = <Checkbox checked={checkedClientDelete} onChange={e => onClientDeleteChange(e.checked)} />
    const ClientSelectAll = <Checkbox checked={checkedClientSelectAll} onChange={e => onSelectAllClientChange(e.checked)} />

    const Project = 'Parametrizar proyecto'
    const ProjectRead = <Checkbox checked={checkedProjectRead} onChange={e => onProjectReadChange(e.checked)} />
    const ProjectCreate = <Checkbox checked={checkedProjectCreate} onChange={e => onProjectCreateChange(e.checked)} />
    const ProjectUpdate = <Checkbox checked={checkedProjectUpdate} onChange={e => onProjectUpdateChange(e.checked)} />
    const ProjectDelete = <Checkbox checked={checkedProjectDelete} onChange={e => onProjectDeleteChange(e.checked)} />
    const ProjectSelectAll = <Checkbox checked={checkedProjectSelectAll} onChange={e => onSelectAllProjectChange(e.checked)} />

    const Aplication = 'Parametrizar aplicación'
    const AplicationRead = <Checkbox checked={checkedAplicationRead} onChange={e => onAplicationReadChange(e.checked)} />
    const AplicationCreate = <Checkbox checked={checkedAplicationCreate} onChange={e => onAplicationCreateChange(e.checked)} />
    const AplicationUpdate = <Checkbox checked={checkedAplicationUpdate} onChange={e => onAplicationUpdateChange(e.checked)} />
    const AplicationDelete = <Checkbox checked={checkedAplicationDelete} onChange={e => onAplicationDeleteChange(e.checked)} />
    const AplicationSelectAll = <Checkbox checked={checkedAplicationSelectAll} onChange={e => onSelectAllAplicationChange(e.checked)} />

    const BusinessAreas = 'Parametrizar área de negocio'
    const BusinessAreasRead = <Checkbox checked={checkedBusinessAreasRead} onChange={e => onBusinessAreasReadChange(e.checked)} />
    const BusinessAreasCreate = <Checkbox checked={checkedBusinessAreasCreate} onChange={e => onBusinessAreasCreateChange(e.checked)} />
    const BusinessAreasUpdate = <Checkbox checked={checkedBusinessAreasUpdate} onChange={e => onBusinessAreasUpdateChange(e.checked)} />
    const BusinessAreasDelete = <Checkbox checked={checkedBusinessAreasDelete} onChange={e => onBusinessAreasDeleteChange(e.checked)} />
    const BusinessAreasSelectAll = <Checkbox checked={checkedBusinessAreasSelectAll} onChange={e => onSelectAllBusinessAreasChange(e.checked)} />

    const ActivityType = 'Parametrizar tipo actividad'
    const ActivityTypeRead = <Checkbox checked={checkedActivityTypeRead} onChange={e => onActivityTypeReadChange(e.checked)} />
    const ActivityTypeCreate = <Checkbox checked={checkedActivityTypeCreate} onChange={e => onActivityTypeCreateChange(e.checked)} />
    const ActivityTypeUpdate = <Checkbox checked={checkedActivityTypeUpdate} onChange={e => onActivityTypeUpdateChange(e.checked)} />
    const ActivityTypeDelete = <Checkbox checked={checkedActivityTypeDelete} onChange={e => onActivityTypeDeleteChange(e.checked)} />
    const ActivityTypeSelectAll = <Checkbox checked={checkedActivityTypeSelectAll} onChange={e => onSelectAllActivityTypeChange(e.checked)} />

    const Position = 'Parametrizar cargo'
    const PositionRead = <Checkbox checked={checkedPositionRead} onChange={e => onPositionReadChange(e.checked)} />
    const PositionCreate = <Checkbox checked={checkedPositionCreate} onChange={e => onPositionCreateChange(e.checked)} />
    const PositionUpdate = <Checkbox checked={checkedPositionUpdate} onChange={e => onPositionUpdateChange(e.checked)} />
    const PositionDelete = <Checkbox checked={checkedPositionDelete} onChange={e => onPositionDeleteChange(e.checked)} />
    const PositionSelectAll = <Checkbox checked={checkedPositionSelectAll} onChange={e => onSelectAllPositionChange(e.checked)} />

    const Rol = 'Parametrizar rol'
    const RolRead = <Checkbox checked={checkedRolRead} onChange={e => onRolReadChange(e.checked)} />
    const RolCreate = <Checkbox checked={checkedRolCreate} onChange={e => onRolCreateChange(e.checked)} />
    const RolUpdate = <Checkbox checked={checkedRolUpdate} onChange={e => onRolUpdateChange(e.checked)} />
    const RolDelete = <Checkbox checked={checkedRolDelete} onChange={e => onRolDeleteChange(e.checked)} />
    const RolSelectAll = <Checkbox checked={checkedRolSelectAll} onChange={e => onSelectAllRolChange(e.checked)} />

    const Calendar = 'Parametrizar calendario'
    const CalendarRead = <Checkbox checked={checkedCalendarRead} onChange={e => onCalendarReadChange(e.checked)} />
    const CalendarManage = <Checkbox checked={checkedCalendarManage} onChange={e => onCalendarManageChange(e.checked)} tooltip="Parametrizar" />
    const CalendarSelectAll = <Checkbox checked={checkedCalendarSelectAll} onChange={e => onSelectAllCalendarChange(e.checked)} />

    const DownloadZip = 'Descargar Zip'
    const DownloadZipRead = <Checkbox checked={checkedDownloadZip} onChange={e => onDownloadZipChange(e.checked)} tooltip="Descargar Zip" />
    const DownloadZipSelectAll = <Checkbox checked={checkedDownloadZipAll} onChange={e => onSelectAllDownloadZipChange(e.checked)} />

    const Closes = 'Procesos de Cierre'
    const RunClose = <Checkbox checked={checkedRunClose} onChange={e => onRunCloseChange(e.checked)} tooltip="Cierre Definitivo" />
    const CloseOpen = <Checkbox checked={checkedCloseOpen} onChange={e => onCloseOpenChange(e.checked)} tooltip="Abrir Mes" />
    const Notification = <Checkbox checked={checkedNotification} onChange={e => onNotificationChange(e.checked)} tooltip="Quien lo tenga marcado recibirá mensajes semanales y mensuales" />
    const ClosesSelectAll = <Checkbox checked={checkedClosesSelectAll} onChange={e => onSelectAllClosesChange(e.checked)} />

    const Historical = 'Histórico'
    const HistoricalRead = <Checkbox checked={checkedHistorical} onChange={e => onHistoricalChange(e.checked)} />
    const HistoricalSelectAll = <Checkbox checked={checkedHistoricalAll} onChange={e => onSelectAllHistoricalChange(e.checked)} />

    const DashBoards = 'DashBoards'
    const DBActivityLogger = <Checkbox checked={checkedDBActivityLogger} onChange={e => onDBActivityLoggerChange(e.checked)} tooltip="Registrador de actividades" />
    const DBWorkload = <Checkbox checked={checkedDBWorkload} onChange={e => onDBWorkloadChange(e.checked)} tooltip="Carga de trabajo y proyectos" />
    const DBDetailTypeActivity = <Checkbox checked={checkedDBDetailTypeActivity} onChange={e => onDBDetailTypeActivityChange(e.checked)} tooltip="Detalle tipo de actividad" />
    const DBDetailTypeActivityUser = <Checkbox checked={checkedDBDetailTypeActivityUser} onChange={e => onDBDetailTypeActivityUserChange(e.checked)} tooltip="Detalle tipo de actividad funcionario" />
    const DBSelectAll = <Checkbox checked={checkedDBSelectAll} onChange={e => onSelectAllDBChange(e.checked)} />

    const DBPowerBi = 'Dashboard Powerbi'
    const DBReadPowerBi = <Checkbox checked={checkedDBReadPowerBi} onChange={e => onDBReadPowerBiChange(e.checked)} />
    const DBPowerBiClient = <Checkbox checked={checkedDBPowerBiClient} onChange={e => onDBPowerBiClientChange(e.checked)} tooltip="Dashboard de powerbi de cliente" />
    const DBPlusPowerBi = <Checkbox checked={checkedDBPlusPowerBi} onChange={e => onDBPlusPowerBiChange(e.checked)} tooltip="Dashboard de powerbi con licencia" />
    const DBPlusPowerBiSelectAll = <Checkbox checked={checkedDBPlusPowerBiSelectAll} onChange={e => onSelectAllDBPlusPowerBiChange(e.checked)} />

    const NoveltyAnotherOfficial = 'Novedades otro funcionario'
    const NoveltyAnotherOfficialManagement = <Checkbox checked={checkedNoveltyAnotherOfficial} onChange={e => onNoveltyAnotherOfficialChange(e.checked)} tooltip="Gestionar novedades a otros funcionarios" />
    const NoveltyAnotherOfficialSelectAll = <Checkbox checked={checkedNoveltyAnotherOfficialSelectAll} onChange={e => onSelectAllNoveltyAnotherOfficialChange(e.checked)} />

    const IndividualMonthlyReport = 'Informe mensual individual'
    const IndividualMonthlyReportRead = <Checkbox checked={checkedindividualMonthlyReportRead} onChange={e => onindividualMonthlyReportReadChange(e.checked)} />
    const IndividualMonthlyReportReadOtherOfficial = <Checkbox checked={checkedindividualMonthlyReportReadOtherOfficial} onChange={e => onindividualMonthlyReportReadOtherOfficialChange(e.checked)} tooltip="Consultar informe mensual otro funcionario" />
    const IndividualMonthlyReportReopen = <Checkbox checked={checkedindividualMonthlyReportReopen} onChange={e => onindividualMonthlyReportReopenChange(e.checked)} tooltip="Reabrir informe mensual" />
    const IndividualMonthlyReportSelectAll = <Checkbox checked={checkedindividualMonthlyReportSelectAll} onChange={e => onSelectAllindividualMonthlyReportChange(e.checked)} />

    const InterestedPerson = 'Parametrizar personas interesadas'
    const InterestedPersonRead = <Checkbox checked={checkedInterestedPersonRead} onChange={e => onInterestedPersonReadChange(e.checked)} />
    const InterestedPersonCreate = <Checkbox checked={checkedInterestedPersonCreate} onChange={e => onInterestedPersonCreateChange(e.checked)} />
    const InterestedPersonUpdate = <Checkbox checked={checkedInterestedPersonUpdate} onChange={e => onInterestedPersonUpdateChange(e.checked)} />
    const InterestedPersonDelete = <Checkbox checked={checkedInterestedPersonDelete} onChange={e => onInterestedPersonDeleteChange(e.checked)} />
    const InterestedPersonSelectAll = <Checkbox checked={checkedInterestedPersonSelectAll} onChange={e => onSelectAllInterestedPersonChange(e.checked)} />

    const Objective = 'Parametrizar objetivos'
    const ObjectiveRead = <Checkbox checked={checkedObjectiveRead} onChange={e => onObjectiveReadChange(e.checked)} />
    const ObjectiveCreate = <Checkbox checked={checkedObjectiveCreate} onChange={e => onObjectiveCreateChange(e.checked)} />
    const ObjectiveUpdate = <Checkbox checked={checkedObjectiveUpdate} onChange={e => onObjectiveUpdateChange(e.checked)} />
    const ObjectiveDelete = <Checkbox checked={checkedObjectiveDelete} onChange={e => onObjectiveDeleteChange(e.checked)} />
    const ObjectiveSelectAll = <Checkbox checked={checkedObjectiveSelectAll} onChange={e => onSelectAllObjectiveChange(e.checked)} />

    const Contract = 'Parametrizar contratos'
    const ContractRead = <Checkbox checked={checkedContractRead} onChange={e => onContractReadChange(e.checked)} />
    const ContractCreate = <Checkbox checked={checkedContractCreate} onChange={e => onContractCreateChange(e.checked)} />
    const ContractUpdate = <Checkbox checked={checkedContractUpdate} onChange={e => onContractUpdateChange(e.checked)} />
    const ContractDelete = <Checkbox checked={checkedContractDelete} onChange={e => onContractDeleteChange(e.checked)} />
    const ContractSelectAll = <Checkbox checked={checkedContractSelectAll} onChange={e => onSelectAllContractChange(e.checked)} />

    const Deliverable = 'Parametrizar entregables'
    const DeliverableRead = <Checkbox checked={checkedDeliverableRead} onChange={e => onDeliverableReadChange(e.checked)} />
    const DeliverableCreate = <Checkbox checked={checkedDeliverableCreate} onChange={e => onDeliverableCreateChange(e.checked)} />
    const DeliverableUpdate = <Checkbox checked={checkedDeliverableUpdate} onChange={e => onDeliverableUpdateChange(e.checked)} />
    const DeliverableDelete = <Checkbox checked={checkedDeliverableDelete} onChange={e => onDeliverableDeleteChange(e.checked)} />
    const DeliverableSelectAll = <Checkbox checked={checkedDeliverableSelectAll} onChange={e => onSelectAllDeliverableChange(e.checked)} />

    const Service = 'Parametrizar servicios'
    const ServiceRead = <Checkbox checked={checkedServiceRead} onChange={e => onServiceReadChange(e.checked)} />
    const ServiceCreate = <Checkbox checked={checkedServiceCreate} onChange={e => onServiceCreateChange(e.checked)} />
    const ServiceUpdate = <Checkbox checked={checkedServiceUpdate} onChange={e => onServiceUpdateChange(e.checked)} />
    const ServiceDelete = <Checkbox checked={checkedServiceDelete} onChange={e => onServiceDeleteChange(e.checked)} />
    const ServiceSelectAll = <Checkbox checked={checkedServiceSelectAll} onChange={e => onSelectAllServiceChange(e.checked)} />

    const EquipoTrabajo = 'Parametrizar equipos de trabajo'
    const EquipoRead = <Checkbox checked={checkedWorkTeamRead} onChange={e => onWorkTeamReadChange(e.checked)} />
    const EquipoCreate = <Checkbox checked={checkedWorkTeamCreate} onChange={e => onWorkTeamCreateChange(e.checked)} />
    const EquipoUpdate = <Checkbox checked={checkedWorkTeamUpdate} onChange={e => onWorkTeamUpdateChange(e.checked)} />
    const EquipoDelete = <Checkbox checked={checkedWorkTeamDelete} onChange={e => onWorkTeamDeleteChange(e.checked)} />
    const EquipoSelectAll = <Checkbox checked={checkedWorkTeamSelectAll} onChange={e => onSelectAllWorkTeamChange(e.checked)} />

    const TipoProyecto = 'Parametrizar tipos de proyectos'
    const TipoProyectoRead = <Checkbox checked={checkedTypeProjectRead} onChange={e => onTypeProjectReadChange(e.checked)} />
    const TipoProyectoCreate = <Checkbox checked={checkedTypeProjectCreate} onChange={e => onTypeProjectCreateChange(e.checked)} />
    const TipoProyectoUpdate = <Checkbox checked={checkedTypeProjectUpdate} onChange={e => onTypeProjectUpdateChange(e.checked)} />
    const TipoProyectoDelete = <Checkbox checked={checkedTypeProjectDelete} onChange={e => onTypeProjectDeleteChange(e.checked)} />
    const TipoProyectoSelectAll = <Checkbox checked={checkedTypeProjectSelectAll} onChange={e => onSelectAllTypeProjectChange(e.checked)} />

    const organizationChart = 'Organigrama'
    const organizationChartRead = <Checkbox checked={checkedOrgRead} onChange={e => onOrgChange(e.checked)} />
    const organizationChartSelectAll = <Checkbox checked={checkedOrgSelectAll} onChange={e => onSelectAllOrgChange(e.checked)} />

    const DetailPending = 'Detalle de pendientes'
    const DetailPendingRead = <Checkbox checked={checkedDetailPendingRead} onChange={e => onDetailPendingChange(e.checked)} />
    const DetailPendingSelectAll = <Checkbox checked={checkedDetailPendingSelectAll} onChange={e => onSelectAllDetailPendingChange(e.checked)} />

    const UserClient = 'Consultar usarios de clientes'
    const UserClientRead = <Checkbox checked={checkedUserClientRead} onChange={e => onUserClientChange(e.checked)} />
    const UserClientSelectAll = <Checkbox checked={checkedUserClientSelectAll} onChange={e => onSelectAllUserClientChange(e.checked)} />

    const Supplier = 'Parametrizar proveedores'
    const SupplierRead = <Checkbox checked={checkedSupplierRead} onChange={e => onSupplierReadChange(e.checked)} />
    const SupplierCreate = <Checkbox checked={checkedSupplierCreate} onChange={e => onSupplierCreateChange(e.checked)} />
    const SupplierUpdate = <Checkbox checked={checkedSupplierUpdate} onChange={e => onSupplierUpdateChange(e.checked)} />
    const SupplierDelete = <Checkbox checked={checkedSupplierDelete} onChange={e => onSupplierDeleteChange(e.checked)} />
    const SupplierSelectAll = <Checkbox checked={checkedSupplierSelectAll} onChange={e => onSelectAllSupplierChange(e.checked)} />

    const FilterSupplier = 'Filtros de proveedor'
    const FilterSupplierNDB1 = <Checkbox checked={checkedFilterSupplierNDB1} onChange={e => onFilterSupplierNDB1Change(e.checked)} tooltip="Dashboard detalle tipos de actividad con filtro de proveedor" />
    const FilterSupplierNDB2 = <Checkbox checked={checkedFilterSupplierNDB2} onChange={e => onFilterSupplierNDB2Change(e.checked)} tooltip="Dashboard tipos de actividad - funcionario con filtro de proveedor" />
    const FilterSupplierExportData = <Checkbox checked={checkedFilterSupplierExportData} onChange={e => onFilterSupplierExportDataChange(e.checked)} tooltip="Exportar datos con filtro de proveedor" />
    const FilterSupplierSelectAll = <Checkbox checked={checkedFilterSupplierSelectAll} onChange={e => onSelectAllFilterSupplierChange(e.checked)} />

    const DBPowerBiFilterSupplier = 'Dashboard Powerbi con filtro de proveedor'
    const DBReadPowerBiFilterSupplier = <Checkbox checked={checkedDBReadPowerBiFilterSupplier} onChange={e => onDBReadPowerBiFilterSupplierChange(e.checked)} />
    const DBPowerBiFilterSupplierClient = <Checkbox checked={checkedDBPowerBiFilterSupplierClient} onChange={e => onDBPowerBiFilterSupplierClientChange(e.checked)} tooltip="Dashboard de powerbi de cliente" />
    const DBPlusPowerBiFilterSupplier = <Checkbox checked={checkedDBPlusPowerBiFilterSupplier} onChange={e => onDBPlusPowerBiFilterSupplierChange(e.checked)} tooltip="Dashboard de powerbi con licencia" />
    const DBPlusPowerBiFilterSupplierSelectAll = <Checkbox checked={checkedDBPlusPowerBiFilterSupplierSelectAll} onChange={e => onSelectAllDBPlusPowerBiFilterSupplierChange(e.checked)} />

    const uploadImage = 'Cargar Imagen Corporativa'
    const uploadImageRead = <Checkbox checked={checkedBDReadUploadImage} onChange={e => onChangeCheckedReadUploadImage(e.checked)}  tooltip="Menú de Cargar Imagen Corporativa"/>
    const uploadImageSelectAll = <Checkbox checked={checkedBDUploadImageSelectAll} onChange={e => onChangeCheckedUploadImageSelectAll(e.checked)}  tooltip="Menú de Cargar Imagen Corporativa" />
    const TablaPermisos = [

        { "options": User, "read": UserRead, "create": UserCreate, "update": UserUpdate, "delete": UserDelete, "selectAll": UserSelectAll },
        { "options": Activity, "read": ActivityRead, "create": ActivityCreate, "update": ActivityUpdate, "delete": ActivityDelete, "selectAll": ActivitySelectAll },
        { "options": ExportData, "read": ExportDataRead, "create": ExportDataAll, "selectAll": ExportDataSelectAll },
        { "options": Client, "read": ClientRead, "create": ClientCreate, "update": ClientUpdate, "delete": ClientDelete, "selectAll": ClientSelectAll },
        { "options": Project, "read": ProjectRead, "create": ProjectCreate, "update": ProjectUpdate, "delete": ProjectDelete, "selectAll": ProjectSelectAll },
        { "options": Aplication, "read": AplicationRead, "create": AplicationCreate, "update": AplicationUpdate, "delete": AplicationDelete, "selectAll": AplicationSelectAll },
        { "options": BusinessAreas, "read": BusinessAreasRead, "create": BusinessAreasCreate, "update": BusinessAreasUpdate, "delete": BusinessAreasDelete, "selectAll": BusinessAreasSelectAll },
        { "options": ActivityType, "read": ActivityTypeRead, "create": ActivityTypeCreate, "update": ActivityTypeUpdate, "delete": ActivityTypeDelete, "selectAll": ActivityTypeSelectAll },
        { "options": Position, "read": PositionRead, "create": PositionCreate, "update": PositionUpdate, "delete": PositionDelete, "selectAll": PositionSelectAll },
        { "options": Rol, "read": RolRead, "create": RolCreate, "update": RolUpdate, "delete": RolDelete, "selectAll": RolSelectAll },
        { "options": Calendar, "read": CalendarRead, "create": CalendarManage, "selectAll": CalendarSelectAll },
        { "options": DownloadZip, "read": DownloadZipRead, "selectAll": DownloadZipSelectAll },
        { "options": Closes, "read": RunClose, "create": CloseOpen, "update": Notification, "selectAll": ClosesSelectAll },
        { "options": Historical, "read": HistoricalRead, "selectAll": HistoricalSelectAll },
        { "options": DashBoards, "read": DBActivityLogger, "create": DBWorkload, "update": DBDetailTypeActivity, "delete": DBDetailTypeActivityUser, "selectAll": DBSelectAll },
        { "options": DBPowerBi, "read": DBReadPowerBi, "create": DBPlusPowerBi, "update": DBPowerBiClient, "selectAll": DBPlusPowerBiSelectAll },
        { "options": NoveltyAnotherOfficial, "read": NoveltyAnotherOfficialManagement, "selectAll": NoveltyAnotherOfficialSelectAll },
        { "options": IndividualMonthlyReport, "read": IndividualMonthlyReportRead, "create": IndividualMonthlyReportReadOtherOfficial, "update": IndividualMonthlyReportReopen, "selectAll": IndividualMonthlyReportSelectAll },
        { "options": InterestedPerson, "read": InterestedPersonRead, "create": InterestedPersonCreate, "update": InterestedPersonUpdate, "delete": InterestedPersonDelete, "selectAll": InterestedPersonSelectAll },
        { "options": Objective, "read": ObjectiveRead, "create": ObjectiveCreate, "update": ObjectiveUpdate, "delete": ObjectiveDelete, "selectAll": ObjectiveSelectAll },
        { "options": Contract, "read": ContractRead, "create": ContractCreate, "update": ContractUpdate, "delete": ContractDelete, "selectAll": ContractSelectAll },
        { "options": Deliverable, "read": DeliverableRead, "create": DeliverableCreate, "update": DeliverableUpdate, "delete": DeliverableDelete, "selectAll": DeliverableSelectAll },
        { "options": Service, "read": ServiceRead, "create": ServiceCreate, "update": ServiceUpdate, "delete": ServiceDelete, "selectAll": ServiceSelectAll },
        { "options": EquipoTrabajo, "read": EquipoRead, "create": EquipoCreate, "update": EquipoUpdate, "delete": EquipoDelete, "selectAll": EquipoSelectAll },
        { "options": TipoProyecto, "read": TipoProyectoRead, "create": TipoProyectoCreate, "update": TipoProyectoUpdate, "delete": TipoProyectoDelete, "selectAll": TipoProyectoSelectAll },
        { "options": organizationChart, "read": organizationChartRead, "selectAll": organizationChartSelectAll },
        { "options": DetailPending, "read": DetailPendingRead, "selectAll": DetailPendingSelectAll },
        { "options": UserClient, "read": UserClientRead, "selectAll": UserClientSelectAll },
        { "options": Supplier, "read": SupplierRead, "create": SupplierCreate, "update": SupplierUpdate, "delete": SupplierDelete, "selectAll": SupplierSelectAll },
        { "options": FilterSupplier, "read": FilterSupplierNDB1, "create": FilterSupplierNDB2, "update": FilterSupplierExportData, "selectAll": FilterSupplierSelectAll },
        { "options": DBPowerBiFilterSupplier, "read": DBReadPowerBiFilterSupplier, "create": DBPlusPowerBiFilterSupplier, "update": DBPowerBiFilterSupplierClient, "selectAll": DBPlusPowerBiFilterSupplierSelectAll },
        { "options": uploadImage, "read": uploadImageRead, "selectAll": uploadImageSelectAll },

    ]

    return (

        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar rol" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-2">
                            <label className="labels" >Id rol<span className="require">*</span></label>
                            <InputText id="id" type="text" name="id" disabled value={idRol} />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label className="labels" >Rol<span className="require">*</span></label>
                            <InputText id="rol" type="text" name="rol" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onRolChange} value={rol}
                                placeholder="Registre el nombre del rol"
                                className={rolError || errorDuplicated? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{rolError ? mensajeRol : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label className="labels" >Estado<span className="require">*</span></label>
                            <Dropdown value={selectedEstado} options={estados} onChange={onEstadoChange} name="estado" optionLabel="estado" placeholder="Seleccione Estado" />
                        </div>

                    </form>
                </div>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={TablaPermisos} className="p-datatable-responsive-demo" paginator rows={10} >
                            <Column className="p-column" field="options" header="Opciones" />
                            <Column className="p-column" field="read" header="Consultar" />
                            <Column className="p-column" field="create" header="Crear" />
                            <Column className="p-column" field="update" header="Editar" />
                            <Column className="p-column" field="delete" header="Eliminar" />
                            <Column className="p-column" field="selectAll" header="Seleccionar todo" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarRol