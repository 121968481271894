import React, { useEffect, useState, useRef } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from 'axios';
import { CambiarFormatoFecha, UseMedia } from '../Funciones';
import Cookies from 'universal-cookie';
import { InputText } from 'primereact/inputtext';
import BtnAsociarActividades from './BtnAsociarActividades';

const BlockActivity = ({ dateStartProp, dateEndProp, idSelectedClientProp, loadingProp,
    selectedProyectoProp, setRunRenderProp2 }) => {

    const cookies = new Cookies();
    const dt = useRef(null);
    let small = UseMedia("(max-width: 760px)");

    const [services, setServices] = useState([])
    const [selectedActivities, setSelectedActivities] = useState([])
    const [runRender, setRunRender] = useState(0)
    useEffect(() => {
        const usuario = cookies.get('usuario')
        const executeServices = async () => {

            const newDateStart = CambiarFormatoFecha(dateStartProp)
            const newDateEnd = CambiarFormatoFecha(dateEndProp)
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const json = JSON.stringify({
                esadicional: "0",
                fecFinal: newDateEnd,
                fecInicial: newDateStart,
                usuario: usuario
            })

            await axios.post(url, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    },
                    /* cancelToken: source.token */
                })
                .then(response => {
                    if (response !== undefined) {
                        const filter = response.data.filter(item => {
                            return item.cliente.id === idSelectedClientProp && item.idObjetivo === null
                        })
                        setServices(filter)
                    }

                })
                .catch(e => {

                })
        }

        if (dateStartProp !== null && dateEndProp !== null) {
            executeServices()
        }
    }, [dateStartProp, dateEndProp, idSelectedClientProp, runRender]);// eslint-disable-line react-hooks/exhaustive-deps

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    
    const header = (
        
        <div className="headerTable">
            {
                selectedActivities.length > 0 ?
                    <BtnAsociarActividades
                        selectedProyectoProp={selectedProyectoProp}
                        dataServicesProp={selectedActivities}
                        runRenderProp={runRender}
                        setRunRenderProp={setRunRender}
                        setRunRenderProp2={setRunRenderProp2}
                        setSelectedActivitiesProp={setSelectedActivities}
                    />
                    :
                    ''
            }

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    )

    const onSelectedServiceChange = (e) => {
        setSelectedActivities(e.value)
    }


    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={services} dataKey="id" className="p-datatable-responsive-demo" header={header} globalFilter={globalFilter}
                    emptyMessage="actividades no registradas en el sistema" paginator rows={5} sortField="descripcion" sortOrder={1} loading={loadingProp}
                    onSelectionChange={(e) => onSelectedServiceChange(e)} selectionMode="checkbox" selection={selectedActivities}
                    scrollHeight={small ? "" : "500px"} scrollable={small ? false : true}
                >
                    <Column selectionMode="multiple" style={{ maxWidth: '4rem' }} ></Column>
                    <Column className="columna" style={{ minWidth: '16rem' }} field="fecha" sortable header="Fecha" />
                    <Column className="columna" style={{ minWidth: '16rem' }} field="cliente.razonsocial" sortable header="Cliente" />
                    <Column className="columna" style={{ minWidth: '16rem' }} field="proyecto.nombreproyecto" sortable header="Proyecto" />
                    <Column className="columna" style={{ minWidth: '16rem' }} field="tipoActividad.nombretipoactividad" sortable header="Tipo Actividad" />
                    <Column className="columna" headerStyle={{ width: '160px' }} field="descripcionactividad" sortable header="Actividad" />
                    <Column className="columna" headerStyle={{ width: '160px' }} field="numerohoras" sortable header="Num Horas" />
                </DataTable>
            </div>
        </div>
    )
}

export default BlockActivity;