import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import Services from '../../service/Services';
import axios from 'axios';

const DetalleNovedad = ({ idProp }) => {

    const [detalleNovedad, setDetalleNovedad] = useState([])

    const getServices = new Services();
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {

        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            getServices.getDetalleNovedad(idProp).then(data => setDetalleNovedad(data))
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [idProp, displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const dt = useRef(null);

    return (

        <>

            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Detalle de novedad" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Detalle de la novedad" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={detalleNovedad} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay registros en el sistema">

                            <Column className="columna" field="proyecto.cliente.razonsocial" header="Cliente" />
                            <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                            <Column className="columna" field="porcentaje" header="Porcentaje" />

                        </DataTable>

                    </div>
                </div>
            </Dialog>

        </>
    )


}
export default DetalleNovedad