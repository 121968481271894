import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const BtnActualizarAreaNegocio = ({ idAreaProp, areaProp, creadoporProp, fechacreacionProp, areasNegocioProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [inputId, setInputId] = useState({
        id: idAreaProp
    })


    //Obtener datos del input área de negocio

    const [inputArea, setInputArea] = useState({
        area: areaProp
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onAreaChange = (event) => {

        setInputArea({
            ...inputArea,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }
    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setInputId({
            id: idAreaProp
        })
        setInputArea({
            area: areaProp
        })

    }, [idAreaProp, areaProp])


    //Almacenamos los valores para enviarlos por props

    const idArea = inputId.id
    const area = inputArea.area
    const usuario = cookies.get('usuario')


    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {


            setInputArea({
                area: areaProp
            })

            setValidarCambio(false)


            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idAreaProp={idArea}
                        usuarioProp={usuario}
                        areaProp={area}
                        creadoporProp={creadoporProp}
                        fechacreacionProp={fechacreacionProp}
                    />
                    : ""
                }

            </div>
        );
    }
    //validaciones
    var areaError = false
    var mensajeArea = ''
    if (area === '') {
        areaError = true
        mensajeArea = 'Debes ingresar el nombre del área de negocio '
    } else if (area.length < 3) {
        areaError = true
        mensajeArea = 'El nombre del área de negocio debe ser mayor a 3 dígitos'
    } else if (area.length > 100) {
        areaError = true
        mensajeArea = 'El nombre del área de negocio debe ser menor a 100 dígitos'
    }
    const [errorDuplicated, setErrorDuplicated] = useState(false)

    //Validar que no se cree un area igual a uno creado
    useEffect(() => {

        let arrItemsDuplicated = []
        if (inputArea.area !== areaProp) {
            areasNegocioProp.forEach(element => {
                let areaNameCreated = removeAccents(element.nombreareanegocio)
                let newInputArea = removeAccents(inputArea.area)
                if (newInputArea.toUpperCase() === areaNameCreated.toUpperCase()) {
                    arrItemsDuplicated.push(element)
                }
            })
        }    
        if (arrItemsDuplicated.length !== 0) {
            setErrorDuplicated(true)
            toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Area pre-existente' });
        } else {
            setErrorDuplicated(false)
        }
    }, [inputArea]); // eslint-disable-line react-hooks/exhaustive-deps

    //Validar que no hay ningun error
    var permisoActualizar = false

    if (areaError === false && errorDuplicated === false) {
        permisoActualizar = true
    }

    return (

        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar Área de negocio" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Id Área </label>
                            <InputText id="id" type="text" name="id" disabled value={idArea} />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Área de Negocio<span className="require">*</span></label>
                            <InputText id="area" type="text" name="area" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onAreaChange} value={area}
                                placeholder="Registre el nombre del área de negocio"
                                className={areaError || errorDuplicated ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{areaError ? mensajeArea : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarAreaNegocio