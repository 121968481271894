import React, { useState, useEffect, useRef } from "react"
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button"
import { Dialog } from 'primereact/dialog';
import { CambiarFormatoFecha, Validarfechas } from "../Funciones";
import ConfirmBtn from "../ConsultarUsuarios/ConfirmBtn";
import Cookies from "universal-cookie";
import { Toast } from "primereact/toast";

//Creando la modal del funcionario
const AñadirFuncionarios = ({ officialProp, informationOfficialProp, idClientsAsociacionesProp, dateStartTeamProp, dateEndTeamProp,
    teamWorkProp, idProp, createByProp, dateCreatedProp, runRenderProp, setRunRenderProp, idCoordinatorProp,
    serviceUsersProp, loadingProp }) => {

    const [userServices, setUserServices] = useState([]);
    const [displayOfficial, setDisplayOfficial] = useState(false);
    const toast = useRef(null);
    const dialogFuncMap = {
        'displayOfficial': setDisplayOfficial
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true)
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false)
    }

    const [validateChangeOfficial, setValidateChangeOfficial] = useState(false)
    useEffect(() => {
        setUserServices(serviceUsersProp)
    }, [idClientsAsociacionesProp, serviceUsersProp])// eslint-disable-line react-hooks/exhaustive-deps

    //Obtener datos del funcionario

    const [selectedOfficial, setSelectedOfficial] = useState({
        name: '',
        id: '',
    })

    const getOfficial = userServices.map(item => {
        const getObject = { name: item.nombres + ' ' + item.apellidos, id: item.idUsuario }
        return getObject
    })
    getOfficial.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    const onOfficialChange = (e) => {
        setSelectedOfficial(e.value)
        setValidateChangeOfficial(true)
    }

    //Función para validar que no seleccione funcionario seleccionado o coordinador seleccionado

    function idRepeat(e) {
        var disabled = false
        informationOfficialProp.forEach(x => {
            if (e.id === idCoordinatorProp) {
                disabled = true
            }
            if (x.fechainicioasignacion !== null && x.fechafinasignacion === null) {
                if (x.idUsuario === e.id) {
                    disabled = true
                }
            }
        })

        return disabled
    }

    const [dateStartOfficial, setDateStartOfficial] = useState(null) //Estado de fechas y agrengando les valor con la función onChange

    const onDateStartChange = (e) => {
        setDateStartOfficial(e.value)
        setValidateChangeOfficial(true)
    }

    const [dateEndOfficial, setDateEndOfficial] = useState(null)

    const onDateEndChange = (e) => {
        setDateEndOfficial(e.value)
        setValidateChangeOfficial(true)
    }

    //Validaciones de fechas

    var dateStartErrorOfficial = false
    var messageDateStartOfficial = ''

    var idRegisterOfficial = officialProp.map(item => {
        return item.id
    })

    const dataOfficial = () => {
        var newInformationOfficial = informationOfficialProp.filter(function (object) {
            var idUser = object.idUsuario
            return idUser === selectedOfficial.id && object.id !== idRegisterOfficial

        })
        return newInformationOfficial
    }


    const returnArrayValidateRange = (dateStart, dateEnd) => {
        var newInformationOfficial = dataOfficial()
        const validateInformationOfficial = []
        newInformationOfficial.forEach(element => {
            if (element.fechafinasignacion !== null) {
                if ((dateStart <= element.fechafinasignacion && dateEnd >= element.fechainicioasignacion)
                    || (dateEnd >= element.fechafinasignacion && dateStart <= element.fechainicioasignacion)) {
                    validateInformationOfficial.push(element)
                }
            }
        })
        return validateInformationOfficial
    }

    const validateDateStartRange = (dateStart) => {
        var newInformationOfficial = dataOfficial()
        const validateInformationOfficial = []
        newInformationOfficial.forEach(element => {
            if (dateStart >= element.fechainicioasignacion && dateStart <= element.fechafinasignacion) {
                validateInformationOfficial.push(element)
            }
        })
        return validateInformationOfficial
    }

    const validateDateStartOfficial = (dateStart) => {
        var rangeDateStart = validateDateStartRange(dateStart)
        var data = dataOfficial()
        if (dateEndTeamProp === null) {
            if (dateStart < dateStartTeamProp) {
                dateStartErrorOfficial = true
                messageDateStartOfficial = 'La fecha inicio debe ser mayor a la fecha inicio del equipo'
            }

        }
        if (rangeDateStart.length !== 0) {
            dateStartErrorOfficial = true
            messageDateStartOfficial = 'La fecha inicio se cruza con fechas ya asignadas'
        } else if (data.length !== 0) {
            data.sort(function (a, b) {
                var textA = new Date(a.fechafinasignacion)
                var textB = new Date(b.fechafinasignacion)
                return textB - textA
            })
            if (dateStart <= data[0].fechainicioasignacion) {
                dateStartErrorOfficial = true
                messageDateStartOfficial = 'La fecha inicio no puede ser menor a fechas ya asignadas'
            }
        }
    }

    const validateRangeOfficial = (dateStart, dateEnd) => {
        var dates = Validarfechas(dateStart, dateEnd)

        if (dateEndTeamProp === null) {
            if (dateStart >= dateStartTeamProp && dateEnd >= dateStartTeamProp) {
                dateStartErrorOfficial = false
                messageDateStartOfficial = ''
            } else {
                dateStartErrorOfficial = true
                messageDateStartOfficial = 'La fecha inicio y fin debe ser mayor a la fecha inicio del equipo'
            }
        }

        if (dates.setFechaError === true) {
            dateStartErrorOfficial = dates.setFechaError
            messageDateStartOfficial = dates.setMensajeFecha
        } else if (dateStartTeamProp !== null && dateEndTeamProp !== null) {
            if (dateStart >= dateStartTeamProp && dateStartTeamProp <= dateEndTeamProp &&
                dateEnd >= dateStartTeamProp && dateEnd <= dateEndTeamProp) {
                dateStartErrorOfficial = false
                messageDateStartOfficial = ''
            } else {
                dateStartErrorOfficial = true
                messageDateStartOfficial = 'La fecha inicio y fin debe estar en el rango de fechas asignadas al equipo '
            }

            var arrayOfficialRange = returnArrayValidateRange(dateStart, dateEnd)
            if (arrayOfficialRange.length !== 0) {
                dateStartErrorOfficial = true
                messageDateStartOfficial = 'La fecha inicio y fin de vigencias se cruzan con las de otras asignaciones para el mismo funcionario'
            }
        }
        /* console.log(arrayOfficial) */
    }

    var newDateStart = dateStartOfficial === null ? dateStartOfficial : CambiarFormatoFecha(dateStartOfficial)
    var newDateEnd = dateEndOfficial === null ? dateEndOfficial : CambiarFormatoFecha(dateEndOfficial)

    let validateStarts = false

    if (dateStartOfficial !== null && dateEndOfficial === null) {
        if (dateStartTeamProp !== null && dateEndTeamProp !== null) {

            dateStartErrorOfficial = true
            messageDateStartOfficial = 'La fecha fin no puede quedar vacia débido a que el equipo tiene una fecha fin registrada'
        }
        else if (dateStartTeamProp !== null && dateEndTeamProp === null) {
            validateDateStartOfficial(newDateStart)
        }

    } else if (dateStartOfficial !== null && dateEndOfficial !== null) {
        validateRangeOfficial(newDateStart, newDateEnd)
    }

    if (dateStartTeamProp !== null && dateEndTeamProp !== null) {

        validateStarts = true
    }

    //Validar que no se crucen las fechas del usuario en diferentes equipos de trabajo
    let datedCrusses = false
    if (displayOfficial === true) {

        const filter = userServices?.filter(obj => {
            return parseInt(obj.idUsuario) === parseInt(selectedOfficial?.id)
        })
        function isDateInRange(date, rangeStart, rangeEnd) {
            const targetDate = new Date(date);
            const startDate = new Date(rangeStart);
            const endDate = rangeEnd ? new Date(rangeEnd) : null;

            if (endDate) {
                return targetDate >= startDate && targetDate <= endDate;
            } else {
                return targetDate >= startDate;
            }
        }

        function rangesOverlap(start1, end1, start2, end2) {
            const range1Start = new Date(start1);
            const range1End = end1 ? new Date(end1) : null;
            const range2Start = new Date(start2);
            const range2End = end2 ? new Date(end2) : null;

            if (range1End && range2End) {
                return range1Start <= range2End && range1End >= range2Start;
            } else if (range1End) {
                return range1Start <= range2Start && range1End >= range2Start;
            } else if (range2End) {
                return range1Start <= range2End && range2Start >= range1Start;
            } else {
                return range1Start <= range2Start;
            }
        }

        function validateRange(startDate, endDate, ranges) {
            for (const range of ranges) {
                const { fechainicioasignacion, fechafinasignacion } = range;
                if (
                    isDateInRange(startDate, fechainicioasignacion, fechafinasignacion) ||
                    (endDate && isDateInRange(endDate, fechainicioasignacion, fechafinasignacion)) ||
                    (endDate && rangesOverlap(startDate, endDate, fechainicioasignacion, fechafinasignacion))
                ) {
                    return false;
                }
            }
            return true;
        }

        // Validar que no se crucen fechas con otros equipos en los que esté asociado el usuario
        if (dateStartOfficial !== null) {
            filter?.forEach(i => {
                let ranges = i.equipoTrabajoLider?.concat(i.equipoTrabajoUsuario);

                const isValid = validateRange(
                    CambiarFormatoFecha(dateStartOfficial),
                    dateEndOfficial ? CambiarFormatoFecha(dateEndOfficial) : null,
                    ranges
                );

                if (!isValid) {
                    datedCrusses = true;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Fechas Cruzadas',
                        detail: `El usuario seleccionado se cruza con fechas ya creadas para un equipo de trabajo`,
                        life: 4000
                    });
                }
            });
        }
    }

    //Validaciones para darle permiso al boton guardar de aparecer
    var createPermissionSave = false

    if (selectedOfficial.name !== '' && dateStartOfficial !== null &&
        dateStartErrorOfficial === false && datedCrusses === false) {

        createPermissionSave = true
    }

    //Almacenamos los valores para enviarlos por props

    const cookies = new Cookies()

    const usuarioSesion = cookies.get('usuario')


    const arrayOfficial = [{
        usuario: {
            id: selectedOfficial.id
        },
        fechacreacion: new Date(),
        fechainicioasignacion: dateStartOfficial,
        fechafinasignacion: dateEndOfficial,
        estado: 1
    }]

    const ModalRegresar = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            dialogFuncMap[`${name}`](false)
            setDisplayOfficial(false)
            setSelectedOfficial({
                name: '',
                id: ''
            })
            setDateStartOfficial(null)
            setDateEndOfficial(null)
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChangeOfficial ?
                        <ModalRegresar displayBasicProp={true} />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }

                {
                    createPermissionSave ?
                        <ConfirmBtn
                            updateDateProp={true}
                            titulo='Equipo de trabajo'
                            mensaje='¿Desea adicionar un nuevo funcionario?'
                            accion='CrearFuncionarios'
                            label='Guardar'
                            icono='pi pi-save'
                            updateOfficialTeam={arrayOfficial}
                            usuarioSesionProp={usuarioSesion}
                            createByProp={createByProp}
                            dateCreateProp={dateCreatedProp}
                            updateDateStartProp={dateStartTeamProp}
                            updateDateEndProp={dateEndTeamProp}
                            idUpdate={idProp}
                            updateNameTeam={teamWorkProp}
                            updateClientTeam={idClientsAsociacionesProp}
                            updateCoordinatorTeam={[]}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicOfficialProp={setDisplayOfficial}
                            addOficialProp={true}
                            setSelectedOfficialProp={setSelectedOfficial}
                            setDateStartOfficialProp={setDateStartOfficial}
                            setDateEndOfficialProp={setDateEndOfficial}
                        />
                        :
                        ''
                }

            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label="Añadir nuevo funcionario" icon="pi pi-plus" id="boton-official" onClick={() => onClick('displayOfficial')}
                className="p-button-success" style={{ top: '2px', left: '10px' }} />

            <Dialog className="modal" header="Lista de funcionarios" visible={displayOfficial} modal style={{}}
                footer={renderFooter('displayOfficial')} onHide={() => onHide('displayOfficial')}>

                <div className="p-fluid p-formgrid p-grid">
                    <div className='p-field p-col-6 p-md-6'>
                        <label className="labels">Seleccione un funcionario<span className="require">*</span></label>
                        <Dropdown filter emptyMessage="No hay resultados" value={selectedOfficial} options={getOfficial} optionLabel="name" optionDisabled={idRepeat} onChange={onOfficialChange} placeholder="Selecione un funcionario" />
                        {loadingProp ? <p style={{ fontWeight: "bold" }}>Cargando información...</p> : ''}
                    </div>

                    <div className="p-field p-col-6 p-md-6">
                        <label className="labels">Fecha inicio de vigencia del funcionario<span className="require">*</span></label>
                        <Calendar className={dateStartErrorOfficial ? "p-invalid" : ''} value={dateStartOfficial} onChange={onDateStartChange} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha inicio de vigencia" />
                        <p className="mensajeError">{dateStartErrorOfficial ? messageDateStartOfficial : ""}</p>
                    </div>

                    <div className="p-field p-col-6 p-md-6">
                        <label className="labels">Fecha fin de vigencia del funcionario<div >{validateStarts ? <span className="require">*</span> : ''}</div></label>
                        <Calendar value={dateEndOfficial} onChange={onDateEndChange} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha fin de vigencia" />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AñadirFuncionarios