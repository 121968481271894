import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import { TabView, TabPanel } from 'primereact/tabview';
import { AutoComplete } from 'primereact/autocomplete';
import { Tooltip } from 'primereact/tooltip';
import DataTable from "../PersonasInteresadas/DataTable"
import Services from "../../service/Services";
import axios from "axios";
import { CambiarFormatoFecha, isValidDate } from "../Funciones";

const Block3 = ({ permissionsProp, getProjectsProp, getClientsProp, setSelectedInterestedPersonProp, objectMonthlyReportDataProp, readReportProp, listReportsUsersProp,
    runRenderProp, selectedInterestedPersonProp, allDataProp, showAllDataProp, monthlyReportProp, loadingProp, monthlyReportDataAllDataProp, setListInterestedPersonProp,
    servicesWorkTeamProp, periodDateStartProp, periodDateEndProp, servicesClientsProjectUsersProp, projectAssignmentsProp }) => {

    const [interestedPersons, setInterestedPersons] = useState([]);
    const [sendInterestedPersons, setSendInterestedPersons] = useState([]);
    const [sendInterestedPersonsExport, setSendInterestedPersonsExport] = useState([]);
    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [servicesInterestedPerson, setServicesInterestedPerson] = useState([]);

    const getServices = new Services();

    const [idProject, setIdProject] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [disabledIndex, setDisabledIndex] = useState(false);

    const [runRenderBlock, setRunRenderBlock] = useState(0);

    useEffect(() => {
        setProjectsUserSession(servicesClientsProjectUsersProp)
    }, [servicesClientsProjectUsersProp])


    useEffect(() => {
        const source = axios.CancelToken.source()
        getServices.getInterestedPerson().then(data => {
            if (data !== undefined) {
                setServicesInterestedPerson(data)
            }
        })
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [runRenderBlock, objectMonthlyReportDataProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const newProjecAssignment = projectAssignmentsProp.map(item => {
            return {
                dateEndValidity: item.dateEndValidity,
                dateStartValidity: item.dateStartValidity,
                id: item.id,
                project: item.project
            }
        })
        setSelectedProject(newProjecAssignment)
        setItemsSelectedProject(projectAssignmentsProp.length)
        setActiveIndex(0)

    }, [projectAssignmentsProp, readReportProp, listReportsUsersProp, runRenderBlock]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loadingProp === true) {
            setDisabledIndex(true)
        } else {
            setDisabledIndex(false)
        }
    }, [runRenderProp, loadingProp])

    useEffect(() => {
        if (showAllDataProp === true) {
            let newAllDataProp = []
            // Nuevo array para almacenar los datos filtrados
            const newDataFiltered = [];
            allDataProp.forEach(item => {
                var workTeamUser = []
                servicesWorkTeamProp.forEach(element => {
                    element.lider.forEach(x => {
                        if (x.colaborador.idusuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(periodDateStartProp),
                                CambiarFormatoFecha(periodDateEndProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                    element.usuarios.forEach(x => {
                        if (x.usuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(periodDateStartProp),
                                CambiarFormatoFecha(periodDateEndProp))) {
                                workTeamUser.push(element)
                            }
                        }
                    })
                })

                var period = JSON.parse(item.periodo)
                var interestedPersons = JSON.parse(item.personasinteresadas)
                var newGetInterestedPersonFilter = []
                interestedPersons?.forEach(i => {
                    const filter = servicesInterestedPerson.filter(obj => {
                        return parseInt(obj.id) === parseInt(i.id)
                    })
                    filter.forEach(x => {
                        newGetInterestedPersonFilter.push(x)
                    })
                })

                interestedPersons.forEach(i => {
                    newGetInterestedPersonFilter.forEach(y => {
                        //Obtener el proyecto asociado a la persona interesada que esté en el informe
                        // con el i.id === y.id valido que si esa persona está seleccionada para ese proyecto
                        const result = y.proyectos.filter(pro => {
                            return parseInt(pro.proyecto.id) === parseInt(i.proyecto) && parseInt(i.id) === parseInt(y.id)
                        })
                        result.forEach(r => {
                            newAllDataProp.push({
                                cliente: item.cliente.razonsocial,
                                periodo: new Date(period.dateEnd).toLocaleString("es-US", { month: "long" }) + ' ' + new Date(period.dateEnd).getFullYear(),
                                equipoTrabajo: workTeamUser.length !== 0 ? workTeamUser[0].nombreequipotrabajo : '',
                                nombreColaborador: item.usuario.nombre + ' ' + item.usuario.apellido,
                                personainteresada: y.nombre + ' ' + y.apellido,
                                empresa: y.empresa,
                                cargo: y.cargo.cargo,
                                proyecto: r.proyecto.nombreproyecto,
                                id: y.id
                            })
                        })
                    })
                })
            })

            const uniqueCombos = {};
            // Recorrer el array original y filtrar los datos
            for (const data of newAllDataProp) {
                const comboKey = `${data.nombreColaborador}-${data.proyecto}-${data.personainteresada}`;

                if (!uniqueCombos[comboKey]) {
                    uniqueCombos[comboKey] = true;
                    newDataFiltered.push(data);
                }
            }

            setSendInterestedPersonsExport(newDataFiltered)
        } else {
            if (servicesInterestedPerson.length !== 0) {
                setSendInterestedPersons(servicesInterestedPerson)
            }
            var arrInterestedPerson = []
            const result = servicesInterestedPerson?.map(item => {
                return item.proyectos.map(j => {
                    var boolean
                    if (j.proyecto.id === parseInt(idProject)) {
                        boolean = item
                    }
                    return boolean
                })
            })
            result.forEach(i => {
                i.forEach(j => {
                    if (j !== undefined) {
                        if (j.length !== 0) {
                            arrInterestedPerson.push(j)
                        }
                    }
                })
            })
            var newArr = []
            for (var i = 0; i < servicesInterestedPerson.length; i++) {
                var equal = false;
                for (var j = 0; j < arrInterestedPerson.length & !equal; j++) {
                    if (servicesInterestedPerson[i]['id'] === arrInterestedPerson[j]['id'])
                        equal = true;
                }
                if (!equal) newArr.push(servicesInterestedPerson[i]);
            }
            var newData = newArr.map(item => {
                return {
                    actualizadopor: item.actualizadopor,
                    apellido: item.apellido,
                    cargo: item.cargo,
                    creadopor: item.creadopor,
                    empresa: item.empresa,
                    estado: item.estado,
                    fechaactualizado: item.fechaactualizado,
                    fechacreacion: item.fechacreacion,
                    nombre: item.nombre,
                    nombrecompleto: item.nombre + " " + item.apellido,
                    numerodocumento: item.numerodocumento,
                    proyectos: item.proyectos,
                    id: item.id,
                    tipodocumento: item.tipodocumento,
                    usuario: item.usuario
                }
            })
            setInterestedPersons(newData)
            setListInterestedPersonProp(servicesInterestedPerson)
        }


    }, [idProject, runRenderBlock, showAllDataProp, allDataProp, servicesInterestedPerson, servicesWorkTeamProp, getClientsProp, periodDateStartProp, periodDateEndProp])// eslint-disable-line react-hooks/exhaustive-deps


    /* console.log(sendInterestedPersons) */
    // obtener datos del select proyecto

    const [selectedProject, setSelectedProject] = useState(null)

    const projects = projectAssignmentsProp.map(item => {
        const obj = { project: item.project, id: item.id, dateStartValidity: item.dateStartValidity, dateEndValidity: item.dateEndValidity }
        return obj
    })

    const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
    const onProjectChange = (e) => {
        if (e.value !== null) {
            setItemsSelectedProject(e.value.length)
        }
        setSelectedProject(e.value)
    }

    // buscar persona interesada

    const [selectedInterestedPersonComplete, setSelectedInterestedPersonComplete] = useState(null);
    const [filteredInterestedPerson, setFilteredinterestedPerson] = useState(null)

    const searchInterestedPerson = (event) => {
        setTimeout(() => {
            let _filteredInterestedPerson;
            if (!event.query.trim().length) {
                _filteredInterestedPerson = [...interestedPersons];
            } else {
                _filteredInterestedPerson = interestedPersons.filter((object) => {
                    return object.nombrecompleto.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredinterestedPerson(_filteredInterestedPerson);
        }, 250);
    }

    const onInterestedPersonChange = (e) => {
        setSelectedInterestedPersonComplete(e.value)
    }

    // almacenar variables para enviar por props 

    var lastName
    var position
    var createdBy
    var company
    var creationDate
    var name
    var numberDocument
    var selectedProjectsOld
    var id
    var documentType

    if (selectedInterestedPersonComplete !== null) {
        if (typeof selectedInterestedPersonComplete === 'object') {
            lastName = selectedInterestedPersonComplete.apellido
            position = selectedInterestedPersonComplete.cargo.id
            createdBy = selectedInterestedPersonComplete.creadopor
            company = selectedInterestedPersonComplete.empresa
            creationDate = selectedInterestedPersonComplete.fechacreacion
            name = selectedInterestedPersonComplete.nombre
            numberDocument = selectedInterestedPersonComplete.numerodocumento
            selectedProjectsOld = selectedInterestedPersonComplete.proyectos.map(item => {
                return {
                    idCliente: item.proyecto.cliente.id,
                    estado: item.estado,
                    id: item.proyecto.id,
                    idR: item.id,
                    name: item.proyecto.nombreproyecto
                }
            })
            id = selectedInterestedPersonComplete.id
            if (selectedInterestedPersonComplete.tipodocumento !== null) {
                documentType = selectedInterestedPersonComplete.tipodocumento.id
            } else {
                documentType = null
            }
        }
    }

    var sendObjUser = {
        lastName: lastName,
        position: position,
        createdBy: createdBy,
        company: company,
        creationDate: creationDate,
        name: name,
        numberDocument: numberDocument,
        selectedProjectsOld: selectedProjectsOld,
        id: id,
        documentType: documentType,
        selectedInterestedPersonComplete: selectedInterestedPersonComplete
    }

    var condition = false

    if (projectAssignmentsProp !== null && selectedProject !== null) {
        if (projectAssignmentsProp.length !== 0) {
            condition = true
        }
    }

    var showButtonCreate = false

    if (typeof selectedInterestedPersonComplete === 'string') {
        showButtonCreate = true
    } else if (typeof selectedInterestedPersonComplete === 'object') {
        showButtonCreate = false
    }

    return (
        <>
            <div >
                <h3 className="labels" style={{ marginBottom: '10px' }}>Personas Interesadas</h3>
                <span className="text">Personas externas al equipo de trabajo de Linexperts que patrocinan, supervisan, son aliados o clientes del proyecto:</span>
            </div>
            {showAllDataProp ?
                null
                :
                <>
                    <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                                <label className="labels" >Proyectos:</label>
                                <MultiSelect value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                    emptyFilterMessage="No hay resultados" name="project" optionLabel="project" showClear
                                    maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`}
                                />
                            </div>
                        </div>
                    </div>
                    {condition ?
                        readReportProp ?
                            null
                            :
                            <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                                <div style={{ display: 'contents' }} >
                                    <div className="p-field p-col-12 p-md-5" style={{ marginTop: '5px' }}>
                                        <label className="labels" >Buscar persona interesada para asignar proyecto
                                            <Tooltip target=".custom-target-icon" autoHide={false} />
                                            <i className="custom-target-icon pi pi-question-circle" style={{ marginLeft: '5px' }}
                                                data-pr-tooltip="Debe buscar una persona interesada para asignarle un nuevo proyecto, si esta persona no aprece en la 
                                    lista de sugerencias y tampoco en la tabla, se habilitará un botón para crear una nueva persona interesada"
                                                data-pr-position="right" mousetrack="true" mousetrackleft={10} data-pr-at="right+1 top" data-pr-my="left center+6"
                                            ></i>
                                        </label>
                                        <AutoComplete value={selectedInterestedPersonComplete} suggestions={filteredInterestedPerson} completeMethod={searchInterestedPerson} field="nombrecompleto"
                                            dropdown filterMatchMode="startsWith" onChange={onInterestedPersonChange} placeholder="Buscar persona interesada"
                                            className="auto"
                                        />
                                    </div>
                                </div>
                            </div>
                        :
                        ""
                    }
                </>
            }
            <div className="tabview-demo">
                <div className="card">
                    {showAllDataProp === true ?
                        <DataTable
                            setIdProjectProp={setIdProject}
                            permissionsProp={permissionsProp}
                            individualMonthReportProp={true}
                            fullTable={true}
                            getClientsProp={getClientsProp}
                            showButtonCreateProp={showButtonCreate}
                            selectedInterestedPersonCompleteProp={selectedInterestedPersonComplete}
                            sendObjUserProp={sendObjUser}
                            setSelectedInterestedPersonProp={setSelectedInterestedPersonProp}
                            selectedInterestedPersonProp={selectedInterestedPersonProp}
                            objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                            readReportProp={readReportProp}
                            listReportsUsersProp={listReportsUsersProp}
                            setSelectedProjectProp={setSelectedProject}
                            setDisabledIndexProp={setDisabledIndex}
                            disabledIndexProp={disabledIndex}
                            sendInterestedPersonsProp={sendInterestedPersonsExport}
                            runRenderBlockProp={runRenderBlock}
                            setRunRenderBlockProp={setRunRenderBlock}
                            projectsUserSessionProp={projectsUserSession}
                            showAllDataProp={showAllDataProp}
                            loadingProp={loadingProp}
                            servicesInterestedPersonProp={servicesInterestedPerson}
                        />
                        :
                        <TabView scrollable >
                            {condition ?
                                selectedProject.map((element, i) => {
                                    var panel = (
                                        <TabPanel header={element.project} disabled={disabledIndex} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}
                                            key={i}
                                        >
                                            <div className="datatable-responsive-demo">
                                                <div className="card">
                                                    <DataTable
                                                        setIdProjectProp={setIdProject}
                                                        permissionsProp={permissionsProp}
                                                        individualMonthReportProp={true}
                                                        fullTable={true}
                                                        projectProp={element.project}
                                                        idProjectProp={element.id}
                                                        getClientsProp={getClientsProp}
                                                        showButtonCreateProp={showButtonCreate}
                                                        selectedInterestedPersonCompleteProp={selectedInterestedPersonComplete}
                                                        sendObjUserProp={sendObjUser}
                                                        setSelectedInterestedPersonProp={setSelectedInterestedPersonProp}
                                                        selectedInterestedPersonProp={selectedInterestedPersonProp}
                                                        objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                                                        readReportProp={readReportProp}
                                                        listReportsUsersProp={listReportsUsersProp}
                                                        setSelectedProjectProp={setSelectedProject}
                                                        setDisabledIndexProp={setDisabledIndex}
                                                        disabledIndexProp={disabledIndex}
                                                        sendInterestedPersonsProp={sendInterestedPersons}
                                                        runRenderBlockProp={runRenderBlock}
                                                        setRunRenderBlockProp={setRunRenderBlock}
                                                        projectsUserSessionProp={projectsUserSession}
                                                        showAllDataProp={showAllDataProp}
                                                        monthlyReportDataAllDataProp={monthlyReportDataAllDataProp}
                                                        loadingProp={loadingProp}
                                                        periodDateStartProp={periodDateStartProp}
                                                        periodDateEndProp={periodDateEndProp}
                                                    />
                                                </div>
                                            </div>
                                        </TabPanel>
                                    )
                                    return panel
                                })
                                :
                                <TabPanel header="Seleccione un proyecto">
                                </TabPanel>
                            }
                        </TabView>
                    }
                </div>
            </div>
        </>
    )
}

export default Block3