import React, { useState, useRef } from 'react';
import '../assets/css/Index.css'
import '../assets/css/Forms.css'
import '../assets/css/Recuperar.css'
import '../assets/css/ToastDemo.css'
import { Link } from 'react-router-dom'
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import IMG from '../assets/images/Logo_Timevalue _transpa_sin_slogan.png';
import { Divider } from 'primereact/divider';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { GetParameterByName } from '../componets/Funciones';


const cookies = new Cookies();

const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/updatePassword'

const RestablecerContarseña = () => {

    const toast = useRef(null);

    const cancelar = () => {
        cookies.remove('idUsuario', { path: "/" })
        cookies.remove('code', { path: "/" })
        cookies.remove('message', { path: "/" })
        cookies.remove('usuario', { path: "/" })
        cookies.remove('cargo', { path: "/" })
        cookies.remove('nombre', { path: "/" })
        cookies.remove('numeroDocumento', { path: "/" })
        cookies.remove('tipoDocumento', { path: "/" })
        window.location.href = "/"
    }

    const [inputContraseña1, setInputContraseña1] = useState({
        contraseña1: ''
    });

    const [inputContraseña2, setInputContraseña2] = useState({
        contraseña2: ''
    });

    const onContraseña1Change = (event) => {

        setInputContraseña1({
            ...inputContraseña1,
            [event.target.name]: event.target.value
        })
    }
    const onContraseña2Change = (event) => {

        setInputContraseña2({
            ...inputContraseña2,
            [event.target.name]: event.target.value
        })
    }

    const password1 = inputContraseña1.contraseña1
    const password2 = inputContraseña2.contraseña2

    const [validateInput, setValidateInput] = useState(false)
    const [validateMessage, setValidateMessage] = useState('')

    const [validateInput2, setValidateInput2] = useState(false)
    const [validateMessage2, setValidateMessage2] = useState('')

    var urlUsuario = GetParameterByName('usuario');
    const username = cookies.get('usuario')
    
    var enviarUsuario = ""
    if (urlUsuario) {
        enviarUsuario = urlUsuario
    } 
    if (username) {
        enviarUsuario = username
    } 

    const ActualizarContraseña = async (username, password2) => {

        const json = JSON.stringify({ contrasena: password2, usuario: enviarUsuario })
        /* console.log(json) */
        await axios.post(url, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(response => {
                //console.log(response.data)
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                window.location.href = "/"
            })
            .catch(function (error) {
                if(error.response.status === 404){
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 3000 });
                }
                //console.log(error.response)
            })
        
    }

    const validacion = (e,) => {
        e.preventDefault();

        if (password1 === "") {
            setValidateInput(true)
            setValidateMessage('Debes ingresar una contraseña')
        } else if (password1.length < 5) {
            setValidateInput(true)
            setValidateMessage('La contraseña debe tener más de 5 caracteres')
        } else if (password1.length > 15) {
            setValidateInput(true)
            setValidateMessage('La contraseña debe tener menos de 15 caracteres')
        } else if (password1.length > 5 && password1.length < 15) {
            var validarContraseña = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*["-'_;+/#¡()><¿=°$@$!%*?&])([A-Za-z\d$@$!%*?:&]|[^ ]){5,15}$/;
            var esValido = validarContraseña.test(password1)
            if (esValido === false) {
                setValidateInput(true)
                setValidateMessage('La contraseña debe tener una minúscula, una mayúscula, un número, un caracter especial y sin espacios')
            } else if (password1 !== password2) {
                setValidateInput(true)
                setValidateMessage('Las contraseñas no coinciden')
                setValidateInput2(true)
                setValidateMessage2('Las contraseñas no coinciden')

            } else {
                setValidateMessage('')
                setValidateInput(false)
                setValidateMessage2('')
                setValidateInput2(false)

                ActualizarContraseña(username, password2)
            }
        }

    }


    return (
        <div className="body">
            <div className="recuperar">
                <Toast ref={toast} />
                <div className="Form"><img src={IMG} className="img" alt="LogoTipo TimeValue" />
                    <h3 className="Tittle" >Restablecer contraseña</h3>
                    <Divider />
                    <form onSubmit={validacion}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Nueva contraseña</label>
                                <Password onChange={onContraseña1Change} name="contraseña1" placeholder="Ingresar Contraseña" toggleMask feedback={false}
                                    className={validateInput ? "p-invalid" : ""} />
                                <p className="mensajeError">{validateMessage}</p>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels">Confirmar contraseña</label>
                                <Password onChange={onContraseña2Change} name="contraseña2" placeholder="Confirmar Contraseña" toggleMask feedback={false}
                                    className={validateInput2 ? "p-invalid" : ""} />
                                <p className="mensajeError">{validateMessage2}</p>
                            </div>
                        </div>
                        <div className="contBtn">
                            <Link to="" onClick={() => cancelar()} className="btn">Cancelar</Link>
                            <button className="btn">Actualizar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )


}

export default RestablecerContarseña