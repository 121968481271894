import React, { useEffect } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import MobileSidebar from './MobileSidebar'
import Jwt from 'jsonwebtoken'
const SidebarMenu = ({ accesoProp, noShowProp }) => {

    useEffect(() => {
        const checkTokenExpiration = () => {
            try {
                // Decodifica el token
                const decodedToken = Jwt.decode(sessionStorage.getItem('token'));
                /*Se multiplica * 1000 para convertirlos a milisegundos que es el
                formato que se necesita para convertirlo a objeto Date  */
                const expirationDate = new Date(decodedToken.exp * 1000);

                // Verifica la fecha de expiración
                if (expirationDate < Date.now()) {
                    // La fecha de expiración ha pasado, realiza la lógica para desconectar al usuario
                    /* console.log('Token expirado'); */
                    window.location.href = '/registroActividades'
                }
            } catch (error) {
                /* console.error('Error al decodificar el token:', error); */
            }
        };

        // Ejecuta la verificación cada minuto (puedes ajustar el intervalo según tus necesidades)
        const intervalId = setInterval(checkTokenExpiration, 60000);

        // Limpia el intervalo al desmontar el componente
        return () => clearInterval(intervalId);
    }, []);

    return (

        <>
            <Header />
            <MobileSidebar
                acceso={accesoProp}
                noShowProp={noShowProp}
            />
            <Sidebar
                acceso={accesoProp}
                noShowProp={noShowProp}
            />
        </>
    )
}

export default SidebarMenu
