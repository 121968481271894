import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { CambiarFormatoFecha, SumarDias, Validarfechas } from '../Funciones';
import ConfirmBtn from "../ConsultarUsuarios/ConfirmBtn";
import Cookies from "universal-cookie";
import { Toast } from "primereact/toast";

const BtnEditarFuncionarios = ({ nameOfficial, idOficcial, dateStartOfficial, dateEndOfficial,
    idOfficialProp, dateStartTeam, dateEndTeam, idClientProp, createdByProp, idRegister, dateCreatedProp,
    updateDateStartProp, updateDateEndProp, updateCoordinatorProp, nameTeamProp = {}, runRenderProp, setRunRenderProp, registerIdOfficial, dateCreatedOfficialProp, idCoordinatorProp, informationOfficialProp, serviceUsersProp }) => {

    const toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false)
    const [validateChange, setValidateChange] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [addOfficial, setAddOfficial] = useState([])

    useEffect(() => {
        setAddOfficial(serviceUsersProp)

    }, [serviceUsersProp]) // eslint-disable-line react-hooks/exhaustive-deps

    const [official, setOfficial] = useState({
        name: nameOfficial,
        id: idOficcial
    })


    const getAllOfficial = addOfficial.map(item => {
        const av = { name: item.nombres + ' ' + item.apellidos, id: item.idUsuario }
        return av
    })

    getAllOfficial.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    const onOfficialChange = (e) => {
        setOfficial(e.value)
        setValidateChange(true)
    }

    function disabledOfficial(e) {
        var disabled = false
        idOfficialProp.forEach(x => {
            if (x.id === e.id) {
                disabled = true
            }
            else if (e.id === idCoordinatorProp) {
                disabled = true
            }
            if (x.fechainicioasignacion !== null && x.fechafinasignacion === null) {
                if (x.idUsuario === e.id) {
                    disabled = true
                }
            }
        })
        return disabled
    }

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;

    const [dateStartOfficials, setDateStartOfficials] = useState(null)

    const onStartOfficialChange = (e) => {
        setDateStartOfficials(e.value)
        setValidateChange(true)
    }

    const [dateEndOfficials, setDateEndOfficials] = useState(null)

    const onEndOfficialChange = (e) => {
        setDateEndOfficials(e.value)
        setValidateChange(true)

    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio

    useEffect(() => {

        const dateStartChange = new Date(dateStartOfficial)

        if (gmtHours < 0) {
            setDateStartOfficials(SumarDias(dateStartChange, +1))
        }
        else {
            setDateStartOfficials(dateStartChange)
        }

        if (dateEndOfficial !== null) {

            const dateEndChange = new Date(dateEndOfficial)

            if (gmtHours < 0) {
                setDateEndOfficials(SumarDias(dateEndChange, +1))
            }
            else {
                setDateEndOfficials(dateEndChange)
            }
        }
        else {
            setDateEndOfficials(null)
        }

        setOfficial({
            name: nameOfficial,
            id: idOficcial
        })

    }, [nameOfficial, idOficcial, dateStartOfficial, dateEndOfficial, gmtHours, dateStartTeam, dateEndTeam, informationOfficialProp])

    const resetData = () => {
        setOfficial({
            name: nameOfficial,
            id: idOficcial
        })

        const dateStartChange = new Date(dateStartOfficial)

        if (gmtHours < 0) {
            setDateStartOfficials(SumarDias(dateStartChange, +1))
        }
        else {
            setDateStartOfficials(dateStartChange)
        }

        if (dateEndOfficial !== null) {

            const dateEndChange = new Date(dateEndOfficial)

            if (gmtHours < 0) {
                setDateEndOfficials(SumarDias(dateEndChange, +1))
            }
            else {
                setDateEndOfficials(dateEndChange)
            }
        }
        else {
            setDateEndOfficials(null)
        }
        setValidateChange(false)
    }
    const ModalRegresar = ({ displayBasicProp }) => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            dialogFuncMap[`${name}`](false)
            setDisplayBasic(displayBasicProp)
            resetData()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    //Validar fechas
    var dateStartError = false
    var messageDateStart = ''

    const dataOfficial = () => {
        var newInformationOfficial = informationOfficialProp.filter(function (object) {
            var idUser = object.idUsuario
            return idUser === official.id && object.id !== registerIdOfficial
        })
        return newInformationOfficial
    }

    const returnArrayValidate = () => {
        var newInformationOfficial = dataOfficial()
        const validateInformationOfficial = []
        newInformationOfficial.forEach(element => {
            if (element.fechafinasignacion === null) {
                validateInformationOfficial.push(element)
            }
        })
        return validateInformationOfficial
    }

    const returnArrayValidateRange = (dateStart, dateEnd) => {
        var newInformationOfficial = dataOfficial()
        const validateInformationOfficial = []
        newInformationOfficial.forEach(element => {
            if (element.fechafinasignacion !== null) {
                if ((dateStart <= element.fechafinasignacion && dateEnd >= element.fechainicioasignacion)
                    || (dateEnd >= element.fechafinasignacion && dateStart <= element.fechainicioasignacion)) {
                    validateInformationOfficial.push(element)
                }
            }
        })
        return validateInformationOfficial
    }

    const validateDateStartRange = (date) => {
        var newInformationOfficial = dataOfficial()
        const validateInformationOfficial = []
        newInformationOfficial.forEach(element => {
            if (date >= element.fechainicioasignacion && date <= element.fechafinasignacion) {
                validateInformationOfficial.push(element)
            }
        })
        return validateInformationOfficial
    }

    const validateDateStartOfficial = (date) => {
        var arrayOfficial = returnArrayValidate()
        var rangeDateStart = validateDateStartRange(date)
        var data = dataOfficial()
        if (dateEndTeam === null) {
            if (date < dateStartTeam) {
                dateStartError = true
                messageDateStart = 'La fecha inicio debe ser mayor a la fecha inicio del equipo'
            }

        }
        if (arrayOfficial.length !== 0) {
            dateStartError = true
            messageDateStart = 'No puede dejar esta asociación sin fecha fin de vigencia, ya que existe otra asociación que no la tiene'
        } else if (rangeDateStart.length !== 0) {
            dateStartError = true
            messageDateStart = 'La fecha inicio se cruza con fechas ya asignadas'
        } else if (data.length !== 0) {
            data.sort(function (a, b) {
                var textA = new Date(a.fechafinasignacion)
                var textB = new Date(b.fechafinasignacion)
                return textB - textA
            })
            if (date <= data[0].fechainicioasignacion) {
                dateStartError = true
                messageDateStart = 'La fecha inicio no puede ser menor a fechas ya asignadas'
            }
        }
        /* console.log('executed') */
    }

    const validateRangeOfficial = (dateStart, dateEnd) => {
        var arrayOfficial = returnArrayValidate()
        var dates = Validarfechas(dateStart, dateEnd)
        if (arrayOfficial.length !== 0) {
            if (dateEnd >= arrayOfficial[0].fechainicioasignacion) {
                dateStartError = true
                messageDateStart = 'La fecha fin no puede ser mayor a la fecha inicio de una vigencia abierta'
            }

        }
        if (dates.setFechaError === true) {
            dateStartError = dates.setFechaError
            messageDateStart = dates.setMensajeFecha
        } else if (dateEndTeam === null) {
            if (dateStart >= dateStartTeam && dateEnd >= dateStartTeam) {
                dateStartError = false
                messageDateStart = ''
            } else {
                dateStartError = true
                messageDateStart = 'La fecha inicio y fin debe ser mayor a la fecha inicio del equipo'
            }
        } else if (dateStartTeam !== null && dateEndTeam !== null) {
            if (dateStart >= dateStartTeam && dateStartTeam <= dateEndTeam &&
                dateEnd >= dateStartTeam && dateEnd <= dateEndTeam) {
                dateStartError = false
                messageDateStart = ''
            } else {
                dateStartError = true
                messageDateStart = 'La fecha inicio y fin debe estar en el rango de fechas asignadas al equipo '
            }

            var arrayOfficialRange = returnArrayValidateRange(dateStart, dateEnd)
            if (arrayOfficialRange.length !== 0) {
                dateStartError = true
                messageDateStart = 'La fecha inicio y fin de vigencias se cruzan con las de otras asignaciones para el mismo funcionario'
            }

        }
        /* console.log(arrayOfficial) */
    }

    var newDateStart = dateStartOfficials === null ? dateStartOfficials : CambiarFormatoFecha(dateStartOfficials)
    var newDateEnd = dateEndOfficials === null ? dateEndOfficials : CambiarFormatoFecha(dateEndOfficials)

    if (dateStartOfficials !== null && dateEndOfficials === null) {
        if (dateStartTeam !== null && dateEndTeam !== null) {
            dateStartError = true
            messageDateStart = 'La fecha fin no puede quedar vacia débido a que el equipo tiene una fecha fin registrada'
        }
        else if (dateStartTeam !== null && dateEndTeam === null) {
            validateDateStartOfficial(newDateStart)
        }
    } else if (dateStartOfficials !== null && dateEndOfficials !== null) {
        validateRangeOfficial(newDateStart, newDateEnd)
    }


    //Almaceno los valores que se enviarán por props
    const cookies = new Cookies()

    const usuarioSesion = cookies.get('usuario')

    const updateOfficial = [{
        id: registerIdOfficial,
        usuario: {
            id: official.id
        },
        fechacreacion: dateCreatedOfficialProp,
        fechainicioasignacion: dateStartOfficials === null ? dateStartOfficials : CambiarFormatoFecha(dateStartOfficials),
        fechafinasignacion: dateEndOfficials === null ? dateEndOfficials : CambiarFormatoFecha(dateEndOfficials),
        estado: dateEndOfficials !== null ? CambiarFormatoFecha(dateEndOfficials) < CambiarFormatoFecha(new Date()) ? 0 : 1 : 1
    }]

    //Validar que no se crucen las fechas del usuario en diferentes equipos de trabajo
    let datedCrusses = false

    if (displayBasic === true) {

        const filter = addOfficial?.filter(obj => {
            return parseInt(obj.idUsuario) === parseInt(official?.id)
        })

        function isDateInRange(date, rangeStart, rangeEnd) {
            const targetDate = new Date(date);
            const startDate = new Date(rangeStart);
            const endDate = rangeEnd ? new Date(rangeEnd) : null;

            if (endDate) {
                return targetDate >= startDate && targetDate <= endDate;
            } else {
                return targetDate >= startDate;
            }
        }

        function rangesOverlap(start1, end1, start2, end2) {
            const range1Start = new Date(start1);
            const range1End = end1 ? new Date(end1) : null;
            const range2Start = new Date(start2);
            const range2End = end2 ? new Date(end2) : null;

            if (range1End && range2End) {
                return range1Start <= range2End && range1End >= range2Start;
            } else if (range1End) {
                return range1Start <= range2Start && range1End >= range2Start;
            } else if (range2End) {
                return range1Start <= range2End && range2Start >= range1Start;
            } else {
                return range1Start <= range2Start;
            }
        }

        function validateRange(startDate, endDate, ranges) {
            for (const range of ranges) {
                const { fechainicioasignacion, fechafinasignacion } = range;
                if (
                    isDateInRange(startDate, fechainicioasignacion, fechafinasignacion) ||
                    (endDate && isDateInRange(endDate, fechainicioasignacion, fechafinasignacion)) ||
                    (endDate && rangesOverlap(startDate, endDate, fechainicioasignacion, fechafinasignacion))
                ) {
                    return false;
                }
            }
            return true;
        }

        // Validar que no se crucen fechas con otros equipos en los que esté asociado el usuario
        if (dateStartOfficial !== null) {
            filter?.forEach(i => {
                let ranges = i.equipoTrabajoLider?.concat(i.equipoTrabajoUsuario);

                ranges.forEach(range => {
                const isValid = validateRange(
                    CambiarFormatoFecha(dateStartOfficial),
                    dateEndOfficial ? CambiarFormatoFecha(dateEndOfficial) : null,
                    ranges
                );

                if (!isValid) {
                    datedCrusses = true;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Fechas Cruzadas',
                        detail: `El usuario seleccionado se cruza con fechas ya creadas para un equipo de trabajo`,
                        life: 4000
                    });
                }
                });
         });
        }
    }

    var createPermissionSave = false

    if (official.id !== '' && dateStartOfficials !== null && dateStartError === false
        && datedCrusses === false
    ) {
        createPermissionSave = true
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    createPermissionSave ?
                        <ConfirmBtn
                            titulo='Equipo de trabajo'
                            mensaje='¿Desea actualizar el funcionario?'
                            accion='ActualizarFuncionario'
                            label='Actualizar'
                            icono='pi pi-pencil'
                            updateDateProp={true}
                            usuarioSesionProp={usuarioSesion}
                            updateClientTeam={idClientProp}
                            createByProp={createdByProp}
                            idUpdate={idRegister}
                            dateCreateProp={dateCreatedProp}
                            updateDateEndProp={updateDateEndProp}
                            updateDateStartProp={updateDateStartProp}
                            updateCoordinatorTeam={updateCoordinatorProp}
                            updateNameTeam={nameTeamProp}
                            updateOfficialTeam={updateOfficial}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicOfficialProp={setDisplayBasic}
                            editOfficialProp={true}
                        />
                        :
                        ''

                }

            </div>
        )
    }

    return (
        <>
            <Toast ref={toast} />

            <Button icon="pi pi-pencil" className="p-button-secondary btnEditar" onClick={() => {
                onClick('displayBasic')
                resetData()
            }} />

            <Dialog className="modal" header="Editar Funcionarios" visible={displayBasic} footer={renderFooter('displayBasic')}
                onHide={() => onHide('displayBasic')}>

                <div className="p-fluid p-grid p-formgrid">

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels" htmlFor="tipoDoc">Nombre del funcionario<span className="require">*</span></label>
                        <Dropdown value={official} filter options={getAllOfficial} onChange={onOfficialChange} optionLabel="name"
                            emptyMessage='No hay resultados' placeholder="Seleccione un funcionario" optionDisabled={disabledOfficial} />
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels" htmlFor="tipoDoc">Fecha inicio vigencia del funcionario<span className="require">*</span></label>
                        <Calendar value={dateStartOfficials} className={dateStartError ? "p-invalid" : ""} onChange={onStartOfficialChange} placeholder="Seleccione una fecha inicio de vigencia" dateFormat="dd-mm-yy" locale="es" />
                        <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label className="labels" htmlFor="tipoDoc">Fecha fin vigencia del funcionario</label>
                        <Calendar value={dateEndOfficials} onChange={onEndOfficialChange} placeholder="Seleccione una fecha fin de vigencia" dateFormat="dd-mm-yy" locale="es" />
                    </div>

                </div>
            </Dialog>
        </>
    )

}

export default BtnEditarFuncionarios