import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import BtnAddSupplier from './BtnAddSupplier';
import { EstandarFormatoFecha, SumarDias } from '../Funciones';
import ConfirmBtn from './ConfirmBtn';
import BtnUpdateDatail from './BtnUpdateDetail';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ClienteProveedor = ({ suppliersProp, permisoClientCreateProp, permisoClientUpdateProp, 
    permisoClientDeleteProp, dataClientProp, renderProp, setRenderProp, linkPowerBiProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [newSuppliers, setNewSuppliers] = useState([]);
    const [render, setRender] = useState(0);
    useEffect(() => {
        var newSuppliersProp = suppliersProp.map(item => {
            var status
            if (item.estado === 1) {
                status = "Vigente"
            } else if (item.estado === 0) {
                status = "No vigente"
            }
            var d = new Date()
            var gtmHours = -d.getTimezoneOffset() / 60
            var formatDateStart, formatDateEnd

            if (item.fechainicioasignacion && item.fechafinasignacion) {
                if (gtmHours < 0) {
                    formatDateStart = SumarDias(new Date(item.fechainicioasignacion), +1)
                    formatDateEnd = SumarDias(new Date(item.fechafinasignacion), +1)
                }
                else {
                    formatDateStart = new Date(item.fechainicioasignacion)
                    formatDateEnd = new Date(item.fechafinasignacion)
                }
            } else {
                formatDateStart = null
                formatDateEnd = null
            }

            return {
                ...item,
                sendDateStart: formatDateStart && EstandarFormatoFecha(formatDateStart),
                sendDateEnd: formatDateEnd && EstandarFormatoFecha(formatDateEnd),
                newStatus: status
            }
        })
        setNewSuppliers(newSuppliersProp.filter(obj => obj.estado !== 3))
    }, [suppliersProp, render]) // eslint-disable-line react-hooks/exhaustive-deps

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoClientCreateProp ?
                <BtnAddSupplier
                    dataClientProp={dataClientProp}
                    renderProp={renderProp}
                    setRenderProp={setRenderProp}
                    renderDetailProp={render}
                    setRenderDetailProp={setRender}
                    suppliersProp={suppliersProp}
                    linkPowerBiProp={linkPowerBiProp}
                />
                :
                ""
            }
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    
    const usuario = cookies.get('usuario')

    const Acciones = (rowData) => {
        var objectSupplier = {
            estado: 3,
            fechafinasignacion: rowData.fechafinasignacion,
            fechainicioasignacion: rowData.fechainicioasignacion,
            id: rowData.id,
            proveedor: {
                id: rowData.proveedor.id
            }
        }
        
        return (
            <>
                {permisoClientUpdateProp ?
                    <BtnUpdateDatail
                        dateStartProp={rowData.fechainicioasignacion}
                        dateEndProp={rowData.fechafinasignacion}
                        supplierProp={rowData.proveedor}
                        idDetailProp={rowData.id}
                        dataClientProp={dataClientProp}
                        renderProp={renderProp}
                        setRenderProp={setRenderProp}
                        suppliersProp={suppliersProp}
                        linkPowerBiProp={linkPowerBiProp}
                    />
                    :
                    ""
                }
                {permisoClientDeleteProp ?
                    <ConfirmBtn
                        titulo="Confirmar Eliminación"
                        mensaje="¿Está seguro de eliminar la asociación con este proveedor?"
                        accion="Actualizar"
                        clase="p-button-danger"
                        icono="pi pi-trash"
                        tooltipProp="Eliminar"
                        tipoDocumentoProp={dataClientProp.tipodocumento}
                        numeroDocumentoProp={dataClientProp.numerodocumento}
                        nombreProp={dataClientProp.razonsocial}
                        dominioProp={dataClientProp.dominio}
                        idClienteProp={dataClientProp.id}
                        creadoporProp={dataClientProp.creadopor}
                        fechacreacionProp={dataClientProp.fechacreacion}
                        usuarioProp={usuario}
                        objectSupplierProp={objectSupplier}
                        showDataProp={false}
                        updateDatailprop={true}
                        renderProp={renderProp}
                        setRenderProp={setRenderProp}
                        linkPowerBiProp={linkPowerBiProp}
                    />
                    :
                    ""
                }
            </>
        );
    }
    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Cliente Proveedores asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={newSuppliers} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para este cliente" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="proveedor.razonsocial" header="Proveedor" />
                            <Column className="columna" field="sendDateStart" header="Fecha inicio de asignación" />
                            <Column className="columna" field="sendDateEnd" header="Fecha fin de asignación" />
                            <Column header="Acciones" body={Acciones} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default ClienteProveedor