import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const BtnCrearAreaNegocio = ({ areasNegocioProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input area de negocio

    const [inputArea, setInputArea] = useState({
        area: ''
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onAreaChange = (event) => {

        setInputArea({
            ...inputArea,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Almacenamos los valores para enviarlos por props

    const area = inputArea.area
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {


            setInputArea({
                area: ''
            })

            setValidarCambio(false)


            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registraresta área de negocio?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            areaProp={area}
                            usuarioProp={usuario}
                        />
                        :
                        ""
                }

            </div>
        );
    }

    //validaciones

    var areaError = false
    var mensajeArea = ''
    if (area.length < 3 && area !== '') {
        areaError = true
        mensajeArea = 'El nombre del área de negocio debe ser mayor a 3 dígitos'
    } else if (area.length > 100) {
        areaError = true
        mensajeArea = 'El nombre del área de negocio debe ser menor a 100 dígitos'
    } 
    var validateErrorDuplicity = false
    function validateDuplicity() {
        areasNegocioProp.forEach(element => {
            const nameArea = removeAccents(element.nombreareanegocio)
            const newNameArea = removeAccents(area)

            if (nameArea.toUpperCase() === newNameArea.toUpperCase()) {
                validateErrorDuplicity = true
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Area pre-existente' });
            }
        })
    }
    if (area !== '') {
        validateDuplicity()
    }
    var clienteError = false
    var mensajeCliente = ''

    var permisoRegistrar = false
    if (areaError === false && area !== '' && clienteError === false && validateErrorDuplicity === false) {
        permisoRegistrar = true
    }
    return (

        <div className="">
            <div>
                <Toast ref={toast} />
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar Área negocio</button>

                <Dialog className="modal" header="Registrar Área De Negocio" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >

                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Área de negocio<span className="require">*</span></label>
                                <InputText id="area" type="text" name="area" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onAreaChange}
                                    placeholder="Registre el área de negocio"
                                    className={areaError || validateErrorDuplicity ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{clienteError || areaError ? mensajeArea || mensajeCliente : ""}</p>
                            </div>
                        </form>

                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearAreaNegocio