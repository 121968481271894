import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { EstandarFormatoFecha, SumarDias } from '../Funciones';


const ProveedorCliente = ({ clientsProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }
    const [newClients, setNewClients] = useState([]);

    useEffect(() => {
        var newClientsProp = clientsProp.map(item => {
            var status
            if (item.estado === 1) {
                status = "Vigente"
            } else if (item.estado === 0) {
                status = "No vigente"
            }
            var d = new Date()
            var gtmHours = -d.getTimezoneOffset() / 60
            var formatDateStart, formatDateEnd

            if (item.fechainicioasignacion && item.fechafinasignacion) {
                if (gtmHours < 0) {
                    formatDateStart = SumarDias(new Date(item.fechainicioasignacion), +1)
                    formatDateEnd = SumarDias(new Date(item.fechafinasignacion), +1)
                }
                else {
                    formatDateStart = new Date(item.fechainicioasignacion)
                    formatDateEnd = new Date(item.fechafinasignacion)
                }
            } else {
                formatDateStart = null
                formatDateEnd = null
            }

            return {
                ...item,
                fechainicioasignacion: formatDateStart && EstandarFormatoFecha(formatDateStart),
                fechafinasignacion: formatDateEnd && EstandarFormatoFecha(formatDateEnd),
                estado: status
            }
        })
        setNewClients(newClientsProp)
    }, [clientsProp]) // eslint-disable-line react-hooks/exhaustive-deps

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );


    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Proveedores clientes asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={newClients} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para este proveedor" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="cliente.razonsocial" header="Cliente" />
                            <Column className="columna" field="fechainicioasignacion" header="Fecha inicio de asignación" />
                            <Column className="columna" field="fechafinasignacion" header="Fecha fin de asignación" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default ProveedorCliente