import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import DataTableEquipoTrabajo from "../componets/EquipoTrabajo/DataTableEquipoTrabajo";
import BreadCrumb from "../componets/Home/BreadCrumb";
import SidebarMenu from "../componets/Home/MenuSidebar/sidebarMenu";

const cookies = new Cookies();

class equipoTrabajo extends React.Component {
    render() {
        const home = (
            <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
        )

        if (!cookies.get('code')) {
            window.location.href = "/singOff"
        }

        const menu = JSON.parse(sessionStorage.getItem("permissions"))
        const permisos = menu.rol

        if (permisos[17].consultar === "false") {
            window.location.href = "/registroActividades/inicio"
        }

        const consultar = (
            <span className="disabled">Gestionar Equipos de Trabajo</span>
        )
        return (

            <div>
                <SidebarMenu
                    accesoProp={permisos}
                    noShowProp={true}
                />

                <div className="content">

                    <BreadCrumb
                        home={home}
                        consultar={consultar}
                    />

                    <div className="container">
                        <h1 >Gestionar Equipos de Trabajo</h1>
                    </div>

                    <div className="tabla">
                        <DataTableEquipoTrabajo />
                    </div>

                </div>
            </div>
        )
    }
}

export default equipoTrabajo