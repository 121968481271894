import React from 'react'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

const DataApprovals = ({ nameUserProp, stylesProp }) => {
    return (
        <Table
            data={[{
                preparedBy: `${nameUserProp}: Consultor de Linexperts Consultoría Empresarial S.A.S`,
                assigenedBy: '',
                firm: '',
                approvalDate: '',
                comments: ''
            }]}
        >
            <TableHeader textAlign={"center"}>
                <TableCell style={stylesProp.textTable}>
                    Preparado por:
                </TableCell>
                <TableCell style={stylesProp.textTable}>
                    Aprobado por:
                </TableCell>
                <TableCell style={stylesProp.textTable}>
                    Firma
                </TableCell>
                <TableCell style={stylesProp.textTable}>
                    Fecha aprobación
                </TableCell>
                <TableCell style={stylesProp.textTable}>
                    Comentarios
                </TableCell>
            </TableHeader>
            <TableBody textAlign={"center"} >
                <DataTableCell getContent={(r) => r.preparedBy} style={stylesProp.textCell}/>
                <DataTableCell getContent={(r) => r.assigenedBy} style={stylesProp.textCell}/>
                <DataTableCell getContent={(r) => r.firm} style={stylesProp.textCell}/>
                <DataTableCell getContent={(r) => r.approvalDate} style={stylesProp.textCell}/>
                <DataTableCell getContent={(r) => r.comments} style={stylesProp.textCell}/>
            </TableBody>
        </Table >
    )
}

export default DataApprovals