import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ConfirmBtn from "./ConfirmBtn";
import Cookies from 'universal-cookie'
const cookies = new Cookies()


const BtnActualizarObjetivo = ({ objectiveProp, idObjectiveProp, creationDateProp, createdByProp, individualMonthReportProp, setRunRenderProp, runRenderProp,
    reloadObjectiveProp, setReloadObjectiveProp, runRenderBlockProp, setRunRenderBlockProp, objectivesProp, createProjectsProp, dataObjetiveProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input objetivo

    const [inputObjective, setInputObjective] = useState({
        objective: objectiveProp
    })

    const onObjectiveChange = (event) => {

        setInputObjective({
            ...inputObjective,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setInputObjective({
            objective: objectiveProp
        })
    }, [objectiveProp])

    //Almacenamos los valores para enviarlos por props

    const objective = inputObjective.objective

    const resetData = () => {
        setInputObjective({
            objective: objectiveProp
        })
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const usuario = cookies.get('usuario');

    const renderFooter = (name) => {
        var createNewObjective = false
        if (usuario !== createdByProp) {
            createNewObjective = true
        }
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage={createNewObjective ? "El registro editado no fue creado con su usuario, por lo tanto, se creará un nuevo registro con los datos actualizados, tenga en cuenta seleccionar este nuevo registro para su informe." : "¿Estás seguro de guardar esta información?"}
                            action={createNewObjective ? "Create" : "Update"}
                            label="Actualizar"
                            icon="pi pi-pencil"
                            objectiveProp={objective}
                            idObjectiveProp={idObjectiveProp}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            updateDatailProp={false}
                            deleteDetailProp={false}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                            createNewObjectiveProp={createNewObjective}
                            createProjectsProp={createProjectsProp}
                        />
                        :
                        ""
                }
            </div>
        );
    }
    // validaciones

    var objectiveError = false
    var objectiveMessage = ''

    if (objective !== '') {
        if (objective?.length < 10) {
            objectiveError = true
            objectiveMessage = 'La descripción del objetivo es demasiado corta'
        } else if (objective?.length > 110) {
            objectiveError = true
            objectiveMessage = 'La descripción del objetivo supera el limite permitido de 110 caracteres'
        }
    }

    const [duplicityError, setDuplicityError] = useState(false)
    const [duplicityMessage, setDuplicityMessage] = useState('')

    useEffect(() => {

        if (inputObjective.objective !== objectiveProp) {
            var getProjectsObjective
            dataObjetiveProp.forEach(element => {
                if (inputObjective.objective.toLowerCase() === element.descripcion?.toLowerCase()) {
                    getProjectsObjective = element
                }
            })
            if (getProjectsObjective !== undefined) {
                getProjectsObjective.proyectos.forEach(element => {
                    createProjectsProp?.forEach(i => {
                        if (element.proyecto.id === i.proyecto.id) {
                            if ((i.fechainiciovigencia <= element.fechainiciovigencia && i.fechafinvigencia <= element.fechainiciovigencia)
                                || (i.fechainiciovigencia >= element.fechafinvigencia && i.fechafinvigencia >= element.fechafinvigencia)) {
                                setDuplicityError(false)
                                setDuplicityMessage('')
                            }
                            else {
                                setDuplicityError(true)
                                setDuplicityMessage('El objetivo ya se encuentra creado con el mismo proyecto y está dentro del rango de fechas del ya creado.')
                            }
                        } else {
                            setDuplicityError(false)
                            setDuplicityMessage('')
                        }
                    })

                })
            } else {
                setDuplicityError(false)
                setDuplicityMessage('')
            }
        }

    }, [inputObjective.objective, dataObjetiveProp]) // eslint-disable-line react-hooks/exhaustive-deps

    var updatePermission = false

    if (objective !== '' && objectiveError === false && duplicityError === false) {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar objetivo" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Objetivo<span className="require">*</span></label>
                            <InputText id="name" type="text" name="objective" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onObjectiveChange}
                                placeholder="Ingrese un nombre" value={objective}
                                className={objectiveError || duplicityError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{objectiveError ? objectiveMessage : ""}{duplicityError ? duplicityMessage : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnActualizarObjetivo