import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
/* import { Link } from 'react-router-dom' */
import Services from '../../../service/Services';
import ConfirmBtn from './confirmBtn'
import Cookies from 'universal-cookie';
import { GetDates, SumarDias } from '../../Funciones';

const cookies = new Cookies();

const UpdateDates = ({ arrayFechasConvertidasProp, countryValueProp, idRegistroProp, BotonRegresarProp, fechaCreacionProp, creadoPorProp }) => {

    const [date, setDate] = useState(arrayFechasConvertidasProp);
    const [dates, setDates] = useState(null);
    const [cierres, setCierres] = useState([]);

    const [validateChange, setValidateChange] = useState(false);
    const getServices = new Services();

    useEffect(() => {

        getServices.getCierres().then(data => setCierres(data));

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onDateChange = (e) => {
        setDate(e.value)
        setValidateChange(true)
        var date = e.value
        var dates = []
        if (date !== null) {
            date.forEach(element => {
                let month = element.getMonth() + 1;
                let day = element.getDate();

                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }
                const fecha = month + '/' + day + '/' + element.getFullYear()
                dates.push(fecha)
            })
        }
        const sendDates = dates.toString()
        setDates(sendDates)
    }

    const usuario = cookies.get('usuario')

    const Alert = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                </div>
            );
        }

        return (
            <>
                <Button label="Actualizar" onClick={() => onClick('displayConfirmation')} className="p-button-outlined p-button-success"
                    tooltip="Actualizar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                />
                <div className="dialog-demo">
                    <div className="card">
                        <Dialog className="modalConfirm" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>{mensaje}</span>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </>
        )
    }
    var mensaje = ""
    var showAlert = false
    if (validateChange === false) {
        showAlert = true
        mensaje = "No has realizado nigun cambio"
    } else if (dates.length === 0) {
        showAlert = true
        mensaje = "Debes selecionar al menos una fecha"
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    // validar Mes cerrado

    const cierreActivo = cookies.get('cierreActivo')
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    mesesCerrados.forEach(element => {
        fechaCierre.push(element.fechaFinalCierre)
    })

    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))

    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))

    const mesAbierto = cierres.filter(function (objeto) {
        var id = parseInt(cierreActivo)
        return objeto.id === id
    })
    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre)
    var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2)

    var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre)
    var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1)

    /* console.log(getMesAbierto.fechaInicialCierre)
    console.log(getMesAbierto.fechaFinalCierre) */

    var dateArray = GetDates(newFechaFinalCierre, (fechaUltimoCierre).addDays(2));

    cierres.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })

    var minFechaCierre
    var disabledDates

    if (mesAbierto.length !== 0) {
        minFechaCierre = newFechaInicialCierre
        disabledDates = dateArray
    } else {
        minFechaCierre = SumarDias(fechaUltimoCierre, +1)
        disabledDates = []
    }
    /* console.log(usuario) */

    return (
        <>
            <div className="p-fluid ">
                <div className="p-field  p-md-12 " style={{ textAlign: 'left' }} >
                    <div className="p-fluid ">
                        <div className="" style={{ textAlign: 'left' }} >
                            <Calendar value={date} onChange={onDateChange} monthNavigator yearNavigator yearRange="2010:2030" locale="es"
                                selectionMode="multiple" placeholder="Seleccione los días festivos" readOnlyInput inline
                                minDate={minFechaCierre} disabledDates={disabledDates} className="CalendarDataPiker"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-formgroup-inline" style={{ marginLeft: '7px' }}>
                <div className="p-field">
                    {
                        showAlert ?
                            <Alert />
                            :
                            <ConfirmBtn
                                accion="Actualizar"
                                label="Actualizar"
                                titulo="Actualizar Calendario"
                                paisProp={countryValueProp}
                                fechasProp={dates}
                                usuarioProp={usuario}
                                idRegistroProp={idRegistroProp}
                                fechaCreacionProp={fechaCreacionProp}
                                creadoPorProp={creadoPorProp}
                            />
                    }
                </div>
                <BotonRegresarProp />

            </div>
        </>
    )
}

export default UpdateDates