import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import { useEffect } from 'react';

const ConfirmBtnEliminar = ({ usuarioProp, tooltipProp, dataUserProp, proyectosProp, 
    isDeleteProjectUsersProp, runRenderProp, setRunRenderProp, estadoProp }) => {

    const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/user/updateUser'
    const toast = useRef(null);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }
    const [disabled, setDisabled] = useState(false)
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const [errorClientAsociations, setErrorClientAsociations] = useState(false)
    const [messageErrorAssociations, setMessageErrorAssociations] = useState('')
    useEffect(() => {
        if (dataUserProp.length !== 0 && displayConfirmation === true) {
            dataUserProp.forEach(newData => {
                if (newData.equipoTrabajoLider.length !== 0) {
                    newData.equipoTrabajoLider.forEach(equipo => {
                        if (equipo.estado === 1) {
                            setErrorClientAsociations(true)
                            setMessageErrorAssociations(`El usuario está asociado al equipo ${equipo.equipoTrabajo.nombreequipotrabajo}`)
                        }
                    })
                } else {
                    setErrorClientAsociations(false)
                    setMessageErrorAssociations('')
                }
                if (newData.equipoTrabajoUsuario.length !== 0) {
                    newData.equipoTrabajoUsuario.forEach(equipo => {
                        if (equipo.estado === 1) {
                            setErrorClientAsociations(true)
                            setMessageErrorAssociations(`El usuario está asociado al equipo ${equipo.equipoTrabajo.nombreequipotrabajo}`)
                        }
                    })
                } else {
                    setErrorClientAsociations(false)
                    setMessageErrorAssociations('')
                }
            })
        }

    }, [dataUserProp, displayConfirmation])// eslint-disable-line react-hooks/exhaustive-deps


    /*   const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/user/deleteUser?usuario=' + user
      const eliminarUsuario = async () => {
          // console.log(user)
          await axios.post(urlEliminar,
              {
                  headers:
                  {
                      'Content-Type': 'application/json',
                      "Access-Control-Allow-Origin": "*"
                  }
              })
              .then(response => {
                  toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                  //console.log(response.data)
                  actualizarVigenciaUsuario()
                  window.location.href = "/registroActividades/consultarUsuarios"
              })
              .catch(function (error) {
                  //console.log(error.response)
                  toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
              })
  
      } */

    const actualizarVigenciaUsuario = async () => {      
        setDisabled(true)
        const newDataProjects = proyectosProp.map((item, index) => {
            return {

                ...item,
                fechafinvigencia: isDeleteProjectUsersProp ? item.sendDateEnd : item.fechafinvigencia,
                fechainiciovigencia: isDeleteProjectUsersProp ? item.sendDateStart : item.fechainiciovigencia,
                estado: 3,
                proyecto: {
                    id: item.proyecto.id
                },
            }
        })
        const json = JSON.stringify(

            {
                usuario: usuarioProp,
                proyectos: newDataProjects,
                estado: isDeleteProjectUsersProp ? estadoProp : 3
            }
        )
        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {   
                toast.current.show({ severity: 'success', summary: 'Eliminación satisfactoria', detail: response.data.message, life: 3000 });
                if (isDeleteProjectUsersProp === true) {
                    setDisplayConfirmation(false)
                    setRunRenderProp(runRenderProp + 1)
                } else {
                    window.location.href = "/registroActividades/consultarUsuarios"
                }

                //console.log(response.data)
            })
            .catch(function (error) {
                //console.log(error.response)
                setDisabled(false)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label={errorClientAsociations ? "Aceptar" : "Cancelar"} icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                {
                    errorClientAsociations ?
                        ''
                        :
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => actualizarVigenciaUsuario()} disabled={disabled} autoFocus />
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button icon="pi pi-trash" onClick={() => onClick('displayConfirmation')} style={isDeleteProjectUsersProp ? { marginTop: '3px', marginLeft: '10px' } : { margin: '5px 0px 0px 5px' }} className="btnEditar p-button-danger "
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <Dialog className="modalConfirm" header={isDeleteProjectUsersProp ? "Eliminar proyecto asociado al usuario" : "Eliminar Usuario"} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>

                <div className="confirmation-content">
                    <div className="mensajeCofirmar">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {
                            errorClientAsociations ?
                                <span>{messageErrorAssociations}</span>
                                :
                                isDeleteProjectUsersProp ?
                                    <span>¿Está seguro de eliminar la asociación?</span>
                                    :
                                    <span>¿Está seguro de eliminar el usuario?, existen unas asociaciones
                                        con proyectos que también se eliminarán</span>
                        }


                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ConfirmBtnEliminar