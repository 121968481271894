import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import Vincularfecha from './BtnVincularFecha'
import Cambiarfecha from './BtnCambiarFecha'
import { EstandarFormatoFecha, SumarDias } from '../Funciones';
import ConfirmBtnEliminar from './ConfirmBtnEliminar';

const VerClientesProyectos = ({ proyectosProp, usuarioProp, runRender, setRunRender, dataProp, redirectProp, valueUniqueProp, setRedirectProp,
    estadoProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setRedirectProp(false)
    }


    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [newProjects, setNewProjects] = useState([])

    useEffect(() => {

        var newProyectosProp = proyectosProp.map(item => {
            var status

            if (item.estado === 1) {
                status = "Vigente"
            }
            else if (item.estado === 0) {
                status = "No vigente"
            }

            var d = new Date()
            var gtmHours = -d.getTimezoneOffset() / 60
            var formatDateStart, formatDateEnd

            if (item.fechainiciovigencia) {
                if (gtmHours < 0) {
                    formatDateStart = SumarDias(new Date(item.fechainiciovigencia), +1)
                }
                else {
                    formatDateStart = new Date(item.fechainiciovigencia)
                }
            } else {
                formatDateStart = null
            }

            if (item.fechafinvigencia) {
                if (gtmHours < 0) {
                    formatDateEnd = SumarDias(new Date(item.fechafinvigencia), +1)
                }
                else {
                    formatDateEnd = new Date(item.fechafinvigencia)
                }
            } else {
                formatDateEnd = null
            }


            return {
                fechacreacion: item.fechacreacion,
                fechainiciovigencia: formatDateStart && EstandarFormatoFecha(formatDateStart),
                fechafinvigencia: item.fechafinvigencia === null ? item.fechafinvigencia : EstandarFormatoFecha(formatDateEnd),
                sendDateStart: item.fechainiciovigencia,
                sendDateEnd: item.fechafinvigencia,
                id: item.id,
                proyecto: item.proyecto,
                estado: item.estado,
                newEstado: status
            }
        })
        setNewProjects(newProyectosProp)
    }, [proyectosProp, runRender]) // eslint-disable-line react-hooks/exhaustive-deps

    const Acciones = (rowData) => {
        const { fechainiciovigencia, fechafinvigencia, newEstado, ...rest } = rowData;
        return (
            <div>
                <Vincularfecha
                    usuarioProp={usuarioProp}
                    idProp={rowData.id}
                    idProyectoProp={rowData.proyecto.id}
                    dateStartProp={rowData.sendDateStart}
                    dateStartProjectProp={rowData.proyecto.fechainiciovigencia}
                    dateEndProp={rowData.sendDateEnd}
                    dateEndProjectProp={rowData.proyecto.fechafinvigencia}
                    runRenderProp={runRender}
                    setRunRenderProp={setRunRender}
                    estadoProp={estadoProp}
                />

                <Cambiarfecha
                    usuarioProp={usuarioProp}
                    idProp={rowData.id}
                    idProyectoProp={rowData.proyecto.id}
                    dateStartProp={rowData.sendDateStart}
                    dateStartProjectProp={rowData.proyecto.fechainiciovigencia}
                    dateEndProp={rowData.sendDateEnd}
                    dateEndProjectProp={rowData.proyecto.fechafinvigencia}
                    runRenderProp={runRender}
                    setRunRenderProp={setRunRender}
                    estadoProp={estadoProp}
                />

                <ConfirmBtnEliminar
                    usuarioProp={usuarioProp}
                    tooltipProp="Eliminar"
                    dataUserProp={[]}
                    proyectosProp={[rest]}
                    isDeleteProjectUsersProp={true}
                    runRenderProp={runRender}
                    setRunRenderProp={setRunRender}
                    estadoProp={estadoProp}
                />
            </div>
        )
    }
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    useEffect(() => {
        if (redirectProp === true) {
            if (usuarioProp === valueUniqueProp) {
                onClick('displayBasic')
            }
        }
    }, [redirectProp, usuarioProp, valueUniqueProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    return (

        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Clientes proyectos asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={newProjects} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para este usuario" header={header} globalFilter={globalFilter}>
                            <Column className="columna" sortable field="proyecto.cliente.razonsocial" header="Cliente" />
                            <Column className="columna" sortable field="proyecto.nombreproyecto" header="Proyecto" />
                            <Column className="columna" sortable field="fechainiciovigencia" header="Fecha Inicio de Vigencia" />
                            <Column className="columna" sortable field="fechafinvigencia" header="Fecha Fin de Vigencia" />
                            <Column className="columna" sortable field="newEstado" header="Estado" />
                            <Column className='columna' sortable header="Acciones" body={Acciones} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerClientesProyectos