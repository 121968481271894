import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
const BtnVerObjetivosNoVigentes = ({ objectivesNotCurrentProp }) => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };
    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    const [data, setData] = useState([])
    useEffect(() => {
        setData([objectivesNotCurrentProp])
    }, [objectivesNotCurrentProp])

    return (
        <div>
            <Button label='Ver Objetivos' icon="pi pi-eye" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')} />
            <Dialog className="modal" header="Objetivos no vigentes con el periodo seleccionado" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={data} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para este objetivo" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="descripcion" header="Objetivo" />
                            <Column className="columna" field="creadopor" header="Creado Por" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default BtnVerObjetivosNoVigentes