import React from 'react';
import RegistradorActividades from './RegistradorActividades/RegistradorActividades'

const Indicadores = ({ acceso }) => {

    var permisoDashBoard = false
    if (acceso[13].DashBoards === 'true') {
        permisoDashBoard = true
    } else {
        permisoDashBoard = false
    }

    return (
        <>
            {
                permisoDashBoard
                    ?
                    <RegistradorActividades />
                    :
                    ""
            }

        </>
    )

}
export default Indicadores