import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from "./ConfirmBtn";
import Cookies from "universal-cookie";
import { removeAccents } from "../Funciones";
const cookies = new Cookies();
const BtnCrearTipoProyecto = ({ listTypeProjectProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [validarCambio, setValidarCambio] = useState(false)

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [inputTypeProject, setInputTypeProject] = useState('')
    const [errorTypeProject, setErrorTypeProject] = useState(false)
    const [messageErrorTypeProject, setMessageErrorTypeProject] = useState('')

    const onTypeProjectChange = (e) => {
        setInputTypeProject(e.target.value)
        setValidarCambio(true)
        if (e.target.value !== '') {
            if (e.target.value.length > 0 && e.target.value.length < 4) {
                setErrorTypeProject(true)
                setMessageErrorTypeProject('El nombre de tipo de proyecto deber ser mayor a 3 dígitos')
            }
            else if (e.target.value.length > 100) {
                setErrorTypeProject(true)
                setMessageErrorTypeProject('El nombre de tipo de proyecto deber ser menor a 100 dígitos')
            }
            else {
                setErrorTypeProject(false)
                setMessageErrorTypeProject('')
            }
        }
    }

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setInputTypeProject('')
            setValidarCambio(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    var validateNameEqualityError = false
    var messageNameEquality = ''
    //Validar que el nombre no se repita
    const validateNameEquality = () => {
        listTypeProjectProp.forEach(element => {
            const nameTypeProject = removeAccents(element.nombretipoproyecto)
            const newNameTypeProject = removeAccents(inputTypeProject)
            if (nameTypeProject.toUpperCase() === newNameTypeProject.toUpperCase()) {
                validateNameEqualityError = true
                messageNameEquality = 'Ya hay un tipo de proyecto creado con este nombre'
            }
        })
    }

    if (inputTypeProject !== '') {
        validateNameEquality()
    }

    //Validaciones
    var permisoRegistrar = false
    if (inputTypeProject !== '' && errorTypeProject === false && validateNameEqualityError === false) {
        permisoRegistrar = true
    }
    //Almacenar valores para enviarlos por props
    const usuario = cookies.get('usuario')

    const renderFooter = (name) => {
        return (
            <div>
                {validarCambio ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo='Registrar Tipo de Proyecto'
                            mensaje="¿Estás seguro de registrar este tipo de proyecto?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-save"
                            nombreTipoProyectoProp={inputTypeProject}
                            usuarioProp={usuario}

                        />
                        :
                        ''
                }
            </div>
        );
    }
    return (
        <>
            <Button label="Crear Tipo de Proyecto" icon="pi pi-plus" className="btnAdd" onClick={() => onClick('displayBasic')} />
            <Dialog className="modal" header="Registrar Tipo de Proyecto" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }}>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Tipo de Proyecto<span className="require">*</span></label>
                            <InputText value={inputTypeProject} type="text" name="tipoActividad" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/}
                                placeholder="Registre un tipo de proyecto" onChange={onTypeProjectChange}
                                className={errorTypeProject || validateNameEqualityError ? "p-invalid" : ''}
                            />
                            <p className="mensajeError">{errorTypeProject ? messageErrorTypeProject : ""}{validateNameEqualityError ? messageNameEquality : ''}</p>
                        </div>
                    </form>
                </div>

            </Dialog>
        </>

    );
}

export default BtnCrearTipoProyecto