import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import CrearAsociacionFuncionario from "./BtnCrearAsociacion";
import BtnDetalleEquipo from "./BtnDetalleEquipo";
import Services from "../../service/Services";
import BtnAsociacionesTeamWork from "./BtnAsociacionesTeamWork";
import ConfirmBtn from "./ConfirmBtn";
import { EstandarFormatoFecha, SumarDias, UseMedia } from '../Funciones';
import { InputText } from "primereact/inputtext";
import { UpdateValidity } from "../ActualizacionVigencias";
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { CambiarFormatoFecha } from "../Funciones";
import '../../assets/css/DataTable.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import BtnHistoricoLiderYContactoCliente from "./BtnHistoricoLiderYContactoCliente";
const DataTableEquipoTrabajo = () => {

    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    const [runRender, setRunRender] = useState(0)
    const [messageToast, setMessageToast] = useState('')
    const [leaderInactive, setLeaderInactive] = useState([]);

    const toast = useRef(null);

    useEffect(() => {
        getServices.getWorkTeamSpecific().then(data => {
            if (data !== undefined) {
                setLoading(false)
            }
            var newData = data.map(item => {
                var d = new Date()
                var gtmHours = -d.getTimezoneOffset() / 60
                var formatDateStart, formatDateEnd

                var status
                if (item.estado === 1) {
                    status = "Vigente"
                }
                else if (item.estado === 0) {
                    status = "No vigente"
                }
                if (gtmHours < 0) {
                    formatDateStart = SumarDias(new Date(item.fechainiciovigencia), +1)
                    formatDateEnd = SumarDias(new Date(item.fechafinvigencia), +1)
                }
                else {
                    formatDateStart = new Date(item.fechainiciovigencia)
                    formatDateEnd = new Date(item.fechafinvigencia)
                }
                const result = item.contactoCliente.sort(function (a, b) {
                    var textA = new Date(a.fechafinasignacion)
                    var textB = new Date(b.fechafinasignacion)
                    return textB - textA
                })
                const contactCustomer = result.map(i => {
                    return {
                        personainteresada: {
                            nombrecompleto: i.personainteresada.nombre + ' ' + i.personainteresada.apellido,
                            cargo: i.personainteresada.cargo.cargo,
                            id: i.personainteresada.id

                        },
                        estado: i.estado,
                        fechacreacion: i.fechacreacion,
                        fechafinasignacion: i.fechafinasignacion,
                        fechainicioasignacion: i.fechainicioasignacion,
                        id: i.id,
                    }
                })

                const filterContact = contactCustomer.filter(obj => {
                    return obj.estado === 1
                })

                return {
                    actualizadopor: item.actualizadopor,
                    creadopor: item.creadopor,
                    estado: status,
                    fechaactualizado: item.fechaactualizado,
                    fechacreacion: item.fechacreacion,
                    fechafinvigencia: item.fechafinvigencia === null ? item.fechafinvigencia : EstandarFormatoFecha(formatDateEnd),
                    fechainiciovigencia: EstandarFormatoFecha(formatDateStart),
                    dateStartVigencia: item.fechainiciovigencia,
                    dateEndVigencia: item.fechafinvigencia,
                    id: item.id,
                    lider: item.lider[0],
                    lideresNoVigentes: item.lideresNoVigentes,
                    nombreequipotrabajo: item.nombreequipotrabajo,
                    cliente: item.cliente,
                    usuarios: item.usuarios,
                    contactocliente: filterContact[0],
                    contactosClientesNoVigentes: item.contactoClienteNoVigentes
                }
            })
            setService(newData)
            //Validar que las fechas de las asociaciones esten vigentes
            const urlUpdateTeam = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/update'
            data.forEach(x => {
                if (x.fechafinvigencia < CambiarFormatoFecha(new Date()) && x.estado !== 0) {
                    const json = JSON.stringify(
                        {
                            ...x,
                            estado: 0
                        }
                    )
                    setLoading(true)
                    UpdateValidity(urlUpdateTeam, json).then(data => {
                        setMessageToast(data.message)
                        setLoading(data.loading)
                        setRunRender(runRender + 1)
                    })
                } else {
                    setLoading(false)
                    setMessageToast('')
                }
                x.usuarios.forEach(element => {
                    if (element.fechafinasignacion < CambiarFormatoFecha(new Date()) && element.estado !== 0) {
                        const json = JSON.stringify(
                            {
                                ...x,
                                usuarios: [{
                                    estado: 0,
                                    fechacreacion: element.fechacreacion,
                                    fechafinasignacion: element.fechafinasignacion,
                                    fechainicioasignacion: element.fechainicioasignacion,
                                    id: element.id,
                                    usuario: {
                                        id: element.usuario.id
                                    }
                                }],
                            }
                        )
                        setLoading(true)
                        UpdateValidity(urlUpdateTeam, json).then(data => {
                            setMessageToast(data.message)
                            setLoading(data.loading)
                            setRunRender(runRender + 1)
                        })
                    } else {
                        setLoading(false)
                        setMessageToast('')
                    }
                    x.lider.forEach(i => {

                        if (i.fechafinasignacion < CambiarFormatoFecha(new Date()) && i.estado !== 0) {
                            const json = JSON.stringify(
                                {
                                    ...x,
                                    lider: [{
                                        colaborador: {
                                            id: i.colaborador.id
                                        },
                                        estado: 0,
                                        fechacreacion: i.fechacreacion,
                                        fechafinasignacion: i.fechafinasignacion,
                                        fechainicioasignacion: i.fechainicioasignacion,
                                        id: i.id
                                    }]
                                }
                            )
                            setLoading(true)
                            UpdateValidity(urlUpdateTeam, json).then(data => {
                                setMessageToast(data.message)
                                setLoading(data.loading)
                                setRunRender(runRender + 1)
                            })
                        } else {
                            setLoading(false)
                            setMessageToast('')
                        }
                        x.contactoCliente.forEach(e => {
                            if (e.fechafinasignacion < CambiarFormatoFecha(new Date()) && e.estado !== 0) {
                                const json = JSON.stringify(
                                    {
                                        ...x,
                                        contactoCliente: [{
                                            estado: 0,
                                            fechacreacion: e.fechacreacion,
                                            fechafinasignacion: e.fechafinasignacion,
                                            fechainicioasignacion: e.fechainicioasignacion,
                                            id: e.id,
                                            personainteresada: {
                                                id: e.personainteresada.id
                                            }
                                        }],
                                    }

                                )
                                setLoading(true)
                                UpdateValidity(urlUpdateTeam, json).then(data => {
                                    setMessageToast(data.message)
                                    setLoading(data.loading)
                                    setRunRender(runRender + 1)
                                })
                            } else {
                                setLoading(false)
                                setMessageToast('')
                            }
                            if (element.usuario.estado === 2 && element.usuario.idtipobloqueo?.descripcion === 'Retiro' && element.estado !== 0) {
                                const json = JSON.stringify(
                                    {
                                        ...x,
                                        usuarios: [{
                                            estado: 0,
                                            fechacreacion: element.fechacreacion,
                                            fechafinasignacion: element.usuario.fechaactualizado,
                                            fechainicioasignacion: element.fechainicioasignacion,
                                            id: element.id,
                                            usuario: {
                                                id: element.usuario.id
                                            }
                                        }],
                                    }
                                )
                                setLoading(true)
                                UpdateValidity(urlUpdateTeam, json).then(data => {
                                    setMessageToast(data.message)
                                    setLoading(data.loading)
                                    setRunRender(runRender + 1)
                                })
                            } else {
                                setLoading(false)
                                setMessageToast('')
                            }

                            if (i.colaborador.idusuario.estado === 2 && i.colaborador.idusuario.idtipobloqueo.descripcion === 'Retiro' && i.estado !== 0) {
                                const json = JSON.stringify(
                                    {
                                        ...x,
                                        lider: [{
                                            colaborador: {
                                                id: i.colaborador.id
                                            },
                                            estado: 0,
                                            fechacreacion: i.fechacreacion,
                                            fechafinasignacion: i.colaborador.idusuario.fechaactualizado,
                                            fechainicioasignacion: i.fechainicioasignacion,
                                            id: i.id
                                        }],
                                    }
                                )
                                setLoading(true)
                                UpdateValidity(urlUpdateTeam, json).then(data => {
                                    setMessageToast(data.message)
                                    setLoading(data.loading)
                                    setRunRender(runRender + 1)
                                })
                            } else {
                                setLoading(false)
                                setMessageToast('')
                            }

                        })
                    })

                })
            })

        })
        if (messageToast !== '') {
            toast.current.show({ severity: 'success', summary: messageToast, detail: '', life: 3000 });
        }
    }, [runRender]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var result = []
        service.forEach(element => {
            if (element.lider.estado === 0 && element.lider.colaborador.idusuario.estado === 2 && element.lider.colaborador.idusuario.idtipobloqueo.descripcion === 'Retiro') {
                result.push(element)
            }
        })
        var newResult = [...new Set(result)]
        setLeaderInactive(newResult)

    }, [service]) // eslint-disable-line react-hooks/exhaustive-deps

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const Header = () => {
        return (
            <div className="headerTable">
                <>
                    <CrearAsociacionFuncionario
                        runRenderProp={runRender}
                        setRunRenderProp={setRunRender}
                        nameTeamWorkProp={service}
                        activeCoordinatorProp={service}
                    />
                </>
                {loading && <p>Cargando información...</p>}
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                            onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                        />
                    </span>
                </div>
            </div>
        )
    }

    // modal de advertencia 
    var showModal = false
    if (leaderInactive.length !== 0) {
        showModal = true
    } else {
        showModal = false
    }


    const ModalWarning = ({ showModalProp, dataProp }) => {
        const [displayBasic, setDisplayBasic2] = useState(showModalProp);

        const dialogFuncMap = {
            'displayBasic': setDisplayBasic2,
        }

        const onHide = (name) => {
            setDisplayBasic2(false)
            setLeaderInactive([])
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
                </div>
            );
        }

        const uniqueData = [...new Set(dataProp)]
        var textWorkTeam = uniqueData.map(item => {
            return item.nombreequipotrabajo
        })
        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic} modal footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')} style={{ maxWidth: '50vw' }} breakpoints={{ '960px': '75vw' }} >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>{'Señor usuario, el o los equipos de trabajo: ' + textWorkTeam.join(", ") + ' tienen lideres bloqueados por retiro, actualice la asociación.'}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    const Acciones = (rowData) => {
        //Validar que si el equipo tiene una asociación vigente no lo deje eliminar
        let currentError = false
        let messageError = ''
        let newUsersCurrent = []
        if (rowData.usuarios.length !== 0) {

            const usersCurrent = rowData.usuarios.filter(i => {
                return i.estado === 1 || i.estado === 0
            })
            usersCurrent.forEach(e => {
                newUsersCurrent.push(e)
            })
        }
        if (newUsersCurrent.length !== 0) {
            currentError = true
            messageError = 'No se puede eliminar el equipo porque tiene usuarios asociados'
        } else if (rowData.lider?.estado === 1 || rowData.lider?.estado === 0) {
            currentError = true
            messageError = 'No se puede eliminar el equipo porque tiene un lider asociado'
        } else if (rowData.contactocliente?.estado === 1 || rowData.contactocliente?.estado === 0) {
            currentError = true
            messageError = 'No se puede eliminar el equipo porque tiene un contacto cliente asociado'
        }

        return (
            <div className="Botones">
                <div>
                    <BtnDetalleEquipo
                        officialProp={rowData.usuarios}
                        nameTeamWorkProp={service}
                        nameClientsProp={rowData.cliente.razonsocial}
                        idClientsProp={rowData.cliente.id}
                        teamWork={rowData.nombreequipotrabajo}
                        coordinatorProp={rowData.lider.colaborador.nombrecompleto}
                        coordinatorId={rowData.lider.colaborador.id}
                        coordinatorData={rowData.lider}
                        leaderCreationDateProp={rowData.lider.fechacreacion}
                        dateStartCoordinatorProp={rowData.lider.fechainicioasignacion}
                        dateEndCoordinatorProp={rowData.lider.fechafinasignacion}
                        dateStartTeam={rowData.dateStartVigencia}
                        dateEndTeam={rowData.dateEndVigencia}
                        idProp={rowData.id}
                        dateCreatedProp={rowData.fechacreacion}
                        createdByProp={rowData.creadopor}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                        dataContactCustomerProp={rowData.contactocliente}
                    />
                </div>

                <div>
                    <ConfirmBtn
                        titulo='Equipo de trabajo'
                        mensaje='¿Desea eliminar su equipo de trabajo?, Recuerde que una vez se elimine se borrará definitivamente del sistema'
                        accion='EliminarEquipoTrabajo'
                        icono='pi pi-trash'
                        classProp="p-button-danger"
                        tooltipProp="Eliminar"
                        idTeamWork={rowData.id}
                        updateDateProp={true}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                        currentErrorProp={currentError}
                        messageErrorProp={messageError}
                    />
                </div>

                <div>
                    <BtnAsociacionesTeamWork
                        official={rowData.usuarios}
                        dateStartTeamProp={rowData.dateStartVigencia}
                        dateEndTeamProp={rowData.dateEndVigencia}
                        idClientsAsociacionesProp={rowData.cliente.id}
                        nameClientsProp={rowData.cliente.razonsocial}
                        teamWorkProp={rowData.nombreequipotrabajo}
                        idCoordinatorProp={rowData.lider.colaborador.idusuario.id}
                        leaderProp={rowData.lider.colaborador.nombrecompleto}
                        idProp={rowData.id}
                        createByProp={rowData.creadopor}
                        dateCreatedProp={rowData.fechacreacion}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                    />
                </div>
                <div>

                    <BtnHistoricoLiderYContactoCliente
                        idTeamWorkProp={rowData.id}
                        leaderHistoryProp={rowData.lideresNoVigentes}
                        contactClientHistoryProp={rowData.contactosClientesNoVigentes}
                    />
                </div>
            </div>
        )

    }
    const [showModalTime, setShowModalTime] = useState(false);

    setTimeout(() => {
        setShowModalTime(true)
    }, [1000])

    let small = UseMedia("(max-width: 760px)")

    return (
        <div className="datatable-responsive-demo">
            {showModalTime ?
                <ModalWarning
                    showModalProp={showModal}
                    dataProp={leaderInactive}
                />
                :
                null
            }

            <Toast ref={toast} />
            <div className="card">
                <DataTable emptyMessage="No hay resultados" className="p-datatable-responsive-demo" sortField="nombreequipotrabajo" sortOrder={1} value={service}
                    paginator rows={10} header={Header} globalFilter={globalFilter} loading={loading} scrollable={small ? false : true} scrollHeight={small ? "" : "500px"}>
                    <Column field="cliente.razonsocial" style={{ minWidth: '10rem' }} sortable header="Cliente" />
                    <Column field="nombreequipotrabajo" style={{ minWidth: '10rem' }} sortable header="Nombre de equipo de trabajo" />
                    <Column field="lider.colaborador.nombrecompleto" style={{ minWidth: '10rem' }} sortable header="Nombre del coordinador" />
                    <Column field="lider.colaborador.idcargo.cargo" style={{ minWidth: '10rem' }} sortable header="Cargo del coordinador" />
                    <Column field="contactocliente.personainteresada.nombrecompleto" style={{ minWidth: '10rem' }} sortable header="Nombre del contacto Cliente" />
                    <Column field="contactocliente.personainteresada.cargo" style={{ minWidth: '10rem' }} sortable header="Cargo del contacto Cliente" />
                    <Column field="fechainiciovigencia" sortable style={{ minWidth: '10rem' }} sortField="fechainiciovigencia" header="Fecha de inicio vigencia" />
                    <Column field="fechafinvigencia" sortable style={{ minWidth: '10rem' }} header="Fecha fin vigencia" />
                    <Column field="estado" sortable style={{ minWidth: '10rem' }} header="Estado" />
                    <Column header="Acciones" className="Acciones" body={Acciones} />
                </DataTable>
            </div>
        </div>
    )
}
export default DataTableEquipoTrabajo