import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { CambiarFormatoFecha, SumarDias } from "../Funciones";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";


const BtnUpdateDatailOther = ({ idOtherProp, otherProp, idContractProp, dateStartProp, dateEndProp, idOtherServiceProp, idServiceProp, serviceProp, dateStartValidityProp,
    dateEndValidityProp, dateStartOtherShowProp,  dateEndOtherShowProp, creationDateProp, createdByProp, setRunRenderProp, runRenderProp }) => {

    const [serviceService, setServiceService] = useState([]);
    const getService = new Services();

    useEffect(() => {
        getService.getServices().then(data => setServiceService(data))
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select servicios

    const [selectedService, setSelectedService] = useState({
        service: '',
        id: ''
    })

    const services = serviceService.map(item => {
        const av = { service: item.descripcion, id: item.id }
        return av
    })

    const onServiceChange = (e) => {
        setSelectedService(e.value);
        setValidateChange(true)
    }

    // calendar de fecha  inicio

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidateChange(true)
    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        const dateStartChange = new Date(dateStartValidityProp)
        const dateEndChange = new Date(dateEndValidityProp)
        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
            setDateEnd(SumarDias(dateEndChange, +1))
        } else {
            setDateStart(dateStartChange)
            setDateEnd(dateEndChange)
        }

        setSelectedService({
            service: serviceProp,
            id: idServiceProp,
        })

    }, [gmtHours, idServiceProp, serviceProp, dateStartValidityProp, dateEndValidityProp])

    //Almacenamos los valores para enviarlos por props

    var idService = selectedService.id

    const resetData = () => {
        const dateStartChange = new Date(dateStartValidityProp)
        const dateEndChange = new Date(dateEndValidityProp)
        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
            setDateEnd(SumarDias(dateEndChange, +1))
        } else {
            setDateStart(dateStartChange)
            setDateEnd(dateEndChange)
        }
        setSelectedService({
            service: serviceProp,
            id: idServiceProp,
        })
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage="¿Estás seguro de guardar esta información?"
                            action="UpdateOther"
                            label="Actualizar"
                            icon="pi pi-pencil"
                            idOtherProp={idOtherProp}
                            otherProp={otherProp}
                            dateStartProp={dateStartProp}
                            dateEndProp={dateEndProp}
                            idContractProp={idContractProp}
                            statusProp={1}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            OtherServiceStatusProp={1}
                            dateStartServiceProp={CambiarFormatoFecha(dateStart)}
                            dateEndServiceProp={CambiarFormatoFecha(dateEnd)}
                            idOtherServiceProp={idOtherServiceProp}
                            idServiceProp={idService}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            updateDetailProp={true}
                            deleteDetailProp={false}
                        />
                        :
                        ""
                }
            </div>
        );
    }
    // validaciones
    // validar fechas dentro del rango del proyecto seleccionado

    var dateStartError = false
    var dateStartMessage = ''

    const validateRangeDate = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        var newDateEnd = CambiarFormatoFecha(dateEnd)

        if (newDateStart >= dateStartProp && newDateStart <= dateEndProp &&
            newDateEnd >= dateStartProp && newDateEnd <= dateEndProp) {
            dateStartError = false
            dateStartMessage = ''
        } else {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio y fin están fuera del rango de fechas del proyecto seleccionado'
        }
    }

    const validateDateStart = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        if (newDateStart >= dateStartProp && newDateStart <= dateEndProp) {
            dateStartError = false
            dateStartMessage = ''
        } else {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio esta fuera del rango de fechas del proyecto seleccionado'
        }
    }

    if (dateStart !== null && dateEnd === null && selectedService.id !== '' && dateStartError === false) {
        validateDateStart()
    }

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        } else {
            if (dateStart !== null && dateEnd !== null && selectedService.id !== '' && dateStartError === false) {
                validateRangeDate()
            }
        }
    }

    var updatePermission = false
    if (dateStartError === false && selectedService.id !== '') {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar Otrosi" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Serivicio<span className="require">*</span></label>
                            <Dropdown value={selectedService} options={services} onChange={onServiceChange} name="servicio" optionLabel="service"
                                placeholder="Seleccione un servicio" filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            {selectedService.id !== '' ?
                                <span>Las fechas de vigencia tendrán que estar dentro del rango de fechas del contrato {dateStartOtherShowProp} - {dateEndOtherShowProp}</span>
                                :
                                ""
                            }
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Inicio<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Fin</label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={""}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnUpdateDatailOther