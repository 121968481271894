import React, { useState, useEffect, useRef } from "react";
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import '../../assets/css/estilo.css'
import ConfirmBtn from "./ConfirmBtn";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { CambiarFormatoFecha, SumarDias } from "../Funciones"
import Cookies from 'universal-cookie'



const BtnActualizarVigencias = ({ dateStartProp, dateEndProp, dateStartProjectProp, dateEndProjectProp, usuarioProp, idProp, idProyectoProp, runRenderProp,
    setRunRenderProp, apellidoProp, idCargoProp, creadoPorProp, empresaProp, fechaCreacionProp,
    nombreProp, numeroDocumentoProp, idPersonInterestedProp, idTipoDocumentoProp, newUsuarioProp,
    getDataProjectsProp, iconProp, bgRedProp, isDeleteProp, headerProp, classNameProp, toolTipProp,
    runRenderBlockProp, setRunRenderBlockProp, individualMonthReportProp }) => {
    const cookies = new Cookies()
    const usuario = cookies.get('usuario')
    const toast = useRef(null)
    const [validateChange, setValidateChange] = useState(false)

    const [date1, setDate1] = useState(null)

    const onDateStartChange = (e) => {
        setDate1(e.value)
        setValidateChange(true)

    }

    const [date2, setDate2] = useState(null)

    const onDateEndChange = (e) => {
        setDate2(e.value)
        setValidateChange(true)
    }

    var d = new Date()
    var gtmHours = -d.getTimezoneOffset() / 60

    useEffect(() => {




        if (dateStartProp !== null) {
            const dateStartChange = new Date(dateStartProp)
            if (gtmHours < 0) {
                setDate1(SumarDias(dateStartChange, +1))
            }
            else {
                setDate1(dateStartChange)
            }
        } else {
            setDate1(null)
        }


        if (dateEndProp !== null) {

            const dateEndChange = new Date(dateEndProp)

            if (gtmHours < 0) {
                setDate2(SumarDias(dateEndChange, +1))
            }
            else {
                setDate2(dateEndChange)
            }
        }
        else {
            setDate2(null)
        }

    }, [dateStartProp, dateEndProp, gtmHours])


    const [displayBasic, setDisplayBasic] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            const dateStartChange = new Date(dateStartProp)

            if (gtmHours < 0) {
                setDate1(SumarDias(dateStartChange, +1))
            }
            else {
                setDate1(dateStartChange)
            }

            if (dateEndProp !== null) {

                const dateEndChange = new Date(dateEndProp)

                if (gtmHours < 0) {
                    setDate2(SumarDias(dateEndChange, +1))
                }
                else {
                    setDate2(dateEndChange)
                }
            }
            else {
                setDate2(null)
            }

            setValidateChange(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header='Advertencia' visible={displayConfirmation}
                    modal style={{}} footer={renderFooter('displayConfirmation')}
                    onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    //Validaciones
    var createPermission = false
    var dateStartError = false
    var messageDateStart = ''



    function validarRangoFechaProyecto() {
        var newDateStart = CambiarFormatoFecha(date1)
        var newDateEnd = CambiarFormatoFecha(date2)

        if (newDateStart >= dateStartProjectProp && newDateStart <= dateEndProjectProp &&
            newDateEnd >= dateStartProjectProp && newDateEnd <= dateEndProjectProp) {
            dateStartError = false
            messageDateStart = ""
        }
        else {
            dateStartError = true
            messageDateStart = "Las fechas establecidas están fuera del rango de las fechas del proyecto"
        }
    }

    function validarFechaInicio() {
        var newDateStart = CambiarFormatoFecha(date1)
        if (newDateStart < dateStartProjectProp || newDateStart > dateEndProjectProp) {
            dateStartError = true
            messageDateStart = "La fecha de inicio de vinculación, no está dentro del rango de las fechas del proyecto"
        }
    }

    if (date1 !== null && date2 !== null) {
        if (date1 > date2) {
            dateStartError = true
            messageDateStart = "La fecha de inicio debe ser menor a la fecha fin"
        }
        else {
            validarRangoFechaProyecto()
        }
    }

    if (date1 !== null && date2 === null) {
        validarFechaInicio()
    }

    if (date1 !== null && dateStartError === false) {
        createPermission = true
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Enviar datos por props

    const [newArrProjects, setNewArrProjects] = useState([]);
    useEffect(() => {
        if (displayBasic === true) {
            const result = getDataProjectsProp.filter(item => {
                return item.id === idProp
            })
            let sendArrayProject = [{
                id: idProp,
                fechacreacion: result[0].fechacreacion,
                estado: isDeleteProp ? 3 : date2 !== null ? CambiarFormatoFecha(date2) < CambiarFormatoFecha(new Date()) ? 0 : 1 : 1,
                fechainiciovigencia: date1 === null ? date1 : CambiarFormatoFecha(date1),
                fechafinvigencia: date2 === null ? date2 : CambiarFormatoFecha(date2),
                proyecto: {
                    id: idProyectoProp
                }
            }]
            setNewArrProjects(sendArrayProject)
        }


    }, [date1, date2, idProyectoProp, displayBasic])//eslint-disable-line react-hooks/exhaustive-deps

    const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/interestedPerson/update'
    const actualizarVigenciaUsuario = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                apellido: apellidoProp,
                cargo: {
                    id: idCargoProp
                },
                creadopor: creadoPorProp,
                empresa: empresaProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: fechaCreacionProp,
                nombre: nombreProp,
                numerodocumento: numeroDocumentoProp,
                proyectos: newArrProjects,
                id: idPersonInterestedProp,
                tipodocumento: idTipoDocumentoProp===undefined?null: {
                    id: idTipoDocumentoProp
                },
                usuario: newUsuarioProp
            })

        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayBasic(false)
                    if (individualMonthReportProp === true) {
                        setRunRenderBlockProp(runRenderBlockProp + 1)
                    }
                  /*   if (runRenderBlockProp !==undefined && setRunRenderBlockProp !==undefined) {
                        setRunRenderBlockProp(runRenderBlockProp + 1)
                    } */
                }, [500]);
            })
            .catch(function (error) {
                if (error?.response?.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response?.data?.mensaje, life: 6000 });
                }
            })
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    createPermission && isDeleteProp === false ?
                        <ConfirmBtn
                            tittle='Actualización de fechas'
                            menssage='¿Desea registrar las fechas ingresadas?'
                            action='ActualizarVigencia'
                            label={isDeleteProp ? 'Aceptar' : 'Guardar'}
                            icon='pi pi-save'
                            updateDateProp={true}
                            usuarioProp={usuarioProp}
                            sendArrayProjectProp={newArrProjects}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            newSetDisplayBasicProp={setDisplayBasic}
                            apellidoProp={apellidoProp}
                            idCargoProp={idCargoProp}
                            creadoPorProp={creadoPorProp}
                            empresaProp={empresaProp}
                            fechaCreacionProp={fechaCreacionProp}
                            nombreProp={nombreProp}
                            numeroDocumentoProp={numeroDocumentoProp}
                            idPersonInterestedProp={idPersonInterestedProp}
                            idTipoDocumentoProp={idTipoDocumentoProp}
                            newUsuarioProp={newUsuarioProp}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            individualMonthReportProp={individualMonthReportProp}
                        />
                        :
                        isDeleteProp === true ?
                            <Button label="Aceptar" icon="pi pi-check" onClick={() => actualizarVigenciaUsuario()} autoFocus />
                            :
                            ''
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button icon={iconProp} tooltip={toolTipProp} className={bgRedProp} id="vincular-fecha" onClick={() => onClick('displayBasic')} />
            <Dialog className={classNameProp} header={headerProp} visible={displayBasic}
                onHide={() => onHide('displayBasic')} footer={renderFooter('displayBasic')}>

                {
                    isDeleteProp ?
                        <div className="confirmation-content">
                            <div className="mensajeCofirmar">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {

                                    <span>¿Está seguro de eliminar la asociación?</span>
                                }


                            </div>
                        </div>
                        :
                        <div className="p-fluid p-grid p-formgrid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="basic">Fecha de inicio<span className="require">*</span></label>
                                <Calendar className={dateStartError ? "p-invalid" : ""} placeholder="Seleccione una fecha" dateFormat="dd-mm-yy" locale="es" value={date1} onChange={onDateStartChange}></Calendar>
                                <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="basic">Fecha fin</label>
                                <Calendar dateFormat="dd-mm-yy" locale="es" placeholder="Seleccione una fecha" value={date2} onChange={onDateEndChange}></Calendar>
                            </div>
                        </div>
                }
            </Dialog>

        </>
    );
}
export default BtnActualizarVigencias





