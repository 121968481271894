import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import Services from '../../service/Services';
import BtnCrearProyecto from './BtnCrearProyecto'
import BtnActualizarProyecto from './BtnActualizarProyecto'
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/DataTable.css';
import { EstandarFormatoFecha, SumarDias, UseMedia } from '../Funciones';
import axios from 'axios';

const ConsultarProyectos = ({ permisosProp }) => {

    const [servicio, setServicio] = useState([]);
    const [loading, setLoading] = useState(true);
    const getServices = new Services();

    const toast = useRef(null);

    useEffect(() => {
        const source = axios.CancelToken.source();
        getServices.getNewProyectos().then(data => {
            if (data !== undefined) {
                var newData = data.map(item => {
                    var dateStart, dateEnd;
                    const gmtHours = new Date().getTimezoneOffset() / 60;
    
                    if (gmtHours < 0) {
                        dateStart = SumarDias(new Date(item.fechainiciovigencia), 1);
                        dateEnd = SumarDias(new Date(item.fechafinvigencia), 1);
                    } else {
                        dateStart = new Date(item.fechainiciovigencia);
                        dateEnd = new Date(item.fechafinvigencia);
                    }
    
                   
                    const currentDate = new Date();
                    let state = "No Vigente";
                    if (dateEnd >= currentDate) {
                        state = "Vigente";
                    }
    

                    return {
                        actualizadopor: item.actualizadopor,
                        cliente: item.cliente,
                        creadopor: item.creadopor,
                        estado: item.estado,
                        stateString: state,
                        fechaactualizado: item.fechaactualizado,
                        fechacreacion: item.fechacreacion,
                        fechafinvigencia: EstandarFormatoFecha(dateEnd),
                        fechainiciovigencia: EstandarFormatoFecha(dateStart),
                        sendFechafinvigencia: item.fechafinvigencia,
                        sendFechainiciovigencia: item.fechainiciovigencia,
                        id: item.id,
                        idempresa: item.idempresa,
                        nombreproyecto: item.nombreproyecto,
                        tipoproyectos: item.tipoproyectos
                    }
                })   
                setServicio(newData)
                setLoading(false)
            }
        });
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var permisoProjectCreate
    if (permisosProp[4].permisos[0] === "Crear") {
        permisoProjectCreate = true
    } else {
        permisoProjectCreate = false
    }
    var permisoProjectUpdate
    if (permisosProp[4].permisos[1] === "Editar") {
        permisoProjectUpdate = true
    } else {
        permisoProjectUpdate = false
    }
    var permisoProjectDelete
    if (permisosProp[4].permisos[2] === "Eliminar") {
        permisoProjectDelete = true
    } else {
        permisoProjectDelete = false
    }

    const Acciones = (rowData) => {
        //console.log(rowData)
        return (
            <>
                {permisoProjectUpdate ?
                    <BtnActualizarProyecto
                        idProyectoProp={rowData.id}
                        proyectoProp={rowData.nombreproyecto}
                        idClienteProp={rowData.cliente.id}
                        clienteProp={rowData.cliente.razonsocial}
                        proyectosProp={servicio}
                        creadoporProp={rowData.creadopor}
                        usuariosProp={rowData.usuarios}
                        fechacreacionProp={rowData.fechacreacion}
                        fechaInicioProp={rowData.sendFechainiciovigencia}
                        fechaFinProp={rowData.sendFechafinvigencia}
                        nombreTipoProyectosProp={rowData.tipoproyectos.nombretipoproyecto}
                        idTipoProyectosProp={rowData.tipoproyectos.id}
                    />
                    :
                    ""
                }
                {permisoProjectDelete ?
                    <ConfirmBtn
                        titulo="Confirmar Eliminación"
                        mensaje="¿Está seguro de eliminar proyecto?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        accion="Eliminar"
                        clase="p-button-danger"
                        icono="pi pi-trash"
                        idProyectoProp={rowData.id}
                        tooltipProp="Eliminar"
                    />
                    :
                    ""
                }

            </>
        );
    }


    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoProjectCreate ?
                <BtnCrearProyecto
                    proyectosProp={servicio}
                />
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    var permisosAcciones
    if (permisoProjectUpdate === false && permisoProjectDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    let small = UseMedia("(max-width: 760px)");

    return (
        <div className="datatable-responsive-demo">
            <Toast ref={toast} />
            <div className="card">
                <DataTable ref={dt} value={servicio} paginator rows={10} header={header} globalFilter={globalFilter} loading={loading}
                    emptyMessage="Proyecto no registrado en el sistema" responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px" sortField='sendFechainiciovigencia' sortOrder={1}>
                    <Column className="columna" field="id" sortable header="Id Proyecto" />
                    <Column className="columna" field="cliente.razonsocial" sortable header="Cliente" />
                    <Column className="columna" field="nombreproyecto" sortable header="Proyecto" />
                    <Column className="columna" field='tipoproyectos.nombretipoproyecto' sortable header="Tipo de proyecto" />
                    <Column className="columna" field="sendFechainiciovigencia" sortable header="Fecha inicio vigencia" />
                    <Column className="columna" field="sendFechafinvigencia" sortable header="Fecha fin vigencia" />
                    <Column className="columna" field="stateString" sortable header="Estado" />
                    {permisosAcciones ?
                        <Column header="Acciones" body={Acciones} />
                        :
                        <Column />
                    }

                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarProyectos