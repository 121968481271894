import React, { useState } from 'react'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ModalAdvertencia = ({ usuarioProp, newRunRenderProp, setNewRunRenderProp, setRedirectProp, setDisplayBasicProp, setValueUniqueProp }) => {

    const [displayBasic, setDisplayBasic] = useState(true);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    //Función que envía al usuario a las asociaciones
    const onAssociationsRedirect = () => {
        setValueUniqueProp(usuarioProp)
        setDisplayBasicProp(false)
        setRedirectProp(true)
        setDisplayBasic(false)
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setNewRunRenderProp(newRunRenderProp + 1)
    }

    const renderFooter = (name) => {
        return (

            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => onAssociationsRedirect()} autoFocus />
            </div>
        );
    }

    return (
        <div className="dialog-demo">
            <div className="card">
                <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic} modal footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')} breakpoints={{ '960px': '75vw' }} >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>Está desasociando el proyecto o los proyectos del usuario, ¿Desea usted desasociar el proyecto?</span>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default ModalAdvertencia