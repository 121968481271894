import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { CambiarFormatoFecha } from "../Funciones";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const BtnAddSupplier = ({ dateEndProp, dateStartProp, dataClientProp, renderProp, setRenderProp,
    renderDetailProp, setRenderDetailProp, suppliersProp, linkPowerBiProp }) => {

    const [service, setService] = useState([]);

    const getService = new Services()
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        if (displayBasic === true) {
            getService.getSuppliers().then(data => setService(data));
        }
    }, [displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select tipo de proveedor

    const [selectedSupplier, setSelectedSupplier] = useState({
        name: '',
        id: ''
    })

    const suppliers = service.map(item => {
        const av = { name: item.razonsocial, id: parseInt(item.id) }
        return av
    })

    const onSupplierChange = (e) => {
        setSelectedSupplier(e.value);
        setValidateChange(true)
    }
    function optionDisabledService(e) {
        var disabled = false
        suppliersProp.forEach(element => {
            if (element.estado !== 3) {
                if (element.proveedor?.id === e?.id) {
                    disabled = true
                }
            }

        });
        return disabled
    }

    // calendar de fecha  inicio

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidateChange(true)
    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Almacenamos los valores para enviarlos por props

    /* var idService = selectedSupplier.id */

    const resetData = () => {
        setSelectedSupplier({
            id: '',
            name: ''
        })
        setDateStart(null)
        setDateEnd(null)
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    var objectSupplier = {
        estado: 1,
        fechafinasignacion: dateEnd && CambiarFormatoFecha(dateEnd),
        fechainicioasignacion: dateStart && CambiarFormatoFecha(dateStart),
        proveedor: {
            id: selectedSupplier.id
        }
    }

    const usuario = cookies.get('usuario')

    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {updatePermission ?
                    <ConfirmBtn
                        titulo="Confirmar Asignación"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Asignar"
                        icono="pi pi-plus-circle"
                        tipoDocumentoProp={dataClientProp.tipodocumento}
                        numeroDocumentoProp={dataClientProp.numerodocumento}
                        nombreProp={dataClientProp.razonsocial}
                        dominioProp={dataClientProp.dominio}
                        idClienteProp={dataClientProp.id}
                        creadoporProp={dataClientProp.creadopor}
                        fechacreacionProp={dataClientProp.fechacreacion}
                        usuarioProp={usuario}
                        objectSupplierProp={objectSupplier}
                        showDataProp={false}
                        updateDatailprop={true}
                        renderProp={renderProp}
                        setRenderProp={setRenderProp}
                        setDisplayBasicProp={setDisplayBasic}
                        renderDetailProp={renderDetailProp}
                        setRenderDetailProp={setRenderDetailProp}
                        linkPowerBiProp={linkPowerBiProp}
                    />
                    :
                    null
                }
            </div>
        );
    }
    // validaciones
    // validar fechas dentro del rango del proyecto seleccionado

    var dateStartError = false
    var dateStartMessage = ''

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        }
    }

    var updatePermission = false
    if (dateStart !== null && dateEnd !== null && dateStartError === false && selectedSupplier.id !== '') {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-plus" label="Asociar proveedor" className="btnAdd" onClick={() => {
                onClick('displayBasic')
                resetData()
            }}
                tooltip="Agregar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Asociar proveedor" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Proveedor<span className="require">*</span></label>
                            <Dropdown value={selectedSupplier} options={suppliers} onChange={onSupplierChange} placeholder="Seleccione un proveedor" filter
                                className="p-column-filter " emptyMessage="No hay resultados" name="service" optionLabel="name" optionDisabled={optionDisabledService}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha inicio asignación<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha fin asignación<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={""}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnAddSupplier