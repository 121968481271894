import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../../assets/css/DataTable.css';


const Mensaje = ({ validarProp, enviarMensajeProp, exportPDFProp, setSendPDFProp }) => {

    /* console.log(validarProp) */
    const [displayBasic, setDisplayBasic] = useState(validarProp);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        if (setSendPDFProp !== undefined) {
            setSendPDFProp(false)
        }
    }

    const openUploadFile = (name) => {
        onHide(name)
        window.open(process.env.REACT_APP_DROPBOX_URL)
    }

    const renderFooter = (name) => {
        return (
            <div>
                {exportPDFProp ?
                    <>
                        <Button label="Cancelar" className="p-button-text" icon="pi pi-times" onClick={() => onHide(name)} autoFocus />
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => openUploadFile(name)} autoFocus />
                    </>
                    :
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
                }

            </div>
        );
    }

    return (
        <div className="dialog-demo">
            <div className="card">

                <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic} modal breakpoints={{ '960px': '75vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>{enviarMensajeProp}</span>

                    </div>
                </Dialog>

            </div>
        </div>
    )
}

export default Mensaje