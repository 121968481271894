import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import ConfirmBtn from './ConfirmBtn'
import Services from '../../service/Services';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import '../../assets/css/CalendarSize.css'
import { SumarDias, ValidarMesCerrado, CambiarFormatoFecha, GetDates, obtenerHoraMaximaPorFecha } from '../Funciones'

import Cookies from 'universal-cookie';
import BtnNotas from './BtnNotas';

const cookies = new Cookies();

const BtnActualizarActividad = ({ fechaProp, clienteProp, idClienteProp, proyectoProp, idProyectoProp, tipoActividadProp, idTipoActividadProp,
    ticketProp, areaProp, idAreaProp, sistemaProp, idSistemaProp, actividadProp, horasProp, idActividadProp, horasHoyProp, creadoPorProp, fechaCreacionProp, disabledProp, notesDescriptionProp,
    categoriaProp, idCategoriaProp, idObjetivoProp, idLogroProp, idEntregableProp }) => {

    var idProveedor = cookies.get('idProveedor');

    const toast = useRef(null);

    const [servicioTipoActividad, setServicioTipoActividad] = useState([]);
    const [servicioAplicacion, setServicioAplicacion] = useState([]);
    const [servicioAreaNegocio, setServicioAreaNegocio] = useState([]);
    const [projectsSession, setProjectsSession] = useState([]);
    const [cierres, setCierres] = useState([]);
    const [tipoNovedades, setTipoNovedades] = useState([]);
    const [servicesUsers, setServicesUsers] = useState([]);
    const [servicesCategory, setServicesCategory] = useState([]);
    const getServices = new Services();
    const [objectivesService, setObjectivesServices] = useState([])
    const [achievementServices, setAchievementServices] = useState([])
    const [deliverablesServices, setDeliverablesServices] = useState([])
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        if (displayBasic === true) {
            getServices.getAreasNegocio().then(data => {
                if (data !== undefined) {
                    setServicioAreaNegocio(data)
                }
            });
            getServices.getCategory().then(data => {
                if (data !== undefined) {
                    setServicesCategory(data)
                }
            })
            getServices.getAplicaciones().then(data => {
                if (data !== undefined) {
                    setServicioAplicacion(data)
                }
            });
            getServices.getTipoActividades().then(data => {
                if (data !== undefined) {
                    setServicioTipoActividad(data)
                }
            });
            getServices.getAsociacionProyectoUsuarioVigente().then(data => {
                if (data !== undefined) {
                    setProjectsSession(data)
                }
            });
            getServices.getCierres().then(data => {
                if (data !== undefined) {
                    setCierres(data)
                }
            });
            getServices.getTipoNovedad().then(data => {
                if (data !== undefined) {
                    setTipoNovedades(data)
                }
            });
            getServices.getUsuariosWithIdNameAddActivity().then(data => {
                if (data !== undefined) {
                    setServicesUsers(data)
                }
            });
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (displayBasic === true) {

            getServices.getObjectivesShortList([idProyectoProp]).then((result) => {
                setSelectedObjectivesServiceComplete(result?.find(item => item.id === idObjetivoProp))
                setObjectivesServices(result)
            }).catch((error) => {

            })

            getServices.getAchievementListShort(idObjetivoProp).then((result => {
                setSelectedAchievementServiceComplete(result?.find(item => item.id === idLogroProp))
                setAchievementServices(result)
            })).catch((error) => {

            })

            getServices.getDeliverablesListShort(idObjetivoProp).then((result => {
                setSelectedDeliverablesServiceComplete(result?.find(item => item.id === idEntregableProp))
                setDeliverablesServices(result)
            })).catch((error) => {

            })
        }

    }, [displayBasic, idObjetivoProp, idLogroProp, idEntregableProp, idProyectoProp])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    /// Proceso para llenar los clientes y los proyectos asociados al usuario en sesión

    var clientsUserSession = []

    //// llenar clientes asociados al usuario en sesión 
    projectsSession.forEach(element => {
        var id = element.cliente.id
        var convertToNumber = parseInt(id);
        clientsUserSession.push({
            id: convertToNumber,
            razonsocial: element.cliente.nombre
        })
    });
    const tableClients = {}
    const uniqueClients = clientsUserSession.filter(function (object) {
        return tableClients.hasOwnProperty(object.id) ? false : (tableClients[object.id] = true)
    })

    //Obtener datos del select de clientes

    const [selectedCliente, setSelectedCliente] = useState({
        cliente: clienteProp,
        id: idClienteProp
    });
    /* console.log(clientsUserSession) */

    const clientes = uniqueClients.map(item => {
        const av = { cliente: item.razonsocial, id: item.id }
        return av
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onClienteChange = (e) => {
        setSelectedCliente(e.value);
        setValidarCambio(true)
        setSelectedProyecto({
            proyecto: '',
            id: ''
        })
        setSelectedObjectivesServiceComplete(null)
        setSelectedAchievementServiceComplete(null)
        setSelectedDeliverablesServiceComplete(null)
        validateDate({
            ...objectData,
            cliente: {
                id: parseInt(e.value.id)
            }
        }, inputHoras.horas)
    }

    //// llenar proyectos asociados sugún el cliente seleccionado 

    var proyectsUserSession = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const result = projectsSession.filter(function (object) {
        var idClient = object.cliente.id.toString()
        return idClient === selectedCliente.id.toString()
    })

    result.forEach(element => {
        var id = element.id
        var convertToNumber = parseInt(id);
        proyectsUserSession.push({
            id: convertToNumber,
            nombreproyecto: element.nombre,
            fechafinvigencia: element.fechafinvigencia
        })
    });

    const filterDate = proyectsUserSession.filter(element => {
        return element.fechafinvigencia >= dateV
    })
    filterDate?.forEach(element => {
        proyectoFechaVigente.push(element)
    })

    var dateEndValidError = false
    var messageDateEndValidError = ''

    if (proyectoFechaVigente.length === 0 && selectedCliente.cliente !== '') {

        dateEndValidError = true
        messageDateEndValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
    } else {
        dateEndValidError = false
        messageDateEndValidError = ''
    }


    //Obtener datos del select de proyecto

    const [selectedProyecto, setSelectedProyecto] = useState({
        proyecto: proyectoProp,
        id: idProyectoProp
    });

    const proyectos = proyectoFechaVigente.map(item => {
        const av = { proyecto: item.nombreproyecto, id: item.id }
        return av
    })

    const onProyectoChange = (e) => {
        setSelectedProyecto(e.value);
        setValidarCambio(true)
        setSelectedObjectivesServiceComplete(null)
        setSelectedAchievementServiceComplete(null)
        setSelectedDeliverablesServiceComplete(null)
        validateDate({
            ...objectData,
            proyecto: {
                id: parseInt(e.value.id)
            }
        }, inputHoras.horas)
    }

    //Obtener datos de categoria 
    const [selectedCategoryComplete, setSelectedCategoryComplete] = useState({
        descripcion: categoriaProp,
        id: idCategoriaProp
    });

    const [filteredCategory, setFilteredCategory] = useState(null);

    const searchCategory = (event) => {
        setTimeout(() => {
            let _filteredCategory;
            if (!event.query.trim().length) {
                _filteredCategory = [...servicesCategory];
            }
            else {
                _filteredCategory = servicesCategory.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCategory(_filteredCategory);
        }, 250);
    }

    const onCategoryCompleteChange = (e) => {
        setSelectedCategoryComplete(e.value)
        setValidarCambio(true)
        setSelectedTipoActividadComplete(null)
    }


    //Obtener datos del select tipo de actividades
    const [selectedTipoActividadComplete, setSelectedTipoActividadComplete] = useState({
        nombretipoactividad: tipoActividadProp,
        id: idTipoActividadProp
    });

    const [servicesTypeActivityForCategory, setServicesTypeActivityForCategory] = useState([])
    useEffect(() => {
        //Obtener los tipos de actividad asociado a cada categoria

        if (displayBasic === true) {
            if (selectedCategoryComplete !== null && selectedCategoryComplete.id !== '') {

                const filter = servicioTipoActividad.filter(obj => {
                    return obj.categoria.id === selectedCategoryComplete.id
                })
                setServicesTypeActivityForCategory(filter)
            }
        }

    }, [displayBasic, selectedCategoryComplete, servicioTipoActividad])// eslint-disable-line react-hooks/exhaustive-deps

    const [filteredTipoActividad, setFilteredTipoActividad] = useState(null);

    const searchTipoActividad = (event) => {
        setTimeout(() => {
            let _filteredTipoActividad;
            if (!event.query.trim().length) {
                _filteredTipoActividad = [...servicesTypeActivityForCategory];
            }
            else {
                _filteredTipoActividad = servicesTypeActivityForCategory.filter((element) => {
                    return element.nombretipoactividad.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredTipoActividad(_filteredTipoActividad);
        }, 250);
    }

    const onTipoActividadCompleteChange = (e) => {

        setValidarCambio(true)
        var eOld = { ...selectedTipoActividadComplete }
        if (e.value !== null) {
            if (e.value.nombretipoactividad !== eOld.nombretipoactividad) {
                setSelectedTipoActividadComplete(e.value);
                validarTipoActividad(e.value.nombretipoactividad)
            } else {
                setSelectedTipoActividadComplete(e.value);
            }
            if (typeof (e.value) === 'object') {
                validateDate({
                    ...objectData,
                    tipoActividad: {
                        id: parseInt(e.value.id)
                    }
                }, inputHoras.horas)
            }
        }
    }
    //Obtener datos del select área de negocio

    const [selectedAreaComplete, setSelectedAreaComplete] = useState({
        nombreareanegocio: areaProp,
        id: idAreaProp
    });
    const [filteredArea, setFilteredArea] = useState(null);

    const searchArea = (event) => {
        setTimeout(() => {
            let _filteredArea;
            if (!event.query.trim().length) {
                _filteredArea = [...servicioAreaNegocio];
            }
            else {
                _filteredArea = servicioAreaNegocio.filter((element) => {
                    return element.nombreareanegocio.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredArea(_filteredArea);
        }, 250);
    }

    const onAreaCompleteChange = (e) => {
        setSelectedAreaComplete(e.value)
        setValidarCambio(true)
        if (e.value !== null) {
            if (typeof (e.value) === 'object') {
                validateDate({
                    ...objectData,
                    areanegocio: {
                        id: parseInt(e.value.id)
                    }
                }, inputHoras.horas)
            }
        }
    }

    //Obtener datos del select Sistema

    const [selectedSistemaComplete, setSelectedSistemaComplete] = useState({
        nombreaplicacion: sistemaProp,
        id: idSistemaProp
    });

    //// llenar aplicaciones asociados sugún el cliente seleccionado 

    var AplicationsClients = []

    const resultAplication = servicioAplicacion.filter(function (object) {
        var idClient = object.idcliente.id.toString()
        return idClient === selectedCliente.id.toString()
    })

    resultAplication.forEach(element => {
        AplicationsClients.push(element)
    })

    const [filteredSistema, setFilteredSistema] = useState(null);

    const searchSistema = (event) => {
        setTimeout(() => {
            let _filteredSistema;
            if (!event.query.trim().length) {
                _filteredSistema = [...AplicationsClients];
            }
            else {
                _filteredSistema = AplicationsClients.filter((element) => {
                    return element.nombreaplicacion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredSistema(_filteredSistema);
        }, 250);
    }

    const onSistemaCompleteChange = (e) => {
        setSelectedSistemaComplete(e.value);
        setValidarCambio(true)
        if (e.value !== null) {
            if (typeof (e.value) === 'object') {
                validateDate({
                    ...objectData,
                    aplicacion: {
                        id: parseInt(e.value.id)
                    }
                }, inputHoras.horas)
            }
        }
    }

    useEffect(() => {
        if (displayBasic === true) {
            if (selectedProyecto !== null && selectedProyecto.id !== '') {

                getServices.getObjectivesShortList([parseInt(selectedProyecto.id)]).then((result) => {
                    setObjectivesServices(result)
                }).catch((error) => {

                })
            }
        }

    }, [selectedProyecto, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    //Listado de Objetivos asociados al proyecto seleccionado
    const [selectedObjectivesServiceComplete, setSelectedObjectivesServiceComplete] = useState(null);
    const [filteredObjectivesService, setFilteredObjectivesService] = useState(null);

    //// llenar objetivos  asociados al proyecto seleccionado

    const tableObjectivesServices = {}
    const uniqueObjectivesServices = objectivesService?.filter(function (object) {
        return tableObjectivesServices.hasOwnProperty(object.id) ? false : (tableObjectivesServices[object.id] = true)
    })

    const searchObjectives = (event) => {
        setTimeout(() => {
            let _filteredObjectives;
            if (!event.query.trim().length) {
                _filteredObjectives = [...uniqueObjectivesServices];
            }
            else {
                _filteredObjectives = uniqueObjectivesServices.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredObjectivesService(_filteredObjectives);
        }, 250);
    }

    const objectivesOnChange = (e) => {
        setSelectedObjectivesServiceComplete(e.value)
        setValidarCambio(true)
        setSelectedAchievementServiceComplete(null)
        setSelectedDeliverablesServiceComplete(null)
    }

    useEffect(() => {
        if (displayBasic === true) {
            if (selectedObjectivesServiceComplete !== null && selectedObjectivesServiceComplete !== undefined) {

                getServices.getAchievementListShort(selectedObjectivesServiceComplete.id).then((result => {
                    setAchievementServices(result)
                })).catch((error) => {

                })

                getServices.getDeliverablesListShort(selectedObjectivesServiceComplete.id).then((result => {
                    setDeliverablesServices(result)
                })).catch((error) => {

                })
            }
        }

    }, [selectedObjectivesServiceComplete, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    //Listado de logros asociados al objetivo seleccionado

    const [selectedAchievementServiceComplete, setSelectedAchievementServiceComplete] = useState(null);
    const [filteredAchievementService, setFilteredAchievementService] = useState(null);

    const tableAchievementServices = {}
    const uniqueAchievementServices = achievementServices?.filter(function (object) {
        return tableAchievementServices.hasOwnProperty(object.id) ? false : (tableAchievementServices[object.id] = true)
    })

    const searchAchievement = (event) => {
        setTimeout(() => {
            let _filteredAchievement;
            if (!event.query.trim().length) {
                _filteredAchievement = [...uniqueAchievementServices];
            }
            else {
                _filteredAchievement = uniqueAchievementServices.filter((element) => {
                    return element.logro.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredAchievementService(_filteredAchievement);
        }, 250);
    }

    const achievementOnChange = (e) => {
        setSelectedAchievementServiceComplete(e.value)
        setValidarCambio(true)
    }

    //Listado de entregables asociados al objetivo seleccionado

    const [selectedDeliverablesServiceComplete, setSelectedDeliverablesServiceComplete] = useState(null);
    const [filteredDeliverablesService, setFilteredDeliverablesService] = useState(null);

    const tableDeliverableServices = {}
    const uniqueDeliverablesServices = deliverablesServices?.filter(function (object) {
        return tableDeliverableServices.hasOwnProperty(object.id) ? false : (tableDeliverableServices[object.id] = true)
    })

    const searchDeliverables = (event) => {
        setTimeout(() => {
            let _filteredDeliverables;
            if (!event.query.trim().length) {
                _filteredDeliverables = [...uniqueDeliverablesServices];
            }
            else {
                _filteredDeliverables = uniqueDeliverablesServices.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredDeliverablesService(_filteredDeliverables);
        }, 250);
    }

    const deliverablesOnChange = (e) => {
        setSelectedDeliverablesServiceComplete(e.value)
        setValidarCambio(true)
    }

    //Notas de la actividad
    const [noteDescription, setNoteDescription] = useState('')

    //Obtener datos del input ticket
    const [inputTicket, setInputTicket] = useState({
        ticket: ticketProp
    })

    const onTicketChange = (event) => {
        setInputTicket({
            ...inputTicket,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
        validateDate({
            ...objectData,
            ticket: event.target.value
        }, inputHoras.horas)
    }
    //Obtener datos del input actividad

    const [inputActividad, setInputActividad] = useState({
        actividad: actividadProp
    })

    const [actividadBlur, setActividadBlur] = useState(true)
    const onActividadChange = (event) => {
        setInputActividad({
            ...inputActividad,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    const onActividadBlur = (event) => {
        setActividadBlur(true)
        validateDate({
            ...objectData,
            descripcionactividad: event.target.value
        }, inputHoras.horas)
    }
    //Obtener datos del input número de horas

    const [inputHoras, setInputHoras] = useState({
        horas: horasProp
    })

    const onHorasChange = (event) => {
        const newValue = event.target.value;
        if ((newValue.match(/\./g) || []).length > 1 || newValue.startsWith('.')) {
            return;
        }
        setInputHoras({
            ...inputHoras,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
        validateDate({
            ...objectData
        }, event.target.value)
    }

    /// calendar de fecha 

    var gmtHours = -d.getTimezoneOffset() / 60;

    const [date, setDate] = useState(fechaProp);
    const [guardarFecha, setGuardarFecha] = useState(null);
    const [sendDate, setSendDate] = useState(fechaProp)


    useEffect(() => {
        setDate(fechaProp)
        setSendDate(fechaProp)
    }, [fechaProp])

    useEffect(() => {
        const fechaConvertida = new Date(fechaProp)
        if (gmtHours < 0) {
            setGuardarFecha(SumarDias(fechaConvertida, +1))
        } else {
            setGuardarFecha(fechaConvertida)
        }
    }, [fechaProp, gmtHours])

    const onConvertirFechaChange = (e) => {
        let fecha = e.value
        setDate(fecha)
        setSendDate(CambiarFormatoFecha(fecha))
        setGuardarFecha(fecha)
        setValidarCambio(true)
        if (fecha !== null) {
            var validacion = ValidarMesCerrado(fecha, minFechaCierre, disabledDates)
            validateDate({
                ...objectData,
                fecha: CambiarFormatoFecha(fecha)
            }, inputHoras.horas)
            setFechaError(validacion.setFechaError)
            setMensajeFecha(validacion.setMensajeFecha)
            setValidarTipoActividad(validacion.setMostrarAdvertencia)
            setValidarTipoActividadMensaje(validacion.setMostrarAdvertenciaMensaje)
            setValidarMes(validacion.setValidarMes)
        }

    }


    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }


    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setSelectedCliente({
            cliente: clienteProp,
            id: idClienteProp
        })

        setSelectedProyecto({
            proyecto: proyectoProp,
            id: idProyectoProp
        })
        setSelectedCategoryComplete({
            descripcion: categoriaProp,
            id: idCategoriaProp
        })
        setSelectedTipoActividadComplete({
            nombretipoactividad: tipoActividadProp,
            id: idTipoActividadProp
        })

        setSelectedAreaComplete({
            nombreareanegocio: areaProp,
            id: idAreaProp
        })
        setSelectedSistemaComplete({
            nombreaplicacion: sistemaProp,
            id: idSistemaProp
        })
        setInputActividad({
            actividad: actividadProp
        })
        setInputHoras({
            horas: horasProp
        })
        setInputTicket({
            ticket: ticketProp
        })


    }, [clienteProp, idClienteProp, proyectoProp, idProyectoProp, tipoActividadProp, idTipoActividadProp, ticketProp, areaProp, idAreaProp, sistemaProp, idSistemaProp, actividadProp, horasProp, idCategoriaProp, categoriaProp])

    //Almacenamos los valores para enviarlos por props
    const fecha = date
    const cliente = selectedCliente.cliente
    const idCliente = selectedCliente.id
    const proyecto = selectedProyecto.proyecto
    const idProyecto = selectedProyecto.id

    var tipoActividad
    var idTipoActividad
    if (selectedTipoActividadComplete !== null) {
        tipoActividad = selectedTipoActividadComplete.nombretipoactividad
        idTipoActividad = selectedTipoActividadComplete.id
    }

    const ticket = inputTicket.ticket

    var area
    var idArea
    if (selectedAreaComplete !== null) {
        area = selectedAreaComplete.nombreareanegocio
        idArea = selectedAreaComplete.id
    }

    var sistema
    var idSistema
    if (selectedSistemaComplete !== null) {
        sistema = selectedSistemaComplete.nombreaplicacion
        idSistema = selectedSistemaComplete.id
    }

    const actividad = inputActividad.actividad
    const horas = inputHoras.horas
    const idColaborador = cookies.get('idColaborador')
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            setNewErrorHours(false)
            setNewMessageHours('')
            setErrorDateStartUserSession(false)
            setErrorMessageDateStartUserSession('')
            setSelectedCliente({
                cliente: clienteProp,
                id: idClienteProp
            })
            setSelectedProyecto({
                proyecto: proyectoProp,
                id: idProyectoProp
            })
            setSelectedCategoryComplete({
                descripcion: categoriaProp,
                id: idCategoriaProp
            })
            setSelectedTipoActividadComplete({
                nombretipoactividad: tipoActividadProp,
                id: idTipoActividadProp
            })
            setSelectedAreaComplete({
                nombreareanegocio: areaProp,
                id: idAreaProp
            })
            setSelectedSistemaComplete({
                nombreaplicacion: sistemaProp,
                id: idSistemaProp
            })
            setInputActividad({
                actividad: actividadProp
            })
            setInputHoras({
                horas: horasProp
            })
            setInputTicket({
                ticket: ticketProp
            })
            setDate(fechaProp)
            setFechaError(false)
            setMensajeFecha('')
            setShowErrorDataDuplicate(false)
            setMenssageDataDuplicate('')

            setValidarCambio(false)

            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

            getServices.getObjectivesShortList([parseInt(idProyectoProp)]).then((result) => {
                setSelectedObjectivesServiceComplete(result?.find(item => item.id === idObjetivoProp))
                setObjectivesServices(result)

            }).catch((error) => {

            })

            getServices.getAchievementListShort(idObjetivoProp).then((result => {
                setSelectedAchievementServiceComplete(result?.find(item => item.id === idLogroProp))
                setAchievementServices(result)
            })).catch((error) => {

            })

            getServices.getDeliverablesListShort(idObjetivoProp).then((result => {
                setSelectedDeliverablesServiceComplete(result?.find(item => item.id === idEntregableProp))
                setDeliverablesServices(result)
            })).catch((error) => {

            })

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }

                {
                    permisoActualizar ?
                        <ConfirmBtn
                            titulo="Actualizar Registro"
                            mensaje="¿Estás seguro de actualizar Actividad?"
                            accion="Actualizar"
                            label="Actualizar"
                            icono="pi pi-pencil"
                            idColaboradorProp={idColaborador}
                            usuarioProp={usuario}
                            fechaProp={sendDate}
                            clienteProp={cliente}
                            idClienteProp={idCliente}
                            proyectoProp={proyecto}
                            idProyectoProp={idProyecto}
                            tipoActividadProp={tipoActividad}
                            idTipoActividadProp={idTipoActividad}
                            ticketProp={ticket}
                            areaProp={area}
                            idAreaProp={idArea}
                            sistemaProp={sistema}
                            idSistemaProp={idSistema}
                            coordinadoConProp=""
                            fechaCorreoProp=""
                            actividadProp={actividad}
                            creadoPorProp={creadoPorProp}
                            fechaCreacionProp={fechaCreacionProp}
                            horasProp={horas}
                            idActividadProp={idActividadProp}
                            esAdicionalProp="0"
                            indicadorProp="0"
                            notasProp={noteDescription}
                            idProveedorProp={idProveedor}
                            idObjetivoProp={selectedObjectivesServiceComplete !== null ? parseInt(selectedObjectivesServiceComplete?.id) : null}
                            idLogroProp={selectedAchievementServiceComplete !== null ? parseInt(selectedAchievementServiceComplete?.id) : null}
                            idEntregableProp={selectedDeliverablesServiceComplete !== null ? parseInt(selectedDeliverablesServiceComplete?.id) : null}
                            BtnAsociarActividadesProp={false}
                        />
                        :
                        ""
                }

            </div>
        );
    }
    //validaciones

    const [fechaError, setFechaError] = useState(false)
    const [mensajeFecha, setMensajeFecha] = useState('')

    var clienteError = false
    var mensajeCliente = ''
    if (cliente === '') {
        clienteError = true
        mensajeCliente = 'Debe ingresar un cliente'
    }
    var proyectoError = false
    var mensajeProyecto = ''
    var disabledProyecto

    if (cliente === '') {
        disabledProyecto = true
    } else {
        disabledProyecto = false
        if (proyecto === '') {
            proyectoError = true
            mensajeProyecto = 'Debe ingresar un proyecto'
        }
    }

    let disabledObjectives = false
    if (selectedProyecto.id === '') {
        disabledObjectives = true
    } else {
        disabledObjectives = false
    }

    let disabledAchivementAndDeliverable = false
    if (selectedObjectivesServiceComplete === null || selectedObjectivesServiceComplete === undefined) {
        disabledAchivementAndDeliverable = true
    } else {
        disabledAchivementAndDeliverable = false
    }

    var tipoActividadError = false
    var mensajeTipoActividad = ''

    if (typeof (selectedTipoActividadComplete) === 'string') {
        tipoActividadError = true
        mensajeTipoActividad = 'Resultado no encontrado'
    }

    var areaError = false
    var mensajeArea = ''

    if (typeof (selectedAreaComplete) === 'string') {
        areaError = true
        mensajeArea = 'Resultado no encontrado'
    }

    var sistemaError = false
    var mensajeSistema = ''

    if (typeof (selectedSistemaComplete) === 'string') {
        sistemaError = true
        mensajeSistema = 'Resultado no encontrado'
    }

    if (selectedCliente.id !== '') {
        if (AplicationsClients.length === 0) {
            sistemaError = true
            mensajeSistema = 'El cliente seleccionado no tiene aplicaciones asociadas'
        }
    }

    var ticketError = false
    var mensajeTicket = ''
    if (tipoActividad === 'Soporte-Requerimientos' || tipoActividad === 'Soporte-Incidencias') {
        ticketError = true
        mensajeTicket = 'Debes ingresar un ticket'
        if (ticket !== '') {
            if (ticket.length < 2) {
                ticketError = true
                mensajeTicket = 'El ticket debe tener más de 2 dígitos'
            } else if (ticket.length > 50) {
                ticketError = true
                mensajeTicket = 'El ticket debe tener menos de 50 dígitos'
            } else {
                ticketError = false
                mensajeTicket = ''
            }
        }
    } else {
        ticketError = false
        mensajeTicket = ''
    }

    var actividadError = false
    var mensajeActividad = ''
    if (actividad === '') {
        actividadError = true
        mensajeActividad = 'Debe agreagar una descripción de la actividad'
    } else if (actividad.length < 10) {
        actividadError = true
        mensajeActividad = 'La descripción es muy corta'
    } else if (actividad.length > 300) {
        actividadError = true
        mensajeActividad = 'La descripción es demasiado larga '
    }

    //validación actividades identicas 

    var objectData = {
        cliente: {
            id: parseInt(idCliente)
        },
        proyecto: {
            id: parseInt(idProyecto)
        },
        tipoActividad: {
            id: parseInt(idTipoActividad)
        },
        areanegocio: {
            id: idArea
        },
        aplicacion: {
            id: idSistema
        },
        id: idActividadProp,
        fecha: guardarFecha && CambiarFormatoFecha(guardarFecha),
        descripcionactividad: actividad,
        ticket: ticket
    }

    const [showErrorDataDuplicate, setShowErrorDataDuplicate] = useState(false);
    const [menssageDataDuplicate, setMenssageDataDuplicate] = useState('');

    const [newErrorHours, setNewErrorHours] = useState(false)
    const [newMessageHours, setNewMessageHours] = useState('')

    const [newErrorVacaciones, setNewErrorVacaciones] = useState(false)

    var dataDuplicate = []

    const validateDate = async (objectData, hours, source) => {
        if (objectData.fecha !== null) {
            let fechaConvertida = [objectData.fecha]
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const json = JSON.stringify({
                esadicional: "0",
                fecFinal: objectData.fecha,
                fecInicial: objectData.fecha,
                usuario: usuario
            })
            /* console.log(json) */
            await axios.post(url, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    },
                    cancelToken: source?.token
                })
                .then(response => {
                    var newData = response.data.filter(obj => obj.id !== idActividadProp)
                    const historicoHoras = sessionStorage.getItem('historicoHoras')
                    const newHistoricoHoras = JSON.parse(historicoHoras)

                    let excedioHoras = false
                    let numeroTotalHoras = 0
                    if (parseFloat(hours) === parseFloat(0) && parseFloat(hours) === parseFloat(0.0)) {
                        setNewErrorHours(true)
                        setNewMessageHours('Las horas registradas deben ser mayor a 0')
                    } else {

                        if (newData.length > 0) {
                            newData.forEach(element => {
                                numeroTotalHoras += element.numerohoras

                                const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(newHistoricoHoras, [element.fecha])

                                if (parseFloat(horasMaximaPorFecha[element.fecha]) !== 0) {
                                    if (parseFloat(numeroTotalHoras) + parseFloat(hours) > parseFloat(horasMaximaPorFecha[element.fecha])) {
                                        excedioHoras = true
                                    }
                                    if (excedioHoras === true) {
                                        setNewErrorHours(true)
                                        setNewMessageHours('No se pueden registrar más de las horas máximas parametrizadas')
                                    } else {
                                        setNewErrorHours(false)
                                        setNewMessageHours('')
                                    }
                                } else {

                                    if (parseFloat(numeroTotalHoras) + parseFloat(hours) > 24) {
                                        setNewErrorHours(true)
                                        setNewMessageHours('No se pueden pasar de las 24 horas del día')
                                    }
                                    else {
                                        setNewErrorHours(false)
                                        setNewMessageHours('')
                                    }
                                }
                            })
                        } else {
                            //Validar horas máximas cuando no hay en fechas donde hay actividades registradas
                            fechaConvertida.forEach(fecha => {
                                const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(newHistoricoHoras, [fecha])

                                if (parseFloat(horasMaximaPorFecha[fecha]) > parseFloat(0)) {
                                    if (parseFloat(hours) > parseFloat(parseFloat(horasMaximaPorFecha[fecha]))) {
                                        excedioHoras = true
                                    }
                                    if (excedioHoras === true) {
                                        setNewErrorHours(true)
                                        setNewMessageHours('No se pueden registrar más de las horas máximas parametrizadas')
                                    } else {
                                        setNewErrorHours(false)
                                        setNewMessageHours('')
                                    }
                                } else {
                                    if (parseFloat(hours) > parseFloat(24)) {
                                        setNewErrorHours(true)
                                        setNewMessageHours('No se pueden pasar de las 24 horas del día')
                                    }
                                    else {
                                        setNewErrorHours(false)
                                        setNewMessageHours('')
                                    }
                                }
                            })
                        }
                        //Validar que no se puede registrar una actividad en una novedad de tiempo completo
                        const filterVacaciones = tipoNovedades.filter(item => {
                            return item.isVacaciones === true
                        })

                        newData.forEach(i => {
                            filterVacaciones.forEach(element => {
                                if (element.tipoActividad.id === i.tipoActividad.id) {
                                    setNewErrorVacaciones(true)
                                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'No se puede registrar una actividad en una novedad de tiempo completo', life: 6000 });
                                } else {
                                    setNewErrorVacaciones(false)
                                }
                            })
                        })
                    }


                    var newArrayDataRegistered = newData.map(item => {
                        return {
                            cliente: {
                                id: item.cliente.id
                            },
                            proyecto: {
                                id: item.proyecto.id
                            },
                            tipoActividad: {
                                id: item.tipoActividad.id
                            },
                            areanegocio: {
                                id: item.areanegocio.id
                            },
                            aplicacion: {
                                id: item.aplicacion.id
                            },
                            id: item.id,
                            fecha: item.fecha,
                            descripcionactividad: item.descripcionactividad,
                            ticket: item.ticket
                        }
                    })
                    newArrayDataRegistered.forEach(x => {
                        if (x.id !== objectData.id) {
                            x.id = ''
                            objectData.id = ''
                            if ((JSON.stringify(x) === JSON.stringify(objectData) === true)) {
                                dataDuplicate.push(x)
                            }
                        }
                    })
                })

                .catch(function (error) {
                    //console.log(error.response)
                })


            if (dataDuplicate.length !== 0) {
                setShowErrorDataDuplicate(true)
                setMenssageDataDuplicate('Los datos registrados son exactamente iguales a una actividad ya registrada, modifique los campos')
            } else {
                setShowErrorDataDuplicate(false)
                setMenssageDataDuplicate('')
            }
        }
    }



    const handleChange = (e) => {
        e.preventDefault();
    };

    // validar Mes cerrado
    var minFechaCierre
    var disabledDates
    var idUsuario = cookies.get('idUsuario')
    const resultUser = servicesUsers.filter(item => {
        return parseInt(item.idUsuario) === parseInt(idUsuario)
    })

    var mesAbierto = []
    if (resultUser.length !== 0) {
        resultUser.forEach(element => {
            mesAbierto = cierres.filter(function (objeto) {
                var id = element.blnAddActivitivy
                return objeto.id === id
            })
        })
    }
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    if (mesAbierto.length === 0) {
        cierres.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    } else {
        mesesCerrados.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    }
    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))
    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))

    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre)
    var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2)

    var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre)
    var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1)

    /* console.log(getMesAbierto.fechaInicialCierre)
    console.log(getMesAbierto.fechaFinalCierre) */

    var dateArray = GetDates(newFechaFinalCierre, (fechaUltimoCierre).addDays(2));

    cierres.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })

    if (dateArray.length !== 0) {
        minFechaCierre = newFechaInicialCierre
        disabledDates = dateArray
    } else {
        minFechaCierre = SumarDias(fechaUltimoCierre, +1)
        disabledDates = []
    }


    // Modal para validar si es una actividad o una novedad


    const [ValidarTipoActividad, setValidarTipoActividad] = useState(false)
    const [ValidarTipoActividadMensaje, setValidarTipoActividadMensaje] = useState('')

    const [validarMes, setValidarMes] = useState(false)

    const validarTipoActividad = (tipoNovedad) => {
        tipoNovedades.forEach(element => {
            if (tipoNovedad === element.descripcion) {
                setValidarTipoActividad(true)
                setValidarTipoActividadMensaje('Señor usuario la actividad seleccionada pertenece a una novedad, debe dirigirse a la pestaña novedades para registrarla')
                setValidarMes(false)
            }
        })
    }


    const MensajeTipoActividad = ({ mostrarMensajeProp, mensajeProp }) => {
        const [displayBasic, setDisplayBasic2] = useState(mostrarMensajeProp);

        const dialogFuncMap = {
            'displayBasic': setDisplayBasic2,
        }

        const onHide = (name) => {

            setValidarTipoActividad(false)
            setValidarTipoActividadMensaje('')

            setValidarCambio(false)
            if (validarMes !== true) {
                setDisplayBasic(false)
                setSelectedCliente({
                    cliente: clienteProp,
                    id: idClienteProp
                })
                setSelectedProyecto({
                    proyecto: proyectoProp,
                    id: idProyectoProp
                })
                setSelectedTipoActividadComplete({
                    nombretipoactividad: tipoActividadProp,
                    id: idTipoActividadProp
                })
                setSelectedAreaComplete({
                    nombreareanegocio: areaProp,
                    id: idAreaProp
                })
                setSelectedSistemaComplete({
                    nombreaplicacion: sistemaProp,
                    id: idSistemaProp
                })
                setInputActividad({
                    actividad: actividadProp
                })
                setInputHoras({
                    horas: horasProp
                })
                setInputTicket({
                    ticket: ticketProp
                })
                setDate(fechaProp)
            }
            setDisplayBasic2(false)
            dialogFuncMap[`${name}`](false);

        }

        const renderFooter = (name) => {
            return (
                <div>
                    {/* <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" /> */}
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic} modal footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')} breakpoints={{ '960px': '75vw' }}  >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>{mensajeProp}</span>

                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
    // Sé valida que las fechas de registrar actividad no sean menores que la fecha de ingreso del usuario en sessión
    const [errorDateStartUserSession, setErrorDateStartUserSession] = useState(false)
    const [errorMessageDateStartUserSession, setErrorMessageDateStartUserSession] = useState('')
    useEffect(() => {
        const dateStartUserSession = cookies.get('fechaIngreso')
        if (guardarFecha !== null) {
            if (CambiarFormatoFecha(guardarFecha) < dateStartUserSession) {
                setErrorDateStartUserSession(true)
                setErrorMessageDateStartUserSession(`No puedes registrar actividades con fecha menor a su fecha de ingreso, la cuál es ${dateStartUserSession}`)
            } else {
                setErrorDateStartUserSession(false)
                setErrorMessageDateStartUserSession('')
            }
        }
    }, [guardarFecha])

    const [errorEntero, setErrorEntero] = useState(false)
    const [messageEntero, setMessageEntero] = useState('')
    useEffect(() => {
        if (inputHoras.horas !== '') {
            const validarEntero = isValidNumber(inputHoras.horas)
            if (validarEntero === false) {
                setErrorEntero(true)
                setMessageEntero('Debe ingresar números enteros o decimales')
            } else {
                setErrorEntero(false)
                setMessageEntero('')
            }
        }
        function isValidNumber(value) {
            // Verifica si el valor es un número válido
            return /^\d+(\.\d+)?$/.test(value);
        }
    }, [inputHoras.horas])// eslint-disable-line react-hooks/exhaustive-deps

    //Validar que el  usuario no se exceda del máximo de horas permitidos

    var permisoActualizar = false
    if (fechaError === false && fecha !== null && clienteError === false && proyectoError === false && tipoActividadError === false && selectedTipoActividadComplete !== null && areaError === false
        && selectedAreaComplete !== null && sistemaError === false && selectedSistemaComplete !== null && ticketError === false && actividadError === false && dateEndValidError === false
        && showErrorDataDuplicate === false && errorDateStartUserSession === false

        && newErrorHours === false && (actividadBlur !== null && actividadBlur === true)
        && errorEntero === false && newErrorVacaciones === false) {
        permisoActualizar = true
    }
    return (

        <div className="">
            <div>
                <Toast ref={toast} />

                <Button className="p-button-secondary" icon="pi pi-pencil" onClick={() => onClick('displayBasic')}
                    tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} disabled={disabledProp}
                ></Button>

                <MensajeTipoActividad
                    mostrarMensajeProp={ValidarTipoActividad}
                    mensajeProp={ValidarTipoActividadMensaje}

                />
                <Dialog className="modal" header="Actualizar Actividad" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Fecha<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={guardarFecha} onChange={onConvertirFechaChange} dateFormat="dd-mm-yy" locale="es"
                                    className={fechaError || errorDateStartUserSession ? "p-invalid" : ""} />
                                <p className="mensajeError">{errorDateStartUserSession ? errorMessageDateStartUserSession : ""}</p>
                                <p className="mensajeError">{fechaError ? mensajeFecha : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Cliente<span className="require">*</span></label>
                                <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="cliente"
                                    placeholder="Seleccione un cliente" filter
                                    className={clienteError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{clienteError ? mensajeCliente : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Proyecto<span className="require">*</span></label>
                                <Dropdown value={selectedProyecto} options={proyectos} onChange={onProyectoChange} name="proyecto" optionLabel="proyecto"
                                    placeholder="Seleccione un proyecto" disabled={disabledProyecto} filter
                                    className={proyectoError || dateEndValidError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{proyectoError ? mensajeProyecto : ""}</p>
                                <p className="mensajeError">{dateEndValidError ? messageDateEndValidError : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels">Categoria<span className="require">*</span></label>
                                <AutoComplete value={selectedCategoryComplete} suggestions={filteredCategory} completeMethod={searchCategory} field="descripcion"
                                    onChange={onCategoryCompleteChange} placeholder="Seleccione una categoria" dropdown forceSelection filterMatchMode="startsWith"
                                    className={tipoActividadError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{tipoActividadError ? mensajeTipoActividad : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Tipo Actividad<span className="require">*</span></label>
                                <AutoComplete value={selectedTipoActividadComplete} suggestions={filteredTipoActividad} completeMethod={searchTipoActividad} field="nombretipoactividad"
                                    onChange={onTipoActividadCompleteChange} placeholder="Seleccione un tipo de actividad"
                                    className={tipoActividadError ? "p-invalid auto" : "auto"} dropdown forceSelection filterMatchMode="startsWith"
                                />
                                <p className="mensajeError">{tipoActividadError ? mensajeTipoActividad : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels">Req.Ticket</label>
                                <InputText id="ticket" type="text" name="ticket" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onTicketChange} value={ticket}
                                    placeholder=" Registre un ticket"
                                    className={ticketError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{ticketError ? mensajeTicket : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Área Negocio<span className="require">*</span></label>
                                <AutoComplete value={selectedAreaComplete} suggestions={filteredArea} completeMethod={searchArea} field="nombreareanegocio"
                                    onChange={onAreaCompleteChange} placeholder="Seleccione un área de negocio" dropdown forceSelection filterMatchMode="startsWith"
                                    className={areaError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{areaError ? mensajeArea : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Sistema Aplicación<span className="require">*</span></label>
                                <AutoComplete value={selectedSistemaComplete} suggestions={filteredSistema} completeMethod={searchSistema} field="nombreaplicacion"
                                    onChange={onSistemaCompleteChange} placeholder="Seleccione un Sistema" dropdown forceSelection filterMatchMode="startsWith"
                                    className={sistemaError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{sistemaError ? mensajeSistema : ""}</p>
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Objetivo</label>
                                <AutoComplete value={selectedObjectivesServiceComplete} suggestions={filteredObjectivesService} completeMethod={searchObjectives} field="descripcion"
                                    onChange={objectivesOnChange} placeholder="Seleccione un Objetivo" dropdown forceSelection filterMatchMode="startsWith"
                                    disabled={disabledObjectives} className={"auto"}
                                />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Logro</label>
                                <AutoComplete value={selectedAchievementServiceComplete} suggestions={filteredAchievementService} completeMethod={searchAchievement} field="logro"
                                    onChange={achievementOnChange} placeholder="Seleccione un Logro" dropdown forceSelection filterMatchMode="startsWith"
                                    disabled={disabledAchivementAndDeliverable} className={"auto"}
                                />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Entregable</label>
                                <AutoComplete value={selectedDeliverablesServiceComplete} suggestions={filteredDeliverablesService} completeMethod={searchDeliverables} field="descripcion"
                                    onChange={deliverablesOnChange} placeholder="Seleccione un Entregable" dropdown forceSelection filterMatchMode="startsWith"
                                    disabled={disabledAchivementAndDeliverable} className={"auto"}
                                />
                            </div>

                            <div className="p-field p-col-12 p-md-9">
                                <label className="labels" >Actividad<span className="require">*</span></label>
                                <InputText id="actividad" type="text" name="actividad" keyfilter={/^[^<>°|'{}]+$/} onChange={onActividadChange} onBlurCapture={onActividadBlur} value={actividad}
                                    placeholder="Describa la Actividad que realizó"
                                    className={actividadError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{actividadError ? mensajeActividad : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label className="labels" >Número Horas<span className="require">*</span></label>
                                <InputText id="horas" type="text" name="horas" keyfilter={/^[0-9]*\.?[0-9]*$/} onChange={onHorasChange} value={horas}
                                    placeholder="Registre el número de horas"
                                    className={newErrorHours || errorEntero ? "p-invalid" : ""} onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                                />
                                <p className="mensajeError">
                                    {newErrorHours ? newMessageHours : ''}{errorEntero ? messageEntero : ""}</p>
                            </div>

                            <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                                {showErrorDataDuplicate === true ?
                                    <Message severity="error" text={menssageDataDuplicate} />
                                    :
                                    ""
                                }
                            </div>
                        </form>
                    </div>
                    <BtnNotas
                        titleProp={'Nota'}
                        tooltipProp={'Actualizar notas'}
                        notesDescriptionProp={notesDescriptionProp}
                        setNoteDescriptionProp={setNoteDescription}
                        footerProp={true}
                        btnCrearActividadProp={false}
                    />
                </Dialog>
            </div>
        </div>
    )
}
export default BtnActualizarActividad