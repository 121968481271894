import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ConfirmBtn from './ConfirmBtn'
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const BtnCrearCliente = ({ renderProp, setRenderProp }) => {

    const [servicioTipoDocumento, setServicioTipoDocumento] = useState([]);
    const getServices = new Services();
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        if (displayBasic === true) {
            getServices.getTiposDocumento().then(data => setServicioTipoDocumento(data));
        }

    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del select tipo de documentos

    const [selectedTipoDocumento, setSelectedTipoDocumento] = useState({
        tipoDocumento: '',
        id: ''
    });
    const tipoDocumentos = servicioTipoDocumento.map(item => {
        const av = { tipoDocumento: item.nombretipodocumento, id: item.id }
        return av
    });

    const [validarCambio, setValidarCambio] = useState(false)

    const onTipoDocumentoChange = (e) => {
        setSelectedTipoDocumento(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del input número de documento 

    const [inputNumeroDocumento, setInputNumeroDocumento] = useState({
        numeroDocumento: ''
    })

    const onNumeroDocumentoChange = (event) => {

        setInputNumeroDocumento({
            ...inputNumeroDocumento,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }
    //Obtener datos del input de nombre

    const [inputNombre, setInputNombre] = useState({
        nombre: ''
    })

    const onNombreChange = (event) => {

        setInputNombre({
            ...inputNombre,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input de dominio

    const [inputDominio, setInputDominio] = useState({
        dominio: ''
    })

    const onDominioChange = (event) => {

        setInputDominio({
            ...inputDominio,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }


    //Almacenamos los valores para enviarlos por props

    const tipoDocumento = selectedTipoDocumento.tipoDocumento
    const idTipoDocumento = selectedTipoDocumento.id
    const numeroDocumento = inputNumeroDocumento.numeroDocumento
    const nombre = inputNombre.nombre
    const dominio = inputDominio.dominio
    const idColaborador = cookies.get('idColaborador')
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            setSelectedTipoDocumento({
                tipoDocumento: ''
            })
            setInputNumeroDocumento({
                numeroDocumento: ''
            })
            setInputNombre({
                nombre: ''
            })

            setInputDominio({
                dominio: ''
            })

            setValidarCambio(false)



            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }


    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }

                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registrar este cliente?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            tipoDocumentoProp={tipoDocumento}
                            idTipoDocumentoProp={idTipoDocumento}
                            numeroDocumentoProp={numeroDocumento}
                            nombreProp={nombre}
                            dominioProp={dominio}
                            idColaboradorProp={idColaborador}
                            usuarioProp={usuario}
                            showDataProp={true}
                            renderProp={renderProp}
                            setRenderProp={setRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                        />
                        :
                        ""
                }

            </div>
        );
    }
    //validaciones

    var tipoDocumentoError = false
    var mensajeTipoDocumento = ''

    var numeroDocumentoError = false
    var mensajeNumeroDocumento = ''
    if (numeroDocumento.length < 3 && numeroDocumento !== '') {
        numeroDocumentoError = true
        mensajeNumeroDocumento = 'El número de documento debe ser mayor a tres dígitos'
    } else if (numeroDocumento.length > 20) {
        numeroDocumentoError = true
        mensajeNumeroDocumento = 'El número de documento debe ser menor a 20 dígitos'
    }
    else if (tipoDocumento !== 'Pasaporte' && tipoDocumento !== "Cédula Extranjería") {
        if (isNaN(numeroDocumento) === true) {
            numeroDocumentoError = true
            mensajeNumeroDocumento = 'Este tipo de documento no puede contener letras'
        }
    }

    var nombreError = false
    var mensajeNombre = ''
    if (nombre.length <= 1 && nombre !== '') {
        nombreError = true
        mensajeNombre = 'El nombre / razón social debe ser mayor a 1 dígitos'
    } else if (nombre.length > 100) {
        nombreError = true
        mensajeNombre = 'El nombre / razón social debe ser menor a 100 dígitos'
    }

    var dominioError = false
    var mensajeDominio = ''
    if (dominio.length <= 5 && dominio !== '') {
        dominioError = true
        mensajeDominio = 'El dominio debe ser mayor a 5 dígitos'
    } else if (dominio.length > 100) {
        dominioError = true
        mensajeDominio = 'El dominio debe ser menor a 100 dígitos'
    } else if (dominio.length > 5 && dominio.length < 100) {
        if ((dominio.match(/^[^.]+\.[^.]+(\.[^.]+)*$/)) === null) {
            dominioError = true
            mensajeDominio = 'El dominio ingresado no es válido'
        }
    }


    var placeholder = 'Ingrese un número de documento'
    if (tipoDocumento === "Nit") {
        placeholder = 'El número de NIT del cliente sin digito de verificación'
    }
  
    //validar cuando es pasaporte 
    var validarPasaporte = false

    if (selectedTipoDocumento.tipoDocumento === "Pasaporte" || selectedTipoDocumento.tipoDocumento === "Cédula Extranjería") {
        validarPasaporte = true
    }

    var permisoRegistrar = false
    if (tipoDocumentoError === false && numeroDocumentoError === false && numeroDocumento !== '' && nombreError === false && nombre !== '' && dominioError === false && dominio !== '') {
        permisoRegistrar = true
    }
    return (

        <div className="">
            <div>
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar Cliente</button>

                <Dialog className="modal" header="Registrar Cliente" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >

                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Tipo de documento<span className="require">*</span></label>
                                <Dropdown value={selectedTipoDocumento} options={tipoDocumentos} onChange={onTipoDocumentoChange} name="tipoDocumento" optionLabel="tipoDocumento"
                                    placeholder="Seleccione un tipo de documento"
                                    className={tipoDocumentoError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{tipoDocumentoError ? mensajeTipoDocumento : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Número de documento<span className="require">*</span></label>
                                <InputText id="numeroDocumento" type="text" name="numeroDocumento" onChange={onNumeroDocumentoChange} keyfilter={validarPasaporte ? "alphanum" : "pint"}
                                    placeholder={placeholder}
                                    className={numeroDocumentoError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{numeroDocumentoError ? mensajeNumeroDocumento : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Nombre / Razón social<span className="require">*</span></label>
                                <InputText id="nombre" type="text" name="nombre" keyfilter={/^[^<>*!"=°|(#$%)+._/?¡¿'{}]+$/} onChange={onNombreChange}
                                    placeholder="Ingrese el nombre / razón social"
                                    className={nombreError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{nombreError ? mensajeNombre : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Dominio<span className="require">*</span></label>
                                <InputText id="dominio" type="text" name="dominio" keyfilter={/^[^<>*!"=°@|(#$%),;:+_/?¡¿'{}]+$/} onChange={onDominioChange}
                                    placeholder="Ingrese el dominio del correo del cliente, ejemplo: dominio.com"
                                    className={dominioError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{dominioError ? mensajeDominio : ""}</p>
                            </div>
                        </form>

                    </div>
                </Dialog>
            </div>
        </div>
    )


}
export default BtnCrearCliente