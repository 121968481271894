import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import Services from '../../service/Services'
import ConfirmBtn from './ConfirmBtn.js'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { Validarfechas, SumarDias, removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';
import axios from 'axios';
const cookies = new Cookies();

const BtnActualizarProyecto = ({ idProyectoProp, proyectoProp, idClienteProp, clienteProp, proyectosProp, creadoporProp, usuariosProp, fechacreacionProp, fechaInicioProp, fechaFinProp, nombreTipoProyectosProp, idTipoProyectosProp }) => {

    const toast = useRef(null)
    const [servicioCliente, setServicioCliente] = useState([]);
    const [servicesTypesProjects, setServicesTypeProjects] = useState([]);


    const getServices = new Services();

    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        let source;
        if (displayBasic === true) {
            source = axios.CancelToken.source();
            getServices.getClientes().then(data => {
                if (data !== undefined) {
                    setServicioCliente(data)
                }
            });
            getServices.getTypesProjects().then(data => {
                if (data !== undefined) {
                    setServicesTypeProjects(data)
                }
            })
        }

        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [inputId, setInputId] = useState({
        id: idProyectoProp
    })


    //Obtener datos del input proyecto

    const [inputProyecto, setInputProyecto] = useState({
        proyecto: proyectoProp
    })
    const [validarCambio, setValidarCambio] = useState(false)
    const onProyectoChange = (event) => {

        setInputProyecto({
            ...inputProyecto,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del select tipo de clientes

    const [selectedCliente, setSelectedCliente] = useState({
        cliente: clienteProp,
        id: idClienteProp
    });

    const clientes = servicioCliente.map(item => {
        const av = { cliente: item.razonsocial, id: item.id }


        return av


    })

    const onClienteChange = (e) => {
        setSelectedCliente(e.value);
        setValidarCambio(true)
    }

    //Tipo de proyectos

    const [selectedTypeProject, setSelectedTypeProject] = useState({
        name: nombreTipoProyectosProp,
        id: idTipoProyectosProp
    });
    const typeProjects = servicesTypesProjects.map(item => {
        const av = { name: item.nombretipoproyecto, id: item.id }
        return av

    })

    const [validateFilter, setValidateFilter] = useState(false);

    const onTypeProjectCompleteChange = (e) => {
        setSelectedTypeProject(e.value);
        setValidarCambio(true)
        setValidateFilter(false)
        setNameTypeProject('')
    }

    const filterTypeProject = () => {
        if (selectedTypeProject !== null) {
            setValidateFilter(false)
        } if (selectedTypeProject === undefined) {
            setValidateFilter(true)
        } else {
            setValidateFilter(true)
        }
        return (
            <p>No se encontraron resultados</p>
        )
    }

    //Campo para registrar un tipo de proyecto

    const [nameTypeProject, setNameTypeProject] = useState('')
    const [errorField, setErrorField] = useState(false)
    const [messageErrorField, setMessageErrorField] = useState('')

    const onNameTypeProject = (e) => {
        setNameTypeProject(e.target.value)
        setValidarCambio(true)

        if (e.target.value.length > 0 && e.target.value.length <= 4) {
            setErrorField(true)
            setMessageErrorField('El nombre es muy corto')
        }
        else if (e.target.value.length > 100) {
            setErrorField(true)
            setMessageErrorField('El nombre es muy largo')
        }
        else {
            setErrorField(false)
            setMessageErrorField('')
        }
    }

    /// calendar de fecha  inicio

    const [dateStartError, setDateStartError] = useState(false)
    const [messageDateStart, setMessageDateStart] = useState('')

    const [dateStart, setDateStart] = useState(fechaInicioProp);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidarCambio(true)
        if (dateEnd !== null) {
            var validateDate = Validarfechas(fecha, dateEnd)
            setDateStartError(validateDate.setFechaError)
            setMessageDateStart(validateDate.setMensajeFecha)
        }
    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(fechaFinProp);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidarCambio(true)
        if (dateStart !== null) {
            var validateDate = Validarfechas(dateStart, fecha)
            setDateStartError(validateDate.setFechaError)
            setMessageDateStart(validateDate.setMensajeFecha)
        }
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;

        setInputId({
            id: idProyectoProp
        })
        setInputProyecto({
            proyecto: proyectoProp
        })
        setSelectedCliente({
            cliente: clienteProp,
            id: idClienteProp
        })
        setSelectedTypeProject({
            name: nombreTipoProyectosProp,
            id: idTipoProyectosProp
        })
        var dateStartChange = new Date(fechaInicioProp)
        var dateEndChange = new Date(fechaFinProp)
        if (gmtHours < 0) {
            SumarDias(dateStartChange = new Date(fechaInicioProp), 1)
            SumarDias(dateEndChange = new Date(fechaFinProp), 1)
        } else {
            dateStartChange = new Date(fechaInicioProp)
            dateEndChange = new Date(fechaFinProp)
        }
        setDateStart(dateStartChange)
        setDateEnd(dateEndChange)

    }, [idProyectoProp, proyectoProp, clienteProp, idClienteProp, fechaInicioProp, fechaFinProp, nombreTipoProyectosProp, idTipoProyectosProp])


    //Almacenamos los valores para enviarlos por props

    const idProyecto = inputId.id
    const proyecto = inputProyecto.proyecto
    const cliente = selectedCliente.cliente
    const idCliente = selectedCliente.id
    const usuario = cookies.get('usuario')
    const idTipoProyecto = selectedTypeProject?.id
    const nameTipoProyecto = selectedTypeProject?.name

    // Validar que el proyecto no se repita en el mismo clientes 

    const result = proyectosProp.filter(function (objeto) {
        var idClienteProyecto = objeto.cliente.id
        return idClienteProyecto === idCliente
    })


    var arrayError = []
    if (result.length > 0) {
        result.forEach(element => {
            var nombreproyecto = element.nombreproyecto
            if (proyecto !== '') {
                if (proyecto === nombreproyecto) {
                    arrayError.push(element)
                }
            }
        });
    }



    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setInputProyecto({
                proyecto: proyectoProp
            })
            setSelectedCliente({
                cliente: clienteProp,
                id: idClienteProp
            })
            setSelectedTypeProject({
                name: nombreTipoProyectosProp,
                id: idTipoProyectosProp
            })

            setValidarCambio(false)

            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idClienteProp={idCliente}
                        idProyectoProp={idProyecto}
                        usuarioProp={usuario}
                        proyectoProp={proyecto}
                        creadoporProp={creadoporProp}
                        usuariosProp={usuariosProp}
                        fechacreacionProp={fechacreacionProp}
                        dateStartProp={dateStart}
                        dateEndProp={dateEnd}
                        idTipoProyectoProp={idTipoProyecto}
                        nombreTipoProyectoProp={nameTipoProyecto}
                        oldDateEndProp={fechaFinProp}
                    />
                    : ""
                }

            </div>
        );
    }
    //validaciones
    var proyectoError = false
    var mensajeProyecto = ''
    if (proyecto === '') {
        proyectoError = true
        mensajeProyecto = 'Debes ingresar el nombre del proyecto '
    } else if (proyecto.length < 3) {
        proyectoError = true
        mensajeProyecto = 'El nombre del proyecto debe ser mayor a 3 dígitos'
    } else if (proyecto.length > 100) {
        proyectoError = true
        mensajeProyecto = 'El nombre del proyecto debe ser menor a 100 dígitos'
    }
    var clienteError = false
    var mensajeCliente = ''
    if (cliente === '') {
        clienteError = true
        mensajeCliente = 'Debes Asociar un cliente '
    }

    const [validateErrorDuplicity, setValidateErrorDuplicity] = useState(false)
    useEffect(() => {
        if (displayBasic === true) {

            let newArr = []
            if (inputProyecto.proyecto !== proyectoProp) {

                proyectosProp.forEach(element => {
                    const nameProject = removeAccents(element.nombreproyecto)
                    const newNameProject = removeAccents(inputProyecto.proyecto)
                    if (nameProject.toLowerCase() === newNameProject.toLowerCase() && parseInt(element.cliente.id) === parseInt(idCliente)) {
                        newArr.push(newNameProject)

                    }
                })
            }

            if (newArr.length !== 0) {
                setValidateErrorDuplicity(true)
                toast.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Proyecto pre-existente' });
            } else {
                setValidateErrorDuplicity(false)
            }
        }

    }, [displayBasic, inputProyecto, proyectoProp, proyectosProp, selectedCliente])// eslint-disable-line react-hooks/exhaustive-deps

    //Validar que no hay ningun error
    var permisoActualizar = false

    if (proyectoError === false && dateStartError === false && selectedTypeProject !== undefined
        && validateErrorDuplicity === false) {
        permisoActualizar = true
    }

    return (

        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar Proyecto" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="cliente"
                                placeholder="Seleccione un cliente" filter
                                className={clienteError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{clienteError ? mensajeCliente : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Id Proyecto</label>
                            <InputText id="id" type="text" name="id" disabled value={idProyecto} />
                        </div>

                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Proyecto<span className="require">*</span></label>
                            <InputText id="proyecto" type="text" name="proyecto" keyfilter={/^[^<>*!"=°|(#$%)+._/?¡¿'{}]+$/} onChange={onProyectoChange} value={proyecto}
                                placeholder="Registre el nombre del proyecto"
                                className={proyectoError || validateErrorDuplicity ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{proyectoError ? mensajeProyecto : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Tipo Proyecto<span className="require">*</span></label>
                            <Dropdown filter value={selectedTypeProject} options={typeProjects} optionLabel="name" onChange={onTypeProjectCompleteChange}
                                dropdown className='auto' placeholder='Seleccione un tipo de proyecto' showClear
                                emptyFilterMessage={filterTypeProject} emptyMessage="No se encontraron resultados" resetFilterOnHide />
                        </div>
                        {
                            validateFilter ?
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" >Registre un tipo de proyecto</label>
                                    <InputText value={nameTypeProject} className={errorField ? 'p-invalid' : ''} onChange={onNameTypeProject} placeholder='Registre un tipo de proyecto' />
                                    <p className="mensajeError">{errorField ? messageErrorField : ""}</p>
                                </div>
                                :
                                ''
                        }
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha inicio vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha fin vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" />
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarProyecto