import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import { RadioButton } from 'primereact/radiobutton';
/* import { Divider } from 'primereact/divider'; */
import { Panel } from 'primereact/panel';
import { UseMedia } from '../../../Funciones';
import { GenerarNuevoColor } from '../../../Funciones'

const Chart3 = ({ dashBoardDataProp }) => {

    /* console.log(dashBoardDataProp.graficaTres) */

    var newDatasets = dashBoardDataProp.graficaTres.datasets.map(item => {
        return {
            label: item.label,
            backgroundColor: GenerarNuevoColor(),
            data: item.data
        }
    })

    var newData = {
        labels: dashBoardDataProp.graficaTres.labels,
        datasets: newDatasets
    }

    const basicData = newData;

    var newChartLabels = []
    var newChartDataComplete = []
    var newBackgroundColor = []

    var newArray = [dashBoardDataProp.graficaTres]

    newArray.forEach(element => {
        basicData.datasets.forEach((element, index) => {
            newChartLabels.push(element.label)
            newBackgroundColor.push(GenerarNuevoColor())
        });
        element.labels.forEach((item, index) => {
            var array = []
            basicData.datasets.forEach(element => {
                array.push(element.data[index])
            });
            newChartDataComplete.push({
                labels: newChartLabels,
                datasets: [
                    {
                        data: array,
                        backgroundColor: newBackgroundColor,
                        label: item
                    }
                ]
            })
        })
    });

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }

    const { basicOptions } = getLightTheme();

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        responsive: true
    };

    const [selectChart, setSelectChart] = useState('ChartBar')



    let small = UseMedia("(max-width: 1500px)");
    /* let small2 = useMedia("(max-width: 1060px)"); */

    var List = dashBoardDataProp.graficaTres.labels.map((element, index) => {
        return <span key={index} style={{ marginTop: '5px', width: '25%', textAlign: 'center' }}>{element}</span>
    })

    var ListChart = newChartDataComplete.map((element, index) => {
        return <div className={small ? "p-col" : "p-col-4"}>
            <Panel header={List[index]}>
                <Chart type="pie" data={element} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
            </Panel>
        </div>
    })

    /* var ListChartResponsive = newChartDataComplete.map((element, index) => {
        return <>
            <div className="card p-d-flex p-jc-center">
                <Chart type="pie" data={element} options={lightOptions} style={{ position: 'relative', width: small2 ? "50%" : "40%" }} />
            </div>
            <div className="card p-d-flex p-jc-center">
                <span key={index} style={{ marginTop: '5px', width: small2 ? "50%" : "40%", textAlign: 'center' }}>{element.datasets[0].label}</span>
            </div>
            <Divider />
        </>

    }) */

    return (
        <div style={{ padding: '10px' }}>
            <div className="p-field-radiobutton">
                <RadioButton inputId="ChartBar" name="ChartBar" value="ChartBar" onChange={(e) => setSelectChart(e.value)} checked={selectChart === 'ChartBar'} />
                <label htmlFor="ChartBar">Gráfica de Barras</label>
                <RadioButton inputId="ChartPie" name="ChartPie" value="ChartPie" onChange={(e) => setSelectChart(e.value)} checked={selectChart === 'ChartPie'} />
                <label htmlFor="ChartPie">Gráfica de Torta</label>
            </div>
            {
                selectChart === 'ChartBar'
                    ?
                    <div className="p-fluid" style={{ textAlign: 'left' }}>
                        <div className="p-field p-grid">
                            <div className="p-col-12 p-md-12">
                                <Chart type="bar" data={basicData} options={basicOptions} />
                            </div>
                        </div>
                    </div>
                    :
                    selectChart === 'ChartPie'
                        ?
                            <div>
                                <div className="card p-d-flex">
                                    {ListChart}
                                </div>
                            </div>
                        :
                        <div className="p-fluid" style={{ textAlign: 'left' }}>
                            <div className="p-field p-grid">
                                <div className="p-col-12 p-md-12">
                                    <Chart type="bar" data={basicData} options={basicOptions} />
                                </div>
                            </div>
                        </div>

            }


        </div>

    )

}
export default Chart3