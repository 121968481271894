import React from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import '../assets/css/Sidebar.css'
import Cookies from 'universal-cookie';
import CargueDeImagen from '../componets/CargueDeImagen/CargueDeImagen'

const cookies = new Cookies();

const CargarImagen = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }
   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol
    
    if (permisos[31].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Cargar Imagen Corporativa</span>
    )

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}
                />
                <div className="container">
                    <h1 >Cargar Imagen Corporativa</h1>
                </div>
                <div className="tabla">
                    <CargueDeImagen/>
                </div>
            </div>
        </div>
    )
}

export default CargarImagen