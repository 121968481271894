import React from 'react'
import { View, Text, Image } from '@react-pdf/renderer'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import { PrepareDataToChart, PrepareDataToChartCategory } from '../../Funciones';

const DataOfficialTable = ({ dataOfficialProp, idProjectProp, stylesProp }) => {

    var newDataOfficialProp = dataOfficialProp.filter(function (object) {
        var idProject = object.idproyecto
        return parseInt(idProjectProp) === parseInt(idProject)
    })

    var showData = []
    newDataOfficialProp.forEach(element => {
        showData.push({
            typeActivity: 'Total horas actividades',
            TittleProject: element.proyecto,
            hours: element.totalHoras,
            hoursPercentage: element.totalPorcentaje
        })
        element.detalle.forEach(i => {
            showData.push({
                typeActivity: i.tipoActividad,
                TittleProject: i.tipoActividad,
                hours: i.horas,
                hoursPercentage: i.porcentajeHoras
            })
        })
    });

    var sendImage
    var sendImageCategory

    const ChartToImage = async () => {
        const ChartJsImage = require('chartjs-to-image');
        const myChart = new ChartJsImage();
        const myChartCategory = new ChartJsImage();

        newDataOfficialProp.forEach(element => {
            const { labels, data } = PrepareDataToChart(element.detalle, true);
            const { labelsCategory, dataCategory } = PrepareDataToChartCategory(element.detalle, true);

            const colors = [
                "#C499CA",
                "#C7B8E7",
                "#F5C4AF",
                "#F29600",
                "#900B9F",
                "#FFC76D",
                "#D2D2D2",
                "#F17314",
                "#A2868D",
                "#CD81D5",
                "#DA11EF",
                "#9A9AA4"
            ]

            var newIndex = -1
            const newData = () => ({
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: labels.map((item, i) => colors[newIndex === 11 ? newIndex -= 11 : newIndex += 1]),
                        color: '#000000'
                    },
                ],
            });
            const newDataCategory = () => ({
                labels: labelsCategory,
                datasets: [
                    {
                        data: dataCategory,
                        backgroundColor: labelsCategory.map((item, i) => colors[newIndex === 11 ? newIndex -= 11 : newIndex += 1]),
                        color: '#000000'
                    },
                ],
            });

            myChart.setConfig({
                type: 'outlabeledPie',
                data: newData(),
                options: {
                    plugins: {
                        legend: false,
                        outlabels: {
                            color: "#000000",
                            stretch: 35,
                            font: {
                                resizable: true,
                                minSize: 22,
                                maxSize: 28,
                                top: 50
                            }
                        }
                    }
                }
            });
            myChartCategory.setConfig({
                type: 'outlabeledPie',
                data: newDataCategory(),
                options: {
                    plugins: {
                        legend: false,
                        outlabels: {
                            color: "#000000",
                            stretch: 35,
                            font: {
                                resizable: true,
                                minSize: 22,
                                maxSize: 28,
                                top: 50
                            }
                        }
                    }
                }

            });
            if (showData !== undefined && showData.length !== 0) {
                if (showData.length >= 12) {
                    myChart.setWidth(2800).setHeight(2820)
                    myChartCategory.setWidth(2800).setHeight(2820)
                }else{
                    myChart.setWidth(1400).setHeight(700)
                    myChartCategory.setWidth(1400).setHeight(700)
                }
            }

            sendImage = myChart.getUrl()
            sendImageCategory = myChartCategory.getUrl()
        })
    }

    if (dataOfficialProp.length !== 0) {
        ChartToImage()
    }

    return (
        <View>
            <Text style={stylesProp.subtitle2}>
                Actividades registradas en el proyecto: {showData !== undefined && showData.length !== 0 ? showData[0].TittleProject : ""}
            </Text>
            <Table
                data={showData}
            >
                <TableHeader textAlign={"center"}>
                    <TableCell style={stylesProp.textTable}>
                        Tipo de Actividad
                    </TableCell>
                    <TableCell style={stylesProp.textTable}>
                        Horas
                    </TableCell>
                    <TableCell style={stylesProp.textTable}>
                        Horas %
                    </TableCell>
                </TableHeader>
                <TableBody textAlign={"center"}>
                    <DataTableCell getContent={(r) => r.typeActivity} style={stylesProp.textCell} />
                    <DataTableCell getContent={(r) => r.hours} style={stylesProp.textCell} />
                    <DataTableCell getContent={(r) => r.hoursPercentage + '%'} style={stylesProp.textCell} />
                </TableBody>
            </Table>

            <Text style={stylesProp.text}>{showData !== undefined && showData.length !== 0 ? 'Horas - Tipo de actividad - ' + showData[0].TittleProject : ""}</Text>
            {sendImage !== undefined ?
                <Image
                    style={stylesProp.imageChart}
                    src={sendImage}
                />
                :
                <Text style={stylesProp.subtitle}></Text>
            }
            <Text style={stylesProp.text}>{showData !== undefined && showData.length !== 0 ? 'Horas - Categoría - ' + showData[0].TittleProject : ""}</Text>
            {sendImageCategory !== undefined ?
                <Image
                    style={stylesProp.imageChart}
                    src={sendImageCategory}
                />
                :
                <Text style={stylesProp.subtitle}></Text>
            }
        </View>
    )
}

export default DataOfficialTable