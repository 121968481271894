import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import '../../assets/css/BtnNotas.css'
import { useEffect } from "react";

const BtnNotas = ({ setNoteDescriptionProp, tooltipProp, titleProp, footerProp, disabledProp, notesDescriptionProp, btnCrearActividadProp }) => {

    const [validateChange, setValidateChange] = useState(false)

    const [displayBasic, setDisplayBasic] = useState(false)

    const [setPosition] = useState('center')


    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true)

        if (position) {
            setPosition(position)
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [notesDescription, setNotesDescription] = useState('')

    const onChangeNotes = (e) => {
        setNotesDescription(e.target.value)
        setValidateChange(true)

    }

    useEffect(() => {
        setNotesDescription(notesDescriptionProp)

    }, [notesDescriptionProp]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        if (btnCrearActividadProp === false) {
            setNoteDescriptionProp(notesDescription)
        }
        
    }, [btnCrearActividadProp, notesDescription])// eslint-disable-line react-hooks/exhaustive-deps


    var descriptionError = false
    var messageDescriptionError = ''
    // Validaciones

    function notesDescriptionError() {

        if (notesDescription.length < 15) {
            descriptionError = true
            messageDescriptionError = 'Debe ingresar más de 15 carácteres'
        }
        else if (notesDescription.length > 800) {
            descriptionError = true
            messageDescriptionError = 'Debe ingresar menos de 800 carácteres'
        }
    }

    if (notesDescription !== '' && notesDescription !== undefined && notesDescription !== null) {
        notesDescriptionError()
    }

    var permissionSave = false

    if (descriptionError === false && notesDescription !== '') {
        permissionSave = true
    }

    const ModalRegresar = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            dialogFuncMap[`${name}`](false)
            setDisplayConfirmation(false)
            setDisplayBasic(false)

            if (btnCrearActividadProp === false) {
                setNotesDescription(notesDescriptionProp)
            } else {
                setNotesDescription('')
            }
            setValidateChange(false)
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const ModalGuardarNota = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const saveNote = () => {
            setDisplayConfirmation(false)
            setDisplayBasic(false)
            if (btnCrearActividadProp === true) {
                setNoteDescriptionProp(notesDescription)
            }
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => saveNote()} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Guardar" icon="pi pi-save" onClick={() => onClick('displayConfirmation')} />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>¿Desea agregar la nota?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permissionSave ?
                        <ModalGuardarNota />
                        :
                        ''
                }

            </div>
        )
    }
    return (
        <>

            <Button className="BtnNotas p-button-secondary" icon="pi pi-book" onClick={() => onClick('displayBasic')} tooltip={tooltipProp}
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} style={{ marginTop: '5px' }}
            />

            <Dialog className="dialog-demo" header={titleProp} visible={displayBasic} modal style={{}}
                footer={footerProp === true ? renderFooter('displayBasic') : ''} onHide={() => onHide('displayBasic')} >

                <div className="card">
                    <label className="labels" >{ }</label>
                    <InputTextarea value={notesDescription} onChange={onChangeNotes} className={descriptionError ? "p-invalid" : ''}
                        rows={5} cols={30} autoResize placeholder="Ingrese una nota" disabled={disabledProp} />
                    <p className="mensajeError">{descriptionError ? messageDescriptionError : ''}</p>
                </div>

            </Dialog>
        </>
    )
}

export default BtnNotas