import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/rol/update'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/rol'

const ConfirmBtn = ({ rolProp, usuarioProp, idRolProp, estadoProp, creadoporProp,
    checkedUserReadProp, checkedUserCreateProp, checkedUserUpdateProp, checkedUserDeleteProp,
    checkedActivityReadProp, checkedActivityCreateProp, checkedActivityUpdateProp, checkedActivityDeleteProp,
    checkedExportDataReadProp, checkedExportDataAllProp,
    checkedClientReadProp, checkedClientCreateProp, checkedClientUpdateProp, checkedClientDeleteProp,
    checkedProjectReadProp, checkedProjectCreateProp, checkedProjectUpdateProp, checkedProjectDeleteProp,
    checkedAplicationReadProp, checkedAplicationCreateProp, checkedAplicationUpdateProp, checkedAplicationDeleteProp,
    checkedBusinessAreasReadProp, checkedBusinessAreasCreateProp, checkedBusinessAreasUpdateProp, checkedBusinessAreasDeleteProp,
    checkedActivityTypeReadProp, checkedActivityTypeCreateProp, checkedActivityTypeUpdateProp, checkedActivityTypeDeleteProp,
    checkedPositionReadProp, checkedPositionCreateProp, checkedPositionUpdateProp, checkedPositionDeleteProp,
    checkedRolReadProp, checkedRolCreateProp, checkedRolUpdateProp, checkedRolDeleteProp,
    checkedCalendarReadProp, checkedCalendarManageProp,
    checkedDownloadZipProp,
    checkedRunCloseProp, checkedCloseOpenProp, checkedNotificationProp,
    checkedHistoricalProp,
    checkedDBActivityLoggerProp, checkedDBWorkloadProp, checkedDBDetailTypeActivityProp, checkedDBDetailTypeActivityUserProp,
    checkedDBReadPowerBiProp,
    checkedDBPowerBiClientProp,
    checkedDBPlusPowerBiProp,
    checkedNoveltyAnotherOfficialProp,
    checkedindividualMonthlyReportReadProp, checkedindividualMonthlyReportReadOtherOfficialProp, checkedindividualMonthlyReportReopenProp,
    checkedInterestedPersonReadProp, checkedInterestedPersonCreateProp, checkedInterestedPersonUpdateProp, checkedInterestedPersonDeleteProp,
    checkedObjectiveReadProp, checkedObjectiveCreateProp, checkedObjectiveUpdateProp, checkedObjectiveDeleteProp,
    checkedContractReadProp, checkedContractCreateProp, checkedContractUpdateProp, checkedContractDeleteProp,
    checkedDeliverableReadProp, checkedDeliverableCreateProp, checkedDeliverableUpdateProp, checkedDeliverableDeleteProp,
    checkedServiceReadProp, checkedServiceCreateProp, checkedServiceUpdateProp, checkedServiceDeleteProp,
    checkedWorkTeamReadProp, checkedWorkTeamCreateProp, checkedWorkTeamUpdateProp, checkedWorkTeamDeleteProp,
    checkedTypeProjectReadProp, checkedTypeProjectCreateProp, checkedTypeProjectUpdateProp, checkedTypeProjectDeleteProp,
    checkedOrgReadProp,
    checkedDetailPendingReadProp,
    checkedUserClientReadProp,
    checkedSupplierReadProp, checkedSupplierCreateProp, checkedSupplierUpdateProp, checkedSupplierDeleteProp,
    checkedFilterSupplierNDB1Prop, checkedFilterSupplierNDB2Prop, checkedFilterSupplierExportDataProp,
    checkedDBReadPowerBiFilterSupplierProp, checkedDBPowerBiFilterSupplierClientProp, checkedDBPlusPowerBiFilterSupplierProp,
    titulo, mensaje, accion, icono, clase, label, tooltipProp, fechacreacionProp, checkedBDReadUploadImageProp }) => {

    const toast = useRef(null);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "Crear") {
            crearRol()
            setDisabled(true);
        } else if (accion === "Actualizar") {
            actualizarRol()
            setDisabled(true);
        } else if (accion === "Eliminar") {
            setDisabled(true);
            eliminarRol()
        }
    }

    //// Usuarios
    var createUser = ''
    if (checkedUserCreateProp === true) {
        createUser = 'Crear'
    }
    var updateUser = ''
    if (checkedUserUpdateProp === true) {
        updateUser = 'Editar'
    }
    var deleteUser = ''
    if (checkedUserDeleteProp === true) {
        deleteUser = 'Eliminar'
    }
    ///// Actividades
    var createActivity = ''
    if (checkedActivityCreateProp === true) {
        createActivity = 'Crear'
    }
    var updateActivity = ''
    if (checkedActivityUpdateProp === true) {
        updateActivity = 'Editar'
    }
    var deleteActivity = ''
    if (checkedActivityDeleteProp === true) {
        deleteActivity = 'Eliminar'
    }
    //// Clientes
    var createClient = ''
    if (checkedClientCreateProp === true) {
        createClient = 'Crear'
    }
    var updateClient = ''
    if (checkedClientUpdateProp === true) {
        updateClient = 'Editar'
    }
    var deleteClient = ''
    if (checkedClientDeleteProp === true) {
        deleteClient = 'Eliminar'
    }
    //// Proyectos
    var createProject = ''
    if (checkedProjectCreateProp === true) {
        createProject = 'Crear'
    }
    var updateProject = ''
    if (checkedProjectUpdateProp === true) {
        updateProject = 'Editar'
    }
    var deleteProject = ''
    if (checkedProjectDeleteProp === true) {
        deleteProject = 'Eliminar'
    }
    //// Aplicaciones
    var createAplication = ''
    if (checkedAplicationCreateProp === true) {
        createAplication = 'Crear'
    }
    var updateAplication = ''
    if (checkedAplicationUpdateProp === true) {
        updateAplication = 'Editar'
    }
    var deleteAplication = ''
    if (checkedAplicationDeleteProp === true) {
        deleteAplication = 'Eliminar'
    }
    //// Áreas de negocio
    var createBusinessAreas = ''
    if (checkedBusinessAreasCreateProp === true) {
        createBusinessAreas = 'Crear'
    }
    var updateBusinessAreas = ''
    if (checkedBusinessAreasUpdateProp === true) {
        updateBusinessAreas = 'Editar'
    }
    var deleteBusinessAreas = ''
    if (checkedBusinessAreasDeleteProp === true) {
        deleteBusinessAreas = 'Eliminar'
    }
    //// Tipos de Actividad
    var createActivityType = ''
    if (checkedActivityTypeCreateProp === true) {
        createActivityType = 'Crear'
    }
    var updateActivityType = ''
    if (checkedActivityTypeUpdateProp === true) {
        updateActivityType = 'Editar'
    }
    var deleteActivityType = ''
    if (checkedActivityTypeDeleteProp === true) {
        deleteActivityType = 'Eliminar'
    }
    //// Cargos
    var createPosition = ''
    if (checkedPositionCreateProp === true) {
        createPosition = 'Crear'
    }
    var updatePosition = ''
    if (checkedPositionUpdateProp === true) {
        updatePosition = 'Editar'
    }
    var deletePosition = ''
    if (checkedPositionDeleteProp === true) {
        deletePosition = 'Eliminar'
    }
    //// roles
    var createRol = ''
    if (checkedRolCreateProp === true) {
        createRol = 'Crear'
    }
    var updateRol = ''
    if (checkedRolUpdateProp === true) {
        updateRol = 'Editar'
    }
    var deleteRol = ''
    if (checkedRolDeleteProp === true) {
        deleteRol = 'Eliminar'
    }
    //// Calendario
    var manageCalendar = ''
    if (checkedCalendarManageProp === true) {
        manageCalendar = 'Parametrizar'
    }
    //// Procesos de cierre
    var ConsultarCierre
    if (checkedRunCloseProp === true || checkedCloseOpenProp === true) {
        ConsultarCierre = true
    } else {
        ConsultarCierre = false
    }
    var AbrirMes = ''
    if (checkedCloseOpenProp === true) {
        AbrirMes = 'AbrirMes'
    }
    var cerrarMes = ''
    if (checkedRunCloseProp === true) {
        cerrarMes = 'CierreDefinitivo'
    }
    //// Dashboard
    var DB1
    if (checkedDBWorkloadProp === true) {
        DB1 = 'DB1'
    } else {
        DB1 = ''
    }
    var DB2
    if (checkedDBDetailTypeActivityProp === true) {
        DB2 = 'DB2'
    } else {
        DB2 = ''
    }
    var DB3
    if (checkedDBDetailTypeActivityUserProp === true) {
        DB3 = 'DB3'
    } else {
        DB3 = ''
    }
    /// novedades
    var NoveltyAnotherOfficial
    if (checkedNoveltyAnotherOfficialProp === true) {
        NoveltyAnotherOfficial = 'NovedadOtroFucionario'
    } else {
        NoveltyAnotherOfficial = ''
    }
    /// informe mensual
    var individualMonthlyReportReadOtherOfficial
    if (checkedindividualMonthlyReportReadOtherOfficialProp === true) {
        individualMonthlyReportReadOtherOfficial = 'true'
    } else {
        individualMonthlyReportReadOtherOfficial = ''
    }
    var individualMonthlyReportReopen
    if (checkedindividualMonthlyReportReopenProp === true) {
        individualMonthlyReportReopen = 'true'
    } else {
        individualMonthlyReportReopen = ''
    }
    //// personas interesadas
    var createInterestedPerson = ''
    if (checkedInterestedPersonCreateProp === true) {
        createInterestedPerson = 'Crear'
    }
    var updateInterestedPerson = ''
    if (checkedInterestedPersonUpdateProp === true) {
        updateInterestedPerson = 'Editar'
    }
    var deleteInterestedPerson = ''
    if (checkedInterestedPersonDeleteProp === true) {
        deleteInterestedPerson = 'Eliminar'
    }
    //// Objetivos
    var createObjective = ''
    if (checkedObjectiveCreateProp === true) {
        createObjective = 'Crear'
    }
    var updateObjective = ''
    if (checkedObjectiveUpdateProp === true) {
        updateObjective = 'Editar'
    }
    var deleteObjective = ''
    if (checkedObjectiveDeleteProp === true) {
        deleteObjective = 'Eliminar'
    }
    //// Contratos
    var createContract = ''
    if (checkedContractCreateProp === true) {
        createContract = 'Crear'
    }
    var updateContract = ''
    if (checkedContractUpdateProp === true) {
        updateContract = 'Editar'
    }
    var deleteContract = ''
    if (checkedContractDeleteProp === true) {
        deleteContract = 'Eliminar'
    }
    //// Entregables
    var createDeliverable = ''
    if (checkedDeliverableCreateProp === true) {
        createDeliverable = 'Crear'
    }
    var updateDeliverable = ''
    if (checkedDeliverableUpdateProp === true) {
        updateDeliverable = 'Editar'
    }
    var deleteDeliverable = ''
    if (checkedDeliverableDeleteProp === true) {
        deleteDeliverable = 'Eliminar'
    }
    //// Servicios
    var createService = ''
    if (checkedServiceCreateProp === true) {
        createService = 'Crear'
    }
    var updateService = ''
    if (checkedServiceUpdateProp === true) {
        updateService = 'Editar'
    }
    var deleteService = ''
    if (checkedServiceDeleteProp === true) {
        deleteService = 'Eliminar'
    }
    ////Plus powerBi
    var plusPowerBi = ''
    if (checkedDBPlusPowerBiProp === true) {
        plusPowerBi = 'plusPowerBi'
    }
    var powerBiClient = ''
    if (checkedDBPowerBiClientProp === true) {
        powerBiClient = 'powerBiClient'
    }

    //// Equipo de trabajo
    var createTeamWork = ''
    if (checkedWorkTeamCreateProp === true) {
        createTeamWork = 'Crear'
    }
    var updateTeamWork = ''
    if (checkedWorkTeamUpdateProp === true) {
        updateTeamWork = 'Editar'
    }
    var deleteTeamWork = ''
    if (checkedWorkTeamDeleteProp === true) {
        deleteTeamWork = 'Eliminar'
    }

    ////Tipos de Proyectos
    var createTypeProject = ''
    if (checkedTypeProjectCreateProp === true) {
        createTypeProject = 'Crear'
    }
    var updateTypeProject = ''
    if (checkedTypeProjectUpdateProp === true) {
        updateTypeProject = 'Editar'
    }
    var deleteTypeProject = ''
    if (checkedTypeProjectDeleteProp === true) {
        deleteTypeProject = 'Eliminar'
    }

    ////Proveedores
    var createSupplier = ''
    if (checkedSupplierCreateProp === true) {
        createSupplier = 'Crear'
    }
    var updateSupplier = ''
    if (checkedSupplierUpdateProp === true) {
        updateSupplier = 'Editar'
    }
    var deleteSupplier = ''
    if (checkedSupplierDeleteProp === true) {
        deleteSupplier = 'Eliminar'
    }
    ////Filtros Proveedores
    var FilterSupplierNDB1 = ''
    if (checkedFilterSupplierNDB1Prop === true) {
        FilterSupplierNDB1 = 'NDB1'
    }
    var FilterSupplierNDB2 = ''
    if (checkedFilterSupplierNDB2Prop === true) {
        FilterSupplierNDB2 = 'NDB2'
    }

    ////PowerBi - Supplier
    var plusPowerBiFilterSupplier = ''
    if (checkedDBPlusPowerBiFilterSupplierProp === true) {
        plusPowerBiFilterSupplier = 'plusPowerBiSupplier'
    }
    var powerBiFilterSupplierClient = ''
    if (checkedDBPowerBiFilterSupplierClientProp === true) {
        powerBiFilterSupplierClient = 'powerBiSupplierClient'
    }

    var JsonBody = '{"rol":[' +
        '{'
        + '"consultar": "' + checkedUserReadProp + '",'

        + '"menu": "Usuarios",'

        + '"ruta": "/registroActividades/consultarUsuarios",'

        + '"permisos": ["' + createUser + '","' + updateUser + '","' + deleteUser + '"]'

        + '},' +
        '{'
        + '"consultar": "' + checkedActivityReadProp + '",'

        + '"menu": "Actividades",'

        + '"ruta": "/registroActividades/consultarActividades/0",'

        + '"permisos": ["' + createActivity + '","' + updateActivity + '","' + deleteActivity + '","' + NoveltyAnotherOfficial + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedExportDataReadProp + '",'

        + '"menu": "Exportar Datos",'

        + '"ruta": "/registroActividades/exportarDatos",'

        + '"exportarTodos": "' + checkedExportDataAllProp + '"'

        + '},' +
        '{'

        + '"consultar": "' + checkedClientReadProp + '",'

        + '"menu": "Clientes",'

        + '"ruta": "/registroActividades/clientes",'

        + '"permisos": ["' + createClient + '","' + updateClient + '","' + deleteClient + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedProjectReadProp + '",'

        + '"menu": "Proyectos",'

        + '"ruta": "/registroActividades/proyectos",'

        + '"permisos": ["' + createProject + '","' + updateProject + '","' + deleteProject + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedAplicationReadProp + '",'

        + '"menu": "Aplicaciones",'

        + '"ruta": "/registroActividades/aplicaciones",'

        + '"permisos": ["' + createAplication + '","' + updateAplication + '","' + deleteAplication + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedBusinessAreasReadProp + '",'

        + '"menu": "Áreas de Negocio",'

        + '"ruta": "/registroActividades/areasNegocio",'

        + '"permisos": ["' + createBusinessAreas + '","' + updateBusinessAreas + '","' + deleteBusinessAreas + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedActivityTypeReadProp + '",'

        + '"menu": "Tipos de Actividad",'

        + '"ruta": "/registroActividades/tiposActividades",'

        + '"permisos": ["' + createActivityType + '","' + updateActivityType + '","' + deleteActivityType + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedPositionReadProp + '",'

        + '"menu": "Cargos",'

        + '"ruta": "/registroActividades/cargos",'

        + '"permisos": ["' + createPosition + '","' + updatePosition + '","' + deletePosition + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedRolReadProp + '",'

        + '"menu": "Roles",'

        + '"ruta": "/registroActividades/roles",'

        + '"permisos": ["' + createRol + '","' + updateRol + '","' + deleteRol + '"]'

        + '},' +
        '{'

        + '"consultarCalendario": "' + checkedCalendarReadProp + '",'

        + '"menu": "Calendario",'

        + '"permisos": ["' + manageCalendar + '","false","false"]'

        + '},' +
        '{'

        + '"DescargarZip": "' + checkedDownloadZipProp + '",'

        + '"menu": "Descargar Zip",'

        + '"ruta": ""'

        + '},' +
        '{'

        + '"ProcesosCierre": "' + ConsultarCierre + '",'

        + '"menu": "Procesos Cierre",'

        + '"permisos": ["' + AbrirMes + '","' + cerrarMes + '","false"]'

        + '},' +
        '{'

        + '"DashBoards": "' + checkedDBActivityLoggerProp + '",'

        + '"menu": "Dashboards",'

        + '"permisos": ["' + DB1 + '","' + DB2 + '","' + DB3 + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedHistoricalProp + '",'

        + '"menu": "Historico",'

        + '"ruta": "/registroActividades/historico",'

        + '"permisos": ["","",""]'

        + '},' +
        '{'

        + '"consultar": "' + checkedindividualMonthlyReportReadProp + '",'

        + '"menu": "Informe mensual individual",'

        + '"ruta": "/registroActividades/informeMensualIndividual",'

        + '"permisos": ["' + individualMonthlyReportReadOtherOfficial + '","' + individualMonthlyReportReopen + '",""]'

        + '},' +
        '{'

        + '"consultar": "' + checkedInterestedPersonReadProp + '",'

        + '"menu": "Personas interesadas",'

        + '"ruta": "/registroActividades/personasInteresadas",'

        + '"permisos": ["' + createInterestedPerson + '","' + updateInterestedPerson + '","' + deleteInterestedPerson + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedObjectiveReadProp + '",'

        + '"menu": "Objetivos",'

        + '"ruta": "/registroActividades/objetivos",'

        + '"permisos": ["' + createObjective + '","' + updateObjective + '","' + deleteObjective + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedContractReadProp + '",'

        + '"menu": "Gestionar Contratos",'

        + '"ruta": "/registroActividades/contratos",'

        + '"permisos": ["' + createContract + '","' + updateContract + '","' + deleteContract + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedDeliverableReadProp + '",'

        + '"menu": "Entregables",'

        + '"ruta": "/registroActividades/entregables",'

        + '"permisos": ["' + createDeliverable + '","' + updateDeliverable + '","' + deleteDeliverable + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedServiceReadProp + '",'

        + '"menu": "Servicios",'

        + '"ruta": "/registroActividades/servicios",'

        + '"permisos": ["' + createService + '","' + updateService + '","' + deleteService + '"]'

        + '},' +
        '{'

        + '"consultar": "' + checkedWorkTeamReadProp + '",'

        + '"menu": "Gestión de Equipos de Trabajo",'

        + '"ruta": "/registroActividades/equipoTrabajo",'

        + '"permisos": ["' + createTeamWork + '","' + updateTeamWork + '","' + deleteTeamWork + '"]'

        + '},' +
        '{'

        + '"DBPowerBI": "' + checkedDBReadPowerBiProp + '",'

        + '"menu": "Dashboard PowerBI",'

        + '"permisos": ["' + plusPowerBi + '","' + powerBiClient + '",""]'

        + '},' +
        '{'
        + '"consultar": "' + checkedTypeProjectReadProp + '",'

        + '"menu": "Tipos de Proyectos",'

        + '"ruta": "/registroActividades/tiposProyectos",'

        + '"permisos": ["' + createTypeProject + '","' + updateTypeProject + '","' + deleteTypeProject + '"]'

        + '},' +
        '{'
        + '"consultar": "' + checkedOrgReadProp + '",'

        + '"menu": "Organigrama",'

        + '"ruta": "/registroActividades/Organigrama",'

        + '"permisos": ["","",""]'

        + '},' +
        '{'
        + '"consultar": "' + checkedDetailPendingReadProp + '",'

        + '"menu": "Detalle de Pendientes",'

        + '"ruta": "/registroActividades/detallePendientes",'

        + '"permisos": ["","",""]'
        + '},' +
        '{'
        + '"consultar": "' + checkedUserClientReadProp + '",'

        + '"menu": "Usuarios de clientes",'

        + '"ruta": "/registroActividades/usuariosClientes",'

        + '"permisos": ["","",""]'
        + '},' +
        '{'
        + '"consultar": "' + checkedSupplierReadProp + '",'

        + '"menu": "Proveedores",'

        + '"ruta": "/registroActividades/proveedores",'

        + '"permisos": ["' + createSupplier + '","' + updateSupplier + '","' + deleteSupplier + '"]'
        + '},' +
        '{'
        + '"NuevoDashboards": "' + checkedFilterSupplierNDB1Prop + '",'

        + '"menu": "Dashboards - Prov",'

        + '"permisos": ["","' + FilterSupplierNDB1 + '","' + FilterSupplierNDB2 + '"]'
        + '},' +
        '{'
        + '"consultar": "' + checkedFilterSupplierExportDataProp + '",'

        + '"menu": "Exportar datos proveedor",'

        + '"ruta": "/registroActividades/exportarDatosProveedor"'

        + '},' +
        '{'
        + '"DBPowerBISupplier": "' + checkedDBReadPowerBiFilterSupplierProp + '",'

        + '"menu": "Dashboard PowerBI - Proveedor",'

        + '"permisos": ["' + plusPowerBiFilterSupplier + '","' + powerBiFilterSupplierClient + '",""]'
        + '},' +
        '{'
        + '"consultar": "' + checkedBDReadUploadImageProp + '",'

        + '"menu": "Cargar Imagen Corporativa",'

        + '"ruta": "/registroActividades/cargarImagenCorporativa",'

        + '"permisos": ["","",""]'
        + '}' +
        ']' +
        '}'

    const crearRol = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuarioProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                id: "",
                idempresa: {
                    id: 1
                },
                nombre: rolProp,
                notificaciones: checkedNotificationProp,
                permisos: JsonBody
            }
        )
        /* console.log(json) */
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/roles"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Rol existente en el sitema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    var enviarEstado = 1
    if (estadoProp === "Inactivo") {
        enviarEstado = 2
    }
    /* console.log(checkedNotificationProp) */
    const actualizarRol = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuarioProp,
                creadopor: creadoporProp,
                estado: enviarEstado,
                fechaactualizado: new Date(),
                fechacreacion: fechacreacionProp,
                id: idRolProp,
                idempresa: {
                    id: 1
                },
                nombre: rolProp,
                notificaciones: checkedNotificationProp,
                permisos: JsonBody
            }
        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/roles"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Rol existente en el sitema', life: 4000 });
                }
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })



    }
    const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/rol/delete?id=' + idRolProp
    const eliminarRol = async () => {
        await axios.delete(urlEliminar,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/roles"
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled}/>
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{mensaje}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn