import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { EstandarFormatoFecha, SumarDias, UseMedia } from '../Funciones';
import BtnUpdateDetail from './BtnUpdateDetail';
import BtnAddService from './BtnAddService';
import ConfirmBtn from './ConfirmBtn';


const ContratosServicios = ({ servicesProp, idProp, clientProp, contractProp, createdByProp, statusProp, creationDateProp, dateEndProp, dateStartProp, dateContractProp,
    objectContractProp, setRunRenderProp, runRenderProp, permissionsContractCreateProp, permissionsContractUpdateProp, permissionsContractDeleteProp }) => {

    const [detailServices, setDetailServices] = useState([])

    useEffect(() => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newServiceProp = servicesProp.map(item => {
            var newDateStart
            var newDateEnd
            if (gmtHours < 0) {
                newDateStart = SumarDias(new Date(item.fechainiciovigencia), 1)
                if (item.fechafinvigencia === null) {
                    newDateEnd = null
                } else {
                    newDateEnd = SumarDias(new Date(item.fechafinvigencia), 1)
                }
            } else {
                newDateStart = new Date(item.fechainiciovigencia)
                if (item.fechafinvigencia === null) {
                    newDateEnd = null
                } else {
                    newDateEnd = new Date(item.fechafinvigencia)
                }
            }
            return {
                estado: item.estado,
                fechacreacion: item.fechacreacion,
                fechafinvigencia: item.fechafinvigencia === null ? null : EstandarFormatoFecha(newDateEnd),
                fechainiciovigencia: EstandarFormatoFecha(newDateStart),
                sendDateStart: item.fechainiciovigencia,
                sendDateEnd: item.fechafinvigencia,
                id: item.id,
                servicio: item.servicio
            }
        })
        setDetailServices(newServiceProp)

    }, [servicesProp])

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const acctions = (rowData) => {
        /* console.log(rowData.contrato) */
        return (
            <div style={{ display: '-webkit-box' }}>
                {permissionsContractUpdateProp ?
                    <BtnUpdateDetail
                        idContractServiceProp={rowData.id}
                        serviceProp={rowData.servicio}
                        servicesProp={servicesProp}
                        dateStartServiceProp={rowData.sendDateStart}
                        dateEndServiceProp={rowData.sendDateEnd}
                        statusProp={statusProp}
                        serviceCreationDateProp={rowData.fechacreacion}
                        idProp={idProp}
                        clientProp={clientProp}
                        contractProp={contractProp}
                        createdByProp={createdByProp}
                        creationDateProp={creationDateProp}
                        dateEndProp={dateEndProp}
                        dateStartProp={dateStartProp}
                        dateContractProp={dateContractProp}
                        objectContractProp={objectContractProp}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                    />
                    :
                    ""
                }
                {permissionsContractDeleteProp ?
                    <ConfirmBtn
                        tittle="Confirmar Eliminación"
                        menssage="¿Está seguro de eliminar este servicio?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        action="Update"
                        label=""
                        classProp="p-button-danger"
                        icon="pi pi-trash"
                        tooltipProp="Eliminar"
                        idClientProp={clientProp.id}
                        contractProp={contractProp}
                        createdByProp={createdByProp}
                        statusProp={statusProp}
                        creationDateProp={creationDateProp}
                        dateStartProp={dateStartProp}
                        dateEndProp={dateEndProp}
                        dateContractProp={dateContractProp}
                        idProp={idProp}
                        objectContractProp={objectContractProp}
                        idContractServiceProp={rowData.id}
                        statusContractService={3}
                        serviceCreationDateProp={rowData.fechacreacion}
                        dateStartServiceProp={rowData.sendDateStart}
                        dateEndServiceProp={rowData.sendDateEnd}
                        idServiceProp={rowData.servicio.id}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                        updateDetailProp={true}
                        deleteDetailProp={true}
                    />
                    :
                    ""
                }
            </div>
        )
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permissionsContractCreateProp ?
                <BtnAddService
                    servicesProp={servicesProp}
                    statusProp={statusProp}
                    idProp={idProp}
                    clientProp={clientProp}
                    contractProp={contractProp}
                    createdByProp={createdByProp}
                    creationDateProp={creationDateProp}
                    dateEndProp={dateEndProp}
                    dateStartProp={dateStartProp}
                    dateContractProp={dateContractProp}
                    objectContractProp={objectContractProp}
                    addServiceContract={true}
                    setRunRenderProp={setRunRenderProp}
                    runRenderProp={runRenderProp}
                />
                :
                ""
            }
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                    onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                />
            </span>

        </div>
    );

    const dt = useRef(null);

    let small = UseMedia("(max-width: 760px)");

    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Contratos servicios asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="">
                    <div className="card">
                        <DataTable ref={dt} value={detailServices} paginator rows={5} responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                            emptyMessage="No hay servicios registrados para este contrato" sortable header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="servicio.descripcion" sortable header="Servicio" />
                            <Column className="columna" field="fechainiciovigencia" sortable header="Fecha inicio vigencia" />
                            <Column className="columna" field="fechafinvigencia" sortable header="Fecha fin vigencia" />
                            <Column header="Acciones" body={acctions} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default ContratosServicios