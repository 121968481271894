import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Slider } from "primereact/slider";
import { addLocale } from "primereact/api";
import { CambiarFormatoFecha, SumarDias } from "../Funciones";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import Cookies from 'universal-cookie'
import axios from "axios";
const cookies = new Cookies()


const BtnUpdateDatail = ({ idObjectiveDeliverableProp, idDeliverableProp, deliverableProp, idObjectiveProp, objectiveProp, projectProp, clientProp, percentageProp, dateProp, creationDateProp, createdByProp,
    individualMonthReportProp, setRunRenderProp, runRenderProp, reloadObjectiveProp, runRenderBlockProp, setRunRenderBlockProp, listObjectivesProp }) => {

    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [clientsUserSession, setClientsUserSession] = useState([]);
    const [objective, setObjective] = useState([]);

    const getService = new Services()
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {

            if (listObjectivesProp) {
                setObjective(listObjectivesProp)
            } else {
                getService.getObjectives().then(data => {
                    if (data !== undefined) {
                        setObjective(data)
                    }
                })
            }

            getService.getProyectosUsuarios().then(data => {
                if (data !== undefined) {

                    /////Proceso pra evitar que los clientes se repitan 
                    const listClients = []
                    data.forEach(element => {
                        var selectClients = {}
                        selectClients.id = parseInt(element.cliente.id)
                        selectClients.name = element.cliente.nombre
                        listClients.push(selectClients)
                    });
                    listClients.push({
                        id: '',
                        name: ''
                    })
                    const clientsTable = {}
                    const uniqueClients = listClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })
                    setClientsUserSession(uniqueClients)

                    const listProjects = []
                    data.forEach(element => {
                        listProjects.push(element)
                    })
                    const projectsTable = {}
                    const uniqueProjects = listProjects.filter(function (object) {
                        return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                    })
                    setProjectsUserSession(uniqueProjects)
                }
            });
        }

        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        name: '',
        id: ''
    });

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: item.id }
        return av

    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
        setSelectedProject({
            id: '',
            name: '',
            dateStartValidity: '',
            dateEndValidity: ''
        })
    }

    //Obtener datos del select tipo de proyecto

    const [selectedProject, setSelectedProject] = useState({
        id: '',
        name: '',
        dateStartValidity: '',
        dateEndValidity: ''
    })

    var projectClient = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const loadProjects = () => {

        if (selectedClient !== undefined) {
            if (selectedClient.length !== 0) {

                const result = projectsUserSession.filter(function (object) {
                    var idClient = parseInt(object.cliente.id)
                    return idClient === parseInt(selectedClient.id)
                })

                result.forEach(element => {
                    var selectedProject = {}
                    selectedProject.id = parseInt(element.id)
                    selectedProject.name = element.nombre
                    selectedProject.fechainiciovigencia = element.fechainiciovigencia
                    selectedProject.fechafinvigencia = element.fechafinvigencia
                    projectClient.push(selectedProject)
                });
                projectClient.sort(function (a, b) {
                    var textA = a.name;
                    var textB = b.name;
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            }
        }
        const filterDate = projectClient.filter(element => {
            return element.fechafinvigencia >= dateV
        })
        filterDate?.forEach(element => {
            proyectoFechaVigente.push(element)
        })
        return proyectoFechaVigente
    }
    var dateValidError = false
    var messageDateValidError = ''

    if (selectedClient !== null) {
        loadProjects()
        if (proyectoFechaVigente.length === 0) {
            dateValidError = true
            messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
        } else {
            dateValidError = false
            messageDateValidError = ''
        }
    }

    const projects = proyectoFechaVigente.map(item => {
        const av = { name: item.name, id: item.id, dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
        return av
    })

    const onProjectChange = (e) => {
        setSelectedProject(e.value);
        setValidateChange(true)
        setSelectedObjectives({
            descriptionObjectives: '',
            id: ''
        })
    }

    //Obtener datos del select objetivo

    const [selectedObjectives, setSelectedObjectives] = useState({
        descriptionObjectives: '',
        id: ''
    })

    var porjectsObjectives = []

    const loadObjectives = () => {
        const result = objective.map(item => {
            return item.proyectos.map(j => {
                var boolean
                if (j.proyecto.id === parseInt(selectedProject.id)) {
                    boolean = item
                }
                return boolean
            })
        })

        result.forEach(i => {
            i.forEach(j => {
                if (j !== undefined) {
                    if (j.length !== 0) {
                        porjectsObjectives.push(j)
                    }
                }
            })
        })
    }

    if (selectedProject.id !== '') {
        loadObjectives()
    }

    const objectives = (selectedProject.id !== '' ? porjectsObjectives : objective).map(item => {
        const av = { descriptionObjectives: item.descripcion, id: item.id }
        return av
    })

    const onObjectivesChange = (e) => {
        setSelectedObjectives(e.value);
        setValidateChange(true)
    }

    //Obtener datos del porcentaje ingresado

    const [percentage, setPercentage] = useState(0);

    const onPercentageChange = (e) => {
        setPercentage(e.value)
        setValidateChange(true)
    }

    var gmtHours = -d.getTimezoneOffset() / 60;

    // calendar de fecha  inicio

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        let fecha = e.value
        setDate(fecha)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    ////validaciones proyectos
    var projectsError = false
    var menssageProjects = ''

    /// cuando ingrese un cliente 
    var disabledProject
    var disabledClient

    if (individualMonthReportProp === true) {
        disabledProject = true
        disabledClient = true
    } else {
        disabledProject = false
        disabledClient = false
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio

    useEffect(() => {
        setSelectedObjectives({
            descriptionObjectives: objectiveProp,
            id: idObjectiveProp
        })
        setSelectedClient({
            name: clientProp.razonsocial,
            id: clientProp.id
        })
        setSelectedProject({
            id: projectProp.id,
            name: projectProp.nombreproyecto,
            dateStartValidity: projectProp.fechainiciovigencia,
            dateEndValidity: projectProp.fechafinvigencia
        })
        var dateChange = new Date(dateProp)
        if (dateProp === null) {
            setDate(null)
        } else {
            if (gmtHours < 0) {
                setDate(SumarDias(dateChange, +1))
            } else {
                setDate(dateChange)
            }
        }
        setPercentage(percentageProp)

    }, [gmtHours, percentageProp, dateProp, projectProp, clientProp, idObjectiveProp, objectiveProp])

    //Almacenamos los valores para enviarlos por props

    const idProject = selectedProject.id
    const idObjective = selectedObjectives.id

    const resetData = () => {

        setSelectedObjectives({
            descriptionObjectives: objectiveProp,
            id: idObjectiveProp
        })
        setSelectedClient({
            name: clientProp.razonsocial,
            id: clientProp.id
        })
        setSelectedProject({
            id: projectProp.id,
            name: projectProp.nombreproyecto,
            dateStartValidity: projectProp.fechainiciovigencia,
            dateEndValidity: projectProp.fechafinvigencia
        })
        var dateChange = new Date(dateProp)
        if (dateProp === null) {
            setDate(null)
        } else {
            if (gmtHours < 0) {
                setDate(SumarDias(dateChange, +1))
            } else {
                setDate(dateChange)
            }
        }
        setPercentage(percentageProp)
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const usuario = cookies.get('usuario');

    const renderFooter = (name) => {
        var createNewDeliverable = false
        if (usuario !== createdByProp) {
            createNewDeliverable = true
        }
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage={createNewDeliverable ? "El registro editado no fue creado con su usuario, por lo tanto, se creará un nuevo registro con los datos actualizados, tenga en cuenta seleccionar este nuevo registro para su informe." : "¿Estás seguro de guardar esta información?"}
                            action={createNewDeliverable ? "Create" : "Update"}
                            label="Actualizar"
                            icon="pi pi-pencil"
                            idObjectiveDeliverableProp={idObjectiveDeliverableProp}
                            idDeliverableProp={idDeliverableProp}
                            deliverableProp={deliverableProp}
                            percentageProp={percentage}
                            dateProp={date === null ? date : CambiarFormatoFecha(date)}
                            idProjectProp={idProject}
                            idObjectiveProp={idObjective}
                            statusProp={1}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            assingnedNewObjectiveDeliverableProp={true}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            deleteDetailProp={false}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                        />
                        :
                        ""
                }
            </div>
        );
    }
    // validaciones
    // validar fechas dentro del rango del proyecto seleccionado

    var dateError = false
    var dateMessage = ''


    const validateDate = () => {
        var newDate = CambiarFormatoFecha(date)
        if (newDate >= selectedProject.dateStartValidity && newDate <= selectedProject.dateEndValidity) {
            dateError = false
            dateMessage = ''
        } else {
            dateError = true
            dateMessage = 'La fecha de cumplimiento esta fuera del rango de fechas del proyecto seleccionado'
        }
    }

    if (date !== null && selectedProject.id !== '' && dateError === false) {
        validateDate()
    }

    var updatePermission = false
    if (dateError === false && selectedProject.id !== '' && selectedObjectives.id !== '' && dateValidError === false) {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar entregable" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente</label>
                            <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente" filter
                                className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name" disabled={disabledClient}
                            />
                        </div>
                        <div className={`p-field p-col-12 p-md-6`}>
                            <label className="labels" >Proyecto </label>
                            <Dropdown value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto" filter
                                className={dateValidError || projectsError ? "p-invalid" : ""} emptyMessage="No hay resultados" name="project" optionLabel="name"
                                disabled={disabledProject}
                            />
                            <p className="mensajeError">{projectsError ? menssageProjects : ""}</p>
                            <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Objetivo<span className="require">*</span></label>
                            <Dropdown value={selectedObjectives} options={objectives} onChange={onObjectivesChange} name="objetivo" optionLabel="descriptionObjectives"
                                placeholder="Seleccione un objetivo" emptyMessage='No hay resultados' filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Cumplimiento</label>
                            <Calendar placeholder="Seleccione una fecha" value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Porcentaje cumplimiento</label>
                            <h5 style={{ marginBottom: '13px' }}> {percentage} %</h5>
                            <Slider value={percentage} onChange={onPercentageChange} step={5} />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnUpdateDatail