import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import { CambiarFormatoFecha, removeAccents } from "../Funciones";
import Cookies from 'universal-cookie'
import { Toast } from "primereact/toast";
import ModalAdvertencia from "../ModalAdvertencia/ModalAdvertencia";
import axios from "axios";
const cookies = new Cookies()

const BtnActualizarPersonasInteresadas = ({ nameProp, documentTypeProp, idDocumentTypeProp, numberDocumentProp, lastNameProp, positionProp, idPositionProp,
    companyProp, projectsProp, idInterestedPersonProp, creationDateProp, createdByProp, individualMonthReportProp, setRunRenderProp, runRenderProp,
    runRenderBlockProp, setRunRenderBlockProp, positionServiceProp, documentTypeServiceProp, clientServiceProp, projectsUserSessionProp, sendInterestedPersonsProp,
    idProjectProp, validateUpdateProp, dataInterestedPersonsProp, setRedirectProp, setValueUniqueProp }) => {
    const toast = useRef(null)
    const [positionService, setPositionService] = useState([])
    const [documentTypeService, setDocumentTypeService] = useState([]);
    const [clientService, setClientService] = useState([]);
    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [clientsUserSession, setClientsUserSession] = useState([]);
    const [displayBasic, setDisplayBasic] = useState(false);

    const getService = new Services()
    useEffect(() => {
        let source = axios.CancelToken.source()
        if (displayBasic === true) {

            getService.getCargos().then(data => {
                if (data !== undefined) {
                    setPositionService(data)
                }
            })
            getService.getTiposDocumento().then(data => {
                if (data !== undefined) {
                    setDocumentTypeService(data)
                }
            })

            if (individualMonthReportProp === true) {
                getService.getProyectosUsuarios().then(data => {
                    if (data !== undefined) {

                        var nameClients = []
                        data.forEach(element => {
                            var clients = {}
                            clients.id = element.cliente.id
                            clients.name = element.cliente.nombre
                            nameClients.push(clients)
                        })

                        const clientsTable = {}
                        const uniqueClients = nameClients.filter(function (object) {
                            return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                        })
                        const tableProjects = {}
                        const uniqueProjects = data.filter(function (object) {
                            return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
                        })
                        setClientService(uniqueClients)
                        setClientsUserSession(uniqueClients)
                        setProjectsUserSession(uniqueProjects)
                    }

                });
            }
        }

        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [displayBasic, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps

    const [newRunRender, setNewRunRender] = useState(0)
    useEffect(() => {
        if (displayBasic === true) {
            if (individualMonthReportProp === true) {
                /////Proceso pra evitar que los clientes se repitan 
                const selectedClients = []

                if (projectsProp !== undefined) {
                    projectsProp.forEach(element => {
                        var selectClients = {}
                        selectClients.id = element.proyecto.cliente.id
                        selectClients.name = element.proyecto.cliente.razonsocial
                        selectedClients.push(selectClients)
                    });

                }
                /*   projectsUserSessionProp.forEach(element => {
                      var clients = {}
                      clients.id = element.cliente.id
                      clients.name = element.cliente.nombre
                      selectedClients.push(clients)
                  }) */

                const clientsTable = {}
                const uniqueClients = selectedClients.filter(function (object) {
                    return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                })

                const selectedProjects = []
                const selectedProjectsOld = []
                const listProjects = []

                projectsUserSessionProp.forEach(element => {
                    listProjects.push(element)
                })

                var proyectoFechaVigente = []
                if (projectsProp !== undefined) {
                    const filterProject = projectsProp.filter(x => {
                        return parseInt(x.proyecto.id) === parseInt(idProjectProp)
                    })

                    const filterDate = filterProject.filter(x => {
                        return x.proyecto.fechafinvigencia >= dateV
                    })

                    filterDate.forEach(x => {
                        proyectoFechaVigente.push(x)
                    })
                    filterProject.forEach(element => {
                        var selectProject = {}
                        selectProject.id = parseInt(element.proyecto.id)
                        selectProject.name = element.proyecto.nombreproyecto
                        selectProject.fechafinvigencia = element.proyecto.fechafinvigencia
                        selectedProjects.push(selectProject)
                        var listProject = {}
                        listProject.id = String(element.proyecto.id)
                        listProject.nombre = element.proyecto.nombreproyecto
                        listProject.fechafinvigencia = element.proyecto.fechafinvigencia
                        listProject.cliente = {
                            id: element.proyecto.cliente.id,
                            nombre: element.proyecto.cliente.razonsocial
                        }
                        listProjects.push(listProject)
                        var selectProjectOld = {}
                        selectProjectOld.idCliente = element.proyecto.cliente.id
                        selectProjectOld.estado = element.estado
                        selectProjectOld.idR = element.id
                        selectProjectOld.id = element.proyecto.id
                        selectProjectOld.name = element.proyecto.nombreproyecto
                        selectedProjectsOld.push(selectProjectOld)
                    });

                }

                setSelectedProject(selectedProjects)
                setSelectedProjectOld(selectedProjectsOld)
                setSelectedClient(uniqueClients)

            } else {

                getService.getProyectosUsuarios().then(data => {

                    /////Proceso pra evitar que los clientes se repitan 
                    const selectedClients = []
                    const newSelectedClients = []

                    if (projectsProp !== undefined) {
                        projectsProp.forEach(element => {
                            var selectClients = {}
                            selectClients.id = element.proyecto.cliente.id
                            selectClients.name = element.proyecto.cliente.razonsocial
                            selectedClients.push(selectClients)
                        });
                    }

                    const clientsTable = {}
                    const uniqueClients = selectedClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })

                    data.forEach(element => {
                        var clients = {}
                        clients.id = parseInt(element.cliente.id)
                        clients.name = element.cliente.nombre
                        newSelectedClients.push(clients)
                    })
                    const newClientsTable = {}
                    const newUniqueClients = newSelectedClients.filter(function (object) {
                        return newClientsTable.hasOwnProperty(object.id) ? false : (newClientsTable[object.id] = true)
                    })
                    const selectedProjects = []
                    const selectedProjectsOld = []
                    const listProjects = []

                    data.forEach(element => {
                        listProjects.push(element)
                    })

                    var proyectoFechaVigente = []
                    if (projectsProp !== undefined) {
                        const filterDate = projectsProp.filter(x => {
                            return x.proyecto.fechafinvigencia >= dateV
                        })
                        filterDate.forEach(x => {
                            proyectoFechaVigente.push(x)
                        })
                        projectsProp.forEach(element => {
                            var selectProject = {}
                            selectProject.id = parseInt(element.proyecto.id)
                            selectProject.name = element.proyecto.nombreproyecto
                            selectProject.fechafinvigencia = element.proyecto.fechafinvigencia
                            selectedProjects.push(selectProject)
                            var listProject = {}
                            listProject.id = element.proyecto.id
                            listProject.nombre = element.proyecto.nombreproyecto
                            listProject.fechafinvigencia = element.proyecto.fechafinvigencia
                            listProject.cliente = {
                                id: element.proyecto.cliente.id,
                                nombre: element.proyecto.cliente.razonsocial
                            }
                            listProjects.push(listProject)
                            var selectProjectOld = {}
                            selectProjectOld.idCliente = element.proyecto.cliente.id
                            selectProjectOld.estado = element.estado
                            selectProjectOld.idR = element.id
                            selectProjectOld.id = element.proyecto.id
                            selectProjectOld.name = element.proyecto.nombreproyecto
                            selectProjectOld.fechafinvigencia = element.proyecto.fechafinvigencia
                            selectedProjectsOld.push(selectProjectOld)
                        });
                    }

                    const projectsTable = {}
                    const uniqueProjects = listProjects.filter(function (object) {
                        return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                    })
                    const selectedProjectsTable = {}
                    const uniqueSelectedProjects = selectedProjects.filter(function (object) {
                        return selectedProjectsTable.hasOwnProperty(object.id) ? false : (selectedProjectsTable[object.id] = true)
                    })
                    setSelectedProject(uniqueSelectedProjects)
                    setSelectedProjectOld(selectedProjectsOld)
                    setSelectedClient(uniqueClients)
                    setClientService(newUniqueClients)
                    setProjectsUserSession(uniqueProjects)
                    setClientsUserSession(newUniqueClients)
                });
            }
        }

    }, [newRunRender, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps


    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        resetData()
        dialogFuncMap[`${name}`](false);
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input Nombre

    const [inputName, setInputName] = useState({
        name: nameProp
    })

    const onNameChange = (event) => {
        setInputName({
            ...inputName,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del input Apellido

    const [inputLastName, setInputLastName] = useState({
        lastName: lastNameProp
    })

    const onLastNameChange = (event) => {
        setInputLastName({
            ...inputLastName,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del select Tipo de documento

    const [inputDocumentType, setInputDocumentType] = useState({
        documentType: documentTypeProp,
        id: idDocumentTypeProp
    })

    const documentTypes = documentTypeService.map(item => {
        const av = { documentType: item.nombretipodocumento, id: item.id }
        return av
    })

    const onDocumentTypeChange = (e) => {
        setInputDocumentType(e.value)
        setValidateChange(true)
    }
    // console.log(numberDocumentProp)
    //Obtener datos del input Numero de documento

    const [inputDocumentNumber, setInputDocumentNumber] = useState({
        number: numberDocumentProp
    })

    const onDocumentNumberChange = (event) => {
        if (event.target.value === '') {
            setInputDocumentNumber({
                number: null
            })
        } else {
            setInputDocumentNumber({
                ...inputDocumentNumber,
                [event.target.name]: event.target.value
            })
        }

        setValidateChange(true)
    }

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState(null);
    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: parseInt(item.id) }
        return av

    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
        if (e.value.length === 0) {
            setSelectedProject(null)
        }
    }

    //Obtener datos del select tipo de proyecto
    const [selectedProject, setSelectedProject] = useState(projectsProp.map(item => {
        return {
            id: parseInt(item.id),
            name: item.proyecto.nombreproyecto,
            fechafinvigencia: item.proyecto.fechafinvigencia
        }
    }));
    const [selectedProjectOld, setSelectedProjectOld] = useState(null);
    var projectClient = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const loadProjects = () => {

        if (selectedClient !== undefined) {
            if (selectedClient.length !== 0) {
                selectedClient.forEach(element => {
                    const result = projectsUserSession.filter(function (object) {
                        var idClient = parseInt(object.cliente.id)
                        return idClient === parseInt(element.id)
                    })
                    result.forEach(element => {
                        projectClient.push(element)
                    });
                })
            }
        }
        projectClient.sort(function (a, b) {
            var textA = a.name;
            var textB = b.name;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }

    var dateValidError = false
    var messageDateValidError = ''

    if (selectedClient !== null) {
        loadProjects()
    }
    const projects = projectClient.map(item => {
        const av = { id: parseInt(item.id), name: item.nombre, fechafinvigencia: item.fechafinvigencia }
        return av
    })

    const onProjectChange = (e) => {
        if (individualMonthReportProp === false && projectsProp !== undefined) {
            let getProject = []
            projectsProp.forEach(i => {
                const result = e.value.filter(item => {
                    return item.id === i.proyecto.id
                })
                result.forEach(e => {
                    getProject.push(e)
                })
            })
            if (getProject.length < projectsProp.length) {
                setDeselectedProjects(true)
            } else {
                setDeselectedProjects(false)
            }
        }
        setSelectedProject(e.value);
        setValidateChange(true)
    }
    // console.log(selectedProject)
    //Obtener datos del select tipo de cargo
    const [selectedPosition, setSelectedPosition] = useState({
        position: positionProp,
        id: idPositionProp
    });

    const positions = positionService.map(item => {
        const av = { position: item.cargo, id: item.id }
        return av
    })

    const onPositionChange = (e) => {
        setSelectedPosition(e.value);
        setValidateChange(true)
    }

    //Obtener datos del input empresa

    const [selectedCompanyComplete, setSelectedCompanyComplete] = useState(null);
    const [filteredCompany, setFilteredCompany] = useState(null)

    const searchCompany = (event) => {
        setTimeout(() => {
            let _filteredCompany;
            if (!event.query.trim().length) {
                _filteredCompany = [...clientService];
            }
            else {
                _filteredCompany = clientService.filter((object) => {
                    return object.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCompany(_filteredCompany);
        }, 250);
    }

    const onCompanyChange = (e) => {
        setSelectedCompanyComplete(e.value)
        setValidateChange(true)
    }

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    /// cuando ingrese un cliente 
    var disabledProject

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setInputName({
            name: nameProp
        })
        setInputLastName({
            lastName: lastNameProp
        })
        setInputDocumentType({
            documentType: documentTypeProp,
            id: idDocumentTypeProp
        })
        setInputDocumentNumber({
            number: numberDocumentProp
        })
        setSelectedPosition({
            position: positionProp,
            id: idPositionProp
        })
        setSelectedCompanyComplete(companyProp)
    }, [nameProp, lastNameProp, documentTypeProp, idDocumentTypeProp, numberDocumentProp, positionProp, idPositionProp, companyProp])

    //Almacenamos los valores para enviarlos por props

    const fullName = inputName.name
    const fullLastName = inputLastName.lastName
    const position = selectedPosition.id
    var company
    if (selectedCompanyComplete !== null) {
        if (typeof selectedCompanyComplete === 'object') {
            company = selectedCompanyComplete.name
        } else {
            company = selectedCompanyComplete
        }
    }
    const number = inputDocumentNumber.number
    const document = inputDocumentType.id
    var idProject
    selectedProject?.forEach(element => {
        idProject = element.id
    })
    const resetData = () => {
        setInputName({
            name: nameProp
        })
        setInputLastName({
            lastName: lastNameProp
        })
        setInputDocumentType({
            documentType: documentTypeProp,
            id: idDocumentTypeProp
        })
        setInputDocumentNumber({
            number: numberDocumentProp
        })
        /////Proceso pra evitar que los clientes se repitan 
        const selectedClients = []

        if (projectsProp !== undefined) {
            projectsProp.forEach(element => {
                var selectClients = {}
                selectClients.id = element.proyecto.cliente.id
                selectClients.name = element.proyecto.cliente.razonsocial
                selectedClients.push(selectClients)
            });

        }

        const clientsTable = {}
        const uniqueClients = selectedClients.filter(function (object) {
            return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
        })
        const selectedProjects = []
        if (projectsProp !== undefined) {
            projectsProp.forEach(element => {
                var selectProject = {}
                selectProject.id = parseInt(element.proyecto.id)
                selectProject.name = element.proyecto.nombreproyecto
                selectProject.fechafinvigencia = element.proyecto.fechafinvigencia
                selectedProjects.push(selectProject)
            });

        }
        setSelectedProject(selectedProjects)
        setSelectedClient(uniqueClients)
        setSelectedPosition({
            position: positionProp,
            id: idPositionProp
        })
        setSelectedCompanyComplete(companyProp)
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        )
    }

    const usuario = cookies.get('usuario');
    var permissionToEdit = false
    if (usuario === createdByProp || validateUpdateProp === true) {
        permissionToEdit = true
    }

    //validar cuando es pasaporte o cedula de extranjería
    var validarPasaporte = false

    if (inputDocumentType.documentType === "Pasaporte" || inputDocumentType.documentType === "Cédula Extranjería") {
        validarPasaporte = true
    }

    var documentError = false
    var documentMessage = ''
    if (inputDocumentType.documentType !== 'Pasaporte' && inputDocumentType.documentType !== "Cédula Extranjería") {
        if (isNaN(number) === true) {
            documentError = true
            documentMessage = 'Este tipo de documento no puede contener letras'
        }
    }


    const renderFooter = (name) => {

        return (
            <div>
                {changes ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage="¿Estás seguro de guardar esta información?"
                            action="Actualizar"
                            label="Actualizar"
                            icon="pi pi-pencil"
                            companyProp={company}
                            nameProp={fullName}
                            lastNameProp={fullLastName}
                            idPositionProp={position}
                            numberProp={number}
                            documentProp={document}
                            projectsProp={selectedProject}
                            projectsOldProp={selectedProjectOld}
                            idInterestedPersonProp={idInterestedPersonProp}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            validateChangeProp={validateChange}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            sendInterestedPersonsProp={sendInterestedPersonsProp}
                            projectProp={selectedProject}
                            idProjectProp={idProject}
                            assingNewProjectInterestedPerson={false}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    //validaciones para nombre

    var nameError = false
    var menssageName = ''
    if (fullName.length < 3 && fullName !== '') {
        nameError = true
        menssageName = 'El nombre debe ser mayor a 3 dígitos'
    } else if (fullName.length > 30) {
        nameError = true
        menssageName = 'El nombre debe ser menor a 30 dígitos'
    }

    //validaciones para apellido

    var lastNameError = false
    var menssageLastName = ''
    if (fullLastName.length < 3 && fullLastName !== '') {
        lastNameError = true
        menssageLastName = 'El apellido debe ser mayor a 3 dígitos'
    } else if (fullLastName.length > 30) {
        lastNameError = true
        menssageLastName = 'El apellido debe ser menor a 30 dígitos'
    }

    //Validaciones para Numero de Documento
    var numberError = false
    var messageNumber = ''
    if (number !== null) {
        if (number.length < 5 && number !== '') {
            numberError = true
            messageNumber = 'El número de documento debe ser mayor a 5 dígitos '
        } else if (number.length > 12) {
            numberError = true
            messageNumber = 'El número de documento debe ser menor a 12 dígitos'
        }
    }

    //Validar duplicidad por nombre o por documento cuando sea necesario
    const [validateErrorDuplicity, setValidateErrorDuplicity] = useState(false)
    useEffect(() => {

        let arrayNameDuplicated = []
        let arrayNumberDocumentDuplicated = []
        if (inputName.name !== nameProp || inputLastName.lastName !== lastNameProp || inputDocumentNumber.number !== numberDocumentProp) {
            dataInterestedPersonsProp.forEach(i => {
                let newNameComplete = removeAccents(i.nombrecompleto)
                let nameComplete = removeAccents(inputName.name + inputLastName.lastName)
                if (newNameComplete.toLowerCase() === nameComplete.toLowerCase()) {
                    arrayNameDuplicated.push(i)
                }
            })
        }
        arrayNameDuplicated.forEach(element => {
            if (element.numerodocumento === inputDocumentNumber.number) {
                arrayNumberDocumentDuplicated.push(element)

            }
        })

        if (arrayNumberDocumentDuplicated.length !== 0) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Persona interesada pre-existente', life: 3000 });
            setValidateErrorDuplicity(true)
        } else {
            setValidateErrorDuplicity(false)
        }

    }, [inputName, inputLastName, inputDocumentNumber, dataInterestedPersonsProp])// eslint-disable-line react-hooks/exhaustive-deps

    /*     console.log(validateErrorDuplicity); */
    //validaciones para empresa

    var businessError = false
    var messageBusiness = ''

    var updatePermission = false
    if (nameError === false && fullName !== '' && fullLastName !== '' && position !== '' && dateValidError === false && validateChange !== false && numberError === false && documentError === false
        && selectedClient !== null && selectedProject !== null && validateErrorDuplicity === false && lastNameError === false) {
        if (selectedCompanyComplete !== null && selectedProject.length !== 0 && selectedClient.length !== 0) {
            if (selectedCompanyComplete !== '') {
                updatePermission = true
            }
        }
    }

    ////validaciones proyectos
    var proyectosError = false
    var mensajeProyectos = ''
    if (selectedProject.length === 0) {
        proyectosError = true
        mensajeProyectos = 'Debe asociar almenos un proyecto '
    }

    const [deselectedProjects, setDeselectedProjects] = useState(false)

    useEffect(() => {
      

    }, [selectedProject, projectsProp, displayBasic, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>

            {
                deselectedProjects === true ?
                    <ModalAdvertencia
                        usuarioProp={idInterestedPersonProp}
                        newRunRenderProp={newRunRender}
                        setNewRunRenderProp={setNewRunRender}
                        setRedirectProp={setRedirectProp}
                        setDisplayBasicProp={setDisplayBasic}
                        setValueUniqueProp={setValueUniqueProp}
                    />
                    :
                    ''
            }
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            {
                permissionToEdit ?
                    <div>
                        <Toast ref={toast} />
                        <Dialog className="modal" header="Actualizar persona interesada" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                            <div className="p-fluid p-formgrid p-grid">

                                <form style={{ display: 'contents' }} >

                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Empresa<span className="require">*</span></label>
                                        <AutoComplete value={selectedCompanyComplete} suggestions={filteredCompany} completeMethod={searchCompany} field="name"
                                            dropdown filterMatchMode="startsWith" onChange={onCompanyChange} placeholder="Ingrese una empresa"
                                            className={businessError ? "p-invalid auto" : "auto"}
                                        />
                                        <p className="mensajeError">{businessError ? messageBusiness : ""}</p>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Cargo<span className="require">*</span></label>
                                        <Dropdown value={selectedPosition} options={positions} onChange={onPositionChange} name="position" optionLabel="position"
                                            placeholder="Seleccione un Cargo" filter
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Nombres<span className="require">*</span></label>
                                        <InputText value={fullName} id="name" type="text" name="name" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onNameChange}
                                            placeholder="Ingrese un nombre"
                                            className={nameError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{nameError ? menssageName : ""}</p>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Apellidos<span className="require">*</span></label>
                                        <InputText value={fullLastName} id="lastName" type="text" name="lastName" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onLastNameChange}
                                            placeholder="Ingrese un apellido"
                                            className={lastNameError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{lastNameError ? menssageLastName : ""}</p>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Tipo documento</label>
                                        <Dropdown value={inputDocumentType} options={documentTypes} onChange={onDocumentTypeChange} name="documentTypes" optionLabel="documentType"
                                            placeholder="Seleccione un tipo de documento "
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Número documento</label>
                                        <InputText value={number} id="number" type="text" name="number" keyfilter={validarPasaporte ? "alphanum" : "pint"} onChange={onDocumentNumberChange}
                                            placeholder="Ingrese un número de documento"
                                            className={numberError || documentError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{numberError ? messageNumber : ""}{documentError ? documentMessage : ""}</p>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Cliente<span className="require">*</span></label>
                                        <MultiSelect value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                            className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name" disabled={individualMonthReportProp ? true : false}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Proyecto<span className="require">*</span></label>
                                        <MultiSelect value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                            className={dateValidError || proyectosError ? "p-invalid" : ""} emptyMessage="No hay resultados" name="project" optionLabel="name"
                                            disabled={individualMonthReportProp || disabledProject ? true : false} tooltip={'Proyectos Antiguos: \n' + selectedProjectOld?.map(item => { return item.name + '\n' })}
                                            filter emptyFilterMessage="No se encontraron resultados"
                                        />
                                        <p className="mensajeError">{proyectosError ? mensajeProyectos : ""}</p>
                                        <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                    </div>

                    :
                    <Dialog className="modalConfirm" header="Confirmación de edición" visible={displayBasic} modal style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                        <div className="confirmation-content-modal">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>No puede editar registros creados por otro usuario </span>
                        </div>
                    </Dialog>
            }
        </>
    )
}

export default BtnActualizarPersonasInteresadas