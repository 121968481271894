import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import { addLocale } from 'primereact/api';
import Services from '../../../service/Services';
import '../../../assets/css/Forms.css'
import '../../../assets/css/DialogDemo.css'
/* import { Link} from 'react-router-dom' */
import Cookies from 'universal-cookie';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { CambiarFormatoFecha, SumarDias, /*RestarMeses,  weekNext ,*/ NuevoValidarMesCerrado, weekNext, GetDates, obtenerHoraMaximaPorFecha } from '../../Funciones'
import ModalSelectedDates from '../CopiarActividades/ModalSelectedDates';
import BtnNotas from '../BtnNotas';
import { useMemo } from 'react';
const cookies = new Cookies();

const BtnCopiarActividad = ({ horasHoyProp, detailPendingActivitiesProp,
    datePendigProp, hoursPendigProp, setRenderDetailProp, renderDetailProp, deliverablesServicesProp,
    achievementServicesProp, objectivesServiceProp, fechaProp, clienteProp, idClienteProp, proyectoProp, idProyectoProp, tipoActividadProp, idTipoActividadProp,
    ticketProp, areaProp, idAreaProp, sistemaProp, idSistemaProp, actividadProp, horasProp, idActividadProp, creadoPorProp, fechaCreacionProp, disabledProp, notesDescriptionProp,
    categoriaProp, idCategoriaProp, idObjetivoProp, idLogroProp, idEntregableProp, copiarActividadesProp }) => {

    const [servicioTipoActividad, setServicioTipoActividad] = useState([]);
    const [servicioAplicacion, setServicioAplicacion] = useState([]);
    const [servicioAreaNegocio, setServicioAreaNegocio] = useState([]);
    const [projectsSession, setProjectsSession] = useState([]);
    const [servicesUsers, setServicesUsers] = useState([]);
    const [servicesCategory, setServicesCategory] = useState([]);
    const [cierres, setCierres] = useState([]);
    const [tipoNovedades, setTipoNovedades] = useState([]);
    const [objectivesService, setObjectivesServices] = useState([])
    const [achievementServices, setAchievementServices] = useState([])
    const [deliverablesServices, setDeliverablesServices] = useState([])
    const getServices = new Services();

    const toast = useRef(null);
    const usuario = cookies.get('usuario')
    var idProveedor = cookies.get('idProveedor')


    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        if (displayBasic === true) {
            getServices.getAreasNegocio().then(data => {
                if (data !== undefined) {
                    setServicioAreaNegocio(data)
                }
            });
            getServices.getCategory().then(data => {
                if (data !== undefined) {
                    setServicesCategory(data)
                }
            });
            getServices.getAplicaciones().then(data => {
                if (data !== undefined) {
                    setServicioAplicacion(data)
                }
            });
            getServices.getTipoActividades().then(data => {
                if (data !== undefined) {
                    setServicioTipoActividad(data)
                }
            });
            getServices.getAsociacionProyectoUsuarioVigente().then(data => {
                if (data !== undefined) {
                    setProjectsSession(data)
                }
            });
            getServices.getCierres().then(data => {
                if (data !== undefined) {
                    setCierres(data)
                }
            });
            getServices.getTipoNovedad().then(data => {
                if (data !== undefined) {
                    setTipoNovedades(data)
                }
            });
            getServices.getUsuariosWithIdNameAddActivity().then(data => {
                if (data !== undefined) {
                    setServicesUsers(data)
                }
            });

        }

        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (displayBasic === true) {

            getServices.getObjectivesShortList([idProyectoProp]).then((result) => {
                setSelectedObjectivesServiceComplete(result?.find(item => item.id === idObjetivoProp))
                setObjectivesServices(result)
            }).catch((error) => {

            })

            getServices.getAchievementListShort(idObjetivoProp).then((result => {
                setSelectedAchievementServiceComplete(result?.find(item => item.id === idLogroProp))
                setAchievementServices(result)
            })).catch((error) => {

            })

            getServices.getDeliverablesListShort(idObjetivoProp).then((result => {
                setSelectedDeliverablesServiceComplete(result?.find(item => item.id === idEntregableProp))
                setDeliverablesServices(result)
            })).catch((error) => {

            })
        }

    }, [displayBasic, idObjetivoProp, idLogroProp, idEntregableProp, idProyectoProp])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    /// Proceso para llenar los clientes y los proyectos asociados al usuario en sesión

    /* console.log(projectsSession) */

    var clientsUserSession = []

    //// llenar clientes asociados al usuario en sesión 
    projectsSession.forEach(element => {
        clientsUserSession.push({
            id: element.cliente.id,
            razonsocial: element.cliente.nombre
        })
    });
    const tableClients = {}
    const uniqueClients = clientsUserSession.filter(function (object) {
        return tableClients.hasOwnProperty(object.id) ? false : (tableClients[object.id] = true)
    })

    //Obtener datos del select tipo de clientes

    const [selectedCliente, setSelectedCliente] = useState({
        cliente: clienteProp,
        id: idClienteProp
    });

    const clientes = uniqueClients.map(item => {
        const av = { cliente: item.razonsocial, id: parseInt(item.id) }
        return av
    })


    const [validarCambio, setValidarCambio] = useState(false)

    const onClienteChange = (e) => {
        const source = axios.CancelToken.source()
        setSelectedCliente(e.value);
        setValidarCambio(true)
        setSelectedProyecto({
            proyecto: '',
            id: ''
        })
        setSelectedObjectivesServiceComplete(null)
        setSelectedAchievementServiceComplete(null)
        setSelectedDeliverablesServiceComplete(null)
        validarCantidadHoras({
            ...objectData,
            cliente: {
                id: parseInt(e.value.id)
            }
        }, horas, source)
        return () => {
            if (source) {
                source.cancel()
            }
        }
    }

    //// llenar proyectos asociados sugún el cliente seleccionado 

    var proyectsUserSession = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const result = projectsSession.filter(function (object) {
        var idClient = object.cliente.id.toString()
        return parseInt(idClient) === parseInt(selectedCliente.id)
    })
    const tableProjects = {}
    const uniqueProjects = result.filter(function (object) {
        return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
    })

    uniqueProjects.forEach(element => {
        proyectsUserSession.push({
            id: element.id,
            nombreproyecto: element.nombre,
            fechafinvigencia: element.fechafinvigencia
        })
    });

    const filterDate = proyectsUserSession.filter(element => {
        return element.fechafinvigencia >= dateV
    })
    filterDate?.forEach(element => {
        proyectoFechaVigente.push(element)
    })

    var dateValidError = false
    var messageDateValidError = ''

    if (proyectoFechaVigente.length === 0 && selectedCliente.cliente !== '') {

        dateValidError = true
        messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
    } else {
        dateValidError = false
        messageDateValidError = ''
    }
    //Obtener datos del select tipo de proyecto

    const [selectedProyecto, setSelectedProyecto] = useState({
        proyecto: proyectoProp,
        id: idProyectoProp
    });


    const proyectos = proyectoFechaVigente.map(item => {
        const av = { proyecto: item.nombreproyecto, id: parseInt(item.id) }
        return av
    })

    const onProyectoChange = (e) => {

        const source = axios.CancelToken.source()
        setSelectedProyecto(e.value);
        setValidarCambio(true)
        validarCantidadHoras({
            ...objectData,
            proyecto: {
                id: parseInt(e.value.id)
            }
        }, horas, source)
        setSelectedObjectivesServiceComplete(null)
        setSelectedAchievementServiceComplete(null)
        setSelectedDeliverablesServiceComplete(null)


        return () => {
            if (source) {
                source.cancel()
            }
        }

    }
    //Obtener datos de categoria 
    const [selectedCategoryComplete, setSelectedCategoryComplete] = useState({
        descripcion: categoriaProp,
        id: idCategoriaProp
    });
    const [filteredCategory, setFilteredCategory] = useState(null);

    const searchCategory = (event) => {
        setTimeout(() => {
            let _filteredCategory;
            if (!event.query.trim().length) {
                _filteredCategory = [...servicesCategory];
            }
            else {
                _filteredCategory = servicesCategory.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCategory(_filteredCategory);
        }, 250);
    }

    const onCategoryCompleteChange = (e) => {
        setSelectedCategoryComplete(e.value)
        setValidarCambio(true)
        setSelectedTipoActividadComplete(null)
    }
    //Obtener datos del select tipo de actividades
    const [servicesTypeActivityForCategory, setServicesTypeActivityForCategory] = useState([])
    useEffect(() => {
        //Obtener los tipos de actividad asociado a cada categoria
        if (selectedCategoryComplete !== null) {
            const filter = servicioTipoActividad.filter(obj => {
                return obj.categoria.id === selectedCategoryComplete.id
            })
            setServicesTypeActivityForCategory(filter)
        }

    }, [selectedCategoryComplete])// eslint-disable-line react-hooks/exhaustive-deps

    const [selectedTipoActividadComplete, setSelectedTipoActividadComplete] = useState({
        nombretipoactividad: tipoActividadProp,
        id: idTipoActividadProp
    });
    const [filteredTipoActividad, setFilteredTipoActividad] = useState(null);

    const searchTipoActividad = (event) => {
        setTimeout(() => {
            let _filteredTipoActividad;
            if (!event.query.trim().length) {
                _filteredTipoActividad = [...servicesTypeActivityForCategory];
            }
            else {
                _filteredTipoActividad = servicesTypeActivityForCategory.filter((element) => {
                    return element.nombretipoactividad.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredTipoActividad(_filteredTipoActividad);
        }, 250);
    }

    const onTipoActividadCompleteChange = (e) => {
        setSelectedTipoActividadComplete(e.value);
        setValidarCambio(true)
        validarTipoActividad()
        if (e.value !== null) {
            if (typeof (e.value) === 'object') {
                validarCantidadHoras({
                    ...objectData,
                    tipoActividad: {
                        id: parseInt(e.value.id)
                    }
                }, horas)
            }
        }
    }

    //Obtener datos del select área de negocio

    const [selectedAreaComplete, setSelectedAreaComplete] = useState({
        nombreareanegocio: areaProp,
        id: idAreaProp
    });
    const [filteredArea, setFilteredArea] = useState(null);

    const searchArea = (event) => {
        setTimeout(() => {
            let _filteredArea;
            if (!event.query.trim().length) {
                _filteredArea = [...servicioAreaNegocio];
            }
            else {
                _filteredArea = servicioAreaNegocio.filter((element) => {
                    return element.nombreareanegocio.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredArea(_filteredArea);
        }, 250);
    }

    const onAreaCompleteChange = (e) => {
        setSelectedAreaComplete(e.value)
        setValidarCambio(true)
        if (e.value !== null) {
            if (typeof (e.value) === 'object') {
                validarCantidadHoras({
                    ...objectData,
                    areanegocio: {
                        id: parseInt(e.value.id)
                    }
                }, horas)
            }
        }
    }

    //Obtener datos del select Sistema

    const [selectedSistemaComplete, setSelectedSistemaComplete] = useState({
        nombreaplicacion: sistemaProp,
        id: idSistemaProp
    });

    const [filteredSistema, setFilteredSistema] = useState(null);

    //// llenar aplicaciones asociados sugún el cliente seleccionado 

    var AplicationsClients = []

    const resultAplication = servicioAplicacion.filter(function (object) {
        var idClient = object.idcliente.id.toString()
        return parseInt(idClient) === parseInt(selectedCliente.id)
    })

    resultAplication.forEach(element => {
        AplicationsClients.push(element)
    })

    const searchSistema = (event) => {
        setTimeout(() => {
            let _filteredSistema;
            if (!event.query.trim().length) {
                _filteredSistema = [...AplicationsClients];
            }
            else {
                _filteredSistema = AplicationsClients.filter((element) => {
                    return element.nombreaplicacion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredSistema(_filteredSistema);
        }, 250);
    }

    const onSistemaCompleteChange = (e) => {

        setSelectedSistemaComplete(e.value);
        setValidarCambio(true)
        if (e.value !== null) {
            if (typeof (e.value) === 'object') {
                validarCantidadHoras({
                    ...objectData,
                    aplicacion: {
                        id: parseInt(e.value.id)
                    }
                }, horas)
            }
        }
    }


    //Listado de Objetivos asociados al proyecto seleccionado

    useEffect(() => {
        if (displayBasic === true) {

            if (selectedProyecto !== null && selectedProyecto.id !== '') {

                getServices.getObjectivesShortList([parseInt(selectedProyecto.id)]).then((result) => {
                    setObjectivesServices(result)
                }).catch((error) => {

                })
            }
        }


    }, [selectedProyecto, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [selectedObjectivesServiceComplete, setSelectedObjectivesServiceComplete] = useState(null);
    const [filteredObjectivesService, setFilteredObjectivesService] = useState(null);

    //// llenar objetivos  asociados al proyecto seleccionado

    const tableObjectivesServices = {}
    const uniqueObjectivesServices = objectivesService?.filter(function (object) {
        return tableObjectivesServices.hasOwnProperty(object.id) ? false : (tableObjectivesServices[object.id] = true)
    })
    const searchObjectives = (event) => {
        setTimeout(() => {
            let _filteredObjectives;
            if (!event.query.trim().length) {
                _filteredObjectives = [...uniqueObjectivesServices];
            }
            else {
                _filteredObjectives = uniqueObjectivesServices?.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredObjectivesService(_filteredObjectives);
        }, 250);
    }

    const objectivesOnChange = (e) => {
        setSelectedObjectivesServiceComplete(e.value)
        setValidarCambio(true)
        setSelectedAchievementServiceComplete(null)
        setSelectedDeliverablesServiceComplete(null)
        validarCantidadHoras({
            ...objectData
        }, inputHoras.horas)
    }


    useEffect(() => {
        if (displayBasic === true) {
            if (selectedObjectivesServiceComplete !== null && selectedObjectivesServiceComplete !== undefined) {

                getServices.getAchievementListShort(selectedObjectivesServiceComplete.id).then((result => {
                    setAchievementServices(result)
                })).catch((error) => {

                })

                getServices.getDeliverablesListShort(selectedObjectivesServiceComplete.id).then((result => {
                    setDeliverablesServices(result)
                })).catch((error) => {

                })
            }
        }

    }, [selectedObjectivesServiceComplete, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    //Listado de logros asociados al objetivo seleccionado

    const [selectedAchievementServiceComplete, setSelectedAchievementServiceComplete] = useState(null);
    const [filteredAchievementService, setFilteredAchievementService] = useState(null);

    const tableAchievementServices = {}
    const uniqueAchievementServices = achievementServices?.filter(function (object) {
        return tableAchievementServices.hasOwnProperty(object.id) ? false : (tableAchievementServices[object.id] = true)
    })

    const searchAchievement = (event) => {
        setTimeout(() => {
            let _filteredAchievement;
            if (!event.query.trim().length) {
                _filteredAchievement = [...uniqueAchievementServices];
            }
            else {
                _filteredAchievement = uniqueAchievementServices?.filter((element) => {
                    return element.logro.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredAchievementService(_filteredAchievement);
        }, 250);
    }

    const achievementOnChange = (e) => {
        setSelectedAchievementServiceComplete(e.value)
        setValidarCambio(true)
        validarCantidadHoras({
            ...objectData
        }, inputHoras.horas)
    }

    //Listado de entregables asociados al objetivo seleccionado

    const [selectedDeliverablesServiceComplete, setSelectedDeliverablesServiceComplete] = useState(null);
    const [filteredDeliverablesService, setFilteredDeliverablesService] = useState(null);

    const tableDeliverableServices = {}
    const uniqueDeliverablesServices = deliverablesServices?.filter(function (object) {
        return tableDeliverableServices.hasOwnProperty(object.id) ? false : (tableDeliverableServices[object.id] = true)
    })

    const searchDeliverables = (event) => {
        setTimeout(() => {
            let _filteredDeliverables;
            if (!event.query.trim().length) {
                _filteredDeliverables = [...uniqueDeliverablesServices];
            }
            else {
                _filteredDeliverables = uniqueDeliverablesServices?.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredDeliverablesService(_filteredDeliverables);
        }, 250);
    }

    const deliverablesOnChange = (e) => {
        setSelectedDeliverablesServiceComplete(e.value)
        setValidarCambio(true)
        validarCantidadHoras({
            ...objectData
        }, inputHoras.horas)
    }

    //Notas de la actividad

    const [noteDescription, setNoteDescription] = useState('')

    //Obtener datos del input ticket
    const [inputTicket, setInputTicket] = useState({
        ticket: ''
    })

    const onTicketChange = (event) => {
        setInputTicket({
            ...inputTicket,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
        validarCantidadHoras({
            ...objectData,
            ticket: event.target.value
        }, horas)
    }
    //Obtener datos del input actividad
    const [inputActividad, setInputActividad] = useState({
        actividad: ''
    })

    const [actividadBlur, setActividadBlur] = useState(true)
    const onActividadChange = (event) => {
        setActividadBlur(false)
        setInputActividad({
            ...inputActividad,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)

    }

    const onActividadBlur = (event) => {
        setActividadBlur(true)
        validarCantidadHoras({
            ...objectData,
            descripcionactividad: event.target.value
        }, horas)
    }

    //Obtener datos del input número de horas

    const [inputHoras, setInputHoras] = useState({
        horas: ''
    })

    const onHorasChange = (event) => {
        const newValue = event.target.value;
        if ((newValue.match(/\./g) || []).length > 1 || newValue.startsWith('.')) {
            return;
        }
        setInputHoras({
            ...inputHoras,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
        validarCantidadHoras({
            ...objectData
        }, event.target.value)
    }

    //Variable que obtiene la funciones de semanas posteriores para habilitar

    /* var maxDate = weekNext() */

    // calendar de fecha 

    const [selectedDate, setSelectedDate] = useState([])

    const onDateChange = (e) => {
        let fecha = e.value
        setSelectedDate(fecha)
        setValidarCambio(true)
        if (fecha !== null && fecha.length !== 0) {
            if (fecha.length > 0) {
                var validacion = NuevoValidarMesCerrado(fecha, minFechaCierre, disabledDates)
                validarCantidadHoras({
                    ...objectData,
                    fecha: fecha
                }, inputHoras.horas)
                setFechaError(validacion.setFechaError)
                setMensajeFecha(validacion.setMensajeFecha)
                setValidarTipoActividad(validacion.setMostrarAdvertencia)
                setValidarTipoActividadMensaje(validacion.setMostrarAdvertenciaMensaje)
                setValidarMes(validacion.setValidarMes)

            }
        }
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Almacenamos los valores para enviarlos por props

    const fecha = selectedDate
    const cliente = selectedCliente.cliente
    const idCliente = selectedCliente.id
    const proyecto = selectedProyecto.proyecto
    const idProyecto = selectedProyecto.id

    var tipoActividad = ''
    var idTipoActividad
    if (selectedTipoActividadComplete !== null) {
        tipoActividad = selectedTipoActividadComplete.nombretipoactividad
        idTipoActividad = selectedTipoActividadComplete.id
    }


    const ticket = inputTicket.ticket

    var area
    var idArea
    if (selectedAreaComplete !== null) {
        area = selectedAreaComplete.nombreareanegocio
        idArea = selectedAreaComplete.id
    }

    var sistema
    var idSistema
    if (selectedSistemaComplete !== null) {
        sistema = selectedSistemaComplete.nombreaplicacion
        idSistema = selectedSistemaComplete.id
    }

    const actividad = inputActividad.actividad
    const horas = inputHoras.horas
    const idColaborador = cookies.get('idColaborador')

    var objectData = {
        cliente: {
            id: parseInt(idCliente)
        },
        proyecto: {
            id: parseInt(idProyecto)
        },
        tipoActividad: {
            id: parseInt(idTipoActividad)
        },
        areanegocio: {
            id: idArea
        },
        aplicacion: {
            id: idSistema
        },
        fecha: fecha,
        descripcionactividad: actividad.toLowerCase(),
        ticket: ticket,
        idObjetivo: selectedObjectivesServiceComplete?.id,
        idLogro: selectedAchievementServiceComplete?.id,
        idEntregable: selectedDeliverablesServiceComplete?.id
    }

    useEffect(() => {

        if (detailPendingActivitiesProp !== undefined) {
            if (detailPendingActivitiesProp === true) {
                if (hoursPendigProp !== undefined) {
                    setInputHoras({
                        horas: hoursPendigProp
                    })
                }
                if (datePendigProp !== undefined) {
                    var d = new Date()
                    var gmtHours = -d.getTimezoneOffset() / 60;
                    var newDate = new Date(datePendigProp)
                    newDate.setHours(0, 0, 0, 0);
                    if (gmtHours < 0) {
                        setSelectedDate([SumarDias(newDate, +1)])
                    } else {
                        setSelectedDate([newDate])
                    }

                }

            }
        }
    }, [detailPendingActivitiesProp, hoursPendigProp, datePendigProp]) // eslint-disable-line react-hooks/exhaustive-deps


    // Modal para el botón regresar

    const [actividadRegistrada, setActividadRegistrada] = useState(false)

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            setErrorDateStartUserSession(false)
            setErrorMessageDateStartUserSession('')
            if (actividadRegistrada === true) {
                if (detailPendingActivitiesProp !== undefined) {
                    if (detailPendingActivitiesProp === true) {
                        setDisplayConfirmation(false)
                        setDisplayBasic(false)
                        setRenderDetailProp(renderDetailProp + 1)
                        setShowElements(false)
                    }
                } else {
                    window.location.href = "/registroActividades/consultarActividades/0"
                }
            } else {

                /*  setSelectedCliente({
                     cliente: '',
                     id: ''
                 })
                 setSelectedProyecto({
                     proyecto: '',
                     id: ''
                 })
                 setSelectedCategoryComplete(null)
                 setSelectedTipoActividadComplete(null)
 
                 setSelectedAreaComplete(null)
                 setNewErrorHours(false)
                 setNewMessageHours('')
                 setSelectedSistemaComplete(null)
 
                 setSelectedObjectivesServiceComplete(null)
                 setSelectedAchievementServiceComplete(null)
                 setSelectedDeliverablesServiceComplete(null)
 
                 setSelectedDate([])
 
                 setInputActividad({
                     actividad: ''
                 })
                 setInputHoras({
                     horas: ''
                 })
                 setInputTicket({
                     ticket: ''
                 }) */
                setFechaError(false)
                setMensajeFecha('')
                setHorasError(false)
                setMensajeError('')
                setNewErrorHours(false)
                setNewMessageHours('')
                setSelectedDate([])
                setValidarCambio(false)
                setShowElements(false)
                setDisplayBasic(false)
                dialogFuncMap[`${name}`](false);
            }


        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }


    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        if (displayBasic === true) {

            setSelectedCliente({
                cliente: clienteProp,
                id: idClienteProp
            })

            setSelectedProyecto({
                proyecto: proyectoProp,
                id: idProyectoProp
            })
            setSelectedCategoryComplete({
                descripcion: categoriaProp,
                id: idCategoriaProp
            })
            setSelectedTipoActividadComplete({
                nombretipoactividad: tipoActividadProp,
                id: idTipoActividadProp
            })

            setSelectedAreaComplete({
                nombreareanegocio: areaProp,
                id: idAreaProp
            })
            setSelectedSistemaComplete({
                nombreaplicacion: sistemaProp,
                id: idSistemaProp
            })
            setInputActividad({
                actividad: actividadProp
            })
            setInputHoras({
                horas: horasProp
            })
            setInputTicket({
                ticket: ticketProp
            })

        }

    }, [displayBasic, clienteProp, idClienteProp, proyectoProp, idProyectoProp, tipoActividadProp, idTipoActividadProp, ticketProp, areaProp, idAreaProp, sistemaProp, idSistemaProp, actividadProp, horasProp, idCategoriaProp, categoriaProp])


    // selección de datos por fecha
    const [showElements, setShowElements] = useState(false);
    const [disabledBtnNo, setDisabledBtnNo] = useState(false);
    const [newData, setNewData] = useState([]);
    const [restartData, setRestartData] = useState(0);

    /* console.log(newData) */
    var dataCopied = {
        tipoActividad: {
            nombretipoactividad: tipoActividad
        }
    }
    var arrayNewData = newData.map(item => {
        return {
            actualizadopor: "",
            aplicacion: {
                id: idSistema
            },
            areanegocio: {
                id: idArea
            },
            cliente: {
                id: idCliente
            },
            colaborador: {
                id: idColaborador
            },
            coordinadocon: "",
            creadopor: usuario,
            descripcionactividad: item.descripcionactividad,
            esadicional: 0,
            estado: 1,
            fecha: item.fecha,
            fechaactualizado: "",
            fechacorreoelec: "",
            fechacreacion: "",
            idempresa: {
                id: 1
            },
            notas: noteDescription,
            numerohoras: item.numerohoras,
            proyecto: {
                id: idProyecto
            },
            tipoActividad: {
                id: idTipoActividad
            },
            ticket: item.ticket,
            idproveedor: parseInt(idProveedor),
            idObjetivo: selectedObjectivesServiceComplete !== null ? parseInt(selectedObjectivesServiceComplete?.id) : null,
            idLogro: selectedAchievementServiceComplete !== null ? parseInt(selectedAchievementServiceComplete?.id) : null,
            idEntregable: selectedDeliverablesServiceComplete !== null ? parseInt(selectedDeliverablesServiceComplete?.id) : null
        }
    })
    var arrayFechas = selectedDate?.map(item => {
        let date = null
        if (item !== null) {
            date = CambiarFormatoFecha(item)
        }

        return {
            actualizadopor: "",
            aplicacion: {
                id: idSistema
            },
            areanegocio: {
                id: idArea
            },
            cliente: {
                id: idCliente
            },
            colaborador: {
                id: idColaborador
            },
            coordinadocon: "",
            creadopor: usuario,
            descripcionactividad: actividad,
            esadicional: 0,
            estado: 1,
            fecha: date,
            fechaactualizado: "",
            fechacorreoelec: "",
            fechacreacion: "",
            idempresa: {
                id: 1
            },
            notas: noteDescription,
            numerohoras: horas,
            proyecto: {
                id: idProyecto
            },
            tipoActividad: {
                id: idTipoActividad
            },
            ticket: ticket,
            idproveedor: parseInt(idProveedor),
            idObjetivo: selectedObjectivesServiceComplete !== null ? parseInt(selectedObjectivesServiceComplete?.id) : null,
            idLogro: selectedAchievementServiceComplete !== null ? parseInt(selectedAchievementServiceComplete?.id) : null,
            idEntregable: selectedDeliverablesServiceComplete !== null ? parseInt(selectedDeliverablesServiceComplete?.id) : null
        }
    })

    const [registered, setRegistered] = useState(false)
    const RegistrarActividad = ({ setDisplayConfirmationProp }) => {
        const [disabled, setDisabled] = useState(false)
        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }
        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {

            dialogFuncMap[`${name}`](false);

        }
        const indicador = 0

        const Registrar = async (agregarMas, jsonProp) => {

            setDisabled(true)
            const urlRegistrar = process.env.REACT_APP_BASE_URL + '/v1/api/activity/registerActivity'

            var json = JSON.stringify(jsonProp)

            await axios.post(urlRegistrar, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then(response => {
                    setRegistered(true)
                    //console.log(response.data)
                    setInputHoras({
                        horas: ''
                    })
                    if (detailPendingActivitiesProp !== undefined) {
                        if (detailPendingActivitiesProp === true) {
                            setDisplayConfirmation(false)
                            setRenderDetailProp(renderDetailProp + 1)
                            setDisplayConfirmationProp(false)
                            if (agregarMas === false) {
                                setDisplayBasic(false)
                            }
                        }
                    } else {
                        if (agregarMas === false) {
                            window.location.href = "/registroActividades/consultarActividades/" + indicador
                        }
                    }
                })
                .catch(function (error) {
                    //console.log(error.response)
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                })
        }
        const Regresar = (name, agregarMas) => {

            if (showElements === true) {
                arrayFechas.forEach(element => {
                    Registrar(agregarMas, element)
                })
            } else {
                arrayNewData.forEach(element => {
                    Registrar(agregarMas, element)
                })
            }

            if (agregarMas === true) {
                setInputActividad({
                    actividad: ''
                })
                setInputHoras({
                    horas: ''
                })
                setActividadRegistrada(true)
                setRestartData(restartData + 1)
                setDisabledBtnNo(false)
                setDisplayBasic(true)

                dialogFuncMap[`${name}`](false);

            }

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => Regresar(name, false)} className="p-button-text" disabled={disabled} />
                    <Button label="Si" icon="pi pi-check" onClick={() => Regresar(name, true)} autoFocus disabled={disabled} />
                </div>
            );
        }



        return (
            <>
                <Button label={detailPendingActivitiesProp ? "Aceptar" : "Guardar"} icon="pi pi-plus-circle" onClick={() => onClick('displayConfirmation')} className="" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal breakpoints={{ '960px': '75vw' }} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>La información ingresada se guardará en su registro de Actividades, ¿Desea agregar otra actividad?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    useMemo(() => {
        if (registered === true) {
            toast.current.show({ severity: 'success', summary: 'Se ha registrado la actividad', detail: '', life: 3000 });
        }

        return () => {
            setRegistered(false)
        }
    }, [registered])
    const MessageDetail = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>

                    <RegistrarActividad
                        setDisplayConfirmationProp={setDisplayConfirmation}
                    />
                </div>
            );
        }

        return (
            <>
                <Button label="Guardar" icon="pi pi-plus-circle" onClick={() => onClick('displayConfirmation')} className="" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal breakpoints={{ '960px': '75vw' }} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>Señor usuario su actividad se registró satisfactoriamente y aparecerá en la página de Registro de Actividades.</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {

        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }


                {permisoRegistrar ?
                    detailPendingActivitiesProp === true ?
                        <MessageDetail />
                        :
                        <>
                            <RegistrarActividad />
                        </>
                    :
                    ""
                }

            </div>
        );
    }

    //validaciones

    const [fechaError, setFechaError] = useState(false)
    const [mensajeFecha, setMensajeFecha] = useState('')


    var clienteError = false
    var mensajeCliente = ''

    var proyectoError = false
    var mensajeProyecto = ''

    var disabledProyecto

    if (cliente === '') {
        disabledProyecto = true
    } else {
        disabledProyecto = false

    }


    let disabledObjectives = false
    if (selectedProyecto.id === '') {
        disabledObjectives = true
    } else {
        disabledObjectives = false
    }

    let disabledAchivementAndDeliverable = false
    if (selectedObjectivesServiceComplete === null) {
        disabledAchivementAndDeliverable = true
    } else {
        disabledAchivementAndDeliverable = false
    }


    var tipoActividadError = false
    var mensajeTipoActividad = ''

    if (typeof (selectedTipoActividadComplete) === 'string') {
        tipoActividadError = true
        mensajeTipoActividad = 'Resultado no encontrado'
    }

    var areaError = false
    var mensajeArea = ''

    if (typeof (selectedAreaComplete) === 'string') {
        areaError = true
        mensajeArea = 'Resultado no encontrado'
    }

    var sistemaError = false
    var mensajeSistema = ''

    if (typeof (selectedSistemaComplete) === 'string') {
        sistemaError = true
        mensajeSistema = 'Resultado no encontrado'
    }

    if (selectedCliente.id !== '') {
        if (AplicationsClients.length === 0) {
            sistemaError = true
            mensajeSistema = 'El cliente seleccionado no tiene aplicaciones asociadas'
        }
    }

    var ticketError = false
    var mensajeTicket = ''
    if (tipoActividad === 'Soporte-Requerimientos' || tipoActividad === 'Soporte-Incidencias') {
        ticketError = true
        mensajeTicket = 'Debes ingresar un ticket'
        if (ticket !== '') {
            if (ticket.length < 2) {
                ticketError = true
                mensajeTicket = 'El ticket debe tener más de 2 dígitos'
            } else if (ticket.length > 50) {
                ticketError = true
                mensajeTicket = 'El ticket debe tener menos de 50 dígitos'
            } else {
                ticketError = false
                mensajeTicket = ''
            }
        }
    } else {
        if (ticket !== '') {
            if (ticket?.length < 2) {
                ticketError = true
                mensajeTicket = 'El ticket debe tener más de 2 dígitos'
            } else if (ticket?.length > 50) {
                ticketError = true
                mensajeTicket = 'El ticket debe tener menos de 50 dígitos'
            } else {
                ticketError = false
                mensajeTicket = ''
            }
        }
    }

    var actividadError = false
    var mensajeActividad = ''
    if (actividad.length < 10 && actividad !== '') {
        actividadError = true
        mensajeActividad = 'La descripción es muy corta'
    } else if (actividad.length > 300) {
        actividadError = true
        mensajeActividad = 'La descripción es demasiado larga '
    }

    const [horasError, setHorasError] = useState(false)
    const [mensajeHoras, setMensajeError] = useState('')

    const [newErrorHours, setNewErrorHours] = useState(false)
    const [newMessageHours, setNewMessageHours] = useState('')

    const [showErrorDataDuplicate, setShowErrorDataDuplicate] = useState(false);
    const [menssageDataDuplicate, setMenssageDataDuplicate] = useState('');

    const [label2, setLabel2] = useState('')

    const [dataActivity, setDataActivity] = useState([])
    const [newErrorVacaciones, setNewErrorVacaciones] = useState(false)
    var dataDuplicate = []

    const validarCantidadHoras = async (objectData, hours, source, data) => {

        if (objectData.fecha !== null && objectData.fecha.length > 0) {
            var fechaConvertida = objectData.fecha.map(item => {
                return item !== null ? CambiarFormatoFecha(item) : ''
            })
            var arrayDates = objectData.fecha.sort((a, b) => a - b)
            arrayDates.forEach(e => {
                e.setHours(0, 0, 0, 0);
            })
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const newDateStart = arrayDates[0]
            const newDateEnd = arrayDates[arrayDates.length - 1]
            const json = JSON.stringify({
                esadicional: "0",
                fecFinal: newDateEnd,
                fecInicial: newDateStart,
                usuario: usuario
            })

            await axios.post(url, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    },
                    /* cancelToken: source.token */
                })
                .then(response => {
                    if (response !== undefined) {
                        var filterData = []
                        let excedioHoras = false
                        let tieneMaximaCero = false;
                        let horasPorFecha = {};
                        let newData = []
                        const historicoHoras = sessionStorage.getItem('historicoHoras')
                        const newHistoricoHoras = JSON.parse(historicoHoras)
                        const dataConcat = label2 === 'No' ? response.data : response.data.concat(data)

                        if (parseFloat(hours) === parseFloat(0) && parseFloat(hours) === parseFloat(0.0)) {
                            setNewErrorHours(true)
                            setNewMessageHours('Las horas registradas deben ser mayor a 0')
                        } else {

                            fechaConvertida.forEach(y => {
                                const filterDataConcat = dataConcat.filter(obj => {
                                    return obj.fecha === y
                                });
                                if (filterDataConcat.length > 0) {
                                    filterDataConcat.forEach(element => {
                                        newData.push(element)
                                    })
                                }
                            })
                            setDataActivity(newData)
                            //Validar horas máximas cuando hay en fechas donde hay actividades registradas

                            if (newData.length > 0) {

                                newData.forEach(element => {
                                    const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(newHistoricoHoras, [element.fecha])

                                    if (!horasPorFecha.hasOwnProperty(element.fecha)) {
                                        horasPorFecha[element.fecha] = 0;
                                    }
                                    filterData.push(element)

                                    horasPorFecha[element.fecha] += parseFloat(element.numerohoras);

                                    if (parseFloat(horasMaximaPorFecha[element.fecha]) === 0) {
                                        tieneMaximaCero = true;
                                    }

                                    if ((tieneMaximaCero && horasPorFecha[element.fecha] + parseFloat(hours) > parseFloat(24)) ||
                                        (!tieneMaximaCero && horasPorFecha[element.fecha] + parseFloat(hours) > parseFloat(horasMaximaPorFecha[element.fecha]))) {

                                        excedioHoras = true;
                                    }
                                    if (excedioHoras === true) {
                                        setNewErrorHours(true)
                                        setNewMessageHours('No se pueden registrar más de las horas máximas parametrizadas')
                                    } else {
                                        setNewErrorHours(false)
                                        setNewMessageHours('')
                                    }

                                })
                            } else {
                                //Obtener horas Máximas segun las fechas
                                fechaConvertida.forEach(fecha => {
                                    const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(newHistoricoHoras, [fecha])

                                    //Validar horas máximas en fechas que no hay actividades registradas
                                    if (parseFloat(horasMaximaPorFecha[fecha]) > parseFloat(0)) {
                                        if (parseFloat(hours) > parseFloat(horasMaximaPorFecha[fecha])) {
                                            excedioHoras = true
                                        }
                                        if (excedioHoras === true) {
                                            setNewErrorHours(true)
                                            setNewMessageHours('No se pueden registrar más de las horas máximas parametrizadas')
                                        } else {
                                            setNewErrorHours(false)
                                            setNewMessageHours('')
                                        }
                                    } else {
                                        if (parseFloat(hours) > parseFloat(24)) {
                                            setNewErrorHours(true)
                                            setNewMessageHours('No se pueden pasar de las 24 horas del día')
                                        }
                                        else {
                                            setNewErrorHours(false)
                                            setNewMessageHours('')
                                        }
                                    }
                                })
                            }
                        }


                        var newArrayDataRegistered = filterData.map(item => {
                            return {
                                cliente: {
                                    id: item.cliente.id
                                },
                                proyecto: {
                                    id: item.proyecto.id
                                },
                                tipoActividad: {
                                    id: item.tipoActividad.id
                                },
                                areanegocio: {
                                    id: item.areanegocio.id
                                },
                                aplicacion: {
                                    id: item.aplicacion.id
                                },
                                fecha: item.fecha,
                                descripcionactividad: item.descripcionactividad.toLowerCase(),
                                ticket: item.ticket,
                                idObjetivo: item.idObjetivo,
                                idLogro: item.idLogro,
                                idEntregable: item.idEntregable,
                            }
                        })

                        var comparatorObject = fechaConvertida.map(item => {
                            return {
                                ...objectData,
                                fecha: item,
                            }
                        })

                        newArrayDataRegistered.forEach(x => {
                            comparatorObject.forEach(y => {
                                if ((JSON.stringify(x) === JSON.stringify(y) === true)) {
                                    dataDuplicate.push(y)
                                }
                            })
                        })
                        if (dataDuplicate.length !== 0) {
                            setShowErrorDataDuplicate(true)
                            setMenssageDataDuplicate('Los datos registrados son exactamente iguales a una actividad ya registrada, modifique los campos')
                        } else {
                            setShowErrorDataDuplicate(false)
                            setMenssageDataDuplicate('')
                        }
                    }
                })
                .catch(function (error) {
                    //console.log(error.response)
                });
        }
    } //Validar que no se puede registrar una actividad en una novedad de tiempo completo'
    const onHideDate = (e) => {
        let arrayFechas = []//Obtener listado de fechas en la que hay novedades
        const filterVacaciones = tipoNovedades.filter(item => {
            return item.isVacaciones === true
        })

        dataActivity?.forEach(i => {
            filterVacaciones.forEach(element => {
                if (element.tipoActividad.id === i.tipoActividad.id) {
                    arrayFechas.push(i.fecha)
                }
            })
        })

        if (arrayFechas.length === 1) {
            const fecha = arrayFechas[0];
            setNewErrorVacaciones(true)

            toast.current.show({
                severity: 'error',
                summary: '¡Algo salió mal!',
                detail: `La siguiente fecha ya está seleccionada como novedad de tiempo completo: ${fecha}. Modifique la fecha`,
                life: 10000,
            });

        } else if (arrayFechas.length > 1) {
            const fechas = arrayFechas.join(', ');
            setNewErrorVacaciones(true)
            toast.current.show({
                severity: 'error',
                summary: '¡Algo salió mal!',
                detail: `Las siguientes fechas ya están seleccionadas como novedad de tiempo completo: ${fechas}. Modifique las fechas.`,
                life: 10000
            });

        } else {

            setNewErrorVacaciones(false);
        }
    };
    useEffect(() => {
        if (detailPendingActivitiesProp !== undefined) {
            if (detailPendingActivitiesProp === true) {
                if (datePendigProp !== undefined && displayBasic === true) {
                    validarCantidadHoras({
                        ...objectData
                    }, inputHoras.horas)
                }
            }
        }


    }, [inputHoras.horas, datePendigProp, detailPendingActivitiesProp, displayBasic]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        e.preventDefault();
    };

    // validar Mes cerrado

    var minFechaCierre
    var disabledDates

    var idUsuario = cookies.get('idUsuario')
    const resultUser = servicesUsers.filter(item => {
        return parseInt(item.idUsuario) === parseInt(idUsuario)
    })

    var mesAbierto = []
    if (resultUser.length !== 0) {
        resultUser.forEach(element => {
            mesAbierto = cierres.filter(function (objeto) {
                var id = element.blnAddActivitivy
                return objeto.id === id
            })
        })
    }
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    if (mesAbierto.length === 0) {
        cierres.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    } else {
        mesesCerrados.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    }
    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))
    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))
    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })
    var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre)
    var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2)

    var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre)
    var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1)
    var dateArray = GetDates(newFechaFinalCierre, (fechaUltimoCierre).addDays(1));

    cierres.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })

    if (dateArray.length !== 0) {
        minFechaCierre = newFechaInicialCierre
        disabledDates = dateArray

    } else {
        minFechaCierre = SumarDias(fechaUltimoCierre, +1)
        disabledDates = []
    }

    // Modal para validar si es una actividad o una novedad

    const [ValidarTipoActividad, setValidarTipoActividad] = useState(false)
    const [ValidarTipoActividadMensaje, setValidarTipoActividadMensaje] = useState('')

    const [validarMes, setValidarMes] = useState(false)

    const validarTipoActividad = () => {
        tipoNovedades.forEach(element => {
            if (tipoActividad === element.descripcion) {
                setValidarTipoActividad(true)
                setValidarTipoActividadMensaje('Señor usuario la actividad seleccionada pertenece a una novedad, debe dirigirse a la pestaña novedades para registrarla')
                setValidarMes(false)
            }
        })
    }

    const MensajeTipoActividad = ({ mostrarMensajeProp, mensajeProp }) => {
        const [displayBasic2, setDisplayBasic2] = useState(mostrarMensajeProp);

        const dialogFuncMap = {
            'displayBasic': setDisplayBasic2,
        }

        const onHide = (name) => {

            setValidarTipoActividad(false)
            setValidarTipoActividadMensaje('')

            if (validarMes !== true) {
                setDisplayBasic(false)
                setSelectedCliente({
                    cliente: clienteProp,
                    id: idClienteProp
                })

                setSelectedProyecto({
                    proyecto: proyectoProp,
                    id: idProyectoProp
                })
                setSelectedCategoryComplete({
                    descripcion: categoriaProp,
                    id: idCategoriaProp
                })
                setSelectedTipoActividadComplete({
                    nombretipoactividad: tipoActividadProp,
                    id: idTipoActividadProp
                })

                setSelectedAreaComplete({
                    nombreareanegocio: areaProp,
                    id: idAreaProp
                })
                setSelectedSistemaComplete({
                    nombreaplicacion: sistemaProp,
                    id: idSistemaProp
                })
                setInputActividad({
                    actividad: actividadProp
                })
                setInputHoras({
                    horas: horasProp
                })
                setInputTicket({
                    ticket: ticketProp
                })
            }
            setDisplayBasic2(false)
            dialogFuncMap[`${name}`](false);

        }

        const renderFooter = (name) => {
            return (
                <div>
                    {/* <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" /> */}
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')} breakpoints={{ '960px': '75vw' }}  >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>{mensajeProp}</span>

                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    var maxDates = weekNext()
    const [newInputHours, setNewInputHours] = useState('')
    const [label, setLabel] = useState('')
    const [data, setData] = useState([])
    useEffect(() => {
        let source;
        if (displayBasic === true) {
            source = axios.CancelToken.source()
            try {
                if (selectedDate !== null && selectedDate.length !== 0) {
                    var validacion = NuevoValidarMesCerrado(selectedDate, minFechaCierre, disabledDates)
                    setFechaError(validacion.setFechaError)
                    setMensajeFecha(validacion.setMensajeFecha)
                    setValidarTipoActividad(validacion.setMostrarAdvertencia)
                    setValidarTipoActividadMensaje(validacion.setMostrarAdvertenciaMensaje)
                    setValidarMes(validacion.setValidarMes)
                }
            } catch (error) {
                /* console.log(error); */
            }
            if (label === 'Si' && actividadBlur === true) {
                validarCantidadHoras({
                    ...objectData
                }, newInputHours.hours, source, data)
            }
        }
        return () => {
            if (source !== undefined && source) {
                source.cancel()
            }
        }
    }, [inputActividad, inputTicket, selectedDate, newInputHours, displayBasic, label, data, actividadBlur]) // eslint-disable-line react-hooks/exhaustive-deps


    // Sé valida que las fechas de registrar actividad no sean menores que la fecha de ingreso del usuario en sessión
    const [errorDateStartUserSession, setErrorDateStartUserSession] = useState(false)
    const [errorMessageDateStartUserSession, setErrorMessageDateStartUserSession] = useState('')
    useEffect(() => {
        const dateStartUserSession = cookies.get('fechaIngreso')
        let arrDate = []
        if (selectedDate !== null) {
            selectedDate.forEach(e => {
                if (e !== null) {
                    if (CambiarFormatoFecha(e) < dateStartUserSession) {
                        arrDate.push(e)
                    }
                }
            })
            if (arrDate.length !== 0) {
                setErrorDateStartUserSession(true)
                setErrorMessageDateStartUserSession(`No puedes registrar actividades con fecha menor a su fecha de ingreso, la cuál es ${dateStartUserSession}`)
            } else {
                setErrorDateStartUserSession(false)
                setErrorMessageDateStartUserSession('')
            }
        }

    }, [selectedDate])// eslint-disable-line react-hooks/exhaustive-deps

    const [errorEntero, setErrorEntero] = useState(false)
    const [messageEntero, setMessageEntero] = useState('')
    useEffect(() => {
        if (inputHoras.horas !== '') {
            const validarEntero = isValidNumber(inputHoras.horas)
            if (validarEntero === false) {
                setErrorEntero(true)
                setMessageEntero('Debe ingresar números enteros o decimales')
            } else {
                setErrorEntero(false)
                setMessageEntero('')
            }
        }
        function isValidNumber(value) {
            // Verifica si el valor es un número válido
            return /^\d+(\.\d+)?$/.test(value);
        }
    }, [inputHoras.horas])// eslint-disable-line react-hooks/exhaustive-deps

    //Validar que el  usuario no se exceda del máximo de horas permitidos

    var permisoRegistrar = false
    if (showElements === true) {
        if (fechaError === false && fecha !== null && clienteError === false && cliente !== '' && proyectoError === false && proyecto !== '' && tipoActividadError === false && tipoActividad !== ''
            && selectedTipoActividadComplete !== null && areaError === false && selectedAreaComplete !== null && (area !== '' && sistemaError === false && selectedSistemaComplete !== null && sistema !== '' && ticketError === false && actividadError === false && actividad !== ''

                && horasError === false && errorDateStartUserSession === false) && horas !== '' && showErrorDataDuplicate === false && newErrorHours === false && (actividadBlur !== null && actividadBlur === true)
            && errorEntero === false && newErrorVacaciones === false && selectedDate?.length !== 0) {
            permisoRegistrar = true
        }
    } else {
        if (fechaError === false && fecha !== null && clienteError === false && cliente !== '' && proyectoError === false && proyecto !== '' && tipoActividadError === false && tipoActividad !== ''
            && selectedTipoActividadComplete !== null && areaError === false && selectedAreaComplete !== null && area !== '' && sistemaError === false && selectedSistemaComplete !== null && sistema !== ''
            && showErrorDataDuplicate === false && errorDateStartUserSession === false && newErrorHours === false && (actividadBlur !== null && actividadBlur === true)
            && errorEntero === false && newErrorVacaciones === false && data.length !== 0) {


            permisoRegistrar = true
        }
    }

    var disabledSelectionButtons = true
    if (fechaError === false && fecha !== null && clienteError === false && cliente !== '' && proyectoError === false && proyecto !== '' && tipoActividadError === false && tipoActividad !== ''
        && selectedTipoActividadComplete !== null && areaError === false && selectedAreaComplete !== null && (area !== '' && sistemaError === false && selectedSistemaComplete !== null && sistema !== '') && errorDateStartUserSession === false) {
        disabledSelectionButtons = false
    }

    return (

        <div className="">
            <Toast ref={toast} />
            <div>
                <Button className="p-button-warning" icon="pi pi-clone" onClick={() => onClick('displayBasic')}
                    tooltip="Copiar Actividad" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                ></Button>

                <MensajeTipoActividad
                    mostrarMensajeProp={ValidarTipoActividad}
                    mensajeProp={ValidarTipoActividadMensaje}
                />
                <Dialog className="modal" header="Copiar Actividad" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }}  >
                            <div className="p-field p-col-12 p-md-6">

                                <label className="labels" >Cliente<span className="require">*</span></label>
                                <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="cliente"
                                    placeholder="Seleccione un cliente" filter
                                    className={clienteError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{clienteError ? mensajeCliente : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Proyecto<span className="require">*</span></label>
                                <Dropdown value={selectedProyecto} options={proyectos} onChange={onProyectoChange} name="proyecto" optionLabel="proyecto"
                                    placeholder="Seleccione un proyecto" disabled={disabledProyecto} filter
                                    className={proyectoError || dateValidError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{proyectoError ? mensajeProyecto : ""}</p>
                                <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels">Categoria<span className="require">*</span></label>
                                <AutoComplete value={selectedCategoryComplete} suggestions={filteredCategory} completeMethod={searchCategory} field="descripcion"
                                    onChange={onCategoryCompleteChange} placeholder="Seleccione una categoria" dropdown forceSelection filterMatchMode="startsWith"
                                    className={tipoActividadError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{tipoActividadError ? mensajeTipoActividad : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels" >Tipo Actividad<span className="require">*</span></label>
                                <AutoComplete value={selectedTipoActividadComplete} suggestions={filteredTipoActividad} completeMethod={searchTipoActividad} field="nombretipoactividad"
                                    onChange={onTipoActividadCompleteChange} placeholder="Seleccione un tipo de actividad" dropdown forceSelection filterMatchMode="startsWith"
                                    className={tipoActividadError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{tipoActividadError ? mensajeTipoActividad : ""}</p>
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels" >Área Negocio<span className="require">*</span></label>
                                <AutoComplete value={selectedAreaComplete} suggestions={filteredArea} completeMethod={searchArea} field="nombreareanegocio"
                                    onChange={onAreaCompleteChange} placeholder="Seleccione un área de negocio" dropdown forceSelection filterMatchMode="startsWith"
                                    className={areaError ? "p-invalid auto" : "auto"}
                                />
                                <p className="mensajeError">{areaError ? mensajeArea : ""}</p>
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels" >Sistema Aplicación<span className="require">*</span></label>
                                <AutoComplete value={selectedSistemaComplete} suggestions={filteredSistema} completeMethod={searchSistema} field="nombreaplicacion"
                                    onChange={onSistemaCompleteChange} placeholder="Seleccione un Sistema" dropdown forceSelection filterMatchMode="startsWith"
                                    className={sistemaError ? "p-invalid auto" : "auto"} disabled={disabledProyecto}
                                />
                                <p className="mensajeError">{sistemaError ? mensajeSistema : ""}</p>
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels" >Objetivo</label>
                                <AutoComplete value={selectedObjectivesServiceComplete} suggestions={filteredObjectivesService} completeMethod={searchObjectives} field="descripcion"
                                    onChange={objectivesOnChange} placeholder="Seleccione un Objetivo" dropdown forceSelection filterMatchMode="startsWith"
                                    disabled={disabledObjectives} className={"auto"}
                                />
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels" >Logro</label>
                                <AutoComplete value={selectedAchievementServiceComplete} suggestions={filteredAchievementService} completeMethod={searchAchievement} field="logro"
                                    onChange={achievementOnChange} placeholder="Seleccione un Logro" dropdown forceSelection filterMatchMode="startsWith"
                                    disabled={disabledAchivementAndDeliverable} className={"auto"}
                                />
                            </div>

                            <div className="p-field p-col-12 p-md-4">
                                <label className="labels" >Entregable</label>
                                <AutoComplete value={selectedDeliverablesServiceComplete} suggestions={filteredDeliverablesService} completeMethod={searchDeliverables} field="descripcion"
                                    onChange={deliverablesOnChange} placeholder="Seleccione un Entregable" dropdown forceSelection filterMatchMode="startsWith"
                                    disabled={disabledAchivementAndDeliverable} className={"auto"}
                                />
                            </div>
                        </form>

                    </div>
                    <BtnNotas
                        notesDescriptionProp={notesDescriptionProp}
                        setNoteDescriptionProp={setNoteDescription}
                        titleProp={'Nota'}
                        tooltipProp={'Agregar notas'}
                        footerProp={true}
                        btnCrearActividadProp={false}
                    />
                    <label className="labels" >¿Desea registrar el número de horas por fechas?<span className="require">*</span></label>
                    <ModalSelectedDates
                        enviarMensajeProp={'selecciono si'}
                        label="Si"
                        setLabelProp={setLabel}
                        setLabelProp2={setLabel2}
                        dataTypeActivityProp={dataCopied}
                        setDataCopiedRegisterProp={setNewData}
                        disabledProp={disabledSelectionButtons}
                        copyActivitiesProp={false}
                        setShowElementsProp={setShowElements}
                        setDisabledBtnNoProp={setDisabledBtnNo}
                        restartDataProp={restartData}
                        inputActividadProp={actividadProp}
                        setInputActividadProp={setInputActividad}
                        inputHoursProp={horasProp}
                        setInputHorasProp={setInputHoras}
                        setInputTicketProp={setInputTicket}
                        setSelectedDateProp={setSelectedDate}
                        detailPendingActivitiesProp={detailPendingActivitiesProp}
                        hoursPendigProp={hoursPendigProp}
                        objectDataProp={objectData}
                        setNewInputHoursProp={setNewInputHours}
                        fechaErrorProp={fechaError}
                        newErrorHoursProp={newErrorHours}
                        newMessageHoursProp={newMessageHours}
                        setNewErrorHoursProp={setNewErrorHours}
                        setDataProp={setData}
                        setActividadBlurProp={setActividadBlur}
                        newErrorVacacionesProp={newErrorVacaciones}
                        copiarActividadesProp={copiarActividadesProp}
                        dataProp={objectData}
                        idActividadProp={idActividadProp}
                        dataActivityProp={dataActivity}

                    />
                    <Button label='No' icon="pi pi-times" disabled={disabledSelectionButtons ? disabledSelectionButtons : disabledBtnNo} style={{ marginTop: '10px', marginLeft: '6px' }} onClick={() => {
                        setShowElements(true)
                        setValidarCambio(true)
                        setNewData([])
                        setLabel2('No')
                    }} />
                    <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                        {showErrorDataDuplicate === true ?
                            <Message severity="error" text={menssageDataDuplicate} />
                            :
                            ""
                        }
                    </div>
                    {showElements ?
                        <div className="p-fluid p-formgrid p-grid">

                            <form style={{ display: 'contents' }}  >

                                <div className="p-field p-col-12 p-md-4">
                                    <label className="labels" >Fecha única o múltiple<span className="require">*</span></label>
                                    <Calendar placeholder="Seleccione una o más fechas" value={selectedDate} onChange={onDateChange} selectionMode="multiple"
                                        dateFormat="dd-mm-yy" locale="es" className={fechaError || errorDateStartUserSession ? "p-invalid" : ""} maxDate={maxDates} onHide={onHideDate} />
                                    <p className="mensajeError">{fechaError ? mensajeFecha : ""}</p>
                                    <p className="mensajeError">{errorDateStartUserSession ? errorMessageDateStartUserSession : ""}</p>

                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <label className="labels" >Actividad<span className="require">*</span></label>
                                    <InputTextarea rows={5} cols={30} value={inputActividad.actividad} keyfilter={/^[^<>°|'{}]+$/}
                                        onChange={onActividadChange} onBlurCapture={onActividadBlur} name="actividad" placeholder="Describa la Actividad que realizó"
                                        className={actividadError ? "p-invalid" : ""} autoResize

                                    />
                                    <p className="mensajeError">{actividadError ? mensajeActividad : ""}</p>
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label className="labels">Req.Ticket</label>
                                    <InputText id="ticket" type="text" name="ticket" keyfilter={/^[^<>*!=°":;_|(#$%&´¨)+-/?¡¿'{}]+$/} onChange={onTicketChange}
                                        placeholder=" Registre un ticket"
                                        className={ticketError ? "p-invalid" : ""}
                                    />
                                    <p className="mensajeError">{ticketError ? mensajeTicket : ""}</p>
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label className="labels" >Número Horas<span className="require">*</span></label>
                                    <InputText id="horas" type="text" name="horas" keyfilter={/^[0-9]*\.?[0-9]*$/} onChange={onHorasChange}
                                        placeholder="Registre el número de horas" value={inputHoras.horas}

                                        onCut={handleChange} onCopy={handleChange} className={horasError || newErrorHours || errorEntero ? "p-invalid" : ""} onPaste={handleChange}

                                    />
                                    <p className="mensajeError">{horasError ? mensajeHoras : ""}
                                        {newErrorHours ? newMessageHours : ''}{errorEntero ? messageEntero : ""}</p>
                                </div>

                            </form>
                        </div>
                        :
                        null
                    }
                </Dialog>
            </div>
        </div>
    )
}
export default BtnCopiarActividad