import React from 'react'
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import IMG from '../../assets/images/image001.png';
import InterestedPersonTable from './TablesExportPdf/InterestedPersonTable';
import AchievementTable from './TablesExportPdf/AchievementsTable';
import DeliverablesTable from './TablesExportPdf/DeliverablesTable';
import DataOfficialTable from './TablesExportPdf/DataOfficialTable';
import DataApprovals from './TablesExportPdf/DataApprovals';
import DataTableTotalProjects from './TablesExportPdf/DataTableTotalProjects';
const ExportPdf = ({ periodProp, nameUserProp, interestedPersonsProp, projectsProp, achievementsProp, deliverableProp, servicesProp, observationsProp, dataOfficialProp,
    listInterestedPersonProp, listAchievementProp, listDeliverableProp, listServiceProp, objectiveProp }) => {

    const BORDER_COLOR = '#000'
    const ColorTableHeader = '#910ba0'

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        title: {
            fontSize: 23,
            textAlign: 'center',
            fontFamily: 'Helvetica',
            marginTop: '20px',
            color: BORDER_COLOR
        },
        author: {
            fontSize: 11,
            textAlign: 'center',
            margin: 12,
            fontFamily: 'Helvetica',
        },
        subtitle: {
            fontSize: 17,
            margin: 12,
            fontFamily: 'Helvetica',
            color: BORDER_COLOR
        },
        subtitle2: {
            fontSize: 13,
            margin: 12,
            fontFamily: 'Helvetica',
            color: BORDER_COLOR
        },
        text: {
            margin: 12,
            fontSize: 11,
            textAlign: 'justify',
            fontFamily: 'Helvetica',
            color: '#444'
        },
        image: {
            marginVertical: 8,
            marginHorizontal: 18,
            width: 170,
            height: 65
        },
        /* imageChart: {
            marginVertical: 15,
            marginHorizontal: 100,
        }, */
        header: {
            fontSize: 11,
            marginBottom: 20,
            textAlign: 'center',
            color: 'grey',
        },
        pageNumber: {
            fontSize: 8,
            textAlign: 'left',
            color: 'grey',
        },
        table: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader1: {
            width: 40 + '%',
            borderLeft: '1.4px',
            borderLeftColor: BORDER_COLOR,
            borderRight: '1.4px',
            borderRightColor: BORDER_COLOR,
            borderTop: '1.4px',
            borderTopColor: BORDER_COLOR,
            borderBottom: '1.4px',
            borderBottomColor: BORDER_COLOR
        },
        tableColHeader2: {
            width: 60 + '%',
            borderRight: '1.4px',
            borderRightColor: BORDER_COLOR,
            borderTop: '1.4px',
            borderTopColor: BORDER_COLOR,
            borderBottom: '1.4px',
            borderBottomColor: BORDER_COLOR
        },
        tableCol: {
            borderBottom: '1.4px',
            borderBottomColor: BORDER_COLOR,
        },

        tableCellHeader: {
            margin: 5,
            fontSize: 15,
            fontWeight: 500,
            textAlign: 'center',
            color: ColorTableHeader
        },

        tableCell: {
            margin: 5,
            fontSize: 9
        },
        textTable: {
            color: '#000',
            fontSize: 11,
            fontFamily: 'Helvetica',
        },
        newTextTable: {
            color: '#000',
            fontSize: 11,
            fontFamily: 'Helvetica',
            flexBasis: '50%',
        },
        newTextTable2: {
            color: '#000',
            fontSize: 11,
            fontFamily: 'Helvetica',
            flexBasis: '40%',
        },
        textCell: {
            color: '#444',
            fontSize: 11,
            fontFamily: 'Helvetica',
        },
        newTextCell: {
            color: '#444',
            fontSize: 11,
            fontFamily: 'Helvetica',
            flexBasis: '50%',
        },
        newTextCell2: {
            color: '#444',
            fontSize: 11,
            fontFamily: 'Helvetica',
            flexBasis: '40%',
        },
        footer: {
            position: 'absolute',
            fontSize: 8,
            bottom: 30,
            left: 40,
            right: 40,
            textAlign: 'left',
            borderTop: '1px'
        },
        footerS1: {
            margin: "auto",
            flexDirection: "row"
        },
        footerContents1: {
            width: 48 + '%',
        },
        footerContents2: {
            width: 48 + '%',
        },
        pageNumber2: {
            fontSize: 8,
            textAlign: 'right',
            color: 'grey',
        },

    });

    var arrayAllProjectsData = []

    projectsProp.forEach(element => {
        arrayAllProjectsData.push({
            project: element.project,
            id: element.id
        })
    });
    dataOfficialProp.forEach(element => {
        arrayAllProjectsData.push({
            project: element.proyecto,
            id: String(element.idproyecto)
        })
    });
    const tableProjects = {}
    const uniqueArrayAllProjectsData = arrayAllProjectsData.filter(function (object) {
        return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
    })

    var showServices
    var showProjects
    if (servicesProp !== undefined && servicesProp !== null) {
        var newArray = []
        servicesProp.forEach(element => {
            var result = listServiceProp.filter(obj => obj.id === element.id)
            result.forEach(x => {
                newArray.push(x)
            })
        });
        showServices = newArray.map(item => {
            return item.descripcion
        })
        showProjects = uniqueArrayAllProjectsData.map(item => {
            return item.project
        })
    }
    return (
        <Document>
            <Page style={styles.body}>
                
                    <View style={styles.table} fixed>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader1}>
                                <Image
                                    style={styles.image}
                                    src={IMG}
                                />
                            </View>
                            <View style={styles.tableColHeader2}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCellHeader}>SISTEMA DE GESTION DE CALIDAD</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Informe de estado del proyecto</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.title}>Informe del estado del proyecto</Text>
                    <Text style={styles.subtitle}>
                        1. Introducción.
                    </Text>
                    <Text style={styles.text}>
                        A continuación, resumimos el estatus de las principales actividades desarrolladas por el equipo de Linexperts
                        Consultoría empresarial S.A.S, durante
                        {periodProp !== null && periodProp !== undefined ? ' ' + periodProp.toLocaleString("es-US", { month: "long" }) + ' ' + periodProp.getFullYear() : ""},
                        logros e hitos significativos. Este documento está
                        dentro del método utilizado por Linexperts, para entregar a sus clientes un estatus de lo contratado.
                    </Text>
                    <Text style={styles.subtitle}>
                        2. Servicios prestados.
                    </Text>
                    <Text style={styles.text}>
                        Rol: Consultor {nameUserProp}, {showServices !== undefined ? showServices.join(', ') : ""}.
                    </Text>
                    <Text style={styles.text}>
                        Proyecto: {showProjects !== undefined ? showProjects.join(', ') : ""}.
                    </Text>
                    <Text style={styles.subtitle}>
                        3. Recomendaciones / Observaciones.
                    </Text>
                    <Text style={styles.text}>
                        A la fecha se han identificado las siguientes recomendaciones: {observationsProp}
                    </Text>
                    <Text style={styles.subtitle}>
                        4. Horas por proyecto.
                    </Text>
                    <View>
                        <DataTableTotalProjects
                            dataProp={dataOfficialProp}
                            stylesProp={styles}
                        />
                    </View>
                    <Text style={styles.subtitle}>
                        5. Aprobaciones.
                    </Text>
                    <View>
                        <DataApprovals
                            nameUserProp={nameUserProp}
                            stylesProp={styles}
                        />
                    </View>
                    <Text style={styles.subtitle} break></Text>
                    {uniqueArrayAllProjectsData !== undefined ?
                        uniqueArrayAllProjectsData.map((item, i) => {
                            return (
                                <View key={i}>
                                    <View >
                                        <View>
                                            <Text style={styles.subtitle2} >
                                                Datos registrados para el proyecto: {item.project}
                                            </Text>
                                            {interestedPersonsProp !== null ?
                                                <InterestedPersonTable
                                                    interestedPersonsProp={interestedPersonsProp}
                                                    idProjectProp={item.id}
                                                    stylesProp={styles}
                                                    listInterestedPersonProp={listInterestedPersonProp}
                                                />
                                                :
                                                <View></View>
                                            }
                                            {achievementsProp !== null ?
                                                < AchievementTable
                                                    achievementsProp={achievementsProp}
                                                    idProjectProp={item.id}
                                                    stylesProp={styles}
                                                    listAchievementProp={listAchievementProp}
                                                />
                                                :
                                                <View></View>
                                            }
                                            {deliverableProp !== null ?
                                                <DeliverablesTable
                                                    deliverableProp={deliverableProp}
                                                    idProjectProp={item.id}
                                                    stylesProp={styles}
                                                    listDeliverableProp={listDeliverableProp}
                                                    objectiveProp={objectiveProp}
                                                />
                                                :
                                                <View></View>
                                            }
                                            <Text style={styles.subtitle} break></Text>
                                            {dataOfficialProp !== undefined && dataOfficialProp.length !== 0 ?
                                                <DataOfficialTable
                                                    dataOfficialProp={dataOfficialProp}
                                                    idProjectProp={item.id}
                                                    stylesProp={styles}
                                                />
                                                :
                                                <View></View>
                                            }
                                        </View>
                                    </View>
                                </View>
                            )
                        })
                        :
                        <Text style={styles.subtitle}></Text>
                    }
                    <View style={styles.footer} fixed>
                        <View style={styles.footerS1}>
                            <View style={styles.footerContents1}>
                                <Text style={styles.pageNumber}>
                                    ELABORO: Equipo de Calidad
                                </Text>
                                <Text style={styles.pageNumber}>
                                    REVISO: Representante por la dirección al SGC
                                </Text>
                                <Text style={styles.pageNumber}>
                                    APROBO Gerente General
                                </Text>
                            </View>
                            <View style={styles.footerContents2}>
                                <Text style={styles.pageNumber2}>
                                    VERSION: 01
                                </Text>
                                <Text style={styles.pageNumber2}>
                                    FECHA: {periodProp !== null && periodProp !== undefined ? ' ' + periodProp.toLocaleString("es-US", { month: "long" }) + ' ' + periodProp.getFullYear() : ""}
                                </Text>
                                <Text style={styles.pageNumber2} render={({ pageNumber, totalPages }) => (
                                    `Pág. ${pageNumber} / ${totalPages}`
                                )} />
                            </View>
                        </View>
                    </View>
            </Page>
        </Document>
    )
}

export default ExportPdf