import React, {useRef, useState} from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import '../../assets/css/DataTable.css';

const VerProyectosAsociados = ({dataCollaboratorsProp}) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
   
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const dt = useRef(null);
    return (
        <>

            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Proyectos Asociados" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Proyectos asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable value={dataCollaboratorsProp} ref={dt}  className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para este usuario">
                                <Column header="Proyectos" field="proyecto.nombreproyecto"/>
                        </DataTable>

                    </div>
                </div>
            </Dialog>

        </>
    )
}
export default VerProyectosAsociados