import React, { useRef, useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import '../../assets/css/DataTable.css';
import VerProyectosAsociados from './VerProyectosAsociados'
import { InputText } from "primereact/inputtext";
import Services from "../../service/Services";
import { MultiSelect } from 'primereact/multiselect';
import { UseMedia } from "../Funciones";

const ColaboradoresEquipoTrabajo = ({ dataCollaboratorsProp, dataTeamWorkProp, servicesUsersProp }) => {

    const [dataOfTable, setDataOfTable] = useState([]);
    const [servicesPosition, setServicesPosition] = useState([]);
    const getServices = new Services();
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        if (displayBasic === true) {
            var newArr = []
            var allUsersWithTeam = []
            dataCollaboratorsProp.forEach(element => {
                allUsersWithTeam.push(element)
                var newIdUsers = element.usuario?.id || element.colaborador?.idusuario.id
                const result = servicesUsersProp.filter(function (obj) {
                    var idUsers = obj.idUsuario
                    return idUsers === newIdUsers
                })
                result.forEach(e => {
                    newArr.push(e)
                })
            })
            var newArray = newArr.map(e => {
                var status
                let equipoTrabajo

                var concatTeamWorkUsers = e.equipoTrabajoUsuario.concat(e.equipoTrabajoLider)

                concatTeamWorkUsers.forEach(i => {
                    if (i.estado === 1) {
                        status = 'Vigente'
                    }
                    const result = allUsersWithTeam.filter(function (obj) {
                        var idTeam = obj.equipoTrabajo.id
                        return idTeam === i.equipoTrabajo.id
                    })
                    result.forEach(e => {
                        equipoTrabajo = e.equipoTrabajo.nombreequipotrabajo
                    })
                })
                return {
                    nombres: e.nombres + ' ' + e.apellidos,
                    proyectos: e.proyectos,
                    cargo: e.cargo.cargo,
                    idcargo: e.cargo.id,
                    estado: status,
                    equipotrabajo: equipoTrabajo
                }
            })
            setDataOfTable(newArray)

            getServices.getCargos().then(data => {
                if (data !== undefined) {
                    setServicesPosition(data)
                    setSelectedPosition(data.map(item => {
                        return {
                            cargo: item.cargo,
                            id: item.id
                        }
                    }))
                    setItemsSelectedPosition(data.length)
                }
            })
            setSelectedTeamWork(dataTeamWorkProp.map(item => {
                return { name: item.nombreequipotrabajo, id: item.id }
            }))
            setItemsSelectedTeamWork(dataTeamWorkProp.length)
        }

    }, [dataCollaboratorsProp, servicesUsersProp, displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }
    const [itemsSelectedPosition, setItemsSelectedPosition] = useState(0);
    const [selectedPosition, setSelectedPosition] = useState(null)

    const getPositions = servicesPosition.map(item => {
        const av = { cargo: item.cargo, id: item.id }
        return av
    })

    const onChangePosition = (e) => {
        var cargo = e.value?.map(item => {
            return item.cargo
        })
        dt.current.filter(cargo, 'cargo', 'in');
        setSelectedPosition(e.value)
        if (e.value !== null) {
            setItemsSelectedPosition(e.value.length)
        } else {
            setItemsSelectedPosition(0)
        }
    }
    const [itemsSelectedTeamWork, setItemsSelectedTeamWork] = useState(0);
    const [selectedTeamWork, setSelectedTeamWork] = useState(null)
    const getTeamWork = dataTeamWorkProp.map(item => {
        const av = { name: item.nombreequipotrabajo, id: item.id }
        return av
    })
    const onChangeTeamWork = (e) => {
        var name = e.value?.map(item => {
            return item.name
        })
        dt.current.filter(name, 'equipotrabajo', 'in');
        setSelectedTeamWork(e.value)
        if (e.value !== null) {
            setItemsSelectedTeamWork(e.value.length)
        } else {
            setItemsSelectedTeamWork(0)
        }
    }
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const handleChange = (e) => {
        e.preventDefault();
    };
    const TeamWorkFilter = <MultiSelect value={selectedTeamWork} options={getTeamWork} onChange={onChangeTeamWork}
        maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTeamWork} Elementos Seleccionados`}
        placeholder="Seleccione" className="p-column-filter" showClear optionLabel="name" filter emptyFilterMessage="No hay registros"
        resetFilterOnHide={true}
    />

    const positionFilter = <MultiSelect value={selectedPosition} options={getPositions} onChange={(e) => onChangePosition(e)}
        maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedPosition} Elementos Seleccionados`}
        placeholder="Seleccione" className="p-column-filter" showClear optionLabel="cargo" filter emptyFilterMessage="No hay registros"
        resetFilterOnHide={true}
    />

    const header = (
        <div className="headerTable">
            <div className="p-field p-col-12 p-md-6">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    const Acciones = (rowData) => {
        return (
            <>
                <VerProyectosAsociados
                    dataCollaboratorsProp={rowData.proyectos}
                />
            </>
        )
    }
    let small = UseMedia("(max-width: 760px)");
    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Colaboradores y Proyectos" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Colaboradores de Equipo de Trabajo" visible={displayBasic} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo" style={{ overflow: 'hidden' }}>
                    <div className="card">
                        <DataTable value={dataOfTable} ref={dt} className="p-datatable-responsive-demo" paginator rows={10} globalFilter={globalFilter} header={header}
                            emptyMessage="No hay asociaciones registradas para este usuario" filterDisplay="row" scrollable={small ? false : true}
                        >
                            <Column className="columna" style={{ minWidth: '16rem' }} headerStyle={{ width: '160px' }} header="Equipo de trabajo" field="equipotrabajo" sortable filter filterElement={TeamWorkFilter} showFilterMenu={false} />
                            <Column className="columna" headerStyle={{ width: '160px' }} header="Nombres" field="nombres" sortable />
                            <Column className="columna" style={{ minWidth: '16rem' }} headerStyle={{ width: '160px' }} header="Cargo" field="cargo" sortable filter filterElement={positionFilter} showFilterMenu={false} />
                            <Column className="columna" style={{ minWidth: '16rem' }} headerStyle={{ width: '160px' }} header="Estado" field="estado" sortable />
                            <Column className="columna" headerStyle={{ width: '180px' }} header="Acciones" body={Acciones} />
                        </DataTable>

                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default ColaboradoresEquipoTrabajo