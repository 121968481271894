import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import '../assets/css/Index.css'
import '../assets/css/Forms.css'
import '../assets/css/Recuperar.css'
import { Link } from 'react-router-dom'
import IMG from '../assets/images/image001.png';
import { Divider } from 'primereact/divider';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Services from '../service/Services';

const RecuperarContraseña = () => {

    const [inputUsuario, setInputUsuario] = useState({
        usuario: ''
    })

    const onUsuarioChange = (event) => {

        setInputUsuario({
            ...inputUsuario,
            [event.target.name]: event.target.value
        })
    }

    const toast = useRef(null);

    const usuario = inputUsuario.usuario
    const [validateInput, setValidateInput] = useState(false)
    const [validateMessage, setValidateMessage] = useState('')

    const [serviceUser, setServiceUser] = useState([])

    const getService = new Services()

    useEffect(() => {
        getService.getUsuarios().then(data => setServiceUser(data))
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const validateUserState = (user) => {
        var filterUser = serviceUser.filter(function (object) {
            return object.usuario === user
        })

        if (filterUser.length !== 0) {
            filterUser.forEach(element => {
                if (element.estado === 2) {
                    if (element.tipobloqueo.descripcion !== 'Exceso de intentos fallidos') {
                        toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'No puede recuperar la contraseña debido a que se encuentra bloquedo por un administrador', life: 8000 });
                    } else {
                        RecuperarContraseña()
                    }
                } else {
                    RecuperarContraseña()
                }
            })
        } else {
            RecuperarContraseña()
        }

    }

    const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/sendEmail?user=' + usuario

    const RecuperarContraseña = async () => {

        await axios.post(url,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(response => {
                //console.log(response.data)
                toast.current.show({ severity: 'success', summary: 'Validacion Satisfactoria', detail: "Información enviada exitosamente al correo electrónico registrado", life: 6000 });

            })
            .catch(function (error) {
                if (error.response.status === 404) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 3000 });
                }
            })

    }
    const validacion = (e,) => {
        e.preventDefault();
        e.target.reset()

        if (usuario === '') {
            setValidateInput(true)
            setValidateMessage('Debe ingresar un correo electronico')
        } else if (usuario.length <= 5) {
            setValidateInput(true)
            setValidateMessage('Debe ingresar un usuario de más de 5 caracteres')
        } else if (usuario.length > 60) {
            setValidateInput(true)
            setValidateMessage('Debe ingresar un usuario de menos de 60 caracteres')
        } else if (usuario.length >= 5 && usuario.length < 60) {
            var expReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
            var esValido = expReg.test(usuario)
            if (esValido === false) {
                setValidateInput(true)
                setValidateMessage('El correo ingresado no es válido')
            } else {
                setValidateInput(false)
                setValidateMessage('')
                validateUserState(usuario)
                /* RecuperarContraseña() */
            }
        } else {
            setValidateInput(false)
            setValidateMessage('')
        }
    }


    return (
        <div className="body">
            <div className="recuperar">
                <Toast ref={toast} />
                <img src={IMG} className="img" alt="" />
                <div className="Form">
                    <h3 className="Tittle" >Recuperar Contraseña</h3>
                    <Divider />
                    <form onSubmit={validacion}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" htmlFor="firstname6">Usuario</label>
                                <InputText id="firstname6" type="text"
                                    placeholder="Ingrese su correo electronico."
                                    name="usuario" keyfilter="email" onChange={onUsuarioChange}
                                    className={validateInput ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{validateMessage}</p>
                            </div>
                        </div>
                        <div className="contBtn">
                            <Link to="/" className="btn">Cancelar</Link>
                            <button className="btn">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )


}

export default RecuperarContraseña