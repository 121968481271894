import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn.js'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { Dropdown } from 'primereact/dropdown';
import Services from '../../service/Services';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const BtnActualizarAplicacion = ({ idAplicacionProp, aplicacionProp, creadoporProp, fechacreacionProp, clientProp, aplicacionesProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [inputId, setInputId] = useState({
        id: idAplicacionProp
    })

    const [servicioCliente, setServicioCliente] = useState([]);

    const getServices = new Services();

    useEffect(() => {
        if (displayBasic === true) {
            getServices.getClientes().then(data => setServicioCliente(data));
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps


    //Obtener datos del input aplicación

    const [inputAplicacion, setInputAplicacion] = useState({
        aplicacion: aplicacionProp
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onAplicacionChange = (event) => {

        setInputAplicacion({
            ...inputAplicacion,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }
    //Obtener datos del select tipo de clientes

    const [selectedCliente, setSelectedCliente] = useState({
        cliente: clientProp.razonsocial,
        id: clientProp.id
    });

    const clientes = servicioCliente.map(item => {
        const av = { cliente: item.razonsocial, id: item.id }
        return av
    })

    const onClienteChange = (e) => {
        setSelectedCliente(e.value);
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {

        setInputId({
            id: idAplicacionProp
        })
        setInputAplicacion({
            aplicacion: aplicacionProp
        })
        setSelectedCliente({
            cliente: clientProp.razonsocial,
            id: clientProp.id
        })

    }, [idAplicacionProp, aplicacionProp, clientProp])


    //Almacenamos los valores para enviarlos por props

    const idAplicacion = inputId.id
    const aplicacion = inputAplicacion.aplicacion
    const idCliente = selectedCliente.id
    const usuario = cookies.get('usuario')


    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {


            setInputAplicacion({
                aplicacion: aplicacionProp
            })
            setSelectedCliente({
                cliente: clientProp.razonsocial,
                id: clientProp.id
            })
            setValidarCambio(false)


            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idAplicacionProp={idAplicacion}
                        usuarioProp={usuario}
                        aplicacionProp={aplicacion}
                        idClienteProp={idCliente}
                        creadoporProp={creadoporProp}
                        fechacreacionProp={fechacreacionProp}
                    />
                    : ""
                }

            </div>
        );
    }
    //validaciones
    var aplicacionError = false
    var mensajeAplicaion = ''
    if (aplicacion === '') {
        aplicacionError = true
        mensajeAplicaion = 'Debes ingresar el nombre de la aplicación '
    } else if (aplicacion !== undefined) {
        if (aplicacion.length < 3) {
            aplicacionError = true
            mensajeAplicaion = 'El nombre de la aplicación debe ser mayor a 3 dígitos'
        } else if (aplicacion.length > 100) {
            aplicacionError = true
            mensajeAplicaion = 'El nombre de la aplicación debe ser menor a 100 dígitos'
        }
    }
    const [validateErrorDuplicity, setValidateErrorDuplicity] = useState(false)

    //Validar que no se cree una aplicacion igual a una creado
    useEffect(() => {
        let newArr = []
        if (inputAplicacion.aplicacion !== aplicacionProp) {
            aplicacionesProp.forEach(element => {
                let nameApp = removeAccents(element.nombreaplicacion)
                let newNameApp = removeAccents(inputAplicacion.aplicacion)
                if (nameApp.toLowerCase() === newNameApp.toLowerCase() && parseInt(element.idcliente.id) === parseInt(selectedCliente.id)) {
                    newArr.push(newNameApp)
                }
            })
        }
        if (newArr.length !== 0) {
            setValidateErrorDuplicity(true)
            toast.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Aplicación pre-existente' });
        } else {
            setValidateErrorDuplicity(false)
        }

    }, [inputAplicacion, selectedCliente.id])// eslint-disable-line react-hooks/exhaustive-deps

    //Validar que no hay ningun error
    var permisoActualizar = false
    if (aplicacionError === false && validateErrorDuplicity === false) {
        permisoActualizar = true
    }

    return (

        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>

            <Dialog className="modal" header="Actualizar Aplicación" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-2">
                            <label className="labels" >Id Aplicación</label>
                            <InputText id="id" type="text" name="id" disabled value={idAplicacion} />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label className="labels" >Aplicación<span className="require">*</span></label>
                            <InputText id="aplicacion" type="text" name="aplicacion" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onAplicacionChange} value={aplicacion}
                                placeholder="Registre el nombre de la aplicación"
                                className={aplicacionError || validateErrorDuplicity ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{aplicacionError ? mensajeAplicaion : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="cliente"
                                placeholder="Seleccione un cliente" filter emptyFilterMessage='Datos no encontrados'
                            />
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarAplicacion
