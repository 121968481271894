import React, { useState, useEffect } from "react";
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import ConfirmBtn from "./ConfirmBtn";
import { SumarDias, CambiarFormatoFecha } from "../Funciones"

const CambiarFecha = ({ dateStartProp, dateEndProp, dateStartProjectProp, dateEndProjectProp, idProp, 
    idProyectoProp, usuarioProp, runRenderProp, setRunRenderProp, estadoProp }) => {
    const [validateChange, setValidateChange] = useState(false)

    const [date3, setDate3] = useState(null)

    const onDateStartChange = (e) => {
        setDate3(e.value)
        setValidateChange(true)

    }

    const [date4, setDate4] = useState(null)

    const onDateEndChange = (e) => {
        setDate4(e.value)
        setValidateChange(true)
    }

    var d = new Date()
    var gtmHours = -d.getTimezoneOffset() / 60

    useEffect(() => {
        const dateStartChange = new Date(dateStartProp)

        if (dateStartProp) {
            if (gtmHours < 0) {
                setDate3(SumarDias(dateStartChange, +1))
            }
            else {
                setDate3(dateStartChange)
            }
        } else {
            setDate3(null)
        }

        if (dateEndProp !== null) {

            const dateEndChange = new Date(dateEndProp)

            if (gtmHours < 0) {
                setDate4(SumarDias(dateEndChange, +1))
            }
            else {
                setDate4(dateEndChange)
            }
        }
        else {
            setDate4(null)
        }


    }, [dateStartProp, dateEndProp, gtmHours])


    const [displayBasic, setDisplayBasic] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            const dateStartChange = new Date(dateStartProp)

            if (gtmHours < 0) {
                setDate3(SumarDias(dateStartChange, +1))
            }
            else {
                setDate3(dateStartChange)
            }

            if (dateEndProp !== null) {

                const dateEndChange = new Date(dateEndProp)

                if (gtmHours < 0) {
                    setDate4(SumarDias(dateEndChange, +1))
                }
                else {
                    setDate4(dateEndChange)
                }
            }
            else {
                setDate4(null)
            }

            setValidateChange(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header='Advertencia' visible={displayConfirmation}
                    modal style={{}} footer={renderFooter('displayConfirmation')}
                    onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    var sendArrayProject = [
        {
            id: idProp,
            estado: 0,
            fechainiciovigencia: dateStartProp,
            fechafinvigencia: dateEndProp,
            proyecto: {
                id: idProyectoProp
            }
        },
        {
            estado: date4 !== null ? CambiarFormatoFecha(date4) < CambiarFormatoFecha(new Date()) ? 0 : 1 : 1,
            fechainiciovigencia: date3 === null ? date3 : CambiarFormatoFecha(date3),
            fechafinvigencia: date4 === null ? date4 : CambiarFormatoFecha(date4),
            proyecto: {
                id: idProyectoProp
            }
        }
    ]

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    createPermission ?
                        <ConfirmBtn
                            titulo='Cambio de fechas'
                            mensaje='¿Desea registrar las fechas ingresadas?'
                            accion='ActualizarVigencia'
                            label='Guardar'
                            icono='pi pi-save'
                            updateDateProp={true}
                            usuarioProp={usuarioProp}
                            sendArrayProjectProp={sendArrayProject}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicOfficialProp={setDisplayBasic}
                            estadoProp={estadoProp}
                        />
                        :
                        ''
                }
            </div>
        );
    }

    //Validaciones
    var createPermission = false
    var dateStartError = false
    var messageDateStart = ''

    function validarRangoFechaProyecto() {
        var newDateStart = CambiarFormatoFecha(date3)
        var newDateEnd = CambiarFormatoFecha(date4)

        if (newDateStart >= dateStartProjectProp && newDateStart <= dateEndProjectProp &&
            newDateEnd >= dateStartProjectProp && newDateEnd <= dateEndProjectProp) {
            dateStartError = false
            messageDateStart = ""
        }
        else {
            dateStartError = true
            messageDateStart = "Las fechas establecidas están fuera del rango de las fechas del proyecto"
        }
    }

    function validarFechaInicio() {
        var newDateStart = CambiarFormatoFecha(date3)
        if (newDateStart < dateStartProjectProp || newDateStart > dateEndProjectProp) {
            dateStartError = true
            messageDateStart = "La fecha de inicio de vinculación, no está dentro del rango de las fechas del proyecto"
        }
    }

    if (date3 !== null && date4 !== null) {
        if (date3 > date4) {
            dateStartError = true
            messageDateStart = "La fecha de inicio debe ser menor a la fecha fin"
        }
        else {
            validarRangoFechaProyecto()
        }
    }

    if (date3 !== null && date4 === null) {
        validarFechaInicio()
    }

    if (date3 !== null && dateStartError === false) {
        createPermission = true
    }

    return (
        <>
            <Button icon='pi pi-sync' tooltip="Actualizar fecha de asociación y generar histórico" className="p-button-secondary" id="cambiar-fecha" onClick={() => onClick('displayBasic')} />
            <Dialog className="modal" header="Cambiar fecha de inicio y fin" visible={displayBasic}
                onHide={() => onHide('displayBasic')} style={{ width: '80vw' }} footer={renderFooter('displayBasic')}>

                <div className="p-fluid p-grid p-formgrid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="basic">Fecha de inicio<span className="require">*</span></label>
                        <Calendar className={dateStartError ? "p-invalid" : ""} placeholder="Seleccione una fecha" dateFormat="dd-mm-yy" locale="es" value={date3} onChange={(e) => onDateStartChange(e)}></Calendar>
                        <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="basic">Fecha final</label>
                        <Calendar dateFormat="dd-mm-yy" locale="es" placeholder="Seleccione una fecha" value={date4} onChange={(e) => onDateEndChange(e)}></Calendar>
                    </div>
                </div>
            </Dialog>

        </>
    );
}

export default CambiarFecha