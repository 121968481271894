import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import ExportPdf from './ExportPdf'

import Cookies from 'universal-cookie'
import Services from '../../service/Services';
const cookies = new Cookies()

const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/MonthlyReportData/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/MonthlyReportData/create'

const ConfirmBtn = ({ tittle, menssage, action, icon, classProp, label, tooltipProp, dataProp, statusProp, setRunRenderProp, runRenderProp,
    periodProp, nameUserProp, projectsProp, interestedPersonsProp, achievementsProp, deliverableProp, observationsProp, servicesProp, dataOfficialProp, chartDataProp,
    exportPDFFile, setSendPDFProp, reopenReportProp, setReloadProp, reloadProp, listInterestedPersonProp, listAchievementProp, listDeliverableProp, listServiceProp,
    reopenProp, anticipateProp, objectiveProp, getSelectedClientProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }
    const getService = new Services();

    const closeMonthlyReportData = async (data, status, reopenPeriod) => {
        const period = JSON.parse(data.periodo).period

        const json = JSON.stringify(
            {
                ...data,
                estado: status,
                validaciones: reopenPeriod === null ? null : JSON.stringify({
                    ...JSON.parse(data.validaciones),
                    reabrirperiodo: reopenPeriod

                })
            }
        )
        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                if (reopenPeriod === true) {
                    toast.current.show({ severity: 'success', summary: `El periodo ${period.toLocaleString("es-US", { month: "long" }) + ' ' + period.getFullYear()} cerrado temporalmente`, detail: '', life: 3000 });
                }

                setRunRenderProp(runRenderProp + 1);
                if (setReloadProp !== undefined) {
                    setReloadProp(reloadProp + 1)
                }
            })
            .catch(function (error) {
                //console.log(error.response)
                /* toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 }); */
            })
    }

    const validateReopenMonthlyReportData = () => {
        getService.getAllMonthlyReportData(dataProp.usuario.id).then(data => {
            if (label === 'Finalizar' || label === 'Reabrir informe') {
                const filterDataReopen = data.filter(obj => obj.estado === 3 && JSON.parse(obj.validaciones)?.reabrirperiodo === true && obj.cliente.id === getSelectedClientProp.id);
                const filterDataClose = data.filter(obj => obj.estado === 1 && obj.cliente.id === getSelectedClientProp.id);
                if (reopenProp && reopenProp === true) {
                    if (filterDataReopen.length !== 0) {
                        closeMonthlyReportData(filterDataReopen[0], 1, false);
                    }
                    if (filterDataClose.length !== 0) {
                        closeMonthlyReportData(filterDataClose[0], 3, true);
                    }
                } else {
                    if (filterDataReopen.length !== 0) {
                        closeMonthlyReportData(filterDataReopen[0], 1, false);
                    }
                    if (filterDataClose.length !== 0) {
                        closeMonthlyReportData(filterDataClose[0], 3, null);
                    }
                }
            }
        })
    }

    const service = () => {
        if (action === "Create") {
            saveMonthlyReportData()
            setDisabledButton(true)
        } else if (action === "Update") {
            if (dataProp.id !== undefined) {
                validateReopenMonthlyReportData();
            }
            updateMonthlyReportData()
            setDisabledButton(true)
        }
    }

    const idUsuario = cookies.get('idUsuario')
    const usuario = cookies.get('usuario')
    const saveMonthlyReportData = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                asignaciones: JSON.stringify(dataProp.asignaciones),
                cliente: {
                    id: dataProp.cliente.id,
                },
                creadopor: usuario,
                estado: 1,
                fechaactualizado: '',
                fechacreacion: new Date(),
                observaciones: dataProp.observaciones,
                periodo: dataProp.periodo,
                usuario: {
                    id: idUsuario,
                },
                servicios: dataProp.servicios,
                objetivos: dataProp.objetivos,
                personasinteresadas: dataProp.personasinteresadas,
                logros: dataProp.logros,
                entregables: dataProp.entregables
            }
        )
        /* console.log(json) */
        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current?.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                /* //console.log(response.data) */
                setDisplayConfirmation(false)
                setRunRenderProp(runRenderProp + 1)
                setDisabledButton(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                setDisabledButton(false)
                toast.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const updateMonthlyReportData = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                asignaciones: reopenReportProp !== undefined ? dataProp.asignaciones : JSON.stringify(dataProp.asignaciones),
                cliente: {
                    id: dataProp.cliente.id,
                },
                creadopor: dataProp.creadopor,
                estado: statusProp,
                fechaactualizado: new Date(),
                fechacreacion: dataProp.fechacreacion,
                id: dataProp.id,
                observaciones: dataProp.observaciones,
                periodo: dataProp.periodo,
                usuario: {
                    id: reopenReportProp !== undefined ? dataProp.usuario.id : idUsuario,
                },
                servicios: dataProp.servicios,
                objetivos: dataProp.objetivos,
                personasinteresadas: dataProp.personasinteresadas,
                logros: dataProp.logros,
                entregables: dataProp.entregables,
                validaciones: anticipateProp && anticipateProp ? JSON.stringify({
                    ...JSON.parse(dataProp.validaciones),
                    anticiparbtn: anticipateProp
                })
                    :
                    dataProp.validaciones
            }
        )
        /* console.log(json) */
        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current?.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                /* //console.log(response.data) */
                setDisplayConfirmation(false)
                setRunRenderProp(runRenderProp + 1)
                if (setReloadProp !== undefined) {
                    setReloadProp(reloadProp + 1)
                }
                if (exportPDFFile === true) {
                    setSendPDFProp(true)
                }
                setDisabledButton(false)
            })
            .catch(function (error) {
                setDisabledButton(false)
                //console.log(error.response)
                toast.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const [disabledButton, setDisabledButton] = useState(false)

    const viewPDF = (
        <ExportPdf
            periodProp={periodProp}
            nameUserProp={nameUserProp}
            projectsProp={projectsProp}
            interestedPersonsProp={interestedPersonsProp}
            achievementsProp={achievementsProp}
            deliverableProp={deliverableProp}
            observationsProp={observationsProp}
            servicesProp={servicesProp}
            dataOfficialProp={dataOfficialProp}
            chartDataProp={chartDataProp}
            listInterestedPersonProp={listInterestedPersonProp}
            listAchievementProp={listAchievementProp}
            listDeliverableProp={listDeliverableProp}
            listServiceProp={listServiceProp}
            objectiveProp={objectiveProp}
        />
    )

    const renderFooter = (name) => {
        var month
        if (periodProp !== null && periodProp !== undefined) {
            month = periodProp.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
        }

        return (
            <div>

                <>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    {exportPDFFile ?
                        <PDFDownloadLink document={viewPDF} fileName={`LX_Informe mensual individual${periodProp !== null && periodProp !== undefined ? '_' + month + '_' + periodProp.getFullYear() : ""}_${nameUserProp}_Versión 1`}
                            style={{ textDecorationLine: "none" }}>
                            {({ blob, url, loading, error }) =>
                                <Button label={loading ? 'Cargando documento...' : '¡Descargar ahora!'} icon="pi pi-check" onClick={() => service()} autoFocus
                                    disabled={loading ? true : false}
                                />
                            }
                            {/* <Button label='Finalizar' icon="pi pi-file-pdf" className="btnAdd" /> */}

                        </PDFDownloadLink>
                        :
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                    }

                </>
            </div>
        );
    }
    const widthModal = {
        width: '50vw'
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={exportPDFFile ? widthModal : null} breakpoints={exportPDFFile ? { '1060px': '75vw' } : null} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>

                        {exportPDFFile ?
                            <div >
                                <div className="confirmation-content-modal">
                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                    <span>{menssage}</span>
                                </div>
                                <div>
                                    <PDFViewer style={{ width: '100%', height: '60vh' }}>
                                        {viewPDF}
                                    </PDFViewer>
                                </div>
                            </div>
                            :
                            <div className="confirmation-content-modal">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>{menssage}</span>
                            </div>
                        }
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn