import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Services from '../../service/Services';
import BtnCrearRol from './BtnCrearRol'
import BtnActualizarRol from './BtnActualizarRol'
import VerPermisos from './VerPermisos'
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/DataTable.css';
import axios from 'axios';


const ConsultarRoles = ({ permisosProp }) => {

    const [servicio, setServicio] = useState([]);
    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    useEffect(() => {
        const source = axios.CancelToken.source()
        getServices.getRoles().then(data => {
            if (data !== undefined) {
                setServicio(data.map(item => {
                    var status
                    switch (item.estado) {
                        case 1:
                            status = 'Activo'
                            break;
                        case 2:
                            status = 'Inactivo'
                            break;
                        default:
                            break;
                    }
                    return {
                        ...item,
                        sendStatus: item.estado,
                        estado: status
                    }
                }))
                setLoading(false)
            }
        });

        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var permisoRolCreate
    if (permisosProp[9].permisos[0] === "Crear") {
        permisoRolCreate = true
    } else {
        permisoRolCreate = false
    }
    var permisoRolUpdate
    if (permisosProp[9].permisos[1] === "Editar") {
        permisoRolUpdate = true
    } else {
        permisoRolUpdate = false
    }
    var permisoRolDelete
    if (permisosProp[9].permisos[2] === "Eliminar") {
        permisoRolDelete = true
    } else {
        permisoRolDelete = false
    }

    const Acciones = (rowData) => {
        /*  console.log(rowData) */
        return (
            <>
                {permisoRolUpdate ?
                    <BtnActualizarRol
                        idRolProp={rowData.id}
                        rolProp={rowData.nombre}
                        permisosProp={rowData.permisos}
                        estadoProp={rowData.sendStatus}
                        creadoporProp={rowData.creadopor}
                        fechacreacionProp={rowData.fechacreacion}
                        checkedNotificationProp={rowData.notificaciones}
                        servicioProp={servicio}
                    />
                    :
                    ""
                }

                <VerPermisos
                    idRolProp={rowData.id}
                    rolProp={rowData.nombre}
                    permisosProp={rowData.permisos}
                    checkedNotificationProp={rowData.notificaciones}
                    checkedPlusPowerBiProp={rowData.pluspowerbi}
                />
                {permisoRolDelete ?
                    <ConfirmBtn
                        titulo="Confirmar Eliminación"
                        mensaje="¿Está seguro de eliminar rol?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        accion="Eliminar"
                        clase="p-button-danger"
                        icono="pi pi-trash"
                        idRolProp={rowData.id}
                        tooltipProp="Eliminar"
                    />
                    :
                    ""
                }
            </>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoRolCreate ?
                <BtnCrearRol
                    servicioProp={servicio}
                />
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={servicio} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                    emptyMessage="Rol no registrado en el sistema" loading={loading}>
                    <Column className="columna" field="id" sortable header="Id Rol" />
                    <Column className="columna" field="nombre" sortable header="Nombre Rol" />
                    <Column className="columna" field="estado" sortable header="Estado" />
                    <Column className="columna" header="Acciones" body={Acciones} />
                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarRoles