import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { PrepareDataToChart, PrepareDataToChartCategory, PrepareDataToChartTypeProject } from "../../../Funciones";

const colores = [
    "#42A5F5",
    "#66BB6A",
    "#8500e7",
    "#ffe601",
    "#de880a",
    "#DEB887",
    "#5F9EA0",
    "#7FFF00",
    "#D2691E",
    "#FF7F50",
    "#42A5F5",
    "#6495ED",
    "#00FFFF",
    "#00008B",
    "#008B8B",
    "#006400",
    "#00CED1",
    "#ADFF2F",
];

const DataTable1 = ({loadingProp, dataChart, setDataChart, setFuncionarioSelected, data, setChartDataCategory, setChartDataProp, individualMonthReportProp, setChartDataTypeProject }) => {
    const [funcionariosData, setFuncionariosData] = useState(data);
    const [dataPerPage, setDataPerPage] = useState(data);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const [itemSelected, setItemSelected] = useState();
    const [paginatorData, setPaginatorData] = useState({
        first: 0,
        page: 0,
        pageCount: 2,
        rows: 1,
    });
    useEffect(() => {
        paginator();
        expandAll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        expandAll();
    }, [dataPerPage, paginatorData]); // eslint-disable-line react-hooks/exhaustive-deps

   
    useEffect(() => {
        setFuncionariosData(data);
        setPaginatorData({
            first: 0,
            page: 0,
            pageCount: 2,
            rows: 1,
        });
        const newData = data.slice(0, 1);
        setDataPerPage(newData);
        if (data.length > 0) {
            handleSelectRow({ value: newData[0] })
        } else {
            handleSelectRow({ value: undefined });
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    //logica del paginador
    const paginator = (page = paginatorData) => {
        //inicio de la extraccion
        const init = page.first;
        const fin = page.first + paginatorData.rows;

        const newData = funcionariosData.slice(init, fin);
        setDataPerPage(newData);
        handleSelectRow({ value: newData[0] });
    };

    const onRowExpand = (event) => { };

    const onRowCollapse = (event) => { };

    const expandAll = () => {
        let _expandedRows = {};
        dataPerPage.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const handleSelectRow = ({ value }) => {

        if (value !== undefined) {
            const valueArray = [value]
            const { labelsTypeProject, dataTypeProject } = PrepareDataToChartTypeProject(valueArray.map(item => {
                return {
                    nombreTipoProyecto: item.nombreTipoProyecto,
                    porcentajeHoras: parseInt(item.totalHoras)
                }
            }
            ), false)
            if (value.hasOwnProperty("detalle")) {
                const { labels, data } = PrepareDataToChart(value.detalle, false);
                const { labelsCategory, dataCategory } = PrepareDataToChartCategory(value.detalle, false);
                const newData = () => ({
                    labels: labels,
                    datasets: [
                        {
                            ...dataChart.datasets[0],
                            data: data,
                            backgroundColor: colores,
                        },
                    ],
                });
                const newDataCategory = () => ({
                    labels: labelsCategory,
                    datasets: [
                        {
                            ...dataChart.datasets[0],
                            data: dataCategory,
                            backgroundColor: colores,
                        },
                    ],
                });

                const newDataTypeProject = () => ({

                    labels: labelsTypeProject,

                    datasets: [

                        {
                            data: dataTypeProject,
                            backgroundColor: colores,

                        },

                    ],

                });

                if (dataChart.datasets !== undefined) {
                    setDataChart(newData());
                    setChartDataCategory(newDataCategory());
                    if (setChartDataTypeProject !== undefined) {
                        setChartDataTypeProject(newDataTypeProject())
                    }
                    if (individualMonthReportProp) {
                        setChartDataProp(newData())
                    }
                }
                setItemSelected(value);

                setFuncionarioSelected(value.proyecto);
            }
        } else {
            setDataChart({});
            setChartDataCategory({});
            if (setChartDataTypeProject !== undefined) {
                setChartDataTypeProject({})
            }
        }
    };

    //total porcentaje body template
    const totalPorcentajeBodyTemplate = (rowData) => {
        return rowData.totalPorcentaje + " %";
    };
    //total porcentaje tiio actividad body template
    const totalPorcentajeTipoActividadBodyTemplate = (rowData) => {
        return rowData.porcentajeHoras + " %";
    };

    //on page
    const onchangePage = (page) => {
        setPaginatorData({
            ...page,
        });
        paginator(page);
    };

    const rowExpansionTemplate = (data) => {
        
        return (
            <>
                <div className="orders-subtable">
                    <h5>Detalle por tipo actividad</h5>
                    <DataTable value={data.detalle} paginator rows={5}>
                        <Column field="categoria.descripcion" header="Categoría"></Column>
                        <Column field="tipoActividad" header="Tipo actividad"></Column>
                        <Column field="horas" header="Horas" sortable></Column>
                        <Column field="porcentajeHoras" header="Horas %" body={totalPorcentajeTipoActividadBodyTemplate} sortable></Column>
                    </DataTable>
                </div>
            </>

        );
    };
    
    return (
        <div className="datatable-rowexpansion-demo">
            <Toast ref={toast} />

            <div className="card">
                <DataTable value={funcionariosData} paginator rows={paginatorData.rows} dataKey="id" selection={itemSelected} expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} selectionMode={"single"} emptyMessage="No hay datos registrados"
                    onSelectionChange={handleSelectRow} rowExpansionTemplate={rowExpansionTemplate} onPage={onchangePage} first={paginatorData.first} responsiveLayout={"scroll"} breakpoint="960px" loading={loadingProp}>
                    <Column expander style={{ width: "3em" }} />
                    <Column field="nombreTipoProyecto" header="Tipo Proyecto" />
                    <Column field="proyecto" header="Proyecto" />
                    <Column field="totalHoras" header="Total horas" sortable />
                    <Column field="horasNovedades" header="Total Horas Novedades" sortable />
                    <Column field="totalPorcentaje" header="Total %" body={totalPorcentajeBodyTemplate} sortable />
                </DataTable>
            </div>
        </div>
    );
};

export default DataTable1;
