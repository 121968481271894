import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import BtnCrearPersonaInteresada from "../PersonasInteresadas/BtnCrearPersonaInteresada";
import BtnActualizarPersonaInteresada from "../PersonasInteresadas/BtnActualizarPersonasInteresadas";
import AssingProject from "./AssingProject"
import VerClientesProyectos from "./VerClientesProyectos";
import Services from "../../service/Services";
import ConfirmBtn from "../PersonasInteresadas/ConfirmBtn";
import { CambiarFormatoFecha, UseMedia } from '../Funciones';
import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();


const ConsultarPersonasInteresadas = ({ permissionsProp, individualMonthReportProp, fullTable, idProjectProp, projectProp, getClientsProp,
    showButtonCreateProp, selectedInterestedPersonCompleteProp, sendObjUserProp, setIdProjectProp, setSelectedInterestedPersonProp, objectMonthlyReportDataProp,
    readReportProp, listReportsUsersProp, setSelectedProjectProp, setDisabledIndexProp, disabledIndexProp, selectedInterestedPersonProp,
    sendInterestedPersonsProp, runRenderBlockProp, setRunRenderBlockProp, positionServiceProp, documentTypeServiceProp, clientServiceProp, projectsUserSessionProp,
    showAllDataProp, loadingProp, monthlyReportDataAllDataProp, getDataProjectsProp, periodDateStartProp, periodDateEndProp, servicesInterestedPersonProp }) => {

    const [interestedPerson, setInterestedPerson] = useState([]);
    const [loading, setLoading] = useState(true);

    const getServices = new Services()

    const [runRender, setRunRender] = useState(0)

    const usuario = cookies.get('usuario')
    const [redirect, setRedirect] = useState(false)
    const [valueUnique, setValueUnique] = useState(null)

    useEffect(() => {
        let source;
        if (showAllDataProp === true) {
            if (servicesInterestedPersonProp !== undefined) {
                var newData = servicesInterestedPersonProp.map(item => {
                    return {
                        actualizadopor: item.actualizadopor,
                        apellido: item.apellido,
                        cargo: item.cargo,
                        creadopor: item.creadopor,
                        empresa: item.empresa,
                        estado: item.estado,
                        fechaactualizado: item.fechaactualizado,
                        fechacreacion: item.fechacreacion,
                        id: item.id,
                        nombre: item.nombre,
                        nombrecompleto: item.nombre + ' ' + item.apellido,
                        numerodocumento: item.numerodocumento,
                        proyectos: item.proyectos,
                        tipodocumento: item.tipodocumento,
                        usuario: item.usuario,
                    }
                })
                setInterestedPerson(newData)
                setLoading(false)
            }
        }
        if (individualMonthReportProp === false) {
            source = axios.CancelToken.source()
            getServices.getInterestedPerson().then(data => {
                if (data !== undefined) {
                    var newData = data.map(item => {
                        return {
                            actualizadopor: item.actualizadopor,
                            apellido: item.apellido,
                            cargo: item.cargo,
                            creadopor: item.creadopor,
                            empresa: item.empresa,
                            estado: item.estado,
                            fechaactualizado: item.fechaactualizado,
                            fechacreacion: item.fechacreacion,
                            id: item.id,
                            nombre: item.nombre,
                            nombrecompleto: item.nombre + ' ' + item.apellido,
                            numerodocumento: item.numerodocumento,
                            proyectos: item.proyectos,
                            tipodocumento: item.tipodocumento,
                            usuario: item.usuario,
                        }
                    })
                    setInterestedPerson(newData)
                    setLoading(false)
                }
            })

        }

        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [runRender,showAllDataProp, servicesInterestedPersonProp, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (showAllDataProp === false) {
            if (fullTable === true) {
                var newData = sendInterestedPersonsProp.map(item => {
                    return {
                        actualizadopor: item.actualizadopor,
                        apellido: item.apellido,
                        cargo: item.cargo,
                        creadopor: item.creadopor,
                        empresa: item.empresa,
                        estado: item.estado,
                        fechaactualizado: item.fechaactualizado,
                        fechacreacion: item.fechacreacion,
                        id: item.id,
                        nombre: item.nombre,
                        nombrecompleto: item.nombre + ' ' + item.apellido,
                        numerodocumento: item.numerodocumento,
                        proyectos: item.proyectos,
                        tipodocumento: item.tipodocumento,
                        usuario: item.usuario,
                    }
                })
                if (readReportProp === true) {
                    if (listReportsUsersProp.length !== 0) {
                        var interestedPerson = JSON.parse(listReportsUsersProp[0].personasinteresadas)
                        var listInterestedPerson = []
                        interestedPerson.forEach(element => {
                            const result = newData.filter(function (object) {
                                var id = parseInt(object.id);
                                return id === element.id &&parseInt(element.proyecto) === parseInt(idProjectProp);
                            });
                            result.forEach((element) => {
                                listInterestedPerson.push(element);
                            });
                        })
                       
                        const tableInterestedPerson = {}
                        const uniqueInterestedPerson = listInterestedPerson.filter(function (object) {
                            return tableInterestedPerson.hasOwnProperty(object.id) ? false : (tableInterestedPerson[object.id] = true)
                        })                        
                        setInterestedPerson(uniqueInterestedPerson)
                        setLoading(false)
                    }
                } else {
                    var interestedPersons = []
                    var newId = [idProjectProp]
                    newId.forEach(element => {
                        const result = newData.map(item => {
                            return item.proyectos.map(j => {
                                var boolean
                                if (j.proyecto.id === parseInt(element)) {
                                    boolean = item
                                }
                                return boolean
                            })
                        })
                        result.forEach(i => {
                            i.forEach(j => {
                                if (j !== undefined) {
                                    if (j.length !== 0) {
                                        interestedPersons.push(j)
                                    }
                                }
                            })
                        })

                    })
                    var newInterestedPersons = interestedPersons.map(item => {
                        return {
                            actualizadopor: item.actualizadopor,
                            apellido: item.apellido,
                            cargo: item.cargo,
                            creadopor: item.creadopor,
                            empresa: item.empresa,
                            estado: item.estado,
                            fechaactualizado: item.fechaactualizado,
                            fechacreacion: item.fechacreacion,
                            id: item.id,
                            nombre: item.nombre,
                            nombrecompleto: item.nombrecompleto,
                            numerodocumento: item.numerodocumento,
                            proyectos: item.proyectos,
                            proyecto: idProjectProp,
                            tipodocumento: item.tipodocumento,
                            usuario: item.usuario,
                        }
                    })
                    var newUniqueDataCreatedBy = newInterestedPersons.filter(function (object) {
                        var createdBy = object.creadopor
                        return createdBy === usuario
                    })
                    var newUniqueDataNoCreatedBy = newInterestedPersons.filter(function (object) {
                        var createdBy = object.creadopor
                        return createdBy !== usuario
                    })
                    var sendData = newUniqueDataCreatedBy.concat(newUniqueDataNoCreatedBy)
                    let getNotCurrent = []
                    let getCurrent = []
                    const newPeriodDateStartProp = periodDateStartProp!==null? CambiarFormatoFecha(periodDateStartProp):periodDateEndProp
                    const newPeriodDateEndProp = periodDateEndProp!==null? CambiarFormatoFecha(periodDateEndProp):periodDateEndProp

                    sendData.forEach(z => {
                        const result = z.proyectos.filter(i => {
                            return i.proyecto.id === idProjectProp
                        })
                        result.forEach(i => {
                            if (i.estado === 0) {
                                if (i.fechainiciovigencia >= newPeriodDateStartProp && i.fechainiciovigencia <= newPeriodDateEndProp) {
                                    getNotCurrent.push(z)
                                }
                                if (i.fechafinvigencia >= newPeriodDateStartProp && i.fechainiciovigencia <= newPeriodDateEndProp) {
                                    getNotCurrent.push(z)
                                }
                            } else if (i.estado === 1) {
                                if (i.fechafinvigencia !== null) {
                                    if (i.fechafinvigencia >= newPeriodDateStartProp && i.fechainiciovigencia <= newPeriodDateEndProp) {
                                        getCurrent.push(z)
                                    }
                                }
                                if (i.fechainiciovigencia !== null) {
                                    if (i.fechainiciovigencia >= newPeriodDateStartProp && i.fechainiciovigencia <= newPeriodDateEndProp) {
                                        getCurrent.push(z)
                                    }
                                } else {
                                    getCurrent.push(z)
                                }
                            }
                        })
                    })
                    const concatData = getCurrent.concat(getNotCurrent)
                    const newDataFiltered = [];
                    const uniqueCombos = {};
                    // Recorrer el array original y filtrar los datos
                    for (const data of concatData) {
                        const comboKey = `${data.id}-${data.proyecto}`;

                        if (!uniqueCombos[comboKey]) {
                            uniqueCombos[comboKey] = true;
                            newDataFiltered.push(data);
                        }
                    }
                    if (loadingProp === false) {
                        setInterestedPerson(newDataFiltered)
                    }
                    setIdProjectProp(idProjectProp)
                    setLoading(false)
                }
            } else {
                getServices.getInterestedPerson().then((data) => {
                    var newData = data.map(item => {
                        return {
                            actualizadopor: item.actualizadopor,
                            apellido: item.apellido,
                            cargo: item.cargo,
                            creadopor: item.creadopor,
                            empresa: item.empresa,
                            estado: item.estado,
                            fechaactualizado: item.fechaactualizado,
                            fechacreacion: item.fechacreacion,
                            id: item.id,
                            nombre: item.nombre,
                            nombrecompleto: item.nombre + ' ' + item.apellido,
                            numerodocumento: item.numerodocumento,
                            proyectos: item.proyectos,
                            tipodocumento: item.tipodocumento,
                            usuario: item.usuario,
                        }
                    })
                    setInterestedPerson(newData)
                    setLoading(false)
                })
            }
        }

    }, [runRender, readReportProp, idProjectProp, listReportsUsersProp, sendInterestedPersonsProp, showAllDataProp, loadingProp, periodDateStartProp, periodDateEndProp]); // eslint-disable-line react-hooks/exhaustive-deps

    // columnas para el datatable responsive

    const dt = useRef(null);

    const [selectedInterestedPerson, setSelectedInterestedPerson] = useState(null);

    /*  console.log(selectedInterestedPersonProp) */

    const onRowSelect = (e) => {
        var newObject = {
            id: e.data.id,
            cargo: {
                cargo: e.data.cargo.cargo
            },
            empresa: e.data.empresa,
            proyecto: idProjectProp,
            nombrecompleto: e.data.nombrecompleto
        }
        setSelectedInterestedPersonProp([...selectedInterestedPersonProp, newObject])
    }

    const onRowUnselect = (e) => {
        const result = selectedInterestedPersonProp.filter(function (object) {
            var id = object.id
            var idProject = object.proyecto
            return id !== e.data.id || idProject !== e.data.proyecto
        })
        setSelectedInterestedPersonProp(result)
    }

    const onSelectedInterestedPersonChange = (e) => {

        var sendData = e.value.map(item => {
            return {
                id: item.id,
                cargo: {
                    cargo: item.cargo.cargo
                },
                empresa: item.empresa,
                proyecto: idProjectProp,
                nombrecompleto: item.nombrecompleto
            }
        })
        if (e.type === "all") {
            if (e.value.length !== 0) {
                var arrayData = selectedInterestedPersonProp.concat(sendData)
                var arrayDataMap = arrayData.map(item => {
                    return [JSON.stringify(item), item]
                })
                var arrayDataMapArr = new Map(arrayDataMap)
                var uniqueData = [...arrayDataMapArr.values()]
                setSelectedInterestedPersonProp(uniqueData)
            } else {
                setDisabledIndexProp(true)
                var newInterestedPersons = interestedPerson.map(item => {
                    return {
                        id: item.id,
                        cargo: {
                            cargo: item.cargo.cargo
                        },
                        empresa: item.empresa,
                        proyecto: idProjectProp,
                        nombrecompleto: item.nombrecompleto
                    }
                })
                var newData = []
                for (var i = 0; i < selectedInterestedPersonProp.length; i++) {
                    var equal = false;
                    for (var j = 0; j < newInterestedPersons.length & !equal; j++) {
                        if (selectedInterestedPersonProp[i]['id'] === newInterestedPersons[j]['id'] &&
                            selectedInterestedPersonProp[i]['proyecto'] === newInterestedPersons[j]['proyecto'])
                            equal = true;
                    }
                    if (!equal) newData.push(selectedInterestedPersonProp[i]);
                }
                setSelectedInterestedPersonProp(newData)
            }
        }
        setSelectedInterestedPerson(sendData)

        if (e.value.length !== 0) {
            setDisabledIndexProp(true)
        }
    }

    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined) {
            var arrayInterestedPerson = JSON.parse(objectMonthlyReportDataProp.personasinteresadas)
            var result = arrayInterestedPerson.filter(function (object) {
                var idProject = object.proyecto
                return String(idProject) === String(idProjectProp)
            })
            setSelectedInterestedPerson(result)
            setSelectedInterestedPersonProp(JSON.parse(objectMonthlyReportDataProp.personasinteresadas))
        } else {
            setSelectedInterestedPerson([])
            if (setSelectedInterestedPersonProp !== undefined) {
                setSelectedInterestedPersonProp([])
            }
        }
    }, [objectMonthlyReportDataProp, setSelectedInterestedPersonProp, idProjectProp])

    var permissionsInterestedPersonCreate
    if (permissionsProp[16].permisos[0] === "Crear") {
        permissionsInterestedPersonCreate = true
    } else {
        permissionsInterestedPersonCreate = false
    }
    var permissionsInterestedPersonUpdate
    if (permissionsProp[16].permisos[1] === "Editar") {
        permissionsInterestedPersonUpdate = true
    } else {
        permissionsInterestedPersonUpdate = false
    }
    var permissionsInterestedPersonDelete
    if (permissionsProp[16].permisos[2] === "Eliminar") {
        permissionsInterestedPersonDelete = true
    } else {
        permissionsInterestedPersonDelete = false
    }

    const acctions = (rowData) => {
        var uniqueFilterInterestedPerson = []
        if (monthlyReportDataAllDataProp) {
            var monthlyReportDataAllDataPropInterestedPerson = monthlyReportDataAllDataProp.map(item => {
                return {
                    personasinteresadas: JSON.parse(item.personasinteresadas)
                }
            })
            var filterInterestedPerson = []
            const result = monthlyReportDataAllDataPropInterestedPerson.map(item => {
                return item.personasinteresadas.map(j => {
                    var boolean
                    if (j.id === rowData.id) {
                        boolean = item
                    }
                    return boolean
                })

            })
            result.forEach(i => {
                i.forEach(j => {
                    if (j !== undefined) {
                        if (j.length !== 0) {
                            filterInterestedPerson.push(j)
                        }
                    }
                })
            })
            uniqueFilterInterestedPerson = [...new Set(filterInterestedPerson)]
        }

        var noDeleteInterestedPerson = false
        var messagDelete = '¿Está seguro de eliminar la persona interesada?, existen unas asociaciones con proyectos que también se eliminarán'
        if (rowData.creadopor !== usuario && permissionsInterestedPersonDelete === false) {
            noDeleteInterestedPerson = true
            messagDelete = 'No puede eliminar registros creados por otro usuario'
        } else if (uniqueFilterInterestedPerson.length !== 0) {
            noDeleteInterestedPerson = true
            messagDelete = 'No puede eliminar esta persona interesada por que ya ha sido usado por otro usuario'
        }

        //Validar que si no tengo permisos no pueda ver las asociaciones de los demás usuarios
        var permissionsAssociations
        if (permissionsInterestedPersonDelete === false && permissionsInterestedPersonUpdate === false && rowData.creadopor !== usuario) {
            permissionsAssociations = false
        } else {
            permissionsAssociations = true
        }
        return (
            <>
                {
                    readReportProp ?
                        null
                        :
                        <BtnActualizarPersonaInteresada
                            nameProp={rowData.nombre}
                            lastNameProp={rowData.apellido}
                            documentTypeProp={rowData.tipodocumento?.nombretipodocumento}
                            idDocumentTypeProp={rowData.tipodocumento?.id}
                            numberDocumentProp={rowData.numerodocumento}
                            projectsProp={rowData.proyectos}
                            positionProp={rowData.cargo.cargo}
                            idPositionProp={rowData.cargo.id}
                            companyProp={rowData.empresa}
                            idInterestedPersonProp={rowData.id}
                            creationDateProp={rowData.fechacreacion}
                            createdByProp={rowData.creadopor}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            projectsUserSessionProp={projectsUserSessionProp}
                            sendInterestedPersonsProp={sendInterestedPersonsProp}
                            idProjectProp={idProjectProp}
                            dataInterestedPersonsProp={interestedPerson}
                            validateUpdateProp={permissionsInterestedPersonUpdate}
                            setRedirectProp={setRedirect}
                            setValueUniqueProp={setValueUnique}
                        />
                }
                {
                    permissionsAssociations ?
                        <VerClientesProyectos
                            projectsProp={rowData.proyectos}
                            usuarioProp={rowData.id}
                            redirectProp={redirect}
                            valueUniqueProp={valueUnique}
                            setRedirectProp={setRedirect}
                            runRenderProp={runRender}
                            setRunRenderProp={setRunRender}
                            apellidoProp={rowData.apellido}
                            idCargoProp={rowData.cargo.id}
                            creadoPorProp={rowData.creadopor}
                            empresaProp={rowData.empresa}
                            fechaCreacionProp={rowData.fechacreacion}
                            nombreProp={rowData.nombre}
                            numeroDocumentoProp={rowData.numerodocumento}
                            idPersonInterestedProp={rowData.id}
                            idTipoDocumentoProp={rowData.tipodocumento?.id}
                            newUsuarioProp={rowData.usuario}
                            getDataProjectsProp={rowData.proyectos}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            individualMonthReportProp={individualMonthReportProp}
                            periodDateStartProp={periodDateStartProp}
                            periodDateEndProp={periodDateEndProp}
                        />
                        :
                        ''
                }


                {
                    readReportProp ?
                        null
                        :
                        <ConfirmBtn
                            tittle="Confirmar Eliminación"
                            menssage={messagDelete}
                            action="Eliminar"
                            label=""
                            classProp="p-button-danger"
                            icon="pi pi-trash"
                            tooltipProp="Eliminar"
                            idInterestedPerson={rowData.id}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            sendInterestedPersonsProp={sendInterestedPersonsProp}
                            noDeleteInterestedPersonProp={noDeleteInterestedPerson}
                            apellidoProp={rowData.apellido}
                            idCargoProp={rowData.cargo.id}
                            creadoPorProp={rowData.creadopor}
                            empresaProp={rowData.empresa}
                            fechaCreacionProp={rowData.fechacreacion}
                            nombreProp={rowData.nombre}
                            numeroDocumentoProp={rowData.numerodocumento}
                            idPersonInterestedProp={rowData.id}
                            idTipoDocumentoProp={rowData.tipodocumento?.id}
                            newUsuarioProp={rowData.usuario}
                            getDataProjectsProp={rowData.proyectos}
                            isDeletedProp={true}
                        />
                }
            </>
        )
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    // proceso para exportar datos por Excel

    const exportExcel = () => {
        const newArrWithOutId = sendInterestedPersonsProp.map(item => {
            const { id, ...rest } = item; // Usamos destructuring para excluir la propiedad 'id'
            return rest;
        })
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(newArrWithOutId);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const header = (
        <div className="headerTable">
            {permissionsInterestedPersonCreate ?
                selectedInterestedPersonCompleteProp === null || selectedInterestedPersonCompleteProp === '' ?
                    ""
                    :
                    showButtonCreateProp ?
                        readReportProp ?
                            null
                            :
                            <BtnCrearPersonaInteresada
                                individualMonthReportProp={individualMonthReportProp}
                                projectProp={projectProp}
                                idProjectProp={idProjectProp}
                                getClientsProp={getClientsProp}
                                setRunRenderProp={setRunRender}
                                runRenderProp={runRender}
                                runRenderBlockProp={runRenderBlockProp}
                                setRunRenderBlockProp={setRunRenderBlockProp}
                                projectsUserSessionProp={projectsUserSessionProp}
                                sendInterestedPersonsProp={sendInterestedPersonsProp}
                                dataInterestedPersonsProp={interestedPerson}
                            />
                        :
                        <AssingProject
                            companyProp={sendObjUserProp.company}
                            nameProp={sendObjUserProp.name}
                            lastNameProp={sendObjUserProp.lastName}
                            idPositionProp={sendObjUserProp.position}
                            numberProp={sendObjUserProp.numberDocument}
                            documentProp={sendObjUserProp.documentType}
                            projectsOldProp={sendObjUserProp.selectedProjectsOld}
                            idInterestedPersonProp={sendObjUserProp.id}
                            creationDateProp={sendObjUserProp.creationDate}
                            createdByProp={sendObjUserProp.createdBy}
                            individualMonthReportProp={true}
                            selectedInterestedPersonCompleteProp={sendObjUserProp.selectedInterestedPersonComplete}
                            idProjectProp={idProjectProp}
                            projectProp={projectProp}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            sendInterestedPersonsProp={sendInterestedPersonsProp}
                        />
                :
                ""
            }
            {showAllDataProp ?
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLSX" />
                :
                null
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    let small = UseMedia("(max-width: 760px)");
    return (
        <div className="card">
            {showAllDataProp ?
                <DataTable ref={dt} value={sendInterestedPersonsProp} dataKey="id" header={header} globalFilter={globalFilter} loading={loadingProp}
                    emptyMessage="Persona interesada no registrado en el sistema" paginator rows={10} responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                >
                    <Column className="columna" field="cliente" sortable header="Cliente" />
                    <Column className="columna" field="proyecto" sortable header="Proyecto" />
                    <Column className="columna" field="periodo" sortable header="Periodo" />
                    <Column className="columna" field="equipoTrabajo" sortable header="Equipo de Trabajo" />
                    <Column className="columna" field="nombreColaborador" sortable header="Nombre Colaborador" />
                    <Column className="columna" field="personainteresada" sortable header="Persona Interesada" />
                    <Column className="columna" field="empresa" sortable header="Empresa" />
                    <Column className="columna" field="cargo" sortable header="Cargo" />
                </DataTable>
                :
                <DataTable ref={dt} value={showAllDataProp ? sendInterestedPersonsProp : interestedPerson} dataKey="id" header={header} globalFilter={globalFilter}
                    emptyMessage="Persona interesada no registrado en el sistema" paginator rows={showAllDataProp ? 10 : 5} responsiveLayout={small ? "stack" : "scroll"}
                    breakpoint="760px" selection={readReportProp ? null : selectedInterestedPerson} onSelectionChange={readReportProp ? null : e => onSelectedInterestedPersonChange(e)}
                    selectionMode={individualMonthReportProp === true ? "checkbox" : null} onRowUnselect={readReportProp ? null : onRowUnselect}
                    onRowSelect={readReportProp ? null : onRowSelect} loading={individualMonthReportProp ? loadingProp : loading}
                >
                    {individualMonthReportProp === true ?
                        readReportProp ?
                            null
                            :
                            <Column selectionMode="multiple" style={{ maxWidth: '1rem' }} ></Column>
                        :
                        <></>
                    }
                    <Column className="columna" field={"nombrecompleto"} sortable header="Nombre" />
                    <Column className="columna" field="cargo.cargo" header="Cargo" />
                    <Column className="columna" field="empresa" header="Empresa" />
                    {individualMonthReportProp === true ?
                        <Column className="columna" field="creadopor" sortable header="Creado por" />
                        :
                        <></>
                    }
                    <Column header="Acciones" body={acctions} />
                </DataTable>
            }

            {individualMonthReportProp === true && disabledIndexProp === true && loadingProp === false ?
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                            <Message severity="warn" text="Debe guardar los datos para cambiar de pestaña" />
                        </div>
                    </div>
                </div>
                :
                null
            }
        </div>
    )
}

export default ConsultarPersonasInteresadas