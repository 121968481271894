import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import ConfirmBtn from "./ConfirmBtn";
import { CambiarFormatoFecha, SumarDias } from "../Funciones";

const BtnActualizarOtrosi = ({ idOtherProp, otherProp, idContractProp, dateStartValidityProp, dateEndValidityProp, creationDateProp, createdByProp, dateStartContractProp,
    setRunRenderProp, runRenderProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input servicio

    const [inputOther, setInputOther] = useState({
        other: ''
    })

    const onOtherChange = (event) => {

        setInputOther({
            ...inputOther,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    // calendar de fecha  inicio Otrosi

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidateChange(true)
    }

    /// calendar de fecha fin Otrosi

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;

    useEffect(() => {
        setInputOther({
            other: otherProp
        })
        const dateStartChange = new Date(dateStartValidityProp)
        const dateEndChange = new Date(dateEndValidityProp)

        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
            setDateEnd(SumarDias(dateEndChange, +1))
        } else {
            setDateStart(dateStartChange)
            setDateEnd(dateEndChange)
        }

    }, [otherProp, dateStartValidityProp, dateEndValidityProp, gmtHours])

    //Almacenamos los valores para enviarlos por props

    var other = inputOther.other

    const resetData = () => {
        setInputOther({
            other: otherProp
        })
        const dateStartChange = new Date(dateStartValidityProp)
        const dateEndChange = new Date(dateEndValidityProp)

        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
            setDateEnd(SumarDias(dateEndChange, +1))
        } else {
            setDateStart(dateStartChange)
            setDateEnd(dateEndChange)
        }
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }


    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage="¿Estás seguro de guardar esta información?"
                            action="UpdateOther"
                            label="Actualizar"
                            icon="pi pi-pencil"
                            idOtherProp={idOtherProp}
                            otherProp={other}
                            idContractProp={idContractProp}
                            statusProp={1}
                            dateStartProp={CambiarFormatoFecha(dateStart)}
                            dateEndProp={CambiarFormatoFecha(dateEnd)}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            updateDetailProp={false}
                            deleteDetailProp={true}
                        />
                        :
                        ""
                }
            </div>
        );
    }
    // validaciones

    var otherError = false
    var otherMessage = ''

    if (other !== '') {
        if (other.length < 10) {
            otherError = true
            otherMessage = 'La descripción del otrosi es demasiado corta'
        } else if (other.length > 500) {
            otherError = true
            otherMessage = 'La descripción del otrosi es demasiado larga'
        }
    }

    var dateStartError = false
    var dateStartMessage = ''

    if (dateStart !== null) {
        if (CambiarFormatoFecha(dateStart) < dateStartContractProp) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia otrosi no puede ser menor a la fecha inicio del contrato'
        }
    }

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        }
    }

    var updatePermission = false

    if (other !== '' && otherError === false && dateStart !== null & dateEnd !== null && dateStartError === false) {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar Otrosi" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Descripción OtroSi<span className="require">*</span></label>
                            <InputText type='text' value={other} onChange={onOtherChange} name="other" placeholder="Ingrese la descripción del otrosi"
                                className={otherError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{otherError ? otherMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha inicio vigencia otrosi<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha fin vigencia otrosi<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={""}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnActualizarOtrosi