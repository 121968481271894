import React from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import Parametrizar from '../componets/Calendario/Parametrizar/Parametrizar'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import '../assets/css/Sidebar.css'
import '../assets/css/DataTable.css'
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const ParametrizarCalendario = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[10].consultarCalendario === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled"> Parametrizar Calendario</span>
    )

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">
                    <h1 >Parametrizar Calendario</h1>
                </div>
                <div className="tabla">
                    <div className="p-grid" style={{ marginTop: '10px' }}>
                        <div className="p-col-12">
                            <Parametrizar />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParametrizarCalendario