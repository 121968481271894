import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Services from "../../service/Services";
import { EstandarFormatoFecha, SumarDias, UseMedia } from "../Funciones";
import BtnCrearContrato from "./BtnCrearContrato";
import BtnActualizarContrato from "./BtnActualizarContrato";
import ConfirmBtn from "./ConfirmBtn";
import ContratosServicios from "./ContratosServicios";
import ContratoOtrosi from "./ContratoOtrosi";

const ConsultarContratos = ({ permissionsProp }) => {

    const [contracts, setContracts] = useState([])
    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    const [runRender, setRunRender] = useState(0)

    useEffect(() => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;

        getServices.getContract().then(data => {
            var newData = data.map(item => {
                var dateStartChange
                var dateEndChange
                var dateContractChange
                if (gmtHours < 0) {
                    dateStartChange = SumarDias(new Date(item.fechainiciovigencia), 1)
                    dateEndChange = SumarDias(new Date(item.fechafinvigencia), 1)
                    dateContractChange = SumarDias(new Date(item.fechafirmacontrato), 1)
                } else {
                    dateStartChange = new Date(item.fechainiciovigencia)
                    dateEndChange = new Date(item.fechafinvigencia)
                    dateContractChange = new Date(item.fechafirmacontrato)
                }

                return {
                    actualizadopor: item.actualizadopor,
                    cliente: item.cliente,
                    contrato: item.contrato,
                    creadopor: item.creadopor,
                    estado: item.estado,
                    fechaactualizado: item.fechaactualizado,
                    fechacreacion: item.fechacreacion,
                    fechafinvigencia: EstandarFormatoFecha(dateEndChange),
                    fechafirmacontrato: EstandarFormatoFecha(dateContractChange),
                    fechainiciovigencia: EstandarFormatoFecha(dateStartChange),
                    sendDateEnd: item.fechafinvigencia,
                    sendDateContract: item.fechafirmacontrato,
                    sendDateStart: item.fechainiciovigencia,
                    id: item.id,
                    objetocontrato: item.objetocontrato,
                    servicios: item.servicios
                }
            })
            setContracts(newData)
            setLoading(false)
        })
    }, [runRender]) // eslint-disable-line react-hooks/exhaustive-deps

    const dt = useRef(null);

    var permissionsContractCreate
    if (permissionsProp[18].permisos[0] === "Crear") {
        permissionsContractCreate = true
    } else {
        permissionsContractCreate = false
    }
    var permissionsContractUpdate
    if (permissionsProp[18].permisos[1] === "Editar") {
        permissionsContractUpdate = true
    } else {
        permissionsContractUpdate = false
    }
    var permissionsContractDelete
    if (permissionsProp[18].permisos[2] === "Eliminar") {
        permissionsContractDelete = true
    } else {
        permissionsContractDelete = false
    }

    const acctions = (rowData) => {
        /* console.log(rowData) */
        return (
            <>
                {permissionsContractUpdate ?
                    <BtnActualizarContrato
                        idProp={rowData.id}
                        clientProp={rowData.cliente}
                        contractProp={rowData.contrato}
                        createdByProp={rowData.creadopor}
                        statusProp={rowData.estado}
                        creationDateProp={rowData.fechacreacion}
                        dateEndProp={rowData.sendDateEnd}
                        dateStartProp={rowData.sendDateStart}
                        dateContractProp={rowData.sendDateContract}
                        objectContractProp={rowData.objetocontrato}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                    />
                    :
                    ""
                }
                <ContratosServicios
                    servicesProp={rowData.servicios}
                    idProp={rowData.id}
                    clientProp={rowData.cliente}
                    contractProp={rowData.contrato}
                    createdByProp={rowData.creadopor}
                    statusProp={rowData.estado}
                    creationDateProp={rowData.fechacreacion}
                    dateEndProp={rowData.sendDateEnd}
                    dateStartProp={rowData.sendDateStart}
                    dateContractProp={rowData.sendDateContract}
                    objectContractProp={rowData.objetocontrato}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                    permissionsContractCreateProp={permissionsContractCreate}
                    permissionsContractUpdateProp={permissionsContractUpdate}
                    permissionsContractDeleteProp={permissionsContractDelete}
                />

                {permissionsContractDelete ?
                    <ConfirmBtn
                        tittle="Confirmar Eliminación"
                        menssage="¿Está seguro de eliminar este contrato?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        action="Delete"
                        label=""
                        classProp="p-button-danger"
                        icon="pi pi-trash"
                        tooltipProp="Eliminar"
                        idProp={rowData.id}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                    />
                    :
                    ""
                }
                <ContratoOtrosi
                    idContractProp={rowData.id}
                    contractProp={rowData.contrato}
                    servicesProp={rowData.servicios}
                    dateStartContractProp={rowData.sendDateStart}
                    permissionsContractCreateProp={permissionsContractCreate}
                    permissionsContractUpdateProp={permissionsContractUpdate}
                    permissionsContractDeleteProp={permissionsContractDelete}
                />

            </>
        )
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permissionsContractCreate ?
                <BtnCrearContrato
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                />
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    let small = UseMedia("(max-width: 760px)");

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={contracts} dataKey="id" header={header} globalFilter={globalFilter}
                    emptyMessage="Contrato no registrado en el sistema" paginator rows={10} sortField="contrato" sortOrder={1}
                    responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px" loading={loading}
                >
                    <Column className="columna" field="cliente.razonsocial" sortable header="Cliente" />
                    <Column className="columna" field="contrato" sortable header="Contrato" />
                    <Column className="columna" field="objetocontrato" sortable header="Objeto del contrato" />
                    <Column className="columna" field="fechainiciovigencia" sortable header="Fecha inicio vigencia" />
                    <Column className="columna" field="fechafinvigencia" sortable header="Fecha fin vigencia" />
                    <Column className="columna" field="fechafirmacontrato" sortable header="Fecha firma contrato" />
                    <Column header="Acciones" body={acctions} />
                </DataTable>
            </div>
        </div>
    )
}

export default ConsultarContratos