import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import BtnCrearActividad from '../ConsultarActividades/BtnCrearActividad';
import Services from '../../service/Services';
import axios from 'axios';
import '../../assets/css/DataTable.css';
import Cookies from 'universal-cookie';
import { CambiarFormatoFecha, EstandarFormatoFecha, GetDates, GetParameterByName, SumarDias, obtenerHoraMinimasPorFecha } from '../Funciones';
const cookies = new Cookies();

const ConsultarDetallePendientes = ({ setLastHistoryProp, setShowTittleProp }) => {

    const [dataActivity, setDataActivity] = useState([]);
    const [servicioCalendario, setServicioCalendario] = useState([]);
    const [loading, setLoading] = useState(true);
    const [render, setRender] = useState(0)

    const getServices = new Services();
    const idUser = cookies.get('idUsuario')
    const usuario = cookies.get("usuario");

    const toast = useRef(null);

    const changeDates = (date) => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newDate = new Date(date)
        var sendDate
        if (gmtHours < 0) {
            sendDate = SumarDias(newDate, +1)
        } else {
            sendDate = newDate
        }
        return sendDate
    }

    const historicoHoras = sessionStorage.getItem("historicoHoras");
    const newHistoricoHoras = JSON.parse(historicoHoras);
   
    const rangeActivities = async (dateStart, dateEnd, newDateArray) => {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivity'
        const json = JSON.stringify({
            esadicional: "0",
            fecFinal: dateEnd,
            fecInicial: dateStart,
            usuario: usuario
        })
        await axios.post(url, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                /* toast.current.show({ severity: 'success', summary: 'Consulta Satisfactoria', detail: response.data.message, life: 3000 }); */
                /* //console.log(response.data) */
                // Ordenar respuesta del servicio para mostrar en la tabla
                var arrayData = []
                
                let horasPorFecha = {};

                var arrayFestivos = []
                var resultFestivos = servicioCalendario.filter(function (objeto) {
                    return objeto.pais === 'Colombia'
                })
            
                resultFestivos.forEach(element => {
                    var array = element.fechas.split(',')
                    arrayFestivos.push(array)
                })

                if (response.data.length !== 0) {
                    // mapear array de fechas 
                    var newArr = newDateArray.map(item => {
                        return {
                            fecha: item,
                            numerohoras: 0
                        }
                    })
                    

                    // unir array de fechas con registros retornados por el servicio
                    const arrayConcat = response.data.concat(newArr)

                    // acumular registros con la misma fecha para sumar sus horas
                    const accumulateActivities = []

                    // Recorro el array elemento por elemento
                    arrayConcat.forEach(function (a) {

                        if (!horasPorFecha.hasOwnProperty(a.fecha)) {
                            horasPorFecha[a.fecha] = 0;
                        }
    
                        horasPorFecha[a.fecha] += parseFloat(a.numerohoras);

                        // Me fijo si el elemento que voy a cargar ya existe, si no existe, lo creo con numerohoras en 0
                        if (!this[a.fecha]) {
                            this[a.fecha] = { fecha: a.fecha, numerohoras: 0 };
                            accumulateActivities.push(this[a.fecha]);
                        }
                        // Y luego le sumo el numerohoras (en el caso que ya exista, no se crea, solo se le suma el numerohoras)
                        this[a.fecha].numerohoras += a.numerohoras;

                        // Como segundo argumento de la funcion del foreach paso [] para que retorne un array.
                    }, []);

                    accumulateActivities.forEach(x => {
                        var sendDate = changeDates(x.fecha)
                        const horasMinimasPorFecha = obtenerHoraMinimasPorFecha(newHistoricoHoras, [x.fecha], arrayFestivos)
                        const tieneMinimaCero = validarHorasMinimasCero(horasMinimasPorFecha, x.fecha)

                        if (!tieneMinimaCero) {
                            if (horasPorFecha[x.fecha] < horasMinimasPorFecha[x.fecha]) {
                                // crear objeto con los campos a mostrar en la tabla
                                var obj = {}
                                obj.fecha = x.fecha
                                obj.fechaEst = EstandarFormatoFecha(sendDate)
                                obj.numerohoras = horasPorFecha[x.fecha]
                                obj.numerohoraspendientes = horasMinimasPorFecha[x.fecha] - horasPorFecha[x.fecha]
                                arrayData.push(obj)
                            }
                        }
                    })
                } else {
                    newDateArray.forEach(element => {
                        const horasMinimasPorFecha = obtenerHoraMinimasPorFecha(newHistoricoHoras, [element], arrayFestivos)
                                       

                        const tieneMinimaCero = validarHorasMinimasCero(horasMinimasPorFecha, element)
                        
                        if (!tieneMinimaCero) {
                            
                            var sendDate = changeDates(element)
                            var obj = {}
                            obj.fecha = element
                            obj.fechaEst = EstandarFormatoFecha(sendDate)
                            obj.numerohoras = 0
                            obj.numerohoraspendientes = horasMinimasPorFecha[element]
                            arrayData.push(obj)
                        }

                    })
                };
                setDataActivity(arrayData)
                if (arrayData.length === 0) {
                    setShowTittleProp(false)
                }
                setLoading(false)
            })
            .catch(function (error) {
                //console.log(error);
                if (error?.response?.status) {
                    toast?.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 4000 });
                }
                setLoading(false)
            })
    }

    const validarHorasMinimasCero = (horasMinimasPorFecha, fechas) => {
        let tieneMinimaCero = false
        if (parseFloat(horasMinimasPorFecha[fechas]) !== isNaN && parseFloat(horasMinimasPorFecha[fechas]) === 0) {
            tieneMinimaCero = true;
        }
        const horas = parseFloat(horasMinimasPorFecha[fechas]);

        // Primero, verifica si 'horas' es NaN. Si es así, no hagas nada.
        if (isNaN(horas)) {
            // Si 'horas' es NaN, simplemente continúa con el siguiente elemento.
            return; // Continúa con la siguiente iteración del bucle forEach.
        }

        // Si 'horas' es igual a 0, establece 'tieneMinimaCero' como verdadero.
        if (horas === 0) {
            tieneMinimaCero = true;
        }
        return tieneMinimaCero;
    }
    // obtener el valor del parámetro enviado por url
    var urlParam = GetParameterByName('tipoNotificacion');
    useEffect(() => {
        getServices.getCalendarios().then(data => {
            if (data !== undefined) {
                setServicioCalendario(data)
            }
        });

        getServices.getHistoricoUsuario(idUser).then(data => {
            /* console.log(data) */
            // cambia el formato de las fechas
            const convertDate = (date) => {
                var newDate = new Date(date)
                return newDate
            }
            // filtrar historico del usuario en sesión por tipo de notificación
            var newData
            if (urlParam !== '') {
                newData = data.filter(obj => obj.tipo === urlParam)
            } else {
                newData = data
            }
            // recorrer y insertar en un nuevo array los datos filtrados
            var dateSplit = []
            newData.forEach(element => {
                // Separar las fechas del rango para obtener fecha inicio y fin
                var arr = element.rango.split('-')
                // cambiar formato a las fechas 
                var newArr = arr.map(item => {
                    return convertDate(item)
                })
                // poner formato estandar
                var arrFormat = newArr.map(item => {
                    return EstandarFormatoFecha(item)
                })
                // cambiar formato a las fechas 
                var arrRAnge = newArr.map(item => {
                    return CambiarFormatoFecha(item)
                })
                // unir fechar para tener rango
                var arrUnion = arrFormat[0] + " / " + arrFormat[1]
                // llenar array con los nuevo datos modificados
                dateSplit.push({
                    fechaCreacion: element.fechaCreacion,
                    id: element.id,
                    numerohoras: element.numerohoras,
                    rango: arrUnion,
                    tipo: element.tipo,
                    dateStart: arrRAnge[0],
                    dateEnd: arrRAnge[1]
                })
            })
            // ordenar array de mayor a menor para obtener el último hostorico del usuario en sesión 
            dateSplit.sort(function (a, b) {
                var textA = a.id;
                var textB = b.id;
                return textB - textA
            })
            /* console.log(dateSplit[0]) */
            // cambiar formato a fecha inicio y fin 
            var newDateStart = changeDates(dateSplit[0].dateStart)
            var newDateEnd = changeDates(dateSplit[0].dateEnd)
            // llamar  función para obeter todas las fechas entre la fecha inicio y fin
            var dateArray = GetDates(newDateStart, newDateEnd);
            // eliminar días no habioles 
            var removeDates = []
            dateArray.forEach(element => {
                if (element.getDay() !== 6 && element.getDay() !== 0) {
                    removeDates.push(element)
                }
            })
            // cambiar formato de fechas para comparar con festivos
            var newDateArray = removeDates.map(item => {
                return CambiarFormatoFecha(item)
            })
            // servivio que trae los días festivos parametrizados para un país 
            getServices.getCalendarios().then(data => {
                // filtrar festivos para colombia
                var newDataHolidays = data.filter(obj => obj.pais === 'Colombia')
                var arrayDatesHolidays
                // recorrer datos retornados y cambiar formato para comparar con el rango de fechas
                newDataHolidays.forEach(element => {
                    var arr = element.fechas.split(',');
                    var newArr = arr.map(item => {
                        return new Date(item)
                    })
                    var formatArrayDates = newArr.map(item => {
                        return CambiarFormatoFecha(item)
                    })
                    arrayDatesHolidays = formatArrayDates
                })
                // filtrar elementos repetidos en ambos array y quitar los festivos 
                var newFilterData = []
                for (var i = 0; i < newDateArray.length; i++) {
                    var equal = false;
                    for (var j = 0; j < arrayDatesHolidays.length & !equal; j++) {
                        if (newDateArray[i] === arrayDatesHolidays[j])
                            equal = true;
                    }
                    if (!equal) newFilterData.push(newDateArray[i]);
                }
                // consultamos las activiades registradas en el rango de fecha del historico correspondinte 
                rangeActivities(dateSplit[0].dateStart, dateSplit[0].dateEnd, newFilterData)

            })
            setLastHistoryProp(dateSplit[0])
        }).catch(function (error) {
            setLoading(false)
            setShowTittleProp(false)
        })
    }, [render]); // eslint-disable-line react-hooks/exhaustive-deps


    const Acciones = (rowData) => {
        /* console.log(rowData) */
        return (
            <>
                <BtnCrearActividad
                    horasHoyProp={0}
                    detailPendingActivitiesProp={true}
                    hoursPendigProp={rowData.numerohoraspendientes}
                    datePendigProp={rowData.fecha}
                    setRenderDetailProp={setRender}
                    renderDetailProp={render}
                />
            </>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <Toast ref={toast} />
                <DataTable ref={dt} value={dataActivity} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                    emptyMessage="No se registran pendientes" loading={loading}>
                    <Column className="columna" field="fechaEst" sortable header="Fecha" />
                    <Column className="columna" field="numerohoras" sortable header="Horas registradas" />
                    <Column className="columna" field="numerohoraspendientes" sortable header="Horas por registrar" />
                    <Column header="Acciones" body={Acciones} />
                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarDetallePendientes