import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn'
import { Dropdown } from 'primereact/dropdown';
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';


const cookies = new Cookies();

const BtnCrearAplicacion = ({ aplicacionesProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [servicioCliente, setServicioCliente] = useState([]);

    const getServices = new Services();

    useEffect(() => {
        if (displayBasic===true) {   
            getServices.getClientes().then(data => setServicioCliente(data));
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [selectedCliente, setSelectedCliente] = useState({
        cliente: '',
        id: ''
    });

    const clientes = servicioCliente.map(item => {
        const av = { cliente: item.razonsocial, id: item.id }
        return av
    })

    const onClienteChange = (e) => {
        setSelectedCliente(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del input aplicacion

    const [inputAplicacion, setInputAplicacion] = useState({
        aplicacion: ''
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onAplicacionChange = (event) => {

        setInputAplicacion({
            ...inputAplicacion,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Almacenamos los valores para enviarlos por props

    const aplicacion = inputAplicacion.aplicacion
    const idCliente = selectedCliente.id
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {


            setInputAplicacion({
                aplicacion: ''
            })
            setSelectedCliente({
                cliente: '',
                id: ''
            })

            setValidarCambio(false)

            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registrar esta aplicación?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            aplicacionProp={aplicacion}
                            idClienteProp={idCliente}
                            usuarioProp={usuario}
                        />
                        :
                        ""
                }

            </div>
        );
    }

    //validaciones

    var aplicacionError = false
    var mensajeAplicacion = ''
    if (aplicacion.length < 3 && aplicacion !== '') {
        aplicacionError = true
        mensajeAplicacion = 'El nombre de la aplicación debe ser mayor a 3 dígitos'
    } else if (aplicacion.length > 100) {
        aplicacionError = true
        mensajeAplicacion = 'El nombre de la aplicación debe ser menor a 100 dígitos'
    }
    var validateErrorDuplicity = false
    function validateDuplicity() {
        aplicacionesProp.forEach(element => {
            const nameApp = removeAccents(element.nombreaplicacion)
            const newNameApp = removeAccents(aplicacion)

            if (nameApp.toUpperCase() === newNameApp.toUpperCase() && parseInt(element.idcliente.id) === parseInt(selectedCliente.id)) {
                validateErrorDuplicity = true
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Aplicación pre-existente'});
            }
        })
    }
    if (aplicacion !== '') {
        validateDuplicity()
    }

    //Validar que no hay ningun error
    var permisoRegistrar = false
    if (aplicacionError === false && aplicacion !== '' && selectedCliente.id !== '' && validateErrorDuplicity === false) {
        permisoRegistrar = true
    }

    return (

        <div className="">
            <div>
                <Toast ref={toast} />
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar Aplicación</button>

                <Dialog className="modal" header="Registrar Aplicación" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >

                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Aplicación<span className="require">*</span></label>
                                <InputText id="aplicacion" type="text" name="aplicacion" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onAplicacionChange}
                                    placeholder="Registre la aplicación"
                                    className={aplicacionError || validateErrorDuplicity ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{aplicacionError ? mensajeAplicacion : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Cliente<span className="require">*</span></label>
                                <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="cliente"
                                    placeholder="Seleccione un cliente" filter emptyFilterMessage='Datos no encontrados'
                                />
                            </div>
                        </form>

                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearAplicacion