import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { EstandarFormatoFecha, SumarDias, CambiarFormatoFecha } from '../Funciones';
import axios from 'axios';
import Cookies from 'universal-cookie';

const VerAsociaciones = ({dateStartMonthlyReportProp, dateEndMonthlyReportProp, idLogroProp}) => {
    const cookies = new Cookies();

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');
    const usuario = cookies.get('usuario')


    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const [servicesActivities, setServicesActivities] = useState([])
    useEffect(() => {
        const executeServices = async () => {

            const newDateStart = CambiarFormatoFecha(dateStartMonthlyReportProp)
            const newDateEnd = CambiarFormatoFecha(dateEndMonthlyReportProp)
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const json = JSON.stringify({
                esadicional: "0",
                fecFinal: newDateEnd,
                fecInicial: newDateStart,
                usuario: usuario
            })


            try {

                const response = await axios.post(url, json,
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        },
                    })
                const filter = response.data.filter(item => {
                    return item.idLogro === idLogroProp
                })

                const newData = await Promise.all(filter.map(async (item) => {
                    let newDate;
                    const gmtHours = -new Date().getTimezoneOffset() / 60;
                    if (gmtHours < 0) {
                        newDate = SumarDias(new Date(item.fecha), 1);
                    } else {
                        newDate = new Date(item.fecha);
                    }

                    return {
                        ...item,
                        fecha: EstandarFormatoFecha(newDate),
                        sendDate: item.fecha,
                    };
                }));
                setServicesActivities(newData)

            }
            catch (error) {

            }
        }

        if (dateStartMonthlyReportProp !== null && dateEndMonthlyReportProp !== null) {
            executeServices()
        }

    }, [idLogroProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp])// eslint-disable-line react-hooks/exhaustive-deps

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const header = (
        <div className="headerTable">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                    />
                </span>
            </div>
        </div>
    );

   
    return (
        <>
            <Button icon="pi pi-eye" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Asociaciones" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Asociaciones" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                

                <div className="datatable-responsive-demo">
                    <div className="card">

                        <DataTable ref={dt} value={servicesActivities} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage="No hay asociaciones registradas para este objetivo" header={header} globalFilter={globalFilter}>
                            <Column className="columna" field="fecha" header="Fecha" />
                            <Column className="columna" field="cliente.razonsocial" header="Cliente" />
                            <Column className="columna" field="proyecto.nombreproyecto" header="Proyecto" />
                            <Column className="columna" field="numerohoras" header="Numero Horas" />
                            <Column className="columna" field="descripcionactividad" header="Descripcion Actividad" />

                        </DataTable>


                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default VerAsociaciones