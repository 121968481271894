import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const ConfirmBtn = ({ projectsProp, idProjectsProp, descriptionObjectivesProp, idDescriptionObjectivesProp, detailAchievementProp, achievementProp, percentageProp, creadoPorProp, dateProp,
    fechaCreacionProp, idLogroProp, userProp, setRunRenderProp, runRenderProp, setShowModal, setDisplayBasicProp, noDeleteAchievementProp,
    tittle, menssage, action, icon, classProp, label, tooltipProp, courtComplianceProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [disabledButton, setDisabledButton] = useState(false);

    const service = () => {
        if (action === "Create") {
            CreateAchievement()
            setDisabledButton(true)
        } else if (action === "Update") {
            UpdateAchievement()
            setDisabledButton(true)
        } else if (action === "Delete") {
            setDisabledButton(true)
            DeleteAchievement()
        }
    }
    const urlRegistrar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement/create'

    const usuario = cookies.get('usuario')
    const CreateAchievement = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuario,
                detallelogro: detailAchievementProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                fechafinalizacion: dateProp,
                logro: achievementProp,
                cumplimientoalcorte: courtComplianceProp,
                objetivo: {
                    id: idDescriptionObjectivesProp
                },
                proyecto: {
                    id: idProjectsProp
                },
            }
        )
        /* console.log(json) */
        await axios.post(urlRegistrar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Se ha registrado la actividad', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (setShowModal) {
                    setShowModal(true)
                }
                if (setDisplayBasicProp) {
                    setDisplayBasicProp(false)
                }
                setDisplayConfirmation(false)
                setDisabledButton(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const urlUpadate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement/update'

    const UpdateAchievement = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: userProp,
                creadopor: creadoPorProp,
                detallelogro: detailAchievementProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: fechaCreacionProp,
                fechafinalizacion: dateProp,
                id: idLogroProp,
                logro: achievementProp,
                cumplimientoalcorte:courtComplianceProp,
                objetivo: {
                    id: idDescriptionObjectivesProp
                },
                proyecto: {
                    id: idProjectsProp
                },

            }
        )
        /* console.log(json) */

        await axios.post(urlUpadate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayBasicProp(false)
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement/delete?id=' + idLogroProp
    const DeleteAchievement = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })
    }

    const renderFooter = (name) => {
        return (
            <div>
                {noDeleteAchievementProp ?
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    :
                    <>
                        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                    </>
                }

            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{menssage}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn