import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Chart2 from './Chart2';

const DataTable2 = ({ dashBoardDataProp, loadingProp }) => {

    var newData = [dashBoardDataProp.proyecto]

    var data = []
    if (dashBoardDataProp.length !== 0) {

        newData.forEach(element => {

            element.datasets.sort((a, b) => {
                let [numA, mesA] = a.label.split(".");
                let [numB, mesB] = b.label.split(".");

                // Convertir el nombre del mes a minúsculas
                mesA = mesA.toLowerCase();
                mesB = mesB.toLowerCase();

                // Ordenar primero por número de mes y luego por nombre de mes
                if (numA !== numB) {
                    return parseInt(numA) - parseInt(numB);
                } else {
                    return mesA.localeCompare(mesB);
                }
            });

            element.labels.forEach((item, index) => {
                var json = {}
                var separados = item.split(' - ')
                json.proyecto = separados[0]
                json.funcionario = separados[1]
                json.mes1 = element.datasets[0].data[index]
                json.label1 = element.datasets[0].label
                json.mes2 = element.datasets[1].data[index]
                json.label2 = element.datasets[1].label
                json.mes3 = element.datasets[2].data[index]
                json.label3 = element.datasets[2].label
                json.porcentaje = element.pcj[index]
                data.push(json)
            })
        })

    }
    data.sort(function (a, b) {
        var textA = a.funcionario;
        var textB = b.funcionario;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    let header = (
        <div className="headerTable">

            <div className="p-fluid p-formgrid p-grid">

                <form style={{ display: 'contents' }} >
                   <span>Trimestre</span>
                </form>
            </div>
        </div>
    );

    const [rows1, setRows1] = useState(5);
    const [first1, setFirst1] = useState(0);
    const [page, setPage] = useState(0);

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setPage(event.page)
    }

    return (
        <>
            <div className="datatable-responsive-demo">
                <DataTable value={data} className="p-datatable-responsive-demo" header={header}
                   paginator rows={rows1} first={first1} onPage={onCustomPage1} emptyMessage="No hay datos para mostrar" loading={loadingProp}
                >
                    <Column className="columna" header="Funcionario" field="funcionario" ></Column>
                    <Column className="columna" header="Proyecto" field="proyecto" ></Column>
                    <Column className="columna" header={dashBoardDataProp.length !== 0 ? dashBoardDataProp.proyecto.datasets[0].label : ""} field="mes1" ></Column>
                    <Column className="columna" header={dashBoardDataProp.length !== 0 ? dashBoardDataProp.proyecto.datasets[1].label : ""} field="mes2" ></Column>
                    <Column className="columna" headerStyle={{ width: '135px' }} header={dashBoardDataProp.length !== 0 ? dashBoardDataProp.proyecto.datasets[2].label : ""} field="mes3" ></Column>
                </DataTable>
            </div>
            <div>
                <h2 className="tittleChart">{dashBoardDataProp.length === 0 ? "No hay datos para mostrar" : "Horas invertidas por proyecto"}</h2>
                {
                    dashBoardDataProp.length === 0 ?
                        ""
                        :
                        <Chart2
                            dashBoardDataProp={dashBoardDataProp}
                            pageProp={page}
                            datatableProp={data}
                        />
                }

            </div>
        </>
    );
}

export default DataTable2