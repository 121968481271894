import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Chart1 from './Chart1';
import { UseMedia,} from '../../../Funciones';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

const DataTable1 = ({ dashBoardDataProp, loadingProp }) => {

    var newData = dashBoardDataProp.usuarios
    
    var data = []

    if (dashBoardDataProp.length !== 0) {
        
        newData.forEach((element, indexuser) => {
            
            const newMeses = element.meses.sort((a, b) => {
                let [numA, mesA] = a.split(".");
                let [numB, mesB] = b.split(".");

                // Convertir el nombre del mes a minúsculas
                mesA = mesA.toLowerCase();
                mesB = mesB.toLowerCase();

                // Ordenar primero por número de mes y luego por nombre de mes
                if (numA !== numB) {
                    return parseInt(numA) - parseInt(numB);
                } else {
                    return mesA.localeCompare(mesB);
                }
            });

            element.datasets.sort((a, b) => {
                let [numA, mesA] = a.label.split(".");
                let [numB, mesB] = b.label.split(".");

                // Convertir el nombre del mes a minúsculas
                mesA = mesA.toLowerCase();
                mesB = mesB.toLowerCase();

                // Ordenar primero por número de mes y luego por nombre de mes
                if (numA !== numB) {
                    return parseInt(numA) - parseInt(numB);
                } else {
                    return mesA.localeCompare(mesB);
                }
            });

            element.labels.forEach((item, index) => {

                var json = {}
                json.funcionario = item
                json.mes1 = element.datasets[0].data[index]
                json.label1 = element.datasets[0].label
                json.mes2 = element.datasets[1].data[index]
                json.label2 = element.datasets[1].label
                json.mes3 = element.datasets[2].data[index]
                json.label3 = element.datasets[2].label
                // json.año = element.datasets[].data[index]
                json.porcentaje = element.pcj[index]
                json.meses = newMeses
                json.horasARegistrarMes = element.horasARegistrarMes[1]
                json.horasARegistrarMes2 = element.horasARegistrarMes[0]
                json.horasARegistrarMes3 = element.horasARegistrarMes[2]
                json.horasAdicionales = element.horasAdicionales[1]
                json.horasAdicionales2 = element.horasAdicionales[0]
                json.horasAdicionales3 = element.horasAdicionales[2]
                data.push(json)
            })
        })

    }

    data.sort(function (a, b) {
        var textA = a.funcionario;
        var textB = b.funcionario;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    let header = (
        <div className="headerTable">

            <div className="p-fluid p-formgrid p-grid">

                <form style={{ display: 'contents' }} >
                    <span>Trimestre</span>
                </form>
            </div>
        </div>
    );

    const [rows1, setRows1] = useState(5);
    const [first1, setFirst1] = useState(0);
    const [page, setPage] = useState(0);

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setPage(event.page)
    }

    const columnsConfig = [
        { header: 'Funcionario', field: 'funcionario' },
        { header: 'Horas a Trabajar', field: 'horasARegistrarMes' },
        { header: 'Horas Trabajadas', field: 'mes1' },
        { header: 'Horas Adicionales', field: 'horasAdicionales' },
    ];

    const columnsConfig2 = [
        { header: 'Horas a Trabajar', field: 'horasARegistrarMes2' },
        { header: 'Horas Trabajadas', field: 'mes2' },
        { header: 'Horas Adicionales', field: 'horasAdicionales2' },
    ];

    const columnsConfig3 = [
        { header: 'Horas a Trabajar', field: 'horasARegistrarMes3' },
        { header: 'Horas Trabajadas', field: 'mes3' },
        { header: 'Horas Adicionales', field: 'horasAdicionales3' },
    ];

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={data.length !== 0 ? data[0].meses[0] : ''} colSpan={4} />
                <Column header={data.length !== 0 ? data[0].meses[1] : ''} colSpan={3.9} />
                <Column header={data.length !== 0 ? data[0].meses[2] : ''} colSpan={4} />
            </Row>

            <Row>

                {columnsConfig.map((header, colIndex) => (
                    <Column key={colIndex} header={header.header} />
                ))}

                {columnsConfig2.map((header, colIndex) => (
                    <Column key={colIndex} header={header.header} />
                ))}

                {columnsConfig3.map((header, colIndex) => (
                    <Column key={colIndex} header={header.header} />
                ))}

                <Column header="Porcentaje total %" />
            </Row>

        </ColumnGroup>
    );

    let small = UseMedia("(max-width: 760px)");

    return (
        <>
            <div className="datatable-responsive-demo">
                <DataTable value={data} showGridlines className="p-datatable-responsive-demo" header={header}
                    paginator rows={rows1} first={first1} onPage={onCustomPage1}
                    emptyMessage="No hay datos para mostrar" loading={loadingProp}
                    responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                    headerColumnGroup={headerGroup}
                >
                    {columnsConfig.map((field, index) => (
                        <Column key={index} field={field.field} header={field.field === 'funcionario' ? 'Funcionario' : ''} />
                    ))}

                    {columnsConfig2.map((field, index) => (
                        <Column key={index} field={field.field} header={field.field === 'funcionario' ? 'Funcionario' : ''} />
                    ))}

                    {columnsConfig3.map((field, index) => (
                        <Column key={index} field={field.field} header={field.field === 'funcionario' ? 'Funcionario' : ''} />
                    ))}

                    <Column field="porcentaje" />
                </DataTable>
            </div>
            <div>
                <h2 className="tittleChart">{dashBoardDataProp.length === 0 ? "No hay datos para mostrar" : "Comportamiento carga de trabajo"}</h2>
                {
                    dashBoardDataProp.length === 0 ?
                        ""
                        :
                        <Chart1
                            dashBoardDataProp={dashBoardDataProp}
                            pageProp={page}
                            datatableProp={data}
                        />
                }

            </div>
        </>
    );
}

export default DataTable1