import React, { useEffect, useState, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { GenerarNuevoColor } from '../../../Funciones'

import { PrepareDataToChart, PrepareDataToChartCategory, PrepareDataToChartTypeProject } from "../../../Funciones";

const DataTableRowExpansionDemo = ({loadingProp, dataChart, setDataChart, setFuncionarioSelected, setProyectoSelected, data, agrupadoPor, setChartDataCategory, setChartDataTypeProject }) => {

	const [funcionariosData, setFuncionariosData] = useState(data);
	const [dataPerPage, setDataPerPage] = useState(data);
	const [expandedRows, setExpandedRows] = useState(null);
	const toast = useRef(null);
	const [itemSelected, setItemSelected] = useState();
	const [paginatorData, setPaginatorData] = useState({
		first: 0,
		page: 0,
		pageCount: 2,
		rows: 1,
	});

	const expandAll = useCallback(() => {
		let _expandedRows = {};
		dataPerPage.forEach((p) => (_expandedRows[`${p.id}`] = true));

		setExpandedRows(_expandedRows);
	}, [dataPerPage]);

	const handleSelectRow = useCallback(
		({ value }) => {
			if (value !== undefined) {
				// console.log(value)
				const valueArray = [value]
				const { labelsTypeProject, dataTypeProject } = PrepareDataToChartTypeProject(valueArray.map(item => {
					return {
						nombreTipoProyecto: item.nombreTipoProyecto,
						porcentajeHoras: parseInt(item.totalHoras)
					}
				}
				), false)
				if (value.hasOwnProperty("detalle")) {
					const { labels, data } = PrepareDataToChart(value.detalle, false);
					const { labelsCategory, dataCategory } = PrepareDataToChartCategory(value.detalle, false);

					var backgroundColors = []
					var backgroundColorsCategory = []
					var backgroundColorTypeProject = []
					data.forEach(element => {
						backgroundColors.push(GenerarNuevoColor())
						backgroundColorsCategory.push(GenerarNuevoColor())
						backgroundColorTypeProject.push(GenerarNuevoColor())
					})

					const newData = () => ({
						labels: labels,
						datasets: [
							{
								...dataChart.datasets[0],
								data: data,
								backgroundColor: backgroundColors
							},
						],
					});
					const newDataCategory = () => ({
						labels: labelsCategory,
						datasets: [
							{
								...dataChart.datasets[0],
								data: dataCategory,
								backgroundColor: backgroundColorsCategory,
							},
						],
					});
					const newDataTypeProject = () => ({

						labels: labelsTypeProject,

						datasets: [

							{
								data: dataTypeProject,
								backgroundColor: backgroundColorTypeProject,

							},

						],

					});
					setItemSelected(value);
					setDataChart(newData());
					setChartDataCategory(newDataCategory())
					setChartDataTypeProject(newDataTypeProject())
					setFuncionarioSelected(value.usuario)
					setProyectoSelected(value.proyecto)

				}
			} else {
				setDataChart({});
				setChartDataCategory({})
				setChartDataTypeProject({})
			}
		},
		[dataChart, setDataChart, setFuncionarioSelected, setProyectoSelected] // eslint-disable-line react-hooks/exhaustive-deps
	);

	useEffect(() => {
		paginator();
		expandAll();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		expandAll();
	}, [dataPerPage, paginatorData, expandAll]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFuncionariosData(data);
		setPaginatorData({
			first: 0,
			page: 0,
			pageCount: 2,
			rows: 1,
		});

		const newData = data.slice(0, 1);
		setDataPerPage(newData);

		if (data.length > 0) {
			handleSelectRow({ value: newData[0] });
		} else {
			handleSelectRow({ value: undefined });
		}
	}, [data]); // eslint-disable-line react-hooks/exhaustive-deps

	//logica del paginador
	const paginator = useCallback((page = paginatorData) => {
		//inicio de la extraccion
		const init = page.first;
		const fin = page.first + paginatorData.rows;

		const newData = funcionariosData.slice(init, fin);
		setDataPerPage(newData);
		handleSelectRow({ value: newData[0] });
	}, [paginatorData]); // eslint-disable-line react-hooks/exhaustive-deps

	const onRowExpand = (event) => { };

	const onRowCollapse = (event) => { };

	//total porcentaje body template
	const totalPorcentajeBodyTemplate = (rowData) => {
		return rowData.totalPorcentaje + " %";
	};
	//total porcentaje tiio actividad body template
	const totalPorcentajeTipoActividadBodyTemplate = (rowData) => {
		return rowData.porcentajeHoras + " %";
	};

	//on page
	const onchangePage = (page) => {
		setPaginatorData({
			...page,
		});
		paginator(page);
	};

	const rowExpansionTemplate = (data) => {
		return (
			<div className="orders-subtable">
				<h5>Detalle por tipo actividad</h5>
				{
					agrupadoPor === "Cliente - Proyecto"
						?
						<DataTable value={data.detalle}>
							<Column className="columna" field="usuario" header="Funcionario"></Column>
							<Column className="columna" field="categoria.descripcion" header="Categoría"></Column>
							<Column className="columna" field="tipoActividad" header="Tipo actividad"></Column>
							<Column className="columna" field="horas" header="Horas" sortable></Column>
							<Column className="columna" field="porcentajeHoras" header="Horas %" body={totalPorcentajeTipoActividadBodyTemplate} sortable></Column>
						</DataTable >
						:
						<DataTable value={data.detalle}>
							<Column className="columna" field="categoria.descripcion" header="Categoría"></Column>
							<Column className="columna" field="tipoActividad" header="Tipo actividad"></Column>
							<Column className="columna" field="horas" header="Horas" sortable></Column>
							<Column className="columna" field="porcentajeHoras" header="Horas %" body={totalPorcentajeTipoActividadBodyTemplate} sortable></Column>
						</DataTable >
				}
			</div >


		);
	};

	return (
		<div className="datatable-rowexpansion-demo">
			<Toast ref={toast} />

			<div className="card">
				<DataTable value={funcionariosData} paginator rows={paginatorData.rows} dataKey="id" selection={itemSelected} expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} selectionMode={"single"}
					onSelectionChange={handleSelectRow} rowExpansionTemplate={rowExpansionTemplate} onPage={onchangePage} first={paginatorData.first}
					emptyMessage="No hay registros" responsiveLayout={"scroll"} breakpoint="960px" scrollHeight={"400px"}
					loading={loadingProp}>
					<Column expander style={{ width: "3em" }} />
					<Column className="columna" field={agrupadoPor === "Cliente - Proyecto" ? "cliente" : "usuario"} header={agrupadoPor === "Cliente - Proyecto" ? "Cliente" : "Funcionario"} />
					<Column field="nombreTipoProyecto" header="Tipo Proyecto" />
					<Column className="columna" field="proyecto" header="Proyecto" />
					<Column className="columna" field="totalHoras" header="Total horas" />
					<Column className="columna" field="totalPorcentaje" header="Total %" body={totalPorcentajeBodyTemplate} />
				</DataTable>
			</div>
		</div>
	);
};

export default DataTableRowExpansionDemo