import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
const AchievementTable = ({ achievementsProp, idProjectProp, stylesProp, listAchievementProp }) => {

    var newAchievementsProp = achievementsProp.filter(function (object) {
        var idProject = object.proyecto
        return parseInt(idProjectProp) === parseInt(idProject)
    })

    var newArray = []
    newAchievementsProp.forEach(element => {
        var result = listAchievementProp.filter(obj => obj.id === element.id)
        result.forEach(x => {
            newArray.push(x)
        })
    });

    const tableAchievement = {}
    const uniqueAchievement = newArray.filter(function (object) {
        return tableAchievement.hasOwnProperty(object.id) ? false : (tableAchievement[object.id] = true)
    })
    
    return (
        <View>
            <Text style={stylesProp.subtitle2}>
                7.1 Actividades Desarrolladas y Logros Significativos.
            </Text>
            <Text style={stylesProp.text} break>
                A continuación, resumimos las principales actividades y logros más importantes de la Consultoría:
            </Text>
            <Table
                data={uniqueAchievement}
            >
                <TableHeader textAlign={'center'}>
                    <TableCell style={stylesProp.newTextTable}>
                        Objetivo
                    </TableCell>
                    <TableCell style={stylesProp.newTextTable2}>
                        Cumplimiento al Corte
                    </TableCell>
                    <TableCell style={stylesProp.newTextTable}>
                        Logro
                    </TableCell>
                    <TableCell style={stylesProp.newTextTable}>
                        Detalle del Logro
                    </TableCell>
                    <TableCell style={stylesProp.newTextTable2}>
                        Fecha Finalización
                    </TableCell>
                </TableHeader>
                <TableBody textAlign={"center"}>
                    <DataTableCell getContent={(r) => r.objetivo.descripcion} style={{ ...stylesProp.newTextCell }} />
                    <DataTableCell getContent={(r) => r.cumplimientoalcorte} style={stylesProp.newTextCell2} />
                    <DataTableCell getContent={(r) => r.logro} style={{ ...stylesProp.newTextCell}} />
                    <DataTableCell getContent={(r) => r.detallelogro} style={{ ...stylesProp.newTextCell }} />
                    <DataTableCell getContent={(r) => r.fechafinalizacion} style={stylesProp.newTextCell2} />
                </TableBody>
            </Table>
        </View>

    )
}

export default AchievementTable