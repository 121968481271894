import React from 'react';
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import Indicadores from '../componets/Home/Indicadores/Indicadores'
/* import HorasIncompletas from '../componets/Home/HorasIncompletas' */
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Home = () => {

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }
    const home = (
        <span className="disabled">Inicio</span>
    )

    /* console.log(cookies.get('proyectos'))
    console.log(cookies.get('nombre'))
    console.log(cookies.get('apellido'))
    console.log(cookies.get('ultimoMesCerrado'))
    console.log(cookies.get('texto')) */
    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    var permisoDashBoard = false
    if (permisos[13].DashBoards === 'true') {
        permisoDashBoard = true
    } else {
        permisoDashBoard = false
    }

    return (

        <>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={false}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                />
                {/* <HorasIncompletas /> */}
                <div >
                    {
                        permisoDashBoard
                            ?
                            <>
                                <div className="container">
                                    <h1 className="tittle">Indicadores Registrador de Actividades</h1>
                                </div>


                                <div>
                                    <div className="tabla">
                                        <Indicadores
                                            acceso={permisos}
                                        />
                                    </div>

                                </div>
                            </>
                            :
                            ""
                    }
                </div>
            </div>
        </>

    )
}

export default Home