import React, { /* useState, */ useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Block7 = () => {

    const dt = useRef(null);
    const fullName = cookies.get('nombre')
    // armar objecto con datos para llenar la tabla 
    var object = [{
        preparedBy: `${fullName}: Consultor de Linexperts Consultoría Empresarial S.A.S`,
        assigenedBy: '',
        firm: '',
        approvalDate: '',
        comments: ''
    }]
    /// usuario en sesión 

    return (
        <>
            <div className="datatable-responsive-demo">
                <h3 className="labels" style={{ marginBottom: '10px' }}>Aprobaciones</h3>
                <div className="card">
                    <DataTable ref={dt} value={object} dataKey="id" className="p-datatable-responsive-demo" >
                        <Column className="columna" field="preparedBy" header="Preparado por" />
                        <Column className="columna" field="assigenedBy" header="Asignado por" />
                        <Column className="columna" field="firm" header="Firma" />
                        <Column className="columna" field="approvalDate" header="Fecha de aprobación" />
                        <Column className="columna" field="comments" header="Comentarios " />
                    </DataTable>
                </div>
            </div>

        </>
    )
}

export default Block7