import React from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import AbrirMesCerrado from '../componets/ProcesosCierre/AbrirMesCerrado'
import '../assets/css/Sidebar.css'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AbrirMes = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol


    if (permisos[12].ProcesosCierre === "false") {
        window.location.href = "/registroActividades/inicio"
    }


    const consultar = (
        <span className="disabled">Abrir Mes Cerrado</span>
    )
    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">

                    <h1 className="tittle">Abrir Mes Cerrado</h1>


                </div>
                <div className="tabla">
                    <AbrirMesCerrado />
                </div>

            </div>
        </div>

    )

}

export default AbrirMes