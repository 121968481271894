import React, { useEffect, useState } from "react";
import { OrganizationChart } from 'primereact/organizationchart';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import Services from "../../service/Services";
import "../../assets/css/Forms.css";
import "../../assets/css/DialogDemo.css";
import '../../assets/css/DataTable.css';
import "../../assets/css/Organigrama.css";
import { Divider } from "primereact/divider";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import ColaboradoresEquipoTrabajo from "./ColaboradoresEquipoTrabajo";
import { CambiarFormatoFecha } from "../Funciones";
import axios from "axios";

const Organigrama = () => {
    const getServices = new Services();

    const [clientsSession, setClientsSession] = useState([]);
    const [servicesUsers, setServicesUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        getServices.getProyectosUsuarios().then(data => {
            if (data !== undefined) {
                const tableClients = {}
                const uniqueClients = data.filter(function (object) {
                    return tableClients.hasOwnProperty(object.cliente.id) ? false : (tableClients[object.cliente.id] = true)
                })
                setClientsSession(uniqueClients)
            }
        })
        getServices.getUsuarios().then(data => {
            if (data !== undefined) {
                setServicesUsers(data)
            }
        })
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [selectedClient, setSelectedCliente] = useState({
        id: '',
        name: ''
    })

    const optionsClient = clientsSession.map(item => {
        const av = { name: item.cliente.nombre, id: item.cliente.id }
        return av
    })

    const [userNoAssociatesCoordinator, setUserNoAssociatesCoordinator] = useState([]);

    const onClientChange = (e) => {
        setSelectedCliente(e.value)
        setLoading(true);
    }

    const nodeTemplate = (node) => {
        return (
            <>
                {
                    node.validateHeader === true ?
                        <>
                            <div className="node-header">{node.titulo}</div>
                            <div className="node-content">
                                <div>{node.label}</div>
                            </div>
                        </>
                        :
                        <>
                            <div className="node-headerC">{node.titulo2}</div>
                            <div className="node-content2">
                                <div>{node.label}</div>
                            </div>
                        </>
                }


            </>
        )
    }

    //Enviar datos por props
    const [dataCollaborators, setDataCollaborators] = useState([])
    const [dataTeamWork, setDataTeamWork] = useState([])
    const [data, setData] = useState([])
    //useEffect cargar toda la información cuando se seleccione un cliente
    useEffect(() => {
        if (selectedClient.id !== '') {
            setLoading(true)
            getServices.getWorkTeam().then(dataTeamWork => {
                var arrNew = []
                const filterTeam = dataTeamWork.filter(item => {
                    return parseInt(selectedClient.id) === item.cliente.id
                })
                var uniqueContactCustomer = []
                var noContactCustomer = []
                var invalidCustomerContact = []
                var hash = {};

                filterTeam.forEach(element => {
                    const result = element.contactoCliente.filter(function (obj) {
                        return obj.estado !== 0
                    })


                    const newArray = result.filter(item => hash[item.personainteresada.id]
                        ? false : hash[item.personainteresada.id] = true);

                    newArray.forEach(i => {
                        uniqueContactCustomer.push({
                            validateHeader: false,
                            titulo2: i.personainteresada.nombre + ' ' + i.personainteresada.apellido,
                            label: i.personainteresada.cargo.cargo,
                            id: i.personainteresada.id,
                            className: 'p-person'
                        })
                    })

                    if (element.contactoCliente === undefined || element.contactoCliente.length === 0) {
                        noContactCustomer = [{
                            validateHeader: false,
                            titulo2: 'NA Contacto Cliente',
                            label: 'NA Contacto Cliente',
                            className: 'p-person'
                        }]
                    } else if (element.contactoCliente !== undefined || element.contactoCliente.length !== 0) {

                        const newResult = element.contactoCliente?.filter(function (obj) {
                            return obj.estado === 0
                        })
                        //Filtro de equipos con asociaciones no vigentes
                        const filter = newResult.filter(function (obj) {
                            var idTeam
                            result.forEach(e => {
                                idTeam = e.equipoTrabajo.id
                            })
                            return obj.equipoTrabajo.id !== idTeam
                        })

                        const filterNew = filterTeam.filter(function (obj) {
                            var idTeam
                            filter.forEach(i => {
                                idTeam = i.equipoTrabajo.id
                            })
                            return idTeam === obj.id
                        })
                        if (filterNew.length !== 0) {
                            invalidCustomerContact = [{
                                validateHeader: false,
                                titulo2: 'NA CONTACTO CLIENTE',
                                label: 'No se encuentra vigente',
                                className: 'p-person'
                            }]
                            filterNew.forEach(element => {
                                arrNew.push(element)
                            })
                        }
                    }
                })
                var union = uniqueContactCustomer.concat(noContactCustomer.concat(invalidCustomerContact))
                const newUniqueContactArr = union.map(item => {
                    var newArr = []
                    filterTeam.forEach(element => {
                        if (element.contactoCliente !== undefined) {
                            const result = element.contactoCliente.filter(function (obj) {
                                return obj.estado !== 0
                            })
                            result.forEach(i => {
                                if (item.id === i.personainteresada.id) {
                                    newArr.push(element)
                                }
                            })

                        }
                    })
                    if (item.label === 'No se encuentra vigente') {
                        arrNew.forEach(e => {
                            newArr.push(e)
                        })
                    }
                    if (item.titulo2 === 'NA Contacto Cliente') {
                        filterTeam.forEach(element => {
                            if (element.contactoCliente.length === 0) {
                                newArr.push(element)
                            }
                        })
                    }

                    return {
                        ...item,
                        expanded: true,
                        children: newArr.map(x => {
                            const filter = x.lider.filter(item => {
                                return item.estado !== 0
                            })
                            const filterUsersCurrent = x.usuarios.filter(function (obj) {
                                return obj.estado !== 0
                            })
                            return {
                                validateHeader: true,
                                titulo: x.nombreequipotrabajo,
                                label: filterUsersCurrent.length + ' Colaboradores asociados',
                                className: 'p-person',
                                expanded: true,
                                children: filter.map(z => {
                                    return {
                                        validateHeader: true,
                                        titulo: z.colaborador.nombrecompleto,
                                        label: z.colaborador.idcargo.cargo,
                                        className: 'p-person',
                                        expanded: true
                                    }
                                })
                            }
                        })
                    }
                })
                setDataTeamWork(filterTeam)
                var usersFiltered = []
                var leaderFiltered = []
                filterTeam.forEach(z => {
                    const leader = {}
                    const leaderUnique = z.lider.filter(item => {
                        return leader.hasOwnProperty(item.colaborador.id) ? false : (leader[item.colaborador.id] = true)
                    })
                    const user = {}
                    const userUnique = z.usuarios.filter(item => {
                        return user.hasOwnProperty(item.usuario.id) ? false : (user[item.usuario.id] = true)
                    })

                    leaderUnique.forEach(element => {
                        var idLeader = element.colaborador.id
                        const filterLeader = servicesUsers.filter(x => {
                            return x.idColaborador === idLeader
                        })
                        filterLeader.forEach(z => {
                            leaderFiltered.push(z)
                        })
                    })
                    userUnique.forEach(element => {
                        var idUsers = element.usuario.id
                        const filterUsers = servicesUsers.filter(x => {
                            return x.idUsuario === idUsers
                        })
                        filterUsers.forEach(z => {
                            usersFiltered.push(z)
                        })
                    })

                })
                var newArrayLeader = []
                var newArrayUser = []
                var newArrayContactCustomer = []
                filterTeam.forEach(element => {
                    var currentLeader = element.lider.filter(i => {
                        return i.estado !== 0

                    })
                    currentLeader.forEach(i => {
                        newArrayLeader.push(i)
                    })

                    var currentUsers = element.usuarios.filter(i => {
                        return i.estado !== 0
                    })
                    currentUsers.forEach(i => {
                        newArrayUser.push(i)
                    })

                    var currentContactCustomer = element.contactoCliente?.filter(i => {
                        return i.estado !== 0
                    })
                    currentContactCustomer.forEach(i => {
                        newArrayContactCustomer.push(i)
                    })
                })
                var newArr = newArrayUser.concat(newArrayLeader)

                setDataCollaborators(newArr)
                var arrayLengthConcat = newArrayUser.concat(newArrayLeader.concat(newArrayContactCustomer))
                setData([{
                    validateHeader: true,
                    titulo: selectedClient?.name,
                    label: `Total Asociados: ${arrayLengthConcat.length}`,
                    expanded: true,
                    children: newUniqueContactArr,
                    className: 'p-person'
                }])
                getServices.getUsuariosAsociadosCliente(selectedClient.id).then(dataService => {
                    var arrayUsers = usersFiltered.concat(leaderFiltered)
                    var arrayDataMap = arrayUsers.map(item => {
                        return [JSON.stringify(item), item]
                    })
                    var arrayDataMapArr = new Map(arrayDataMap)
                    var uniqueData = [...arrayDataMapArr.values()]
                    var newArray = []
                    for (var i = 0; i < dataService.length; i++) {
                        var equal = false;
                        for (var j = 0; j < uniqueData.length & !equal; j++) {
                            if (dataService[i]['id'] === uniqueData[j]['idUsuario'])
                                equal = true;
                        }
                        if (!equal) {
                            newArray.push(dataService[i]);
                        }
                    }

                    var arrUsers = []
                    newArray.forEach(e => {
                        const filterUsers = servicesUsers.filter(item => {
                            return item.idUsuario === e.id
                        })
                        //Filtrar los usuarios que estén bloqueados por retiro
                        const newFilterUsers = filterUsers.filter(item => {
                            return item.tipobloqueo.id !== '2'
                        })
                        //Filtrar usuarios que no tengan equipo o que su asociacion se igual a 0
                        var arrStatusZero = newFilterUsers.filter(item => {
                            var status
                            item.equipoTrabajoLider?.forEach(e => {
                                status = e.estado
                            })
                            item.equipoTrabajoUsuario?.forEach(e => {
                                status = e.estado
                            })
                            return status === 0
                        })

                        const filterUsersWithoutTeam = newFilterUsers.filter(item => {
                            return item.equipoTrabajoLider.length === 0 && item.equipoTrabajoUsuario.length === 0
                        })

                        const arrConcatUsers = filterUsersWithoutTeam.concat(arrStatusZero)
                        arrConcatUsers.forEach(e => {
                            arrUsers.push(e)
                        })
                    })
                    setUserNoAssociatesCoordinator(arrUsers)
                    setLoading(false)
                })
            })
        }
    }, [selectedClient, servicesUsers])// eslint-disable-line react-hooks/exhaustive-deps

    //Obtener la fecha estática en que el organigrama se quedó sin colaboradores por asociar
    var newDate
    if (userNoAssociatesCoordinator.length === 0) {
        var dateNow = Date.now()
        var date = new Date(dateNow)
        newDate = CambiarFormatoFecha(date)
    }
    const [dataUsersNoAssociatesCoordinator, setDataUsersNoAssociatesCoordinator] = useState([]);
    useEffect(() => {
        setDataUsersNoAssociatesCoordinator(userNoAssociatesCoordinator.map(item => {
            return {
                nombres: item.nombres + ' ' + item.apellidos
            }
        }))

    }, [userNoAssociatesCoordinator])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="p-fluid p-formgrid p-grid">
                <form style={{ display: "contents" }}>
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels">Cliente</label>
                        <Dropdown placeholder="Seleccione un cliente" value={selectedClient} options={optionsClient} onChange={onClientChange} optionLabel="name" filter />
                    </div>
                </form>
                <div className="p-field p-col-12 p-md-12">
                    <Divider />
                </div>
            </div>
            <div className="organizationchart-demo">
                <div className="card">
                    {selectedClient.name !== '' ?
                        <div>
                            <div>
                                <ColaboradoresEquipoTrabajo
                                    dataCollaboratorsProp={dataCollaborators}
                                    dataTeamWorkProp={dataTeamWork}
                                    servicesUsersProp={servicesUsers}
                                />
                            </div>


                            {data.length !== 0 &&
                                userNoAssociatesCoordinator.length === 0 ?
                                <label className="labelsorganigrama" >Fecha de generación de Organigrama: {newDate}</label>
                                :
                                ''
                            }

                            {data.length !== 0 &&
                                <OrganizationChart value={data} nodeTemplate={nodeTemplate}></OrganizationChart>
                            }
                        </div>
                        :
                        ''
                    }
                </div>
                <br></br>
                <div>
                {
					loading ?
						<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
						    :
						""
				}
                    {dataUsersNoAssociatesCoordinator.length !== 0 ?
                        <DataTable className="p-datatable-responsive-demo" header="Otros colaboradores asociados al Cliente"
                            value={dataUsersNoAssociatesCoordinator} responsiveLayout="scroll" paginator rows={10}>
                            <Column field="nombres" />
                        </DataTable>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Organigrama