import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Services from '../../service/Services';
import BtnCrearProveedor from './BtnCrearProveedor';
import BtnActualizarProveedor from './BtnActualizarProveedor';
import ConfirmBtn from './ConfirmBtn';
import '../../assets/css/DataTable.css';
import ProveedorCliente from './ProveedorCliente';
import axios from 'axios';

const ConsultarProveedor = ({ permisosProp }) => {

    const [servicio, setServicio] = useState([]);
    const [loading, setLoading] = useState(true);
    const [render, setRender] = useState(0);

    const getServices = new Services();

    useEffect(() => {

        const source = axios.CancelToken.source();
        getServices.getSuppliers().then(data => {
            if (data !== undefined) {
                setServicio(data)
                setLoading(false)
            }
        });
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [render]); // eslint-disable-line react-hooks/exhaustive-deps

    var newRange = []
    if (servicio && servicio.length > 0) {
        servicio.map(item => {
            var estado
            if (item.estado === 0) {
                estado = "Inactivo"
            } else if (item.estado === 1) {
                estado = "Activo"
            } else if (item.estado === 2) {
                estado = "Bloqueado"
            } else if (item.estado === 3) {
                estado = "Eliminado"
            }
            var jsonNewRange = {
                ...item,
                estado: estado,
            }
            return newRange.push(jsonNewRange)
        })
    }

    var permisoSupplierCreate
    if (permisosProp[27].permisos[0] === "Crear") {
        permisoSupplierCreate = true
    } else {
        permisoSupplierCreate = false
    }
    var permisoSupplierUpdate
    if (permisosProp[27].permisos[1] === "Editar") {
        permisoSupplierUpdate = true
    } else {
        permisoSupplierUpdate = false
    }
    var permisoSupplierDelete
    if (permisosProp[27].permisos[2] === "Eliminar") {
        permisoSupplierDelete = true
    } else {
        permisoSupplierDelete = false
    }

    const Acciones = (rowData) => {
        return (
            <>
                {permisoSupplierUpdate ?
                    <BtnActualizarProveedor
                        idProveedorProp={rowData.id}
                        tipoDocumentoProp={rowData.tipodocumento}
                        numeroDocumentoProp={rowData.numerodocumento}
                        nombreProp={rowData.razonsocial}
                        dominioProp={rowData.dominio}
                        creadoporProp={rowData.creadopor}
                        fechacreacionProp={rowData.fechacreacion}
                        renderProp={render}
                        setRenderProp={setRender}
                        servicesProp = {newRange}
                    />
                    :
                    ""
                }
                <ProveedorCliente
                    clientsProp={rowData.clientes}
                />
                {permisoSupplierDelete ?
                    <ConfirmBtn
                        titulo="Confirmar Eliminación"
                        tooltipProp="Eliminar"
                        mensaje='¿Está seguro de eliminar el registro?'
                        clase="p-button-danger"
                        icono="pi pi-trash"
                        accion={"Eliminar"}
                        idProveedorProp={rowData.id}
                        associationsProp={rowData}
                        renderProp={render}
                        setRenderProp={setRender}
                    />
                    :
                    ""
                }
            </>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoSupplierCreate ?
                <BtnCrearProveedor
                    renderProp={render}
                    setRenderProp={setRender}
                    servicesProp = {newRange}
                />
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    var permisosAcciones
    if (permisoSupplierUpdate === false && permisoSupplierDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={newRange} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                    emptyMessage="Proveedor no registrado en el sistema" loading={loading}>
                    <Column className="columna" field="numerodocumento" sortable header="Número Documento" />
                    <Column className="columna" field="tipodocumento" sortable header="Tipo Documento" />
                    <Column className="columna" field="razonsocial" sortable header="Nombre / Razón social" />
                    <Column className="columna" field="dominio" sortable header="Dominio" />
                    <Column className="columna" field="estado" sortable header="Estado" />
                    {permisosAcciones ?
                        <Column header="Acciones" body={Acciones} />
                        :
                        <Column />
                    }
                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarProveedor