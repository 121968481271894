import React, { useState, useEffect } from "react";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { CambiarFormatoFecha, RestarDias } from "../Funciones";
import { Divider } from 'primereact/divider';
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from "primereact/multiselect";
import Services from "../../service/Services";
import axios from 'axios';
import ConfirmBtn from "./ConfirmBtn";
const Block1 = ({ setDateStartDBProp, setDateEndDBProp, setClientsProp, objectMonthlyReportDataProp, clientChangeProp, setClientChangeProp, setPeriodProp, setClearDataProp,
    clearDataProp, setProjectsOfficialProp, setMonthlyReportProp, setUserSelectedProp, setDataUserProp, permissionsProp, setReloadProp, reloadProp, setAllDataProp,
    setShowAllDataProp, setLoadingProp, permissionProp, readReportProp, setRunRenderProp, runRenderProp, listReportsUsersProp, reportsUserActiveProp, /* setChagesProp */ validateOpenMonthProp,
    setServicesWorkTeamProp, servicesClientsProjectUsersProp, setClientsProp2, getSelectedClientProp }) => {

    const [clientSession, setClientSession] = useState([]);
    const [servicesTeamWork, setServicesTeamWork] = useState([]);
    const [servicesUsers, setServicesUsers] = useState([]);
    const getServices = new Services()

    useEffect(() => {
        if (objectMonthlyReportDataProp !== null) {

            if (objectMonthlyReportDataProp !== undefined) {
                setSelectedClient({
                    client: objectMonthlyReportDataProp.cliente.razonsocial,
                    id: objectMonthlyReportDataProp.cliente.id
                })
                setClientsProp({
                    client: objectMonthlyReportDataProp.cliente.razonsocial,
                    id: objectMonthlyReportDataProp.cliente.id
                })
            } /* else {
                setSelectedClient({
                    client: '',
                    id: ''
                })
            } */
        }

    }, [objectMonthlyReportDataProp])// eslint-disable-line react-hooks/exhaustive-deps

    // estado para radiobutton 

    const [monthlyReport, setMonthlyReport] = useState('ownMonthlyReport');

    const onMonthlyReportChange = (e) => {
        setMonthlyReport(e.value)
        if (e.value === 'monthlyReportOtherOfficial') {
            setClearDataProp(clearDataProp + 1)
            setClientChangeProp(clientChangeProp + 1)
            setSelectedDate(null)
            setDateStart(null)
            setDateEnd(null)
            setSelectedWorkTeams(null)
            setDateStartDBProp(null)
            setDateEndDBProp(null)
            setSelectedClient({
                client: '',
                id: ''
            })
        } if (e.value === 'allMonthlyReportOfOfficial') {
            setClearDataProp(clearDataProp + 1)
            setClientChangeProp(clientChangeProp + 1)
            setSelectedDate(null)
            setDateStart(null)
            setDateEnd(null)
            setSelectedWorkTeams(null)
            setDateStartDBProp(null)
            setDateEndDBProp(null)
            setSelectedClient({
                client: '',
                id: ''
            })
        } else {
            setShowAllDataProp(false)
        }
        setDataUserProp([])
        setMonthlyReportProp(e.value)
    }



    useEffect(() => {
        const source = axios.CancelToken.source()
        if (monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') {
            getServices.getWorkTeam().then(data => {
                if (data !== undefined) {
                    setServicesWorkTeamProp(data)
                }
            });
           
            /////Proceso para evitar que los clientes se repitan 
            let selectedClients = []
            servicesClientsProjectUsersProp?.forEach(element => {
                var clients = {}
                clients.id = element.cliente.id
                clients.name = element.cliente.nombre
                selectedClients.push(clients)
            });
            const clientsTable = {}
            const uniqueClients = selectedClients.filter(function (object) {
                return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
            })
            setClientSession(uniqueClients)

        } else {
            /////Proceso para evitar que los clientes se repitan 
            let selectedClients = []
            servicesClientsProjectUsersProp?.forEach(element => {
                var clients = {}
                clients.id = element.cliente.id
                clients.name = element.cliente.nombre
                selectedClients.push(clients)
            });
            const clientsTable = {}
            const uniqueClients = selectedClients.filter(function (object) {
                return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
            })
            setClientSession(uniqueClients)
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [monthlyReport, servicesClientsProjectUsersProp])// eslint-disable-line react-hooks/exhaustive-deps
    // obtener datos del select cliente

    const [selectedClient, setSelectedClient] = useState({
        client: '',
        id: ''
    })

    const clients = clientSession.map(item => {
        const obj = { client: item.name, id: parseInt(item.id) }
        return obj
    })
    const onClientChange = (e) => {
        setSelectedClient(e.value)
        setClientsProp(e.value)
        setClientsProp2(e.value)
        setClientChangeProp(clientChangeProp + 1)
        setClearDataProp(clearDataProp + 1)
        setSelectedWorkTeams(null)
        setSelectedOfficial({
            id: '',
            name: ''
        })
        if (selectedDate !== null) {
            updateDates(selectedDate, e.value)
        }
        /* setChagesProp(true) */
    }

    const [selectedWorkTeams, setSelectedWorkTeams] = useState(null)

    // obtener datos del multiselect de equipos de trabajo
    useEffect(() => {
        const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        const dataWorkTeam = {
            idClient: getId([selectedClient]) || []
        }

        const executeServicesWorTeam = async () => {
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/consultListByClient'
            await axios.post(url, dataWorkTeam, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: newSource.token
            })
                .then((result) => {
                    if (result !== undefined) {
                        const newData = result.data.map(item => item)
                        newData.push({
                            id: 0,
                            nombre: 'Sin Equipo de Trabajo'
                        })
                        setServicesTeamWork(newData)
                    }
                })
                .catch((e) => {
                    /* console.log(e); */
                })
        }
        if (selectedClient !== null && selectedClient !==undefined) {
            if (selectedClient.id !== '') {
                executeServicesWorTeam()
            }
        }

        return () => {
            if (newSource) {
                newSource.cancel()
            }
        }
    }, [selectedClient]); // eslint-disable-line react-hooks/exhaustive-deps

  
    const workTeams = servicesTeamWork.map(item => item)

    const [itemsSelectedWorkTeam, setItemsSelectedWorkTeam] = useState(0)
    const onWorkTeamsChange = (e) => {
        setSelectedWorkTeams(e.value)
        if (e.value !== null) {
            setItemsSelectedWorkTeam(e.value.length)
        }
        if (e.value === null || e.value.length === 0) {
            setSelectedOfficial({
                id: '',
                name: ''
            })
            if (selectedClient.id !== '' && selectedDate !== null) {
                if (monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') {
                    queryAllData(dateStart, dateEnd, selectedClient)
                }
            }
        }
    }

    // obtener datos del select de funcionarios 

    const [selectedOfficial, setSelectedOfficial] = useState({
        id: '',
        name: ''
    })

    const getId = (array) => {
        if (array === null || array.length === 0) return false;
        return array.map((el) => el.id);
    };

    useEffect(() => {
        const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        const dataUsers = {
            cargos: [],
            proveedor: [],
            proyectos: [],
            wt: getId(selectedWorkTeams) || [],
            clientes: getId([selectedClient]) || []
        }
        const getUsersForProjectOrTeamWorkOrPosition = async () => {

            const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/getUsersFilter'
            await axios.post(url, dataUsers, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: newSource.token
            }).then((result) => {
                if (result !== undefined) {
                    setServicesUsers(result.data)
                }

            }).catch((e) => {
                /* console.log(e); */
            })
        }
        if (selectedWorkTeams !== null && selectedWorkTeams.length !== 0 && selectedClient !== null
            && selectedClient.id !== '') {
            getUsersForProjectOrTeamWorkOrPosition()

        }
        return () => {
            if (newSource) {
                newSource.cancel()
            }
        }
    }, [selectedWorkTeams, selectedClient]); // eslint-disable-line react-hooks/exhaustive-deps

    const officials = servicesUsers.map(item => {
        const av = { name: item.nombre, id: item.idUsuario }
        return av
    })
    const onOfficialChange = (e) => {
        setSelectedOfficial(e.value)
        setUserSelectedProp(e.value)
    }

    /* const [projectsOfficial, setProjectsOfficial] = useState([]); */
    useEffect(() => {
        const source = axios.CancelToken.source()
        if (selectedOfficial.id !== '' && selectedWorkTeams !== null && selectedWorkTeams.length !== 0) {
            getServices.getprojectUser(selectedOfficial.id).then(data => {
               /*  setProjectsOfficial(data) */
                setProjectsOfficialProp(data)
                setShowAllDataProp(false)
            })
        } else {
            setProjectsOfficialProp([])
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [selectedOfficial, selectedWorkTeams])// eslint-disable-line react-hooks/exhaustive-deps

    // calendarios de periodo

    const [selectedDate, setSelectedDate] = useState(null)
    const [dateStart, setDateStart] = useState(null)
    const [dateEnd, setDateEnd] = useState(null)

    const onSelectedDateChange = (e) => {

        setSelectedDate(e.value)
        setPeriodProp(e.value)
        setClearDataProp(clearDataProp + 1)
        setReloadProp(reloadProp + 1)
        if (selectedClient.id !== '') {
            updateDates(e.value, selectedClient)
        }
        /* setChagesProp(true) */
    }

    const updateDates = (date, client) => {
        if (date !== null){ 
        var firtsDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var queryFirtsDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var queryLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var firtsDayDBProp = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDayDBProp = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var changePeriod = new Date('2022-09-02')
        if (client.client !== '') {
            if (client.client === "Seguros ALFA") {
                if (CambiarFormatoFecha(date) < CambiarFormatoFecha(changePeriod)) {
                    var subtractFirtsDay = new Date(date.getFullYear(), date.getMonth(), 0).getDate() - 25
                    var subtractLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 25
                    var quetySubtractFirtsDay = new Date(date.getFullYear(), date.getMonth(), 0).getDate() - 25
                    var quetySubtractLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 25
                    setDateStart(RestarDias(firtsDay, subtractFirtsDay))
                    setDateEnd(RestarDias(lastDay, subtractLastDay))
                    setDateStartDBProp(RestarDias(firtsDayDBProp, subtractFirtsDay))
                    setDateEndDBProp(RestarDias(lastDayDBProp, subtractLastDay))
                    if ((monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') && selectedWorkTeams === null && selectedOfficial.id === '') {
                        queryAllData(RestarDias(queryFirtsDay, quetySubtractFirtsDay), RestarDias(queryLastDay, quetySubtractLastDay), client)
                    }
                } else {
                    if (CambiarFormatoFecha(date) === CambiarFormatoFecha(changePeriod)) {
                        var subtractFirtsDay2 = new Date(date.getFullYear(), date.getMonth(), 0).getDate() - 25
                        var quetySubtractFirtsDay2 = new Date(date.getFullYear(), date.getMonth(), 0).getDate() - 25
                        /* var quetySubtractLastDay2 = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 25 */
                        setDateStart(RestarDias(firtsDay, subtractFirtsDay2))
                        setDateEnd(lastDay)
                        setDateStartDBProp(RestarDias(firtsDayDBProp, subtractFirtsDay2))
                        setDateEndDBProp(lastDayDBProp)
                        if ((monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') && selectedWorkTeams === null && selectedOfficial.id === '') {
                            queryAllData(RestarDias(queryFirtsDay, quetySubtractFirtsDay2), queryLastDay, client)
                        }
                    } else {
                        setDateStart(firtsDay)
                        setDateEnd(lastDay)
                        setDateStartDBProp(firtsDay)
                        setDateEndDBProp(lastDay)
                        if ((monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') && selectedWorkTeams === null && selectedOfficial.id === '') {
                            queryAllData(firtsDay, lastDay, client)
                        }
                    }
                }
            } else {
                setDateStart(firtsDay)
                setDateEnd(lastDay)
                setDateStartDBProp(firtsDay)
                setDateEndDBProp(lastDay)
                if ((monthlyReport === 'monthlyReportOtherOfficial' || monthlyReport === 'allMonthlyReportOfOfficial') && selectedWorkTeams === null && selectedOfficial.id === '') {
                    queryAllData(firtsDay, lastDay, client)
                }
            }
        }}
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    // Consulta de datos para realizar export excel del informe mensual

    const queryAllData = async (newDateStart, newDateEnd, client) => {
        setLoadingProp(true)
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/MonthlyReportData/MonthlyReportData/allDataUsersComplete'
        await axios.post(url,
            null,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                /* console.log(response.data) */
                var filterClientAllData = response.data.filter(function (object) {
                    var idClient = object.cliente.id
                    return idClient === client.id
                })
                var filterPeriodAllData = filterClientAllData.filter(function (object) {
                    var period = JSON.parse(object.periodo)
                    return period.period >= CambiarFormatoFecha(newDateStart) && period.period <= CambiarFormatoFecha(newDateEnd)
                })
                /* console.log(filterPeriodAllData) */
                setAllDataProp(filterPeriodAllData)
                setShowAllDataProp(true)
                setLoadingProp(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                setLoadingProp(false)
            })
    }

    // validaciones 
    useEffect(() => {
        if (monthlyReport === 'monthlyReportOtherOfficial') {
            setSelectedDate(null)
            setDateStart(null)
            setDateEnd(null)
            setDateStartDBProp(null)
            setDateEndDBProp(null)
        }
        // eslint-disable-next-line 
    }, [selectedClient])

    var disabledOfficial = false

    if (selectedWorkTeams === null || selectedWorkTeams.length === 0) {
        disabledOfficial = true
    }

    var disabledPeriod = false

    if (selectedOfficial === null || selectedOfficial.id === '') {
        disabledPeriod = true
    }

    var disabledWorkTeam = false
    if (selectedClient === null || selectedClient.id === '') {
        disabledWorkTeam = true
    }

    var permissionsindividualMonthlyReportReadOtherOfficial
    if (permissionsProp[15].permisos[0] === "true") {
        permissionsindividualMonthlyReportReadOtherOfficial = true
    } else {
        permissionsindividualMonthlyReportReadOtherOfficial = false
    }

    var permissionsindividualMonthlyReportReopen
    if (permissionsProp[15].permisos[1] === "true") {
        permissionsindividualMonthlyReportReopen = true
    } else {
        permissionsindividualMonthlyReportReopen = false
    }

    return (
        <>
            {permissionsindividualMonthlyReportReadOtherOfficial ?
                <>
                    <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                        <div className="p-field p-col-12 p-md-6 p-lg-12" >
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="ownMonthlyReport" name="ownMonthlyReport" value="ownMonthlyReport" onChange={onMonthlyReportChange} checked={monthlyReport === 'ownMonthlyReport'} />
                                <label htmlFor="ownMonthlyReport" style={{ fontWeight: '100' }}>Informe mensual propio</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="monthlyReportOtherOfficial" name="monthlyReportOtherOfficial" value="monthlyReportOtherOfficial" onChange={onMonthlyReportChange} checked={monthlyReport === 'monthlyReportOtherOfficial'} />
                                <label htmlFor="monthlyReportOtherOfficial" style={{ fontWeight: '100' }}>Consultar informe mensual de otro funcionario</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="allMonthlyReportOfOfficial" name="allMonthlyReportOfOfficial" value="allMonthlyReportOfOfficial" onChange={onMonthlyReportChange} checked={monthlyReport === 'allMonthlyReportOfOfficial'} />
                                <label htmlFor="allMonthlyReportOfOfficial" style={{ fontWeight: '100' }}>Consultar informe mensual de todos los funcionarios</label>
                            </div>
                        </div>
                    </div>
                    <Divider />
                </>
                :
                null
            }
            <div className="p-fluid p-formgrid p-grid">
                <div style={{ display: 'contents' }} >
                    <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                        <label className="labels" >Cliente:</label>
                        <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                            className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="client" filter
                        />
                    </div>
                </div>
                {monthlyReport === 'monthlyReportOtherOfficial' ?
                    <>
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                                <label className="labels" >Equipo de trabajo:</label>
                                <MultiSelect value={selectedWorkTeams} options={workTeams} onChange={onWorkTeamsChange} placeholder="Seleccione un equipo de trabajo"
                                    className="p-column-filter " emptyMessage="No hay resultados" name="workTeams" optionLabel="nombre" showClear
                                    maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedWorkTeam} Elementos Seleccionados`} disabled={disabledWorkTeam}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                                <label className="labels" >Funcionario:</label>
                                <Dropdown value={selectedOfficial} options={officials} onChange={onOfficialChange} placeholder="Seleccione un funcionario"
                                    className="p-column-filter " emptyMessage="No hay resultados" name="official" optionLabel="name" disabled={disabledOfficial}
                                    filter
                                />
                            </div>
                        </div>
                        {readReportProp ?
                            permissionsindividualMonthlyReportReopen ?
                                validateOpenMonthProp === true ?
                                    <div className="p-field p-col-12 p-md-3" style={{ marginTop: '35px' }}>
                                        {
                                            <ConfirmBtn
                                                tittle="Anticipar Botón Finalizar"
                                                menssage="¿Desea anticipar el informe de este usuario?"
                                                action={"Update"}
                                                label="Anticipar Botón Finalizar"
                                                icon="pi pi-replay"
                                                classProp="p-button-outlined p-button-secondary"
                                                dataProp={listReportsUsersProp[0]}
                                                statusProp={1}
                                                setRunRenderProp={setRunRenderProp}
                                                runRenderProp={runRenderProp}
                                                setReloadProp={setReloadProp}
                                                reloadProp={reloadProp}
                                                reopenReportProp={false}
                                                reopenProp={false}
                                                anticipateProp={true}
                                                getSelectedClientProp={getSelectedClientProp}
                                            />
                                        }
                                    </div>
                                    :
                                    <div className="p-field p-col-12 p-md-3" style={{ marginTop: '35px' }}>



                                        <ConfirmBtn
                                            tittle="Reabrir periodo selecciondao"
                                            menssage="Si el usuario tiene un periodo abierto, este se cerrará temporalmente hasta que se finalice el periodo reabierto, ¿Estás seguro de reabrir este informe?"
                                            action={"Update"}
                                            label="Reabrir informe"
                                            icon="pi pi-replay"
                                            classProp="p-button-outlined p-button-secondary"
                                            dataProp={listReportsUsersProp[0]}
                                            statusProp={1}
                                            setRunRenderProp={setRunRenderProp}
                                            runRenderProp={runRenderProp}
                                            setReloadProp={setReloadProp}
                                            reloadProp={reloadProp}
                                            reopenReportProp={true}
                                            reopenProp={true}
                                            anticipateProp={false}
                                            getSelectedClientProp={getSelectedClientProp}
                                        />
                                    </div>
                                :
                                null
                            :
                            null
                        }
                    </>
                    :
                    ""
                }
                {monthlyReport === 'allMonthlyReportOfOfficial' ?
                    <>
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px', display: 'none' }}>
                                <label className="labels" >Equipo de trabajo:</label>
                                <MultiSelect value={selectedWorkTeams} options={workTeams} onChange={onWorkTeamsChange} placeholder="Seleccione un equipo de trabajo"
                                    className="p-column-filter " emptyMessage="No hay resultados" name="workTeams" optionLabel="name" showClear
                                    maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedWorkTeam} Elementos Seleccionados`} disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px', display: 'none' }}>
                                <label className="labels" >Funcionario:</label>
                                <Dropdown value={selectedOfficial} options={officials} onChange={onOfficialChange} placeholder="Seleccione un funcionario"
                                    className="p-column-filter " emptyMessage="No hay resultados" name="official" optionLabel="name" disabled={disabledOfficial}
                                    filter
                                />
                            </div>
                        </div>
                    </>
                    :
                    ""
                }
            </div>
            <Divider />
            {monthlyReport !== 'monthlyReportOtherOfficial' && monthlyReport !== 'allMonthlyReportOfOfficial' ?
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Periodo:</label>
                            <Calendar id="monthpicker" value={selectedDate} onChange={onSelectedDateChange} view="month" dateFormat="mm/yy"
                                placeholder="Seleccione un periodo" locale="es"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Fecha inicio:</label>
                            <Calendar placeholder="Fecha inicio" value={dateStart} dateFormat="dd-mm-yy" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Fecha fin:</label>
                            <Calendar placeholder="Fecha fin" value={dateEnd} dateFormat="dd-mm-yy" disabled />
                        </div>
                    </div>
                </div>
                :
                ""
            }
            {monthlyReport === 'monthlyReportOtherOfficial' ?
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Periodo:</label>
                            <Calendar id="monthpicker" value={selectedDate} onChange={onSelectedDateChange} view="month" dateFormat="mm/yy"
                                placeholder="Seleccione un periodo" locale="es" disabled={disabledPeriod}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Fecha inicio:</label>
                            <Calendar placeholder="Fecha inicio" value={dateStart} dateFormat="dd-mm-yy" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Fecha fin:</label>
                            <Calendar placeholder="Fecha fin" value={dateEnd} dateFormat="dd-mm-yy" disabled />
                        </div>
                    </div>
                </div>
                :
                ""
            }
            {monthlyReport === 'allMonthlyReportOfOfficial' ?
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Periodo:</label>
                            <Calendar id="monthpicker" value={selectedDate} onChange={onSelectedDateChange} view="month" dateFormat="mm/yy"
                                placeholder="Seleccione un periodo" locale="es"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Fecha inicio:</label>
                            <Calendar placeholder="Fecha inicio" value={dateStart} dateFormat="dd-mm-yy" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Fecha fin:</label>
                            <Calendar placeholder="Fecha fin" value={dateEnd} dateFormat="dd-mm-yy" disabled />
                        </div>
                    </div>
                </div>
                :
                ""
            }
        </>
    )
}

export default Block1