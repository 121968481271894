import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Services from '../../service/Services';
import BtnCrearAreaNegocio from './BtnCrearAreaNegocio'
import BtnActualizarAreaNegocio from './BtnActualizarAreaNegocio'
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/DataTable.css';
import axios from 'axios';

const ConsultarAreasNegocio = ({ permisosProp }) => {

    const [servicio, setServicio] = useState([]);
    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    useEffect(() => {
        const source = axios.CancelToken.source()
        getServices.getAreasNegocio().then(data => {
            if (data !== undefined) {
                setServicio(data)
                setLoading(false)
            }
        });
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var permisoBusinessAreasCreate
    if (permisosProp[6].permisos[0] === "Crear") {
        permisoBusinessAreasCreate = true
    } else {
        permisoBusinessAreasCreate = false
    }
    var permisoBusinessAreasUpdate
    if (permisosProp[6].permisos[1] === "Editar") {
        permisoBusinessAreasUpdate = true
    } else {
        permisoBusinessAreasUpdate = false
    }
    var permisoBusinessAreasDelete
    if (permisosProp[6].permisos[2] === "Eliminar") {
        permisoBusinessAreasDelete = true
    } else {
        permisoBusinessAreasDelete = false
    }

    const Acciones = (rowData) => {
        //console.log(rowData)
        return (
            <>
                {permisoBusinessAreasUpdate ?
                    <BtnActualizarAreaNegocio
                        idAreaProp={rowData.id}
                        areaProp={rowData.nombreareanegocio}
                        creadoporProp={rowData.creadopor}
                        fechacreacionProp={rowData.fechacreacion}
                        areasNegocioProp={servicio}
                    />
                    :
                    ""
                }
                {permisoBusinessAreasDelete ?
                    <ConfirmBtn
                        titulo="Confirmar Eliminación"
                        mensaje="¿Está seguro de eliminar Área?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        accion="Eliminar"
                        clase="p-button-danger"
                        icono="pi pi-trash"
                        idAreaProp={rowData.id}
                        tooltipProp="Eliminar"
                    />
                    :
                    ""
                }

            </>
        );
    }


    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoBusinessAreasCreate ?
                <BtnCrearAreaNegocio
                areasNegocioProp={servicio}
                />
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    var permisosAcciones
    if (permisoBusinessAreasUpdate === false && permisoBusinessAreasDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={servicio} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                    emptyMessage="Área de Negocio no registrada en el sistema" loading={loading}>
                    <Column className="columna" field="id" sortable header="Id Área" />
                    <Column className="columna" field="nombreareanegocio" sortable header="Área de Negocio" />
                    {permisosAcciones ?
                        <Column header="Acciones" body={Acciones} />
                        :
                        <Column />
                    }
                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarAreasNegocio
