import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

const DeliverablesTable = ({ deliverableProp, idProjectProp, stylesProp, listDeliverableProp, objectiveProp }) => {

    var deliverable = []
    const result = deliverableProp.map(x => {
        var boolean
        if (parseInt(x.proyecto) === parseInt(idProjectProp)) {
            boolean = x
        }
        return boolean

    })
    result.forEach(i => {
        if (i !== undefined) {
            if (i.length !== 0) {
                deliverable.push(i)
            }
        }
    })
    const resultTable = {}
    const uniqueResult = deliverable.filter(function (object) {
        return resultTable.hasOwnProperty(object.id) ? false : (resultTable[object.id] = true)
    })
    var newArray = []
    uniqueResult.forEach(element => {
        var result = listDeliverableProp.filter(obj => obj.id === element.id)
        result.forEach(x => {
            newArray.push(x)
        })
    });
    var newUniqueResult = newArray.map(z => {
        var filter = z.objetivos.filter(function (object) {
            var idProject = object.proyecto.id
            return parseInt(idProject) === parseInt(idProjectProp)
        })
        let resultFilter = []
        objectiveProp.forEach(element => {
            var filterObjective = filter.filter(function (obj) {
                return obj.objetivo.id === element.id
            })
            filterObjective.forEach(i => {
                resultFilter.push(i)
            })
        })
        var newFilter = resultFilter.map(item => {
            return {
                fechacumplimiento: item.fechacumplimiento,
                objetivo: item.objetivo,
                porcentajecumplimiento: item.porcentajecumplimiento,
                proyecto: item.proyecto,
                descripcionEntregable: z.descripcion,
            }
        })

        //Validar duplicidad de entregable

        const deliverableDuplicated = {}
        const uniqueDeliverable = newFilter.filter((object, index) => {
            return deliverableDuplicated.hasOwnProperty(object.proyecto.id) ? false : (deliverableDuplicated[object.proyecto.id] = true)
        })
        return {
            descripcion: z.descripcion,
            id: z.id,
            objetivos: uniqueDeliverable
        }
    })
    return (
        <View>
            <Text style={stylesProp.subtitle2}>
                7.2 Entregables
            </Text>
            {
                newUniqueResult.map((item, i) => {
                    return (
                        <View key={i}>
                            <Table
                                data={item.objetivos}
                            >
                                <TableHeader textAlign={"center"} style={stylesProp.headerTable}>
                                    <TableCell style={stylesProp.textTable}>
                                        Objetivos
                                    </TableCell><TableCell style={stylesProp.textTable}>
                                        Entregables
                                    </TableCell>
                                    <TableCell style={stylesProp.textTable}>
                                        Fecha de Cumplimiento
                                    </TableCell>
                                    <TableCell style={stylesProp.textTable}>
                                        Porcentaje de cumplimiento
                                    </TableCell>
                                </TableHeader>
                                <TableBody textAlign={"center"}>
                                    <DataTableCell getContent={(r) => r.objetivo.descripcion} style={stylesProp.textCell} />
                                    <DataTableCell getContent={(r) => r.descripcionEntregable} style={stylesProp.textCell} />
                                    <DataTableCell getContent={(r) => r.fechacumplimiento} style={stylesProp.textCell} />
                                    <DataTableCell getContent={(r) => r.porcentajecumplimiento + '%'} style={stylesProp.textCell} />
                                </TableBody>
                            </Table>
                            <Text style={stylesProp.subtitle2}>

                            </Text>
                        </View>
                    )
                })
            }
        </View>
    )
}

export default DeliverablesTable