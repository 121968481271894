import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import ConfirmBtn from './ConfirmBtn';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import Services from '../../service/Services';
import Cookies from 'universal-cookie';
import { CambiarFormatoFecha } from '../Funciones';
import moment from 'moment/moment';
import 'moment/locale/es';

const cookies = new Cookies();

const CierreDefinitivo = () => {

    const [ultimoCierre, setUsltimoCierre] = useState([]);
    const [listaCierres, setListaCierres] = useState([]);
    const [dateStartSystem, setDateStartSystem] = useState([]);

    const getServices = new Services();

    useEffect(() => {

        getServices.getUltimoCierre().then(data => {
            if (data === undefined) {
                getServices.getUsuarios().then(obj => {
                    var newData = obj.sort(function (a, b) {
                        var textA = a.idUsuario;
                        var textB = b.idUsuario;
                        return textA - textB;
                    });
                    setDateStartSystem(newData);
                });
            }

            setUsltimoCierre(data)
        });
        getServices.getCierres().then(data => setListaCierres(data))

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var arrayfechas = []

    listaCierres.forEach(element => {
        if (element.estado !== 'C') {
            const date = new Date(element.fechaFinalCierre)
            const month = date.toLocaleString('es-CO', { month: 'long' })
            const year = date.getFullYear()
            arrayfechas.push({
                id: element.id,
                fechaFinalCierre: element.fechaFinalCierre,
                fechaInicialCierre: element.fechaInicialCierre,
                mesAño: month + '-' + year
            })
        }
    });

    // obtener cuando se puede ejecutar un cierre apartir del último mes cerrado 

    var lastDate = ultimoCierre === undefined ? new Date() : moment(ultimoCierre.fechaFinalCierre).add(1, 'month').endOf('month').format('YYYY-MM-DD');

    const newLastDate = lastDate;

    // obtener fecha actual
    const date = new Date()

    const newDate = CambiarFormatoFecha(date)

    if (newDate > newLastDate) {
        const date = new Date(newLastDate)
        const month = date.toLocaleString('es-CO', { month: 'long' })
        const year = date.getFullYear()
        arrayfechas.push({
            id: '',
            fechaFinalCierre: '',
            fechaInicialCierre: newDate,
            mesAño: month + '-' + year
        })
    }

    const [selectedMesAño, setSelectedMesAño] = useState({
        id: '',
        fechaFinalCierre: '',
        fechaInicialCierre: '',
        mesAño: ''
    });

    const mesAño = selectedMesAño.mesAño;
    const idCierre = selectedMesAño.id;
    var mesVacio = false
    var mensaje = ''
    var accion = "";
    var mensajeProp = "";

    if (ultimoCierre === undefined) {
        if (dateStartSystem.length !== 0) {
            var lastDateStartSystem = moment(dateStartSystem[0].fechacreacion).endOf('month');
            if (moment().format('YYYY-MM-DD') < lastDateStartSystem.format('YYYY-MM-DD')) {
                mesVacio = true
                mensaje = 'El último mes se podrá cerrar después del: ' + lastDateStartSystem.format('YYYY-MM-DD');
            } else {
                accion = 'closeFirstMonth';
                mensajeProp = '¿Está seguro de cerrar el último mes?';
                const firstDayMonth = lastDateStartSystem.startOf('month').format('YYYY-MM-DD');
                const lastDayMonth = lastDateStartSystem.endOf('month').format('YYYY-MM-DD');
                const month = lastDateStartSystem.format('MMMM');
                const year = lastDateStartSystem.format('YYYY');
                arrayfechas.push({
                    id: '',
                    fechaFinalCierre: lastDayMonth,
                    fechaInicialCierre: firstDayMonth,
                    mesAño: month + '-' + year
                })
            }
        }
    } else {
        if (idCierre === '') {
            accion = "cierre"
            mensajeProp = "¿Está seguro de cerrar el último mes?"
        } else {
            accion = "cierreAbierto"
            mensajeProp = "¿Está seguro de cerrar el mes abierto?"
        }
    }

    const mesesAños = arrayfechas.map(item => {
        const av = { id: item.id, fechaFinalCierre: item.fechaFinalCierre, fechaInicialCierre: item.fechaInicialCierre, mesAño: item.mesAño }
        return av
    });

    const onMesesAñosChange = (e) => {
        setSelectedMesAño(e.value);
    }

    const toast = useRef(null);

    const idUsuario = cookies.get('idUsuario')

    var mesAñoSeleccionado = false
    if (ultimoCierre !== undefined) {
        if (mesesAños.length === 0) {
            mesVacio = true
            mensaje = 'El último mes se podrá cerrar después del: ' + newLastDate
        }
    }

    if (mesAño !== '') {
        mesAñoSeleccionado = true
    } else {
        mesAñoSeleccionado = false
    }

    const Alert = () => {
        const toast = useRef(null);
        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                </div>
            );
        }

        return (
            <>
                <Toast ref={toast} />
                <Button label="Guardar" onClick={() => onClick('displayConfirmation')} className="p-button-outlined p-button-success"
                    tooltip="Guardar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                />
                <div className="dialog-demo">
                    <div className="card">
                        <Dialog className="modalConfirm" header="Advertencia" visible={displayConfirmation} modal style={{ textAlign: 'center' }} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>No ha seleccionado un mes para cerrar</span>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </>
        )
    }


    /*   console.log(ultimoCierre) */

    const BotonRegresar = () => {
        return (
            <div className="p-field">
                {mesAñoSeleccionado
                    ?
                    <Button label="Regresar" className="p-button-outlined p-button-secondary regresar" style={{ marginLeft: '5px' }}
                        tooltip="Regresar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={() => setSelectedMesAño({ id: '', fechaFinalCierre: '', fechaInicialCierre: '', mesAño: '' })}
                    />
                    :
                    <Link to="/registroActividades/inicio" className="regresar" style={{ marginLeft: '5px' }}>
                        <Button label="Regresar" className="p-button-outlined p-button-secondary regresar"
                            tooltip="Regresar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                        />
                    </Link>
                }

            </div>
        )
    }
    if (ultimoCierre !== undefined) {
        if (idCierre === '') {
            accion = "cierre"
            mensajeProp = "¿Está seguro de cerrar el último mes?"
        } else {
            accion = "cierreAbierto"
            mensajeProp = "¿Está seguro de cerrar el mes abierto?"
        }
    }


    return (
        <div>
            <Toast ref={toast} />
            <div className="p-fluid p-formgrid p-grid">
                <form style={{ display: "contents" }}>
                    <div className="p-field p-col-12 p-md-6">
                        <h3>Cerrar mes abierto</h3>
                        <label className="labels" >Selecionar mes<span className="require">*</span></label>
                        <Dropdown value={selectedMesAño} options={mesesAños} onChange={onMesesAñosChange} name="mesAño" optionLabel="mesAño"
                            placeholder="Seleccione un mes para Cerrar" disabled={mesVacio} emptyMessage="No se encuentran resultados"
                        />
                        <p className="mensajeError">{mensaje}</p>
                    </div>
                </form>
            </div>
            <div className="p-formgroup-inline">
                {
                    mesAñoSeleccionado
                        ?
                        <ConfirmBtn
                            mensaje={mensajeProp}
                            accion={accion}
                            idUsuariosProp={idUsuario}
                            idCierreProp={idCierre}
                            dateEndProp={selectedMesAño.fechaFinalCierre}
                            dateStartProp={selectedMesAño.fechaInicialCierre}
                        />
                        :
                        <Alert />
                }
                <BotonRegresar />
            </div>
        </div>
    )
}

export default CierreDefinitivo