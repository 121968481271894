import React from 'react'
import { Link } from 'react-router-dom'

import '../assets/css/NotFound.css'

class NotFound extends React.Component {
    render() {
        return (
            <>

                <div className="mainbox">
                    <div className="err">4</div>
                    <i className="far fa-question-circle fa-spin"></i>
                    <div className="err2">4</div>
                    <div className="msg">La página asociada a esta ruta no existe. Volver al <Link className="a" to="/">inicio de sesión.</Link></div>
                </div>
                {/* <div className="playgroundNF">
                    <div className="contentNF">

                        <div className="fourzerofourNF">4</div>

                        <div className="circleNF"></div>

                        <div className="fourzerofourNF">4</div>

                    </div>
                    <span className="spanNF">La página asociada a esta ruta no se encuentra desarrollada</span>
                </div> */}
            </>
        )
    }

}

export default NotFound