import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/areaBusiness/update'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/areaBusiness'

const ConfirmBtn = ({ areaProp, usuarioProp, idAreaProp, creadoporProp, titulo, mensaje, accion, icono, clase, label, tooltipProp, fechacreacionProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "Crear") {
            crearArea()
            setDisabled(true);
        } else if (accion === "Actualizar") {
            actualizarArea()
            setDisabled(true);
        } else if (accion === "Eliminar") {
            setDisabled(true);
            eliminarArea()
        }
    }

    const crearArea = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuarioProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                id: "",
                idempresa: {
                    id: 1
                },
                nombreareanegocio: areaProp,
            }
        )
        /* console.log(json) */
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/areasNegocio"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Área de negocio existente en el sitema', life: 4000 });
                }else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const actualizarArea = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuarioProp,
                creadopor: creadoporProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: fechacreacionProp,
                id: idAreaProp,
                idempresa: {
                    id: 1
                },
                nombreareanegocio: areaProp
            }
        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/areasNegocio"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'Área de negocio existente en el sitema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
                
            })



    }
    const urlEliminar = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/areaBusiness/delete?id=' + idAreaProp
    const eliminarArea = async () => {
        await axios.delete(urlEliminar,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/areasNegocio"
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled}/>
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase} style={{ margin: '5px 5px 0px' }} 
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{mensaje}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn