import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SidebarMenu from "../componets/Home/MenuSidebar/sidebarMenu";
import BreadCrumb from "../componets/Home/BreadCrumb"
import DataTable from "../componets/Objetivos/DataTable";
import Cookies from "universal-cookie";
import axios from 'axios';

const cookies = new Cookies();

const Objetivos = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[17].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Objetivos</span>
    )

    const [monthlyReportDataAllData, setMonthlyReportDataAllData] = useState([]);

    const getMonthlyReportDataAllData = async () => {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/MonthlyReportData/MonthlyReportData/allDataUsers'
        await axios.post(url,
            null,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                setMonthlyReportDataAllData(response.data)
            })
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    useEffect(() => {
        getMonthlyReportDataAllData()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">
                    <h1 >Consultar Objetivos</h1>
                </div>
                <div className="tabla">
                    <DataTable
                        permissionsProp={permisos}
                        fullTableProp={false}
                        individualMonthReportProp={false}
                        monthlyReportDataAllDataProp={monthlyReportDataAllData}
                    />
                </div>
            </div>
        </div>
    )
}

export default Objetivos