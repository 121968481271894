import React from 'react'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

const DataTableTotalProjects = ({ dataProp, stylesProp }) => {
    
    var total = 0
    dataProp.forEach(element => {
        total += parseFloat(element.totalHoras)
    });

    var arrayTotal = [{
        proyecto: "Total horas proyectos",
        totalHoras: total.toFixed(2),
        totalPorcentajes: 100
    }]
    var newDataProp = arrayTotal.concat(dataProp)
    const newData = newDataProp.map(item => {
        return {
            ...item,
            sumaPorcentaje: (parseFloat(item.totalHoras) / parseFloat(newDataProp[0].totalHoras))*100
        }
    })
    const otherNewData = newData.map(item => {
        return {
            ...item,
            sumaPorcentaje: item.sumaPorcentaje.toFixed(2)
        }
    })
    return (
        <Table
            data={otherNewData}
        >
            <TableHeader textAlign={"center"}>
                <TableCell style={stylesProp.textTable}>
                    Proyecto
                </TableCell>
                <TableCell style={stylesProp.textTable}>
                    Horas
                </TableCell>
                <TableCell style={stylesProp.textTable}>
                    Horas %
                </TableCell>
            </TableHeader>
            <TableBody textAlign={"center"} >
                <DataTableCell getContent={(r) => r.proyecto} style={stylesProp.textCell} />
                <DataTableCell getContent={(r) => r.totalHoras} style={stylesProp.textCell} />
                <DataTableCell getContent={(r) => r.sumaPorcentaje} style={stylesProp.textCell} />
            </TableBody>
        </Table >
    )
}

export default DataTableTotalProjects