import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import { addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Link } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import Services from '../../service/Services';
import '../../assets/css/DataTable.css';
import BtnEliminar from './BtnEliminar';
import BtnCrearActividad from './BtnCrearActividad'
import BtnActualizarActividad from './BtnActualizarActividad'
import Mensaje from './Mensaje';
import { UseMedia, CambiarFormatoFecha, EstandarFormatoFecha, SumarDias, RestarMeses } from '../Funciones';
import axios from 'axios';
import Cookies from 'universal-cookie';
import BtnCopiarActividades from './CopiarActividades/BtnCopiarActividades';
import BtnNotas from './BtnNotas';
import MensajeRestriccion from './MensajeRestriccion';

const cookies = new Cookies();

const TableRegistrar = ({ permisosProp, mostrarMensajeProp, rangoFechaInicioProp, rangoFechaFinProp, diferenciaHorasProp }) => {

    const [servicio, setServicio] = useState([]);
    const [saveServicio, setSaveServicio] = useState([]);
    const [newServicio, setNewServicio] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cierres, setCierres] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState(null);
    const [clientsSession, setClientsSession] = useState([]);
    const [sendIdsActivities, setSendIdsActivities] = useState([]);
    const [servicioProyecto, setServicioProyecto] = useState([]);
    const [servicioTipoActividad, setServicioTipoActividad] = useState([]);
    const [tipoNovedades, setTipoNovedades] = useState([]);

    const getServices = new Services();
    const usuario = cookies.get("usuario");
    const [selectedClients, setSelectedClients] = useState(null)

    useEffect(() => { //Se crea una instancia para luego cancelar las peticiones

        getServices.getAsociacionProyectoUsuarioVigente().then(data => {

            if (data !== undefined) {
                setServicioProyecto(data)
                var clientsUserSession = data.map(element => {
                    return {
                        id: element.cliente.id,
                        razonsocial: element.cliente.nombre,
                    }
                });
                const tableClients = {}
                const uniqueClients = clientsUserSession.filter(function (object) {
                    return tableClients.hasOwnProperty(object.id) ? false : (tableClients[object.id] = true)
                })
                setClientsSession(uniqueClients)

                setSelectedClients(uniqueClients.map(item => {
                    return {
                        name: item.razonsocial,
                        id: item.id
                    }
                }))
                setItemsSelectedClient(uniqueClients.length)
            }
        });

        getServices.getTipoActividades().then(data => {
            if (data !== undefined) {
                setServicioTipoActividad(data)
            }
        });

        getServices.getCierres().then(data => {
            if (data !== undefined) {
                setCierres(data)
            }
        });

        getServices.getTipoNovedad().then(data => {
            if (data !== undefined) {
                setTipoNovedades(data)
            }
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const dt = useRef(null);
    const toast = useRef(null);

    const [itemsSelectedClient, setItemsSelectedClient] = useState(0);

    const [proyecto, setProyecto] = useState(null);

    const proyectos = servicioProyecto.map(item => {
        const av = {
            id: item.id,
            name: item.nombre
        }
        return av
    })

    const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
    const onProyectoChange = (e) => {
        if (e.value !== null) {
            var newFilter = e.value.map(item => item.name)
            dt.current.filter(newFilter, 'proyecto.nombreproyecto', 'in');
            setItemsSelectedProject(e.value.length)
        } else {
            dt.current.filter(null, 'proyecto.nombreproyecto', 'in');
            setItemsSelectedProject(0)
        }
        setProyecto(e.value);
    }

    const [itemsSelectedTypeActivity, setItemsSelectedTypeActivity] = useState(0);
    const [tipoActividad, setTipoActividad] = useState(null);

    const tipoActividades = servicioTipoActividad.map(item => {
        const av = {
            id: item.id,
            name: item.nombretipoactividad
        }
        return av
    })

    const onTipoActividadChange = (e) => {

        dt.current.filter(e.value, 'tipoActividad.nombretipoactividad', 'in');
        if (e.value !== null) {
            var newFilter = e.value.map(item => item.name)
            dt.current.filter(newFilter, 'tipoActividad.nombretipoactividad', 'in');
            setItemsSelectedTypeActivity(e.value.length)
        } else {
            dt.current.filter(null, 'tipoActividad.nombretipoactividad', 'in');
            setItemsSelectedTypeActivity(0)
        }
        setTipoActividad(e.value);
    }

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        dt.current.filter(e.value, 'sendDate', 'custom');
        setDate(e.value);
    }

    const filterDate = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value === formatDate(filter);
    }

    const formatDate = (date) => {
        return CambiarFormatoFecha(date)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    var arrayClient = []
    const optionClients = clientsSession.map(item => {
        const av = { name: item.razonsocial, id: item.id }

        return av
    })

    if (selectedClients !== null) {

        const filterClient = saveServicio.filter(item => {
            var idClient = item.cliente.id
            return parseInt(selectedClients.id) === parseInt(idClient)
        })

        filterClient.forEach(x => {
            arrayClient.push(x)
        })

    }

    const onClientsChange = (e) => {
        setSelectedClients(e.value)
        if (e.value !== null && e.value.length !== 0) {
            e.value.forEach(element => {
                const filterClient = saveServicio.filter(item => {
                    var idClient = item.cliente.id
                    return parseInt(element.id) === parseInt(idClient)
                })
                filterClient.forEach(x => {
                    arrayClient.push(x)
                })
                setItemsSelectedClient(e.value.length)
                setServicio(arrayClient)
            })
        } else {
            setItemsSelectedClient(0)
            setServicio(saveServicio)
        }
    }

    //Validar tooltip
    var showTooltipClient = false

    if (selectedClients !== null && selectedClients.length !== 0) {
        if (selectedClients.length > 0 && selectedClients.length <= 10) {
            showTooltipClient = true
        }
    }

    // filtros de la tabla 

    const [fechaInicioError, setFechaInicioError] = useState(false)
    const [mensajeFechaInicioError, setMensajeFechaInicioError] = useState('')
    const [fechaFinError, setFechaFinError] = useState(false)
    const [mensajeFechaFinError, setMensajeFechaFinError] = useState('')

    const currentDate = new Date()
    const threeMonthsBeforeToday = RestarMeses(new Date(), 1)

    const [dateStart, setDateStart] = useState(threeMonthsBeforeToday);

    const onDateStartChange = (e) => {
        setDateStart(e.value);
        if (selectedClients !== null) {
            rangeActivities(e.value, dateEnd, selectedClients)
        }
    }

    const [dateEnd, setDateEnd] = useState(currentDate);

    const onDateEndChange = (e) => {
        setDateEnd(e.value);
        if (selectedClients !== null) {
            rangeActivities(dateStart, e.value, selectedClients)
        }
    }

    const FechaFilter = <div className="p-fluid p-grid">
        <div className="p-md-12">
            <Calendar placeholder="Seleccione " className="fechaFlilter" style={{ marginBottom: '-7px' }} value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es" showButtonBar />
        </div>
    </div>

    const ProyectoFilter = <MultiSelect value={proyecto} options={proyectos} onChange={onProyectoChange} placeholder="Seleccione " filter optionLabel='name'
        className="p-column-filter" showClear emptyFilterMessage="No hay registros" maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`}
    />;


    const TipoActividadFilter = <MultiSelect value={tipoActividad} options={tipoActividades} onChange={onTipoActividadChange} placeholder="Seleccione " filter optionLabel='name'
        className="p-column-filter" showClear emptyFilterMessage="No hay registros" maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedTypeActivity} Elementos Seleccionados`}
    />

    useEffect(() => {
        const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        const currentDate = new Date()
        const threeMonthsBeforeToday = RestarMeses(new Date(), 1)
        setDateStart(threeMonthsBeforeToday)
        setDateEnd(currentDate)
        if (selectedClients !== null) {
            rangeActivities(threeMonthsBeforeToday, currentDate, selectedClients, source)
        }
        return () => {
            if (source) {
                source.cancel()
            }
        }
    }, [selectedClients])// eslint-disable-line react-hooks/exhaustive-deps

    const rangeActivities = async (dateStart, dateEnd, clients, source) => {

        if (dateStart !== null && dateEnd !== null) {
            if (dateStart <= dateEnd) {
                const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivity'
                const newDateStart = CambiarFormatoFecha(dateStart)
                const newDateEnd = CambiarFormatoFecha(dateEnd)
                const json = JSON.stringify({
                    esadicional: "0",
                    fecFinal: newDateEnd,
                    fecInicial: newDateStart,
                    usuario: usuario
                })
                await axios.post(url, json,
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        },
                        cancelToken: source?.token
                    })
                    .then(response => {
                        if (response !== undefined) {
                            toast.current.show({ severity: 'success', summary: 'Consulta Satisfactoria', detail: response.data.message, life: 3000 });
                            var d = new Date()
                            var gmtHours = -d.getTimezoneOffset() / 60;

                            var dataClients = []
                            clients.forEach(element => {
                                var result = response.data.filter(x => {
                                    return parseInt(element.id) === x.cliente.id
                                })
                                result.forEach(z => {
                                    dataClients.push(z)
                                })
                            })

                            var newData = dataClients.map(item => {

                                var newDate
                                if (gmtHours < 0) {
                                    newDate = SumarDias(new Date(item.fecha), 1)
                                } else {
                                    newDate = new Date(item.fecha)
                                }
                                return {
                                    ...item,
                                    fecha: EstandarFormatoFecha(newDate),
                                    sendDate: item.fecha,                               
                                }
                            })
                            setServicio(newData)
                            setNewServicio(newData)
                            setSaveServicio(newData)
                            setFilter(newData)
                            setFechaFinError(false)
                            setMensajeFechaFinError('')
                            setFechaInicioError(false)
                            setMensajeFechaInicioError('')
                            setLoading(false)
                        }
                    })
                    .catch(function (error) {
                        //console.log(error.response)
                        if (error.response !== undefined) {
                            if (error.response.status) {
                                toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 4000 });
                            }
                        }
                    })
            } else {
                setFechaInicioError(true)
                setMensajeFechaInicioError('La fecha inicio no puede ser mayor a la fecha fin')
                setFechaFinError(false)
                setMensajeFechaFinError('')
            }
        } else if (dateStart !== null && dateEnd === null) {
            setFechaFinError(true)
            setMensajeFechaFinError('Seleccione una fecha fin para completar el filtro')
            setFechaInicioError(false)
            setMensajeFechaInicioError('')

        } else if (dateStart === null && dateEnd !== null) {
            setFechaInicioError(true)
            setMensajeFechaInicioError('Seleccione una fecha Inicio para completar el filtro')

        }

    }

    // variables de sesión 
    const tipodocumento = cookies.get('tipoDocumento')
    const documento = cookies.get('numeroDocumento')
    const nombre = cookies.get('nombre')
    const cargo = cookies.get('cargo')


    //estados para validar fechas y mostrar mensajes

    const [valorHorasDia, setValorHorasDia] = useState(0)
    const [lableHorasDia, setLableHorasDia] = useState('')
    const [valorHorasMes, setValorHorasMes] = useState(0)
    const [lableHorasMes, setLableHorasMes] = useState('')
    const [valorHorasHoy, setValorHorasHoy] = useState(0)
    const [valorFiltro, setValorFlitro] = useState(0)

    // validar permisos para acciones 

    var permisoActivityCreate
    if (permisosProp[1].permisos[0] === "Crear") {
        permisoActivityCreate = true
    } else {
        permisoActivityCreate = false
    }
    var permisoActivityUpdate
    if (permisosProp[1].permisos[1] === "Editar") {
        permisoActivityUpdate = true
    } else {
        permisoActivityUpdate = false
    }
    var permisoActivityDelete
    if (permisosProp[1].permisos[2] === "Eliminar") {
        permisoActivityDelete = true
    } else {
        permisoActivityDelete = false
    }

    let eliminarActividades = []

    const [validarActividadSeleccionada, setValidarActividadSeleccionada] = useState(false)
    const [mensajeSeleccion, setMensajeSeleccion] = useState('Debe seleccionar al menos una actividad')

    const onChangeSelectActivities = (e) => {

        var validateSelection = []
        if (e.value !== null) {
            if (e.value.length === 0) {
                setValidarActividadSeleccionada(false)
                setMensajeSeleccion("Debe seleccionar al menos una actividad")
            } else {
                e.value.forEach(x => {
                    eliminarActividades.push(x.id)
                    tipoNovedades.forEach(y => {
                        if (x.tipoActividad.nombretipoactividad === y.descripcion) {
                            validateSelection.push(x)
                        }
                    })
                })
            }

        }
        if (validateSelection.length !== 0) {
            setValidarActividadSeleccionada(false)
            setMensajeSeleccion("Una de las actividades seleccionadas es una novedad y no se pueden copiar o eliminar")
        } else {
            setMensajeSeleccion("¿Está seguro que desea eliminar las actividades seleccionadas?")
            setValidarActividadSeleccionada(true)
        }

        setSelectedActivities(e.value)
        setSendIdsActivities(eliminarActividades)

    }
    const styleLoading = {
        textAlign: 'center',
        marginBottom: '12px'
    }

    const renderHeader = () => {

        return (
            <>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" htmlFor="TipoDocumento">Tipo de Documento</label>
                        <InputText id="tipoDocumento" type="text" name="tipoDocumento" value={tipodocumento} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" htmlFor="numDocumento">Número documento</label>
                        <InputText id="numDocumento" type="text" name="numDocumento" value={documento} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" htmlFor="Nombre">Nombre Completo</label>
                        <InputText id="Nombre" type="text" name="Nombre" value={nombre} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels" htmlFor="Cargo">Cargo</label>
                        <InputText id="Cargo" type="text" name="Cargo" value={cargo} disabled />
                    </div>
                </div>
                <div style={styleLoading}>
                    {loading && <p>Cargando información...</p>}
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels" htmlFor="Nombre">Fecha Inicio</label>
                        <div className="">
                            <Calendar placeholder="Seleccione una fecha inicio" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es" showButtonBar
                                className={fechaInicioError ? "p-invalid fechaFlilter" : "fechaFlilter"}
                            />
                        </div>
                        <p className="mensajeError">{fechaInicioError ? mensajeFechaInicioError : ""}</p>
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels" htmlFor="Nombre">Fecha Fin</label>
                        <div className="">
                            <Calendar placeholder="Seleccione una fecha fin" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" showButtonBar
                                className={fechaFinError ? "p-invalid fechaFlilter" : "fechaFlilter"}
                            />
                        </div>
                        <p className="mensajeError">{fechaFinError ? mensajeFechaFinError : ""}</p>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label className="labels">Clientes</label>
                        {
                            showTooltipClient ?
                                <Tooltip target=".tooltip-button-client" autoHide={false}>
                                    <div className="flex align-items-center">
                                        {
                                            selectedClients.map((item, index) => {
                                                return (<div key={index}>{item.name}</div>)
                                            })
                                        }
                                    </div>
                                </Tooltip>
                                :
                                ''
                        }

                        <MultiSelect className="tooltip-button-client" value={selectedClients} options={optionClients} onChange={onClientsChange} placeholder="Seleccione" optionLabel='name'
                            selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`} maxSelectedLabels={1} emptyFilterMessage="No hay resultados" showClear />

                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels">{lableHorasMes}</label>
                        <InputText type="text" keyfilter={/^[^<0-9>*!=°|(#$%&)+-./?¡¿'{}]+$/} placeholder={valorHorasMes} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels">Total horas según filtro</label>
                        <InputText type="text" keyfilter={/^[^<0-9>*!=°|(#$%&)+-./?¡¿'{}]+$/} placeholder={valorFiltro} disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label className="labels">{lableHorasDia}</label>
                        <InputText type="text" keyfilter={/^[^<0-9>*!=°|(#$%&)+-./?¡¿'{}]+$/} placeholder={valorHorasDia} disabled />
                    </div>
                </div>
                <div className="delete">
                    <div className="">
                        {permisoActivityCreate ?
                            <BtnCrearActividad
                                horasHoyProp={valorHorasHoy}
                            />
                            :
                            ""
                        }
                    </div>

                    <div>
                        {permisoActivityDelete ?
                            <BtnEliminar
                                icono="pi pi-trash"
                                clase="p-button-danger "
                                titulo="Eliminar Actividades"
                                accion="Eliminar"
                                label="Eliminar Actividades"
                                mensaje={mensajeSeleccion}
                                actividadSeleccionadaProp={validarActividadSeleccionada}
                                indicadorProp="0"
                                tooltipProp="Eliminar"
                                idActividadesProp={sendIdsActivities}

                            />
                            :
                            ""
                        }
                    </div>

                </div>
            </>
        )
    }

    const header = renderHeader()

    const [filter, setFilter] = useState(null)

    const CalcularFechas = () => {

        if (filter !== null) {
            var hourCounter = 0
            if (filter !== undefined) {
                filter.map(item => {
                    hourCounter = hourCounter + item.numerohoras

                    return filter
                })
                setValorFlitro(hourCounter)
            }

        }

        const fechaActual = new Date();
        let month = fechaActual.getMonth() + 1;
        let day = fechaActual.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        if (date !== null) {
            const fechaFilter = date

            const fechaConvertida = CambiarFormatoFecha(fechaFilter)

            var horasDia = 0

            const result = servicio.filter(serv => serv.sendDate === fechaConvertida)

            result.map(item => {
                horasDia = horasDia + item.numerohoras

                return result
            })
            setLableHorasDia('Total horas día:')
            setValorHorasDia(0)
            setValorHorasDia(horasDia)

        } else {
            const today = fechaActual.getFullYear() + '-' + month + '-' + day
            var horasHoy = 0

            const result = servicio.filter(serv => serv.sendDate === today)
            result.map(item => {
                horasHoy = horasHoy + item.numerohoras

                return result
            })

            setValorHorasDia(horasHoy)
            setValorHorasHoy(horasHoy)
            setLableHorasDia('Total horas hoy:')


        }

        const mesActual = fechaActual.getFullYear() + '-' + month

        var horasMesActual = 0

        const result = servicio.filter(function (objeto) {
            return objeto.sendDate.includes(mesActual)
        })

        result.map(item => {
            horasMesActual = horasMesActual + item.numerohoras

            return result
        })
        setValorHorasMes(horasMesActual)
        setLableHorasMes('Total horas mes:')

    }

    // Obtener si el cierre esta activo o inactivo para el usuario en sesión

    const cierreActivo = cookies.get('cierreActivo')
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    mesesCerrados.forEach(element => {
        fechaCierre.push(element.fechaFinalCierre)
    })

    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))


    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))
    let month = fechaUltimoCierre.getMonth() + 1;
    let day = fechaUltimoCierre.getDate() + 1;

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }
    const fecha = fechaUltimoCierre.getFullYear() + '-' + month + '-' + day

    /* console.log(fecha) */

    const mesAbierto = cierres.filter(function (objeto) {
        var id = parseInt(cierreActivo)
        return objeto.id === id
    })
    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    cierres.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })


    const validacionMesCerrado = (rowData) => {
        let disabled = false

        if (cierres.length !== 0) {
            if (cierres) {
                if (mesAbierto.length !== 0) {
                    if (rowData.sendDate >= getMesAbierto.fechaInicialCierre && rowData.sendDate <= getMesAbierto.fechaFinalCierre) {
                        disabled = false
                    } else {
                        if (rowData.sendDate < fecha) {
                            disabled = true
                        } else {
                            disabled = false
                        }
                    }
                } else if (rowData.sendDate < fecha) {
                    disabled = true
                } else {
                    disabled = false
                }
            }
        }
        return disabled
    }

    const Acciones = (rowData) => {

        const found = tipoNovedades.some(y => rowData.tipoActividad.id === y.tipoActividad.id);
        var disabled = validacionMesCerrado(rowData)
        return (
            <div className="Botones">
                {permisoActivityUpdate === true && found === false ?
                    <BtnActualizarActividad
                        fechaProp={rowData.sendDate}
                        clienteProp={rowData.cliente.razonsocial}
                        idClienteProp={rowData.cliente.id}
                        proyectoProp={rowData.proyecto.nombreproyecto}
                        idProyectoProp={rowData.proyecto.id}
                        tipoActividadProp={rowData.tipoActividad.nombretipoactividad}
                        idTipoActividadProp={rowData.tipoActividad.id}
                        ticketProp={rowData.ticket}
                        areaProp={rowData.areanegocio.nombreareanegocio}
                        idAreaProp={rowData.areanegocio.id}
                        sistemaProp={rowData.aplicacion.nombreaplicacion}
                        idSistemaProp={rowData.aplicacion.id}
                        actividadProp={rowData.descripcionactividad}
                        horasProp={rowData.numerohoras}
                        idActividadProp={rowData.id}
                        creadoPorProp={rowData.creadopor}
                        fechaCreacionProp={rowData.fechacreacion}
                        horasHoyProp={valorHorasHoy}
                        disabledProp={disabled}
                        notesDescriptionProp={rowData.notas}
                        categoriaProp={rowData.tipoActividad.categoria.descripcion}
                        idCategoriaProp={rowData.tipoActividad.categoria.id}
                        idObjetivoProp={rowData.idObjetivo}
                        idLogroProp={rowData.idLogro}
                        idEntregableProp={rowData.idEntregable}
                    />
                    :
                    permisoActivityUpdate === true && found === true ?
                        <MensajeRestriccion
                            mensajeProp="Una de las actividades seleccionadas es una novedad y no se pueden Actualizar"
                            classNameProp="p-button-secondary"
                            iconProp="pi pi-pencil"
                            tooltipProp="Editar"
                        />
                        :
                        ""
                }
                <>
                    {permisoActivityCreate === true && found === false ?
                        <BtnCopiarActividades
                            horasHoyProp={valorHorasHoy}
                            fechaProp={rowData.sendDate}
                            clienteProp={rowData.cliente.razonsocial}
                            idClienteProp={rowData.cliente.id}
                            proyectoProp={rowData.proyecto.nombreproyecto}
                            idProyectoProp={rowData.proyecto.id}
                            tipoActividadProp={rowData.tipoActividad.nombretipoactividad}
                            idTipoActividadProp={rowData.tipoActividad.id}
                            ticketProp={rowData.ticket}
                            areaProp={rowData.areanegocio.nombreareanegocio}
                            idAreaProp={rowData.areanegocio.id}
                            sistemaProp={rowData.aplicacion.nombreaplicacion}
                            idSistemaProp={rowData.aplicacion.id}
                            actividadProp={rowData.descripcionactividad}
                            horasProp={rowData.numerohoras}
                            idActividadProp={rowData.id}
                            creadoPorProp={rowData.creadopor}
                            fechaCreacionProp={rowData.fechacreacion}
                            disabledProp={disabled}
                            notesDescriptionProp={rowData.notas}
                            categoriaProp={rowData.tipoActividad.categoria.descripcion}
                            idCategoriaProp={rowData.tipoActividad.categoria.id}
                            idObjetivoProp={rowData.idObjetivo}
                            idLogroProp={rowData.idLogro}
                            idEntregableProp={rowData.idEntregable}
                            copiarActividadesProp={true}
                        /> :
                        permisoActivityCreate === true && found === true ?
                            <MensajeRestriccion
                                mensajeProp="Una de las actividades seleccionadas es una novedad y no se puede copiar"
                                classNameProp="p-button-warning"
                                iconProp="pi pi-clone"
                                tooltipProp="Copiar Actividad"

                            />
                            :
                            ''
                    }
                </>
                <>
                    <BtnNotas
                        tooltipProp={'Detalle de notas de la actividad'}
                        notesDescriptionProp={rowData.notas}
                        titleProp={'Detalle de notas'}
                        disabledProp={true}
                        footerProp={false}
                    />
                </>


            </div>
        );
    }

    let small = UseMedia("(max-width: 760px)");

    var permisosAcciones
    if (permisoActivityCreate === false && permisoActivityUpdate === false && permisoActivityDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    // ejecutamos la función de calcular fechas en cada render 
    useEffect(() => {
        CalcularFechas()
    })

    const showSelectionElement = (rowData) => {
        var disabled = validacionMesCerrado(rowData)

        return !disabled
    }
    const onRowSelect = (e) => {

    }

    var newRangoFechaInicioProp = SumarDias(new Date(rangoFechaInicioProp), 1)
    var newRangoFechaFinProp = SumarDias(new Date(rangoFechaFinProp), 1)

    var showRangoInicio = EstandarFormatoFecha(newRangoFechaInicioProp)
    var showRangoFin = EstandarFormatoFecha(newRangoFechaFinProp)

    return (
        <div className="datatable-responsive-demo">
            <Toast ref={toast} />
            {
                mostrarMensajeProp
                    ?
                    <Mensaje
                        validarProp={mostrarMensajeProp}
                        enviarMensajeProp={`Señor usuario entre ${showRangoInicio} y ${showRangoFin} tiene ${diferenciaHorasProp} horas pendientes por registrar`}
                    />
                    :
                    ""
            }

            <div className="card">
                <DataTable ref={dt} value={newServicio} className="p-datatable-responsive-demo" paginator rows={10} header={header} style={{ overflow: 'hidden' }}
                    showSelectionElement={showSelectionElement} onRowSelect={onRowSelect} selectionMode="checkbox"
                    onValueChange={filteredData => setFilter(filteredData)} filterDisplay="row" loading={loading}
                    emptyMessage="No hay datos en el sistema" scrollable={small ? false : true} scrollHeight={small ? "" : "500px"} editMode="row"
                    sortField="sendDate" sortOrder={-1} selection={selectedActivities} onSelectionChange={e => onChangeSelectActivities(e)}>

                    <Column selectionMode="multiple" style={{ maxWidth: '4rem' }} ></Column>
                    <Column className="columna" style={{ minWidth: '16rem' }} field="sendDate" sortable header="Fecha" filter filterElement={FechaFilter} filterFunction={filterDate} showFilterMenu={false} />
                    {/* <Column filterElement={ClienteFilter} showFilterMenu={false} filter /> */}
                    <Column className="columna" style={{ minWidth: '16rem' }} field="proyecto.nombreproyecto" sortable header="Proyecto" filter filterElement={ProyectoFilter} showFilterMenu={false} />
                    <Column className="columna" style={{ minWidth: '16rem' }} field="tipoActividad.nombretipoactividad" sortable header="Tipo Actividad" filter filterElement={TipoActividadFilter} showFilterMenu={false} />
                    <Column className="columna" headerStyle={{ width: '160px' }} field="ticket" sortable header="Req.Ticket" />
                    <Column className="columna" headerStyle={{ width: '160px' }} field="areanegocio.nombreareanegocio" sortable header="Área Negocio" />
                    <Column className="columna" headerStyle={{ width: '185px' }} field="aplicacion.nombreaplicacion" sortable header="Sistema Aplicación" />
                    <Column className="columna" headerStyle={{ width: '160px' }} field="descripcionactividad" sortable header="Actividad" />
                    <Column className="columna" headerStyle={{ width: '160px' }} field="numerohoras" sortable header="Num Horas" />
                    {permisosAcciones ?
                        <Column header="Acciones" headerStyle={{ width: '180px' }} body={Acciones} />
                        :
                        <Column />
                    }
                </DataTable>

            </div>
            <div className="ContainerBtnRegresar">
                <Link to="/registroActividades/inicio" className="BtnRegresar">Regresar</Link>
            </div>
        </div>
    );
}

export default TableRegistrar