import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Slider } from "primereact/slider";
import { addLocale } from "primereact/api";
import { CambiarFormatoFecha, SumarDias } from "../Funciones";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import Cookies from 'universal-cookie'
import axios from "axios";
const cookies = new Cookies()

const BtnUpdateDatail = ({ objectiveProp, idObjectiveProp, creationDateProp, createdByProp, clientProp, percentageProp,
    projectObjectiveProp, projectObjectiveCreationDateProp, projectsProp, dateProp,
    projectProp, dateStartValidityProp, dateEndValidityProp, individualMonthReportProp, setRunRenderProp, runRenderProp, updateDatailProp,
    reloadObjectiveProp, setReloadObjectiveProp, runRenderBlockProp, setRunRenderBlockProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp }) => {

    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [clientsUserSession, setClientsUserSession] = useState([]);

    const getService = new Services()
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            getService.getProyectosUsuarios().then(data => {
                if (data !== undefined) {
                    /////Proceso pra evitar que los clientes se repitan 
                    const listClients = []
                    data.forEach(element => {
                        var selectClients = {}
                        selectClients.id = parseInt(element.cliente.id)
                        selectClients.name = element.cliente.nombre
                        listClients.push(selectClients)
                    });
                    listClients.push({
                        id: parseInt(clientProp.id),
                        name: clientProp.razonsocial
                    })
                    const clientsTable = {}
                    const uniqueClients = listClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })

                    setSelectedClient([{
                        id: clientProp.id,
                        name: clientProp.razonsocial
                    }])
                    setClientsUserSession(uniqueClients)

                    const listProjects = []
                    data.forEach(element => {
                        listProjects.push(element)
                    })
                    listProjects.push({
                        id: projectProp.id,
                        nombre: projectProp.nombreproyecto,
                        fechainiciovigencia: projectProp.fechainiciovigencia,
                        fechafinvigencia: projectProp.fechafinvigencia,
                        cliente: projectProp.cliente
                    })

                    const projectsTable = {}
                    const uniqueProjects = listProjects.filter(function (object) {
                        return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                    })
                    setSelectedProject({
                        id: projectProp.id,
                        name: projectProp.nombreproyecto,
                        dateStartValidity: projectProp.fechainiciovigencia,
                        dateEndValidity: projectProp.fechafinvigencia
                    })
                    setProjectsUserSession(uniqueProjects)
                }
            });
        }
        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState(null);

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: item.id }
        return av

    })

    const onClientChange = (e) => {
        setSelectedProject({
            id: '',
            name: '',
            dateStartValidity: '',
            dateEndValidity: ''
        })
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    //Obtener datos del select tipo de proyecto

    const [selectedProject, setSelectedProject] = useState({
        id: '',
        name: '',
        dateStartValidity: '',
        dateEndValidity: ''
    })

    var projectClient = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const loadProjects = () => {

        if (selectedClient !== undefined) {
            if (selectedClient.length !== 0) {
                selectedClient.forEach(element => {
                    const result = projectsUserSession.filter(function (object) {
                        var idClient = parseInt(object.cliente.id)
                        return idClient === parseInt(element.id)
                    })

                    result.forEach(element => {
                        var selectedProject = {}
                        selectedProject.id = parseInt(element.id)
                        selectedProject.name = element.nombre
                        selectedProject.fechainiciovigencia = element.fechainiciovigencia
                        selectedProject.fechafinvigencia = element.fechafinvigencia
                        projectClient.push(selectedProject)
                    });
                    projectClient.sort(function (a, b) {
                        var textA = a.name;
                        var textB = b.name;
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                })
            }
        }
        const filterDate = projectClient.filter(element => {
            return element.fechafinvigencia >= dateV
        })
        filterDate?.forEach(element => {
            proyectoFechaVigente.push(element)
        })
        return proyectoFechaVigente
    }

    var dateValidError = false
    var messageDateValidError = ''

    if (selectedClient !== null) {
        loadProjects()
        if (proyectoFechaVigente.length === 0) {
            dateValidError = true
            messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
        } else {
            dateValidError = false
            messageDateValidError = ''
        }
        if (selectedClient.length === 0) {
            dateValidError = false
            messageDateValidError = ''
        }
    }

    const projects = proyectoFechaVigente.map(item => {
        const av = { name: item.name, id: item.id, dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
        return av
    })

    const onProjectChange = (e) => {
        setSelectedProject(e.value);
        setValidateChange(true)
    }

    const optionDisabledProject = (e) => {
        const data = projectsProp.filter(function (object) {
            return object.proyecto.id !== projectProp.id
        })
        var arrIds = data.map(item => {
            return item.proyecto.id
        })
        var disabled = false
        arrIds.forEach(element => {
            if (element === e.id) {
                disabled = true
            }
        })
        return disabled
    }

    //Obtener datos del porcentaje ingresado

    const [percentage, setPercentage] = useState(0);

    const onPercentageChange = (e) => {
        setPercentage(e.value)
        if (e.value === 100) {
            setDate(new Date())
            if (dateEnd === null) {
                setDateEnd(new Date())
            }
        } else {
            setDate(null)
        }
        setValidateChange(true)
    }

    var gmtHours = -d.getTimezoneOffset() / 60;

    // calendar de fecha  inicio

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidateChange(true)
    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidateChange(true)
    }

    /// calendar de fecha cumplimiento

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        let date = e.value
        setDate(date)
        if (dateEnd === null) {
            setDateEnd(date)
        }
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    ////validaciones proyectos
    var projectsError = false
    var menssageProjects = ''

    /// cuando ingrese un cliente 
    var disabledProject
    var disabledClient

    if (individualMonthReportProp === true) {
        disabledProject = true
        disabledClient = true
    } else {
        disabledProject = false
        disabledClient = false
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        const dateStartChange = new Date(dateStartValidityProp)
        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
        } else {
            setDateStart(dateStartChange)
        }
        if (dateEndValidityProp !== null) {
            const dateEndChange = new Date(dateEndValidityProp)
            if (gmtHours < 0) {
                setDateEnd(SumarDias(dateEndChange, +1))
            } else {
                setDateEnd(dateEndChange)
            }
        } else {
            setDateEnd(null)
        }
        if (dateProp !== null) {
            const dateChange = new Date(dateProp)
            if (dateProp !== null) {
                if (gmtHours < 0) {
                    setDate(SumarDias(dateChange, +1))
                } else {
                    setDate(dateChange)
                }
            } else {
                setDate(null)
            }
        } else {
            setDate(null)
        }
        setPercentage(percentageProp)

    }, [gmtHours, percentageProp, dateStartValidityProp, dateEndValidityProp, dateProp])


    //Almacenamos los valores para enviarlos por props

    const idProject = selectedProject.id

    const resetData = () => {
        setSelectedProject({
            id: projectProp.id,
            name: projectProp.nombreproyecto,
            dateStartValidity: projectProp.fechainiciovigencia,
            dateEndValidity: projectProp.fechafinvigencia
        })
        setSelectedClient([{
            id: clientProp.id,
            name: clientProp.razonsocial
        }])
        const dateStartChange = new Date(dateStartValidityProp)
        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
        } else {
            setDateStart(dateStartChange)
        }
        if (dateEndValidityProp !== null) {
            const dateEndChange = new Date(dateEndValidityProp)
            if (gmtHours < 0) {
                setDateEnd(SumarDias(dateEndChange, +1))
            } else {
                setDateEnd(dateEndChange)
            }
        } else {
            setDateEnd(null)
        }
        if (dateEndValidityProp !== null) {
            const dateChange = new Date(dateProp)
            if (gmtHours < 0) {
                setDate(SumarDias(dateChange, +1))
            } else {
                setDate(dateChange)
            }
        } else {
            setDate(null)
        }
        setPercentage(percentageProp)
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }
    const usuario = cookies.get('usuario');

    const renderFooter = (name) => {
        var createNewObjective = false
        if (usuario !== createdByProp) {
            createNewObjective = true
        }
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage={createNewObjective ? "El registro editado no fue creado con su usuario, por lo tanto, se creará un nuevo registro con los datos actualizados, tenga en cuenta seleccionar este nuevo registro para su informe." : "¿Estás seguro de guardar esta información?"}
                            action={createNewObjective ? "Create" : "Update"}
                            label="Actualizar"
                            icon="pi pi-pencil"
                            objectiveProp={objectiveProp}
                            percentageProp={percentage}
                            dateStartProp={CambiarFormatoFecha(dateStart)}
                            dateEndProp={dateEnd === null ? dateEnd : CambiarFormatoFecha(dateEnd)}
                            dateProp={date === null ? date : CambiarFormatoFecha(date)}
                            idProyectProp={idProject}
                            idProjectObjective={projectObjectiveProp}
                            projectObjectiveCreationDate={projectObjectiveCreationDateProp}
                            idObjectiveProp={idObjectiveProp}
                            statusProp={1}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            assingnedNewProjectObjective={false}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            setDisplayBasicProp={setDisplayBasic}
                            updateDatailProp={updateDatailProp}
                            deleteDetailProp={false}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    // validaciones
    // validar fechas dentro del rango del proyecto seleccionado

    var dateStartError = false
    var dateStartMessage = ''
    var dateEndError = false
    var dateEndMessage = ''

    const validateRangeDate = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        var newDateEnd = CambiarFormatoFecha(dateEnd)

        if (newDateStart >= selectedProject.dateStartValidity && newDateStart <= selectedProject.dateEndValidity &&
            newDateEnd >= selectedProject.dateStartValidity && newDateEnd <= selectedProject.dateEndValidity) {
            dateStartError = false
            dateStartMessage = ''
        } else {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio y fin están fuera del rango de fechas del proyecto seleccionado'
        }
    }

    const validateDateStart = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        if (newDateStart >= selectedProject.dateStartValidity && newDateStart <= selectedProject.dateEndValidity) {
            dateStartError = false
            dateStartMessage = ''
        } else {
            dateStartError = true
            dateStartMessage = 'La fecha de inicio esta fuera del rango de fechas del proyecto seleccionado'
        }
    }

    if (dateStart !== null && dateEnd === null && selectedProject.id !== '' && dateStartError === false) {
        validateDateStart()
    }

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        } else {
            if (dateStart !== null && dateEnd !== null && selectedProject.id !== '' && dateStartError === false) {
                validateRangeDate()
            }
        }
        if (dateStartMonthlyReportProp !== undefined && dateEndMonthlyReportProp !== undefined) {
            if (CambiarFormatoFecha(dateEnd) < CambiarFormatoFecha(dateStartMonthlyReportProp)) {
                dateEndError = true
                dateEndMessage = 'La fecha fin de asociación objetivo proyecto debe estar dentro del rango del periodo seleccionado'
            }
            if (CambiarFormatoFecha(dateStart) > CambiarFormatoFecha(dateEndMonthlyReportProp)) {
                dateStartError = true
                dateStartMessage = 'La fecha inicio de asociación objetivo proyecto debe estar dentro del rango del periodo seleccionado'
            }
        }

    }

    var dateError = false
    var dateMessage = ''

    // validar fechas dentro del rango del proyecto seleccionado

    const validateDate = () => {
        if (date <= dateStart) {
            dateError = true
            dateMessage = 'La fecha de cumplimiento no puede ser igual o menor a la fecha inicio de vigencia'
        } else {
            dateError = false
            dateMessage = ''
        }
    }

    if (date !== null && selectedProject.id !== '') {
        validateDate()
    }

    var updatePermission = false
    if (dateStart !== null && dateEnd !== null && dateStartError === false && selectedClient !== null && dateValidError === false
        && dateEndError === false && selectedProject.id !== '') {
        if (selectedClient.length !== 0) {
            updatePermission = true
        }
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar objetivo" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <MultiSelect value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name" disabled={disabledClient}
                            />
                        </div>
                        <div className={`p-field p-col-12 p-md-6`}>
                            <label className="labels" >Proyecto <span className="require">*</span></label>
                            <Dropdown value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                className={projectsError || dateValidError ? "p-invalid" : ""} emptyMessage="No hay resultados" name="project" optionLabel="name"
                                disabled={disabledProject} optionDisabled={optionDisabledProject} filter
                            />
                            <p className="mensajeError">{projectsError ? menssageProjects : ""}</p>
                            <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Inicio<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Fin<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateEndError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateEndError ? dateEndMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha Cumplimiento</label>
                            <Calendar placeholder="Seleccione una fecha" value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Porcentaje cumplimiento</label>
                            <h5 style={{ marginBottom: '13px' }}> {percentage} %</h5>
                            <Slider value={percentage} onChange={onPercentageChange} step={5} />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnUpdateDatail