import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../index.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
/* import { ProgressSpinner } from 'primereact/progressspinner'; */
import { ProgressBar } from 'primereact/progressbar';
import Services from '../service/Services';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import moment from 'moment';
import { UseMedia, EstandarFormatoFecha, SumarDias } from './Funciones';
import Cookies from 'universal-cookie';
import BtnNotas from '../componets/ConsultarActividades/BtnNotas';
const cookies = new Cookies();

const DataTableExport = ({ permisosProp }) => {

    const [actividadesRango, setActividadesRango] = useState([]);
    const [clientsSession, setClientsSession] = useState([]);
    const [typeActivityService, setTypeActivityService] = useState([]);
    const [servicesTypeProjects, setServicesTypesProjects] = useState([]);
    const [projectSession, setProjectSession] = useState([]);
    const [projects, setProjects] = useState([]);
    const [servicesTeamWork, setServicesTeamWork] = useState([]);
    const [servicesUsers, setServicesUsers] = useState([]);
    const [serviceSupplier, setServiceSupplier] = useState([]);
    const getServices = new Services();
    const numberDocSession = cookies.get('numeroDocumento')
    const fullNameSession = cookies.get('nombre')
    const [progressBar, setProgressBar] = useState(false)

    var tipoUsuarioCliente = false
    const tipoUsuario = cookies.get('tipoUsuario')

    if (tipoUsuario === 'Cliente') {
        tipoUsuarioCliente = true
    } else {
        tipoUsuarioCliente = false
    }

    useEffect(() => {
        const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones

        getServices.getClientesProyectosUsuario().then(data => {
            if (data !== undefined) {
                setProjectSession(data.map(item => {
                    return {
                        id: parseInt(item.id)
                    }
                }))
                const sendClients = []
                data.forEach(element => {
                    var selectedClients = {}
                    selectedClients.id = parseInt(element.cliente.id)
                    selectedClients.name = element.cliente.nombre
                    sendClients.push(selectedClients)
                });
                const tableClientes = {}
                const uniqueClients = sendClients.filter(function (object) {
                    return tableClientes.hasOwnProperty(object.id) ? false : (tableClientes[object.id] = true)
                })

                uniqueClients.sort(function (a, b) {
                    var textA = a.name.toLowerCase();
                    var textB = b.name.toLowerCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                setCliente(uniqueClients)
                setClientsSession(uniqueClients)
                setItemsSelectedClient(uniqueClients.length)
            }
        })

        getServices.getTypesProjects().then(data => {
            if (data !== undefined) {
                var newData = data.map(x => {
                    return {
                        name: x.nombretipoproyecto,
                        id: x.id
                    }
                })
                setServicesTypesProjects(newData);
                setSelectedTypeProject(newData);
                setItemsSelectedTypeProject(data.length)
            }
        })

        if (permisosProp[2].exportarTodos === "false") {
            setSelectedUsuario([{
                name: fullNameSession,
                id: numberDocSession
            }])
        }
        getServices.getTipoActividades().then(data => {
            if (data !== undefined) {
                setTypeActivityService(data)
            }
        });

        getServices.getSuppliers().then(data => setServiceSupplier(data))

        // Función de limpieza para cancelar la solicitud cuando el componente se desmonta o cambia de pestaña
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        };

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const dt = useRef(null);
    const toast = useRef(null);

    //Retorna el campo de notas
    var newRange = []
    if (actividadesRango !== null) {
        if (actividadesRango.length > 0) {
            actividadesRango.map(item => {

                var jsonNewRange
                jsonNewRange = {
                    Id: item.idUnique,
                    NombreCompleto: item.nombreUsuario,
                    NumeroDocumento: item.numerodocumento,
                    Cargo: item.cargo,
                    EquipoTrabajo: item.equipoTrabajo,
                    Colaborador: item.colaborador,
                    Fecha: item.fecha,
                    TipodeActividad: item.tipoActividad,
                    Categoría: item.categoria,
                    Cliente: item.cliente,
                    Proveedor: item.proveedor,
                    TipodeProyecto: item.tipoProyecto,
                    Proyecto: item.proyecto,
                    ÁreadeNegocio: item.areanegocio,
                    Aplicación: item.aplicacion,
                    DescripcióndeActividad: item.descripcionactividad,
                    EsAdicional: item.esadicional,
                    CoordinadoCon: item.coordinadocon,
                    FechaCorreoElectrónico: item.fechacorreoelec,
                    NúmerodeHoras: item.numerohoras,
                    Empresa: item.empresa,
                    Estado: item.estado,
                    FechaCreación: item.fechacreacion,
                    CreadoPor: item.creadopor,
                    FechaActualizado: item.fechaactualizado,
                    ActualizadoPor: item.actualizadopor,
                    Ticket: item.ticket,
                    Notas: item.notas,
                    Objetivo: item.objetivo,
                    Logro: item.logro,
                    Entregable: item.entregable
                }
                return newRange.push(jsonNewRange)
            })
        }
    }

    const tablaActividades = {}
    const actividadesUnicos = newRange.filter(function (object) {
        return tablaActividades.hasOwnProperty(object.Id) ? false : (tablaActividades[object.Id] = true)
    })

    //No retorna el campo de notas
    var newArray = []
    if (actividadesUnicos !== null) {
        if (actividadesUnicos.length > 0) {
            actividadesUnicos.map(item => {
                var jsonNewArray
                jsonNewArray = {
                    idUnique: item.Id,
                    NombreCompleto: item.NombreCompleto,
                    NúmeroDocumento: item.NumeroDocumento,
                    Cargo: item.Cargo,
                    EquipoTrabajo: item.EquipoTrabajo,
                    Colaborador: item.Colaborador,
                    Fecha: item.Fecha,
                    TipodeActividad: item.TipodeActividad,
                    Categoría: item.Categoría,
                    Cliente: item.Cliente,
                    Proveedor: item.Proveedor,
                    TipodeProyecto: item.TipodeProyecto,
                    Proyecto: item.Proyecto,
                    ÁreadeNegocio: item.ÁreadeNegocio,
                    Aplicacion: item.Aplicación,
                    DescripciodeActividad: item.DescripcióndeActividad,
                    EsAdicional: item.EsAdicional,
                    Coordinadocon: item.Coordinado_Con,
                    FechaCorreoElec: item.FechaCorreoElectrónico,
                    NúmeroHoras: item.NúmerodeHoras,
                    Empresa: item.Empresa,
                    Estado: item.Estado,
                    Fechacreacion: item.FechaCreación,
                    Creadopor: item.Creado_Por,
                    Fechaactualizado: item.FechaActualizado,
                    Actualizadopor: item.ActualizadoPor,
                    Ticket: item.Ticket,
                    Objetivo: item.Objetivo,
                    Logro: item.Logro,
                    Entregable: item.Entregable
                }
                return newArray.push(jsonNewArray)
            })
        }
    }

    // console.log(newArray)
    const exportExcel = (validateShowNotes) => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(validateShowNotes === true ? actividadesUnicos : newArray);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });

    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    const ModalMostrarNotas = ({ displayMessageNotesProp }) => {

        const [displayMessageNotes, setDisplayMessageNotes] = useState(displayMessageNotesProp)

        const dialogFuncMap = {
            'displayMessageNotes': setDisplayMessageNotes
        }

        const onClick = (name) => {
            dialogFuncMap[`${name}`](true);
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => {
                        exportExcel(false)
                        dialogFuncMap[`${name}`](false);
                        onHide(name)
                    }} className="p-button-text" autoFocus />
                    <Button label="Si" icon="pi pi-check" onClick={() => {
                        exportExcel(true)
                        dialogFuncMap[`${name}`](false);
                        onHide(name)
                    }} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button type="button" icon="pi pi-file-excel" onClick={() => onClick('displayMessageNotes')} className="p-button-success p-mr-2" tooltip='XLSX' disabled={disabled ? false : true} />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayMessageNotes} modal style={{}}
                    footer={renderFooter('displayMessageNotes')} onHide={() => onHide('displayMessageNotes')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>¿Señor usuario desea exportar las notas de las actividades registradas en el período seleccionado?”</span>
                    </div>
                </Dialog>
            </>
        )

    }

    const [cliente, setCliente] = useState(null)

    const clientes = clientsSession.map(item => {
        const av = { name: item.name, id: item.id }
        return av

    })

    const [itemsSelectedClient, setItemsSelectedClient] = useState(0);

    const onClienteChange = (e) => {
        setProyecto([])
        if (e.value === null) {
            setCliente([])
        } else {
            setCliente(e.value)
        }
        setValidadoDateRange(false)
        setItemsSelectedClient(e.value?.length)
        setSelectedSupplier([]);
        setSelectedTeamWork([]);
        if (permisosProp[2].exportarTodos === "true") {
            setSelectedUsuario([])
        } else {
            setSelectedUsuario([{
                name: fullNameSession,
                id: numberDocSession
            }])
        }
    }

    var clientSupplier = []
    const loadSuppliers = () => {
        if (cliente.length !== 0) {
            cliente.forEach((element) => {
                var filterSupplier = []
                serviceSupplier.forEach(x => {
                    x.clientes.forEach(y => {
                        if (y.cliente.id === element.id) {
                            filterSupplier.push(x)
                        };
                    });
                });
                filterSupplier.forEach(element => {
                    clientSupplier.push(element)
                });
            });
        };
    };
    if (cliente !== null) {
        loadSuppliers()
    }

    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [itemsSelectedSupplier, setItemsSelectedSupplier] = useState(0);

    const uniquefilterSupplier = [...new Set(clientSupplier)]

    useEffect(() => {
        if (selectedSupplier === null && uniquefilterSupplier.length !== 0) {
            var sendArray = uniquefilterSupplier.map((item) => {
                return {
                    name: item.razonsocial,
                    id: item.id
                };
            });
            sendArray.push({
                id: 0,
                name: 'Sin Proveedor'
            })
            setSelectedSupplier(sendArray);
            setItemsSelectedSupplier(sendArray.length);
        }

    }, [uniquefilterSupplier]); // eslint-disable-line react-hooks/exhaustive-deps

    const suppliers = uniquefilterSupplier.map(item => {
        return {
            id: item.id,
            name: item.razonsocial
        }
    });
    suppliers.push({
        id: 0,
        name: 'Sin Proveedor'
    })
    const onSupplierChange = (e) => {
        if (e.value === null) {
            setSelectedSupplier([]);
        } else {
            setSelectedSupplier(e.value);
        }
        setValidadoDateRange(false)
        setItemsSelectedSupplier(e.value?.length);
        setSelectedUsuario([]);
    };


    useEffect(() => {
        const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        const dataWorkTeam = {
            idClient: getId(cliente) || []
        }

        const executeServicesWorTeam = async () => {
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/consultListByClient'
            await axios.post(url, dataWorkTeam, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: newSource.token
            })
                .then((result) => {
                    if (result !== undefined) {
                        const newData = result.data.map(item => item)
                        newData.push({
                            id: 0,
                            nombre: 'Sin Equipo de Trabajo'
                        })
                        setServicesTeamWork(newData)
                        setSelectedTeamWork(newData)
                        setItemsSelectedTeamWork(newData.length)
                    }
                })
                .catch((e) => {
                    /* console.log(e); */
                })
        }
        if (cliente !== null) {
            if (cliente.length !== 0) {
                executeServicesWorTeam()
            }
        }

        return () => {
            if (newSource) {
                newSource.cancel()
            }
        }
    }, [cliente]); // eslint-disable-line react-hooks/exhaustive-deps

    const [selectedTeamWork, setSelectedTeamWork] = useState(null)

    const optionTeamWork = servicesTeamWork.map(item => item)

    const [itemsSelectedTeamWork, setItemsSelectedTeamWork] = useState(0);

    const onTeamWorkChange = (e) => {
        if (e.value === null) {
            setSelectedTeamWork([])
        } else {
            setSelectedTeamWork(e.value)
        }
        setItemsSelectedTeamWork(e.value?.length)
        setValidadoDateRange(false)
        if (permisosProp[2].exportarTodos === "true") {
            setSelectedUsuario([])
        } else {
            setSelectedUsuario([{
                name: fullNameSession,
                id: numberDocSession
            }])
        }
    }

    const [selectedTypeProject, setSelectedTypeProject] = useState(null)

    useEffect(() => {
        if (cliente !== null && cliente.length !== 0) {
            var newData = servicesTypeProjects.map(x => {
                return {
                    name: x.name,
                    id: x.id
                }
            })
            setSelectedTypeProject(newData);
            setItemsSelectedTypeProject(newData.length)
        }
    }, [cliente]) // eslint-disable-line react-hooks/exhaustive-deps

    const typesProjects = servicesTypeProjects.map(item => {
        const av = { name: item.name, id: item.id }
        return av
    })

    const [itemsSelectedTypeProject, setItemsSelectedTypeProject] = useState(0);

    const onTypesProjectsChange = (e) => {
        if (e.value === null) {
            setSelectedTypeProject([]);
        } else {
            setSelectedTypeProject(e.value);
        }
        setItemsSelectedTypeProject(e.value?.length)
        setProyecto([])
        setValidadoDateRange(false)
    };

    useEffect(() => {
        const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        const idUsuario = cookies.get('idUsuario')
        const dataProjects = {
            idClientes: getId(cliente) || [],
            idTipoProyecto: getId(selectedTypeProject) || [],
            idUsuario: parseInt(idUsuario)
        }
        const getProjectUserByClientAndTypeProject = async () => {
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/listProjectsUsers'
            await axios.post(url, dataProjects, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: newSource.token
            }).then((result) => {
                if (result !== undefined) {
                    const filter = result.data.filter(item => {
                        return item.estado === 1
                    })
                    const tableProjectUser = {}
                    const uniqueProjectsUser = filter.filter(function (object) {
                        return tableProjectUser.hasOwnProperty(object.id) ? false : (tableProjectUser[object.id] = true)
                    })
                    setProjects(uniqueProjectsUser)
                    setProyecto(uniqueProjectsUser.map(item => {
                        return {
                            name: item.nombreproyecto,
                            id: item.id
                        }
                    }))
                    setItemsSelectedProject(uniqueProjectsUser.length)
                }
            }).catch((e) => {
                /* console.log(e); */
            })
        }
        if (cliente !== null && selectedTypeProject !== null) {
            if (cliente.length !== 0 || selectedTypeProject.length !== 0) {
                getProjectUserByClientAndTypeProject();
            }
        }

        return () => {
            if (newSource) {
                newSource.cancel()
            }
        }
    }, [cliente, selectedTypeProject]); // eslint-disable-line react-hooks/exhaustive-deps

    const [proyecto, setProyecto] = useState(null);

    const proyectos = projects.map(item => {
        var av = { name: item.nombreproyecto, id: item.id }
        return av
    })

    const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
    const onProyectoChange = (e) => {
        setValidadoDateRange(false)
        if (e.value === null) {
            setProyecto([])
        } else {
            setProyecto(e.value);
        }
        setItemsSelectedProject(e.value?.length)
        if (permisosProp[2].exportarTodos === "true") {
            setSelectedUsuario([])
        } else {
            setSelectedUsuario([{
                name: fullNameSession,
                id: numberDocSession
            }])
        }
    }
    const [selectedUsuario, setSelectedUsuario] = useState([])

    useEffect(() => {
        const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
        const dataUsers = {
            cargos: [],
            proyectos: getId(proyecto) || [],
            wt: getId(selectedTeamWork) || [],
            proveedor: getId(selectedSupplier) || [],
            clientes: getId(cliente) || []
        }
        const getUsersForProjectOrTeamWorkOrPosition = async () => {

            const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/getUsersFilter'
            await axios.post(url, dataUsers, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: newSource.token
            }).then((result) => {
                if (result !== undefined) {
                    if (permisosProp[2].exportarTodos === "true") {
                        setServicesUsers(result.data)
                        setSelectedUsuario(result.data.map(item => {
                            return {
                                name: item.nombre,
                                id: item.id
                            }
                        }));
                        setItemsSelectedUser(result.data.length)
                    }
                }
            }).catch((e) => {
                /* console.log(e); */
            })
        }
        if (proyecto !== null && selectedTeamWork !== null && selectedSupplier !== null && cliente !== null) {
            if (proyecto.length !== 0 || selectedTeamWork.length !== 0 ||
                selectedSupplier.length !== 0 || cliente.length !== 0) {
                getUsersForProjectOrTeamWorkOrPosition()
            }
        }
        return () => {
            if (newSource) {
                newSource.cancel()
            }
        }
    }, [proyecto, selectedTeamWork, selectedSupplier, cliente]); // eslint-disable-line react-hooks/exhaustive-deps

    const usuarios = servicesUsers.map(item => {
        const av = { name: item.nombre, id: item.id }
        return av
    })

    const [itemsSelectedUser, setItemsSelectedUser] = useState(0);

    const onUsuarioChange = (e) => {
        setItemsSelectedUser(e.value?.length)
        if (permisosProp[2].exportarTodos === "true") {
            if (e.value === null) {
                setSelectedUsuario([])
            } else {
                setSelectedUsuario(e.value)
            }
        }
        setValidadoDateRange(false)
    }

    const dateFirtsDay = new Date()
    var firstDay = new Date(dateFirtsDay.getFullYear(), dateFirtsDay.getMonth(), 1)
    var dateCurrent = new Date()

    const [dateStart, setDateStart] = useState(firstDay);
    const [dateEnd, setDateEnd] = useState(dateCurrent);

    const onDateStartChange = (e) => {
        let date = e.value
        setDateStart(date)
        setValidadoDateRange(false)
    }
    const onDateEndChange = (e) => {
        let date = e.value
        setDateEnd(date)
        setValidadoDateRange(false)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const [validateDateStart, setValidateDateStart] = useState(false)
    const [messageDateStart, setMessageDateStart] = useState('')
    const [validateDateEnd, setValidateDateEnd] = useState(false)
    const [messageDateEnd, setMessageDateEnd] = useState('')

    const [validateUser, setValidateUser] = useState(false)
    const [messageUser, setMessageUser] = useState('')

    const [messageProject, setMessageProject] = useState('')

    const validateDates = () => {

        if (dateStart === null) {
            setValidateDateStart(true)
            setMessageDateStart('Ingrese una fecha de inicio')
            setActividadesRango([])
        } else if (dateStart !== null) {
            setValidateDateStart(false)
            setMessageDateStart('')
        }
        if (dateEnd === null) {
            setValidateDateEnd(true)
            setMessageDateEnd('Ingrese una fecha fin')
            setActividadesRango([])
        } else if (dateEnd !== null) {
            setValidateDateEnd(false)
            setMessageDateEnd('')
        }

        if (dateStart !== null && dateEnd !== null) {
            if (Date.parse(dateStart) > Date.parse(dateEnd)) {
                setValidateDateStart(true)
                setMessageDateStart('La fecha inicial no puede ser mayor a la final')
                setActividadesRango([])
            } else if (Date.parse(dateStart) > Date.parse(new Date()) && Date.parse(dateEnd) > Date.parse(new Date())) {
                setValidateDateStart(true)
                setMessageDateStart('No se pueden hacer consultas de fechas adelantadas')
                setActividadesRango([])
            } else if (Date.parse(dateEnd) > Date.parse(new Date())) {
                setValidateDateEnd(true)
                setMessageDateEnd('No se pueden hacer consultas de fechas adelantadas')
                setActividadesRango([])
            } else {
                setValidadoDateRange(true)
                setProgressBar(true)
            }
        }

    }

    const convertDate = (date) => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newDate
        if (date !== null) {
            if (gmtHours < 0) {
                newDate = SumarDias(new Date(date), 1)
            } else {
                newDate = new Date(date)
            }
        }
        return newDate
    }
    const getId = (array) => {
        if (array === null || array.length === 0) return false;
        return array.map((el) => el.id);
    };

    const [validadoDateRange, setValidadoDateRange] = useState(false)

    const DateRange = async (source) => {
        let booleanClient = false
        if (cliente?.length === 0 && proyecto?.length === 0) {
            booleanClient = true
        }
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivityRangeFilterSupplier'
        const getSelectedTeamWork = getId(selectedTeamWork)
        const newGetSelectedTeamWork = getSelectedTeamWork === false ? false : getSelectedTeamWork?.map(id => id !== null ? id : 0)
        const data = {
            fecFinal: moment(dateEnd).format("YYYY-MM-DD") || "",
            fecInicial: moment(dateStart).format("YYYY-MM-DD") || "",
            idProyectos: booleanClient ? getId(projectSession) : getId(proyecto) || getId(projects),
            numeroDocumentos: permisosProp[2].exportarTodos === "false" ? getId(selectedUsuario) : getId(selectedUsuario) || getId(servicesUsers),
            idEquipoTrabajos: permisosProp[2].exportarTodos === "false" ? [] : newGetSelectedTeamWork || [],
            idProveedores: getId(selectedSupplier) || []
        };

        const json = JSON.stringify(
            data
        )

        /* console.log(sendProyectos) */

        await axios.post(url, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                cancelToken: source.token
            })
            .then(response => {
               /*  console.log(response) */
                toast.current.show({ severity: 'success', summary: 'Consulta Satisfactoria', detail: response.data.message, life: 3000 });
                var newData = response.data.map(item => {
                    var newDate = convertDate(item.fecha)
                    var newDateEmail = convertDate(item.fechacorreoelec)
                    var newDateCreation = convertDate(item.fechacreacion)
                    var newDateUpdate = convertDate(item.fechaactualizado)
                    var category = typeActivityService.filter(function (object) {
                        return object.id === item.idtipoactividad
                    })

                    return {
                        actualizadopor: item.actualizadopor,
                        aplicacion: item.aplicacion,
                        areanegocio: item.areanegocio,
                        cargo: item.cargo,
                        cliente: item.cliente,
                        colaborador: item.colaborador,
                        coordinadocon: item.coordinadocon,
                        creadopor: item.creadopor,
                        descripcionactividad: item.descripcionactividad,
                        empresa: item.empresa,
                        esadicional: item.esadicional,
                        estado: item.estado,
                        fecha: newDate === undefined ? item.fecha : EstandarFormatoFecha(newDate),
                        fechaactualizado: newDateUpdate === undefined ? item.fechaactualizado : EstandarFormatoFecha(newDateUpdate),
                        fechacorreoelec: newDateEmail === undefined ? item.fechacorreoelec : EstandarFormatoFecha(newDateEmail),
                        fechacreacion: newDateCreation === undefined ? item.fechacreacion : EstandarFormatoFecha(newDateCreation),
                        idUnique: item.idUnique,
                        nombreColaborador: item.nombreColaborador,
                        nombreUsuario: item.nombreUsuario,
                        numerohoras: item.numerohoras,
                        proyecto: item.proyecto,
                        ticket: item.ticket,
                        tipoActividad: item.tipoActividad,
                        categoria: category.length !== 0 ? category[0].categoria.descripcion : null,
                        notas: item.notas,
                        numerodocumento: item.numerodocumento,
                        tipoProyecto: item.tipoProyecto,
                        equipoTrabajo: item.equipoTrabajo,
                        proveedor: item.proveedor,
                        objetivo: item.nombreObjetivo,
                        logro: item.nombreLogro,
                        entregable: item.entregable
                    }
                })
                /* console.log(newData) */

                setActividadesRango(newData)
                setProgressBar(false)
                setValidadoDateRange(false)
                setMessageProject('')

            })
            .catch(function (error) {         
                setProgressBar(false)      
                setValidadoDateRange(false)
                if (error.response !== undefined) {
                    if (error.response.status) {
                        toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 4000 });
                    }
                }
            })
    }

    useEffect(() => {
        const source = axios.CancelToken.source()
        if (validadoDateRange === true) {
            DateRange(source)
        }
        return () => {
            source.cancel()
        }
    }, [validadoDateRange])// eslint-disable-line react-hooks/exhaustive-deps

    const LimpiarDatos = () => {
        setActividadesRango([])
        setDateStart(null)
        setDateEnd(null)
        setValidateDateEnd(false)
        setMessageDateEnd('')
        setValidateDateStart(false)
        setMessageDateStart('')
        setSelectedTypeProject([])
        setSelectedTeamWork([])
        setValidadoDateRange(false)

        if (permisosProp[2].exportarTodos === "false") {
            setSelectedUsuario([{
                name: fullNameSession,
                id: numberDocSession
            }])
        } else {
            setSelectedUsuario([])
        }
        if (tipoUsuario !== 'Cliente') {
            setCliente(null)
        }
        setProyecto([])
        setMessageProject('')
        setValidateUser(false)
        setMessageUser('')
        setSelectedSupplier([])

    }

    var disabled

    if (actividadesRango.length === 0) {
        disabled = false
    } else {
        disabled = true
    }

    ////validaciones proyectos

    let disabledProyecto;
    let proyectosError = false
    let mensajeProyectos = ''
    let teamWorkError = false
    let messageTeamWorkError = ''
    let disabledFields

    if (cliente !== null && selectedTypeProject !== null) {
        if (cliente.length === 0 && selectedTypeProject.length === 0) {
            disabledProyecto = true

        } else if (projects.length === 0) {
            disabledProyecto = false
            proyectosError = true
            mensajeProyectos = 'El cliente no tiene proyectos asociados'
        }

    } else {
        disabledProyecto = true
    }

    if (cliente !== null) {
        if (cliente.length === 0) {
            disabledFields = true
        } else if (optionTeamWork.length === 0) {
            teamWorkError = true
            messageTeamWorkError = 'No existe un equipo de trabajo del cliente seleccionado'
        }
        else {
            disabledFields = false
        }

    } else {
        disabledFields = true
    }
    var disableUsuario = false

    if (permisosProp[2].exportarTodos === "false") {
        disableUsuario = true
    } else {
        if (proyecto?.length === 0 && selectedTeamWork?.length === 0 && selectedSupplier?.length === 0 &&
            cliente?.length === 0) {
            disableUsuario = true
        }
    }
    const header = (
        <div className="p-d-flex export-buttons">
            {/*<Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" /> */}
            <ModalMostrarNotas />
            {/* <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" /> */}

        </div>
    );

    let small = UseMedia("(max-width: 760px)");

    var showTooltipClient = false
    var showTooltipProject = false
    var showTooltipUser = false

    if (cliente !== null && cliente.length !== 0) {
        if (cliente.length > 0 && cliente.length <= 10) {
            showTooltipClient = true
        }
    }
    if (proyecto !== undefined) {
        if (proyecto !== null && proyecto.length !== 0) {
            if (proyecto.length > 0 && proyecto.length <= 10) {
                showTooltipProject = true
            }
        }
    }

    if (selectedUsuario !== null && selectedUsuario.length !== 0) {
        if (selectedUsuario.length > 0 && selectedUsuario.length <= 10) {
            showTooltipUser = true
        }
    }

    const acciones = (rowData) => {
        return (
            <div className='botones'>
                <BtnNotas
                    titleProp={'Detalle de nota'}
                    disabledProp={true}
                    notesDescriptionProp={rowData.notas}
                    tooltipProp={'Detalle de nota'}
                />
            </div>
        )
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="p-fluid p-formgrid p-grid">
                <div style={{ display: 'contents' }} >
                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                        <label className="labels">Fecha Inicio<span className="require">*</span></label>
                        <Calendar placeholder="Fecha inicio" className={validateDateStart ? "p-invalid" : ""} value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es" showButtonBar />
                        <p className="mensajeError">{messageDateStart}</p>
                    </div>
                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                        <label className="labels">Fecha Fin<span className="require">*</span></label>
                        <Calendar placeholder="Fecha fin" className={validateDateEnd ? "p-invalid" : ""} value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" showButtonBar />
                        <p className="mensajeError">{messageDateEnd}</p>
                    </div>
                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                        <label className="labels">Clientes</label>
                        {showTooltipClient ?
                            <Tooltip target=".tooltip-button-client" autoHide={false}>
                                <div className="flex align-items-center">
                                    {
                                        cliente.map((item, index) => {
                                            return (<div key={index}>{item.name}</div>)
                                        })
                                    }
                                </div>
                            </Tooltip>
                            :
                            ""
                        }

                        <MultiSelect value={cliente} options={clientes} onChange={onClienteChange} optionLabel="name" name="cliente"
                            placeholder="Seleccione un cliente" disabled={tipoUsuarioCliente} showClear maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`}
                            className="tooltip-button-client" filter emptyFilterMessage="No se encontraron resultados"
                        />
                        <p className="mensajeError">{messageProject}</p>
                    </div>
                    {permisosProp[2].exportarTodos === "true" &&
                        <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                            <label className="labels">Proveedores</label>
                            <MultiSelect value={selectedSupplier} options={suppliers} onChange={onSupplierChange} optionLabel="name" name="proveedor"
                                placeholder="Seleccione un proveedor" showClear maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedSupplier} Elementos Seleccionados`}
                                filter emptyFilterMessage="No se encontraron resultados" disabled={disabledFields}
                            />
                        </div>
                    }
                    {
                        permisosProp[2].exportarTodos === "true" ?
                            <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                                <label className="labels">Equipo Trabajo</label>
                                <MultiSelect value={selectedTeamWork} options={optionTeamWork} onChange={onTeamWorkChange} optionLabel="nombre" name="equipo" disabled={disabledFields}
                                    maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTeamWork} Elementos Seleccionados`} filter placeholder="Seleccione un equipo de trabajo"
                                    className={teamWorkError ? "p-invalid" : ''}
                                />
                                <p className="mensajeError">{teamWorkError ? messageTeamWorkError : ""}</p>
                            </div>
                            :
                            ''

                    }

                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                        <label className="labels">Tipo de Proyecto</label>
                        <MultiSelect value={selectedTypeProject} options={typesProjects} onChange={onTypesProjectsChange} optionLabel="name"
                            maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTypeProject} Elementos Seleccionados`} showClear filter placeholder="Seleccione un tipo de proyecto"
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                        <label className="labels">Proyectos</label>
                        {showTooltipProject ?
                            <Tooltip target=".tooltip-button-project" autoHide={false}>
                                <div className="flex align-items-center">
                                    {
                                        (proyecto !== null ? proyecto : []).map((item, index) => {
                                            return (<div key={index}>{item.name}</div>)
                                        })
                                    }
                                </div>
                            </Tooltip>
                            :
                            ""
                        }
                        <MultiSelect value={proyecto} options={proyectos} onChange={onProyectoChange} optionLabel="name" name="proyecto"
                            placeholder="Seleccione un proyecto" disabled={disabledProyecto} filter
                            className={proyectosError ? "tooltip-button-project p-invalid" : "tooltip-button-project"} showClear maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`}
                        />
                        <p className="mensajeError">{proyectosError ? mensajeProyectos : messageProject}</p>
                    </div>
                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '5px' }}>
                        <label className="labels">Funcionario</label>

                        {showTooltipUser ?
                            <Tooltip target=".tooltip-button-user" autoHide={false}>
                                <div className="flex align-items-center">
                                    {
                                        (selectedUsuario !== null ? selectedUsuario : []).map((item, index) => {
                                            return (<div key={index}>{item.name}</div>)
                                        })
                                    }
                                </div>
                            </Tooltip>
                            :
                            ""
                        }

                        {permisosProp[2].exportarTodos === "false" ?
                            <InputText value={fullNameSession} disabled />
                            :
                            <MultiSelect value={selectedUsuario} options={usuarios} onChange={onUsuarioChange} placeholder="Seleccione un usuario"
                                className={validateUser ? "tooltip-button-user p-column-filter p-invalid" : "tooltip-button-user p-column-filter"} emptyMessage="No hay resultados" name="usuario"
                                optionLabel="name" disabled={disableUsuario} showClear maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedUser} Elementos Seleccionados`} filter
                            />
                        }
                        <p className="mensajeError">{messageUser}</p>

                    </div>
                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '35px' }}>
                        <Button label="Buscar" className="p-button-outlined p-button-secondary" onClick={() => validateDates()} />
                    </div>

                    <div className="p-field p-col-12 p-md-2" style={{ marginTop: '35px' }}>
                        <Button label="Limpiar" className="p-button-outlined p-button-secondary" onClick={() => LimpiarDatos()} />
                    </div>
                </div>
                <div className="p-field p-col-12 p-md-12" style={{ marginTop: '5px' }}>
                    {
                        progressBar ?
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>

                            :
                            ""
                    }
                </div>
            </div>
            <Tooltip target=".export-buttons>button" position="bottom" />

            <DataTable ref={dt} value={actividadesRango} header={header} dataKey="id" emptyMessage="No hay datos para exportar"
                responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                paginator rows={5}
            >
                <Column className="columna" style={{ minWidth: '14rem' }} field="fecha" header="Fecha" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="nombreUsuario" header="Nombre Completo" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="numerodocumento" header="Número Documento" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="colaborador" header="Colaborador" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="cargo" header="Cargo" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="equipoTrabajo" header="Equipo Trabajo" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="cliente" header="Cliente" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="proveedor" header="Proveedor" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="tipoProyecto" header="Tipo Proyecto" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="proyecto" header="Proyecto" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="categoria" header="Categoría" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="tipoActividad" header="Tipo Actividad" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="ticket" header="Req.Ticket" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="areanegocio" header="Área Negocio" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="aplicacion" header="Sistema Aplicación" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="descripcionactividad" header="Actividad" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="numerohoras" header="Num Horas" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="objetivo" header="Objetivo" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="logro" header="Logro" />
                <Column className="columna" style={{ minWidth: '14rem' }} field="entregable" header="Entregable" />
                <Column className="columna" style={{ minWidth: '14rem' }} body={acciones} header="Notas" />
            </DataTable>
        </div>
    );
}

export default DataTableExport