import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
// import { InputNumber } from 'primereact/inputnumber';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Toast } from 'primereact/toast';
import { CambiarFormatoFecha } from '../Funciones';
import ConfirmBtn from './ConfirmBtn';
import Cookies from 'universal-cookie'
const cookies = new Cookies()


const BtnCrearLogro = ({ idProjectProp, projectProp, setRunRenderProp, runRenderProp, dateStartProp, dateEndProp, reloadObjectiveProp,
    objectivesProp, projectsUserSessionProp, achievementsProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp }) => {

    const [projectsSession, setProjectsSession] = useState([]);

    const toast = useRef(null);

    useEffect(() => {
        const projectsTable = {}
        const uniqueProjects = projectsUserSessionProp.filter(function (object) {
            return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
        })
        setProjectsSession(uniqueProjects)
        setSelectedProjects({
            projects: projectProp,
            id: parseInt(idProjectProp),
            dateStartValidity: dateStartProp,
            dateEndValidity: dateEndProp
        })

    }, [idProjectProp, projectProp, dateStartProp, dateEndProp, reloadObjectiveProp])// eslint-disable-line react-hooks/exhaustive-deps

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setRunRenderProp(runRenderProp + 1)
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select Proyecto

    const [selectedProjects, setSelectedProjects] = useState({
        projects: '',
        id: '',
        dateStartValidity: '',
        dateEndValidity: ''
    })

    const proyectos = projectsSession.map(item => {
        const av = { projects: item.nombre, id: parseInt(item.id), dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
        return av
    })

    const onProjectsChange = (e) => {
        setSelectedProjects(e.value);
        setValidateChange(true)
    }

    //Obtener datos del select objetivo

    const [selectedObjectives, setSelectedObjectives] = useState({
        descriptionObjectives: '',
        id: ''
    })

    var porjectsObjectives = []

    const loadObjectives = () => {
        const result = objectivesProp.map(item => {
            return item.proyectos.map(j => {
                var boolean
                if (j.proyecto.id === parseInt(selectedProjects.id)) {
                    boolean = item
                }
                return boolean
            })
        })

        result.forEach(i => {
            i.forEach(j => {
                if (j !== undefined) {
                    if (j.length !== 0) {
                        porjectsObjectives.push(j)
                    }
                }
            })
        })
        return porjectsObjectives
    }

    if (selectedProjects.id !== '') {
        loadObjectives()
    }

    const objetivo = porjectsObjectives.map(item => {
        const av = { descriptionObjectives: item.descripcion, id: item.id }
        return av
    })

    const onObjectivesChange = (e) => {
        setSelectedObjectives(e.value);
        setValidateChange(true)
    }

    //Obtener datos de cumplimiento al corte

    const [selectedCourtCompliance, setSelectedCourtCompliance] = useState(null)

    const courtCompliances = [{name: 'Meta Alcanzada'}, {name: 'Meta No Alcanzada'}]

    const onChangeCourtCompliance = (e) => {
        setSelectedCourtCompliance(e.value)
    }
    //Obtener datos del input detalle del logro

    const [inputDetailAchievement, setInputDetailAchievement] = useState({
        detailAchievement: ''
    })

    const onDetailAchievementChange = (event) => {
        setInputDetailAchievement({
            ...inputDetailAchievement,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del input logro

    const [inputAchievement, setInputAchievement] = useState({
        achievement: ''
    })
    const onAchievementChange = (event) => {
        setInputAchievement({
            ...inputAchievement,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    // calendar de fecha  inicio

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        let date = e.value
        setDate(date)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Almacenamos los valores para enviarlos por props

    const achievement = inputAchievement.achievement
    const descriptionObjectives = selectedObjectives.descriptionObjectives
    const idObjectives = selectedObjectives.id
    const projects = selectedProjects.projects
    const idProjects = selectedProjects.id
    const detailAchievement = inputDetailAchievement.detailAchievement
    const courtCompliance = selectedCourtCompliance?.name

    const resetData = () => {
        setSelectedObjectives({
            descriptionObjectives: '',
            id: ''
        })
        setInputDetailAchievement({
            detailAchievement: ''
        })
        setInputAchievement({
            achievement: ''
        })
        setDate(null)
        setValidateChange(false)
        setSelectedCourtCompliance(null)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setRunRenderProp(runRenderProp + 1)
        }

        const Back = (name) => {
            setRunRenderProp(runRenderProp + 1)
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>
            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreAchievement = ({setRunRenderProp, runRenderProp}) => {
        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setRunRenderProp(runRenderProp + 1)
            setDisplayBasic(false)
            setShowModal(false)
            resetData()
        }

        const AddMore = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false);
            resetData()       
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar otro logro?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {registerPermission ?
                    <ConfirmBtn
                        tittle="Confirmar Registro"
                        menssage="¿Desea registrar la información ingresada?"
                        action="Create"
                        label="Guardar"
                        icon="pi pi-plus-circle"
                        detailAchievementProp={detailAchievement}
                        achievementProp={achievement}
                        idDescriptionObjectivesProp={idObjectives}
                        idProjectsProp={idProjects}
                        dateProp={date === null ? date : CambiarFormatoFecha(date)}
                        setShowModal={setShowModal}
                        courtComplianceProp={courtCompliance}
                    />
                    :
                    ""
                }
            </div>
        );
    }
    
    const usuario = cookies.get('usuario');

    //validaciones para Actividades

    var detailAchievementError = false
    var mensajeDetailAchievement = ''
    if (detailAchievement.length < 8 && detailAchievement !== '') {
        detailAchievementError = true
        mensajeDetailAchievement = 'El detalle del logro debe ser mayor a 8 dígitos'
    } else if (detailAchievement.length > 300) {
        detailAchievementError = true
        mensajeDetailAchievement = 'El detalle del logro debe ser menor a 300 dígitos'
    }
    // detalle 300 caracteres

    //validaciones para logro

    var achievementError = false
    var mensajeAchievement = ''
    if (achievement.length < 8 && achievement !== '') {
        achievementError = true
        mensajeAchievement = 'El logro debe ser mayor a 8 dígitos'
    } else if (achievement.length > 200) {
        achievementError = true
        mensajeAchievement = 'El logro debe ser menor a 200 dígitos'
    }
    if (selectedObjectives.id !== '') {
        if (achievement.length > 8 && achievement.length < 100 && detailAchievement.length > 8 && detailAchievement.length < 200) {
            var serviceDuplicate = achievementsProp.filter(obj => obj.objetivo.id === parseInt(selectedObjectives.id) 
                && obj.creadopor === usuario
                && obj.logro.toLowerCase() === achievement.toLowerCase()
                && obj.detallelogro.toLowerCase() === detailAchievement.toLowerCase())
            if (serviceDuplicate.length !== 0) {
                detailAchievementError = true
                mensajeDetailAchievement = 'Ya has creado un logro con el mismo objetivo, logro y detalle del logro'
            }
        }
    }

    //validaciones para fecha de finalización 
    var dateError = false
    var dateMessage = ''
    const validateDate = () => {
        var newDate = CambiarFormatoFecha(date)
        if (newDate >= selectedProjects.dateStartValidity && newDate <= selectedProjects.dateEndValidity) {
            dateError = false
            dateMessage = ''
        } else {
            dateError = true
            dateMessage = 'La fecha de finalización esta fuera del rango de fechas del proyecto seleccionado'
        }
        if (newDate > CambiarFormatoFecha(new Date())) {
            dateError = true
            dateMessage = 'La fecha de finalización no puede ser mayor a la fecha actual'
        }else if (newDate < CambiarFormatoFecha(dateStartMonthlyReportProp) || newDate > CambiarFormatoFecha(dateEndMonthlyReportProp)) {
            dateError = true
            dateMessage = 'La fecha de finalización no puede estar fuera del rango del periodo seleccionado'
        }
    }

    if (date !== null && selectedProjects.id !== '') {
        validateDate()
    }

    var registerPermission = false

    if (achievementError === false && achievement !== '' && detailAchievementError === false && detailAchievement !== '' && projects !== '' && descriptionObjectives !== ''
        && date !== null && dateError === false && selectedCourtCompliance !== null) {

        registerPermission = true
    }

    return (
        <div className="">
            <Toast ref={toast} />
            <div>
                <button className="btnAdd" onClick={() => {
                    onClick('displayBasic')
                    resetData()
                }}><span className="pi pi-plus Add"></span>Agregar logro</button>
                <Dialog className="modal" header="Crear logros por objetivos" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <AddMoreAchievement 
                        runRenderProp={runRenderProp}
                        setRunRenderProp={setRunRenderProp}
                    />
                    <div className="p-fluid p-formgrid p-grid">
                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Proyecto<span className="require">*</span></label>
                                <Dropdown value={selectedProjects} options={proyectos} onChange={onProjectsChange} name="proyecto" optionLabel="projects"
                                    placeholder="Seleccione un proyecto" disabled filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Objetivo<span className="require">*</span></label>
                                <Dropdown value={selectedObjectives} options={objetivo} onChange={onObjectivesChange} name="objetivo" optionLabel="descriptionObjectives"
                                    placeholder="Seleccione un objetivo" emptyMessage="No se registran datos" filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Cumplimiento al Corte<span className="require">*</span></label>
                                <Dropdown value={selectedCourtCompliance} options={courtCompliances} onChange={onChangeCourtCompliance} optionLabel='name'
                                    placeholder="Seleccione un cumplimiento" emptyMessage="No se registran datos" filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Logro<span className="require">*</span></label>
                                <InputText value={achievement} id="achievement" type="text" name="achievement"  onChange={onAchievementChange}
                                    placeholder="Registre el logro realizado"
                                    className={achievementError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{achievementError ? mensajeAchievement : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Detalle del logro<span className="require">*</span></label>
                                <InputText value={detailAchievement} id="detailAchievement" type="text" name="detailAchievement"  onChange={onDetailAchievementChange}
                                    placeholder="Ingrese el detalle"
                                    className={detailAchievementError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{detailAchievementError ? mensajeDetailAchievement : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Fecha de cumplimiento<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es"
                                    className={dateError ? "p-invalid" : ""} />
                                <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default BtnCrearLogro