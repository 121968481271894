import React from 'react';
import { Chart } from 'primereact/chart';
import { GenerarNuevoColor } from '../../../Funciones'

const Chart2 = ({ dashBoardDataProp, pageProp, datatableProp }) => {

    var arrayLabels = []
    var arrayProjects = []
    var arraypcjs = []
    var arrayData1 = []
    var arrayData2 = []
    var arrayData3= []

    datatableProp.forEach(element => {
        arrayLabels.push(element.funcionario)
        arrayProjects.push(element.proyecto)
        arraypcjs.push(element.porcentaje)
        arrayData1.push(element.mes1)
        arrayData2.push(element.mes2)
        arrayData3.push(element.mes3)
    });

    var newArrayData1 = {
        label:datatableProp[0].label1,
        data: arrayData1
    }
    var newArrayData2 = {
        label:datatableProp[0].label2,
        data: arrayData2
    }
    var newArrayData3 = {
        label:datatableProp[0].label3,
        data: arrayData3
    }

    var orderDatasets = [newArrayData1,newArrayData2,newArrayData3]

    var labels = arrayLabels
    var labelsReduced = [];
    var projectsReduced = []
    var n = 5

    for (let i = 0; i < labels.length; i += n) {
        let reduced = labels.slice(i, i + n);
        labelsReduced.push(reduced);
    }

    for (let i = 0; i < arrayProjects.length; i += n) {
        let reduced = arrayProjects.slice(i, i + n);
        projectsReduced.push(reduced);
    }

    var newDatasets = orderDatasets.map(item => {
        return {
            label: item.label,
            backgroundColor: GenerarNuevoColor(),
            data: item.data
        }
    })

    var dataReduced = newDatasets.map(item => {
        var datos = item.data
        var datosReduced = []
        for (let i = 0; i < datos.length; i += n) {
            let reduced = datos.slice(i, i + n);
            datosReduced.push(reduced);
        }
        return datosReduced
    });

    var newData = newDatasets.map((item, index) => {
        return {
            label: item.label,
            backgroundColor: item.backgroundColor,
            data: dataReduced[index]
        }
    });

    var newArrayDatos = []

    newData.forEach(element => {
        newArrayDatos.push({
            label: element.label,
            backgroundColor: element.backgroundColor,
            data: element.data[pageProp],
            proyects: projectsReduced[pageProp]
        })
    });

    const basicData = {
        labels: labelsReduced[pageProp],
        datasets: newArrayDatos,
        
    };

    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.display = 'flex';
            tooltipEl.style.justifyContent = 'center';
            tooltipEl.style.textAlign = 'center';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table = document.createElement('table');
            table.style.margin = '0px';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            const tableHead = document.createElement('thead');

            const projectLines = tooltip.dataPoints[0].dataset.proyects

            var indexLines = tooltip.dataPoints[0].dataIndex

            titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 100;
                tr.style.fontSize = '13px';
                const th = document.createElement('th');
                th.style.borderWidth = 100;
                const text = document.createTextNode(title + ' - ' + projectLines[indexLines]);
                
                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '10px';
                span.style.display = 'inline-block';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                const text = document.createTextNode(body);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };


    const getLightTheme = () => {
       

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
   

        return {
            horizontalOptions,
        }
    }

    const { horizontalOptions } = getLightTheme();

    return (
        <div>
            <div className="card">
                <Chart type="bar" data={basicData} options={horizontalOptions} />
            </div>
        </div>
    )
}
export default Chart2