import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
/* import { Button } from 'primereact/button'; */
import { Calendar } from 'primereact/calendar';
import Calendario from './Calendario'
import { addLocale } from 'primereact/api';
import Services from '../../../service/Services';
import { SumarDias, UseMedia } from '../../Funciones';

const SeleccionarPais = () => {

    const [servicio, setServicio] = useState([]);
    const [servicioPaises, setServicioPaises] = useState([]);

    const getServices = new Services();

    useEffect(() => {

        getServices.getCalendarios().then(data => setServicio(data));
        getServices.getPaises().then(data => setServicioPaises(data));

    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    //// selección de país 
    const [country, setSelectedCountry] = useState({
        pais: ''
    });
    var countries
    var countriesError = false 
    var countriesMensaje = ''
    if (servicioPaises !== undefined) {
        countries = servicioPaises.map(item => {
            const av = { pais: item }

            return av

        })
        countriesError = false
    } else {
        countries = []
        countriesError = true
        countriesMensaje = 'No hay festivos parametrizados para ningún pais, debe parametrizar un festivo para un país primero'
    }


    const onCountryChange = (e) => {
        setSelectedCountry(e.value);
    }
    ///// Calendario

    const countryValue = country.pais


    var arrayFechasConvertidas = []

    var arrayFechas

    if (servicio !== undefined) {
        const result = servicio.filter(function (objeto) {
            var pais = objeto.pais

            return pais === countryValue
        })

        result.forEach(element => {
            var arr = element.fechas.split(',');
            arrayFechas = arr
        })
    }


    if (arrayFechas !== undefined) {
        arrayFechas.forEach(element => {
            var fechaConvertida = new Date(element)
            arrayFechasConvertidas.push(SumarDias(fechaConvertida, +0))
        })
    }

    /* console.log(arrayFechasConvertidas)
    console.log(arrayFechas) */


    var viewCalendar = false

    if (countryValue !== "") {
        viewCalendar = true
    } else {
        viewCalendar = false
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    let small = UseMedia("(max-width: 1060px)");

    return (

        <>
            <div className="p-formgroup-inline">
                <div className="p-field">
                    <div className="p-grid">
                        <div className={small ? "p-col-12" : "p-col-6"}>
                            <div className="p-fluid ">
                                <div className="p-field  p-md-12 " style={{ textAlign: 'left' }} >
                                    <label className="labels" >Selecciona un País<span className="require">*</span></label>
                                    <Dropdown value={country} options={countries} onChange={onCountryChange} filter name="pais" optionLabel="pais" placeholder="Seleccione un país" style={{ marginTop: '5px' }} />
                                    <p className="mensajeError">{countriesError ? countriesMensaje : ""}</p>
                                </div>

                            </div>
                            <div className="p-fluid ">
                                {
                                    viewCalendar ?
                                        <div className="p-field  p-md-12 " style={{ textAlign: 'left' }} >
                                            <div className="p-fluid ">
                                                <div className="p-field  p-md-12 " style={{ textAlign: 'left' }} >
                                                    <Calendar value={arrayFechasConvertidas} inline monthNavigator yearNavigator yearRange="2010:2030" locale="es"
                                                        selectionMode="multiple"  className="p-datepickerCalendar CalendarDataPiker"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {/* <div className="p-field">
                                    <Button label="Buscar" className="p-button-outlined p-button-secondary" />
                                </div> */}
                            </div>
                        </div>
                        <div className={small ? "p-col-12" : "p-col-6"} >
                            <Calendario
                                fechasProp={arrayFechas}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default SeleccionarPais