import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { EstandarFormatoFecha, SumarDias } from '../Funciones';
import Services from '../../service/Services';
import BtnCrearOtrosi from './BtnCrearOtrosi';
import OtrosiServicio from './OtrosiServicio';
import BtnActualizarOtrosi from './BtnActualizarOtrosi';
import ConfirmBtn from './ConfirmBtn';

const ContratoOtrosi = ({ idContractProp, contractProp, servicesProp, permissionsContractCreateProp, permissionsContractUpdateProp, permissionsContractDeleteProp,
    setRunRenderProp, runRenderProp, dateStartContractProp }) => {

    const [otherContract, setOtherContract] = useState([]);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [runRender, setRunRender] = useState(0)

    const getServices = new Services();

    useEffect(() => {
        if (displayBasic === true) {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        getServices.getOther().then(data => {
            var newData = data.map(item => {
                var newDateStart
                var newDateEnd
                if (gmtHours < 0) {
                    newDateStart = SumarDias(new Date(item.fechainiciovigencia), 1)
                    if (item.fechafinvigencia === null) {
                        newDateEnd = null
                    } else {
                        newDateEnd = SumarDias(new Date(item.fechafinvigencia), 1)
                    }
                } else {
                    newDateStart = new Date(item.fechainiciovigencia)
                    if (item.fechafinvigencia === null) {
                        newDateEnd = null
                    } else {
                        newDateEnd = new Date(item.fechafinvigencia)
                    }
                }
                return {
                    actualizadopor: item.actualizadopor,
                    contrato: item.contrato,
                    creadopor: item.creadopor,
                    descripcion: item.descripcion,
                    estado: item.estado,
                    fechaactualizado: item.fechaactualizado,
                    fechacreacion: item.fechacreacion,
                    fechafinvigencia: EstandarFormatoFecha(newDateEnd),
                    fechainiciovigencia: EstandarFormatoFecha(newDateStart),
                    sendDateStart: item.fechainiciovigencia,
                    sendDateEnd: item.fechafinvigencia,
                    id: item.id,
                    servicios: item.servicios
                }
            })
        
            var sendOther = newData.filter(function (object) {
                var idContract = object.contrato.id
                return idContract === idContractProp
            })

            sendOther.sort(function (a, b) {
                var textA = new Date(a.fechainiciovigencia);
                var textB = new Date(b.fechainiciovigencia);
                return textA - textB
            })

            setOtherContract(sendOther)
        })
        }

    }, [idContractProp, runRender, displayBasic, setDisplayBasic]) // eslint-disable-line react-hooks/exhaustive-deps

  
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permissionsContractCreateProp ?
                <BtnCrearOtrosi
                    idContractProp={idContractProp}
                    contractProp={contractProp}
                    servicesProp={servicesProp}
                    dateStartContractProp={dateStartContractProp}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                />
                :
                ""
            }

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    const acctions = (rowData) => {
        /* console.log(rowData) */
        return (
            <>
                {permissionsContractUpdateProp ?
                    <BtnActualizarOtrosi
                        idOtherProp={rowData.id}
                        otherProp={rowData.descripcion}
                        idContractProp={rowData.contrato.id}
                        dateStartValidityProp={rowData.sendDateStart}
                        dateEndValidityProp={rowData.sendDateEnd}
                        creationDateProp={rowData.fechacreacion}
                        createdByProp={rowData.creadopor}
                        dateStartContractProp={dateStartContractProp}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                    />
                    :
                    ""
                }
                <OtrosiServicio
                    idOtherProp={rowData.id}
                    otherProp={rowData.descripcion}
                    idContractProp={rowData.contrato.id}
                    dateStartValidityProp={rowData.sendDateStart}
                    dateEndValidityProp={rowData.sendDateEnd}
                    dateStartOtherShowProp={rowData.fechainiciovigencia}
                    dateEndOtherShowProp={rowData.fechafinvigencia}
                    creationDateProp={rowData.fechacreacion}
                    createdByProp={rowData.creadopor}
                    servicesProp={rowData.servicios}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                    permissionsContractCreateProp={permissionsContractCreateProp}
                    permissionsContractUpdateProp={permissionsContractUpdateProp}
                    permissionsContractDeleteProp={permissionsContractDeleteProp}
                />
                {permissionsContractDeleteProp ?
                    <ConfirmBtn
                        tittle="Confirmar Eliminación"
                        menssage="¿Está seguro de eliminar este otrosi?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                        action="DeleteOther"
                        label=""
                        classProp="p-button-danger"
                        icon="pi pi-trash"
                        tooltipProp="Eliminar"
                        statusProp={3}
                        idOtherProp={rowData.id}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                    />
                    :
                    ""
                }

            </>
        )
    }

    const dt = useRef(null);

    return (
        <>
            <Button icon="pi pi-cog" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => {
                onClick('displayBasic')
                setRunRender(runRender +1)
            }}
                tooltip="Gestionar Otrosi" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Contrato Otrosi asociados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={otherContract} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                            emptyMessage="No hay otrosi registrados para este contrato">
                            <Column className="columna" field="descripcion" sortable header="Descripción otro si" />
                            <Column className="columna" field="contrato.contrato" sortable header="Contrato" />
                            <Column className="columna" field="fechainiciovigencia" sortable header="Fecha inicio vigencia" />
                            <Column className="columna" field="fechafinvigencia" sortable header="Fecha fin vigencia" />
                            <Column header="Acciones" body={acctions} />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default ContratoOtrosi