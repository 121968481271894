import React, { useState, useEffect, useRef } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { addLocale } from 'primereact/api';
import { Panel } from "primereact/panel";
import { Divider } from "primereact/divider";
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from "primereact/toast";
import DataTable1 from "./DataTable1";
import DataTable2 from "./DataTable2";
import Services from "../../../../service/Services";
import "../../../../assets/css/Forms.css";
import "../../../../assets/css/DialogDemo.css";
import axios from "axios";
import { CambiarFormatoFecha, RestarMeses } from "../../../Funciones";
import { UseMedia } from "../../../Funciones";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CargaTrabajoProyectos = ({ idTrimestreProp, detalleTrimestreProp, usuariosCargaTrabajoProp }) => {

	const [servicioAno, setServicioAno] = useState([]);
	const [proyectoSesion, setProyectoSesion] = useState([]);
	const [clientSession, setClientSession] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false)
	const [loading2, setLoading2] = useState(false)
	const getServices = new Services();

	const tipoUsuario = cookies.get('tipoUsuario')
	const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
	useEffect(() => {
		setLoading(true)

		getServices.getAnos().then((data) => {
			if (data !== undefined) {
				setServicioAno(data)
			}
		});


		getServices.getClientesProyectosUsuario().then((data) => {
			if (data !== undefined) {

				const sendClients = []

				data.forEach(element => {
					var selectedClients = {}
					selectedClients.id = parseInt(element.cliente.id)
					selectedClients.name = element.cliente.nombre
					sendClients.push(selectedClients)
				});
				const tableClientes = {}
				const uniqueClients = sendClients.filter(function (object) {
					return tableClientes.hasOwnProperty(object.id) ? false : (tableClientes[object.id] = true)
				})
				setSelectedCliente(uniqueClients)
				setClientSession(uniqueClients)
				setItemsSelectedClient(uniqueClients.length)


				const tableProjects = {}
				const uniqueProjects = data.filter(function (object) {
					return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
				})

				setProyectoSesion(uniqueProjects.map(item => {
					return {
						nombreproyecto: item.nombre,
						id: parseInt(item.id),
						cliente: item.cliente
					}
				}))

				setSelectedProyecto(uniqueProjects.map((item) => {
					return {
						name: item.nombre,
						id: parseInt(item.id)
					}
				}))
				setItemsSelectedProject(uniqueProjects.length)

			}
		})
		getServices.getUsuariosSomeData().then(data => {
			if (data !== undefined) {
				setUsers(data)
			}
			setLoading(false)
		})

		// Función de limpieza para cancelar la solicitud cuando el componente se desmonta o cambia de pestaña
		return () => {
			if (source) {
				getServices.cancelRequest()
			}
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/// Proceso para llenar los clientes y los proyectos asociados al usuario en sesión

	const toast = useRef(null);

	//Obtener datos del select tipo de clientes

	var disabledCliente = false

	if (tipoUsuario === 'Cliente') {
		disabledCliente = true
	}


	const [selectedCliente, setSelectedCliente] = useState(null);

	const clientes = clientSession.map((item) => {
		const av = { name: item.name, id: item.id };

		return av;
	});

	const [itemsSelectedClient, setItemsSelectedClient] = useState(0);
	const onClienteChange = (e) => {
		setItemsSelectedClient(e.value.length)
		setSelectedCliente(e.value);
		setSelectedProyecto(null)
	};


	// seleccionar proyecto según  clientes
	const [selectedProyecto, setSelectedProyecto] = useState(null);

	var proyectoCliente = [];

	const cargarProyectos = () => {

		if (selectedCliente.length !== 0) {
			selectedCliente.forEach((element) => {
				const result = proyectoSesion.filter(function (objeto) {
					var idCliente = parseInt(objeto.cliente.id);
					return idCliente === element.id;
				});
				result.forEach((element) => {
					proyectoCliente.push(element);
				});
				/* console.log(result) */
				proyectoCliente.sort(function (a, b) {
					var textA = a.nombreproyecto;
					var textB = b.nombreproyecto;
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				});
			});
			return proyectoCliente;
		}
	};

	if (selectedCliente !== null) {
		cargarProyectos();
	}

	const proyectos = proyectoCliente.map((item) => {
		const av = { name: item.nombreproyecto, id: item.id };
		return av;
	});

	const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
	const onProyectoChange = (e) => {
		setItemsSelectedProject(e.value.length)
		setSelectedProyecto(e.value);
		setSelectedCargo(null)
		setSelectedUsuario(null)
	};

	// Seleccionar cargo

	var projectPositions = []
	let getUsers = []
	const loadPosition = () => {
		if (selectedProyecto.length !== 0) {
			selectedProyecto.forEach(element => {
				users.forEach(i => {
					i.proyectos.forEach(obj => {
						if (element.id === obj.proyecto.id) {
							getUsers.push(i)
						}
					})
				})
			})

			const tableUsers = {}
			const uniqueUsers = getUsers.filter(function (object) {
				return tableUsers.hasOwnProperty(object.idUsuario) ? false : (tableUsers[object.idUsuario] = true)
			})
			uniqueUsers.forEach(element => {
				projectPositions.push(element.cargo)
			})
		}
		return projectPositions
	}

	if (selectedProyecto !== null) {
		loadPosition()
	}

	const [selectedCargo, setSelectedCargo] = useState(null);

	const tableProjectPosition = {}
	const uniqueProjectPositions = projectPositions.filter(function (object) {
		return tableProjectPosition.hasOwnProperty(object.id) ? false : (tableProjectPosition[object.id] = true)
	})
	const cargos = uniqueProjectPositions.map((item) => {
		const av = { name: item.cargo, id: item.id };

		return av;
	});

	const [itemsSelectedPosition, setItemsSelectedPosition] = useState(0);
	const onCargoChange = (e) => {
		setItemsSelectedPosition(e.value.length)
		setSelectedCargo(e.value);
		setSelectedUsuario(null)
	};

	//Seleccionar Año

	var dateCurrent = new Date()

	var newYear
	if (detalleTrimestreProp === 'OCTUBRE - DICIEMBRE') {
		newYear = dateCurrent.getFullYear() - 1
	} else {
		newYear = dateCurrent.getFullYear()
	}

	const [selectedAno, setSelectedAno] = useState({
		ano: newYear
	});
	const anos = servicioAno.map(item => {
		const av = {
			ano: item
		}
		return av
	})


	const onAnoChange = (e) => {
		setSelectedAno(e.value);
		setSelectedUsuario(null)
		var date = new Date(String(e.value.ano), selectedTrimestre?.id, 1)
		sendDates(e.value.ano, date)
	}

	//Obtener datos del select trimestre

	const [selectedTrimestre, setSelectedTrimestre] = useState({
		id: idTrimestreProp,
		detalle: detalleTrimestreProp,
	});

	const trimestres = [
		{
			id: 0,
			detalle: 'Enero'
		},
		{
			id: 1,
			detalle: 'Febrero'
		},
		{
			id: 2,
			detalle: 'Marzo'
		},
		{
			id: 3,
			detalle: 'Abril'
		},
		{
			id: 4,
			detalle: 'Mayo'
		},
		{
			id: 5,
			detalle: 'Junio'
		},
		{
			id: 6,
			detalle: 'Julio'
		},
		{
			id: 7,
			detalle: 'Agosto'
		},
		{
			id: 8,
			detalle: 'Septiembre'
		},
		{
			id: 9,
			detalle: 'Octubre'
		},
		{
			id: 10,
			detalle: 'Noviembre'
		},
		{
			id: 11,
			detalle: 'Diciembre'
		},
	]

	const onSelectedTrimestreChange = (e) => {
		setSelectedTrimestre(e.value)
		var date = new Date(String(selectedAno.ano), e.value.id, 1)
		var validaDate = new Date(String(selectedAno.ano), e.value.id + 1, 0)
		if (validaDate > new Date()) {
			setMonthError(true)
			setMessageMonth('No puede seleccionar meses no completados')
		} else {
			setMonthError(false)
			setMessageMonth('')
		}
		setSelectedUsuario(null)
		sendDates(selectedAno.ano, date)
	}


	//Poner calendario al español

	addLocale('es', {
		firstDayOfWeek: 1,
		dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
		dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		today: 'Hoy',
		clear: 'Limpiar'
	});

	//validaciones

	const [monthError, setMonthError] = useState(false)
	const [messageMonth, setMessageMonth] = useState('')

	const [dateStart, setDateStart] = useState(null);
	const [dateEnd, setDateEnd] = useState(null);

	const sendDates = (year, date) => {
		var lastMonthToday = new Date(String(year), date.getMonth() + 1, 0);
		var lastTwoMonthsToday = new Date(String(year), lastMonthToday.getMonth());
		setDateEnd(lastMonthToday)
		setDateStart(RestarMeses(lastTwoMonthsToday, 2))
	}


	useEffect(() => {
		const today = new Date()
		const date = new Date(String(selectedAno.ano), today.getMonth())
		var sendDate = RestarMeses(date, 1)
		var textDate = sendDate.toLocaleString("es-US", { month: "long" })
		var sendTextDate = textDate[0].toUpperCase() + textDate.substring(1)
		var idDate = sendDate.getMonth()
		setSelectedTrimestre({
			id: idDate,
			detalle: sendTextDate,
		})

		const newDate = new Date(String(selectedAno.ano), idDate, 1)

		const lastMonthToday = new Date(String(selectedAno.ano), newDate.getMonth() + 1, 0);
		const lastTwoMonthsToday = new Date(String(selectedAno.ano), lastMonthToday.getMonth());
		setDateEnd(lastMonthToday)
		setDateStart(RestarMeses(lastTwoMonthsToday, 2))

	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// Seleccionar usuario

	const [newUsuariosCargaTrabajoProp, setNewUsuariosCargaTrabajoProp] = useState([])


	const [selectedUsuario, setSelectedUsuario] = useState(null);

	const usuarios = newUsuariosCargaTrabajoProp.map((item) => {
		const av = { name: item.nombre, id: item.numerodocumento };
		return av;
	});

	const [itemsSelectedUser, setItemsSelectedUser] = useState(0);
	const onUsuarioChange = (e) => {
		setItemsSelectedUser(e.value.length)
		setSelectedUsuario(e.value);
	};

	// servicio para lista de usuario 
	useEffect(() => {
		setLoading2(true)
		const newSource = axios.CancelToken.source();
		const serviseListUsers = async () => {
			const url = process.env.REACT_APP_BASE_URL + '/v1/api/report/bulkUsersSelection'

			var newIdCargo = selectedCargo === null ? [] : selectedCargo.map(item => { return item.id })
			var newIdClientes = selectedCliente === null ? [] : selectedCliente.map(item => { return item.id })
			var newIdProyectos = selectedProyecto === null ? [] : selectedProyecto.map(item => { return item.id })

			var newDateStart = dateStart === null ? dateStart : CambiarFormatoFecha(dateStart)
			var newDateEnd = dateEnd === null ? dateEnd : CambiarFormatoFecha(dateEnd)

			var json = JSON.stringify(
				{
					fecFinal: newDateEnd,
					fecInicial: newDateStart,
					idCargo: newIdCargo,
					idClientes: newIdClientes,
					idProyectos: newIdProyectos,
					idUsuarios: []
				}
			)

			await axios.post(url, json,
				{
					headers:
					{
						'Content-Type': 'application/json',
						"Access-Control-Allow-Origin": "*",
						"Authorization": `Bearer ${sessionStorage.getItem('token')}`
					},
					cancelToken: source.token
				})
				.then(response => {
					setNewUsuariosCargaTrabajoProp(response.data)
					/* //console.log(response.data) */
					getDashBoard(response.data)
				})
				.catch(function (error) {
					/* toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 }); */
					//console.log(error.response)
					setLoading2(false)
					setNewUsuariosCargaTrabajoProp([])
					setDashBoardData([])
				})
		}
		if (selectedAno.ano !== '' && loading === false) {
			serviseListUsers();
			window.addEventListener("changes", serviseListUsers)

		}

		return () => {
			if (newSource) {
				source.cancel()
			}
		}

	}, [selectedAno, selectedCargo, selectedCliente, selectedProyecto, selectedUsuario, dateStart, dateEnd, loading])  // eslint-disable-line react-hooks/exhaustive-deps


	// petición al servicio 

	const [dashBoardData, setDashBoardData] = useState([])

	/* console.log(usuariosCargaTrabajoProp) */

	const getDashBoard = async (data) => {

		const url = process.env.REACT_APP_BASE_URL + '/v1/api/report/dashBoardv2'


		/* console.log(selectedUsuario) */

		var json

		var newIdCargo = selectedCargo === null ? [] : selectedCargo.map(item => { return item.id })
		var newIdClientes = selectedCliente === null ? [] : selectedCliente.map(item => { return item.id })
		var newIdProyectos = selectedProyecto === null ? [] : selectedProyecto.map(item => { return item.id })
		var newIdUsuario =
			selectedUsuario === null
				?
				data.map(item => { return item.idColaborador })
				:
				selectedUsuario.length === 0
					?
					data.map(item => { return item.idColaborador })
					:
					selectedUsuario.map(item => { return item.id })

		var newDateStart = dateStart === null ? dateStart : CambiarFormatoFecha(dateStart)
		var newDateEnd = dateEnd === null ? dateEnd : CambiarFormatoFecha(dateEnd)

		json = JSON.stringify(
			{
				fecFinal: newDateEnd,
				fecInicial: newDateStart,
				idCargo: newIdCargo,
				idClientes: newIdClientes,
				idProyectos: newIdProyectos,
				idUsuarios: newIdUsuario
			}
		)


		/* console.log(json) */

		await axios.post(url, json,
			{
				headers:
				{
					'Content-Type': 'application/json',
					"Access-Control-Allow-Origin": "*",
					"Authorization": `Bearer ${sessionStorage.getItem('token')}`
				},
				cancelToken: source.token
			})
			.then(response => {
				if (response.data.usuarios.length === 0) {
					toast.current.show({ severity: 'info', summary: 'No hay datos', detail: 'El usuario tiene horas parametrizadas en 0', life: 6000 });

				} else {
					toast.current.show({ severity: 'success', summary: 'Consulta satisfactoria', detail: response.data.message, life: 6000 });

				}
				setDashBoardData(response.data);
				setLoading2(false)
				/* //console.log(response.data) */
			})
			.catch(function (error) {
				setLoading2(false)
				if (error !== undefined) {
					toast?.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });

				}
				//console.log(error.response)
				setDashBoardData([])

			})
	}


	////validaciones proyectos

	let disabledProyecto;
	let proyectosError = false
	let mensajeProyectos = ''

	if (selectedCliente !== null) {
		if (selectedCliente.length === 0) {
			disabledProyecto = true
		} else {
			disabledProyecto = false

			if (proyectoCliente.length === 0) {
				proyectosError = true
				mensajeProyectos = 'El cliente o clientes no tienen proyectos asociados'
			}

		}
	} else {
		disabledProyecto = true
	}



	var funcionarioError = false;
	var mensajeFuncionario = "";
	var disabledFuncionario = false;

	if (newUsuariosCargaTrabajoProp.length === 0) {
		funcionarioError = true;
		mensajeFuncionario = "No se registran usuarios";
		disabledFuncionario = true;
	} else {
		funcionarioError = false;
		mensajeFuncionario = "";
		disabledFuncionario = false;
	}


	var disabledPosition = false

	if (selectedProyecto === null || selectedProyecto.length === 0) {
		disabledPosition = true
	}

	let small = UseMedia("(max-width: 1570px)");
	
	return (
		<div className="">
			<Toast ref={toast} />
			<div className="p-fluid p-formgrid p-grid">

				<form style={{ display: 'contents' }} >
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Año<span className="require">*</span></label>
						<Dropdown value={selectedAno} options={anos} onChange={onAnoChange} name="ano" optionLabel="ano" placeholder="Seleccione Año"
							className="listas" emptyMessage="Cargando datos"
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Mes de fin de Trimestre<span className="require">*</span></label>
						<Dropdown value={selectedTrimestre} options={trimestres} onChange={onSelectedTrimestreChange} name="detalle" optionLabel="detalle" placeholder="Seleccione un mes"
							className={monthError ? "p-invalid listas" : "error listas"} emptyMessage="Cargando datos"
						/>
						<p className="mensajeError">{monthError ? messageMonth : ""}</p>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Cliente</label>
						<MultiSelect value={selectedCliente} options={clientes} onChange={onClienteChange} name="name" optionLabel="name" filter
							placeholder="Seleccione un cliente" disabled={disabledCliente} maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`}
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Proyecto</label>
						<MultiSelect value={selectedProyecto} options={proyectos} onChange={onProyectoChange} name="proyecto" optionLabel="name" filter
							placeholder="Seleccione un proyecto" disabled={disabledProyecto} maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`}
						/>
						<p className="mensajeError">{proyectosError ? mensajeProyectos : ""}</p>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Cargo</label>
						<MultiSelect value={selectedCargo} options={cargos} onChange={onCargoChange} name="cargo" optionLabel="name"
							placeholder="Seleccione un cargo" maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedPosition} Elementos Seleccionados`}
							disabled={disabledPosition} filter
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Funcionario</label>
						<MultiSelect value={selectedUsuario} options={usuarios} onChange={onUsuarioChange} name="usuario" optionLabel="name" filter
							placeholder="Seleccione un funcionario" disabled={disabledFuncionario} maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedUser} Elementos Seleccionados`}
						/>
						<p className="mensajeError">
							{funcionarioError ? mensajeFuncionario : ""}
						</p>
					</div>
				</form>
				<div className="p-field p-col-12 p-md-12" style={{ marginTop: '5px' }}>
					{
						loading2 ?
							<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>

							:
							""
					}
				</div>
				<div className="p-field p-col-12 p-md-12">
					<Divider />
				</div>
			</div>
			<div className="p-grid">

				<div className={small ? "p-col-12" : "p-col-6"}>
					<Panel header="Comportamiento carga de trabajo">
						<DataTable1 dashBoardDataProp={dashBoardData}
							loadingProp={loading2}
							dateStartProp={dateStart}
							dateEndProp={dateEnd}
						/>
					</Panel>
				</div>
				<div className={small ? "p-col-12" : "p-col-6"}>
					<Panel header="Horas invertidas por proyecto">
						<DataTable2 dashBoardDataProp={dashBoardData}
							loadingProp={loading2}
						/>
					</Panel>
				</div>
			</div>
		</div>
	);
}
export default CargaTrabajoProyectos
