import React, { useState, useEffect, useRef } from "react"
import { Button } from "primereact/button"
import { Dialog } from 'primereact/dialog';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { UseMedia, EstandarFormatoFecha, SumarDias } from "../Funciones"
import BtnEditarFuncionarios from "./BtnEditarFuncionarios";
import ConfirmBtn from '../ConsultarUsuarios/ConfirmBtn';
import Cookies from "universal-cookie";
import AñadirFuncionarios from "./BtnAñadirFuncionarios";
import Services from '../../service/Services';
import axios from "axios";
const BtnAsociacionesTeamWork = ({ official, dateStartTeamProp, dateEndTeamProp, idClientsAsociacionesProp, teamWorkProp, idProp, createByProp, dateCreatedProp, runRenderProp, setRunRenderProp, idCoordinatorProp }) => {

    const getServices = new Services()
    const [displayBasic, setDisplayBasic] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const [informationOfficial, setInformationOfficial] = useState([])

    useEffect(() => {

        const officials = official.map(item => {
            var d = new Date()
            var gtmHours = -d.getTimezoneOffset() / 60
            var formatDateStart, formatDateEnd

            var status
            if (item.estado === 1) {
                status = "Vigente"
            }
            else if (item.estado === 0) {
                status = "No vigente"
            }

            if (gtmHours < 0) {
                formatDateStart = SumarDias(new Date(item.fechainicioasignacion), +1)
                formatDateEnd = SumarDias(new Date(item.fechafinasignacion), +1)
            }
            else {
                formatDateStart = new Date(item.fechainicioasignacion)
                formatDateEnd = new Date(item.fechafinasignacion)
            }

            return {
                id: item.id,
                dateStart: EstandarFormatoFecha(formatDateStart),
                dateEnd: item.fechafinasignacion === null ? item.fechafinasignacion : EstandarFormatoFecha(formatDateEnd),
                fechainicioasignacion: item.fechainicioasignacion,
                fechafinasignacion: item.fechafinasignacion,
                nombre: item.usuario.nombre + ' ' + item.usuario.apellido,
                idUsuario: item.usuario.id,
                estado: status
            }
        })
        setInformationOfficial(officials)

    }, [official, dateStartTeamProp, dateEndTeamProp, runRenderProp]) // eslint-disable-line react-hooks/exhaustive-deps

    const [serviceUsers, setServiceUsers] = useState([]);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        let source;
        if (displayBasic === true) {
            setLoading(true)
            source = axios.CancelToken.source();
            getServices.getUsuarios().then(data => {
                if (data !== undefined) {
                    if (idClientsAsociacionesProp !== null) {
                        getServices.getUsuariosAsociadosCliente(idClientsAsociacionesProp).then(newData => {
                            var arr = []
                            newData.forEach(element => {
                                const result = data.filter(item => {
                                    return item.idUsuario === element.id
                                })
                                const activeUsers = result.filter(item => {
                                    return item.estado === 1 || item.estado === 0
                                })
                                activeUsers.forEach(i => {
                                    arr.push(i)
                                })
                            })
                            setServiceUsers(arr)
                            setLoading(false)
                        })
                    }
                }
            })
        }

        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic, idClientsAsociacionesProp])// eslint-disable-line react-hooks/exhaustive-deps

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        )
    }

    //Propiedades de la tabla
    let small = UseMedia("(max-width: 760px)");

    //Almaceno los valores que se enviarán por props
    const cookies = new Cookies()

    const usuarioSesion = cookies.get('usuario')

    const Acciones = (rowData) => {

        var arrayOfficial = [{
            id: rowData.id,
            usuario: { id: rowData.idUsuario },
            estado: 3,
            fechafinasignacion: rowData.fechafinasignacion,
            fechainicioasignacion: rowData.fechainicioasignacion
        }]

        var updateArrayOfficial = [{
            id: rowData.id,
            usuario: {
                id: rowData.idUsuario
            },
            fechacreacion: new Date(),
            fechainicioasignacion: rowData.dateStart,
            fechafinasignacion: rowData.dateEnd,
            estado: 1
        }]
        return (
            <>
                <div>
                    <BtnEditarFuncionarios
                        nameOfficial={rowData.nombre}
                        informationOfficialProp={informationOfficial}
                        dateStartOfficial={rowData.fechainicioasignacion}
                        dateEndOfficial={rowData.fechafinasignacion}
                        idOfficialProp={informationOfficial}
                        idOficcial={rowData.idUsuario}
                        dateStartTeam={dateStartTeamProp}
                        dateEndTeam={dateEndTeamProp}
                        idClientProp={idClientsAsociacionesProp}
                        idCoordinatorProp={idCoordinatorProp}
                        createdByProp={createByProp}
                        idRegister={idProp}
                        dateCreatedProp={dateCreatedProp}
                        updateDateEndProp={dateEndTeamProp}
                        updateDateStartProp={dateStartTeamProp}
                        updateCoordinatorProp={[]}
                        nameTeamProp={teamWorkProp}
                        registerIdOfficial={rowData.id}
                        dateCreatedOfficialProp={rowData.fechacreacion}
                        updateOfficial={updateArrayOfficial}
                        runRenderProp={runRenderProp}
                        setRunRenderProp={setRunRenderProp}
                        serviceUsersProp={serviceUsers}
                    />
                </div>

                <div>
                    <ConfirmBtn
                        titulo='Funcionarios'
                        mensaje='¿Desea eliminar este funcionario?, Recuerde que una vez se elimine se borrará definitivamente del sistema'
                        accion='EliminarFuncionario'
                        icono='pi pi-trash'
                        classProp="p-button-danger"
                        tooltipProp="Eliminar"
                        updateDateProp={true}
                        usuarioSesionProp={usuarioSesion}
                        updateClientTeam={idClientsAsociacionesProp}
                        createByProp={createByProp}
                        idUpdate={idProp}
                        dateCreateProp={dateCreatedProp}
                        updateDateEndProp={dateEndTeamProp}
                        updateDateStartProp={dateStartTeamProp}
                        updateCoordinatorTeam={[]}
                        updateNameTeam={teamWorkProp}
                        updateOfficialTeam={arrayOfficial}
                        runRenderProp={runRenderProp}
                        setRunRenderProp={setRunRenderProp}
                    />
                </div>
            </>
        )
    }
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };
    const header = (
        <div className="headerTable">
            <div>
                <AñadirFuncionarios
                    officialProp={official}
                    informationOfficialProp={informationOfficial}
                    idClientsAsociacionesProp={idClientsAsociacionesProp}
                    dateStartTeamProp={dateStartTeamProp}
                    dateEndTeamProp={dateEndTeamProp}
                    teamWorkProp={teamWorkProp}
                    idProp={idProp}
                    createByProp={createByProp}
                    dateCreatedProp={dateCreatedProp}
                    runRenderProp={runRenderProp}
                    setRunRenderProp={setRunRenderProp}
                    idCoordinatorProp={idCoordinatorProp}
                    serviceUsersProp={serviceUsers}
                    loadingProp={loading}
                />
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    return (
        <>

            <Button icon="pi pi-eye" className="p-button-secondary" onClick={() => onClick('displayBasic')} style={{ marginTop: '5px' }}
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />

            <Dialog className="modal" header="Asociaciones equipo de trabajo" visible={displayBasic}
                footer={renderFooter('displayBasic')} style={{ width: '80vw' }} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-grid p-formgrid">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="card">
                            <DataTable ref={dt} emptyMessage="No hay resultados" value={informationOfficial} sortField="nombre" sortOrder={1} paginator rows={10} className="p-datatable-responsive-demo"
                                breakpoint="760px" responsiveLayout={small ? "stack" : "scroll"} style={{ top: '10px', width: '79vw' }}
                                header={header} globalFilter={globalFilter}>
                                <Column field='nombre' sortable header="Funcionarios" />
                                <Column field='dateStart' sortable header="Fecha inicio funcionario" />
                                <Column field='dateEnd' sortable header="Fecha fin funcionario" />
                                <Column field='estado' sortable header="Estado" />
                                <Column header="Acciones" body={Acciones} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default BtnAsociacionesTeamWork