import { UpdateValidity } from '../ActualizacionVigencias'

export async function listUsersProjects(idProject, users, oldDateEndProp, newDateEnd) {
    const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/updateUser'
    var array = []
    users.forEach(i => {
        i.proyectos.forEach(e => {
            if (e.proyecto.id === idProject) {
                array.push(i)
            }
        })
    })
    array.forEach(element => {
        element.proyectos.forEach(i => {
            if ((i.fechafinvigencia === oldDateEndProp || i.fechafinvigencia === null)&& parseInt(i.proyecto.id) === parseInt(idProject)) {
                const json = {
                    ...element,
                    proyectos: [{
                        ...i,
                        fechafinvigencia: newDateEnd,
                        estado: i.estado === 0 ? 1 : i.estado
                    }]
                }
                UpdateValidity(url, json)
            }
        })

    })
}