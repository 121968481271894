import React from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import '../assets/css/Sidebar.css'
import '../assets/css/DialogDemo.css'

import Pestaña from '../componets/ConsultarActividades/Pestaña'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ConsultarActividades = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol


    if (permisos[1].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultarActividades = (
        <span className="disabled">Registro y consulta de actividades</span>
    )

    const { indicador } = useParams();

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultarActividades}

                />
                <div className="tabla">
                    <Pestaña
                        permisosProp={permisos}
                        indicadorProp={indicador}
                    />

                </div>

            </div>
        </div>

    )
}

export default ConsultarActividades