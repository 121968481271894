import React from 'react'
import SidebarMenu from '../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import '../assets/css/Sidebar.css'

import Cookies from 'universal-cookie';
import ConsultarUsuariosClientes from '../componets/UsuariosClientes/DataTable'

const cookies = new Cookies();

const UsuariosClientes = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol


    if (permisos[26].consultar === "false") {
        window.location.href = "/registroActividades/inicio"
    }

    const consultar = (
        <span className="disabled">Consultar usuarios de clientes</span>
    )

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">

                    <h1 >Consultar usuarios de clientes</h1>
                </div>
                <div className="tabla">
                    <ConsultarUsuariosClientes />
                </div>
            </div>
        </div>
    )
}

export default UsuariosClientes