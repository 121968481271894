import React from 'react';
/* import { BreadCrumb } from 'primereact/breadcrumb'; */
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';




function MigadePan({home, consultar}) {
   


    return (

        <nav>
            <ul className="breadcrumbs">
                <li className="breadcrumbsItem">
                    {home}
                </li>
                <li className="breadcrumbsItem">
                    {consultar}
                </li>
                
            </ul>
        </nav>

    );
}

export default MigadePan