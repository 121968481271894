import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { UseMedia } from '../Funciones';
import { Button } from "primereact/button";
import { CambiarFormatoFecha, isValidDate } from "../Funciones";



const Observaciones = ({ showAllDataProp, loadingProp, servicesWorkTeamProp, allDataProp,
    dateStartPeriodProp, dateEndPeriodProp, getClientsProp }) => {


    const dt = useRef(null);


    const [sendObservationsExport, setSendObservationsExport] = useState([]);
    useEffect(() => {
        if (showAllDataProp === true) {
            var newAllDataProp = []
            // Nuevo array para almacenar los datos filtrados
            const newDataFiltered = [];
            allDataProp.forEach(item => {
                var workTeamUser = []
                servicesWorkTeamProp.forEach(element => {
                    element.lider.forEach(x => {

                        if (x.colaborador.idusuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartPeriodProp),
                                CambiarFormatoFecha(dateEndPeriodProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                    element.usuarios.forEach(x => {
                        if (x.usuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartPeriodProp),
                                CambiarFormatoFecha(dateEndPeriodProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                })

                var period = JSON.parse(item.periodo)
                newAllDataProp.push({
                    cliente: item.cliente.razonsocial,
                    periodo: new Date(period.dateEnd).toLocaleString("es-US", { month: "long" }) + ' ' + new Date(period.dateEnd).getFullYear(),
                    equipoTrabajo: workTeamUser.length !== 0 ? workTeamUser[0].nombreequipotrabajo : '',
                    nombreColaborador: item.usuario.nombre + ' ' + item.usuario.apellido,
                    estado: item.estado === 3 ? 'Cerrado' : 'Abierto',
                    observaciones: item.observaciones
                })
            })
            const uniqueCombos = {};
            // Recorrer el array original y filtrar los datos
            for (const data of newAllDataProp) {
                const comboKey = `${data.cliente}-${data.periodo}-${data.nombreColaborador}`;

                if (!uniqueCombos[comboKey]) {
                    uniqueCombos[comboKey] = true;
                    newDataFiltered.push(data);
                }
            }
            setSendObservationsExport(newDataFiltered)
        }

    }, [showAllDataProp, allDataProp, servicesWorkTeamProp, dateStartPeriodProp, dateEndPeriodProp, getClientsProp])// eslint-disable-line react-hooks/exhaustive-deps


    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(sendObservationsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {showAllDataProp ?
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLSX" />
                :
                null
            }
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>

        </div>

    );

    let small = UseMedia("(max-width: 760px)");

    return (
        <>
            <div className="datatable-responsive-demo">
                <h3 className="labels">Observaciones</h3>
                <div className="card">
                    {
                        showAllDataProp ?
                            <DataTable ref={dt} value={sendObservationsExport} dataKey="id" header={header} globalFilter={globalFilter} loading={loadingProp}
                                emptyMessage="Objetivo no registrado en el sistema" paginator rows={10} responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px">
                                <Column className="columna" field="cliente" sortable header="Cliente" />
                                <Column className="columna" field="periodo" sortable header="Periodo" />
                                <Column className="columna" field="equipoTrabajo" sortable header="Equipo de Trabajo" />
                                <Column className="columna" field="nombreColaborador" sortable header="Nombre Colaborador" />
                                <Column className="columna" field="observaciones" sortable header="Observaciones/Recomendaciones" />
                                <Column className="columna" field="estado" sortable header="Estado Informe" />
                            </DataTable>
                            :
                            ''
                    }
                </div>

            </div>
        </>
    )
}

export default Observaciones