import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from "primereact/dropdown";
import Services from '../../service/Services';
import '../../assets/css/DataTable.css';
import axios from 'axios';
import BtnCrearNovedadFucionario from './BtnCrearNovedadFuncionario';
import BtnCrearNovedad from './BtnCrearNovedad';
import BtnActualizarNovedad from './BtnActualizarNovedad'
import BtnDetalleNOvedad from './BtnDetalleNovedad'
import BtnEliminar from './BtnEliminar';
import { CambiarFormatoFecha, EstandarFormatoFecha, SumarDias, UseMedia } from '../Funciones';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const TableNovedades = ({ permisosProp }) => {

    const [loading, setLoading] = useState(true);
    const [cierres, setCierres] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState(null);
    const [sendIdsActivities, setSendIdsActivities] = useState([]);
    const [servicioUsuario, setServicioUsuario] = useState([]);
    const [servicioNovedad, setServicioNovedad] = useState([]);
    const [servicioNovedadGuardarFiltro, setServicioNovedadGuardarFiltro] = useState([]);
    const [servicioTipoActividad, setServicioTipoActividad] = useState([]);
    const idColaborador = cookies.get("idColaborador");
    const [idColaboradorConsulta, setIdColaboradorConsulta] = useState(idColaborador)

    const getServices = new Services();
    const [idsClientesUserSession, setIdsClientesUserSession] = useState([])
    useEffect(() => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        const source = axios.CancelToken.source()
        getServices.getClientesProyectosUsuario().then(data => {
            if (data !== undefined) {
                const filterClientVigente = data.filter(obj => {
                    return obj.estado === 1
                })
                const sendClients = filterClientVigente.map(item => {
                    return parseInt(item.cliente.id)
                })

                const tableClientes = {}
                const uniqueClients = sendClients.filter(function (object) {
                    return tableClientes.hasOwnProperty(object) ? false : (tableClientes[object] = true)
                })
                setIdsClientesUserSession(uniqueClients)
            }
        })
        getServices.getCierres().then(data => {
            if (data !== undefined) {
                setCierres(data)
            }
        });

        getServices.getNovedad(idColaboradorConsulta).then(data => {
            if (data !== undefined) {
                var newData = data.map(item => {
                    var newDateStart
                    var newDateEnd
                    if (gmtHours < 0) {
                        newDateStart = SumarDias(new Date(item.fecInicio), 1)
                        newDateEnd = SumarDias(new Date(item.fecFin), 1)
                    } else {
                        newDateStart = new Date(item.fecInicio)
                        newDateEnd = new Date(item.fecFin)
                    }
                    return {
                        colaborador: item.colaborador,
                        descripcion: item.descripcion,
                        estado: item.estado,
                        fecCreacion: item.fecCreacion,
                        fecFin: EstandarFormatoFecha(newDateEnd),
                        sendDateEnd: item.fecFin,
                        fecInicio: EstandarFormatoFecha(newDateStart),
                        sendDateStart: item.fecInicio,
                        id: item.id,
                        isTotal: item.isTotal,
                        tipoNovedad: item.tipoNovedad,
                        usuarioCreacion: item.usuarioCreacion,
                        areanegocio: item.areanegocio,
                        aplicacion: item.aplicacion,
                        numerohoras: item.numerohoras,
                        novedadExtendida:item.novedadExtendida
                    }
                })
                setServicioNovedad(newData)
                setServicioNovedadGuardarFiltro(newData)
                setLoading(false)
            }
        })
        getServices.getTipoNovedad().then(data => {
            if (data !== undefined) {
                setServicioTipoActividad(data)
            }

        });
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [idColaboradorConsulta]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const dataIds = {
            idClientes: idsClientesUserSession
        }
        getServices.getUsersByClient(dataIds).then(data => {
            if (data !== undefined) {
                setServicioUsuario(data)
            }
        });
    }, [idsClientesUserSession])// eslint-disable-line react-hooks/exhaustive-deps
    const dt = useRef(null);

    // filtros de la tabla 

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        setDateStart(e.value);
        if (e.value !== null) {
            const fechaInicio = CambiarFormatoFecha(e.value)
            if (dateEnd !== null) {
                filtrarServicioNovedad(fechaInicio, CambiarFormatoFecha(dateEnd), tipoActividad)
            } else {
                filtrarServicioNovedad(fechaInicio, dateEnd, tipoActividad)
            }
        } else {
            filtrarServicioNovedad(e.value, dateEnd, tipoActividad)
        }
    }

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        setDateEnd(e.value);
        if (e.value !== null) {
            const fechaFin = CambiarFormatoFecha(e.value)
            if (dateStart !== null) {
                filtrarServicioNovedad(CambiarFormatoFecha(dateStart), fechaFin, tipoActividad)
            } else {
                filtrarServicioNovedad(dateStart, fechaFin, tipoActividad)
            }
        } else {
            filtrarServicioNovedad(dateStart, e.value, tipoActividad)
        }
    }


    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const [tipoActividad, setTipoActividad] = useState(null);

    const tipoActividades = servicioTipoActividad.map(item => {
        const av = item.descripcion
        return av
    })

    const onTipoActividadChange = (e) => {
        setTipoActividad(e.value);
        if (dateStart !== null && dateEnd !== null) {
            filtrarServicioNovedad(CambiarFormatoFecha(dateStart), CambiarFormatoFecha(dateEnd), e.value)
        } else {
            filtrarServicioNovedad(dateStart, dateEnd, e.value)
        }
    }


    /* const FechaInicioFilter = <div className="p-fluid p-grid">
        <div className="p-md-12">
            <Calendar placeholder="Seleccione " className="fechaFlilter" style={{ marginBottom: '-7px' }} value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es" showButtonBar />
        </div>
    </div>
    
    const FechaFinFilter = <div className="p-fluid p-grid">
        <div className="p-md-12">
            <Calendar placeholder="Seleccione " className="fechaFlilter" style={{ marginBottom: '-7px' }} value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" showButtonBar />
        </div>
    </div>
    
    const TipoActividadFilter = <Dropdown value={tipoActividad} options={tipoActividades} onChange={onTipoActividadChange} placeholder="Seleccione " className="p-column-filter" showClear />;
    
    */
    const tipodocumento = cookies.get('tipoDocumento')
    const documento = cookies.get('numeroDocumento')
    const nombre = cookies.get('nombre')
    const cargo = cookies.get('cargo')
    const usuario = cookies.get('usuario')
    const rol = cookies.get('code')

    // validar permisos para acciones 

    var permisoActivityCreate
    if (permisosProp[1].permisos[0] === "Crear") {
        permisoActivityCreate = true
    } else {
        permisoActivityCreate = false
    }
    var permisoActivityUpdate
    if (permisosProp[1].permisos[1] === "Editar") {
        permisoActivityUpdate = true
    } else {
        permisoActivityUpdate = false
    }
    var permisoActivityDelete
    if (permisosProp[1].permisos[2] === "Eliminar") {
        permisoActivityDelete = true
    } else {
        permisoActivityDelete = false
    }
    var permisoNovedadOtroFucionario
    if (permisosProp[1].permisos[3] === "NovedadOtroFucionario") {
        permisoNovedadOtroFucionario = true
    } else {
        permisoNovedadOtroFucionario = false
    }

    let eliminarActividades = []

    const onChangeSelectActivities = (e) => {
        setSelectedActivities(e.value)
        let ids = e.value
        ids.forEach(element => {
            eliminarActividades.push(element.id)
        })
        setSendIdsActivities(eliminarActividades)
    }

    // selección de usuario para gestionar novedades

    const [selectedUsuario, setSelectedUsuario] = useState({
        id: '',
        nombrecompleto: '',
        historicohoras: []
    });
    const usuarios = servicioUsuario.map(item => {
        const av = { id: item.idColaborador, usuario: item.nombre, historicohoras: item.historicohoras }
        return av
    })

    const onUsuarioChange = (e) => {
        setSelectedUsuario(e.value);
        if (e.value === null || e.value === undefined) {
            setServicioNovedad([]); 
            setIdColaboradorConsulta(null); 
        } else {
            setIdColaboradorConsulta(e.value.id); 
        }
    }
    
    

    // estado para radiobutton 

    const [selectUser, setSelectUser] = useState('selectUser');

    const onSelectUserChange = (e) => {
        setSelectUser(e.value)
        setSelectedUsuario({
            id: '',
            nombrecompleto: '',
            horasADedicar: ''
        })
        if (e.value === 'selectOtherUser') {
            setServicioNovedad([])
            setIdColaboradorConsulta()
        } else {
            setIdColaboradorConsulta(idColaborador)
        }
    }

    const [fechaInicioError, setFechaInicioError] = useState(false)
    const [mensajeFechaInicioError, setMensajeFechaInicioError] = useState('')

    const [fechaFinError, setFechaFinError] = useState(false)
    const [mensajeFechaFinError, setMensajeFechaFinError] = useState('')

    const filtrarServicioNovedad = (dateStart, dateEnd, tipoNovedad) => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;

        if (dateStart === null && dateEnd === null && (tipoNovedad === null || tipoNovedad === undefined)) {
            getServices.getNovedad(idColaboradorConsulta).then(data => {
                if (data !== undefined) {
                    var newData = data.map(item => {
                        var newDateStart
                        var newDateEnd
                        if (gmtHours < 0) {
                            newDateStart = SumarDias(new Date(item.fecInicio), 1)
                            newDateEnd = SumarDias(new Date(item.fecFin), 1)
                        } else {
                            newDateStart = new Date(item.fecInicio)
                            newDateEnd = new Date(item.fecFin)
                        }
                        return {
                            colaborador: item.colaborador,
                            descripcion: item.descripcion,
                            estado: item.estado,
                            fecCreacion: item.fecCreacion,
                            fecFin: EstandarFormatoFecha(newDateEnd),
                            sendDateEnd: item.fecFin,
                            fecInicio: EstandarFormatoFecha(newDateStart),
                            sendDateStart: item.fecInicio,
                            id: item.id,
                            isTotal: item.isTotal,
                            tipoNovedad: item.tipoNovedad,
                            usuarioCreacion: item.usuarioCreacion
                        }
                    })
                }

                setServicioNovedad(newData)
            })
            setFechaFinError(false)
            setMensajeFechaFinError('')
            setFechaInicioError(false)
            setMensajeFechaInicioError('')
        } else if (dateStart !== null && dateEnd === null) {
            setFechaFinError(true)
            setMensajeFechaFinError('Seleccione una fecha fin para completar el filtro')
            setFechaInicioError(false)
            setMensajeFechaInicioError('')

        } else if (dateStart === null && dateEnd !== null) {
            setFechaInicioError(true)
            setMensajeFechaInicioError('Seleccione una fecha Inicio para completar el filtro')

        } else if (dateStart === null && dateEnd === null && (tipoNovedad !== null && tipoNovedad !== undefined)) {
            const result = servicioNovedadGuardarFiltro.filter(function (objeto) {
                return objeto.tipoNovedad.descripcion === tipoNovedad
            })
            setServicioNovedad(result)
        } else if (dateStart !== null && dateEnd !== null && (tipoNovedad === null || tipoNovedad === undefined)) {
            if (dateStart > dateEnd) {
                setFechaInicioError(true)
                setMensajeFechaInicioError('La fecha inicio no puede ser mayor a la fecha fin')
                setFechaFinError(false)
                setMensajeFechaFinError('')
            } else {
                const result = servicioNovedadGuardarFiltro.filter(function (objeto) {
                    return dateStart <= objeto.sendDateEnd && objeto.sendDateStart <= dateEnd
                    /* return objeto.fecInicio >= dateStart && objeto.fecFin <= dateEnd */
                })
                setServicioNovedad(result)
                setFechaFinError(false)
                setMensajeFechaFinError('')
                setFechaInicioError(false)
                setMensajeFechaInicioError('')
            }
        } else if (dateStart !== null && dateEnd !== null && (tipoNovedad !== null || tipoNovedad !== undefined)) {
            if (dateStart > dateEnd) {
                setFechaInicioError(true)
                setMensajeFechaInicioError('La fecha inicio no puede ser mayor a la fecha fin')
            } else {
                const result = servicioNovedadGuardarFiltro.filter(function (objeto) {
                    return dateStart <= objeto.sendDateEnd && objeto.sendDateStart <= dateEnd
                })
                const newResult = result.filter(function (objeto) {
                    return objeto.tipoNovedad.descripcion === tipoNovedad
                })
                setServicioNovedad(newResult)
            }
        }

    }

    const [mesCerradoSeleccionado, setMesCerradoSeleccionado] = useState(0)
    const [mensajeMesSeleccionado, setMensajeMesSeleccionado] = useState("")

    const renderHeader = () => {

        //Validación eliminación múltiple
        var mensajeSeleccion = ""

        var validarActividadSeleccionada = false

        if (sendIdsActivities.length === 0) {
            mensajeSeleccion = "Debe seleccionar al menos una Novedad"
            validarActividadSeleccionada = false
        }
        else {
            mensajeSeleccion = "Está seguro que desea eliminar las Novedades seleccionadas"
            validarActividadSeleccionada = true
        }
        
        return (
            <>
                <div className="">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="TipoDocumento">Tipo de Documento</label>
                            <InputText id="tipoDocumento" type="text" name="tipoDocumento" value={tipodocumento} disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="numDocumento">Número documento</label>
                            <InputText id="numDocumento" type="text" name="numDocumento" value={documento} disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="Nombre">Nombre Completo</label>
                            <InputText id="Nombre" type="text" name="Nombre" value={nombre} disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="Cargo">Cargo</label>
                            <InputText id="Cargo" type="text" name="Cargo" value={cargo} disabled />
                        </div>
                        {
                            permisoNovedadOtroFucionario
                                ?
                                <div className="p-field p-col-12 p-md-6 p-lg-12" >
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="selectUser" name="selectUser" value="selectUser" onChange={onSelectUserChange} checked={selectUser === 'selectUser'} />
                                        <label htmlFor="selectUser" style={{ fontWeight: '100' }}>Gestionar novedad propia</label>
                                    </div>
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="selectOtherUser" name="selectOtherUser" value="selectOtherUser" onChange={onSelectUserChange} checked={selectUser === 'selectOtherUser'} />
                                        <label htmlFor="selectOtherUser" style={{ fontWeight: '100' }}>Gestionar novedad otro funcionario</label>
                                    </div>
                                </div>
                                :
                                ""
                        }
                        {
                            selectUser === 'selectOtherUser'
                                ?
                                <div className="p-field p-col-12 p-md-3">
                                    <label className="labels">Usuarios<span className="require">*</span></label>
                                    <Dropdown value={selectedUsuario} options={usuarios} onChange={onUsuarioChange} name="nombrecompleto" optionLabel="usuario" placeholder="Seleccione un usuario"
                                        className="listas" filter showClear filterBy="usuario" emptyFilterMessage="No se registran datos" emptyMessage="No se registran datos"
                                    />
                                </div>
                                :
                                <>
                                </>
                        }
                    </div>
                    {loading && <p>Cargando información...</p>}
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="Nombre">Fecha Inicio</label>
                            <div className="">
                                <Calendar placeholder="Seleccione " value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es" showButtonBar
                                    className={fechaInicioError ? "p-invalid fechaFlilter" : "fechaFlilter"}
                                />
                            </div>
                            <p className="mensajeError">{fechaInicioError ? mensajeFechaInicioError : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="Nombre">Fecha Fin</label>
                            <div className="">
                                <Calendar placeholder="Seleccione " value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" showButtonBar
                                    className={fechaFinError ? "p-invalid fechaFlilter" : "fechaFlilter"}
                                />
                            </div>
                            <p className="mensajeError">{fechaFinError ? mensajeFechaFinError : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" htmlFor="Nombre">Tipo de Novedad</label>
                            <div className="">
                                <Dropdown value={tipoActividad} options={tipoActividades} onChange={onTipoActividadChange} placeholder="Seleccione " showClear
                                    className="p-column-filter"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="delete">
                    <div className="">
                        {permisoActivityCreate ?
                            selectUser === 'selectUser'
                                ?
                                <BtnCrearNovedad />
                                :
                                <BtnCrearNovedadFucionario />
                            :
                            ""
                        }
                    </div>
                    {loading && <p>Cargando información...</p>}
                    <div>
                        {permisoActivityDelete ?
                            <BtnEliminar
                                icono="pi pi-trash"
                                clase="p-button-danger "
                                titulo="Eliminar Novedades"
                                accion="EliminarNovedad"
                                label="Eliminar Novedades"
                                mensaje={mesCerradoSeleccionado !== 0 ? mensajeMesSeleccionado : mensajeSeleccion}
                                actividadSeleccionadaProp={validarActividadSeleccionada}
                                indicadorProp="2"
                                tooltipProp="Eliminar"
                                idActividadesProp={sendIdsActivities}
                                advertenciaMesCerradoProp={mesCerradoSeleccionado}

                            />
                            :
                            ""
                        }
                    </div>
                </div>
            </>
        )
    }

    const header = renderHeader()

    // Obtener si el cierre esta activo o inactivo para el usuario en sesión

    const cierreActivo = cookies.get('cierreActivo')
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    mesesCerrados.forEach(element => {
        fechaCierre.push(element.fechaFinalCierre)
    })

    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))


    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))
    let month = fechaUltimoCierre.getMonth() + 1;
    let day = fechaUltimoCierre.getDate() + 1;

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }
    const fecha = fechaUltimoCierre.getFullYear() + '-' + month + '-' + day

    /* console.log(fecha) */

    const mesAbierto = cierres.filter(function (objeto) {
        var id = parseInt(cierreActivo)
        return objeto.id === id
    })
    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    cierres.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })

    /* console.log(cierres)
    console.log(fecha) */

    const validacionMesCerrado = (rowData) => {
        let disabled = false

        if (cierres.length !== 0) {
            if (cierres) {
                if (mesAbierto.length !== 0) {
                    if (rowData.sendDateStart >= getMesAbierto.fechaInicialCierre && rowData.sendDateStart <= getMesAbierto.fechaFinalCierre) {
                        disabled = false
                    } else {
                        if (rowData.sendDateStart < fecha) {
                            disabled = true
                        } else {
                            disabled = false
                        }
                    }
                } else if (rowData.sendDateStart < fecha) {
                    disabled = true
                } else {
                    disabled = false
                }
            }
        }
        return disabled
    }

    const Acciones = (rowData) => {
        var disabled = false
        if (rol === "Super Admin") {
            disabled = validacionMesCerrado(rowData)
        } else {
            if (permisoNovedadOtroFucionario === true) {
                disabled = validacionMesCerrado(rowData)
            } else if (rowData.usuarioCreacion === usuario) {
                disabled = validacionMesCerrado(rowData)
            } else {
                disabled = true
            }
        }
        return (

            <div className="Botones">
                {permisoActivityUpdate ?
                    <BtnActualizarNovedad
                        idProp={rowData.id}
                        fechaInicioProp={rowData.sendDateStart}
                        fechaFinProp={rowData.sendDateEnd}
                        idUsuarioProp={rowData.colaborador.idusuario.id}
                        idColaboradorProp={rowData.colaborador.id}
                        numberDocProp={rowData.colaborador.numerodocumento}
                        usuarioProp={rowData.colaborador.idusuario.usuario}
                        idTipoNovedadProp={rowData.tipoNovedad.id}
                        tipoNovedadProp={rowData.tipoNovedad.descripcion}
                        marcaParcialProp={rowData.tipoNovedad.marcaParcial}
                        isTraslapoProp={rowData.tipoNovedad.isTraslapo}
                        descripcionProp={rowData.descripcion}
                        fecCreacionProp={rowData.fecCreacion}
                        usuarioCreacionProp={rowData.usuarioCreacion}
                        isTotalProp={rowData.isTotal}
                        disabledProp={disabled}
                        areaNegocioProp={rowData.areanegocio}
                        aplicacionProp={rowData.aplicacion}
                        isVacacionesProp={rowData.tipoNovedad.isVacaciones}
                        hourNumberProp={rowData.numerohoras}
                        extenderVacacionesProp={rowData.novedadExtendida}
                        historicoUserSelectedProp={selectedUsuario?.historicohoras || []}
                    />
                    :
                    ""
                }
                <BtnDetalleNOvedad
                    idProp={rowData.id}
                />

            </div>
        );
    }


    var permisosAcciones
    if (permisoActivityCreate === false && permisoActivityUpdate === false && permisoActivityDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    const showSelectionElement = (rowData) => {
        return true
    }

    const onRowSelect = (e) => {

        var disabled = false
        var mensajeMesCerrado = false

        // console.log(e)
        if (rol === "Super Admin") {
            disabled = validacionMesCerrado(e.data)
            mensajeMesCerrado = true
        } else {
            if (permisoNovedadOtroFucionario === true) {
                disabled = validacionMesCerrado(e.data)
                mensajeMesCerrado = true
            } else if (e.data.usuarioCreacion === usuario) {
                disabled = validacionMesCerrado(e.data)
                mensajeMesCerrado = true
            } else {
                disabled = true
                mensajeMesCerrado = false
            }
        }
        if (disabled === true && mensajeMesCerrado === true) {
            setMesCerradoSeleccionado(mesCerradoSeleccionado + 1)
            setMensajeMesSeleccionado("Debe solicitar el permiso al administrador para eliminar la novedad en un mes cerrado")
        } else if (disabled === true && mensajeMesCerrado === false) {
            setMesCerradoSeleccionado(mesCerradoSeleccionado + 1)
            setMensajeMesSeleccionado("No puede eliminar una novedad registrada por un administrador")
        }
    }

    const onRowUnSelect = (e) => {
        var disabled = false
        // console.log(e)
        if (rol === "Super Admin") {
            disabled = validacionMesCerrado(e.data)
        } else {
            if (permisoNovedadOtroFucionario === true) {
                disabled = validacionMesCerrado(e.data)
            } else if (e.data.usuarioCreacion === usuario) {
                disabled = validacionMesCerrado(e.data)
            } else {
                disabled = true
            }
        }

        if (disabled === true) {
            setMesCerradoSeleccionado(mesCerradoSeleccionado - 1)
        }
    }


    let small = UseMedia("(max-width: 760px)");

    return (
        <div className="datatable-responsive-demo">
            <div className="card">

                <DataTable ref={dt} value={servicioNovedad} className="p-datatable-responsive-demo" paginator rows={10} header={header}
                    showSelectionElement={showSelectionElement} onRowSelect={onRowSelect} onRowUnselect={onRowUnSelect} selectionMode="checkbox"
                    emptyMessage="No hay datos en el sistema" responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px" loading={loading}
                    sortField="fecha" sortOrder={-1} selection={selectedActivities} onSelectionChange={e => onChangeSelectActivities(e)}>
                    <Column selectionMode="multiple" style={{ maxWidth: '4rem' }} ></Column>
                    <Column className="columna" field="sendDateStart" sortable header="Fecha Inicio" /* filter filterElement={FechaInicioFilter} filterFunction={filterDate} */ />
                    <Column className="columna" field="sendDateEnd" sortable header="Fecha Fin" /* filter filterElement={FechaFinFilter} filterFunction={filterDate} */ />
                    <Column className="columna" field="usuarioCreacion" sortable header="Creado Por" />
                    <Column className="columna" field="tipoNovedad.descripcion" sortable header="Tipo de Novedad" /* filter filterElement={TipoActividadFilter} */ />
                    <Column className="columna" field="descripcion" sortable header="Descripción" />
                    <Column className="columna" field="areanegocio.nombreareanegocio" sortable header="Area Negocio" />
                    <Column className="columna" field="aplicacion.nombreaplicacion" sortable header="Sistema Aplicación" />
                    {permisosAcciones ?
                        <Column header="Acciones" body={Acciones} />
                        :
                        <Column />
                    }
                </DataTable>
            </div>
        </div>
    );
}

export default TableNovedades