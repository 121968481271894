import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Services from "../../service/Services";
import BtnCrearServicio from "./BtnCrearServicios";
import BtnActualizarServicio from "./BtnActualizarServicios";
import ConfirmBtn from "./ConfirmBtn";
import Cookies from 'universal-cookie'
import axios from "axios";
const cookies = new Cookies()

const ConsultarServicios = ({ permissionsProp, fullTableProp, individualMonthReportProp, clientsProp, monthlyReportProp, setSelectedServiceProp, objectMonthlyReportDataProp,
    readReportProp, listReportsUsersProp, monthlyReportDataAllDataProp, setListServiceProp, loadingProp }) => {

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    const getServices = new Services();

    const [runRender, setRunRender] = useState(0)
    const [dataServices, setDataServices] = useState([])

    //Ejecutar servicios solo una vez
    useEffect(() => {
        const source = axios.CancelToken.source();
        getServices.getServices().then(data => {
            if (data !== undefined) {
                setDataServices(data)
            }
        })
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [runRender])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (individualMonthReportProp === true) {
            if (readReportProp === true) {
                if (listReportsUsersProp.length !== 0) {
                    var services = JSON.parse(listReportsUsersProp[0].servicios)
                    var listServices = []
                    services.forEach(element => {
                        const result = dataServices?.filter(function (object) {
                            var id = parseInt(object.id);
                            return id === element.id;
                        });
                        result.forEach((element) => {
                            listServices.push(element);
                        });
                    })
                    setServices(listServices)
                }
            } else {
                var newData = dataServices?.filter(function (object) {
                    var idClient = object.cliente.id
                    return idClient === clientsProp.id
                })
                if (loadingProp === false) {
                    setServices(newData)
                }
                setListServiceProp(newData)
            }

        } else {
            if (dataServices.length !== 0) {
                getServices.getProyectosUsuarios().then(res => {
                    var clients = res.map(item => {
                        return {
                            id: item.cliente.id,
                            name: item.cliente.nombre
                        }
                    })
                    var newClientsService = []
                    clients.forEach(element => {
                        const result = dataServices?.filter(function (object) {
                            var idClient = object.cliente.id
                            return idClient === parseInt(element.id)
                        })

                        result.forEach(element => {
                            newClientsService.push(element)
                        })
                    });
                    const clientsTable = {}
                    const uniqueClients = newClientsService.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })
                    setServices(uniqueClients)
                })
            }
        }
        setLoading(false)

    }, [runRender, clientsProp, readReportProp, listReportsUsersProp, loadingProp, dataServices]) // eslint-disable-line react-hooks/exhaustive-deps

    const dt = useRef(null);

    // obtener datos seleccionados de la tabla 

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const [selectedService, setSelectedService] = useState(objectMonthlyReportDataProp);

    const onSelectedServiceChange = (e) => {
        var sendData = e.value.map(item => {
            return {
                id: item.id,
                descripcion: item.descripcion
            }
        })
        setSelectedService(sendData)
        setSelectedServiceProp(sendData)
    }
    /* console.log(objectMonthlyReportDataProp.servicios) */
    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined) {
            setSelectedService(JSON.parse(objectMonthlyReportDataProp.servicios))
            setSelectedServiceProp(JSON.parse(objectMonthlyReportDataProp.servicios))
        } else {
            setSelectedService([])
            if (setSelectedServiceProp !== undefined) {
                setSelectedServiceProp([])
            }
        }
    }, [objectMonthlyReportDataProp, setSelectedServiceProp])

    var permissionsServiceCreate
    if (permissionsProp[20].permisos[0] === "Crear") {
        permissionsServiceCreate = true
    } else {
        permissionsServiceCreate = false
    }
    var permissionsServiceUpdate
    if (permissionsProp[20].permisos[1] === "Editar") {
        permissionsServiceUpdate = true
    } else {
        permissionsServiceUpdate = false
    }
    var permissionsServiceDelete
    if (permissionsProp[20].permisos[2] === "Eliminar") {
        permissionsServiceDelete = true
    } else {
        permissionsServiceDelete = false
    }

    const header = (
        <div className="headerTable">
            {permissionsServiceCreate ?
                individualMonthReportProp ?
                    readReportProp ?
                        ""
                        :
                        clientsProp !== undefined ? clientsProp.length !== 0 && clientsProp.id !== '' &&
                            <BtnCrearServicio
                                serviceProp={services}
                                clientsProp={clientsProp}
                                individualMonthReportProp={individualMonthReportProp}
                                setRunRenderProp={setRunRender}
                                runRenderProp={runRender}
                            />
                            :
                            ''
                    :
                    <BtnCrearServicio
                        serviceProp={services}
                        clientsProp={clientsProp}
                        individualMonthReportProp={individualMonthReportProp}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                    />

                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    const usuario = cookies.get('usuario');

    const acctions = (rowData) => {
        var uniqueFilterService = []
        if (monthlyReportDataAllDataProp) {
            var monthlyReportDataAllDataPropService = monthlyReportDataAllDataProp.map(item => {
                return {
                    servicios: JSON.parse(item.servicios)
                }
            })
            var filterService = []
            const result = monthlyReportDataAllDataPropService.map(item => {
                return item.servicios.map(j => {
                    var boolean
                    if (j.id === rowData.id) {
                        boolean = item
                    }
                    return boolean
                })

            })
            result.forEach(i => {
                i.forEach(j => {
                    if (j !== undefined) {
                        if (j.length !== 0) {
                            filterService.push(j)
                        }
                    }
                })
            })
            uniqueFilterService = [...new Set(filterService)]
        }


        var noDeleteServicie = false
        var messagDelete = '¿Está seguro de eliminar este servicio?, Recuerde que una vez se elimine, se borrará definitivamente del sistema'
        if (rowData.creadopor !== usuario) {
            noDeleteServicie = true
            messagDelete = 'No puede eliminar registros creados por otro usuario'
        } else if (uniqueFilterService.length !== 0) {
            noDeleteServicie = true
            messagDelete = 'No puede eliminar este servicio por que ya ha sido usado por otro usuario'
        }

        return (
            <>
                {permissionsServiceUpdate ?
                    readReportProp ?
                        ""
                        :
                        <BtnActualizarServicio
                            idServiceProp={rowData.id}
                            servicesProp={services}
                            serviceProp={rowData.descripcion}
                            clientProp={rowData.cliente}
                            creationDateProp={rowData.fechacreacion}
                            createdByProp={rowData.creadopor}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            clientsProp={clientsProp}
                            individualMonthReportProp={individualMonthReportProp}
                        />
                    :
                    ""
                }
                {permissionsServiceDelete ?
                    readReportProp ?
                        ""
                        :
                        <ConfirmBtn
                            tittle="Confirmar Eliminación"
                            menssage={messagDelete}
                            action="Delete"
                            label=""
                            classProp="p-button-danger"
                            icon="pi pi-trash"
                            tooltipProp="Eliminar"
                            statusProp={3}
                            idServiceProp={rowData.id}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            noDeleteServicieProp={noDeleteServicie}
                        />
                    :
                    ""
                }
            </>
        )
    }

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={services} dataKey="id" className="p-datatable-responsive-demo" header={header} globalFilter={globalFilter}
                    emptyMessage="servicios no registrado en el sistema" paginator rows={10} sortField="descripcion" sortOrder={1} loading={individualMonthReportProp ? loadingProp : loading}
                    selection={readReportProp ? null : selectedService} onSelectionChange={readReportProp ? null : e => onSelectedServiceChange(e)} selectionMode={individualMonthReportProp === true ? "checkbox" : null}
                >
                    {individualMonthReportProp === true ?
                        readReportProp ?
                            null
                            :
                            <Column selectionMode="multiple" style={{ maxWidth: '1rem' }} ></Column>
                        :
                        <></>
                    }
                    <Column className="columna" field="cliente.razonsocial" sortable header="Cliente" />
                    <Column className="columna" field="descripcion" sortable header="Servicio" />
                    <Column className="columna" field="creadopor" sortable header="Creado por" />
                    {readReportProp ?
                        null
                        :
                        <Column header="Acciones" body={acctions} />
                    }
                </DataTable>
            </div>
        </div>
    )
}

export default ConsultarServicios