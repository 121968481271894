import React from 'react'
import SidebarMenu from '../../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import DetalleTipoActividades from '../../componets/Home/Indicadores/DetalleTipoActividad/DetalleTipoActividades'
import '../../assets/css/Sidebar.css'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const DetalleTipoActividad = () => {

    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    const consultar = (
        <span className="disabled">Detalles de tipos de actividad</span>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

   const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[13].permisos[1] === "") {
        window.location.href = "/registroActividades/inicio"
    }

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">

                    <h1 className="tittle">Detalles de tipos de actividad</h1>


                </div>
                <div className="tabla">
                    <DetalleTipoActividades />
                </div>

            </div>
        </div>
    )
}

export default DetalleTipoActividad