import React, { useState, useEffect } from 'react'
import SidebarMenu from '../../componets/Home/MenuSidebar/sidebarMenu'
import BreadCrumb from '../../componets/Home/BreadCrumb'
import { Link } from 'react-router-dom'
import CargaTrabajoProyectos from '../../componets/Home/Indicadores/CargaTrabajoProyectos/CargaTrabajoProyectos'
import Services from '../../service/Services';
import '../../assets/css/Sidebar.css'
import '../../assets/css/Charts.css'
import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CargaTrabajoProyecto = () => {
    const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
    const [servicioUltimoPeriodo, setServicioUltimoPeriodo] = useState([]);

    const getServices = new Services();

    useEffect(() => {

        getServices.getUltimoPeriodo().then(data => {
            if (data !== undefined) {
                setServicioUltimoPeriodo(data)
            }
        })

        // Función de limpieza para cancelar la solicitud cuando el componente se desmonta o cambia de pestaña
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        };

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const home = (
        <Link className="breadcrubsLink" to="/registroActividades/inicio">Inicio</Link>
    )

    const consultar = (
        <span className="disabled">Sobrecarga de trabajo y proyectos</span>
    )

    if (!cookies.get('code')) {
        window.location.href = "/singOff"
    }

    const menu = JSON.parse(sessionStorage.getItem("permissions"))
    const permisos = menu.rol

    if (permisos[13].permisos[0] === "") {
        window.location.href = "/registroActividades/inicio"
    }

    return (
        <div>
            <SidebarMenu
                accesoProp={permisos}
                noShowProp={true}
            />
            <div className="content">
                <BreadCrumb
                    home={home}
                    consultar={consultar}

                />
                <div className="container">

                    <h1 className="tittle">Sobrecarga de trabajo y proyectos</h1>


                </div>
                <div className="tabla">
                    {


                        servicioUltimoPeriodo?.length !== 0
                            ?
                            <CargaTrabajoProyectos
                                idTrimestreProp={servicioUltimoPeriodo?.id}
                                detalleTrimestreProp={servicioUltimoPeriodo?.detalle}
                            />
                            :
                            ""

                    }

                </div>

            </div>
        </div>

    )


}

export default CargaTrabajoProyecto