import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const BtnCrearCargo = ({ cargosProp }) => {
    
    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input cargo

    const [inputCargo, setInputCargo] = useState({
        cargo: ''
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onCargoChange = (event) => {

        setInputCargo({
            ...inputCargo,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Almacenamos los valores para enviarlos por props

    const cargo = inputCargo.cargo
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {


            setInputCargo({
                cargo: ''
            })

            setValidarCambio(false)


            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registrar este cargo?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            cargoProp={cargo}
                            usuarioProp={usuario}
                        />
                        :
                        ""
                }

            </div>
        );
    }

    //validaciones

    var cargoError = false
    var mensajeCargo = ''
    if (cargo.length < 3 && cargo !== '') {
        cargoError = true
        mensajeCargo = 'El nombre del cargo debe ser mayor a 3 dígitos'
    } else if (cargo.length > 100) {
        cargoError = true
        mensajeCargo = 'El nombre del cargo debe ser menor a 100 dígitos'
    } 

    var validateErrorDuplicity = false
    function validateDuplicity() {
        cargosProp.forEach(element => {
            const nameCargo = removeAccents(element.cargo)
            const newNameCargo = removeAccents(cargo)

            if (nameCargo.toUpperCase() === newNameCargo.toUpperCase()) {
                validateErrorDuplicity = true
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Cargo pre-existente' });
            }
        })
    }
    if (cargo !== '') {
        validateDuplicity()
    }
    var clienteError = false
    var mensajeCliente = ''

    var permisoRegistrar = false
    if (cargoError === false && cargo !== '' && clienteError === false && validateErrorDuplicity === false) {
        permisoRegistrar = true
    }

    return (

        <div className="">
            <div>
                <Toast ref={toast} />
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar cargo</button>

                <Dialog className="modal" header="Registrar Cargo" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >

                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Cargo<span className="require">*</span></label>
                                <InputText id="cargo" type="text" name="cargo" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onCargoChange}
                                    placeholder="Registre el cargo"
                                    className={cargoError || validateErrorDuplicity ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{clienteError || cargoError ? mensajeCargo || mensajeCliente : ""}</p>
                            </div>
                        </form>

                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearCargo