import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom'
import UpdateDates from './UpdateDates'
import Services from '../../../service/Services';
import ConfirmBtn from './confirmBtn'
import Cookies from 'universal-cookie';
import { GetDates, SumarDias } from '../../Funciones';

const cookies = new Cookies();


const Parametrizar = () => {

    const [servicio, setServicio] = useState([]);
    const [cierres, setCierres] = useState([]);

    const getServices = new Services();

    useEffect(() => {


        getServices.getCalendarios().then(data => setServicio(data));
        getServices.getCierres().then(data => setCierres(data));


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //// selección de país 
    const [country, setSelectedCountry] = useState({
        pais: ''
    });
    /* const countries = [
        { pais: 'Colombia' },
        { pais: 'España' },
        { pais: 'Peru' },
        { pais: 'Mexico' },
        { pais: 'Ecuador' },
        { pais: 'Estados Unidos' },
    ]; */
    const countries = [
        {
            pais: "Afganistán"
        },
        {
            pais: "Albania"
        },
        {
            pais: "Alemania"
        },
        {
            pais: "Algeria"
        },
        {

            pais: "Andorra"
        },
        {

            pais: "Angola"
        },
        {
            pais: "Anguilla"
        },
        {

            pais: "Antigua y Barbuda"
        },
        {

            pais: "Antillas Holandesas"
        },
        {
            pais: "Arabia Saudita"
        },
        {
            pais: "Argentina"
        },
        {
            pais: "Armenia"
        },
        {

            pais: "Aruba"
        },
        {
            pais: "Australia"
        },
        {
            pais: "Austria"
        },
        {
            pais: "Azerbaiyán"
        },
        {
            pais: "Bahamas"
        },
        {

            pais: "Bahrein"
        },
        {

            pais: "Bangladesh"
        },
        {

            pais: "Barbados"
        },
        {
            pais: "Bélgica"
        },
        {
            pais: "Belice"
        },
        {

            pais: "Benín"
        },
        {
            pais: "Bermudas"
        },
        {
            pais: "Bielorrusia"
        },
        {

            pais: "Bolivia"
        },
        {
            pais: "Bosnia y Herzegovina"
        },
        {

            pais: "Botsuana"
        },
        {
            pais: "Brasil"
        },
        {

            pais: "Brunéi"
        },
        {
            pais: "Bulgaria"
        },
        {

            pais: "Burkina Faso"
        },
        {

            pais: "Burundi"
        },
        {

            pais: "Bután"
        },
        {

            pais: "Cabo Verde"
        },
        {

            pais: "Camboya"
        },
        {
            pais: "Camerún"
        },
        {
            pais: "Canadá"
        },
        {

            pais: "Chad"
        },
        {
            pais: "Chile"
        },
        {
            pais: "China"
        },
        {
            pais: "Chipre"
        },
        {
            pais: "Colombia"
        },
        {

            pais: "Comores"
        },
        {

            pais: "Congo (Brazzaville)"
        },
        {

            pais: "Congo (Kinshasa)"
        },
        {

            pais: "Cook, Islas"
        },
        {
            pais: "Corea del Norte"
        },
        {
            pais: "Corea del Sur"
        },
        {

            pais: "Costa de Marfil"
        },
        {
            pais: "Costa Rica"
        },
        {
            pais: "Croacia"
        },
        {

            pais: "Cuba"
        },
        {
            pais: "Dinamarca"
        },
        {

            pais: "Djibouti, Yibuti"
        },
        {

            pais: "Ecuador"
        },
        {
            pais: "Egipto"
        },
        {
            pais: "El Salvador"
        },
        {
            pais: "Emiratos Árabes Unidos"
        },
        {

            pais: "Eritrea"
        },
        {
            pais: "Eslovaquia"
        },
        {
            pais: "Eslovenia"
        },
        {
            pais: "España"
        },
        {
            pais: "Estados Unidos"
        },
        {
            pais: "Estonia"
        },
        {

            pais: "Etiopía"
        },
        {

            pais: "Feroe, Islas"
        },
        {
            pais: "Filipinas"
        },
        {
            pais: "Finlandia"
        },
        {

            pais: "Fiyi"
        },
        {
            pais: "Francia"
        },
        {

            pais: "Gabón"
        },
        {

            pais: "Gambia"
        },
        {
            pais: "Georgia"
        },
        {

            pais: "Ghana"
        },
        {

            pais: "Gibraltar"
        },
        {

            pais: "Granada"
        },
        {
            pais: "Grecia"
        },
        {
            pais: "Groenlandia"
        },
        {
            pais: "Guadalupe"
        },
        {

            pais: "Guatemala"
        },
        {

            pais: "Guernsey"
        },
        {

            pais: "Guinea"
        },
        {

            pais: "Guinea Ecuatorial"
        },
        {

            pais: "Guinea-Bissau"
        },
        {

            pais: "Guyana"
        },
        {
            pais: "Haiti"
        },
        {

            pais: "Honduras"
        },
        {
            pais: "Hong Kong"
        },
        {
            pais: "Hungría"
        },
        {
            pais: "India"
        },
        {
            pais: "Indonesia"
        },
        {

            pais: "Irak"
        },
        {
            pais: "Irán"
        },
        {
            pais: "Irlanda"
        },
        {

            pais: "Isla Pitcairn"
        },
        {
            pais: "Islandia"
        },
        {

            pais: "Islas Salomón"
        },
        {
            pais: "Islas Turcas y Caicos"
        },
        {

            pais: "Islas Virgenes Británicas"
        },
        {
            pais: "Israel"
        },
        {
            pais: "Italia"
        },
        {

            pais: "Jamaica"
        },
        {
            pais: "Japón"
        },
        {

            pais: "Jersey"
        },
        {
            pais: "Jordania"
        },
        {
            pais: "Kazajstán"
        },
        {
            pais: "Kenia"
        },
        {
            pais: "Kirguistán"
        },
        {

            pais: "Kiribati"
        },
        {
            pais: "Kuwait"
        },
        {

            pais: "Laos"
        },
        {

            pais: "Lesotho"
        },
        {
            pais: "Letonia"
        },
        {
            pais: "Líbano"
        },
        {

            pais: "Liberia"
        },
        {
            pais: "Libia"
        },
        {

            pais: "Liechtenstein"
        },
        {
            pais: "Lituania"
        },
        {
            pais: "Luxemburgo"
        },
        {
            pais: "Macedonia"
        },
        {

            pais: "Madagascar"
        },
        {
            pais: "Malasia"
        },
        {

            pais: "Malawi"
        },
        {

            pais: "Maldivas"
        },
        {

            pais: "Malí"
        },
        {
            pais: "Malta"
        },
        {

            pais: "Man, Isla de"
        },
        {

            pais: "Marruecos"
        },
        {

            pais: "Martinica"
        },
        {

            pais: "Mauricio"
        },
        {

            pais: "Mauritania"
        },
        {
            pais: "México"
        },
        {
            pais: "Moldavia"
        },
        {
            pais: "Mónaco"
        },
        {

            pais: "Mongolia"
        },
        {

            pais: "Mozambique"
        },
        {

            pais: "Myanmar"
        },
        {

            pais: "Namibia"
        },
        {

            pais: "Nauru"
        },
        {

            pais: "Nepal"
        },
        {

            pais: "Nicaragua"
        },
        {

            pais: "Níger"
        },
        {

            pais: "Nigeria"
        },
        {

            pais: "Norfolk Island"
        },
        {
            pais: "Noruega"
        },
        {

            pais: "Nueva Caledonia"
        },
        {
            pais: "Nueva Zelanda"
        },
        {

            pais: "Omán"
        },
        {
            pais: "Países Bajos, Holanda"
        },
        {
            pais: "Pakistán"
        },
        {

            pais: "Panamá"
        },
        {
            pais: "Papúa-Nueva Guinea"
        },
        {

            pais: "Paraguay"
        },
        {
            pais: "Perú"
        },
        {

            pais: "Polinesia Francesa"
        },
        {
            pais: "Polonia"
        },
        {
            pais: "Portugal"
        },
        {

            pais: "Puerto Rico"
        },
        {

            pais: "Qatar"
        },
        {
            pais: "Reino Unido"
        },
        {
            pais: "República Checa"
        },
        {

            pais: "República Dominicana"
        },
        {
            pais: "Reunión"
        },
        {

            pais: "Ruanda"
        },
        {
            pais: "Rumanía"
        },
        {
            pais: "Rusia"
        },
        {

            pais: "Sáhara Occidental"
        },
        {

            pais: "Samoa"
        },
        {

            pais: "San Cristobal y Nevis"
        },
        {

            pais: "San Marino"
        },
        {

            pais: "San Pedro y Miquelón"
        },
        {

            pais: "San Tomé y Príncipe"
        },
        {

            pais: "San Vincente y Granadinas"
        },
        {

            pais: "Santa Elena"
        },
        {

            pais: "Santa Lucía"
        },
        {

            pais: "Senegal"
        },
        {

            pais: "Serbia y Montenegro"
        },
        {

            pais: "Seychelles"
        },
        {

            pais: "Sierra Leona"
        },
        {
            pais: "Singapur"
        },
        {

            pais: "Siria"
        },
        {

            pais: "Somalia"
        },
        {

            pais: "Sri Lanka"
        },
        {

            pais: "Sudáfrica"
        },
        {

            pais: "Sudán"
        },
        {
            pais: "Suecia"
        },
        {
            pais: "Suiza"
        },
        {
            pais: "Surinam"
        },
        {

            pais: "Swazilandia"
        },
        {
            pais: "Tadjikistan"
        },
        {
            pais: "Tailandia"
        },
        {
            pais: "Taiwan"
        },
        {

            pais: "Tanzania"
        },
        {

            pais: "Timor Oriental"
        },
        {

            pais: "Togo"
        },
        {

            pais: "Tokelau"
        },
        {

            pais: "Tonga"
        },
        {

            pais: "Trinidad y Tobago"
        },
        {

            pais: "Túnez"
        },
        {
            pais: "Turkmenistan"
        },
        {
            pais: "Turquía"
        },
        {

            pais: "Tuvalu"
        },
        {
            pais: "Ucrania"
        },
        {
            pais: "Uganda"
        },
        {

            pais: "Uruguay"
        },
        {
            pais: "Uzbekistán"
        },
        {

            pais: "Vanuatu"
        },
        {
            pais: "Venezuela"
        },
        {
            pais: "Vietnam"
        },
        {

            pais: "Wallis y Futuna"
        },
        {

            pais: "Yemen"
        },
        {

            pais: "Zambia"
        },
        {
            pais: "Zimbabwe"
        }

    ];

    const onCountryChange = (e) => {
        setSelectedCountry(e.value);
    }
    ///// Calendario

    //Almacenamos los valores para enviarlos por props

    const countryValue = country.pais

    const usuario = cookies.get('usuario')


    const [dates, setDates] = useState(null);


    var arrayFechasConvertidas = []

    var arrayFechas

    var idRegistro

    var fechaCreacion

    var creadoPor

    var showUpdateDates = false

    if (servicio !== undefined) {
        const result = servicio.filter(function (objeto) {
            var pais = objeto.pais
            return pais === countryValue
        })

        if (result.length > 0) {
            showUpdateDates = true
        }

        result.forEach(element => {
            var arr = element.fechas.split(',');
            idRegistro = element.id
            fechaCreacion = element.fechacreacion
            creadoPor = element.creadopor
            arrayFechas = arr
        })
    }

    /* console.log(servicio) 
    console.log(usuario) */

    if (arrayFechas !== undefined) {
        arrayFechas.forEach(element => {
            var fechaConvertida = new Date(element)
            arrayFechasConvertidas.push(SumarDias(fechaConvertida, +0))
        })
    }


    const [date, setDate] = useState();

    const onDateChange = (e) => {
        setDate(e.value)
        var date = e.value
        var dates = []
        if (date !== null) {
            date.forEach(element => {
                let month = element.getMonth() + 1;
                let day = element.getDate();

                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }
                const fecha = month + '/' + day + '/' + element.getFullYear()
                dates.push(fecha)
            })
        }

        const sendDates = dates.toString()
        setDates(sendDates)
    }


    const Alert = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                </div>
            );
        }

        return (
            <>
                <Button label="Guardar" onClick={() => onClick('displayConfirmation')} className="p-button-outlined p-button-success"
                    tooltip="Guardar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                />
                <div className="dialog-demo">
                    <div className="card">
                        <Dialog className="modalConfirm" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>Debes selecionar un país y al menos una fecha</span>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </>
        )
    }

    var viewCalendar = false

    if (countryValue !== "") {
        viewCalendar = true
    } else {
        viewCalendar = false
    }
    var showAlert = false
    if (dates === null || countryValue === "") {
        showAlert = true
    } else if (dates.length === 0) {
        showAlert = true
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    // validar Mes cerrado
    const cierreActivo = cookies.get('cierreActivo')
    const mesesCerrados = cierres.filter(function (objeto) {
        return objeto.estado === 'C'
    })
    var fechaCierre = []

    mesesCerrados.forEach(element => {
        fechaCierre.push(element.fechaFinalCierre)
    })

    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))

    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))

    const mesAbierto = cierres.filter(function (objeto) {
        var id = parseInt(cierreActivo)
        return objeto.id === id
    })
    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre)
    var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2)

    var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre)
    var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1)

    /* console.log(getMesAbierto.fechaInicialCierre)
    console.log(getMesAbierto.fechaFinalCierre) */

    var dateArray = GetDates(newFechaFinalCierre, (fechaUltimoCierre).addDays(2));

    cierres.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })

    var minFechaCierre
    var disabledDates

    if (mesAbierto.length !== 0) {
        minFechaCierre = newFechaInicialCierre
        disabledDates = dateArray
    } else {
        minFechaCierre = SumarDias(fechaUltimoCierre, +1)
        disabledDates = []
    }


    const BotonRegresar = () => {
        return (
            <div className="p-field">
                {viewCalendar
                    ?
                    <Button label="Regresar" className="p-button-outlined p-button-secondary regresar"
                        tooltip="Regresar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={() => setSelectedCountry({ pais: '' })}
                    />
                    :
                    <Link to="/registroActividades/inicio" className="regresar">
                        <Button label="Regresar" className="p-button-outlined p-button-secondary regresar"
                            tooltip="Regresar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                        />
                    </Link>
                }


            </div>
        )
    }


    return (

        <>
            <div className="p-fluid ">
                <div className=" p-md-12 " style={{ textAlign: 'left' }} >
                    <label className="labels">Selecciona un País<span className="require">*</span></label>
                    <Dropdown value={country} options={countries} onChange={onCountryChange} name="pais" filter optionLabel="pais" placeholder="Seleccione un país" style={{ marginTop: '5px' }} />

                </div>

            </div>
            <div>
                <div>
                    {showUpdateDates
                        ?
                        <UpdateDates
                            arrayFechasConvertidasProp={arrayFechasConvertidas}
                            countryValueProp={countryValue}
                            idRegistroProp={idRegistro}
                            titulo="Guardar Calendario"
                            BotonRegresarProp={BotonRegresar}
                            fechaCreacionProp={fechaCreacion}
                            creadoPorProp={creadoPor}
                        />
                        :
                        <>
                            <div className="p-fluid ">
                                {
                                    viewCalendar ?
                                        <div className="p-field  p-md-12 " style={{ textAlign: 'left' }} >
                                            <div className="p-fluid ">
                                                <div className="" style={{ textAlign: 'left' }} >
                                                    <Calendar value={date} onChange={onDateChange} monthNavigator yearNavigator yearRange="2010:2030" locale="es"
                                                        selectionMode="multiple" placeholder="Seleccione los días festivos" showButtonBar inline className="p-datepickerCalendar CalendarDataPiker"
                                                        minDate={minFechaCierre}  disabledDates={disabledDates}
                                                    /> 
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div className="p-formgroup-inline" style={{ marginLeft: '7px' }}>
                                <div style={{ marginLeft: '7px' }}>
                                    <div className="p-field">
                                        {
                                            showAlert ?
                                                <Alert />
                                                :
                                                <ConfirmBtn
                                                    accion="Crear"
                                                    label="Guardar"
                                                    titulo="Guardar Calendario"
                                                    paisProp={countryValue}
                                                    fechasProp={dates}
                                                    usuarioProp={usuario}
                                                />
                                        }
                                    </div>
                                </div>
                                <BotonRegresar />
                            </div>
                        </>

                    }


                </div>


            </div>


        </>
    )

}
export default Parametrizar