import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import BtnCrearLogro from "./BtnCrearLogro"
import BtnActualizarLogro from "./BtnActualizarLogro";
import ConfirmBtn from "./ConfirmBtn"
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { UseMedia } from "../Funciones";
import Cookies from 'universal-cookie'
import VerAsociaciones from "./VerAsociaciones";
const cookies = new Cookies()

const ConsultarLogros = ({ idProjectsProp, projectProp, dateStartValidity, dateEndValidity, readReportProp, listReportsUsersProp,
    setSelectedAchievementProp, selectedAchievementProp, individualMonthReportProp, reloadObjectiveProp, objectMonthlyReportDataProp, achievementServiceProp,
    setRunRender, runRender, objectivesProp, projectsUserSessionProp, showAllDataProp, disabledIndexProp, setDisabledIndexProp, loadingProp,
    monthlyReportDataAllDataProp, selectedObjectiveProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp }) => {

    // columnas para el datatable responsive

    const dt = useRef(null);

    const [selectedAchievement, setSelectedAchievement] = useState(null);
    const [loading, setLoading] = useState(true);

    if (achievementServiceProp !== undefined && selectedObjectiveProp !== undefined && loadingProp === false) {
        var newSelectedObjectiveProp = selectedObjectiveProp.filter(obj => obj.proyecto === idProjectsProp)
        var filterObjectives = []
        newSelectedObjectiveProp.forEach(element => {
            const result = achievementServiceProp.filter(obj => obj.objetivo.id === element.id)
            result.forEach(x => {
                filterObjectives.push(x)
            })
        })

        var uniqueFilterObjectives = [...new Set(filterObjectives)]
        const result = uniqueFilterObjectives.filter(function (object) {
            var id = object.proyecto.id
            return id === parseInt(idProjectsProp)
        })
        var listAchievement = []
        if (readReportProp === true) {
            if (listReportsUsersProp.length !== 0) {
                var achievement = JSON.parse(listReportsUsersProp[0].logros)
                achievement.forEach(element => {
                    var filterProject = achievementServiceProp.filter(obj => obj.proyecto.id === parseInt(idProjectsProp));
                    const resultAchievement = filterProject.filter(function (object) {
                        var id = parseInt(object.id);
                        return id === element.id;
                    });
                    resultAchievement.forEach((element) => {
                        listAchievement.push(element);
                    });
                })
            }
        }
        var newResult = result.map(item => {
            return {
                actualizadopor: item.actualizadopor,
                creadopor: item.creadopor,
                detallelogro: item.detallelogro,
                estado: item.estado,
                fechaactualizado: item.fechaactualizado,
                fechacreacion: item.fechacreacion,
                fechafinalizacion: item.fechafinalizacion,
                id: item.id,
                logro: item.logro,
                objetivo: item.objetivo,
                proyecto: item.proyecto.id,
                sendDate: item.sendDate,
                proyectos: item.proyecto,
                cumplimientoalcorte: item.cumplimientoalcorte
            }
        })
    }

    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined) {
            setSelectedAchievement(JSON.parse(objectMonthlyReportDataProp.logros))
            setSelectedAchievementProp(JSON.parse(objectMonthlyReportDataProp.logros))
            setLoading(false)
        } else {
            setSelectedAchievement([])
            setSelectedAchievementProp([])
            setLoading(false)
        }
    }, [objectMonthlyReportDataProp, setSelectedAchievementProp])
    const onRowSelect = (e) => {
        var objective = objectivesProp.filter(function (object) {
            var id = object.id
            return id === e.data.objetivo.id
        })
        var filterObjective = objective[0].proyectos.filter(function (object) {
            var id = object.proyecto.id
            return parseInt(id) === parseInt(idProjectsProp)
        })
        var newObject = {
            id: e.data.id,
            logro: e.data.logro,
            detallelogro: e.data.detallelogro,
            objetivo: {
                descripcion: e.data.objetivo.descripcion,
                porcentajecumplimiento: filterObjective[0].porcentajecumplimiento
            },
            fechafinalizacion: e.data.fechafinalizacion,
            proyecto: e.data.proyecto,
            cumplimientoalcorte: e.data.cumplimientoalcorte
        }
        /* console.log(newObject) */
        setSelectedAchievementProp([...selectedAchievementProp, newObject])
    }

    const onRowUnselect = (e) => {
        const result = selectedAchievementProp.filter(function (object) {
            var id = object.id
            var idProject = object.proyecto
            return id !== e.data.id || idProject !== e.data.proyecto
        })
        setSelectedAchievementProp(result)
    }

    const onSelectedAchievementChange = (e) => {
        setSelectedAchievement(e.value)
        if (e.type === "all") {
            var sendData = e.value.map(item => {
                var objective = objectivesProp.filter(function (object) {
                    var id = object.id
                    return id === item.objetivo.id
                })
                var filterObjective = objective[0].proyectos.filter(function (object) {
                    var id = object.proyecto.id
                    return parseInt(id) === parseInt(idProjectsProp)
                })
                return {
                    id: item.id,
                    logro: item.logro,
                    detallelogro: item.detallelogro,
                    objetivo: {
                        descripcion: item.objetivo.descripcion,
                        porcentajecumplimiento: filterObjective[0].porcentajecumplimiento
                    },
                    fechafinalizacion: item.fechafinalizacion,
                    proyecto: item.proyecto
                }
            })
            if (e.value.length !== 0) {
                var arrayData = selectedAchievementProp.concat(sendData)
                var arrayDataMap = arrayData.map(item => {
                    return [JSON.stringify(item), item]
                })
                var arrayDataMapArr = new Map(arrayDataMap)
                var uniqueData = [...arrayDataMapArr.values()]
                setSelectedAchievementProp(uniqueData)
            } else {
                setDisabledIndexProp(true)
                var newAchievement = newResult.map(item => {
                    return {
                        id: item.id,
                        logro: item.logro,
                        detallelogro: item.detallelogro,
                        objetivo: {
                            descripcion: item.objetivo.descripcion
                        },
                        fechafinalizacion: item.fechafinalizacion,
                        proyecto: item.proyecto
                    }
                })
                var newData = []
                for (var i = 0; i < selectedAchievementProp.length; i++) {
                    var equal = false;
                    for (var j = 0; j < newAchievement.length & !equal; j++) {
                        if (selectedAchievementProp[i]['id'] === newAchievement[j]['id'] &&
                            selectedAchievementProp[i]['proyecto'] === newAchievement[j]['proyecto'])
                            equal = true;
                    }
                    if (!equal) newData.push(selectedAchievementProp[i]);
                }
                setSelectedAchievementProp(newData)
            }
        }
        if (e.value.length !== 0) {
            setDisabledIndexProp(true)
        }
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    // proceso para exportar datos por Excel

    const exportExcel = () => {
        const newArrWithOutId = achievementServiceProp.map(item => {
            const { id, ...rest } = item; // Usamos destructuring para excluir la propiedad 'id'
            return rest;
        })
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(newArrWithOutId);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    const header = (
        <div className="headerTable">
            {showAllDataProp ?
                null
                :
                readReportProp ?
                    null
                    :
                    newSelectedObjectiveProp && newSelectedObjectiveProp.length !== 0 &&
                    <BtnCrearLogro
                        idProjectProp={idProjectsProp}
                        projectProp={projectProp}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                        dateStartProp={dateStartValidity}
                        dateEndProp={dateEndValidity}
                        reloadObjectiveProp={reloadObjectiveProp}
                        objectivesProp={objectivesProp}
                        projectsUserSessionProp={projectsUserSessionProp}
                        achievementsProp={newResult}
                        dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                        dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                    />
            }
            {showAllDataProp ?
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLSX" />
                :
                null
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    const usuario = cookies.get('usuario');

    const acctions = (rowData) => {
        var monthlyReportDataAllDataPropAchievement = monthlyReportDataAllDataProp.map(item => {
            return {
                logros: JSON.parse(item.logros)
            }
        })
        var filterAchievement = []
        const result = monthlyReportDataAllDataPropAchievement.map(item => {
            return item.logros.map(j => {
                var boolean
                if (j.id === rowData.id) {
                    boolean = item
                }
                return boolean
            })

        })
        result.forEach(i => {
            i.forEach(j => {
                if (j !== undefined) {
                    if (j.length !== 0) {
                        filterAchievement.push(j)
                    }
                }
            })
        })
        var uniqueFilterAchievement = [...new Set(filterAchievement)]

        //Validar si el logro ya se encuentra creado en base de datos con el mismo usuario
        var getFilterFounded = []
        if (objectMonthlyReportDataProp !== undefined && objectMonthlyReportDataProp.logros !== "[]") {
            var getAchievement = JSON.parse(objectMonthlyReportDataProp.logros)
            selectedAchievement?.forEach(i => {
                const filter = getAchievement.filter(obj => {
                    return obj.id === i.id
                })
                filter.forEach(i => {
                    getFilterFounded.push(i)
                })
            })
        }
        var noDeleteAchievement = false
        var messagDelete = '¿Está seguro de eliminar este logro?, Recuerde que una vez se elimine, se borrará definitivamente del sistema'
        if (rowData.creadopor !== usuario) {
            noDeleteAchievement = true
            messagDelete = 'No puede eliminar registros creados por otro usuario'
        } else if (uniqueFilterAchievement.length !== 0 && getFilterFounded.length === 0) {
            noDeleteAchievement = true
            messagDelete = 'No puede eliminar este logro por que ya ha sido usado por otro usuario'
        } /* else if (rowData.creadopor === usuario && getFilterFounded.length !== 0) {
            noDeleteAchievement = true
            messagDelete = 'Si desea eliminar el logro, por favor deseleccione y selecciona la opción guardar'
        } */
        return (
            <>
                <BtnActualizarLogro
                    projectsProp={rowData.proyectos.nombreproyecto}
                    idProjectsProp={rowData.proyectos.id}
                    dateStartProp={rowData.proyectos.fechainiciovigencia}
                    dateEndProp={rowData.proyectos.fechafinvigencia}
                    descriptionObjectivesProp={rowData.objetivo.descripcion}
                    idDescriptionObjectivesProp={rowData.objetivo.id}
                    detailAchievementProp={rowData.detallelogro}
                    achievementProp={rowData.logro}
                    dateProp={rowData.sendDate}
                    creadoPorProp={rowData.creadopor}
                    fechaCreacionProp={rowData.fechacreacion}
                    idLogroProp={rowData.id}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                    reloadObjectiveProp={reloadObjectiveProp}
                    objectivesProp={objectivesProp}
                    projectsUserSessionProp={projectsUserSessionProp}
                    achievementsProp={newResult}
                    dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                    dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                    courtComplianceProp={rowData.cumplimientoalcorte}
                />
                <ConfirmBtn
                    tittle="Confirmar Eliminación"
                    menssage={messagDelete}
                    action="Delete"
                    label=""
                    classProp="p-button-danger"
                    icon="pi pi-trash"
                    idLogroProp={rowData.id}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                    noDeleteAchievementProp={noDeleteAchievement}
                />

                <VerAsociaciones
                    dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                    dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                    idLogroProp={rowData.id}
                />

            </>
        )
    }
    let small = UseMedia("(max-width: 760px)");
    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                {showAllDataProp ?
                    <DataTable ref={dt} value={achievementServiceProp} dataKey="id" responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                        paginator rows={10} header={header} globalFilter={globalFilter} emptyMessage="Logro no registrado en el sistema" loading={loadingProp}
                    >
                        <Column className="columna" field="cliente" sortable header="Cliente" />
                        <Column className="columna" field="proyecto" sortable header="Proyecto" />
                        <Column className="columna" field="periodo" sortable header="Periodo" />
                        <Column className="columna" field="equipoTrabajo" sortable header="Equipo de Trabajo" />
                        <Column className="columna" field="nombreColaborador" sortable header="Nombre Colaborador" />
                        <Column className="columna" field="objetivo" sortable header="Objetivo" />
                        <Column className="columna" field="cumplimientoalcorte" sortable header="Cumplimiento al Corte" />
                        <Column className="columna" field="logro" sortable header="Logro" />
                        <Column className="columna" field="detalleLogro" sortable header="Detalle del logro" />
                        <Column className="columna" field="fechacumplimiento" sortable header="Fecha Cumplimiento" />
                    </DataTable>
                    :
                    <DataTable ref={dt} value={readReportProp ? listAchievement : newResult} dataKey="id" responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px"
                        selection={readReportProp ? null : selectedAchievement} onSelectionChange={readReportProp ? null : e => onSelectedAchievementChange(e)}
                        selectionMode={individualMonthReportProp === true ? "checkbox" : null} paginator rows={5}
                        onRowUnselect={readReportProp ? null : onRowUnselect} onRowSelect={readReportProp ? null : onRowSelect}
                        header={header} globalFilter={globalFilter} loading={loadingProp}
                        emptyMessage={selectedObjectiveProp.length === 0 ? "Debe seleccionar primero un objetivo para este proyecto, luego podrá gestionar los logros del proyecto" : "Logro no registrado en el sistema"}
                    >
                        {individualMonthReportProp === true ?
                            showAllDataProp ?
                                null
                                :
                                readReportProp ?
                                    null
                                    :
                                    <Column selectionMode="multiple" style={{ maxWidth: '1rem' }} ></Column>
                            :
                            <></>
                        }
                        <Column className="columna" field={readReportProp ? "proyecto.nombreproyecto" : "proyectos.nombreproyecto"} header="Proyecto" />
                        <Column className="columna" field="objetivo.descripcion" header="Objetivo" />
                        <Column className="columna" field="cumplimientoalcorte" header="Cumplimiento al corte" />
                        <Column className="columna" field="logro" header="Logro" />
                        <Column className="columna" field="detallelogro" header="Detalle del logro" />
                        <Column className="columna" field="fechafinalizacion" header="Fecha finalización" />
                        {individualMonthReportProp === true ?
                            <Column className="columna" field="creadopor" sortable header="Creado por" />
                            :
                            <></>
                        }
                        {showAllDataProp ?
                            null
                            :
                            readReportProp ?
                                null
                                :
                                < Column header="Acciones" body={acctions} />
                        }
                    </DataTable>
                }

                {individualMonthReportProp === true && disabledIndexProp === true && loadingProp === false ?
                    <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                                <Message severity="warn" text="Debe guardar los datos para cambiar de pestaña" />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default ConsultarLogros