import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import '../../assets/css/CalendarSize.css'
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../../assets/css/DataTable.css';


const MensajeRestriccion = ({ validarProp, enviarMensajeProp, exportPDFProp, setSendPDFProp, 
    mensajeProp, classNameProp,iconProp, tooltipProp }) => {

    /* console.log(validarProp) */
    const [displayBasic, setDisplayBasic] = useState(validarProp);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const [setPosition] = useState('center');

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

   

    const renderFooter = (name) => {
        return (
            <div>
                    <Button label="Entedido" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }

    return (
        <div className="dialog-demo">
            <div className="card">
            <Button className={classNameProp} icon={iconProp} onClick={() => onClick('displayBasic')}
                    tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                ></Button>
                <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic} modal breakpoints={{ '960px': '75vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>{mensajeProp}</span>

                    </div>
                </Dialog>

            </div>
        </div>
    )
}

export default MensajeRestriccion