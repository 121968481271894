import React from 'react'
import '../assets/css/Login.css';
import Index from '../componets/Login/Index'


const Login = ({ setPermissionsProp }) => {

    return (

        <>
            <Index 
                setPermissionsProp={setPermissionsProp}
            />
        </>
    )
}

export default Login