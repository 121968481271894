import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ConfirmBtn from "./ConfirmBtn";
import Services from '../../service/Services';
import Cookies from 'universal-cookie'
import axios from "axios";
import { Toast } from 'primereact/toast';
const cookies = new Cookies()

const BtnActualizarServicio = ({ idServiceProp, serviceProp, clientProp, creationDateProp, createdByProp, setRunRenderProp, runRenderProp, clientsProp,
    individualMonthReportProp, servicesProp }) => {

    const [clientsUserSession, setClientsUserSession] = useState([]);

    const getServices = new Services();
    const toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            getServices.getProyectosUsuarios().then(data => {
                if (data !== undefined) {
                    var nameClients = []
                    data.forEach(element => {
                        var clients = {}
                        clients.id = element.cliente.id
                        clients.name = element.cliente.nombre
                        nameClients.push(clients)
                    })
                    const clientsTable = {}
                    const uniqueClients = nameClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })
                    if (individualMonthReportProp === true) {
                        var newUniqueClients = uniqueClients.filter(function (object) {
                            var idClient = object.id
                            return parseInt(idClient) === clientsProp.id
                        })
                        setClientsUserSession(newUniqueClients)
                    } else {
                        setClientsUserSession(uniqueClients)
                    }
                }
            });
        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        name: '',
        id: ''
    })

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: parseInt(item.id) }
        return av
    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    //Obtener datos del input servicio

    const [inputService, setInputService] = useState({
        service: ''
    })

    const onServiceChange = (event) => {

        setInputService({
            ...inputService,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setSelectedClient({
            name: clientProp.razonsocial,
            id: clientProp.id
        })
        setInputService({
            service: serviceProp
        })
    }, [clientProp, serviceProp])

    //Almacenamos los valores para enviarlos por props

    var idClient = selectedClient.id
    var service = inputService.service

    const resetData = () => {
        setSelectedClient({
            name: clientProp.razonsocial,
            id: clientProp.id
        })
        setInputService({
            service: serviceProp
        })
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }


    const usuario = cookies.get('usuario');
    const renderFooter = (name) => {
        var createNewService = false
        if (usuario !== createdByProp) {
            createNewService = true
        }
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage={createNewService ? "El registro editado no fue creado con su usuario, por lo tanto, se creará un nuevo registro con los datos actualizados, tenga en cuenta seleccionar este nuevo registro para su informe." : "¿Estás seguro de guardar esta información?"}
                            action={createNewService ? "Create" : "Update"}
                            label="Actualizar"
                            icon="pi pi-pencil"
                            idServiceProp={idServiceProp}
                            idClientProp={idClient}
                            serviceProp={service}
                            statusProp={1}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            updateDetailProp={false}
                            deleteDetailProp={true}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    // validaciones

    var serviceError = false
    var serviceMessage = ''

    if (service !== '') {
        if (service.length < 10) {
            serviceError = true;
            serviceMessage = 'La descripción del servicio es demasiado corta';
        } else if (service.length > 100) {
            serviceError = true;
            serviceMessage = 'La descripción del servicio es demasiado larga';
        } else if (service.length >= 10 && service.length <= 100) {
            if (servicesProp) {
                // Excluir el servicio actual de la búsqueda de duplicados
                var serviceDuplicate = servicesProp.filter(obj => 
                    parseInt(obj.id) !== idServiceProp && // Excluir el servicio actual
                    obj.cliente.id === parseInt(idClient) && 
                    obj.descripcion.toLowerCase().trim().replace(/\s+/g, ' ') === service.toLowerCase().trim().replace(/\s+/g, ' ')
                );
    
                if (Array.isArray(serviceDuplicate) && serviceDuplicate.length !== 0) {
                    serviceError = true;
                    toast.current.show({ 
                        severity: 'error', 
                        summary: '¡Algo salió mal!', 
                        detail: 'Ya se encuentra un servicio existente con ese cliente y esa descripción.', 
                        life: 6000 });
                }
            }
        }
    }


    var updatePermission = false

    if (service !== '' && serviceError === false && selectedClient.id !== '') {
        updatePermission = true
    }

    return (
        <div className="">
            <Toast ref={toast} />
            <div>
                <Button
                    icon="pi pi-pencil"
                    className="btnEditar p-button-secondary"
                    onClick={() => onClick('displayBasic')}
                    tooltip="Editar"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                ></Button>
                <Dialog
                    className="modal"
                    header="Actualizar Servicio"
                    visible={displayBasic}
                    style={{}}
                    footer={renderFooter('displayBasic')}
                    onHide={() => onHide('displayBasic')}
                >
                    <div className="p-fluid p-formgrid p-grid">
                        <form style={{ display: 'contents' }}>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels">
                                    Cliente<span className="require">*</span>
                                </label>
                                <Dropdown
                                    value={selectedClient}
                                    options={clients}
                                    onChange={onClientChange}
                                    placeholder="Seleccione un cliente"
                                    className="p-column-filter"
                                    emptyMessage="No hay resultados"
                                    name="client"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels">
                                    Servicio<span className="require">*</span>
                                </label>
                                <InputText
                                    type="text"
                                    value={service}
                                    onChange={onServiceChange}
                                    name="service"
                                    placeholder="Ingrese la descripción del servicio"
                                    className={serviceError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">
                                    {serviceError ? serviceMessage : ""}
                                </p>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default BtnActualizarServicio