import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

const ConfirmBtn = ({ mensaje, accion, idUsuariosProp, idCierreProp, dateEndProp, dateStartProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "abrirMes") {
            ReabrirUltimoMes()
            setDisabled(true)
        } else if (accion === "cierre") {
            EjecutarCierre()
            setDisabled(true)
        } else if (accion === "cierreAbierto") {
            EjecutarCierreAbierto()
            setDisabled(true)
        } else if (accion === "closeFirstMonth") {
            CloseFirstMonth()
            setDisabled(true)
        }

    }

    const CloseFirstMonth = async () => {

        let url = process.env.REACT_APP_BASE_URL + '/v1/api/admim/closes/closeFirstMonth';
        const json = JSON.stringify(
            {
                estado: "C",
                fechaAReapertura: null,
                fechaFinalCierre: dateEndProp,
                fechaInicialCierre: dateStartProp,
                idusuario: {
                    id: parseInt(idUsuariosProp)
                }
            }
        );
        await axios.post(url, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Cierre Ejcutado Correctamente', detail: response.data.message, life: 6000 });
                //console.log(response.data)
                setDisplayConfirmation(false);
                window.location.href = '/registroActividades/cerrarMes'
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })
    };

    const url = process.env.REACT_APP_BASE_URL + '/v1/api/admim/closes/executeReOpen'

    const ReabrirUltimoMes = async () => {

        const json = JSON.stringify(
            {
                idCierre: idCierreProp,
                listaUsuario: idUsuariosProp
            }
        )
        /* console.log(json) */
        await axios.post(url, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Mes abirerto con exito', detail: response.data.message, life: 6000 });
                //console.log(response.data)
                setDisplayConfirmation(false)
                window.location.href = "/registroActividades/abrirMes"
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })
    }

    const EjecutarCierreAbierto = async () => {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/admim/closes/executeCloseReOpen/' + idCierreProp

        await axios.get(url,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Cierre Ejcutado Correctamente', detail: response.data.message, life: 6000 });
                //console.log(response.data)
                setDisplayConfirmation(false)
                window.location.href = '/registroActividades/cerrarMes'
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })
    }

    const EjecutarCierre = async () => {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/admim/closes/execute/' + idUsuariosProp

        await axios.get(url,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Cierre Ejcutado Correctamente', detail: response.data.message, life: 6000 });
                //console.log(response.data)
                setDisplayConfirmation(false)
                window.location.href = '/registroActividades/cerrarMes'
            })
            .catch(function (error) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                //console.log(error.response)
            })
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled} />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label="Guardar" onClick={() => onClick('displayConfirmation')} className="p-button-outlined p-button-success"
                tooltip="Guardar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content center">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{mensaje}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn