import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Column } from 'primereact/column';
import { CambiarFormatoFecha, EstandarFormatoFecha, SumarDias } from '../Funciones';
import Cookies from 'universal-cookie';
import ConfirmBtn from './ConfirmBtn'
/* import BtnFechasHistoricoCargo from './BtnFechasHistoricoCargo'; */

const BtnUserHistory = ({ positionHistoryProp, statusHistoryProp, dataCompletedProp, 
    runRenderProp, setRunRenderProp, historicoHorasProp}) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const cookies = new Cookies();

    const usuarioSesion = cookies.get('usuario')

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        );
    }
    // función para cambiar formato de fecha y validar zona horaria
    const changeDates = (date) => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newDate = new Date(date)
        var sendDate
        if (gmtHours < 0) {
            sendDate = SumarDias(newDate, +1)
        } else {
            sendDate = newDate
        }
        return sendDate
    }

    const [newPositionHistory, setNewPositionHistory] = useState([]);
    const [newStatusHistory, setNewStatusHistory] = useState([]);
    const [newHoursHistory, setNewHoursHistory] = useState([]);
    useEffect(() => {
        if (positionHistoryProp) {
            var newPosistionHistory = positionHistoryProp.map(item => {
                var newDateStart = null
                if (item.fechainicioasignacion !== null) {
                    newDateStart = changeDates(item.fechainicioasignacion)
                }
                var newDateEnd = null
                if (item.fechafinasignacion !== null) {
                    newDateEnd = changeDates(item.fechafinasignacion)
                }

                return {
                    ...item,
                    fechainicioasignacion: newDateStart !== null ? EstandarFormatoFecha(newDateStart) : null,
                    fechafinasignacion: newDateEnd !== null ? EstandarFormatoFecha(newDateEnd) : null,
                    fechainicioasignacion2: newDateStart !== null ? CambiarFormatoFecha(newDateStart) : null,
                    fechafinasignacion2: newDateEnd !== null ? CambiarFormatoFecha(newDateEnd) : null,
                }
            })
            setNewPositionHistory(newPosistionHistory)

            var newStatusHistory = statusHistoryProp.map(item => {
                var estadoanterior
                if (item.estadoanterior === 0) {
                    estadoanterior = "Inactivo"
                } else if (item.estadoanterior === 1) {
                    estadoanterior = "Activo"
                } else if (item.estadoanterior === 2) {
                    estadoanterior = "Bloqueado"
                } else if (item.estadoanterior === 3) {
                    estadoanterior = "Eliminado"
                }
                var newAdmissionDate = null
                if (item.fechaingreso !== null) {
                    newAdmissionDate = changeDates(item.fechaingreso)
                }
                var newLastDate = null
                if (item.ultimafecharegistro !== null) {
                    newLastDate = changeDates(item.ultimafecharegistro)
                }
                var newDate = null
                if (item.fechacreacion !== null) {
                    newDate = changeDates(item.fechacreacion)
                }
                return {
                    ...item,
                    estadoanterior: estadoanterior,
                    fechaingreso: EstandarFormatoFecha(newAdmissionDate),
                    ultimafecharegistro: newLastDate && EstandarFormatoFecha(newLastDate),
                    fechacreacion: EstandarFormatoFecha(newDate),
                    orderDate: CambiarFormatoFecha(newDate)
                }
            })
            newStatusHistory.sort(function (a, b) {
                var textA = new Date(a.orderDate)
                var textB = new Date(b.orderDate)
                return textB - textA
            })
            setNewStatusHistory(newStatusHistory)
            
            let newHoursHistory2 = historicoHorasProp.map(item => {
                var estado
                if (item.estado === 0) {
                    estado = "Inactivo"
                } else if (item.estado === 1) {
                    estado = "Activo"
                }
                let dateStart =null 
                let dateEnd = null
                if (item.fechainicioasignacion !== null && item.fechainicioasignacion!=='') {
                    dateStart = changeDates(item.fechainicioasignacion)
                }
                if(item.fechafinasignacion !==null && item.fechafinasignacion!=='') {
                    dateEnd = changeDates(item.fechafinasignacion)
                }
               
               
                return {
                    ...item,
                    fechafinasignacion: dateEnd!==null? EstandarFormatoFecha(dateEnd):'',
                    fechainicioasignacion: dateStart!==null?EstandarFormatoFecha(dateStart):'',
                    estado: estado
                }
            })
            setNewHoursHistory(newHoursHistory2)
        }
    }, [positionHistoryProp, statusHistoryProp, historicoHorasProp]) // eslint-disable-line react-hooks/exhaustive-deps

    const dt = useRef(null);

    const [selectedMode, setSelectedMode] = useState('positionHistory');

    const onselectedModeChange = (e) => {
        setSelectedMode(e.value)
    }

    const Acciones = (rowData) => {
        return (    
            <>
                <ConfirmBtn 
                    titulo="Eliminar Cargo"
                    icono="pi pi-trash"
                    classProp="p-button-danger"
                    tooltipProp="Eliminar"
                    mensaje="¿Está seguro de eliminar la asociación?"
                    accion="Actualizar"
                    updateDateProp={true}
                    isDeleteProjectUsersProp={false}
                    eliminarCargoProp={true}
                    usuarioSesionProp={usuarioSesion}
                    apellidoProp={dataCompletedProp.apellidos}
                    bloqueoProp={dataCompletedProp.tipobloqueo.descripcion}
                    idEstadoProp={dataCompletedProp.idEstado}
                    fechaIngresoProp={dataCompletedProp.sendFecIngreso}
                    fechaactualizadoProp={dataCompletedProp.fechaactualizado}
                    fechaCreacionProp={dataCompletedProp.fechacreacion}
                    idCargoProp={dataCompletedProp.cargo.id}
                    idRolProp={dataCompletedProp.rol.id}
                    idBloqueoProp={dataCompletedProp.tipobloqueo.id}
                    intentosProp={dataCompletedProp.intentos}
                    nombreProp={dataCompletedProp.nombre}
                    tipoDocumentoProp={dataCompletedProp.tipodocumento}
                    numeroDocProp={dataCompletedProp.nrodocumento}
                    lastDateRegisterProp={dataCompletedProp.ultimafecharegistro}
                    usuarioProp={dataCompletedProp.usuario}
                    updatePositionHistoryProp={true}
                    dateStartPositionProp={rowData.fechainicioasignacion2}
                    dateEndPositionProp={rowData.fechafinasignacion2}
                    updateStatusHistoryProp={false}
                    historicoHorasProp={dataCompletedProp.historicoHoras}
                    runRenderProp={runRenderProp}
                    setRunRenderProp={setRunRenderProp}
                    idCargoEliminadoProp ={rowData.cargo.id}
                    idAsociacionCargoProp={rowData.id}
                    />
            </>
        )
    } 
    return (
        <>
            <Button icon="pi pi-history" className="btnEditar p-button-secondary" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                tooltip="Histórico usuario" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Histórico de usuario" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3">
                            <div className="p-field-radiobutton" style={{ marginBottom: '5px' }}>
                                <RadioButton inputId="positionHistory" name="positionHistory" value="positionHistory" onChange={onselectedModeChange} checked={selectedMode === 'positionHistory'} />
                                <label htmlFor="positionHistory" style={{ fontWeight: '100' }}>Histórico de cargos</label>
                            </div>
                            <div className="p-field-radiobutton" style={{ marginTop: '10px' }}>
                                <RadioButton inputId="statusHistory" name="statusHistory" value="statusHistory" onChange={onselectedModeChange} checked={selectedMode === 'statusHistory'} />
                                <label htmlFor="statusHistory" style={{ fontWeight: '100' }}>Histórico de estados</label>
                            </div>
                            <div className="p-field-radiobutton" style={{ marginTop: '10px' }}>
                                <RadioButton inputId="statusHistory" name="statusHistory" value="hoursHistory" onChange={onselectedModeChange} checked={selectedMode === 'hoursHistory'} />
                                <label htmlFor="statusHistory" style={{ fontWeight: '100' }}>Histórico de horas</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        {selectedMode === 'positionHistory' ?
                            <DataTable ref={dt} value={newPositionHistory} className="p-datatable-responsive-demo" paginator rows={10}
                                emptyMessage="No hay registros históricos para este usuario">
                                <Column className="columna" sortable field="cargo.cargo" header="Cargo" />
                                <Column className="columna" sortable field="fechainicioasignacion" header="Fecha inicio asignación" />
                                <Column className="columna" sortable field="fechafinasignacion" header="Fecha fin asignación" />
                                {       <Column className="columna" sortable body={Acciones} header="Acciones" /> }
                            </DataTable>
                            :
                            selectedMode === 'statusHistory' ?
                                <DataTable ref={dt} value={newStatusHistory} className="p-datatable-responsive-demo" paginator rows={10}
                                    emptyMessage="No hay registros históricos para este usuario">
                                    <Column className="columna" sortable field="estadoanterior" header="Estado anterior" />
                                    <Column className="columna" sortable field="tipobloqueo.descripcion" header="Tipo de bloqueo" />
                                    <Column className="columna" sortable field="fechaingreso" header="Fecha de ingreso" />
                                    <Column className="columna" sortable field="ultimafecharegistro" header="Última fecha de registro" />
                                    <Column className="columna" field="fechacreacion" header="Fecha de ejecución" />
                                </DataTable>
                                :
                                <DataTable ref={dt} value={newHoursHistory} className="p-datatable-responsive-demo" paginator rows={10}
                                    emptyMessage="No hay registros históricos para este usuario">
                                    <Column className="columna" sortable field="horasMinimas" header="Horas Mínimas" />
                                    <Column className="columna" sortable field="horasMaximas" header="Horas Máximas" />
                                    <Column className="columna" sortable field="fechainicioasignacion" header="Fecha Inicio Asignación" />
                                    <Column className="columna" sortable field="fechafinasignacion" header="Fecha Fin Asignación" />
                                    <Column className="columna" field="estado" header="Estado" />
                                </DataTable>
                        }

                    </div>
                </div>
            </Dialog>
        </>
    )
}
export default BtnUserHistory