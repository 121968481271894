import React, { useState, useEffect } from "react"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import Services from '../../service/Services';
import { CambiarFormatoFecha, SumarDias } from "../Funciones"

const ModalContactClient = ({ disabledProp, selectedIdClienteProp, dateStartTeamProp, dateEndTeamProp, setNameContactCustomerProp, setDataContactCustomerProp,
    dateStartProp, dateEndProp, selectedContactCustomerProp }) => {
    const getServices = new Services()

    const [displayContactClient, setDisplayContactClient] = useState(false);
    const [validateChange, setValidateChange] = useState(false);
    const [servicesInterestedPeople, setServicesInterestedPeople] = useState([]);

    const dialogFuncMap = {
        'displayContactClient': setDisplayContactClient
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true)
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false)
    }

    useEffect(() => {

        if (selectedIdClienteProp) {
            getServices.getInterestedPerson().then(data => {
                var newArray = []
                const result = data.map(item => {
                    return item.proyectos.map(i => {
                        var newData
                        if (i.proyecto.cliente.id === parseInt(selectedIdClienteProp)) {
                            newData = item
                        }
                        return newData
                    })
                })
                result.forEach(element => {
                    element.forEach(i => {
                        if (i !== undefined) {
                            if (i.length !== 0) {
                                newArray.push(i)
                            }
                        }
                    })
                })
                var newArray1 = [...new Set(newArray)]
                setServicesInterestedPeople(newArray1)
            })
        }

    }, [selectedIdClienteProp]) // eslint-disable-line react-hooks/exhaustive-deps

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;

    const sendChangesToStatus = () => {
        if (dateStartProp !== null && dateStartProp !== undefined) {
            const dateStartChange = new Date(dateStartProp)
            if (gmtHours < 0) {
                setDateStart(SumarDias(dateStartChange, +1))
            }
            else {
                setDateStart(dateStartChange)
            }
        } else {
            setDateStart(null)
        }

        if (dateEndProp !== null && dateEndProp !== undefined) {

            const dateEndChange = new Date(dateEndProp)

            if (gmtHours < 0) {
                setDateEnd(SumarDias(dateEndChange, +1))
            }
            else {
                setDateEnd(dateEndChange)
            }
        }
        else {
            setDateEnd(null)
        }
        setSelectedContactCustomer(selectedContactCustomerProp)
    }
    //Se ejecuta cada vez que haya un cambio en el btnContactoCliente en editar
    useEffect(() => {
        sendChangesToStatus()
    }, [dateStartProp, dateEndProp, selectedContactCustomerProp])// eslint-disable-line react-hooks/exhaustive-deps

    const ModalRegresar = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = () => {
            setDisplayConfirmation(false)
            setDisplayContactClient(false)
            setSelectedContactCustomer(null)
            setDateStart(null)
            setDateEnd(null)
            setValidateChange(false)
            sendChangesToStatus()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar()} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

  

    //Obtener datos del contacto del Cliente
    const [selectedContactCustomer, setSelectedContactCustomer] = useState(null)

    const getContactCustomer = servicesInterestedPeople.map(item => {
        const av = { name: item.nombre + ' ' + item.apellido, id: item.id }
        return av
    })

    const onContactCustomer = (e) => {
        setSelectedContactCustomer(e.value)
        setValidateChange(true)
    }

    const [dateStart, setDateStart] = useState(null)

    const onDateStartChange = (e) => {
        setDateStart(e.value)
        setValidateChange(true)
    }

    const [dateEnd, setDateEnd] = useState(null)

    const onDateEndChange = (e) => {
        setDateEnd(e.value)
        setValidateChange(true)
    }


    const closeWindow = () => {
        setDisplayContactClient(false)
        setNameContactCustomerProp(selectedContactCustomer.name)
        setValidateChange(true)
        var newArrayContactClientData = //Enviar datos por detrás
        {
            name: selectedContactCustomer.name,
            id: selectedContactCustomer.id,
            dateStart: dateStart,
            dateEnd: dateEnd
        }
        setDataContactCustomerProp(newArrayContactClientData)
    }

    //Validaciones
    var dateStartError = false
    var messageDateStart = ''

    const dateRange = () => {
        var newDateStart = CambiarFormatoFecha(dateStart)
        var newDateEnd = CambiarFormatoFecha(dateEnd)
        var newDateStartTeamProp = dateStartTeamProp!==null? CambiarFormatoFecha(dateStartTeamProp):null
        var newDateEndTeamProp = dateEndTeamProp!==null? CambiarFormatoFecha(dateEndTeamProp):null
        if (dateStart > dateEnd) {
            dateStartError = true
            messageDateStart = 'La fecha de inicio debe ser menor a la fecha fin'
        }
        else if (dateStartTeamProp !== null && dateEndTeamProp !== null) {
            if ( newDateStart < newDateStartTeamProp || newDateStart > newDateEndTeamProp
                || newDateEnd > newDateEndTeamProp) {
                dateStartError = true
                messageDateStart = 'Las fechas de vigencia del contacto cliente no se encuentran en el rango de fechas del equipo de trabajo'
            }else{
                dateStartError = false
                messageDateStart = ''
            }
        } else if (dateStartTeamProp !== null) {
            if (dateStart < dateStartTeamProp) {
                dateStartError = true
                messageDateStart = 'Las fechas de vigencia del contacto cliente deben ser mayor que las fechas de equipo de trabajo'
            }
        }

    }

    const validateDateStart = () => {
        if (dateStartTeamProp !== null && dateEndTeamProp !== null) {
            dateStartError = true
            messageDateStart = 'La fecha fin no puede quedar vacia débido a que el equipo tiene una fecha fin registrada'

        }
        if (dateStartTeamProp !== null) {
            if (dateStart < dateStartTeamProp) {
                dateStartError = true
                messageDateStart = 'La fecha inicio de contacto cliente debe ser mayor que las fechas de equipo de trabajo'
            }
        }

    }

    if (dateStart !== null && dateEnd !== null) {
        dateRange()
    }
    else if (dateStart !== null && dateEnd === null) {
        validateDateStart()
    }
    //Validaciones para darle permiso al boton guardar de aparecer
    var createPermissionSave = false

    if (selectedContactCustomer !== null && dateStart !== null &&
        dateStartError === false) {

        createPermissionSave = true
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    createPermissionSave ?
                        <Button label="Guardar" icon="pi pi-save" onClick={() => closeWindow()} />
                        :
                        ''
                }
            </div>
        )
    }
    return (
        <>
            <Button icon="pi pi-plus" id="boton-coordinator" onClick={() => onClick('displayContactClient')} className="p-button-success" disabled={disabledProp} />
            <Dialog className="modal" header="Lista de contacto Cliente" visible={displayContactClient} modal style={{}}
                footer={renderFooter('displayContactClient')} onHide={() => onHide('displayContactClient')}>

                <div className="p-fluid p-formgrid p-grid">
                    <div className='p-field p-col-6 p-md-6'>
                        <label className="labels">Seleccione un contacto Cliente<span className="require">*</span></label>
                        <Dropdown value={selectedContactCustomer} options={getContactCustomer} onChange={onContactCustomer} optionLabel="name" filter emptyMessage="No hay resultados" placeholder="Selecione un contacto cliente" />
                    </div>

                    <div className="p-field p-col-6 p-md-6">
                        <label className="labels">Fecha inicio de vigencia del contacto Cliente<span className="require">*</span></label>
                        <Calendar value={dateStart} onChange={onDateStartChange} locale='es' dateFormat='dd-mm-yy'
                            placeholder="Seleccione una fecha inicio de vigencia" className={dateStartError ? "p-invalid" : ''} />
                        <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>
                    </div>

                    <div className="p-field p-col-6 p-md-6">
                        <label className="labels">Fecha fin de vigencia del contacto Cliente</label>
                        <Calendar value={dateEnd} onChange={onDateEndChange} locale='es' dateFormat='dd-mm-yy' placeholder="Seleccione una fecha fin de vigencia" />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ModalContactClient