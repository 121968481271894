import React, {useRef, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn'
import { Checkbox } from 'primereact/checkbox';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';
const cookies = new Cookies();

const BtnCrearRol = ({ servicioProp }) => {

    let toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input rol

    const [inputRol, setInputRol] = useState({
        rol: ''
    })

    const [validateChange, setValidateChange] = useState(false)

    const onRolChange = (event) => {

        setInputRol({
            ...inputRol,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }



    //Almacenamos los valores para enviarlos por props

    const rol = inputRol.rol
    const user = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setInputRol({
                rol: ''
            })

            setValidateChange(false)
            setCheckedUserRead(false)
            setCheckedUserCreate(false)
            setCheckedUserUpdate(false)
            setCheckedUserDelete(false)
            setCheckedUserSelectAll(false)
            setCheckedActivityRead(false)
            setCheckedActivityCreate(false)
            setCheckedActivityUpdate(false)
            setCheckedActivityDelete(false)
            setCheckedActivitySelectAll(false)
            setCheckedExportDataRead(false)
            setCheckedExportDataAll(false)
            setCheckedExportDataSelectAll(false)
            setCheckedClientRead(false)
            setCheckedClientCreate(false)
            setCheckedClientUpdate(false)
            setCheckedClientDelete(false)
            setCheckedClientSelectAll(false)
            setCheckedProjectRead(false)
            setCheckedProjectCreate(false)
            setCheckedProjectUpdate(false)
            setCheckedProjectDelete(false)
            setCheckedProjectSelectAll(false)
            setCheckedAplicationRead(false)
            setCheckedAplicationCreate(false)
            setCheckedAplicationUpdate(false)
            setCheckedAplicationDelete(false)
            setCheckedAplicationSelectAll(false)
            setCheckedBusinessAreasRead(false)
            setCheckedBusinessAreasCreate(false)
            setCheckedBusinessAreasUpdate(false)
            setCheckedBusinessAreasDelete(false)
            setCheckedBusinessAreasSelectAll(false)
            setCheckedActivityTypeRead(false)
            setCheckedActivityTypeCreate(false)
            setCheckedActivityTypeUpdate(false)
            setCheckedActivityTypeDelete(false)
            setCheckedActivityTypeSelectAll(false)
            setCheckedPositionRead(false)
            setCheckedPositionCreate(false)
            setCheckedPositionUpdate(false)
            setCheckedPositionDelete(false)
            setCheckedPositionSelectAll(false)
            setCheckedRolRead(false)
            setCheckedRolCreate(false)
            setCheckedRolUpdate(false)
            setCheckedRolDelete(false)
            setCheckedRolSelectAll(false)
            setCheckedCalendarRead(false)
            setCheckedCalendarManage(false)
            setCheckedCalendarSelectAll(false)
            setCheckedDownloadZip(false)
            setCheckedDownloadZipSelectAll(false)
            setCheckedRunClose(false)
            setCheckedCloseOpen(false)
            setCheckedNotification(false)
            setCheckedClosesSelectAll(false)
            setCheckedHistorical(false)
            setCheckedHistoricalSelectAll(false)
            setCheckedDBActivityLogger(false)
            setCheckedDBWorkload(false)
            setCheckedDBDetailTypeActivity(false)
            setCheckedDBDetailTypeActivityUser(false)
            setCheckedDBSelectAll(false)
            setCheckedDBReadPowerBi(false)
            setCheckedDBPowerBiClient(false)
            setCheckedDBPlusPowerBi(false)
            setCheckedDBPlusPowerBiSelectAll(false)
            setCheckedNoveltyAnotherOfficial(false)
            setCheckedNoveltyAnotherOfficialBSelectAll(false)
            setCheckedindividualMonthlyReportRead(false)
            setCheckedindividualMonthlyReportReadOtherOfficial(false)
            setCheckedindividualMonthlyReportReopen(false)
            setCheckedindividualMonthlyReportSelectAll(false)
            setCheckedInterestedPersonRead(false)
            setCheckedInterestedPersonCreate(false)
            setCheckedInterestedPersonUpdate(false)
            setCheckedInterestedPersonDelete(false)
            setCheckedInterestedPersonSelectAll(false)
            setCheckedObjectiveRead(false)
            setCheckedObjectiveCreate(false)
            setCheckedObjectiveUpdate(false)
            setCheckedObjectiveDelete(false)
            setCheckedObjectiveSelectAll(false)
            setCheckedContractRead(false)
            setCheckedContractCreate(false)
            setCheckedContractUpdate(false)
            setCheckedContractDelete(false)
            setCheckedContractSelectAll(false)
            setCheckedDeliverableRead(false)
            setCheckedDeliverableCreate(false)
            setCheckedDeliverableUpdate(false)
            setCheckedDeliverableDelete(false)
            setCheckedDeliverableSelectAll(false)
            setCheckedServiceRead(false)
            setCheckedServiceCreate(false)
            setCheckedServiceUpdate(false)
            setCheckedServiceDelete(false)
            setCheckedServiceSelectAll(false)
            setCheckedWorkTeamRead(false)
            setCheckedWorkTeamCreate(false)
            setCheckedWorkTeamUpdate(false)
            setCheckedWorkTeamDelete(false)
            setCheckedWorkTeamSelectAll(false)
            setCheckedTypeProjectRead(false)
            setCheckedTypeProjectCreate(false)
            setCheckedTypeProjectUpdate(false)
            setCheckedTypeProjectDelete(false)
            setCheckedTypeProjectSelectAll(false)
            setCheckedOrgRead(false)
            setCheckedOrgSelectAll(false)
            setCheckedDetailPendingRead(false)
            setCheckedDetailPendingSelectAll(false)
            setCheckedUserClientRead(false)
            setCheckedUserClientSelectAll(false)
            setCheckedSupplierRead(false)
            setCheckedSupplierCreate(false)
            setCheckedSupplierUpdate(false)
            setCheckedSupplierDelete(false)
            setCheckedSupplierSelectAll(false)
            setCheckedFilterSupplierNDB1(false)
            setCheckedFilterSupplierNDB2(false)
            setCheckedFilterSupplierExportData(false)
            setCheckedFilterSupplierSelectAll(false)
            setCheckedDBReadPowerBiFilterSupplier(false)
            setCheckedDBPowerBiFilterSupplierClient(false)
            setCheckedDBPlusPowerBiFilterSupplier(false)
            setErrorDuplicated(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }
    ///usuario
    const [checkedUserRead, setCheckedUserRead] = useState(false);
    const [checkedUserCreate, setCheckedUserCreate] = useState(false);
    const [checkedUserUpdate, setCheckedUserUpdate] = useState(false);
    const [checkedUserDelete, setCheckedUserDelete] = useState(false);
    const [checkedUserSelectAll, setCheckedUserSelectAll] = useState(false);


    const onUserReadChange = (e) => {
        if (checkedUserCreate === true || checkedUserUpdate === true || checkedUserDelete === true) {
            setCheckedUserRead(true)
        } else {
            setCheckedUserRead(e)
        }

        setValidateChange(true)

    }
    const onUserCreateChange = (e) => {
        setCheckedUserCreate(e)
        if (checkedUserRead === false) {
            setCheckedUserRead(e)
        }

        setValidateChange(true)

    }
    const onUserUpdateChange = (e) => {
        setCheckedUserUpdate(e)
        if (checkedUserRead === false) {
            setCheckedUserRead(e)
        }
        setValidateChange(true)

    }
    const onUserDeleteChange = (e) => {
        setCheckedUserDelete(e)
        if (checkedUserRead === false) {
            setCheckedUserRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllUserChange = (e) => {
        setCheckedUserSelectAll(e)

        setCheckedUserRead(e)
        setCheckedUserCreate(e)
        setCheckedUserUpdate(e)
        setCheckedUserDelete(e)
        setValidateChange(true)
    }
    ///actividad
    const [checkedActivityRead, setCheckedActivityRead] = useState(false);
    const [checkedActivityCreate, setCheckedActivityCreate] = useState(false);
    const [checkedActivityUpdate, setCheckedActivityUpdate] = useState(false);
    const [checkedActivityDelete, setCheckedActivityDelete] = useState(false);
    const [checkedActivitySelectAll, setCheckedActivitySelectAll] = useState(false);

    const onActivityReadChange = (e) => {
        if (checkedActivityCreate === true || checkedActivityUpdate === true || checkedActivityDelete === true) {
            setCheckedActivityRead(true)
        } else {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }
    const onActivityCreateChange = (e) => {
        setCheckedActivityCreate(e)
        if (checkedActivityRead === false) {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }
    const onActivityUpdateChange = (e) => {
        setCheckedActivityUpdate(e)
        if (checkedActivityRead === false) {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }
    const onActivityDeleteChange = (e) => {
        setCheckedActivityDelete(e)
        if (checkedActivityRead === false) {
            setCheckedActivityRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllActivityChange = (e) => {
        setCheckedActivitySelectAll(e)

        setCheckedActivityRead(e)
        setCheckedActivityCreate(e)
        setCheckedActivityUpdate(e)
        setCheckedActivityDelete(e)
        setValidateChange(true)
    }

    ///exportar datos
    const [checkedExportDataRead, setCheckedExportDataRead] = useState(false);
    const [checkedExportDataAll, setCheckedExportDataAll] = useState(false);

    const [checkedExportDataSelectAll, setCheckedExportDataSelectAll] = useState(false);

    const onExportDataReadChange = (e) => {
        if (checkedExportDataAll === true) {
            setCheckedExportDataRead(true)
        } else {
            setCheckedExportDataRead(e)
        }
        setValidateChange(true)

    }
    const onExportDataAllChange = (e) => {
        setCheckedExportDataAll(e)
        if (checkedExportDataRead === false) {
            setCheckedExportDataRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllExportDataChange = (e) => {
        setCheckedExportDataSelectAll(e)

        setCheckedExportDataRead(e)
        setCheckedExportDataAll(e)

        setValidateChange(true)
    }

    ///cliente
    const [checkedClientRead, setCheckedClientRead] = useState(false);
    const [checkedClientCreate, setCheckedClientCreate] = useState(false);
    const [checkedClientUpdate, setCheckedClientUpdate] = useState(false);
    const [checkedClientDelete, setCheckedClientDelete] = useState(false);
    const [checkedClientSelectAll, setCheckedClientSelectAll] = useState(false);

    const onClientReadChange = (e) => {
        if (checkedClientCreate === true || checkedClientUpdate === true || checkedClientDelete === true) {
            setCheckedClientRead(true)
        } else {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }
    const onClientCreateChange = (e) => {
        setCheckedClientCreate(e)
        if (checkedClientRead === false) {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }
    const onClientUpdateChange = (e) => {
        setCheckedClientUpdate(e)
        if (checkedClientRead === false) {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }
    const onClientDeleteChange = (e) => {
        setCheckedClientDelete(e)
        if (checkedClientRead === false) {
            setCheckedClientRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllClientChange = (e) => {
        setCheckedClientSelectAll(e)

        setCheckedClientRead(e)
        setCheckedClientCreate(e)
        setCheckedClientUpdate(e)
        setCheckedClientDelete(e)
        setValidateChange(true)
    }
    ////proyecto
    const [checkedProjectRead, setCheckedProjectRead] = useState(false);
    const [checkedProjectCreate, setCheckedProjectCreate] = useState(false);
    const [checkedProjectUpdate, setCheckedProjectUpdate] = useState(false);
    const [checkedProjectDelete, setCheckedProjectDelete] = useState(false);
    const [checkedProjectSelectAll, setCheckedProjectSelectAll] = useState(false);

    const onProjectReadChange = (e) => {
        if (checkedProjectCreate === true || checkedProjectUpdate === true || checkedProjectDelete === true) {
            setCheckedProjectRead(true)
        } else {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }
    const onProjectCreateChange = (e) => {
        setCheckedProjectCreate(e)
        if (checkedProjectRead === false) {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }
    const onProjectUpdateChange = (e) => {
        setCheckedProjectUpdate(e)
        if (checkedProjectRead === false) {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }
    const onProjectDeleteChange = (e) => {
        setCheckedProjectDelete(e)
        if (checkedProjectRead === false) {
            setCheckedProjectRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllProjectChange = (e) => {
        setCheckedProjectSelectAll(e)

        setCheckedProjectRead(e)
        setCheckedProjectCreate(e)
        setCheckedProjectUpdate(e)
        setCheckedProjectDelete(e)
        setValidateChange(true)
    }
    ////Aplicación
    const [checkedAplicationRead, setCheckedAplicationRead] = useState(false);
    const [checkedAplicationCreate, setCheckedAplicationCreate] = useState(false);
    const [checkedAplicationUpdate, setCheckedAplicationUpdate] = useState(false);
    const [checkedAplicationDelete, setCheckedAplicationDelete] = useState(false);
    const [checkedAplicationSelectAll, setCheckedAplicationSelectAll] = useState(false);

    const onAplicationReadChange = (e) => {
        if (checkedAplicationCreate === true || checkedAplicationUpdate === true || checkedAplicationDelete === true) {
            setCheckedAplicationRead(true)
        } else {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }
    const onAplicationCreateChange = (e) => {
        setCheckedAplicationCreate(e)
        if (checkedAplicationRead === false) {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }
    const onAplicationUpdateChange = (e) => {
        setCheckedAplicationUpdate(e)
        if (checkedAplicationRead === false) {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }
    const onAplicationDeleteChange = (e) => {
        setCheckedAplicationDelete(e)
        if (checkedAplicationRead === false) {
            setCheckedAplicationRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllAplicationChange = (e) => {
        setCheckedAplicationSelectAll(e)

        setCheckedAplicationRead(e)
        setCheckedAplicationCreate(e)
        setCheckedAplicationUpdate(e)
        setCheckedAplicationDelete(e)
        setValidateChange(true)
    }
    ///área de negocio
    const [checkedBusinessAreasRead, setCheckedBusinessAreasRead] = useState(false);
    const [checkedBusinessAreasCreate, setCheckedBusinessAreasCreate] = useState(false);
    const [checkedBusinessAreasUpdate, setCheckedBusinessAreasUpdate] = useState(false);
    const [checkedBusinessAreasDelete, setCheckedBusinessAreasDelete] = useState(false);
    const [checkedBusinessAreasSelectAll, setCheckedBusinessAreasSelectAll] = useState(false);

    const onBusinessAreasReadChange = (e) => {
        if (checkedBusinessAreasCreate === true || checkedBusinessAreasUpdate === true || checkedBusinessAreasDelete === true) {
            setCheckedBusinessAreasRead(true)
        } else {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }
    const onBusinessAreasCreateChange = (e) => {
        setCheckedBusinessAreasCreate(e)
        if (checkedBusinessAreasRead === false) {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }
    const onBusinessAreasUpdateChange = (e) => {
        setCheckedBusinessAreasUpdate(e)
        if (checkedBusinessAreasRead === false) {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }
    const onBusinessAreasDeleteChange = (e) => {
        setCheckedBusinessAreasDelete(e)
        if (checkedBusinessAreasRead === false) {
            setCheckedBusinessAreasRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllBusinessAreasChange = (e) => {
        setCheckedBusinessAreasSelectAll(e)

        setCheckedBusinessAreasRead(e)
        setCheckedBusinessAreasCreate(e)
        setCheckedBusinessAreasUpdate(e)
        setCheckedBusinessAreasDelete(e)
        setValidateChange(true)
    }
    /////tipo actividad
    const [checkedActivityTypeRead, setCheckedActivityTypeRead] = useState(false);
    const [checkedActivityTypeCreate, setCheckedActivityTypeCreate] = useState(false);
    const [checkedActivityTypeUpdate, setCheckedActivityTypeUpdate] = useState(false);
    const [checkedActivityTypeDelete, setCheckedActivityTypeDelete] = useState(false);
    const [checkedActivityTypeSelectAll, setCheckedActivityTypeSelectAll] = useState(false);

    const onActivityTypeReadChange = (e) => {
        if (checkedActivityTypeCreate === true || checkedActivityTypeUpdate === true || checkedActivityTypeDelete === true) {
            setCheckedActivityTypeRead(true)
        } else {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }
    const onActivityTypeCreateChange = (e) => {
        setCheckedActivityTypeCreate(e)
        if (checkedActivityTypeRead === false) {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }
    const onActivityTypeUpdateChange = (e) => {
        setCheckedActivityTypeUpdate(e)
        if (checkedActivityTypeRead === false) {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }
    const onActivityTypeDeleteChange = (e) => {
        setCheckedActivityTypeDelete(e)
        if (checkedActivityTypeRead === false) {
            setCheckedActivityTypeRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllActivityTypeChange = (e) => {
        setCheckedActivityTypeSelectAll(e)

        setCheckedActivityTypeRead(e)
        setCheckedActivityTypeCreate(e)
        setCheckedActivityTypeUpdate(e)
        setCheckedActivityTypeDelete(e)
        setValidateChange(true)
    }

    ////cargos
    const [checkedPositionRead, setCheckedPositionRead] = useState(false);
    const [checkedPositionCreate, setCheckedPositionCreate] = useState(false);
    const [checkedPositionUpdate, setCheckedPositionUpdate] = useState(false);
    const [checkedPositionDelete, setCheckedPositionDelete] = useState(false);
    const [checkedPositionSelectAll, setCheckedPositionSelectAll] = useState(false);

    const onPositionReadChange = (e) => {
        if (checkedPositionCreate === true || checkedPositionUpdate === true || checkedPositionDelete === true) {
            setCheckedPositionRead(true)
        } else {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }
    const onPositionCreateChange = (e) => {
        setCheckedPositionCreate(e)
        if (checkedPositionRead === false) {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }
    const onPositionUpdateChange = (e) => {
        setCheckedPositionUpdate(e)
        if (checkedPositionRead === false) {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }
    const onPositionDeleteChange = (e) => {
        setCheckedPositionDelete(e)
        if (checkedPositionRead === false) {
            setCheckedPositionRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllPositionChange = (e) => {
        setCheckedPositionSelectAll(e)

        setCheckedPositionRead(e)
        setCheckedPositionCreate(e)
        setCheckedPositionUpdate(e)
        setCheckedPositionDelete(e)
        setValidateChange(true)
    }

    ///roles
    const [checkedRolRead, setCheckedRolRead] = useState(false);
    const [checkedRolCreate, setCheckedRolCreate] = useState(false);
    const [checkedRolUpdate, setCheckedRolUpdate] = useState(false);
    const [checkedRolDelete, setCheckedRolDelete] = useState(false);
    const [checkedRolSelectAll, setCheckedRolSelectAll] = useState(false);

    const onRolReadChange = (e) => {
        if (checkedRolCreate === true || checkedRolUpdate === true || checkedRolDelete === true) {
            setCheckedRolRead(true)
        } else {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }
    const onRolCreateChange = (e) => {
        setCheckedRolCreate(e)
        if (checkedRolRead === false) {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }
    const onRolUpdateChange = (e) => {
        setCheckedRolUpdate(e)
        if (checkedRolRead === false) {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }
    const onRolDeleteChange = (e) => {
        setCheckedRolDelete(e)
        if (checkedRolRead === false) {
            setCheckedRolRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllRolChange = (e) => {
        setCheckedRolSelectAll(e)

        setCheckedRolRead(e)
        setCheckedRolCreate(e)
        setCheckedRolUpdate(e)
        setCheckedRolDelete(e)
        setValidateChange(true)
    }

    ///Calendario
    const [checkedCalendarRead, setCheckedCalendarRead] = useState(false);
    const [checkedCalendarManage, setCheckedCalendarManage] = useState(false);
    const [checkedCalendarSelectAll, setCheckedCalendarSelectAll] = useState(false);

    const onCalendarReadChange = (e) => {
        if (checkedCalendarManage === true) {
            setCheckedCalendarRead(true)
        } else {
            setCheckedCalendarRead(e)
        }
        setValidateChange(true)

    }
    const onCalendarManageChange = (e) => {
        setCheckedCalendarManage(e)
        if (checkedCalendarRead === false) {
            setCheckedCalendarRead(e)
        }
        setValidateChange(true)
    }

    const onSelectAllCalendarChange = (e) => {
        setCheckedCalendarSelectAll(e)

        setCheckedCalendarRead(e)
        setCheckedCalendarManage(e)
        setValidateChange(true)
    }

    ///Descargar Zip
    const [checkedDownloadZip, setCheckedDownloadZip] = useState(false);
    const [checkedDownloadZipAll, setCheckedDownloadZipSelectAll] = useState(false);

    const onDownloadZipChange = (e) => {

        setCheckedDownloadZip(e)

        setValidateChange(true)

    }
    const onSelectAllDownloadZipChange = (e) => {
        setCheckedDownloadZipSelectAll(e)

        setCheckedDownloadZip(e)
        setValidateChange(true)
    }

    ///Procesos de cierre
    const [checkedRunClose, setCheckedRunClose] = useState(false);
    const [checkedCloseOpen, setCheckedCloseOpen] = useState(false);
    const [checkedNotification, setCheckedNotification] = useState(false);
    const [checkedClosesSelectAll, setCheckedClosesSelectAll] = useState(false);

    const onRunCloseChange = (e) => {
        if (checkedCloseOpen === true) {
            setCheckedRunClose(true)
        } else {
            setCheckedRunClose(e)
        }
        setValidateChange(true)

    }
    const onCloseOpenChange = (e) => {
        setCheckedCloseOpen(e)
        if (checkedCloseOpen === false) {
            setCheckedRunClose(e)
        }
        setValidateChange(true)
    }

    const onNotificationChange = (e) => {
        setCheckedNotification(e)
        setValidateChange(true)
    }

    const onSelectAllClosesChange = (e) => {
        setCheckedClosesSelectAll(e)
        setCheckedRunClose(e)
        setCheckedCloseOpen(e)
        setCheckedNotification(e)
        setValidateChange(true)
    }

    ///Historico
    const [checkedHistorical, setCheckedHistorical] = useState(false);
    const [checkedHistoricalAll, setCheckedHistoricalSelectAll] = useState(false);

    const onHistoricalChange = (e) => {

        setCheckedHistorical(e)

        setValidateChange(true)

    }
    const onSelectAllHistoricalChange = (e) => {
        setCheckedHistoricalSelectAll(e)

        setCheckedHistorical(e)
        setValidateChange(true)
    }

    ///DashBoards
    const [checkedDBActivityLogger, setCheckedDBActivityLogger] = useState(false);
    const [checkedDBWorkload, setCheckedDBWorkload] = useState(false);
    const [checkedDBDetailTypeActivity, setCheckedDBDetailTypeActivity] = useState(false);
    const [checkedDBDetailTypeActivityUser, setCheckedDBDetailTypeActivityUser] = useState(false);
    const [checkedDBSelectAll, setCheckedDBSelectAll] = useState(false);

    const onDBActivityLoggerChange = (e) => {

        setCheckedDBActivityLogger(e)

        setValidateChange(true)

    }
    const onDBWorkloadChange = (e) => {
        setCheckedDBWorkload(e)

        setValidateChange(true)

    }
    const onDBDetailTypeActivityChange = (e) => {
        setCheckedDBDetailTypeActivity(e)

        setValidateChange(true)

    }
    const onDBDetailTypeActivityUserChange = (e) => {
        setCheckedDBDetailTypeActivityUser(e)

        setValidateChange(true)

    }

    const onSelectAllDBChange = (e) => {
        setCheckedDBSelectAll(e)

        setCheckedDBActivityLogger(e)
        setCheckedDBWorkload(e)
        setCheckedDBDetailTypeActivity(e)
        setCheckedDBDetailTypeActivityUser(e)
        setValidateChange(true)
    }

    /// Permisos adicionales para powerbi
    const [checkedDBReadPowerBi, setCheckedDBReadPowerBi] = useState(false);
    const [checkedDBPowerBiClient, setCheckedDBPowerBiClient] = useState(false);
    const [checkedDBPlusPowerBi, setCheckedDBPlusPowerBi] = useState(false);
    const [checkedDBPlusPowerBiSelectAll, setCheckedDBPlusPowerBiSelectAll] = useState(false);

    const onDBReadPowerBiChange = (e) => {
        setCheckedDBReadPowerBi(e)
        setValidateChange(true)
    }
    const onDBPowerBiClientChange = (e) => {
        setCheckedDBPowerBiClient(e)
        setValidateChange(true)
    }

    const onDBPlusPowerBiChange = (e) => {
        setCheckedDBPlusPowerBi(e)
        setValidateChange(true)
    }

    const onSelectAllDBPlusPowerBiChange = (e) => {
        setCheckedDBReadPowerBi(e)
        setCheckedDBPlusPowerBi(e)
        setCheckedDBPowerBiClient(e)
        setCheckedDBPlusPowerBiSelectAll(e)
        setValidateChange(true)
    }

    /// Novedades Otro funcionario 
    const [checkedNoveltyAnotherOfficial, setCheckedNoveltyAnotherOfficial] = useState(false);
    const [checkedNoveltyAnotherOfficialSelectAll, setCheckedNoveltyAnotherOfficialBSelectAll] = useState(false);

    const onNoveltyAnotherOfficialChange = (e) => {
        setCheckedNoveltyAnotherOfficial(e)
        setValidateChange(true)

    }

    const onSelectAllNoveltyAnotherOfficialChange = (e) => {
        setCheckedNoveltyAnotherOfficial(e)
        setCheckedNoveltyAnotherOfficialBSelectAll(e)
        setValidateChange(true)
    }

    ///Informe mensual individual
    const [checkedindividualMonthlyReportRead, setCheckedindividualMonthlyReportRead] = useState(false);
    const [checkedindividualMonthlyReportReadOtherOfficial, setCheckedindividualMonthlyReportReadOtherOfficial] = useState(false);
    const [checkedindividualMonthlyReportReopen, setCheckedindividualMonthlyReportReopen] = useState(false);
    const [checkedindividualMonthlyReportSelectAll, setCheckedindividualMonthlyReportSelectAll] = useState(false);

    const onindividualMonthlyReportReadChange = (e) => {
        setCheckedindividualMonthlyReportRead(e)
        setValidateChange(true)
    }

    const onindividualMonthlyReportReadOtherOfficialChange = (e) => {
        setCheckedindividualMonthlyReportRead(e)
        setCheckedindividualMonthlyReportReadOtherOfficial(e)
        setValidateChange(true)
    }

    const onindividualMonthlyReportReopenChange = (e) => {
        setCheckedindividualMonthlyReportRead(e)
        setCheckedindividualMonthlyReportReopen(e)
        setValidateChange(true)
    }

    const onSelectAllindividualMonthlyReportChange = (e) => {
        setCheckedindividualMonthlyReportSelectAll(e)
        setCheckedindividualMonthlyReportRead(e)
        setCheckedindividualMonthlyReportReadOtherOfficial(e)
        setCheckedindividualMonthlyReportReopen(e)
        setValidateChange(true)
    }

    ///Personas interesadas
    const [checkedInterestedPersonRead, setCheckedInterestedPersonRead] = useState(false);
    const [checkedInterestedPersonCreate, setCheckedInterestedPersonCreate] = useState(false);
    const [checkedInterestedPersonUpdate, setCheckedInterestedPersonUpdate] = useState(false);
    const [checkedInterestedPersonDelete, setCheckedInterestedPersonDelete] = useState(false);
    const [checkedInterestedPersonSelectAll, setCheckedInterestedPersonSelectAll] = useState(false);

    const onInterestedPersonReadChange = (e) => {
        if (checkedInterestedPersonCreate === true || checkedInterestedPersonUpdate === true || checkedInterestedPersonDelete === true) {
            setCheckedInterestedPersonRead(true)
        } else {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }
    const onInterestedPersonCreateChange = (e) => {
        setCheckedInterestedPersonCreate(e)
        if (checkedInterestedPersonRead === false) {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }
    const onInterestedPersonUpdateChange = (e) => {
        setCheckedInterestedPersonUpdate(e)
        if (checkedInterestedPersonRead === false) {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }
    const onInterestedPersonDeleteChange = (e) => {
        setCheckedInterestedPersonDelete(e)
        if (checkedInterestedPersonRead === false) {
            setCheckedInterestedPersonRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllInterestedPersonChange = (e) => {
        setCheckedInterestedPersonSelectAll(e)

        setCheckedInterestedPersonRead(e)
        setCheckedInterestedPersonCreate(e)
        setCheckedInterestedPersonUpdate(e)
        setCheckedInterestedPersonDelete(e)
        setValidateChange(true)
    }

    /// objetivos
    const [checkedObjectiveRead, setCheckedObjectiveRead] = useState(false);
    const [checkedObjectiveCreate, setCheckedObjectiveCreate] = useState(false);
    const [checkedObjectiveUpdate, setCheckedObjectiveUpdate] = useState(false);
    const [checkedObjectiveDelete, setCheckedObjectiveDelete] = useState(false);
    const [checkedObjectiveSelectAll, setCheckedObjectiveSelectAll] = useState(false);

    const onObjectiveReadChange = (e) => {
        if (checkedObjectiveCreate === true || checkedObjectiveUpdate === true || checkedObjectiveDelete === true) {
            setCheckedObjectiveRead(true)
        } else {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }
    const onObjectiveCreateChange = (e) => {
        setCheckedObjectiveCreate(e)
        if (checkedObjectiveRead === false) {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }
    const onObjectiveUpdateChange = (e) => {
        setCheckedObjectiveUpdate(e)
        if (checkedObjectiveRead === false) {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }
    const onObjectiveDeleteChange = (e) => {
        setCheckedObjectiveDelete(e)
        if (checkedObjectiveRead === false) {
            setCheckedObjectiveRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllObjectiveChange = (e) => {
        setCheckedObjectiveSelectAll(e)

        setCheckedObjectiveRead(e)
        setCheckedObjectiveCreate(e)
        setCheckedObjectiveUpdate(e)
        setCheckedObjectiveDelete(e)
        setValidateChange(true)
    }

    ///Contratos
    const [checkedContractRead, setCheckedContractRead] = useState(false);
    const [checkedContractCreate, setCheckedContractCreate] = useState(false);
    const [checkedContractUpdate, setCheckedContractUpdate] = useState(false);
    const [checkedContractDelete, setCheckedContractDelete] = useState(false);
    const [checkedContractSelectAll, setCheckedContractSelectAll] = useState(false);

    const onContractReadChange = (e) => {
        if (checkedContractCreate === true || checkedContractUpdate === true || checkedContractDelete === true) {
            setCheckedContractRead(true)
        } else {
            setCheckedContractRead(e)
        }
        setValidateChange(true)

    }
    const onContractCreateChange = (e) => {
        setCheckedContractCreate(e)
        if (checkedContractRead === false) {
            setCheckedContractRead(e)
        }
        setValidateChange(true)

    }
    const onContractUpdateChange = (e) => {
        setCheckedContractUpdate(e)
        if (checkedContractRead === false) {
            setCheckedContractRead(e)
        }
        setValidateChange(true)

    }
    const onContractDeleteChange = (e) => {
        setCheckedContractDelete(e)
        if (checkedContractRead === false) {
            setCheckedContractRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllContractChange = (e) => {
        setCheckedContractSelectAll(e)

        setCheckedContractRead(e)
        setCheckedContractCreate(e)
        setCheckedContractUpdate(e)
        setCheckedContractDelete(e)
        setValidateChange(true)
    }

    ///entregables
    const [checkedDeliverableRead, setCheckedDeliverableRead] = useState(false);
    const [checkedDeliverableCreate, setCheckedDeliverableCreate] = useState(false);
    const [checkedDeliverableUpdate, setCheckedDeliverableUpdate] = useState(false);
    const [checkedDeliverableDelete, setCheckedDeliverableDelete] = useState(false);
    const [checkedDeliverableSelectAll, setCheckedDeliverableSelectAll] = useState(false);

    const onDeliverableReadChange = (e) => {
        if (checkedDeliverableCreate === true || checkedDeliverableUpdate === true || checkedDeliverableDelete === true) {
            setCheckedDeliverableRead(true)
        } else {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }
    const onDeliverableCreateChange = (e) => {
        setCheckedDeliverableCreate(e)
        if (checkedDeliverableRead === false) {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }
    const onDeliverableUpdateChange = (e) => {
        setCheckedDeliverableUpdate(e)
        if (checkedDeliverableRead === false) {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }
    const onDeliverableDeleteChange = (e) => {
        setCheckedDeliverableDelete(e)
        if (checkedDeliverableRead === false) {
            setCheckedDeliverableRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllDeliverableChange = (e) => {
        setCheckedDeliverableSelectAll(e)

        setCheckedDeliverableRead(e)
        setCheckedDeliverableCreate(e)
        setCheckedDeliverableUpdate(e)
        setCheckedDeliverableDelete(e)
        setValidateChange(true)
    }

    ///servicios
    const [checkedServiceRead, setCheckedServiceRead] = useState(false);
    const [checkedServiceCreate, setCheckedServiceCreate] = useState(false);
    const [checkedServiceUpdate, setCheckedServiceUpdate] = useState(false);
    const [checkedServiceDelete, setCheckedServiceDelete] = useState(false);
    const [checkedServiceSelectAll, setCheckedServiceSelectAll] = useState(false);

    const onServiceReadChange = (e) => {
        if (checkedServiceCreate === true || checkedServiceUpdate === true || checkedServiceDelete === true) {
            setCheckedServiceRead(true)
        } else {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }
    const onServiceCreateChange = (e) => {
        setCheckedServiceCreate(e)
        if (checkedServiceRead === false) {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }
    const onServiceUpdateChange = (e) => {
        setCheckedServiceUpdate(e)
        if (checkedServiceRead === false) {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }
    const onServiceDeleteChange = (e) => {
        setCheckedServiceDelete(e)
        if (checkedServiceRead === false) {
            setCheckedServiceRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllServiceChange = (e) => {
        setCheckedServiceSelectAll(e)

        setCheckedServiceRead(e)
        setCheckedServiceCreate(e)
        setCheckedServiceUpdate(e)
        setCheckedServiceDelete(e)
        setValidateChange(true)
    }

    ///Equipos de Trabajo

    const [checkedWorkTeamRead, setCheckedWorkTeamRead] = useState(false);
    const [checkedWorkTeamCreate, setCheckedWorkTeamCreate] = useState(false);
    const [checkedWorkTeamUpdate, setCheckedWorkTeamUpdate] = useState(false);
    const [checkedWorkTeamDelete, setCheckedWorkTeamDelete] = useState(false);
    const [checkedWorkTeamSelectAll, setCheckedWorkTeamSelectAll] = useState(false);

    const onWorkTeamReadChange = (e) => {

        if (checkedWorkTeamCreate === true || checkedWorkTeamUpdate === true || checkedWorkTeamDelete === true) {
            setCheckedWorkTeamRead(true)
        } else {
            setCheckedWorkTeamRead(e)
        }

        setValidateChange(true)

    }

    const onWorkTeamCreateChange = (e) => {
        setCheckedWorkTeamCreate(e)
        if (checkedWorkTeamRead === false) {
            setCheckedWorkTeamRead(e)
        }
        setValidateChange(true)

    }

    const onWorkTeamUpdateChange = (e) => {
        setCheckedWorkTeamUpdate(e)
        if (checkedWorkTeamRead === false) {
            setCheckedWorkTeamRead(e)
        }
        setValidateChange(true)

    }

    const onWorkTeamDeleteChange = (e) => {
        setCheckedWorkTeamDelete(e)
        if (checkedWorkTeamRead === false) {
            setCheckedWorkTeamRead(e)
        }
        setValidateChange(true)

    }

    const onSelectAllWorkTeamChange = (e) => {
        setCheckedWorkTeamSelectAll(e)
        setCheckedWorkTeamRead(e)
        setCheckedWorkTeamCreate(e)
        setCheckedWorkTeamUpdate(e)
        setCheckedWorkTeamDelete(e)
        setValidateChange(true)
    }

    ///Tipo de proyectos
    const [checkedTypeProjectRead, setCheckedTypeProjectRead] = useState(false);
    const [checkedTypeProjectCreate, setCheckedTypeProjectCreate] = useState(false);
    const [checkedTypeProjectUpdate, setCheckedTypeProjectUpdate] = useState(false);
    const [checkedTypeProjectDelete, setCheckedTypeProjectDelete] = useState(false);
    const [checkedTypeProjectSelectAll, setCheckedTypeProjectSelectAll] = useState(false);

    const onTypeProjectReadChange = (e) => {

        if (checkedTypeProjectCreate === true || checkedTypeProjectUpdate === true || checkedTypeProjectDelete === true) {
            setCheckedTypeProjectRead(true)
        } else {
            setCheckedTypeProjectRead(e)
        }

        setValidateChange(true)
    }

    const onTypeProjectCreateChange = (e) => {
        setCheckedTypeProjectCreate(e)
        if (checkedTypeProjectRead === false) {
            setCheckedTypeProjectRead(e)
        }
        setValidateChange(true)

    }

    const onTypeProjectUpdateChange = (e) => {
        setCheckedTypeProjectUpdate(e)
        if (checkedTypeProjectRead === false) {
            setCheckedTypeProjectRead(e)
        }
        setValidateChange(true)
    }

    const onTypeProjectDeleteChange = (e) => {
        setCheckedTypeProjectDelete(e)
        if (checkedTypeProjectRead === false) {
            setCheckedTypeProjectRead(e)
        }
        setValidateChange(true)
    }

    const onSelectAllTypeProjectChange = (e) => {
        setCheckedTypeProjectSelectAll(e)
        setCheckedTypeProjectRead(e)
        setCheckedTypeProjectCreate(e)
        setCheckedTypeProjectUpdate(e)
        setCheckedTypeProjectDelete(e)
        setValidateChange(true)
    }

    //Organigrama

    const [checkedOrgRead, setCheckedOrgRead] = useState(false);
    const [checkedOrgSelectAll, setCheckedOrgSelectAll] = useState(false);

    const onOrgChange = (e) => {

        setCheckedOrgRead(e)

        setValidateChange(true)

    }
    const onSelectAllOrgChange = (e) => {
        setCheckedOrgSelectAll(e)

        setCheckedOrgRead(e)
        setValidateChange(true)
    }

    //detalle de pendinetes
    const [checkedDetailPendingRead, setCheckedDetailPendingRead] = useState(false);
    const [checkedDetailPendingSelectAll, setCheckedDetailPendingSelectAll] = useState(false);

    const onDetailPendingChange = (e) => {
        setCheckedDetailPendingRead(e)
        setValidateChange(true)
    }

    const onSelectAllDetailPendingChange = (e) => {
        setCheckedDetailPendingSelectAll(e)
        setCheckedDetailPendingRead(e)
        setValidateChange(true)
    }

    //Consultar usuarios de clientes 
    const [checkedUserClientRead, setCheckedUserClientRead] = useState(false);
    const [checkedUserClientSelectAll, setCheckedUserClientSelectAll] = useState(false);

    const onUserClientChange = (e) => {
        setCheckedUserClientRead(e)
        setValidateChange(true)
    }

    const onSelectAllUserClientChange = (e) => {
        setCheckedUserClientSelectAll(e)
        setCheckedUserClientRead(e)
        setValidateChange(true)
    }

    ///Proveedores
    const [checkedSupplierRead, setCheckedSupplierRead] = useState(false);
    const [checkedSupplierCreate, setCheckedSupplierCreate] = useState(false);
    const [checkedSupplierUpdate, setCheckedSupplierUpdate] = useState(false);
    const [checkedSupplierDelete, setCheckedSupplierDelete] = useState(false);
    const [checkedSupplierSelectAll, setCheckedSupplierSelectAll] = useState(false);

    const onSupplierReadChange = (e) => {

        if (checkedSupplierCreate === true || checkedSupplierUpdate === true || checkedSupplierDelete === true) {
            setCheckedSupplierRead(true)
        } else {
            setCheckedSupplierRead(e)
        }

        setValidateChange(true)
    }

    const onSupplierCreateChange = (e) => {
        setCheckedSupplierCreate(e)
        if (checkedSupplierRead === false) {
            setCheckedSupplierRead(e)
        }
        setValidateChange(true)

    }

    const onSupplierUpdateChange = (e) => {
        setCheckedSupplierUpdate(e)
        if (checkedSupplierRead === false) {
            setCheckedSupplierRead(e)
        }
        setValidateChange(true)
    }

    const onSupplierDeleteChange = (e) => {
        setCheckedSupplierDelete(e)
        if (checkedSupplierRead === false) {
            setCheckedSupplierRead(e)
        }
        setValidateChange(true)
    }

    const onSelectAllSupplierChange = (e) => {
        setCheckedSupplierSelectAll(e)
        setCheckedSupplierRead(e)
        setCheckedSupplierCreate(e)
        setCheckedSupplierUpdate(e)
        setCheckedSupplierDelete(e)
        setValidateChange(true)
    }
    ///Filtros Proveedores
    const [checkedFilterSupplierNDB1, setCheckedFilterSupplierNDB1] = useState(false);
    const [checkedFilterSupplierNDB2, setCheckedFilterSupplierNDB2] = useState(false);
    const [checkedFilterSupplierExportData, setCheckedFilterSupplierExportData] = useState(false);
    const [checkedFilterSupplierSelectAll, setCheckedFilterSupplierSelectAll] = useState(false);

    const onFilterSupplierNDB1Change = (e) => {

        if (checkedFilterSupplierNDB2 === true || checkedFilterSupplierExportData === true) {
            setCheckedFilterSupplierNDB1(true)
        } else {
            setCheckedFilterSupplierNDB1(e)
        }

        setValidateChange(true)
    }

    const onFilterSupplierNDB2Change = (e) => {
        setCheckedFilterSupplierNDB2(e)
        setValidateChange(true)
    }

    const onFilterSupplierExportDataChange = (e) => {
        setCheckedFilterSupplierExportData(e)
        setValidateChange(true)
    }


    const onSelectAllFilterSupplierChange = (e) => {
        setCheckedFilterSupplierSelectAll(e)
        setCheckedFilterSupplierNDB1(e)
        setCheckedFilterSupplierNDB2(e)
        setCheckedFilterSupplierExportData(e)
        setValidateChange(true)
    }

    /// Permisos para powerbi con filtro de proveedores
    const [checkedDBReadPowerBiFilterSupplier, setCheckedDBReadPowerBiFilterSupplier] = useState(false);
    const [checkedDBPowerBiFilterSupplierClient, setCheckedDBPowerBiFilterSupplierClient] = useState(false);
    const [checkedDBPlusPowerBiFilterSupplier, setCheckedDBPlusPowerBiFilterSupplier] = useState(false);
    const [checkedDBPlusPowerBiFilterSupplierSelectAll, setCheckedDBPlusPowerBiFilterSupplierSelectAll] = useState(false);

    const onDBReadPowerBiFilterSupplierChange = (e) => {
        setCheckedDBReadPowerBiFilterSupplier(e)
        setValidateChange(true)
    }
    const onDBPowerBiFilterSupplierClientChange = (e) => {
        setCheckedDBPowerBiFilterSupplierClient(e)
        setValidateChange(true)
    }

    const onDBPlusPowerBiFilterSupplierChange = (e) => {
        setCheckedDBPlusPowerBiFilterSupplier(e)
        setValidateChange(true)
    }

    const onSelectAllDBPlusPowerBiFilterSupplierChange = (e) => {
        setCheckedDBReadPowerBiFilterSupplier(e)
        setCheckedDBPlusPowerBiFilterSupplier(e)
        setCheckedDBPowerBiFilterSupplierClient(e)
        setCheckedDBPlusPowerBiFilterSupplierSelectAll(e)
        setValidateChange(true)
    }

    /// Permisos para el menú de cargar imagen corporativa
    const [checkedBDReadUploadImage, setCheckedBDReadUploadImage] = useState(false);
    const [checkedBDUploadImageSelectAll, setCheckedBDUploadImageSelectAll] = useState(false);

    const onChangeCheckedReadUploadImage = (e) => {
        setCheckedBDReadUploadImage(e)
        setValidateChange(true)
    }
    
    const onChangeCheckedUploadImageSelectAll = (e) => {
        setCheckedBDReadUploadImage(e)
        setCheckedBDUploadImageSelectAll(e)
        setValidateChange(true)
    }
    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registrar este rol?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            rolProp={rol}
                            usuarioProp={user}
                            checkedUserReadProp={checkedUserRead}
                            checkedUserCreateProp={checkedUserCreate}
                            checkedUserUpdateProp={checkedUserUpdate}
                            checkedUserDeleteProp={checkedUserDelete}
                            checkedActivityReadProp={checkedActivityRead}
                            checkedActivityCreateProp={checkedActivityCreate}
                            checkedActivityUpdateProp={checkedActivityUpdate}
                            checkedActivityDeleteProp={checkedActivityDelete}
                            checkedExportDataReadProp={checkedExportDataRead}
                            checkedExportDataAllProp={checkedExportDataAll}
                            checkedClientReadProp={checkedClientRead}
                            checkedClientCreateProp={checkedClientCreate}
                            checkedClientUpdateProp={checkedClientUpdate}
                            checkedClientDeleteProp={checkedClientDelete}
                            checkedProjectReadProp={checkedProjectRead}
                            checkedProjectCreateProp={checkedProjectCreate}
                            checkedProjectUpdateProp={checkedProjectUpdate}
                            checkedProjectDeleteProp={checkedProjectDelete}
                            checkedAplicationReadProp={checkedAplicationRead}
                            checkedAplicationCreateProp={checkedAplicationCreate}
                            checkedAplicationUpdateProp={checkedAplicationUpdate}
                            checkedAplicationDeleteProp={checkedAplicationDelete}
                            checkedBusinessAreasReadProp={checkedBusinessAreasRead}
                            checkedBusinessAreasCreateProp={checkedBusinessAreasCreate}
                            checkedBusinessAreasUpdateProp={checkedBusinessAreasUpdate}
                            checkedBusinessAreasDeleteProp={checkedBusinessAreasDelete}
                            checkedActivityTypeReadProp={checkedActivityTypeRead}
                            checkedActivityTypeCreateProp={checkedActivityTypeCreate}
                            checkedActivityTypeUpdateProp={checkedActivityTypeUpdate}
                            checkedActivityTypeDeleteProp={checkedActivityTypeDelete}
                            checkedPositionReadProp={checkedPositionRead}
                            checkedPositionCreateProp={checkedPositionCreate}
                            checkedPositionUpdateProp={checkedPositionUpdate}
                            checkedPositionDeleteProp={checkedPositionDelete}
                            checkedRolReadProp={checkedRolRead}
                            checkedRolCreateProp={checkedRolCreate}
                            checkedRolUpdateProp={checkedRolUpdate}
                            checkedRolDeleteProp={checkedRolDelete}
                            checkedCalendarReadProp={checkedCalendarRead}
                            checkedCalendarManageProp={checkedCalendarManage}
                            checkedDownloadZipProp={checkedDownloadZip}
                            checkedRunCloseProp={checkedRunClose}
                            checkedCloseOpenProp={checkedCloseOpen}
                            checkedNotificationProp={checkedNotification}
                            checkedHistoricalProp={checkedHistorical}
                            checkedDBActivityLoggerProp={checkedDBActivityLogger}
                            checkedDBWorkloadProp={checkedDBWorkload}
                            checkedDBDetailTypeActivityProp={checkedDBDetailTypeActivity}
                            checkedDBDetailTypeActivityUserProp={checkedDBDetailTypeActivityUser}
                            checkedDBReadPowerBiProp={checkedDBReadPowerBi}
                            checkedDBPowerBiClientProp={checkedDBPowerBiClient}
                            checkedDBPlusPowerBiProp={checkedDBPlusPowerBi}
                            checkedNoveltyAnotherOfficialProp={checkedNoveltyAnotherOfficial}
                            checkedindividualMonthlyReportReadProp={checkedindividualMonthlyReportRead}
                            checkedindividualMonthlyReportReopenProp={checkedindividualMonthlyReportReopen}
                            checkedindividualMonthlyReportReadOtherOfficialProp={checkedindividualMonthlyReportReadOtherOfficial}
                            checkedInterestedPersonReadProp={checkedInterestedPersonRead}
                            checkedInterestedPersonCreateProp={checkedInterestedPersonCreate}
                            checkedInterestedPersonUpdateProp={checkedInterestedPersonUpdate}
                            checkedInterestedPersonDeleteProp={checkedInterestedPersonDelete}
                            checkedObjectiveReadProp={checkedObjectiveRead}
                            checkedObjectiveCreateProp={checkedObjectiveCreate}
                            checkedObjectiveUpdateProp={checkedObjectiveUpdate}
                            checkedObjectiveDeleteProp={checkedObjectiveDelete}
                            checkedContractReadProp={checkedContractRead}
                            checkedContractCreateProp={checkedContractCreate}
                            checkedContractUpdateProp={checkedContractUpdate}
                            checkedContractDeleteProp={checkedContractDelete}
                            checkedDeliverableReadProp={checkedDeliverableRead}
                            checkedDeliverableCreateProp={checkedDeliverableCreate}
                            checkedDeliverableUpdateProp={checkedDeliverableUpdate}
                            checkedDeliverableDeleteProp={checkedDeliverableDelete}
                            checkedServiceReadProp={checkedServiceRead}
                            checkedServiceCreateProp={checkedServiceCreate}
                            checkedServiceUpdateProp={checkedServiceUpdate}
                            checkedServiceDeleteProp={checkedServiceDelete}
                            checkedWorkTeamReadProp={checkedWorkTeamRead}
                            checkedWorkTeamCreateProp={checkedWorkTeamCreate}
                            checkedWorkTeamUpdateProp={checkedWorkTeamUpdate}
                            checkedWorkTeamDeleteProp={checkedWorkTeamDelete}
                            checkedTypeProjectReadProp={checkedTypeProjectRead}
                            checkedTypeProjectCreateProp={checkedTypeProjectCreate}
                            checkedTypeProjectUpdateProp={checkedTypeProjectUpdate}
                            checkedTypeProjectDeleteProp={checkedTypeProjectDelete}
                            checkedOrgReadProp={checkedOrgRead}
                            checkedDetailPendingReadProp={checkedDetailPendingRead}
                            checkedUserClientReadProp={checkedUserClientRead}
                            checkedSupplierReadProp={checkedSupplierRead}
                            checkedSupplierCreateProp={checkedSupplierCreate}
                            checkedSupplierUpdateProp={checkedSupplierUpdate}
                            checkedSupplierDeleteProp={checkedSupplierDelete}
                            checkedFilterSupplierNDB1Prop={checkedFilterSupplierNDB1}
                            checkedFilterSupplierNDB2Prop={checkedFilterSupplierNDB2}
                            checkedFilterSupplierExportDataProp={checkedFilterSupplierExportData}
                            checkedDBReadPowerBiFilterSupplierProp={checkedDBReadPowerBiFilterSupplier}
                            checkedDBPowerBiFilterSupplierClientProp={checkedDBPowerBiFilterSupplierClient}
                            checkedDBPlusPowerBiFilterSupplierProp={checkedDBPlusPowerBiFilterSupplier}
                            checkedBDReadUploadImageProp={checkedBDReadUploadImage}
                        />
                        :
                        ""
                }

            </div>
        );
    }

    //validaciones

    var rolError = false
    var mensajeRol = ''
    if (rol.length < 3 && rol !== '') {
        rolError = true
        mensajeRol = 'El nombre del rol debe ser mayor a 3 dígitos'
    } else if (rol.length > 100) {
        rolError = true
        mensajeRol = 'El nombre del rol debe ser menor a 100 dígitos'
    }

    const [errorDuplicated, setErrorDuplicated] = useState(false)

    //Validar que no se cree un rol igual a uno creado
    useEffect(() => {
        if (inputRol.rol !== '') {
            let arrItemsDuplicated = []
            servicioProp.forEach(element => {
                let rolNameCreated = removeAccents(element.nombre)
                let newInputRol = removeAccents(inputRol.rol)
                if (newInputRol.toUpperCase() === rolNameCreated.toUpperCase()) {
                    arrItemsDuplicated.push(element)
                }
            })

            if (arrItemsDuplicated.length !== 0) {
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Rol pre-existente' });
                setErrorDuplicated(true)
            } else {
                setErrorDuplicated(false)
            }
        }
    }, [inputRol, servicioProp]); // eslint-disable-line react-hooks/exhaustive-deps

    var permisoRegistrar = false
    if (rolError === false && rol !== '' && errorDuplicated === false) {
        if (checkedUserRead !== false || checkedUserCreate !== false || checkedUserUpdate !== false || checkedUserDelete !== false ||
            checkedActivityRead !== false || checkedActivityCreate !== false || checkedActivityUpdate !== false || checkedActivityDelete !== false ||
            checkedExportDataRead !== false || checkedExportDataAll !== false ||
            checkedClientRead !== false || checkedClientCreate !== false || checkedClientUpdate !== false || checkedClientDelete !== false ||
            checkedProjectRead !== false || checkedProjectCreate !== false || checkedProjectUpdate !== false || checkedProjectDelete !== false ||
            checkedAplicationRead !== false || checkedAplicationCreate !== false || checkedAplicationUpdate !== false || checkedAplicationDelete !== false ||
            checkedBusinessAreasRead !== false || checkedBusinessAreasCreate !== false || checkedBusinessAreasUpdate !== false || checkedBusinessAreasDelete !== false ||
            checkedActivityTypeRead !== false || checkedActivityTypeCreate !== false || checkedActivityTypeUpdate !== false || checkedActivityTypeDelete !== false ||
            checkedPositionRead !== false || checkedPositionCreate !== false || checkedPositionUpdate !== false || checkedPositionDelete !== false ||
            checkedRolRead !== false || checkedRolCreate !== false || checkedRolUpdate !== false || checkedRolDelete !== false ||
            checkedCalendarRead !== false || checkedCalendarManage !== false ||
            checkedDownloadZip !== false || checkedDownloadZipAll !== false ||
            checkedRunClose !== false || checkedCloseOpen !== false || checkedNotification !== false ||
            checkedHistorical !== false || checkedHistoricalAll !== false ||
            checkedDBActivityLogger !== false || checkedDBWorkload !== false || checkedDBDetailTypeActivity !== false || checkedDBDetailTypeActivityUser !== false ||
            checkedDBReadPowerBi !== false ||
            checkedDBPowerBiClient !== false ||
            checkedDBPlusPowerBi !== false ||
            checkedNoveltyAnotherOfficial !== false ||
            checkedindividualMonthlyReportRead !== false ||
            checkedindividualMonthlyReportReadOtherOfficial !== false ||
            checkedindividualMonthlyReportReopen !== false ||
            checkedInterestedPersonRead !== false ||
            checkedInterestedPersonCreate !== false ||
            checkedInterestedPersonUpdate !== false ||
            checkedInterestedPersonDelete !== false ||
            checkedObjectiveRead !== false ||
            checkedObjectiveCreate !== false ||
            checkedObjectiveUpdate !== false ||
            checkedObjectiveDelete !== false ||
            checkedContractRead !== false ||
            checkedContractCreate !== false ||
            checkedContractUpdate !== false ||
            checkedContractDelete !== false ||
            checkedDeliverableRead !== false ||
            checkedDeliverableCreate !== false ||
            checkedDeliverableUpdate !== false ||
            checkedDeliverableDelete !== false ||
            checkedServiceRead !== false ||
            checkedServiceCreate !== false ||
            checkedServiceUpdate !== false ||
            checkedServiceDelete !== false ||
            checkedWorkTeamRead !== false ||
            checkedWorkTeamCreate !== false ||
            checkedWorkTeamUpdate !== false ||
            checkedWorkTeamDelete !== false ||
            checkedTypeProjectRead !== false ||
            checkedTypeProjectCreate !== false ||
            checkedTypeProjectUpdate !== false ||
            checkedTypeProjectDelete !== false ||
            checkedOrgRead !== false ||
            checkedDetailPendingRead !== false ||
            checkedUserClientRead !== false ||
            checkedSupplierRead !== false ||
            checkedSupplierCreate !== false ||
            checkedSupplierUpdate !== false ||
            checkedSupplierDelete !== false ||
            checkedFilterSupplierNDB1 !== false ||
            checkedFilterSupplierNDB2 !== false ||
            checkedFilterSupplierExportData !== false ||
            checkedDBReadPowerBiFilterSupplier !== false ||
            checkedDBPowerBiFilterSupplierClient !== false ||
            checkedDBPlusPowerBiFilterSupplier !== false ||
            checkedBDReadUploadImage !==false
        ) {

            permisoRegistrar = true

        } else {
            permisoRegistrar = false
        }

    }

    const dt = useRef(null);

    const User = 'Parametrizar usuario'
    const UserRead = <Checkbox checked={checkedUserRead} onChange={e => onUserReadChange(e.checked)} />
    const UserCreate = <Checkbox checked={checkedUserCreate} onChange={e => onUserCreateChange(e.checked)} />
    const UserUpdate = <Checkbox checked={checkedUserUpdate} onChange={e => onUserUpdateChange(e.checked)} />
    const UserDelete = <Checkbox checked={checkedUserDelete} onChange={e => onUserDeleteChange(e.checked)} />
    const UserSelectAll = <Checkbox checked={checkedUserSelectAll} onChange={e => onSelectAllUserChange(e.checked)} />

    const Activity = 'Parametrizar actividades'
    const ActivityRead = <Checkbox checked={checkedActivityRead} onChange={e => onActivityReadChange(e.checked)} />
    const ActivityCreate = <Checkbox checked={checkedActivityCreate} onChange={e => onActivityCreateChange(e.checked)} />
    const ActivityUpdate = <Checkbox checked={checkedActivityUpdate} onChange={e => onActivityUpdateChange(e.checked)} />
    const ActivityDelete = <Checkbox checked={checkedActivityDelete} onChange={e => onActivityDeleteChange(e.checked)} />
    const ActivitySelectAll = <Checkbox checked={checkedActivitySelectAll} onChange={e => onSelectAllActivityChange(e.checked)} />

    const ExportData = 'Exportar datos'
    const ExportDataRead = <Checkbox checked={checkedExportDataRead} onChange={e => onExportDataReadChange(e.checked)} />
    const ExportDataAll = <Checkbox checked={checkedExportDataAll} onChange={e => onExportDataAllChange(e.checked)} tooltip="Exportar para todos" />
    const ExportDataSelectAll = <Checkbox checked={checkedExportDataSelectAll} onChange={e => onSelectAllExportDataChange(e.checked)} />

    const Client = 'Parametrizar cliente'
    const ClientRead = <Checkbox checked={checkedClientRead} onChange={e => onClientReadChange(e.checked)} />
    const ClientCreate = <Checkbox checked={checkedClientCreate} onChange={e => onClientCreateChange(e.checked)} />
    const ClientUpdate = <Checkbox checked={checkedClientUpdate} onChange={e => onClientUpdateChange(e.checked)} />
    const ClientDelete = <Checkbox checked={checkedClientDelete} onChange={e => onClientDeleteChange(e.checked)} />
    const ClientSelectAll = <Checkbox checked={checkedClientSelectAll} onChange={e => onSelectAllClientChange(e.checked)} />

    const Project = 'Parametrizar proyecto'
    const ProjectRead = <Checkbox checked={checkedProjectRead} onChange={e => onProjectReadChange(e.checked)} />
    const ProjectCreate = <Checkbox checked={checkedProjectCreate} onChange={e => onProjectCreateChange(e.checked)} />
    const ProjectUpdate = <Checkbox checked={checkedProjectUpdate} onChange={e => onProjectUpdateChange(e.checked)} />
    const ProjectDelete = <Checkbox checked={checkedProjectDelete} onChange={e => onProjectDeleteChange(e.checked)} />
    const ProjectSelectAll = <Checkbox checked={checkedProjectSelectAll} onChange={e => onSelectAllProjectChange(e.checked)} />

    const Aplication = 'Parametrizar aplicación'
    const AplicationRead = <Checkbox checked={checkedAplicationRead} onChange={e => onAplicationReadChange(e.checked)} />
    const AplicationCreate = <Checkbox checked={checkedAplicationCreate} onChange={e => onAplicationCreateChange(e.checked)} />
    const AplicationUpdate = <Checkbox checked={checkedAplicationUpdate} onChange={e => onAplicationUpdateChange(e.checked)} />
    const AplicationDelete = <Checkbox checked={checkedAplicationDelete} onChange={e => onAplicationDeleteChange(e.checked)} />
    const AplicationSelectAll = <Checkbox checked={checkedAplicationSelectAll} onChange={e => onSelectAllAplicationChange(e.checked)} />

    const BusinessAreas = 'Parametrizar área de negocio'
    const BusinessAreasRead = <Checkbox checked={checkedBusinessAreasRead} onChange={e => onBusinessAreasReadChange(e.checked)} />
    const BusinessAreasCreate = <Checkbox checked={checkedBusinessAreasCreate} onChange={e => onBusinessAreasCreateChange(e.checked)} />
    const BusinessAreasUpdate = <Checkbox checked={checkedBusinessAreasUpdate} onChange={e => onBusinessAreasUpdateChange(e.checked)} />
    const BusinessAreasDelete = <Checkbox checked={checkedBusinessAreasDelete} onChange={e => onBusinessAreasDeleteChange(e.checked)} />
    const BusinessAreasSelectAll = <Checkbox checked={checkedBusinessAreasSelectAll} onChange={e => onSelectAllBusinessAreasChange(e.checked)} />

    const ActivityType = 'Parametrizar tipo actividad'
    const ActivityTypeRead = <Checkbox checked={checkedActivityTypeRead} onChange={e => onActivityTypeReadChange(e.checked)} />
    const ActivityTypeCreate = <Checkbox checked={checkedActivityTypeCreate} onChange={e => onActivityTypeCreateChange(e.checked)} />
    const ActivityTypeUpdate = <Checkbox checked={checkedActivityTypeUpdate} onChange={e => onActivityTypeUpdateChange(e.checked)} />
    const ActivityTypeDelete = <Checkbox checked={checkedActivityTypeDelete} onChange={e => onActivityTypeDeleteChange(e.checked)} />
    const ActivityTypeSelectAll = <Checkbox checked={checkedActivityTypeSelectAll} onChange={e => onSelectAllActivityTypeChange(e.checked)} />

    const Position = 'Parametrizar cargo'
    const PositionRead = <Checkbox checked={checkedPositionRead} onChange={e => onPositionReadChange(e.checked)} />
    const PositionCreate = <Checkbox checked={checkedPositionCreate} onChange={e => onPositionCreateChange(e.checked)} />
    const PositionUpdate = <Checkbox checked={checkedPositionUpdate} onChange={e => onPositionUpdateChange(e.checked)} />
    const PositionDelete = <Checkbox checked={checkedPositionDelete} onChange={e => onPositionDeleteChange(e.checked)} />
    const PositionSelectAll = <Checkbox checked={checkedPositionSelectAll} onChange={e => onSelectAllPositionChange(e.checked)} />

    const Rol = 'Parametrizar rol'
    const RolRead = <Checkbox checked={checkedRolRead} onChange={e => onRolReadChange(e.checked)} />
    const RolCreate = <Checkbox checked={checkedRolCreate} onChange={e => onRolCreateChange(e.checked)} />
    const RolUpdate = <Checkbox checked={checkedRolUpdate} onChange={e => onRolUpdateChange(e.checked)} />
    const RolDelete = <Checkbox checked={checkedRolDelete} onChange={e => onRolDeleteChange(e.checked)} />
    const RolSelectAll = <Checkbox checked={checkedRolSelectAll} onChange={e => onSelectAllRolChange(e.checked)} />

    const Calendar = 'Parametrizar calendario'
    const CalendarRead = <Checkbox checked={checkedCalendarRead} onChange={e => onCalendarReadChange(e.checked)} />
    const CalendarManage = <Checkbox checked={checkedCalendarManage} onChange={e => onCalendarManageChange(e.checked)} tooltip="Parametrizar" />
    const CalendarSelectAll = <Checkbox checked={checkedCalendarSelectAll} onChange={e => onSelectAllCalendarChange(e.checked)} />

    const DownloadZip = 'Descargar Zip'
    const DownloadZipRead = <Checkbox checked={checkedDownloadZip} onChange={e => onDownloadZipChange(e.checked)} tooltip="Descargar Zip" />
    const DownloadZipSelectAll = <Checkbox checked={checkedDownloadZipAll} onChange={e => onSelectAllDownloadZipChange(e.checked)} />

    const Closes = 'Procesos de Cierre'
    const RunClose = <Checkbox checked={checkedRunClose} onChange={e => onRunCloseChange(e.checked)} tooltip="Cierre Definitivo" />
    const CloseOpen = <Checkbox checked={checkedCloseOpen} onChange={e => onCloseOpenChange(e.checked)} tooltip="Abrir Mes" />
    const Notification = <Checkbox checked={checkedNotification} onChange={e => onNotificationChange(e.checked)} tooltip="Quien lo tenga marcado recibirá mensajes semanales y mensuales" />
    const ClosesSelectAll = <Checkbox checked={checkedClosesSelectAll} onChange={e => onSelectAllClosesChange(e.checked)} />

    const Historical = 'Histórico'
    const HistoricalRead = <Checkbox checked={checkedHistorical} onChange={e => onHistoricalChange(e.checked)} />
    const HistoricalSelectAll = <Checkbox checked={checkedHistoricalAll} onChange={e => onSelectAllHistoricalChange(e.checked)} />

    const DashBoards = 'DashBoards'
    const DBActivityLogger = <Checkbox checked={checkedDBActivityLogger} onChange={e => onDBActivityLoggerChange(e.checked)} tooltip="Registrador de actividades" />
    const DBWorkload = <Checkbox checked={checkedDBWorkload} onChange={e => onDBWorkloadChange(e.checked)} tooltip="Carga de trabajo y proyectos" />
    const DBDetailTypeActivity = <Checkbox checked={checkedDBDetailTypeActivity} onChange={e => onDBDetailTypeActivityChange(e.checked)} tooltip="Detalle tipo de actividad" />
    const DBDetailTypeActivityUser = <Checkbox checked={checkedDBDetailTypeActivityUser} onChange={e => onDBDetailTypeActivityUserChange(e.checked)} tooltip="Detalle tipo de actividad funcionario" />
    const DBSelectAll = <Checkbox checked={checkedDBSelectAll} onChange={e => onSelectAllDBChange(e.checked)} />

    const DBPowerBi = 'Dashboard Powerbi'
    const DBReadPowerBi = <Checkbox checked={checkedDBReadPowerBi} onChange={e => onDBReadPowerBiChange(e.checked)} />
    const DBPowerBiClient = <Checkbox checked={checkedDBPowerBiClient} onChange={e => onDBPowerBiClientChange(e.checked)} tooltip="Dashboard de powerbi de cliente" />
    const DBPlusPowerBi = <Checkbox checked={checkedDBPlusPowerBi} onChange={e => onDBPlusPowerBiChange(e.checked)} tooltip="Dashboard de powerbi con licencia" />
    const DBPlusPowerBiSelectAll = <Checkbox checked={checkedDBPlusPowerBiSelectAll} onChange={e => onSelectAllDBPlusPowerBiChange(e.checked)} />

    const NoveltyAnotherOfficial = 'Novedades otro funcionario'
    const NoveltyAnotherOfficialManagement = <Checkbox checked={checkedNoveltyAnotherOfficial} onChange={e => onNoveltyAnotherOfficialChange(e.checked)} tooltip="Gestionar novedades a otros funcionarios" />
    const NoveltyAnotherOfficialSelectAll = <Checkbox checked={checkedNoveltyAnotherOfficialSelectAll} onChange={e => onSelectAllNoveltyAnotherOfficialChange(e.checked)} />

    const IndividualMonthlyReport = 'Informe mensual individual'
    const IndividualMonthlyReportRead = <Checkbox checked={checkedindividualMonthlyReportRead} onChange={e => onindividualMonthlyReportReadChange(e.checked)} />
    const IndividualMonthlyReportReadOtherOfficial = <Checkbox checked={checkedindividualMonthlyReportReadOtherOfficial} onChange={e => onindividualMonthlyReportReadOtherOfficialChange(e.checked)} tooltip="Consultar informe mensual otro funcionario" />
    const IndividualMonthlyReportReopen = <Checkbox checked={checkedindividualMonthlyReportReopen} onChange={e => onindividualMonthlyReportReopenChange(e.checked)} tooltip="Reabrir informe mensual" />
    const IndividualMonthlyReportSelectAll = <Checkbox checked={checkedindividualMonthlyReportSelectAll} onChange={e => onSelectAllindividualMonthlyReportChange(e.checked)} />

    const InterestedPerson = 'Parametrizar personas interesadas'
    const InterestedPersonRead = <Checkbox checked={checkedInterestedPersonRead} onChange={e => onInterestedPersonReadChange(e.checked)} />
    const InterestedPersonCreate = <Checkbox checked={checkedInterestedPersonCreate} onChange={e => onInterestedPersonCreateChange(e.checked)} />
    const InterestedPersonUpdate = <Checkbox checked={checkedInterestedPersonUpdate} onChange={e => onInterestedPersonUpdateChange(e.checked)} />
    const InterestedPersonDelete = <Checkbox checked={checkedInterestedPersonDelete} onChange={e => onInterestedPersonDeleteChange(e.checked)} />
    const InterestedPersonSelectAll = <Checkbox checked={checkedInterestedPersonSelectAll} onChange={e => onSelectAllInterestedPersonChange(e.checked)} />

    const Objective = 'Parametrizar objetivos'
    const ObjectiveRead = <Checkbox checked={checkedObjectiveRead} onChange={e => onObjectiveReadChange(e.checked)} />
    const ObjectiveCreate = <Checkbox checked={checkedObjectiveCreate} onChange={e => onObjectiveCreateChange(e.checked)} />
    const ObjectiveUpdate = <Checkbox checked={checkedObjectiveUpdate} onChange={e => onObjectiveUpdateChange(e.checked)} />
    const ObjectiveDelete = <Checkbox checked={checkedObjectiveDelete} onChange={e => onObjectiveDeleteChange(e.checked)} />
    const ObjectiveSelectAll = <Checkbox checked={checkedObjectiveSelectAll} onChange={e => onSelectAllObjectiveChange(e.checked)} />

    const Contract = 'Parametrizar contratos'
    const ContractRead = <Checkbox checked={checkedContractRead} onChange={e => onContractReadChange(e.checked)} />
    const ContractCreate = <Checkbox checked={checkedContractCreate} onChange={e => onContractCreateChange(e.checked)} />
    const ContractUpdate = <Checkbox checked={checkedContractUpdate} onChange={e => onContractUpdateChange(e.checked)} />
    const ContractDelete = <Checkbox checked={checkedContractDelete} onChange={e => onContractDeleteChange(e.checked)} />
    const ContractSelectAll = <Checkbox checked={checkedContractSelectAll} onChange={e => onSelectAllContractChange(e.checked)} />

    const Deliverable = 'Parametrizar entregables'
    const DeliverableRead = <Checkbox checked={checkedDeliverableRead} onChange={e => onDeliverableReadChange(e.checked)} />
    const DeliverableCreate = <Checkbox checked={checkedDeliverableCreate} onChange={e => onDeliverableCreateChange(e.checked)} />
    const DeliverableUpdate = <Checkbox checked={checkedDeliverableUpdate} onChange={e => onDeliverableUpdateChange(e.checked)} />
    const DeliverableDelete = <Checkbox checked={checkedDeliverableDelete} onChange={e => onDeliverableDeleteChange(e.checked)} />
    const DeliverableSelectAll = <Checkbox checked={checkedDeliverableSelectAll} onChange={e => onSelectAllDeliverableChange(e.checked)} />

    const Service = 'Parametrizar servicios'
    const ServiceRead = <Checkbox checked={checkedServiceRead} onChange={e => onServiceReadChange(e.checked)} />
    const ServiceCreate = <Checkbox checked={checkedServiceCreate} onChange={e => onServiceCreateChange(e.checked)} />
    const ServiceUpdate = <Checkbox checked={checkedServiceUpdate} onChange={e => onServiceUpdateChange(e.checked)} />
    const ServiceDelete = <Checkbox checked={checkedServiceDelete} onChange={e => onServiceDeleteChange(e.checked)} />
    const ServiceSelectAll = <Checkbox checked={checkedServiceSelectAll} onChange={e => onSelectAllServiceChange(e.checked)} />

    const EquipoTrabajo = 'Parametrizar equipos de trabajo'
    const EquipoRead = <Checkbox checked={checkedWorkTeamRead} onChange={e => onWorkTeamReadChange(e.checked)} />
    const EquipoCreate = <Checkbox checked={checkedWorkTeamCreate} onChange={e => onWorkTeamCreateChange(e.checked)} />
    const EquipoUpdate = <Checkbox checked={checkedWorkTeamUpdate} onChange={e => onWorkTeamUpdateChange(e.checked)} />
    const EquipoDelete = <Checkbox checked={checkedWorkTeamDelete} onChange={e => onWorkTeamDeleteChange(e.checked)} />
    const EquipoSelectAll = <Checkbox checked={checkedWorkTeamSelectAll} onChange={e => onSelectAllWorkTeamChange(e.checked)} />

    const TipoProyecto = 'Parametrizar tipos de proyectos'
    const TipoProyectoRead = <Checkbox checked={checkedTypeProjectRead} onChange={e => onTypeProjectReadChange(e.checked)} />
    const TipoProyectoCreate = <Checkbox checked={checkedTypeProjectCreate} onChange={e => onTypeProjectCreateChange(e.checked)} />
    const TipoProyectoUpdate = <Checkbox checked={checkedTypeProjectUpdate} onChange={e => onTypeProjectUpdateChange(e.checked)} />
    const TipoProyectoDelete = <Checkbox checked={checkedTypeProjectDelete} onChange={e => onTypeProjectDeleteChange(e.checked)} />
    const TipoProyectoSelectAll = <Checkbox checked={checkedTypeProjectSelectAll} onChange={e => onSelectAllTypeProjectChange(e.checked)} />

    const organizationChart = 'Organigrama'
    const organizationChartRead = <Checkbox checked={checkedOrgRead} onChange={e => onOrgChange(e.checked)} />
    const organizationChartSelectAll = <Checkbox checked={checkedOrgSelectAll} onChange={e => onSelectAllOrgChange(e.checked)} />

    const DetailPending = 'Detalle de pendientes'
    const DetailPendingRead = <Checkbox checked={checkedDetailPendingRead} onChange={e => onDetailPendingChange(e.checked)} />
    const DetailPendingSelectAll = <Checkbox checked={checkedDetailPendingSelectAll} onChange={e => onSelectAllDetailPendingChange(e.checked)} />

    const UserClient = 'Consultar usuarios de clientes'
    const UserClientRead = <Checkbox checked={checkedUserClientRead} onChange={e => onUserClientChange(e.checked)} />
    const UserClientSelectAll = <Checkbox checked={checkedUserClientSelectAll} onChange={e => onSelectAllUserClientChange(e.checked)} />

    const Supplier = 'Parametrizar proveedores'
    const SupplierRead = <Checkbox checked={checkedSupplierRead} onChange={e => onSupplierReadChange(e.checked)} />
    const SupplierCreate = <Checkbox checked={checkedSupplierCreate} onChange={e => onSupplierCreateChange(e.checked)} />
    const SupplierUpdate = <Checkbox checked={checkedSupplierUpdate} onChange={e => onSupplierUpdateChange(e.checked)} />
    const SupplierDelete = <Checkbox checked={checkedSupplierDelete} onChange={e => onSupplierDeleteChange(e.checked)} />
    const SupplierSelectAll = <Checkbox checked={checkedSupplierSelectAll} onChange={e => onSelectAllSupplierChange(e.checked)} />

    const FilterSupplier = 'Filtros de proveedor'
    const FilterSupplierNDB1 = <Checkbox checked={checkedFilterSupplierNDB1} onChange={e => onFilterSupplierNDB1Change(e.checked)} tooltip="Dashboard detalle tipos de actividad con filtro de proveedor" />
    const FilterSupplierNDB2 = <Checkbox checked={checkedFilterSupplierNDB2} onChange={e => onFilterSupplierNDB2Change(e.checked)} tooltip="Dashboard tipos de actividad - funcionario con filtro de proveedor" />
    const FilterSupplierExportData = <Checkbox checked={checkedFilterSupplierExportData} onChange={e => onFilterSupplierExportDataChange(e.checked)} tooltip="Exportar datos con filtro de proveedor" />
    const FilterSupplierSelectAll = <Checkbox checked={checkedFilterSupplierSelectAll} onChange={e => onSelectAllFilterSupplierChange(e.checked)} />

    const DBPowerBiFilterSupplier = 'Dashboard Powerbi con filtro de proveedor'
    const DBReadPowerBiFilterSupplier = <Checkbox checked={checkedDBReadPowerBiFilterSupplier} onChange={e => onDBReadPowerBiFilterSupplierChange(e.checked)} />
    const DBPowerBiFilterSupplierClient = <Checkbox checked={checkedDBPowerBiFilterSupplierClient} onChange={e => onDBPowerBiFilterSupplierClientChange(e.checked)} tooltip="Dashboard de powerbi de cliente" />
    const DBPlusPowerBiFilterSupplier = <Checkbox checked={checkedDBPlusPowerBiFilterSupplier} onChange={e => onDBPlusPowerBiFilterSupplierChange(e.checked)} tooltip="Dashboard de powerbi con licencia" />
    const DBPlusPowerBiFilterSupplierSelectAll = <Checkbox checked={checkedDBPlusPowerBiFilterSupplierSelectAll} onChange={e => onSelectAllDBPlusPowerBiFilterSupplierChange(e.checked)} />

    const uploadImage = 'Cargar Imagen Corporativa'
    const uploadImageRead = <Checkbox checked={checkedBDReadUploadImage} onChange={e => onChangeCheckedReadUploadImage(e.checked)}  tooltip="Menú de Cargar Imagen Corporativa"/>
    const uploadImageSelectAll = <Checkbox checked={checkedBDUploadImageSelectAll} onChange={e => onChangeCheckedUploadImageSelectAll(e.checked)}  tooltip="Menú de Cargar Imagen Corporativa" />

    const TablaPermisos = [

        { "options": User, "read": UserRead, "create": UserCreate, "update": UserUpdate, "delete": UserDelete, "selectAll": UserSelectAll },
        { "options": Activity, "read": ActivityRead, "create": ActivityCreate, "update": ActivityUpdate, "delete": ActivityDelete, "selectAll": ActivitySelectAll },
        { "options": ExportData, "read": ExportDataRead, "create": ExportDataAll, "selectAll": ExportDataSelectAll },
        { "options": Client, "read": ClientRead, "create": ClientCreate, "update": ClientUpdate, "delete": ClientDelete, "selectAll": ClientSelectAll },
        { "options": Project, "read": ProjectRead, "create": ProjectCreate, "update": ProjectUpdate, "delete": ProjectDelete, "selectAll": ProjectSelectAll },
        { "options": Aplication, "read": AplicationRead, "create": AplicationCreate, "update": AplicationUpdate, "delete": AplicationDelete, "selectAll": AplicationSelectAll },
        { "options": BusinessAreas, "read": BusinessAreasRead, "create": BusinessAreasCreate, "update": BusinessAreasUpdate, "delete": BusinessAreasDelete, "selectAll": BusinessAreasSelectAll },
        { "options": ActivityType, "read": ActivityTypeRead, "create": ActivityTypeCreate, "update": ActivityTypeUpdate, "delete": ActivityTypeDelete, "selectAll": ActivityTypeSelectAll },
        { "options": Position, "read": PositionRead, "create": PositionCreate, "update": PositionUpdate, "delete": PositionDelete, "selectAll": PositionSelectAll },
        { "options": Rol, "read": RolRead, "create": RolCreate, "update": RolUpdate, "delete": RolDelete, "selectAll": RolSelectAll },
        { "options": Calendar, "read": CalendarRead, "create": CalendarManage, "selectAll": CalendarSelectAll },
        { "options": DownloadZip, "read": DownloadZipRead, "selectAll": DownloadZipSelectAll },
        { "options": Closes, "read": RunClose, "create": CloseOpen, "update": Notification, "selectAll": ClosesSelectAll },
        { "options": Historical, "read": HistoricalRead, "selectAll": HistoricalSelectAll },
        { "options": DashBoards, "read": DBActivityLogger, "create": DBWorkload, "update": DBDetailTypeActivity, "delete": DBDetailTypeActivityUser, "selectAll": DBSelectAll },
        { "options": DBPowerBi, "read": DBReadPowerBi, "create": DBPlusPowerBi, "update": DBPowerBiClient, "selectAll": DBPlusPowerBiSelectAll },
        { "options": NoveltyAnotherOfficial, "read": NoveltyAnotherOfficialManagement, "selectAll": NoveltyAnotherOfficialSelectAll },
        { "options": IndividualMonthlyReport, "read": IndividualMonthlyReportRead, "create": IndividualMonthlyReportReadOtherOfficial, "update": IndividualMonthlyReportReopen, "selectAll": IndividualMonthlyReportSelectAll },
        { "options": InterestedPerson, "read": InterestedPersonRead, "create": InterestedPersonCreate, "update": InterestedPersonUpdate, "delete": InterestedPersonDelete, "selectAll": InterestedPersonSelectAll },
        { "options": Objective, "read": ObjectiveRead, "create": ObjectiveCreate, "update": ObjectiveUpdate, "delete": ObjectiveDelete, "selectAll": ObjectiveSelectAll },
        { "options": Contract, "read": ContractRead, "create": ContractCreate, "update": ContractUpdate, "delete": ContractDelete, "selectAll": ContractSelectAll },
        { "options": Deliverable, "read": DeliverableRead, "create": DeliverableCreate, "update": DeliverableUpdate, "delete": DeliverableDelete, "selectAll": DeliverableSelectAll },
        { "options": Service, "read": ServiceRead, "create": ServiceCreate, "update": ServiceUpdate, "delete": ServiceDelete, "selectAll": ServiceSelectAll },
        { "options": EquipoTrabajo, "read": EquipoRead, "create": EquipoCreate, "update": EquipoUpdate, "delete": EquipoDelete, "selectAll": EquipoSelectAll },
        { "options": TipoProyecto, "read": TipoProyectoRead, "create": TipoProyectoCreate, "update": TipoProyectoUpdate, "delete": TipoProyectoDelete, "selectAll": TipoProyectoSelectAll },
        { "options": organizationChart, "read": organizationChartRead, "selectAll": organizationChartSelectAll },
        { "options": DetailPending, "read": DetailPendingRead, "selectAll": DetailPendingSelectAll },
        { "options": UserClient, "read": UserClientRead, "selectAll": UserClientSelectAll },
        { "options": Supplier, "read": SupplierRead, "create": SupplierCreate, "update": SupplierUpdate, "delete": SupplierDelete, "selectAll": SupplierSelectAll },
        { "options": FilterSupplier, "read": FilterSupplierNDB1, "create": FilterSupplierNDB2, "update": FilterSupplierExportData, "selectAll": FilterSupplierSelectAll },
        { "options": DBPowerBiFilterSupplier, "read": DBReadPowerBiFilterSupplier, "create": DBPlusPowerBiFilterSupplier, "update": DBPowerBiFilterSupplierClient, "selectAll": DBPlusPowerBiFilterSupplierSelectAll },
        { "options": uploadImage, "read": uploadImageRead, "selectAll": uploadImageSelectAll },

    ]

    return (

        <div className="">
            <div>
                <Toast ref={toast} />
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar rol</button>
                <Dialog className="modal" header="Permisos asignados" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">
                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Rol<span className="require">*</span></label>
                                <InputText id="rol" type="text" name="rol" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onRolChange} value={rol}
                                    placeholder="Registre el nombre del rol"
                                    className={rolError || errorDuplicated ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{rolError ? mensajeRol : ""}</p>
                            </div>
                        </form>
                    </div>
                    <div className="datatable-responsive-demo">
                        <div className="card">
                            <DataTable ref={dt} value={TablaPermisos} className="p-datatable-responsive-demo" paginator rows={10} >
                                <Column className="p-column" field="options" header="Opciones" />
                                <Column className="p-column" field="read" header="Consultar" />
                                <Column className="p-column" field="create" header="Crear" />
                                <Column className="p-column" field="update" header="Editar" />
                                <Column className="p-column" field="delete" header="Eliminar" />
                                <Column className="p-column" field="selectAll" header="Seleccionar todo" />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )

}

export default BtnCrearRol
