import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import ModalAddServices from "./ModalAddServices";
import { CambiarFormatoFecha } from "../Funciones";

const BtnCrearContrato = ({ setRunRenderProp, runRenderProp }) => {

    const [serviceClients, setServiceClients] = useState([]);

    const getService = new Services()
    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        if (displayBasic === true) {
        getService.getProyectosUsuarios().then(data => {
            var nameClients = []
            data.forEach(element => {
                var clients = {}
                clients.id = element.cliente.id
                clients.razonsocial = element.cliente.nombre
                nameClients.push(clients)
            })
            const clientsTable = {}
            const uniqueClients = nameClients.filter(function (object) {
                return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
            })
            setServiceClients(uniqueClients)
        })
       };
    }, [displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        resetData()
        dialogFuncMap[`${name}`](false);
        setRunRenderProp(runRenderProp + 1)
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input contrato

    const [inputContract, setInputContract] = useState({
        contract: ''
    })

    const onContractChange = (event) => {
        setInputContract({
            ...inputContract,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del input objeto del contrato

    const [inputObjectContract, setInputObjectContract] = useState({
        objectContract: ''
    })

    const onObjectContractChange = (event) => {
        setInputObjectContract({
            ...inputObjectContract,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        id: '',
        name: ''
    });

    const clients = serviceClients.map(item => {
        const av = { name: item.razonsocial, id: item.id }
        return av
    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    // estado que guarda la lista de servicios asociados 

    const [listServices, setListServices] = useState([])

    // calendar de fecha inicio vigencia

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let date = e.value
        setDateStart(date)
        setValidateChange(true)
    }

    // calendar de fecha fin vigencia

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let date = e.value
        setDateEnd(date)
        setValidateChange(true)
    }

    // calendat de fecha de firma de contrato

    const [dateContract, setDateContract] = useState(null)

    const onDateContractChange = (e) => {
        let date = e.value
        setDateContract(date)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false

    if (validateChange === true) {
        changes = true
    }

    //Almacenamos los valores para enviarlos por props

    const objectContract = inputObjectContract.objectContract
    const contract = inputContract.contract
    const idClient = selectedClient.id
    const services = listServices.map(item => {
        return {
            estado: 1,
            fechacreacion: new Date(),
            fechafinvigencia: item.sendDateEnd,
            fechainiciovigencia: item.sendDateStart,
            servicio: {
                id: item.id
            }
        }
    })

    const resetData = () => {
        setSelectedClient({
            id: '',
            name: ''
        })
        setInputContract({
            contract: ''
        })
        setInputObjectContract({
            objectContract: ''
        })
        setDateStart(null)
        setDateEnd(null)
        setDateContract(null)
        setListServices([])
        setValidateChange(false)
    }

    // Modal para el botón Back

    const [registeredContract, setRegisteredContract] = useState(false)

    const ModalBack = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Back = (name) => {
            if (registeredContract === true) {
                setRunRenderProp(runRenderProp + 1)
                setDisplayBasic(false)
                resetData()
            } else {
                resetData()
                setDisplayBasic(false)
                dialogFuncMap[`${name}`](false);
            }
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreContract = () => {
        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false)
            setRunRenderProp(runRenderProp + 1)
            resetData()
            setDisplayBasic(false)
        }

        const AddMore = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false);
            resetData()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar otro contrato?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }


    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    registerPermission ?
                        <ConfirmBtn
                            tittle="Confirmar Registro"
                            menssage="¿Está seguro de Crear este contrato?"
                            action="Create"
                            label="Guardar"
                            icon="pi pi-plus-circle"
                            contractProp={contract}
                            objectContractProp={objectContract}
                            dateStartProp={dateStart}
                            dateEndProp={dateEnd}
                            dateContractProp={dateContract}
                            servicesProp={services}
                            idClientProp={idClient}
                            setShowModal={setShowModal}
                            setRegisteredContractProp={setRegisteredContract}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    // validaciones

    var contractError = false
    var contractMessage = ''

    if (contract !== '') {
        if (contract.length < 10) {
            contractError = true
            contractMessage = 'La descripción del contrato es demasiado corta'
        } else if (contract.length > 100) {
            contractError = true
            contractMessage = 'La descripción del contrato es demasiado larga'
        }
    }

    var objectContractError = false
    var objectContractMessage = ''

    if (objectContract !== '') {
        if (objectContract.length < 10) {
            objectContractError = true
            objectContractMessage = 'La descripción del objeto del contrato es demasiado corta'
        } else if (objectContract.length > 300) {
            objectContractError = true
            objectContractMessage = 'La descripción del objeto del contrato es demasiado larga'
        }
    }

    var dateStartError = false
    var dateStartMessage = ''

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        }
    }

    var dateContractError = false
    var dateContractMessage = ''

    if (dateContract !== null && dateEnd !== null) {
        if (dateContract >= dateEnd) {
            dateContractError = true
            dateContractMessage = 'La fecha de firma del contrato no puede ser mayor a la fecha fin de vigencia'
        }
    }

    var serviceError = false
    if (listServices.length === 0) {
        serviceError = true
    }

    var disabledServices = false

    if (dateStart === null || dateEnd === null || dateStartError === true) {
        disabledServices = true
    }

    // validación para crear

    var registerPermission = false

    if (contract !== '' && contractError === false && objectContract !== '' && objectContractError === false && dateStartError === false && dateContractError === false
        && selectedClient.id !== '' && dateContract !== null && dateStart !== null && dateEnd !== null && serviceError === false) {
        registerPermission = true
    }

    return (
        <div>
            <button style={{ marginBottom: '10px' }} className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Gestionar contrato</button>
            <AddMoreContract />
            <Dialog className="modal" header={`Gestionar contrato`} visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                className="p-column-filter " emptyMessage="No hay resultados" name="Client" optionLabel="name" filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Contrato<span className="require">*</span></label>
                            <InputText id="name" type="text" name="contract" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onContractChange}
                                placeholder="Ingrese el contrato" value={contract}
                                className={contractError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{contractError ? contractMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Objeto del contrato<span className="require">*</span></label>
                            <InputText id="name" type="text" name="objectContract" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onObjectContractChange}
                                placeholder="Ingrese el objeto del contrato" value={objectContract}
                                className={objectContractError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{objectContractError ? objectContractMessage : ""}</p>
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" >Fecha inicio vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label className="labels" >Fecha fin vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={""}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha firma contrato<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateContract} onChange={onDateContractChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateContractError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateContractError ? dateContractMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Servicios<span className="require">*</span></label>
                            <div className="p-inputgroup">
                                <MultiSelect value={listServices} options={listServices} optionLabel="service" name="service" placeholder="Asocie un servicio"
                                    disabled={disabledServices}
                                />
                                <ModalAddServices
                                    setListServicesProp={setListServices}
                                    listSevicesProp={listServices}
                                    dateStartValidateRangeProp={dateStart !== null ? CambiarFormatoFecha(dateStart) : dateStart}
                                    dateEndValidateRangeProp={dateEnd !== null ? CambiarFormatoFecha(dateEnd) : dateEnd}
                                    disabledServicesProp={disabledServices}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default BtnCrearContrato