import { UpdateValidity } from "../ActualizacionVigencias";

export default function deleteAssiations(idProveedorProp, servicesClients) {

    const url = process.env.REACT_APP_BASE_URL + '/v1/api/client/updateClient'

     //Obtener solo los clientes asociados al proveedor que se está eliminado
     var arrClients = []
     servicesClients.forEach(e => {
         e?.proveedores.forEach(i => {
             if (i.proveedor.id === idProveedorProp) {
                 arrClients.push(e)
             }
         })
     })
     //Validar que solo elimine la asociación en cliente del proveedor que se eliminó
     arrClients.forEach(e => {
        e?.proveedores.forEach(i => {
            if (i.proveedor.id === idProveedorProp) {
                const json = {
                    ...e,
                    proveedores: [{
                        ...i,
                        estado: 3
                    }],
                }
                UpdateValidity(url, json)
            }
        })
    })
}