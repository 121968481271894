import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import Services from '../../service/Services';
import '../../assets/css/DataTable.css';
import { UseMedia } from '../Funciones';
import axios from 'axios';

const ConsultarUsuariosClientes = () => {

    const [clientsSession, setClientsSession] = useState([]);
    const [userClientData, setUserClientData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getServices = new Services();

    useEffect(() => {
        const source = axios.CancelToken.source();

        getServices.getClientesProyectosUsuario().then(data => {
            if (data !== undefined) {

                const sendClients = []
                data.forEach(element => {
                    var selectedClients = {}
                    selectedClients.id = parseInt(element.cliente.id)
                    selectedClients.name = element.cliente.nombre
                    sendClients.push(selectedClients)
                });
                const tableClientes = {}
                const uniqueClients = sendClients.filter(function (object) {
                    return tableClientes.hasOwnProperty(object.id) ? false : (tableClientes[object.id] = true)
                })
                uniqueClients.sort(function (a, b) {
                    var textA = a.name.toLowerCase();
                    var textB = b.name.toLowerCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                setClientsSession(uniqueClients)
            }
        });

        return () => {
            if (source) {
                getServices.cancelRequest();
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // estado para checkbox
    const [checked, setChecked] = useState(false);

    // estado para radiobutton selección de modo

    const [selectedMode, setSelectedMode] = useState('AllUsers');

    const onselectedModeChange = (e) => {
        setSelectedMode(e.value)
    }

    const getId = (array) => {
        if (array === null || array.length === 0) return false;
        return array.map((el) => el.id);
    };

    // funcion para filtrar usuarios por clientes
    const userFliter = (clients, selectedModeP, checkedProp) => {
        
        const data = {
            idClientes: getId(clients),
            asociacionVigente: selectedModeP === 'AllUsers'?false:true,
            usuariosActivos: checkedProp
        }
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/listUsersByClient2'
        axios.post(url, data, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(response => {
            if (response !==undefined) {
                let newData = response.data.map(item => {
                    var status
                    if (item.estado === 0) {
                        status = 'Inactivo'
                    } else if (item.estado === 1) {
                        status = 'Activo'
                    } else if (item.estado === 2) {
                        status = 'Bloqueado'
                    } else if (item.estado === 3) {
                        status = 'Eliminado'
                    }
                    return {
                        ...item,
                        estado: status
                    }
                })
                setUserClientData(newData)
                setLoading(false)
            }
        }).catch(function (error) {
            setLoading(false)
            setUserClientData([])
        })
    }


    // multiselect clients

    const [selectedClients, setSelectedClients] = useState(null)

    const clients = clientsSession.map(item => {
        return {
            name: item.name,
            id: item.id
        }
    })

    const [itemsSelectedClient, setItemsSelectedClient] = useState(0);

    const onClientsChange = (e) => {
        if (e.value !== null) {
            setItemsSelectedClient(e.value.length)
        } else {
            setItemsSelectedClient(0)
        }
        setSelectedClients(e.value)
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    // exportar datos a Excel
    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(userClientData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            var textClients = selectedClients.map(item => {
                return item.name
            })
            saveAsExcelFile(excelBuffer, 'Usuarios asociados a ' + textClients.join(", "));
        });

    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
        });
    }

    const header = (
        <div className="headerTable">
            <Button type="button" icon="pi pi-file-excel" onClick={() => exportExcel()} className="p-button-success p-mr-2" tooltip='XLSX'
                disabled={userClientData.length === 0 && true} />
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );
    //validaciones 

    var showTooltipClient = false

    if (selectedClients !== null && selectedClients.length !== 0) {
        if (selectedClients.length > 0 && selectedClients.length <= 10) {
            showTooltipClient = true
        }
    }
    var disabledButton = false
    if (selectedClients === null || selectedClients.length === 0) {
        disabledButton = true
    } else {
        disabledButton = false
    }

    let small = UseMedia("(max-width: 760px)");

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <div className="p-fluid p-formgrid p-grid">
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3">
                            <div className="p-field-radiobutton" style={{ marginBottom: '5px' }}>
                                <RadioButton inputId="AllUsers" name="AllUsers" value="AllUsers" onChange={onselectedModeChange} checked={selectedMode === 'AllUsers'} />
                                <label htmlFor="AllUsers" style={{ fontWeight: '100' }}>Todos los usuarios</label>
                            </div>
                            <div className="p-field-radiobutton" style={{ marginTop: '10px' }}>
                                <RadioButton inputId="ActiveUsers" name="ActiveUsers" value="ActiveUsers" onChange={onselectedModeChange} checked={selectedMode === 'ActiveUsers'} />
                                <label htmlFor="ActiveUsers" style={{ fontWeight: '100' }}>Solo usuarios con asociación vigente</label>
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
                                <label htmlFor="binary" style={{ marginLeft: '0.5rem' }}>Usuarios activos</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels">Clientes</label>
                            {showTooltipClient ?
                                <Tooltip target=".tooltip-button-client" autoHide={false}>
                                    <div className="flex align-items-center">
                                        {
                                            selectedClients.map((item, index) => {
                                                return (<div key={index}>{item.name}</div>)
                                            })
                                        }
                                    </div>
                                </Tooltip>
                                :
                                ""
                            }
                            <MultiSelect value={selectedClients} options={clients} onChange={onClientsChange} optionLabel="name" name="client"
                                placeholder="Seleccione un cliente" showClear maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`}
                                className="tooltip-button-client" filter emptyFilterMessage="No se encontraron resultados"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2" style={{ marginTop: '35px' }}>
                            <Button label="Buscar" disabled={disabledButton} className="p-button-outlined p-button-secondary" onClick={() => {
                                setLoading(true)
                                userFliter(selectedClients, selectedMode, checked)
                            }} />
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-12" style={{ marginTop: '5px' }}>
                        {
                            loading ?
                                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>

                                :
                                ""
                        }
                    </div>
                </div>
                <DataTable ref={dt} value={userClientData} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                    emptyMessage="Usuario por Cliente no registrado en el sistema" loading={loading} responsiveLayout={small ? "stack" : "scroll"}>
                    <Column className="columna" field="nrodocumento" sortable header="Número de documento" />
                    <Column className="columna" field="nombrecompleto" sortable header="Nombre del colaborador" />
                    <Column className="columna" field="usuario" sortable header="Usuario" />
                    <Column className="columna" field="fecIngreso" sortable header="Fecha de ingreso" />
                    <Column className="columna" field="estado" sortable header="Estado" />
                    <Column className="columna" field="tipoBloqueo" sortable header="Tipo de bloqueo" />
                    <Column className="columna" field="ultimoDíaDeRegistro" sortable header="Último día de registro" />
                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarUsuariosClientes

