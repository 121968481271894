import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Services from '../../service/Services';
import BtnCrearCliente from './BtnCrearCliente'
import BtnActualizarCliente from './BtnActualizarCliente'
import '../../assets/css/DataTable.css';
import ClienteProveedor from './ClienteProveedor';
import ConfirmBtn from './ConfirmBtn';
import axios from 'axios';

const ConsultarCliente = ({ permisosProp }) => {

    const [servicio, setServicio] = useState([]);
    const [loading, setLoading] = useState(true);
    const [render, setRender] = useState(0);

    const getServices = new Services();

    useEffect(() => {
        const source = axios.CancelToken.source();
        getServices.getClientes().then(data => {
            if (data !== undefined) {

                var newData = data.map(item => {
                    var estado
                    if (item.estado === 0) {
                        estado = "Inactivo"
                    } else if (item.estado === 1) {
                        estado = "Activo"
                    } else if (item.estado === 2) {
                        estado = "Bloqueado"
                    } else if (item.estado === 3) {
                        estado = "Eliminado"
                    }
                    return {
                        ...item,
                        estado: estado,
                    }
                })
                newData.sort(function (a, b) {
                    var textA = a.razonsocial;
                    var textB = b.razonsocial;
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                setServicio(newData)
                setLoading(false)
            }
        });
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [render]); // eslint-disable-line react-hooks/exhaustive-deps

    var permisoClientCreate
    if (permisosProp[3].permisos[0] === "Crear") {
        permisoClientCreate = true
    } else {
        permisoClientCreate = false
    }
    var permisoClientUpdate
    if (permisosProp[3].permisos[1] === "Editar") {
        permisoClientUpdate = true
    } else {
        permisoClientUpdate = false
    }
    var permisoClientDelete
    if (permisosProp[3].permisos[2] === "Eliminar") {
        permisoClientDelete = true
    } else {
        permisoClientDelete = false
    }

    const Acciones = (rowData) => {
        return (
            <>
                {permisoClientUpdate ?
                    <BtnActualizarCliente
                        idClienteProp={rowData.id}
                        tipoDocumentoProp={rowData.tipodocumento}
                        numeroDocumentoProp={rowData.numerodocumento}
                        nombreProp={rowData.razonsocial}
                        dominioProp={rowData.dominio}
                        creadoporProp={rowData.creadopor}
                        fechacreacionProp={rowData.fechacreacion}
                        renderProp={render}
                        setRenderProp={setRender}
                        linkPowerBiProp={rowData.linkpowerbi}
                    />
                    :
                    ""
                }
                <ClienteProveedor
                    dataClientProp={rowData}
                    suppliersProp={rowData.proveedores}
                    permisoClientCreateProp={permisoClientCreate}
                    permisoClientUpdateProp={permisoClientUpdate}
                    permisoClientDeleteProp={permisoClientDelete}
                    renderProp={render}
                    setRenderProp={setRender}
                    linkPowerBiProp={rowData.linkpowerbi}
                />
                {permisoClientDelete ?
                    <ConfirmBtn
                        idClienteProp={rowData.id}
                        titulo="Confirmar Eliminación"
                        mensaje="¿Está seguro de eliminar cliente?, Recuerde que una vez se elimine se borrara definitivamente del sistema"
                        accion="Eliminar"
                        clase="p-button-danger"
                        icono="pi pi-trash"
                        tooltip="Eliminar"
                        renderProp={render}
                        setRenderProp={setRender}
                    />
                    :
                    ""
                }
            </>
        );
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoClientCreate ?
                <BtnCrearCliente
                    renderProp={render}
                    setRenderProp={setRender}
                />
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    var permisosAcciones
    if (permisoClientUpdate === false && permisoClientDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={servicio} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                    emptyMessage="Cliente no registrado en el sistema" loading={loading}>
                    <Column className="columna" field="numerodocumento" sortable header="Número Documento" />
                    <Column className="columna" field="tipodocumento" sortable header="Tipo Documento" />
                    <Column className="columna" field="razonsocial" sortable header="Nombre / Razón social" />
                    <Column className="columna" field="dominio" sortable header="Dominio" />
                    <Column className="columna" field="estado" sortable header="Estado" />
                    {permisosAcciones ?
                        <Column header="Acciones" body={Acciones} />
                        :
                        <Column />
                    }
                </DataTable>
            </div>
        </div>
    );
}

export default ConsultarCliente