import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
/* import { Slider } from 'primereact/slider'; */
import ConfirmBtn from './ConfirmBtn.js'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { CambiarFormatoFecha, SumarDias } from '../Funciones';
const cookies = new Cookies();

const BtnActualizarLogro = ({ projectsProp, idProjectsProp, descriptionObjectivesProp, idDescriptionObjectivesProp, detailAchievementProp, achievementProp, percentageProp, dateStartProp,
    dateEndProp, creadoPorProp, fechaCreacionProp, idLogroProp, setRunRenderProp, runRenderProp, dateProp, reloadObjectiveProp, objectivesProp, projectsUserSessionProp,
    achievementsProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp, courtComplianceProp }) => {

    const [projectsSession, setProjectsSession] = useState([]);

    useEffect(() => {
        const projectsTable = {}
        const uniqueProjects = projectsUserSessionProp.filter(function (object) {
            return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
        })
        setProjectsSession(uniqueProjects)
    }, [reloadObjectiveProp])// eslint-disable-line react-hooks/exhaustive-deps

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select Proyecto

    const [selectedProjects, setSelectedProjects] = useState({
        projects: projectsProp,
        id: idProjectsProp,
        dateStartValidity: dateStartProp,
        dateEndValidity: dateEndProp
    })

    const proyectos = projectsSession.map(item => {
        const av = { projects: item.nombre, id: item.id, dateStartValidity: item.fechainiciovigencia, dateEndValidity: item.fechafinvigencia }
        return av
    })

    const onProjectsChange = (e) => {
        setSelectedProjects(e.value);
        setValidateChange(true)
    }

    //Obtener datos del select objetivo

    const [selectedObjectives, setSelectedObjectives] = useState({
        descriptionObjectives: descriptionObjectivesProp,
        id: idDescriptionObjectivesProp
    })


    var porjectsObjectives = []

    const loadObjectives = () => {
        const result = objectivesProp.map(item => {
            return item.proyectos.map(j => {
                var boolean
                if (j.proyecto.id === parseInt(selectedProjects.id)) {
                    boolean = item
                }
                return boolean
            })
        })

        result.forEach(i => {
            i.forEach(j => {
                if (j !== undefined) {
                    if (j.length !== 0) {
                        porjectsObjectives.push(j)
                    }
                }
            })
        })
        return porjectsObjectives
    }

    if (selectedProjects.id !== '') {
        loadObjectives()
    }

    const objetivo = porjectsObjectives.map(item => {
        const av = { descriptionObjectives: item.descripcion, id: item.id }
        return av
    })

    const onObjectivesChange = (e) => {
        setSelectedObjectives(e.value);
        setValidateChange(true)
    }

    //Obtener datos de cumplimiento al corte

    const [selectedCourtCompliance, setSelectedCourtCompliance] = useState(null)

    const courtCompliances = [{ name: 'Meta Alcanzada' }, { name: 'Meta No Alcanzada' }]

    const onChangeCourtCompliance = (e) => {
        setSelectedCourtCompliance(e.value)
    }

    //Obtener datos del input detalle del logro

    const [inputDetailAchievement, setInputDetailAchievement] = useState({
        detailAchievement: detailAchievementProp
    })

    const onDetailAchievementChange = (event) => {
        setInputDetailAchievement({
            ...inputDetailAchievement,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del input logro

    const [inputAchievement, setInputAchievement] = useState({
        achievement: achievementProp
    })

    const onAchievementChange = (event) => {
        setInputAchievement({
            ...inputAchievement,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    // calendar de fecha  inicio

    const [date, setDate] = useState(null);

    const onDateChange = (e) => {
        let date = e.value
        setDate(date)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Almacenamos los valores para enviarlos por props

    const achievement = inputAchievement.achievement
    const descriptionObjectives = selectedObjectives.descriptionObjectives
    const idObjectives = selectedObjectives.id
    const projects = selectedProjects.projects
    const idProjects = selectedProjects.id
    const detailAchievement = inputDetailAchievement.detailAchievement
    const courtCompliance = selectedCourtCompliance?.name
    const user = cookies.get('usuario')

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio

    useEffect(() => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        const dateChange = new Date(dateProp)
        if (gmtHours < 0) {
            setDate(SumarDias(dateChange, +1))
        } else {
            setDate(dateChange)
        }
        setSelectedProjects({
            projects: projectsProp,
            id: String(idProjectsProp),
            dateStartValidity: dateStartProp,
            dateEndValidity: dateEndProp
        })
        setSelectedObjectives({
            descriptionObjectives: descriptionObjectivesProp,
            id: idDescriptionObjectivesProp
        })
        setInputDetailAchievement({
            detailAchievement: detailAchievementProp
        })
        setInputAchievement({
            achievement: achievementProp
        })
        setSelectedCourtCompliance({
            name: courtComplianceProp
        })

    }, [projectsProp, idProjectsProp, descriptionObjectivesProp, idDescriptionObjectivesProp, detailAchievementProp, achievementProp, dateStartProp, dateEndProp, dateProp, courtComplianceProp])

    const resetData = () => {
        setSelectedProjects({
            projects: projectsProp,
            id: String(idProjectsProp),
            dateStartValidity: dateStartProp,
            dateEndValidity: dateEndProp
        })
        setSelectedObjectives({
            descriptionObjectives: descriptionObjectivesProp,
            id: idDescriptionObjectivesProp
        })
        setInputDetailAchievement({
            detailAchievement: detailAchievementProp
        })
        setInputAchievement({
            achievement: achievementProp
        })
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        const dateChange = new Date(dateProp)
        if (gmtHours < 0) {
            setDate(SumarDias(dateChange, +1))
        } else {
            setDate(dateChange)
        }
        setValidateChange(false)
        setSelectedCourtCompliance({
            name: courtComplianceProp
        })
    }


    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Back = (name) => {
            setDisplayBasic(false)
            resetData()
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const renderFooter = (name) => {
        var createNewAchievement = false
        if (user !== creadoPorProp) {
            createNewAchievement = true
        }
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {updatePermission ?
                    <ConfirmBtn
                        tittle="Confirmar Actualización"
                        menssage={createNewAchievement ? "El registro editado no fue creado con su usuario, por lo tanto, se creará un nuevo registro con los datos actualizados, tenga en cuenta seleccionar este nuevo registro para su informe." : "¿Estás seguro de guardar esta información?"}
                        action={createNewAchievement ? "Create" : "Update"}
                        label="Actualizar"
                        icon="pi pi-pencil"
                        projectsProp={projects}
                        idProjectsProp={idProjects}
                        descriptionObjectivesProp={descriptionObjectives}
                        idDescriptionObjectivesProp={idObjectives}
                        detailAchievementProp={detailAchievement}
                        achievementProp={achievement}
                        creadoPorProp={creadoPorProp}
                        dateProp={date && CambiarFormatoFecha(date)}
                        fechaCreacionProp={fechaCreacionProp}
                        idLogroProp={idLogroProp}
                        userProp={user}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                        setDisplayBasicProp={setDisplayBasic}
                        courtComplianceProp={courtCompliance}
                    />
                    :
                    ""
                }
            </div>
        );
    }

    //validaciones para Actividades

    var detailAchievementError = false
    var mensajeDetailAchievement = ''
    if (detailAchievement.length < 8 && detailAchievement !== '') {
        detailAchievementError = true
        mensajeDetailAchievement = 'El detalle del logro debe ser mayor a 8 dígitos'
    } else if (detailAchievement.length > 300) {
        detailAchievementError = true
        mensajeDetailAchievement = 'El detalle del logro debe ser menor a 300 dígitos'
    }

    //validaciones para logro

    var achievementError = false
    var mensajeAchievement = ''
    if (achievement.length < 8 && achievement !== '') {
        achievementError = true
        mensajeAchievement = 'El logro debe ser mayor a 8 dígitos'
    } else if (achievement.length > 200) {
        achievementError = true
        mensajeAchievement = 'El logro debe ser menor a 200 dígitos'
    }

    if (selectedObjectives.id !== '') {
        if (achievement.length > 8 && achievement.length < 100 && detailAchievement.length > 8 && detailAchievement.length < 200) {
            var serviceDuplicate = achievementsProp.filter(obj => obj.id !== idLogroProp
                && obj.objetivo.id === parseInt(selectedObjectives.id)
                && obj.creadopor === user
                && obj.logro.toLowerCase() === achievement.toLowerCase()
                && obj.detallelogro.toLowerCase() === detailAchievement.toLowerCase())
            if (serviceDuplicate.length !== 0) {
                detailAchievementError = true
                mensajeDetailAchievement = 'Ya has creado un logro con el mismo objetivo, logro y detalle del logro'
            }
        }
    }

    //validaciones para fecha de finalización 
    var dateError = false
    var dateMessage = ''
    const validateDate = () => {
        if (date !== null) {
            var newDate = CambiarFormatoFecha(date)
            if (newDate >= selectedProjects.dateStartValidity && newDate <= selectedProjects.dateEndValidity) {
                dateError = false
                dateMessage = ''
            } else {
                dateError = true
                dateMessage = 'La fecha de finalización esta fuera del rango de fechas del proyecto seleccionado'
            }
            if (newDate > CambiarFormatoFecha(new Date())) {
                dateError = true
                dateMessage = 'La fecha de finalización no puede ser mayor a la fecha actual'
            } else if (newDate < CambiarFormatoFecha(dateStartMonthlyReportProp) || newDate > CambiarFormatoFecha(dateEndMonthlyReportProp)) {
                dateError = true
                dateMessage = 'La fecha de finalización no puede estar fuera del rango del periodo seleccionado'
            }
        }

    }

    if (date !== null && selectedProjects.id !== '') {
        validateDate()
    }

    //Validar que no hay ningun error

    var updatePermission = false
    if (achievementError === false && achievement !== '' && detailAchievementError === false && detailAchievement !== '' && projects !== '' && descriptionObjectives !== ''
        && date !== null && dateError === false) {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar logros por objetivos" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Proyecto<span className="require">*</span></label>
                            <Dropdown value={selectedProjects} options={proyectos} onChange={onProjectsChange} name="proyecto" optionLabel="projects"
                                placeholder="Seleccione un proyecto" disabled filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Objetivo<span className="require">*</span></label>
                            <Dropdown value={selectedObjectives} options={objetivo} onChange={onObjectivesChange} name="objetivo" optionLabel="descriptionObjectives"
                                placeholder="Seleccione un objetivo" filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Cumplimiento al Corte<span className="require">*</span></label>
                            <Dropdown value={selectedCourtCompliance} options={courtCompliances} onChange={onChangeCourtCompliance} optionLabel='name'
                                placeholder="Seleccione un cumplimiento" emptyMessage="No se registran datos" filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Logro<span className="require">*</span></label>
                            <InputText value={achievement} id="achievement" type="text" name="achievement"  onChange={onAchievementChange}
                                placeholder="Registre el logro realizado"
                                className={achievementError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{achievementError ? mensajeAchievement : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Detalle del logro<span className="require">*</span></label>
                            <InputText value={detailAchievement} id="detailAchievement" type="text" name="detailAchievement"  onChange={onDetailAchievementChange}
                                placeholder="Ingrese el detalle"
                                className={detailAchievementError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{detailAchievementError ? mensajeDetailAchievement : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Fecha de cumplimiento<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={date} onChange={onDateChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateError ? dateMessage : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnActualizarLogro