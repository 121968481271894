import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import Services from "../../service/Services";
import "../../assets/css/DataTable.css";
import ConfirmBtn from "./ConfirmBtn";
import BtnCrearActividadAdicional from "./BtnCrearActividadAdicional";
import BtnActualizarActividadAdicional from "./BtnActualizarActividadAdicional";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  UseMedia,
  EstandarFormatoFecha,
  SumarDias,
  CambiarFormatoFecha,
  RestarMeses,
} from "../Funciones";

const cookies = new Cookies();

const TableRegistrarAdicionales = ({ permisosProp }) => {
  const [servicio, setServicio] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cierres, setCierres] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState(null);
  const [sendIdsActivities, setSendIdsActivities] = useState([]);
  const getServices = new Services();
  
  const usuario = cookies.get("usuario");
  
  const dt = useRef(null);
  
  useEffect(() => {
    const source = axios.CancelToken.source();

    getServices.getCierres().then((data) => {
      if (data !== undefined) {
        setCierres(data);
      }
    });

    return () => {
      if (source) {
        getServices.cancelRequest();
        source.cancel();
      }

    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Filtrar registros con las fechas
  const currentDate = new Date();
  const oneMonthsBeforeToday = RestarMeses(new Date(), 1);
  const [dateStart, setDateStart] = useState(oneMonthsBeforeToday);

  const onDateStartChange = (e) => {
    setDateStart(e.value);
  };

  const [dateEnd, setDateEnd] = useState(currentDate);

  const onDateEndChange = (e) => {
    setDateEnd(e.value);
  };

  const [dateError, setDateError] = useState(false);
  const [dateMessageError, setDateMessageError] = useState("");

  useEffect(() => {
    //Validaciones de fechas
    if (dateStart !== null && dateEnd !== null) {
      if (dateStart > dateEnd) {
        setDateError(true);
        setDateMessageError(
          "La fecha inicio no puede ser mayor a la fecha fin"
        );
      } else {
        setDateError(false);
        setDateMessageError("");
      }
    } else if (dateStart === null || dateEnd === null) {
      setDateError(true);
      setDateMessageError("Las fechas no pueden quedar vacías");
    } else {
      setDateError(false);
      setDateMessageError("");
    }
  }, [dateStart, dateEnd]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchData(oneMonthsBeforeToday, currentDate, dateError) {
      var d = new Date();
      var gmtHours = -d.getTimezoneOffset() / 60;
      const url =
        process.env.REACT_APP_BASE_URL + "/v1/api/activity/consultListActivity";
      if (
        oneMonthsBeforeToday !== null &&
        currentDate !== null &&
        dateError === false
      ) {
        setLoading(true);
        const newDateStart = CambiarFormatoFecha(oneMonthsBeforeToday);
        const newDateEnd = CambiarFormatoFecha(currentDate);
        const json = JSON.stringify({
          esadicional: "1",
          fecFinal: newDateEnd,
          fecInicial: newDateStart,
          usuario: usuario,
        });
        /* console.log(json) */
        await axios
          .post(url, json, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response !== undefined) {
              var newData = response.data.map((item) => {
                var newDate;
                var newDateEmail;
                if (gmtHours < 0) {
                  newDate = SumarDias(new Date(item.fecha), 1);
                  newDateEmail = SumarDias(new Date(item.fechacorreoelec), 1);
                } else {
                  newDate = new Date(item.fecha);
                  newDateEmail = new Date(item.fechacorreoelec);
                }
                return {
                  actualizadopor: item.actualizadopor,
                  aplicacion: item.aplicacion,
                  areanegocio: item.areanegocio,
                  cliente: item.cliente,
                  colaborador: item.colaborador,
                  coordinadocon: item.coordinadocon,
                  creadopor: item.creadopor,
                  descripcionactividad: item.descripcionactividad,
                  esadicional: item.esadicional,
                  estado: item.estado,
                  fecha: EstandarFormatoFecha(newDate),
                  sendDate: item.fecha,
                  fechaactualizado: item.fechaactualizado,
                  fechacorreoelec: EstandarFormatoFecha(newDateEmail),
                  sendDateEmail: item.fechacorreoelec,
                  fechacreacion: item.fechacreacion,
                  id: item.id,
                  idempresa: item.idempresa,
                  numerohoras: item.numerohoras,
                  proyecto: item.proyecto,
                  ticket: item.ticket,
                  tipoActividad: item.tipoActividad,
                };
              });
              setServicio(newData);
              setLoading(false);
            }
          })
          .catch(function (error) {
            setLoading(false);
            //console.log(error.response)
          });
      }
    }

    fetchData(dateStart, dateEnd, dateError);
  }, [dateStart, dateEnd, dateError]); // eslint-disable-line react-hooks/exhaustive-deps

  const [date, setDate] = useState(null);

  const onDateChange = (e) => {
    dt.current.filter(e.value, "sendDate", "custom");
    setDate(e.value);
  };

  const filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    return value === formatDate(filter);
  };

  const formatDate = (date) => {
    return CambiarFormatoFecha(date);
  };

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  const FechaFilter = (
    <div className="p-fluid p-grid">
      <div className="p-md-12">
        <Calendar
          placeholder="Seleccione "
          className="fechaFlilter"
          style={{ marginBottom: "-7px" }}
          value={date}
          onChange={onDateChange}
          dateFormat="dd-mm-yy"
          locale="es"
          showButtonBar
        />
      </div>
    </div>
  );

  const tipodocumento = cookies.get("tipoDocumento");
  const documento = cookies.get("numeroDocumento");
  const nombre = cookies.get("nombre");
  const cargo = cookies.get("cargo");
  const idColaborador = cookies.get("idColaborador");

  var permisoActivityCreate;
  if (permisosProp[1].permisos[0] === "Crear") {
    permisoActivityCreate = true;
  } else {
    permisoActivityCreate = false;
  }
  var permisoActivityUpdate;
  if (permisosProp[1].permisos[1] === "Editar") {
    permisoActivityUpdate = true;
  } else {
    permisoActivityUpdate = false;
  }
  var permisoActivityDelete;
  if (permisosProp[1].permisos[2] === "Eliminar") {
    permisoActivityDelete = true;
  } else {
    permisoActivityDelete = false;
  }

  //Validación eliminación múltiple
  var mensajeSeleccion = "";
  var validarActividadSeleccionada = true;

  if (sendIdsActivities.length === 0) {
    mensajeSeleccion = "Debe seleccionar al menos una actividad";
    validarActividadSeleccionada = false;
  } else {
    mensajeSeleccion =
      "Está seguro que desea eliminar las actividades seleccionadas";
    validarActividadSeleccionada = true;
  }

  const header = (
    <>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-3">
          <label className="labels" htmlFor="TipoDocumento">
            Tipo de Documento
          </label>
          <InputText
            id="tipoDocumento"
            type="text"
            name="tipoDocumento"
            value={tipodocumento}
            disabled
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <label className="labels" htmlFor="numDocumento">
            Número documento
          </label>
          <InputText
            id="numDocumento"
            type="text"
            name="numDocumento"
            value={documento}
            disabled
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <label className="labels" htmlFor="Nombre">
            Nombre Completo
          </label>
          <InputText
            id="Nombre"
            type="text"
            name="Nombre"
            value={nombre}
            disabled
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <label className="labels" htmlFor="Cargo">
            Cargo
          </label>
          <InputText
            id="Cargo"
            type="text"
            name="Cargo"
            value={cargo}
            disabled
          />
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label className="labels" htmlFor="Nombre">
            Fecha Inicio
          </label>
          <div className="">
            <Calendar
              placeholder="Seleccione una fecha inicio"
              value={dateStart}
              onChange={onDateStartChange}
              dateFormat="dd-mm-yy"
              locale="es"
              showButtonBar
              className={dateError ? "p-invalid fechaFlilter" : "fechaFlilter"}
            />
          </div>
          <p className="mensajeError">{dateError ? dateMessageError : ""}</p>
        </div>

        <div className="p-field p-col-12 p-md-3">
          <label className="labels" htmlFor="Nombre">
            Fecha Fin
          </label>
          <div className="">
            <Calendar
              placeholder="Seleccione una fecha fin"
              value={dateEnd}
              onChange={onDateEndChange}
              dateFormat="dd-mm-yy"
              locale="es"
              showButtonBar
            />
          </div>
        </div>
      </div>
      <div className="delete">
        <div className="">
          {permisoActivityCreate ? <BtnCrearActividadAdicional /> : ""}
        </div>
        {loading && <p>Cargando información...</p>}
        <div>
          {permisoActivityDelete ? (
            <ConfirmBtn
              icono="pi pi-trash"
              clase="p-button-danger "
              titulo="Eliminar actividades adicionales"
              accion="Eliminar"
              label="Eliminar Actividades Adicionales"
              mensaje={mensajeSeleccion}
              actividadSeleccionadaProp={validarActividadSeleccionada}
              indicadorProp="1"
              tooltipProp="Eliminar"
              idActividadesProp={sendIdsActivities}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );

  const [valorHorasMes, setValorHorasMes] = useState(0);

  const CalcularFechas = () => {
    const fechaActual = new Date();
    let month = fechaActual.getMonth() + 1;
    let day = fechaActual.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    const mesActual = fechaActual.getFullYear() + "-" + month;

    var horasMesActual = 0;

    const result = servicio.filter(function (objeto) {
      return objeto.sendDate.includes(mesActual);
    });

    result.map((item) => {
      horasMesActual = horasMesActual + item.numerohoras;

      return result;
    });
    setValorHorasMes(horasMesActual);
  };

  const footer = (
    <div className="">
      <label className="labels">Total horas mes:</label>
      <InputText
        type="text"
        style={{ margin: "8px" }}
        keyfilter={/^[^<0-9>*!=°|(#$%&)+-./?¡¿'{}]+$/}
        placeholder={valorHorasMes}
        disabled
      />
    </div>
  );

  // Obtener si el cierre esta activo o inactivo para el usuario en sesión

  /* console.log(cierreUsuario) */

  /*  console.log(servicio) */

  const cierreActivo = cookies.get("cierreActivo");
  const mesesCerrados = cierres.filter(function (objeto) {
    return objeto.estado === "C";
  });
  var fechaCierre = [];

  mesesCerrados.forEach((element) => {
    fechaCierre.push(element.fechaFinalCierre);
  });

  let arrayFechasCierres = fechaCierre.map((item) => new Date(item));

  var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres));
  let month = fechaUltimoCierre.getMonth() + 1;
  let day = fechaUltimoCierre.getDate() + 1;

  if (month < 10) {
    month = "0" + month;
  }

  if (day < 10) {
    day = "0" + day;
  }
  const fecha = fechaUltimoCierre.getFullYear() + "-" + month + "-" + day;

  /* console.log(cierres) */

  const mesAbierto = cierres.filter(function (objeto) {
    var id = parseInt(cierreActivo);
    return objeto.id === id;
  });
  var getMesAbierto = {};

  mesAbierto.forEach((element) => {
    getMesAbierto = {
      estado: element.estado,
      fechaFinalCierre: element.fechaFinalCierre,
      fechaInicialCierre: element.fechaInicialCierre,
    };
  });

  const validacionMesCerrado = (rowData) => {
    let disabled = false;

    if (cierres.length !== 0) {
      if (cierres) {
        if (mesAbierto.length !== 0) {
          if (
            rowData.sendDate >= getMesAbierto.fechaInicialCierre &&
            rowData.sendDate <= getMesAbierto.fechaFinalCierre
          ) {
            disabled = false;
          } else {
            if (rowData.sendDate < fecha) {
              disabled = true;
            } else {
              disabled = false;
            }
          }
        } else if (rowData.sendDate < fecha) {
          disabled = true;
        } else {
          disabled = false;
        }
      }
    }
    return disabled;
  };

  const Acciones = (rowData) => {
    var disabled = validacionMesCerrado(rowData);
    return (
      <div className="Botones">
        {permisoActivityUpdate ? (
          <BtnActualizarActividadAdicional
            fechaProp={rowData.sendDate}
            clienteProp={rowData.cliente.razonsocial}
            idClienteProp={rowData.cliente.id}
            proyectoProp={rowData.proyecto.nombreproyecto}
            idProyectoProp={rowData.proyecto.id}
            tipoActividadProp={rowData.tipoActividad.nombretipoactividad}
            idTipoActividadProp={rowData.tipoActividad.id}
            ticketProp={rowData.ticket}
            areaProp={rowData.areanegocio.nombreareanegocio}
            idAreaProp={rowData.areanegocio.id}
            sistemaProp={rowData.aplicacion.nombreaplicacion}
            idSistemaProp={rowData.aplicacion.id}
            fechaCorreoProp={rowData.sendDateEmail}
            coordinadoConProp={rowData.coordinadocon}
            actividadProp={rowData.descripcionactividad}
            horasProp={rowData.numerohoras}
            creadoPorProp={rowData.creadopor}
            fechaCreacionProp={rowData.fechacreacion}
            idActividadProp={rowData.id}
            disabledProp={disabled}
            categoriaProp={rowData.tipoActividad.categoria.descripcion}
            idCategoriaProp={rowData.tipoActividad.categoria.id}
          />
        ) : (
          ""
        )}
        {permisoActivityCreate ? (
          <ConfirmBtn
            icono="pi pi-clone"
            clase="p-button-success"
            titulo="Duplicar actividad"
            mensaje=""
            accion="Duplicar"
            mensajeAlerta="Duplicado"
            idColaboradorProp={idColaborador}
            usuarioProp={usuario}
            fechaProp={rowData.sendDate}
            clienteProp={rowData.cliente.razonsocial}
            idClienteProp={rowData.cliente.id}
            proyectoProp={rowData.proyecto.nombreproyecto}
            idProyectoProp={rowData.proyecto.id}
            tipoActividadProp={rowData.tipoActividad.nombretipoactividad}
            idTipoActividadProp={rowData.tipoActividad.id}
            ticketProp={rowData.ticket}
            areaProp={rowData.areanegocio.nombreareanegocio}
            idAreaProp={rowData.areanegocio.id}
            sistemaProp={rowData.aplicacion.nombreaplicacion}
            idSistemaProp={rowData.aplicacion.id}
            actividadProp={rowData.descripcionactividad}
            fechaCorreoProp={rowData.sendDateEmail}
            coordinadoConProp={rowData.coordinadocon}
            esAdicionalProp="1"
            horasProp={rowData.numerohoras}
            indicadorProp="1"
            tooltipProp="Duplicar"
            disabledProp={disabled}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  // ejecutamos la función de calcular fechas en cada render
  useEffect(() => {
    CalcularFechas();
  });

  let eliminarActividades = [];

  const onChangeSelectActivities = (e) => {
    setSelectedActivities(e.value);
    let ids = e.value;
    ids.forEach((element) => {
      eliminarActividades.push(element.id);
    });
    setSendIdsActivities(eliminarActividades);
  };

  let small = UseMedia("(max-width: 760px)");

  var permisosAcciones;
  if (
    permisoActivityCreate === false &&
    permisoActivityUpdate === false &&
    permisoActivityDelete === false
  ) {
    permisosAcciones = false;
  } else {
    permisosAcciones = true;
  }

  const showSelectionElement = (rowData) => {
    var disabled = validacionMesCerrado(rowData);
    return !disabled;
  };

  return (
    <div>
      <div className="datatable-responsive-demo">
        <div className="card">
          <DataTable
            ref={dt}
            value={servicio}
            className="p-datatable-responsive-demo"
            paginator
            rows={10}
            header={header}
           /*  style={{ overflow: "hidden" }} */
            showSelectionElement={showSelectionElement}
            selectionMode="checkbox"
            filterDisplay="row"
            loading={loading}
            emptyMessage="No hay datos en el sistema"
            scrollable={small ? false : true}
            scrollHeight={small ? "" : "500px"}
            footer={footer}
            editMode="row"
            sortField="sendDate"
            sortOrder={-1}
            selection={selectedActivities}
            onSelectionChange={(e) => onChangeSelectActivities(e)}
          >
            <Column
              selectionMode="multiple"
              style={{ maxWidth: "4rem" }}
            ></Column>
            <Column
              className="columna"
              headerStyle={{ width: "260px" }}
              field="sendDate"
              sortable
              header="Fecha"
              filter
              filterElement={FechaFilter}
              filterFunction={filterDate}
              showFilterMenu={false}
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="cliente.razonsocial"
              sortable
              header="Cliente "
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="proyecto.nombreproyecto"
              sortable
              header="Proyecto"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="tipoActividad.nombretipoactividad"
              sortable
              header="Tipo Actividad"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="ticket"
              sortable
              header="Req.Ticket"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="areanegocio.nombreareanegocio"
              sortable
              header="Área Negocio"
            />
            <Column
              className="columna"
              headerStyle={{ width: "185px" }}
              field="aplicacion.nombreaplicacion"
              sortable
              header="Sistema Aplicación"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="descripcionactividad"
              sortable
              header="Actividad"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="coordinadocon"
              sortable
              header="Coordinado Con"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="sendDateEmail"
              sortable
              header="Fecha Correo"
            />
            <Column
              className="columna"
              headerStyle={{ width: "160px" }}
              field="numerohoras"
              sortable
              header="Num Horas"
            />

            {permisosAcciones ? (
              <Column
                header="Acciones"
                headerStyle={{ width: "180px" }}
                body={Acciones}
              />
            ) : (
              <Column />
            )}
          </DataTable>
        </div>
        <div className="ContainerBtnRegresar">
          <Link to="/registroActividades/inicio" className="BtnRegresar">
            Regresar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TableRegistrarAdicionales;
