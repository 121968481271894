import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import Services from '../../service/Services';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Toast } from 'primereact/toast';
import ConfirmBtn from './ConfirmBtn';
import ModalAddServices from './ModalAddServices';
import { CambiarFormatoFecha, EstandarFormatoFecha } from '../Funciones';


const BtnCrearOtrosi = ({ idContractProp, contractProp, servicesProp, dateStartContractProp, runRenderProp, setRunRenderProp }) => {

    const [serviceContracts, setServiceContracts] = useState([]);

    const getServices = new Services();

    const toast = useRef(null);

    useEffect(() => {
        getServices.getContract().then(data => setServiceContracts(data));
        setSelectedContract({
            contract: contractProp,
            id: idContractProp,
        })

    }, [idContractProp, contractProp])// eslint-disable-line react-hooks/exhaustive-deps


    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select contratos

    const [selectedContract, setSelectedContract] = useState({
        contract: '',
        id: '',
    })

    const contracts = serviceContracts.map(item => {
        const av = { contract: item.contrato, id: item.id }
        return av
    })

    const onContractChange = (e) => {
        setSelectedContract(e.value);
        setValidateChange(true)
    }

    //Obtener datos del input otrosi

    const [inputOther, setInputOther] = useState({
        other: ''
    })

    const onOtherChange = (event) => {

        setInputOther({
            ...inputOther,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    // estado que guarda la lista de servicios asociados 

    const [listServices, setListServices] = useState([])

    // calendar de fecha  inicio Otrosi

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidateChange(true)
    }

    /// calendar de fecha fin Otrosi

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidateChange(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Almacenamos los valores para enviarlos por props

    var idContract = selectedContract.id
    var other = inputOther.other

    const services = listServices.map(item => {
        return {
            estado: 1,
            fechacreacion: new Date(),
            fechafinvigencia: item.sendDateEnd,
            fechainiciovigencia: item.sendDateStart,
            servicio: {
                id: item.id
            }
        }
    })

    // función para restablecer los datos del formulario

    const resetData = () => {
        setSelectedContract({
            contract: contractProp,
            id: idContractProp,
        })
        setInputOther({
            other: ''
        })
        setDateStart(null)
        setDateEnd(null)
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            resetData()
        }

        const Back = (name) => {
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
            resetData()
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreOther = () => {
        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setRunRenderProp(runRenderProp + 1)
            setDisplayBasic(false)
            setShowModal(false)
            resetData()
        }

        const AddMore = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false);
            resetData()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar un nuevo otrosi?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    const [displayBasic3, setDisplayBasic3] = useState(false);
    const AddServicesContract = () => {


        const dialogFuncMap = {
            'displayBasic3': setDisplayBasic3,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setListServices(selectedServices)
        }

        const [selectedServices, setSelectedServices] = useState([])

        servicesProp.sort(function (a, b) {
            var textA = a.servicio.descripcion;
            var textB = b.servicio.descripcion;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })

        const services = servicesProp.map(item => {
            return {
                id: item.servicio.id,
                service: item.servicio.descripcion,
                sendDateStart: item.fechainiciovigencia,
                sendDateEnd: item.fechafinvigencia,
                dateStart: EstandarFormatoFecha(new Date(item.fechainiciovigencia,)),
                dateEnd: EstandarFormatoFecha(new Date(item.fechafinvigencia,))
            }
        })

        const [itemsSelectedService, setItemsSelectedService] = useState(0);
        const onServicesChange = (e) => {
            if (e.value !== null) {
                setItemsSelectedService(e.value.length)
            } else {
                setItemsSelectedService(0)
            }
            setSelectedServices(e.value)
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Guardar" icon="pi pi-save" onClick={() => onHide(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic3} modal style={{}} footer={renderFooter('displayBasic3')} onHide={() => onHide('displayBasic3')}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div style={{ display: 'contents' }} >
                                <div className="p-field p-col-12 p-md-12">

                                    <label style={{ textAlign: 'center' }}>Seleccione los servicios que desea mantener para el otro si</label>
                                    <MultiSelect value={selectedServices} options={services} onChange={onServicesChange} optionLabel="service" name="service"
                                        placeholder="Seleccione los servicios" className='multiselectService' maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedService} Elementos Seleccionados`}
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {registerPermission ?
                    <ConfirmBtn
                        tittle="Confirmar Registro"
                        menssage="¿Está seguro de crear este otrosi?"
                        action="CreateOther"
                        label="Guardar"
                        icon="pi pi-plus-circle"
                        idContractProp={idContract}
                        otherProp={other}
                        dateStartProp={dateStart}
                        dateEndProp={dateEnd}
                        servicesProp={services}
                        setShowModal={setShowModal}
                        statusProp={1}
                        runRenderProp={runRenderProp}
                        setRunRenderProp={setRunRenderProp}
                        setDisplayBasicProp={setDisplayBasic}
                    />
                    :
                    ""
                }

            </div>
        );
    }

    // validaciones

    var otherError = false
    var otherMessage = ''

    if (other !== '') {
        if (other.length < 10) {
            otherError = true
            otherMessage = 'La descripción del servicio es demasiado corta'
        } else if (other.length > 500) {
            otherError = true
            otherMessage = 'La descripción del servicio es demasiado larga'
        }
    }

    // validar fechas 

    var dateStartError = false
    var dateStartMessage = ''
    if (dateStart !== null) {
        if (CambiarFormatoFecha(dateStart) < dateStartContractProp) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia otrosi no puede ser menor a la fecha inicio del contrato'
        }
    }

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia otrosi no puede ser mayor a la fecha fin de vigencia otrosi'
        }
    }

    // validaciones para servicios
    var serviceError = false
    if (listServices.length === 0) {
        serviceError = true
    }

    var disabledServices = false

    if (dateStart === null || dateEnd === null || dateStartError === true) {
        disabledServices = true
    }

    // validación para registrar

    var registerPermission = false

    if (other !== '' && otherError === false && dateStartError === false && selectedContract !== null && selectedContract.id !== '' && serviceError === false) {
        registerPermission = true
    }

    return (

        <div className="">
            <Toast ref={toast} />
            <div>
                <button className="btnAdd" onClick={() => {
                    onClick('displayBasic')
                    resetData()
                    setTimeout(() => {
                        setDisplayBasic3(true)
                    }, [500])
                }}><span className="pi pi-plus Add"></span>Crear Otrosi</button>
                <AddMoreOther />
                <AddServicesContract />
                <Dialog className="modal" header="Crear Otrosi" visible={displayBasic} style={{ width: '60vw' }} breakpoints={{ '960px': '85vw', '640px': '85vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Contrato<span className="require">*</span></label>
                                <Dropdown value={selectedContract} options={contracts} onChange={onContractChange} name="contrato" optionLabel="contract"
                                    placeholder="Seleccione un contrato" disabled filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >OtroSi<span className="require">*</span></label>
                                <InputText type='text' value={other} onChange={onOtherChange} name="other" placeholder="Ingrese la descripción del otrosi"
                                    className={otherError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{otherError ? otherMessage : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Fecha inicio vigencia otrosi<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                    className={dateStartError ? "multiselectService p-invalid" : "multiselectService"}
                                />
                                <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Fecha fin vigencia otrosi<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                    className={"multiselectService"}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Servicios<span className="require">*</span></label>
                                <div className="p-inputgroup">
                                    <MultiSelect value={listServices} options={listServices} optionLabel="service" name="service" placeholder="Asocie un servicio"
                                        disabled={disabledServices}
                                    />
                                    <ModalAddServices
                                        setListServicesProp={setListServices}
                                        listSevicesProp={listServices}
                                        dateStartValidateRangeProp={dateStart !== null ? CambiarFormatoFecha(dateStart) : dateStart}
                                        dateEndValidateRangeProp={dateEnd !== null ? CambiarFormatoFecha(dateEnd) : dateEnd}
                                        disabledServicesProp={disabledServices}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearOtrosi