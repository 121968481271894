import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Cookies from 'universal-cookie';
import Services from '../../service/Services';
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { removeAccents } from '../Funciones';

const cookies = new Cookies();

const BtnCrearTipoActividad = ({ dataProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [category, setCategory] = useState([]);

    const getServices = new Services();

    useEffect(() => {
        if (displayBasic === true) {
            getServices.getCategory().then(data => setCategory(data))
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    //Obtener datos del input tipo actividad

    const [inputTipoActividad, setInputTipoActividad] = useState({
        tipoActividad: ''
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onTipoActividadChange = (event) => {
        setInputTipoActividad({
            ...inputTipoActividad,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //obtenemos datos del select de categoría

    const [selectedCategory, setSelectedCategory] = useState({
        id: '',
        name: ''
    })

    const categories = category.map(item => {
        return {
            id: item.id,
            name: item.descripcion
        }
    })

    const onCategoryChange = (e) => {
        setSelectedCategory(e.value)
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Almacenamos los valores para enviarlos por props

    const tipoActividad = inputTipoActividad.tipoActividad
    const idCategory = selectedCategory.id
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            setInputTipoActividad({
                tipoActividad: ''
            })
            setSelectedCategory({
                id: '',
                name: ''
            })
            setValidarCambio(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registrar este tipo de actividad?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            tipoActividadProp={tipoActividad}
                            idCategoryProp={idCategory}
                            usuarioProp={usuario}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    //validaciones

    var tipoActividadError = false
    var mensajeTipoActividad = ''

    if (tipoActividad.length < 3 && tipoActividad !== '') {
        tipoActividadError = true
        mensajeTipoActividad = 'El nombre del tipo de actividad debe ser mayor a 3 dígitos'
    } else if (tipoActividad.length > 100) {
        tipoActividadError = true
        mensajeTipoActividad = 'El nombre del tipo de actividad debe ser menor a 100 dígitos'
    }

    var validateErrorDuplicity = false


    const validateDuplicity = () => {
        dataProp.forEach(element => {
            const nameTypeActivity = removeAccents(element.nombretipoactividad)
            const newNameTypeActivity = removeAccents(tipoActividad)

            if (nameTypeActivity.toUpperCase() === newNameTypeActivity.toUpperCase()) {
                validateErrorDuplicity = true
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Tipo Actividad pre-existente' });
            }
        })
    }

    //Función que Valida que el tipo de actividad y las categorías no se registren igual a unas ya creadas
    if (tipoActividad !== '') {
        validateDuplicity()
    }

    var permisoRegistrar = false
    if (tipoActividadError === false && tipoActividad !== '' && validateErrorDuplicity === false
        && selectedCategory.name !== '' && selectedCategory.id !== '') {
        permisoRegistrar = true
    }

    return (
        <div className="">
            <div>
                <Toast ref={toast} />
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar tipo de actividad</button>
                <Dialog className="modal" header="Registrar tipo de actividad" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">
                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Tipo de Actividad<span className="require">*</span></label>
                                <InputText id="tipoActividad" type="text" name="tipoActividad" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onTipoActividadChange}
                                    placeholder="Registre el tipo de actividad"
                                    className={tipoActividadError || validateErrorDuplicity ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{tipoActividadError ? mensajeTipoActividad : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Categoría<span className="require">*</span></label>
                                <Dropdown value={selectedCategory} options={categories} onChange={onCategoryChange} name="category" optionLabel='name'
                                    placeholder="Seleccione una categoria" filter
                                />
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default BtnCrearTipoActividad