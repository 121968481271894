import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ConfirmBtn from './ConfirmBtn.js'
import Services from '../../service/Services.js';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones';
import { Toast } from 'primereact/toast';
const cookies = new Cookies();

const BtnActualizarTipoActividad = ({ idTipoActividadProp, tipoActividadProp, categoryProp, idCategoryProp, creadoporProp, fechacreacionProp, dataProp }) => {

    const toast = useRef(null)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [category, setCategory] = useState([]);

    const getServices = new Services();

    useEffect(() => {
        if (displayBasic===true) {
            getServices.getCategory().then(data => setCategory(data))
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps


    const [inputId, setInputId] = useState({
        id: idTipoActividadProp
    })

    //Obtener datos del input tipo de actividad

    const [inputTipoActividad, setInputTipoActividad] = useState({
        tipoActividad: tipoActividadProp
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onTipoActividadChange = (event) => {
        setInputTipoActividad({
            ...inputTipoActividad,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //obtenemos datos del select de categoría

    const [selectedCategory, setSelectedCategory] = useState({
        id: idCategoryProp,
        name: categoryProp
    })

    const categories = category.map(item => {
        return {
            id: item.id,
            name: item.descripcion
        }
    })

    const onCategoryChange = (e) => {
        setSelectedCategory(e.value)
        setValidarCambio(true)
    }

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setInputId({
            id: idTipoActividadProp
        })
        setInputTipoActividad({
            tipoActividad: tipoActividadProp
        })
        setSelectedCategory({
            id: idCategoryProp,
            name: categoryProp
        })
    }, [idTipoActividadProp, tipoActividadProp, idCategoryProp, categoryProp])

    const [validateErrorDuplicity, setValidateErrorDuplicity] = useState(false)

    useEffect(() => {
        if (inputTipoActividad.tipoActividad !== tipoActividadProp) {
            var duplicated = []
            dataProp.forEach(element => {
                const nameTypeActivityTable = removeAccents(element.nombretipoactividad)
                const nameTypeActivity = removeAccents(inputTipoActividad.tipoActividad)
                if (nameTypeActivityTable.toUpperCase() === nameTypeActivity.toUpperCase()) {
                    duplicated.push(element)
                    toast.current?.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Tipo Actividad pre-existente' });
                }
            })
            if (duplicated.length !== 0) {
                setValidateErrorDuplicity(true)
            }else{
                setValidateErrorDuplicity(false)
            }
        }
    }, [inputTipoActividad])// eslint-disable-line react-hooks/exhaustive-deps

    //Almacenamos los valores para enviarlos por props

    const idTipoActividad = inputId.id
    const tipoActividad = inputTipoActividad.tipoActividad
    const idCategory = selectedCategory.id
    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            setInputTipoActividad({
                tipoActividad: tipoActividadProp
            })
            setSelectedCategory({
                id: idCategoryProp,
                name: categoryProp
            })
            setValidarCambio(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        label="Actualizar"
                        icono="pi pi-pencil"
                        idTipoActividadProp={idTipoActividad}
                        usuarioProp={usuario}
                        tipoActividadProp={tipoActividad}
                        idCategoryProp={idCategory}
                        creadoporProp={creadoporProp}
                        fechacreacionProp={fechacreacionProp}
                    />
                    : ""
                }
            </div>
        );
    }

    //validaciones

    var tipoActividadError = false
    var mensajeTipoActividad = ''
    if (tipoActividad === '') {
        tipoActividadError = true
        mensajeTipoActividad = 'Debes ingresar el nombre del tipo de actividad '
    } else if (tipoActividad.length < 3) {
        tipoActividadError = true
        mensajeTipoActividad = 'El nombre del tipo de actividad debe ser mayor a 3 dígitos'
    } else if (tipoActividad.length > 100) {
        tipoActividadError = true
        mensajeTipoActividad = 'El nombre del tipo de actividad debe ser menor a 100 dígitos'
    }

    var permisoActualizar = false
    if (tipoActividadError === false && validateErrorDuplicity === false) {
        permisoActualizar = true
    }

    return (
        <>
            <Toast ref={toast} />
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar Tipo Actividad" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Id Tipo Actividad </label>
                            <InputText id="id" type="text" name="id" disabled value={idTipoActividad} />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" > Tipo de Actividad<span className="require">*</span></label>
                            <InputText id="tipoActividad" type="text" name="tipoActividad" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onTipoActividadChange} value={tipoActividad}
                                placeholder="Registre el tipo de actividad"
                                className={tipoActividadError || validateErrorDuplicity ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{tipoActividadError ? mensajeTipoActividad : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Categoría<span className="require">*</span></label>
                            <Dropdown value={selectedCategory} options={categories} onChange={onCategoryChange} name="category" optionLabel='name'
                                placeholder="Seleccione una categoria" filter
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}
export default BtnActualizarTipoActividad