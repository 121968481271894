import React, { useState, useEffect, useRef } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import { addLocale } from "primereact/api";
import { Divider } from "primereact/divider";
import DataTable1 from "./DataTable1";
import Chart1 from "./Chart1";
import Services from "../../../../service/Services";
import "../../../../assets/css/Forms.css";
import "../../../../assets/css/DialogDemo.css";
import moment from "moment";
import { Toast } from "primereact/toast";
import { UseMedia } from "../../../Funciones";
import { ProgressBar } from 'primereact/progressbar';
import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const DetalleTipoAcividad = () => {

	const [servicioTipoActividad, setServicioTipoActividad] = useState([]);
	const [clientSession, setClientSession] = useState([]);
	const [servicioCargo, setServicioCargo] = useState([])
	const [projects, setProjects] = useState([]);
	const [servicesUsers, setServicesUsers] = useState([]);
	const [category, setCategory] = useState([]);
	const [servicesTypeProjects, setServicesTypesProjects] = useState([]);
	const [dataFuncionariosTable, setDatafuncionariosTable] = useState([]);
	const [servicesTeamWork, setServicesTeamWork] = useState([]);
	const toast = useRef(null);
	const [funcionarioSelected, setFuncionarioSelected] = useState("");
	const dateFirtsDay = new Date()
	var firstDay = new Date(dateFirtsDay.getFullYear(), dateFirtsDay.getMonth(), 1)
	var dateCurrent = new Date()

	//Seleccionar Fechas de inicio y fin

	const [dateStart, setDateStart] = useState(firstDay);
	const [dateEnd, setDateEnd] = useState(dateCurrent);

	const getService = new Services();
	const tipoUsuario = cookies.get('tipoUsuario')
	const chartDataDummy = {
		labels: [],
		datasets: [
			{
				data: [],
				backgroundColor: [
					"#42A5F5",
					"#66BB6A",
					"#8500e7",
					"#ffe601",
					"#de880a",
					"#42A5F5",
					"#66BB6A",
					"#8500e7",
					"#ffe601",
					"#de880a",
					"#42A5F5",
					"#66BB6A",
					"#8500e7",
					"#ffe601",
					"#de880a",
				],
				hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
			},
		],
	};
	const [chartData, setChartData] = useState(chartDataDummy);
	const [loading2, setLoading2] = useState(false)
	const source = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
	useEffect(() => {
		getService.getClientesProyectosUsuario().then((data) => {
			if (data !== undefined) {

				const sendClients = []
				data.forEach(element => {
					var selectedClients = {}
					selectedClients.id = parseInt(element.cliente.id)
					selectedClients.name = element.cliente.nombre
					sendClients.push(selectedClients)
				});
				const tableClientes = {}
				const uniqueClients = sendClients.filter(function (object) {
					return tableClientes.hasOwnProperty(object.id) ? false : (tableClientes[object.id] = true)
				})
				setSelectedCliente(uniqueClients)
				setClientSession(uniqueClients)
				setItemsSelectedClient(uniqueClients.length)
			}
		});

		getService.getTypesProjects().then(data => {
			if (data !== undefined) {
				setServicesTypesProjects(data.map(x => {
					return {
						name: x.nombretipoproyecto,
						id: x.id
					}
				}))
				setSelectedTypeProject(data.map(x => {
					return {
						name: x.nombretipoproyecto,
						id: x.id
					}
				}))
				setItemsSelectedTypeProject(data.length)
			}
		})
		getService.getCargos().then((dataPosition) => {
			setServicioCargo(dataPosition)
			setSelectedCargo(dataPosition.map(item => {
				return {
					name: item.cargo,
					id: item.id
				}
			}));
			setItemsSelectedPosition(dataPosition.length)
		});

		getService.getCategory().then(data => {
			if (data !== undefined) {
				setCategory(data)
			}
		})

		getService.getTipoActividades().then((data) => {
			if (data !== undefined) {
				setServicioTipoActividad(data)
			}
		});
		// Función de limpieza para cancelar la solicitud cuando el componente se desmonta o cambia de pestaña
		return () => {
			if (source) {
				getService.cancelRequest()
			}
		};

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/// Proceso para llenar los clientes y los proyectos asociados al usuario en sesión

	//Obtener datos del select tipo de clientes
	const [selectedCliente, setSelectedCliente] = useState(null);

	const clientes = clientSession.map((item) => {
		const av = { name: item.name, id: item.id };

		return av;
	});

	var disabled = false

	if (tipoUsuario === "Cliente") {
		disabled = true
	}

	const [itemsSelectedClient, setItemsSelectedClient] = useState(0);
	const onClienteChange = (e) => {
		setItemsSelectedClient(e.value.length)
		setSelectedCliente(e.value);
		setSelectedProyecto([])
		setSelectedTeamWork([])
		setSelectedUsuario([])
	};


	useEffect(() => {
		const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
		const dataWorkTeam = {
			idClient: getId(selectedCliente) || []
		}

		const executeServicesWorTeam = async () => {
			const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/consultListByClient'
			await axios.post(url, dataWorkTeam, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Authorization": `Bearer ${sessionStorage.getItem('token')}`
				},
				cancelToken: newSource.token
			})
				.then((result) => {
					if (result !== undefined) {
						const newData = result.data.map(item => item)
						newData.push({
							id: 0,
							nombre: 'Sin Equipo de Trabajo'
						})
						setServicesTeamWork(newData)
						setSelectedTeamWork(newData)
						setItemsSelectedTeamWork(newData.length)
					}
				})
				.catch((e) => {
					/* console.log(e); */
				})
		}
		if (selectedCliente !== null) {
			if (selectedCliente.length !== 0) {
				executeServicesWorTeam()
			}
		}

		return () => {
			if (newSource) {
				newSource.cancel()
			}
		}
	}, [selectedCliente]); // eslint-disable-line react-hooks/exhaustive-deps

	const [selectedTeamWork, setSelectedTeamWork] = useState(null);

	const optionTeamWork = servicesTeamWork.map(item => item)

	const [itemsSelectedTeamWork, setItemsSelectedTeamWork] = useState(0);
	const onTeamWorkChange = (e) => {
		setSelectedTeamWork(e.value)
		setItemsSelectedTeamWork(e.value.length)
		setSelectedUsuario([])
	}

	const [selectedTypeProject, setSelectedTypeProject] = useState(null)
	useEffect(() => {
		if (selectedCliente !== null && selectedCliente.length !== 0) {
			var newData = servicesTypeProjects.map(x => {
				return {
					name: x.name,
					id: x.id
				}
			})
			setSelectedTypeProject(newData);
			setItemsSelectedTypeProject(newData.length)
		}
	}, [selectedCliente]) // eslint-disable-line react-hooks/exhaustive-deps
	const typesProjects = servicesTypeProjects.map(item => {
		const av = { name: item.name, id: item.id }
		return av
	})
	const [itemsSelectedTypeProject, setItemsSelectedTypeProject] = useState(0);
	const onTypesProjectsChange = (e) => {
		setItemsSelectedTypeProject(e.value.length)
		setSelectedTypeProject(e.value);
		setSelectedProyecto([])
		setSelectedUsuario([]);
	};

	useEffect(() => {
		const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
		const idUsuario = cookies.get('idUsuario')
		const dataProjects = {
			idClientes: getId(selectedCliente) || [],
			idTipoProyecto: getId(selectedTypeProject) || [],
			idUsuario: parseInt(idUsuario)
		}
		const getProjectUserByClientAndTypeProject = async () => {
			const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/listProjectsUsers'
			await axios.post(url, dataProjects, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Authorization": `Bearer ${sessionStorage.getItem('token')}`
				},
				cancelToken: newSource.token
			}).then((result) => {
				if (result !== undefined) {
					const filter = result.data.filter(item => {
                        return item.estado === 1
                    })
					const tableProjectUser = {}
					const uniqueProjectsUser = filter.filter(function (object) {
						return tableProjectUser.hasOwnProperty(object.id) ? false : (tableProjectUser[object.id] = true)
					})
					setProjects(uniqueProjectsUser)
					setSelectedProyecto(uniqueProjectsUser.map(item => {
						return {
							name: item.nombreproyecto,
							id: item.id
						}
					}))
					setItemsSelectedProject(uniqueProjectsUser.length)
				}
			}).catch((e) => {
				/* console.log(e); */
			})
		}
		if (selectedCliente !== null && selectedTypeProject !== null) {
			if (selectedCliente.length !== 0 || selectedTypeProject.length !== 0) {
				getProjectUserByClientAndTypeProject();
			}
		}

		return () => {
			if (newSource) {
				newSource.cancel()
			}
		}
	}, [selectedCliente, selectedTypeProject]); // eslint-disable-line react-hooks/exhaustive-deps

	const [selectedProyecto, setSelectedProyecto] = useState(null);

	const proyectos = projects.map((item) => {
		const av = { name: item.nombreproyecto, id: item.id };
		return av;
	});

	const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
	const onProyectoChange = (e) => {
		setItemsSelectedProject(e.value.length)
		setSelectedProyecto(e.value);
		setSelectedUsuario([])
	};

	const [selectedCargo, setSelectedCargo] = useState(null);

	const cargos = servicioCargo.map((item) => {
		const av = { name: item.cargo, id: item.id };

		return av;
	});

	const [itemsSelectedPosition, setItemsSelectedPosition] = useState(0);
	const onCargoChange = (e) => {
		setItemsSelectedPosition(e.value.length)
		setSelectedCargo(e.value);
		setSelectedUsuario([])
	};

	const [selectedUsuario, setSelectedUsuario] = useState(null);
	useEffect(() => {
		const newSource = axios.CancelToken.source(); //Se crea una instancia para luego cancelar las peticiones
		const dataUsers = {
			cargos: getId(selectedCargo) || [],
			proyectos: getId(selectedProyecto) || [],
			wt: getId(selectedTeamWork) || [],
			proveedor: [],
			clientes: getId(selectedCliente) || []
		}
		const getUsersForProjectOrTeamWorkOrPosition = async () => {

			const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/getUsersFilter'
			await axios.post(url, dataUsers, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Authorization": `Bearer ${sessionStorage.getItem('token')}`
				},
				cancelToken: newSource.token
			}).then((result) => {
				if (result !== undefined) {
					setServicesUsers(result.data)
					setSelectedUsuario(result.data.map(item => {
						return {
							name: item.nombre,
							id: item.id
						}
					}));
					setItemsSelectedUser(result.data.length)
				}
			}).catch((e) => {
				/* console.log(e); */
			})
		}
		if (selectedProyecto !== null && selectedCargo !== null && selectedTeamWork !== null && selectedCliente !== null) {
			if (selectedProyecto.length !== 0 || selectedCargo.length !== 0 || selectedTeamWork.length !== 0 || selectedCliente.length !== 0) {
				getUsersForProjectOrTeamWorkOrPosition()
			}
		}

		return () => {
			if (newSource) {
				newSource.cancel()
			}
		}
	}, [selectedProyecto, selectedCargo, selectedTeamWork]); // eslint-disable-line react-hooks/exhaustive-deps

	const usuarios = servicesUsers.map((item) => {
		const av = { name: item.nombre, id: item.id };

		return av;
	});

	const [itemsSelectedUser, setItemsSelectedUser] = useState(0);
	const onUsuarioChange = (e) => {
		setItemsSelectedUser(e.value.length)
		setSelectedUsuario(e.value);
	};

	// seleccionar categoría

	const [selectedCategory, setSelectedCategory] = useState(null)
	useEffect(() => {
		if (selectedCategory === null && category.length !== 0) {
			setSelectedCategory(category.map((item) => {
				return {
					id: item.id,
					name: item.descripcion
				}
			}))
			setItemsSelectedCategory(category.length)
		}
	}, [category]); // eslint-disable-line react-hooks/exhaustive-deps
	const categories = category.map(item => {
		return {
			id: item.id,
			name: item.descripcion
		}
	})

	const [itemsSelectedCategory, setItemsSelectedCategory] = useState(0);
	const onCategoryChange = (e) => {
		setItemsSelectedCategory(e.value.length)
		setSelectedTipoActividad(null)
		setSelectedCategory(e.value)
	}

	// Proceso para caragr tipos de actividada según la categoría seleccionada

	var categoryTypeActivity = []

	const loadTypeActivity = () => {
		if (selectedCategory.length !== 0) {
			selectedCategory.forEach(element => {
				const result = servicioTipoActividad.filter(function (object) {
					var id = object.categoria.id
					return id === element.id
				})
				result.forEach(element => {
					categoryTypeActivity.push(element)
				})
			})
		}
	}

	if (selectedCategory !== null) {
		loadTypeActivity()
	}

	// Seleccionar tipo de actividad

	const [selectedTipoActividad, setSelectedTipoActividad] = useState(null);
	useEffect(() => {
		if (selectedTipoActividad === null && categoryTypeActivity.length !== 0) {
			setSelectedTipoActividad(categoryTypeActivity.map((item) => {
				return {
					name: item.nombretipoactividad,
					id: item.id
				}
			}))
			setItemsSelectedTypeActivity(categoryTypeActivity.length)
		}
	}, [categoryTypeActivity]); // eslint-disable-line react-hooks/exhaustive-deps

	const tipoActividades = categoryTypeActivity.map((item) => {
		const av = { name: item.nombretipoactividad, id: item.id };

		return av;
	});

	const [itemsSelectedTypeActivity, setItemsSelectedTypeActivity] = useState(0);
	const onTipoActividadChange = (e) => {
		setItemsSelectedTypeActivity(e.value.length)
		setSelectedTipoActividad(e.value);
	};

	const onDateStartChange = (e) => {
		let date = e.value;
		setDateStart(date);
	};

	const onDateEndChange = (e) => {
		let date = e.value;
		setDateEnd(date);
	};

	addLocale('es', {
		firstDayOfWeek: 1,
		dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
		dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
		monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
		today: 'Hoy',
		clear: 'Limpiar'
	})

	const getId = (array) => {
		if (array === null || array.length === 0) return false;
		return array.map((el) => el.id);
	};

	//efecto cuando cambiar un filtro
	useEffect(() => {
		setLoading2(true)
		const Axios = axios.create({
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
				"Authorization": `Bearer ${sessionStorage.getItem('token')}`
			},
			baseURL: process.env.REACT_APP_BASE_URL + "/v1/api/",
		});
		//peticion para llenar datos de datatable
		const executeServices = async () => {

			const getSelectedTeamWork = getId(selectedTeamWork)
			const newGetSelectedTeamWork = getSelectedTeamWork === false ? false : getSelectedTeamWork?.map(id => id !== null ? id : 0)
			const data = {
				fecFinal: moment(dateEnd).format("YYYY-MM-DD") || "",
				fecInicial: moment(dateStart).format("YYYY-MM-DD") || "",
				idActividades: getId(selectedTipoActividad) || [],
				idCargo: getId(selectedCargo) || [],
				idClientes: getId(selectedCliente) || [],
				idProyectos: getId(selectedProyecto) || getId(projects) || [],
				idUsuarios: getId(selectedUsuario) || getId(servicesUsers),
				idEquipoTrabajo: newGetSelectedTeamWork || [],

			};

			await Axios.post("report/dashBoardTypeActivityProject", data, { cancelToken: source.token })
				.then((res) => {
					if (res !== undefined) {
						if (res.status === 200) {
							toast.current.show({ severity: 'success', summary: 'Consulta Satisfactoria', detail: '', life: 6000 });
							var newData = res.data.map(item => {
								return {
									detalle: item.detalle.map(j => {
										return {
											categoria: j.categoria,
											horas: j.horas.toFixed(2),
											porcentajeHoras: j.porcentajeHoras,
											tipoActividad: j.tipoActividad,
										}
									}),
									/* detalleTeamWork: item.detalleTeamWork.map(j => {
										return {
											equipoTrabajo: j.equipoTrabajo,
											idEquipoTrabajo: j.idEquipoTrabajo,
											horas: j.horas,
											porcentajeHoras: j.porcentajeHoras
										}
									}), */
									id: item.id,
									proyecto: item.proyecto,
									nombreTipoProyecto: item.tipoProyecto,
									totalHoras: item.totalHoras.toFixed(2),
									totalPorcentaje: item.totalPorcentaje,
									usuario: item.usuario,
								}
							})
							setDatafuncionariosTable(newData);
							setLoading2(false)
						}
					}
				})
				.catch((error) => {
					if (error !== undefined) {
						if (error.response !== undefined) {
							if (error.response.status === 404) {
								setLoading2(false)
								setDatafuncionariosTable([]);
								toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'No se encontraron registros que concuerden con los parametros dados', life: 6000 });
							} else {
								setLoading2(false)
								setDatafuncionariosTable([]);
							}
						}
					}
				});
		}

		if (dateStart !== null && dateEnd !== null) {
			if (dateStart > dateEnd) {
				toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'La fecha incial no puede ser mayor a la fecha final', life: 6000 });
			} else {
				if (selectedCliente !== null && selectedProyecto !== null && selectedTeamWork !== null
					&& selectedCargo !== null && selectedUsuario !== null && selectedTypeProject !== null) {
					if (selectedCliente.length !== 0 || selectedProyecto.length !== 0 ||
						selectedTeamWork.length !== 0 || selectedCargo.length !== 0
						|| selectedUsuario.length !== 0 || selectedTypeProject.length !== 0) {
						executeServices()
					}
				}
			}
		}

		return () => {
			if (source) {
				source.cancel()
			}
		}

	}, [dateStart, selectedCliente, selectedTypeProject, selectedProyecto, selectedTeamWork, selectedCargo, selectedUsuario, selectedCategory, selectedTipoActividad, dateEnd]); // eslint-disable-line react-hooks/exhaustive-deps

	let small = UseMedia("(max-width: 1300px)");

	let disabledProyecto = false;
	let proyectosError = false
	let mensajeProyectos = ''
	let teamWorkError = false
	let messageTeamWorkError = ''
	let disabledTeamWork

	/* if (tipoUsuario !== 'Cliente') {
		if (selectedCliente !== null && selectedTypeProject !== null) {
			if (selectedCliente.length === 0 || selectedTypeProject.length === 0) {
				disabledProyecto = true
			} else {
				disabledProyecto = false
			}
		} else {
			disabledProyecto = true
		}
	} */

	if (selectedCliente !== null) {
		if (selectedCliente.length === 0) {
			disabledTeamWork = true
			if (selectedTypeProject.length === 0) {
				disabledProyecto = true
			}

		} else if (servicesTeamWork.length === 0) {

			teamWorkError = true
			messageTeamWorkError = 'No existe un equipo de trabajo del cliente seleccionado'
		}
		else {
			disabledTeamWork = false
		}

	} else {
		disabledTeamWork = true
	}

	let disabledUser = false

	if (selectedCargo?.length === 0 && (selectedProyecto === null || selectedProyecto?.length === 0) && selectedTeamWork?.length === 0 && selectedCliente?.length === 0) {
		disabledUser = true
	}

	var disabledTypeActivity = false

	if (selectedCategory === null || selectedCategory.length === 0) {
		disabledTypeActivity = true
	}

	const [chartDataCategory, setChartDataCategory] = useState()
	const [/* chartDataTypeProject */, setChartDataTypeProject] = useState()

	return (

		<div className="">
			<Toast ref={toast} />
			<div className="p-fluid p-formgrid p-grid">
				<form style={{ display: "contents" }}>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">
							Fecha Inicio<span className="require">*</span>
						</label>
						<Calendar value={dateStart} onChange={onDateStartChange} locale="es" placeholder="Seleccione la fecha de inicio" dateFormat="dd-mm-yy" />
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">
							Fecha Fin<span className="require">*</span>
						</label>
						<Calendar value={dateEnd} onChange={onDateEndChange} locale="es" placeholder="Seleccione la fecha de fin" dateFormat="dd-mm-yy"
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Cliente</label>
						<MultiSelect value={selectedCliente} options={clientes} disabled={disabled} onChange={onClienteChange} name="cliente" optionLabel="name" placeholder="Seleccione un cliente"
							maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`} filter
						/>
					</div>

					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Equipo Trabajo</label>
						<MultiSelect value={selectedTeamWork} options={optionTeamWork} onChange={onTeamWorkChange} name="equipo" disabled={disabledTeamWork}
							maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTeamWork} Elementos Seleccionados`} filter placeholder="Seleccione un equipo de trabajo"
							className={teamWorkError ? "p-invalid" : ''} optionLabel="nombre"
						/>
						<p className="mensajeError">{teamWorkError ? messageTeamWorkError : ""}</p>
					</div>

					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Tipo de Proyecto</label>
						<MultiSelect value={selectedTypeProject} options={typesProjects} onChange={onTypesProjectsChange} optionLabel="name"
							maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTypeProject} Elementos Seleccionados`} filter placeholder="Seleccione un tipo de proyecto"
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Proyecto</label>
						<MultiSelect value={selectedProyecto} options={proyectos} onChange={onProyectoChange} name="proyecto" optionLabel="name" placeholder="Seleccione un proyecto"
							disabled={disabledProyecto} maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`} filter
						/>
						<p className="mensajeError">{proyectosError ? mensajeProyectos : ""}</p>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Cargo</label>

						<MultiSelect value={selectedCargo} selectAll={true} showSelectAll={true} options={cargos} onChange={onCargoChange} name="cargo" optionLabel="name" placeholder="Seleccione un cargo"
							maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedPosition} Elementos Seleccionados`} filter
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Funcionario</label>

						<MultiSelect value={selectedUsuario} options={usuarios} onChange={onUsuarioChange} name="usuario" optionLabel="name" placeholder="Seleccione un funcionario"
							maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedUser} Elementos Seleccionados`} filter
							disabled={disabledUser}
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Categoría</label>

						<MultiSelect value={selectedCategory} options={categories} onChange={onCategoryChange} name="category" optionLabel="name" placeholder="Seleccione una categoría"

							maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedCategory} Elementos Seleccionados`} filter
						/>
					</div>
					<div className="p-field p-col-12 p-md-2">
						<label className="labels">Tipo actividad<span className="require">{disabledTypeActivity ? "" : "*"}</span></label>
						<MultiSelect value={selectedTipoActividad} options={tipoActividades} onChange={onTipoActividadChange} name="tipoActividad" optionLabel="name" filter
							placeholder="Seleccione un tipo de actividad" disabled={disabledTypeActivity} maxSelectedLabels={1} selectedItemsLabel={`${itemsSelectedTypeActivity} Elementos Seleccionados`}
						/>
					</div>

					<div className="p-field p-col-12 p-md-12" style={{ marginTop: '5px' }}>
						{
							loading2 ?
								<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>

								:
								""
						}
					</div>
				</form>
				<div className="p-field p-col-12 p-md-12">
					<Divider />
				</div>
			</div>
			<h4 className="Tittle">Total de Horas - Proyecto - Tipo de actividad</h4>
			<div className="p-grid">
				<div className={"p-col-12"}>
					<Panel header="Tabla de datos">
						{
							<DataTable1
								data={dataFuncionariosTable}
								setDataChart={setChartData}
								dataChart={chartData}
								setChartDataCategory={setChartDataCategory}
								setFuncionarioSelected={setFuncionarioSelected}
								setChartDataTypeProject={setChartDataTypeProject}
								loadingProp={loading2}
							/>
						}
					</Panel>
				</div>
				<div className={small ? "p-col-12" : "p-col-6"}>
					<Panel header={"Horas - Tipo actividad - " + funcionarioSelected}>
						<Chart1 chartData={chartData} />
					</Panel>
				</div>
				{/* <div className={small ? "p-col-12" : "p-col-6"}>
					<Panel header={"Horas - Tipo proyecto - " + funcionarioSelected}>
						<Chart1 chartData={chartDataTypeProject} />
					</Panel>
				</div> */}
				<div className={small ? "p-col-12" : "p-col-6"}>
					<Panel header={"Horas - Categoría - " + funcionarioSelected}>
						<Chart1 chartData={chartDataCategory} />
					</Panel>
				</div>

			</div>
		</div>
	);
};
export default DetalleTipoAcividad;
