import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import ConfirmBtn from './ConfirmBtn.js'
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { CambiarFormatoFecha } from '../Funciones.js'
import Cookies from 'universal-cookie'
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import axios from 'axios';
const cookies = new Cookies()

const BtnCrearUsuario = ({ dataUsersProp }) => {

    const [servicioCargo, setServicioCargo] = useState([]);
    const [servicioRol, setServicioRol] = useState([]);
    const [servicioTipoDocumento, setServicioTipoDocumento] = useState([]);
    const [servicioCliente, setServicioCliente] = useState([]);
    const [servicioProyecto, setServicioProyecto] = useState([]);
    const [servicioTipoUsuario, setServicioTipoUsuario] = useState([]);
    const [servicioProveedor, setServicioProveedor] = useState([]);
    const [validateErrorDuplicity, setValidateErrorDuplicity] = useState(false)
    const [tienePermiso, setTienePermiso] = useState(false)
    const [openMessages, setOpenMessages] = useState(false)

    const getServices = new Services();
    const toast = useRef(null)

    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        let source; //Se crea una instancia para luego cancelar las peticiones
        if (displayBasic === true) {
            source = axios.CancelToken.source();
            getServices.getCargos().then(data => {
                if (data !== undefined) {
                    setServicioCargo(data)
                }
            });
            getServices.getRoles().then(data => {
                if (data !== undefined) {
                    setServicioRol(data)
                }
            });
            getServices.getTiposDocumento().then(data => {
                if (data !== undefined) {
                    setServicioTipoDocumento(data)
                }
            });
            getServices.getClientes().then(data => {
                if (data !== undefined) {
                    setServicioCliente(data)
                }
            });
            getServices.getProyectos().then(data => {
                if (data !== undefined) {
                    setServicioProyecto(data)
                }
            });
            getServices.getTipoUsuario().then(data => {
                if (data !== undefined) {
                    setServicioTipoUsuario(data)
                }
            })
            getServices.getSuppliers().then(data => {
                if (data !== undefined) {
                    setServicioProveedor(data)
                }
            })
        }
        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps



    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        resetData(name)
    }


    // Selects

    //Obtener datos del select tipo de documento

    const [selectedTipoDoc, setSelectedTipoDoc] = useState({
        tipoDocumento: ''
    });

    const tipoDocs = servicioTipoDocumento.map(item => {
        const av = { tipoDocumento: item.nombretipodocumento, id: item.id }
        return av
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onTipoDocChange = (e) => {
        setSelectedTipoDoc(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del select tipo de usuario

    const [selectedTipoUser, setSelectedTipoUser] = useState({
        tipoUsuario: '',
        id: ''
    });

    /* var newServicioTipoUsuario = servicioTipoUsuario.filter(function (objeto) {
        return objeto.descripcion === 'Colaborador'
    }) */

    const tipoUsers = servicioTipoUsuario.map(item => {
        const av = { tipoUsuario: item.descripcion, id: item.id }
        return av
    })

    const onTipoUserChange = (e) => {
        setSelectedTipoUser(e.value);
        setValidarCambio(true)
        setSelectedTipoDoc({
            tipoDocumento: ''
        })
        setSelectedCargo({
            cargo: '',
            id: ''
        })
        setSelectedRol({
            rol: '',
            id: ''
        })
        setSelectedEstado({
            estado: 'Inactivo'
        })
        setInputDoc({
            documento: ''
        })
        setInputNombre({
            nombre: ''
        })
        setInputApellido({
            apellido: ''
        })
        setInputUsuario({
            usuario: ''
        })
        setSelectedCliente({
            name: '',
            id: ''
        })
        setMostrarHorasAdicionales('')
        setSelectProviderType(null)
        setMultiSelectedCliente(null)
        setSelectedProyecto(null)
        setDate(null)
        setHourMin('')
        setHourMax('')
    }


    //Obtener datos del select tipo de cargo

    const [selectedCargo, setSelectedCargo] = useState({
        cargo: '',
        id: ''
    });

    const cargos = servicioCargo.map(item => {
        const av = { cargo: item.cargo, id: item.id }
        return av
    })

    const onCargoChange = (e) => {
        setSelectedCargo(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del select tipo de roles
    const [selectedRol, setSelectedRol] = useState({
        rol: '',
        id: '',
        permisos: ''
    });

    const roles = servicioRol.map(item => {
        const av = { rol: item.nombre, id: item.id, permisos: item.permisos }
        return av
    })

    const onRolChange = (e) => {
        setSelectedRol(e.value);
        setValidarCambio(true)
    }


    //Obtener datos del select tipo de estados

    const [selectedEstado, setSelectedEstado] = useState({
        estado: 'Inactivo'
    });
    const estados = [
        { estado: 'Activo' },
        { estado: 'Inactivo' },
        { estado: 'Bloqueado' }
    ];

    const onEstadoChange = (e) => {
        setSelectedEstado(e.value);
        setValidarCambio(true)
    }

    //HorasAdicionales
    const [mostrarHorasAdicionales, setMostrarHorasAdicionales] = useState('');
    const opcionesMostrarHorasAdicionales = [
        { valor: 'Si' },
        { valor: 'No' },
    ];

    const onOpcionesMostrarHorasAdicionalesChange = (e) => {
        setMostrarHorasAdicionales(e.value)
        setValidarCambio(true)
    }

    //Obtener datos del select tipo proveedor

    const [selectProviderType, setSelectProviderType] = useState(null)

    const optionsProvidersType = servicioProveedor.map(item => {
        const av = { name: item.razonsocial, id: item.id, clientes: item.clientes }
        return av
    })

    const onChangeProviderTyoe = (e) => {
        setSelectProviderType(e.value)
        setMultiSelectedCliente(null)
    }

    //Función que carga lista de clientes por proveedores cada vez que se cree un usuario tipo proveedor
    var listClientForSupplier = []
    const loadCustomer = () => {
        if (servicioCliente.length !== 0) {
            if (parseInt(selectedTipoUser.id) === 4) {
                selectProviderType.clientes.forEach(e => {
                    var idClient = e.cliente.id
                    const result = servicioCliente.filter(item => {
                        return idClient === item.id
                    })
                    result.forEach(e => {
                        listClientForSupplier.push(e)
                    })
                })
            } else {
                servicioCliente.forEach(e => {
                    listClientForSupplier.push(e)
                })
            }
        }
    }
    if (selectProviderType !== null) {
        loadCustomer()
    }
    var listClient = true
    if (selectedTipoUser.tipoUsuario === 'Proveedor') {
        listClient = false
    }
    //Obtener datos del select tipo de clientes

    const [multiSelectedCliente, setMultiSelectedCliente] = useState(null);
    const [selectedCliente, setSelectedCliente] = useState({
        name: '',
        id: '',
        dominio: ''
    });

    const clientes = (listClient ? servicioCliente : listClientForSupplier).map(item => {
        const av = { name: item.razonsocial, id: item.id, dominio: item.dominio }
        return av
    })

    const onClienteChange = (e) => {
        if (tipoUsuario === 'Cliente') {
            if (e.value.dominio !== null) {
                setInputUsuario({
                    usuario: '@' + e.value.dominio
                })
            } else {
                setInputUsuario({
                    usuario: ''
                })
            }
        }
        setSelectedCliente(e.value);
        setMultiSelectedCliente(e.value)
        setValidarCambio(true)
    }


    //Obtener datos del select tipo de proyecto

    const [selectedProyecto, setSelectedProyecto] = useState(null);

    var proyectoCliente = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const filterProjectForClient = (multiSelectedCliente) => {
        if (multiSelectedCliente !== null) {
            if (multiSelectedCliente.length !== 0) {
                multiSelectedCliente.forEach(element => {
                    const result = servicioProyecto.filter(function (objeto) {
                        var idCliente = objeto.cliente.id
                        return idCliente === element.id
                    })
                    result.forEach(element => {
                        proyectoCliente.push(element)
                    });
                    /* console.log(result) */
                    proyectoCliente.sort(function (a, b) {
                        var textA = a.nombreproyecto;
                        var textB = b.nombreproyecto;
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                })
                const filterDate = proyectoCliente.filter(element => {
                    return element.fechafinvigencia >= dateV
                })
                filterDate?.forEach(element => {
                    proyectoFechaVigente.push(element)
                })

                return proyectoFechaVigente
            }
        }
    }

    const cargarProyectos = () => {
        if (selectedTipoUser.tipoUsuario === 'Colaborador') {
            filterProjectForClient(multiSelectedCliente)

        } else if (selectedTipoUser.tipoUsuario === 'Cliente') {
            const result = servicioProyecto.filter(function (objeto) {
                var idCliente = objeto.cliente.id
                return idCliente === selectedCliente.id
            })
            result.forEach(element => {
                proyectoCliente.push(element)
            });
            /* console.log(result) */
            proyectoCliente.sort(function (a, b) {
                var textA = a.nombreproyecto;
                var textB = b.nombreproyecto;
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            const filterDate = proyectoCliente.filter(element => {
                return element.fechafinvigencia >= dateV
            })
            filterDate?.forEach(element => {
                proyectoFechaVigente.push(element)
            })
        } else if (selectedTipoUser.tipoUsuario === 'Proveedor') {
            filterProjectForClient(multiSelectedCliente)
        }
    }

    var dateValidError = false
    var messageDateValidError = ''

    if (selectedTipoUser.tipoUsuario === 'Colaborador' || selectedTipoUser.tipoUsuario === 'Proveedor') {
        if (multiSelectedCliente !== null) {
            cargarProyectos()
            if (proyectoFechaVigente.length === 0) {
                dateValidError = true
                messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
            } else {
                dateValidError = false
                messageDateValidError = ''
            }
            if (multiSelectedCliente.length === 0) {
                dateValidError = false
                messageDateValidError = ''
            }

        }
    } else {
        cargarProyectos()
        if (selectedCliente.name !== '') {
            if (proyectoFechaVigente.length === 0) {
                dateValidError = true
                messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
            } else {
                dateValidError = false
                messageDateValidError = ''
            }

        }
    }


    const proyectos = proyectoFechaVigente.map(item => {
        const av = { name: item.nombreproyecto, id: item.id }
        return av
    })

    const onProyectoChange = (e) => {
        setSelectedProyecto(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del input documento

    const [inputDoc, setInputDoc] = useState({
        documento: ''
    })

    const onDocChange = (event) => {
        setInputDoc({
            ...inputDoc,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }


    //validaciones para rol
    var rolError = false
    var mensajeRol = ''


    //Validar que si el rol tiene permiso de registrar actividad muestre horas mínimas y máximas
    useEffect(() => {
        if (selectedRol.permisos !== '' && selectedRol.permisos !== undefined) {
            let getRol = JSON.parse(selectedRol.permisos)
            let arrayPermisos = getRol.rol
            if (arrayPermisos[1].permisos[0] !== '') {
                setTienePermiso(true)
            } else {
                setTienePermiso(false)
                setHourMin('')
                setHourMax('')
            }
        }
    }, [selectedRol])
    //Obtener datos del input nombre

    const [inputNombre, setInputNombre] = useState({
        nombre: ''
    })

    const onNombreChange = (event) => {
        setInputNombre({
            ...inputNombre,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }
    //Obtener datos del input apellido


    const [inputApellido, setInputApellido] = useState({
        apellido: ''
    })

    const onApellidoChange = (event) => {
        setInputApellido({
            ...inputApellido,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input usuario

    const [inputUsuario, setInputUsuario] = useState({
        usuario: ''
    })
    const onUsuarioChange = (event) => {
        setInputUsuario({
            ...inputUsuario,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Validar que si existe un número de documento o un correo de usuario
    useEffect(() => {
        let existingDocument = []
        let existingEmail = []
        if (inputDoc.documento !== '') {
            dataUsersProp.forEach(element => {
                if (element.nrodocumento === inputDoc.documento) {
                    existingDocument.push(element)
                }
            })
        }
        if (inputUsuario.usuario !== '') {
            dataUsersProp.forEach(element => {
                if (element.usuario.toLowerCase() === inputUsuario.usuario.toLowerCase()) {
                    existingEmail.push(element)
                }
            })
        }
        if (existingDocument.length !== 0) {
            toast.current.show({ severity: 'error', summary: 'Usuario Pre-existente', detail: 'Ya hay un usuario con el mismo número de documento', life: 4000 });
            setValidateErrorDuplicity(true)
        }
        else if (existingEmail.length !== 0) {
            toast.current.show({ severity: 'error', summary: 'Usuario Pre-existente', detail: 'Ya hay un usuario con el mismo correo', life: 4000 });
            setValidateErrorDuplicity(true)
        } else {
            setValidateErrorDuplicity(false)
        }
    }, [inputDoc, inputUsuario]) // eslint-disable-line react-hooks/exhaustive-deps
    // calendar de fecha ingreso.

    const [date, setDate] = useState(null);

    const onConvertirFechaChange = (e) => {
        let fecha = e.value
        setDate(fecha)
        setValidarCambio(true)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });


    //Horas mínimas

    const [valueValidNumber, setValueValidNumber] = useState(false)
    const [messageValidNumber, setMessageValidNumber] = useState('')

    const [hourMin, setHourMin] = useState('')

    const onChangeHourMin = (e) => {
        setHourMin(e.target.value)
        setValidarCambio(true)
    }

    //Horas máximas
    const [valueValidNumberMax, setValueValidNumberMax] = useState(false)
    const [messageValidNumberMax, setMessageValidNumberMax] = useState('')

    const [hourMax, setHourMax] = useState('')

    const onChangeHourMax = (e) => {
        setHourMax(e.target.value)
        setValidarCambio(true)
    }

    useEffect(() => {


        if (hourMax !== '') {
            const getValidNumberMax = isValidNumber(hourMax)
            if (getValidNumberMax === false) {
                setValueValidNumberMax(true)
                setMessageValidNumberMax('Debe ingresar números enteros o decimales')
            } else {
                setValueValidNumberMax(false)
                setMessageValidNumberMax('')
            }
        }
        if (hourMin !== '') {
            const getValidNumber = isValidNumber(hourMin)
            if (getValidNumber === false) {
                setValueValidNumber(true)
                setMessageValidNumber('Debe ingresar números enteros o decimales')
            } else {
                setValueValidNumber(false)
                setMessageValidNumber('')
            }
        }

        function isValidNumber(value) {
            // Verifica si el valor es un número válido
            return /^\d+(\.\d+)?$/.test(value);
        }
    }, [hourMin, hourMax])

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    //Almacenamos los valores para enviarlos por props
    const tipoUsuario = selectedTipoUser.tipoUsuario
    const idTipoUsuario = selectedTipoUser.id
    const cliente = multiSelectedCliente
    const proyecto = selectedProyecto
    const numeroDoc = inputDoc.documento
    const tipoDocumento = selectedTipoDoc.tipoDocumento
    const idTipoDocumento = selectedTipoDoc.id
    const nombre = inputNombre.nombre
    const apellido = inputApellido.apellido
    const cargo = selectedCargo.cargo
    const idCargo = selectedCargo.id
    const usuario = inputUsuario.usuario
    const contraseña = inputDoc.documento
    const rol = selectedRol.rol
    const idRol = selectedRol.id
    const estado = selectedEstado.estado
    const idProveedor = selectProviderType?.id
    const historicoHoras = [{
        horasMinimas: hourMin,
        horasMaximas: hourMax,
        fechainicioasignacion: date,
        fechafinasignacion: '',
        estado: 1,
        fechacreacion: ''
    }]
    const horasAdicionales = mostrarHorasAdicionales?.valor ==='Si'?true:false
    var fecha

    if (date === null) {
        fecha = date
    } else {
        fecha = CambiarFormatoFecha(date)
    }

    const usuarioSesion = cookies.get('usuario')

    const resetData = (name) => {
        setSelectedTipoUser({
            tipoUsuario: '',
            id: ''
        })
        setSelectedTipoDoc({
            tipoDocumento: ''
        })
        setSelectedCargo({
            cargo: '',
            id: ''
        })
        setSelectedRol({
            rol: '',
            id: ''
        })
        setSelectedEstado({
            estado: ''
        })
        setInputDoc({
            documento: ''
        })
        setInputNombre({
            nombre: ''
        })
        setInputApellido({
            apellido: ''
        })
        setInputUsuario({
            usuario: ''
        })
        setSelectedCliente({
            name: '',
            id: ''
        })
        setHourMin('')
        setHourMax('')
        setMultiSelectedCliente(null)
        setSelectedProyecto(null)
        setDate(null)
        setSelectProviderType(null)
        setValidarCambio(false)
        setDisplayBasic(false)
        setMostrarHorasAdicionales('')
        dialogFuncMap[`${name}`](false);

    }
    // Modal para el botón regresar 

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        
        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }
        const resetData2 = (name) => {
            setSelectedTipoUser({
                tipoUsuario: '',
                id: ''
            })
            setSelectedTipoDoc({
                tipoDocumento: ''
            })
            setSelectedCargo({
                cargo: '',
                id: ''
            })
            setSelectedRol({
                rol: '',
                id: ''
            })
            setSelectedEstado({
                estado: ''
            })
            setInputDoc({
                documento: ''
            })
            setInputNombre({
                nombre: ''
            })
            setInputApellido({
                apellido: ''
            })
            setInputUsuario({
                usuario: ''
            })
            setSelectedCliente({
                name: '',
                id: ''
            })
            setHourMin('')
            setHourMax('')
            setMultiSelectedCliente(null)
            setSelectedProyecto(null)
            setDate(null)
            setSelectProviderType(null)
            setValidarCambio(false)
            setDisplayBasic(false)
            setMostrarHorasAdicionales('')
            dialogFuncMap[`${name}`](false);
    
        }
        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            

        }

        const Regresar = (name) => {
            resetData2(name)
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        )
    }


    const ShowModal = () => {

        const [displayBasic3, setDisplayBasic3] = useState(openMessages)

        const dialogFuncMap = {
            'displayBasic3': setDisplayBasic3,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setOpenMessages(false)
            window.location.href = "/registroActividades/consultarUsuarios"
        }

        const redirectModal = () => {
            setOpenMessages(false)
            window.location.href = "/registroActividades/equipoTrabajo"
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus />
                    <Button label="Si" icon="pi pi-check" onClick={() => redirectModal()} autoFocus />
                </div>
            )
        }

        return (
            <>
                <Dialog className="dialog-demo" header="Mensaje" visible={displayBasic3}
                    footer={renderFooter('displayBasic3')} modal breakpoints={{ '960px': '75vw' }} style={{ width: '35vw' }} onHide={() => onHide('displayBasic3')} >

                    <div className="confirmation-content">
                        <i className="pi pi-info p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>¿Señor usuario se redirigirá a la página de creación de equipos de trabajo para asignar el equipo de trabajo del colaborador? </span>
                    </div>
                </Dialog>
            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }

                {permisoCrear ?
                    <ConfirmBtn
                        titulo="Confirmar Registro"
                        mensaje="¿Estás seguro de registrar este usuario?"
                        accion="Crear"
                        icono="pi pi-plus-circle"
                        label="Guardar"
                        updateDateprop={false}
                        idTipoUsuarioProp={idTipoUsuario}
                        tipoUsuarioProp={tipoUsuario}
                        numeroDocProp={numeroDoc}
                        tipoDocumentoProp={tipoDocumento}
                        idTipoDocumentoProp={idTipoDocumento}
                        nombreProp={nombre}
                        apellidoProp={apellido}
                        cargoProp={cargo}
                        idCargoProp={idCargo}
                        cargosProp={servicioCargo}
                        usuarioProp={usuario}
                        contraseñaProp={contraseña}
                        rolProp={rol}
                        idRolProp={idRol}
                        estadoProp={estado}
                        proyectosProp={selectedProyecto}
                        fechaIngresoProp={fecha}
                        creadoporProp={usuarioSesion}
                        setOpen={setOpenMessages}
                        setDisplayBasicUser={setDisplayBasic}
                        ProveedorSeleccionadoProp={idProveedor}
                        historicoHorasProp={historicoHoras}
                        mostrarHorasAdicionalesProp={horasAdicionales}
                    />

                    :
                    ""
                }

            </div>
        );
    }
    //Desplegar formulario seguntipo de usuario
    var condicion = false
    if (tipoUsuario === 'Colaborador' || tipoUsuario === 'Cliente' || tipoUsuario === 'Proveedor') {
        condicion = true
    }

    //Validaciones para tipo de usuario
    var tipoUsuarioError = false
    var mensajeTipoUsuario = ''

    //Validaciones para Clientes
    var clientesError = false
    var mensajeClientes = ''



    ////validaciones proyectos
    var clientError = false
    var messageClient = ''
    //Validar que cliente se deshabilite cuando se seleccione un proveedor
    var disabledClient
    if (selectProviderType !== null) {
        disabledClient = false
        if (listClientForSupplier.length === 0) {
            clientError = true
            messageClient = 'El proveedor no tiene un cliente asociado'
        }
    } else {
        disabledClient = true
    }
    ////validaciones proyectos
    var proyectosError = false
    var mensajeProyectos = ''
    /// cuando ingrese un cliente 
    var disabledProyecto

    if (multiSelectedCliente !== null) {
        if (multiSelectedCliente.length === 0) {
            disabledProyecto = true
        } else {
            disabledProyecto = false

            if (proyectoCliente.length === 0) {
                proyectosError = true
                mensajeProyectos = 'El cliente o clientes no tienen proyectos asociados'
            }

        }
    } else {
        disabledProyecto = true
    }
    //Validaciones para Documento
    var documentoError = false
    var mensajeDocumento = ''
    if (numeroDoc.length < 5 && numeroDoc !== '') {
        documentoError = true
        mensajeDocumento = 'El número de documento debe ser mayor a 5 dígitos '
    } else if (numeroDoc.length > 12) {
        documentoError = true
        mensajeDocumento = 'El número de documento debe ser menor a 12 dígitos'
    }

    if (tipoDocumento !== 'Pasaporte' && tipoDocumento !== "Cédula Extranjería") {
        if (isNaN(numeroDoc) === true) {
            documentoError = true
            mensajeDocumento = 'Este tipo de documento no puede contener letras'
        }
    }

    //validaciones para tipo de docemento
    var tipoDocumentoError = false
    var mensajeTipoDocumento = ''

    //validaciones para nombre
    var nombreError = false
    var mensajeNombre = ''

    if (nombre.length <= 1 && nombre !== '') {
        nombreError = true
        mensajeNombre = 'Debe ingresar un nombre de más de 1 caracteres'
    } else if (nombre.length > 30) {
        nombreError = true
        mensajeNombre = 'Debe ingresar un nombre de menos de 30 caracteres'
    } else {
        nombreError = false
        mensajeNombre = ''
    }
    //validaciones para apellido

    var apellidoError = false
    var mensajeApellido = ''

    if (apellido.length <= 1 && apellido !== '') {
        apellidoError = true
        mensajeApellido = 'Debe ingresar un apellido de más de 1 caracteres'

    } else if (apellido.length > 30) {
        apellidoError = true
        mensajeApellido = 'Debe ingresar un apellido de menos de 30 caracteres'
    } else {
        apellidoError = false
        mensajeApellido = ''
    }

    //validaciones para cargo

    var cargoError = false
    var mensajeCargo = ''

    // validaciones para usuario
    var usuarioError = false
    var mensajeUsuario = ''
    if (usuario.length <= 5 && usuario !== '') {
        usuarioError = true
        mensajeUsuario = 'Debe ingresar un usuario de más de 5 caracteres'
    } else if (usuario.length > 60) {
        usuarioError = true
        mensajeUsuario = 'Debe ingresar un usuario de menos de 60 caracteres'
    } else if (usuario.length >= 5 && usuario.length < 60) {
        var expReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var esValido = expReg.test(usuario)
        if (esValido === false) {
            usuarioError = true
            mensajeUsuario = 'El correo ingresado no es válido'
        } else {
            var dominiosValidos
            if (tipoUsuario === 'Proveedor') {
                let dominios = servicioProveedor.concat(servicioCliente)
                dominiosValidos = dominios.map(item => {
                    return item.dominio
                })
            } else {
                dominiosValidos = servicioCliente.map(item => {
                    return item.dominio
                })
            }
            var validarCorreoUsuario = usuario.split('@')
            if (validarCorreoUsuario.length !== 0) {
                var boolean = false
                if (dominiosValidos !== undefined) {
                    dominiosValidos.forEach(element => {
                        if (validarCorreoUsuario[1] === element) {
                            boolean = true
                        }
                    })
                }
                if (boolean === false) {
                    usuarioError = true
                    mensajeUsuario = 'El correo ingresado no es un correo corporativo válido'
                }
            }
        }
    }


    //Validar que horas míminas no sea mayor que las horas máximas
    let errorHour = false
    let messageHourError = ''
    let errorHourMax = false
    let messageHourErrorMax = ''
    if (hourMin !== '' && hourMax !== '') {
        if (parseFloat(hourMin) > parseFloat(hourMax)) {
            errorHour = true
            messageHourError = 'Las horas mínimas no pueden ser mayor a las horas máximas'
        } else if (hourMax > 24) {
            errorHourMax = true
            messageHourErrorMax = 'No se permite registrar más de 24h'
        }
        else {
            errorHour = false
            messageHourError = ''
        }
    }

    //Validar que no hay ningun error
    var permisoCrear = false

    if (tipoUsuarioError === false && tipoUsuario !== '' && clientesError === false && proyectosError === false && documentoError === false && numeroDoc !== ''
        && tipoDocumentoError === false && tipoDocumento !== '' && nombreError === false && nombre !== '' && apellidoError === false &&
        apellido !== '' && cargoError === false && cargo !== '' && usuarioError === false
        && usuario !== '' && rolError === false && date !== null && clientError === false &&
        selectedRol.rol !== '' && validateErrorDuplicity === false && errorHour === false && errorHourMax === false && valueValidNumber === false && valueValidNumberMax === false && mostrarHorasAdicionales!=='') {

        if (cliente === null || cliente.length === 0) {
            permisoCrear = false
        } else if (proyecto === null || proyecto.length === 0) {
            permisoCrear = false
        } else if (tienePermiso === true && (hourMin === '' || hourMax === '')) {
            permisoCrear = false
        }
        else {
            permisoCrear = true
        }

    }
    //validar cuando es pasaporte 
    var validarPasaporte = false

    if (selectedTipoDoc.tipoDocumento === "Pasaporte" || selectedTipoDoc.tipoDocumento === "Cédula Extranjería") {
        validarPasaporte = true
    }


    return (

        <div className="">
            <Toast ref={toast} />
            <div>
                {/* <Button label="Crear usuario" icon="pi pi-external-link" onClick={() => onClick('displayBasic')} /> */}
                <button className="btnAdd" onClick={() => onClick('displayBasic')} ><span className="pi pi-plus Add"></span>Crear Usuario</button>

                <ShowModal />

                <Dialog className="modal" header="Crear Usuario" visible={displayBasic} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>

                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" htmlFor="tipoDoc">Tipo Usuario<span className="require">*</span></label>
                                <Dropdown value={selectedTipoUser} options={tipoUsers} onChange={onTipoUserChange} name="tipoUsuario" optionLabel="tipoUsuario" placeholder="Seleccione tipo de Usuario"
                                    className={tipoUsuarioError ? "p-invalid" : ""} />
                                <p className="mensajeError">{tipoUsuarioError ? mensajeTipoUsuario : ""}</p>
                            </div>


                            {condicion ?
                                <>
                                    {
                                        tipoUsuario === 'Cliente' ?
                                            <div className="p-field p-col-12 p-md-6">
                                                <label className="labels" htmlFor="tipoDoc">Cliente<span className="require">*</span></label>
                                                <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="name" optionLabel="name"
                                                    placeholder="Seleccione un cliente"
                                                    className={clientesError ? "p-invalid" : ""}
                                                />
                                                <p className="mensajeError">{clientesError ? mensajeClientes : ""}</p>
                                            </div>
                                            :
                                            tipoUsuario === 'Proveedor' ?
                                                <>
                                                    <div className="p-field p-col-12 p-md-6">
                                                        <label className="labels" htmlFor="tipoDoc">Proveedor<span className="require">*</span></label>
                                                        <Dropdown value={selectProviderType} options={optionsProvidersType} onChange={onChangeProviderTyoe} optionLabel="name"
                                                            placeholder="Seleccione un proveedor" filter
                                                        />
                                                    </div>
                                                    <div className="p-field p-col-12 p-md-6">
                                                        <label className="labels" htmlFor="tipoDoc">Cliente<span className="require">*</span></label>
                                                        <MultiSelect value={multiSelectedCliente} options={clientes} onChange={onClienteChange} optionLabel="name" name="cliente"
                                                            placeholder="Seleccione un cliente" filter
                                                            className={clientError ? "p-invalid" : ""} disabled={disabledClient}
                                                        />
                                                        <p className="mensajeError">{clientError ? messageClient : ""}</p>
                                                    </div>
                                                </>
                                                :
                                                <div className="p-field p-col-12 p-md-6">
                                                    <label className="labels" htmlFor="tipoDoc">Cliente<span className="require">*</span></label>
                                                    <MultiSelect value={multiSelectedCliente} options={clientes} onChange={onClienteChange} optionLabel="name" name="cliente"
                                                        placeholder="Seleccione un cliente" filter
                                                        className={clientesError ? "p-invalid" : ""}
                                                    />
                                                    <p className="mensajeError">{clientesError ? mensajeClientes : ""}</p>
                                                </div>

                                    }
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="tipoDoc">Proyecto<span className="require">*</span></label>
                                        <MultiSelect value={selectedProyecto} options={proyectos} onChange={onProyectoChange} optionLabel="name" name="proyecto"
                                            placeholder="Seleccione un proyecto" disabled={disabledProyecto}
                                            className={proyectosError || dateValidError ? "p-invalid" : ""} filter
                                        />
                                        <p className="mensajeError">{proyectosError ? mensajeProyectos : ""}</p>
                                        <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="rol">Rol<span className="require">*</span></label>
                                        <Dropdown value={selectedRol} options={roles} onChange={onRolChange} name="rol" optionLabel="rol"
                                            placeholder="Seleccione Rol"
                                            className={rolError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{rolError ? mensajeRol : ""}</p>
                                    </div>



                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="tipoDoc">Tipo documento<span className="require">*</span></label>
                                        <Dropdown value={selectedTipoDoc} options={tipoDocs} onChange={onTipoDocChange} name="tipoDocumento" optionLabel="tipoDocumento"
                                            placeholder="Seleccione tipo de documento"
                                            className={tipoDocumentoError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{tipoDocumentoError ? mensajeTipoDocumento : ""}</p>
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="numDoc">Número documento<span className="require">*</span></label>
                                        <InputText id="numDoc" type="text" name="documento" keyfilter={validarPasaporte ? "alphanum" : "pint"} onChange={onDocChange} placeholder="Ingrese un número de documento" value={numeroDoc}
                                            className={documentoError ? "p-invalid" : ""} />
                                        <p className="mensajeError">{documentoError ? mensajeDocumento : ""}</p>
                                    </div>


                                    <>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label className="labels" htmlFor="nombre">Nombre<span className="require">*</span></label>
                                            <InputText id="nombre" type="text" name="nombre" keyfilter={/^[^<0-9>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/} onChange={onNombreChange} placeholder="Ingrese un nombre" value={nombre}
                                                className={nombreError ? "p-invalid" : ""}
                                            />
                                            <p className="mensajeError">{nombreError ? mensajeNombre : ""}</p>
                                        </div>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label className="labels" htmlFor="apellido">Apellido<span className="require">*</span></label>
                                            <InputText id="apellido" type="text" name="apellido" keyfilter={/^[^<0-9>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/} onChange={onApellidoChange} placeholder="Ingrese un apellido" value={apellido}
                                                className={apellidoError ? "p-invalid" : ""}
                                            />
                                            <p className="mensajeError">{apellidoError ? mensajeApellido : ""}</p>
                                        </div>
                                    </>
                                    {
                                        tienePermiso ?
                                            <>

                                                <div className="p-field p-col-12 p-md-3">
                                                    <label className="labels" htmlFor="usuario">Horas Mínimas (Diarias)<span className="require">*</span></label>
                                                    <InputText name="horaMin" value={hourMin} onChange={onChangeHourMin}
                                                        placeholder="Ingrese las fechas Mínimas" keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                        className={errorHour || valueValidNumber ? "p-invalid" : ""}
                                                    />
                                                    <p className="mensajeError">{errorHour ? messageHourError : ""}{valueValidNumber ? messageValidNumber : ""}</p>
                                                </div>

                                                <div className="p-field p-col-12 p-md-3">
                                                    <label className="labels" htmlFor="usuario">Horas Máximas (Diarias)<span className="require">*</span></label>
                                                    <InputText name="horaMax" value={hourMax} onChange={onChangeHourMax}
                                                        placeholder="Ingrese las fechas Máximas" keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                        className={errorHourMax || valueValidNumberMax ? "p-invalid" : ""}
                                                    />
                                                    <p className="mensajeError">{errorHourMax ? messageHourErrorMax : ""}{valueValidNumberMax ? messageValidNumberMax : ""}</p>
                                                </div>
                                            </>
                                            :
                                            ''
                                    }
                                    <>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label className="labels" htmlFor="cargo">Cargo<span className="require">*</span></label>
                                            <Dropdown value={selectedCargo} options={cargos} onChange={onCargoChange} name="cargo" optionLabel="cargo"
                                                placeholder="Seleccione Cargo" filter
                                                className={cargoError ? "p-invalid" : ""}
                                            />
                                            <p className="mensajeError">{cargoError ? mensajeCargo : ""}</p>
                                        </div>
                                    </>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="usuario">Usuario<span className="require">*</span></label>
                                        <InputText id="usuario" type="text" name="usuario" keyfilter="email" onChange={onUsuarioChange} placeholder="Ingrese un usuario"
                                            className={usuarioError ? "p-invalid" : ""} value={usuario}
                                            suffix=" mi"
                                        />
                                        <p className="mensajeError">{usuarioError ? mensajeUsuario : ""}</p>
                                    </div>

                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" >Fecha de ingreso del usuario<span className="require">*</span></label>
                                        <Calendar placeholder="Ingrese una fecha " value={date} onChange={onConvertirFechaChange} dateFormat="dd-mm-yy" locale="es"
                                            monthNavigator yearNavigator yearRange="2010:2030"
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label className="labels" htmlFor="contraseña">Contraseña</label>
                                        <InputText value={inputDoc.documento} id="contraseña" type="text" keyfilter="pint" disabled />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <label className="labels" htmlFor="estado">Estado</label>
                                        <Dropdown value={selectedEstado} options={estados} onChange={onEstadoChange} name="estado" optionLabel="estado" placeholder="Seleccione Estado" disabled />
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <label className="labels" htmlFor="estado">Mostrar Horas Adicionales<span className="require">*</span></label>
                                        <Dropdown value={mostrarHorasAdicionales} options={opcionesMostrarHorasAdicionales}
                                            onChange={onOpcionesMostrarHorasAdicionalesChange} name="valor" optionLabel="valor"
                                            placeholder="Seleccione una opción" />
                                    </div>

                                </>
                                :
                                <div>

                                </div>
                            }

                        </form>

                    </div>
                </Dialog>
            </div>
        </div>
    )


}
export default BtnCrearUsuario