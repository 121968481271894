import React, { useEffect, useRef, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";
import Services from '../../service/Services';
import BtnCrearTipoProyecto from "./BtnCrearTipoProyecto";
import { Column } from 'primereact/column';
import ConfirmBtn from "./ConfirmBtn";
import BtnActualizarTipoProyecto from "./BtnActualizarTipoProyecto";

const getServices = new Services();

const ConsultarTiposDeProyectos = ({ permisosProp }) => {

    const [servicio, setServicio] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getServices.getTypesProjects().then(data => {
            setServicio(data)
            setLoading(false)
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
    var permisoTypeProjectCreate
    if (permisosProp[23].permisos[0] === "Crear") {
        permisoTypeProjectCreate = true
    } else {
        permisoTypeProjectCreate = false
    }
    var permisoTypeProjectUpdate
    if (permisosProp[23].permisos[1] === "Editar") {
        permisoTypeProjectUpdate = true
    } else {
        permisoTypeProjectUpdate = false
    }
    var permisoTypeProjectDelete
    if (permisosProp[23].permisos[2] === "Eliminar") {
        permisoTypeProjectDelete = true
    } else {
        permisoTypeProjectDelete = false
    }

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {
                permisoTypeProjectCreate ?
                    <BtnCrearTipoProyecto 
                        listTypeProjectProp={servicio}
                    />
                    :
                    ''
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );

    const Acciones = (rowData) => {
        return (
            <>
                <BtnActualizarTipoProyecto
                    idTipoProyectoProp={rowData.id}
                    fechacreacionProp={rowData.fechacreacion}
                    nombreTipoProyectoProp={rowData.nombretipoproyecto}
                    listTypeProjectProp={servicio}
                />
                <ConfirmBtn
                    titulo="Confirmar Eliminación"
                    mensaje="¿Está seguro de eliminar este Proyecto?, Recuerde que una vez se elimine, se borrará definitivamente del sistema"
                    accion="Eliminar"
                    clase="p-button-danger"
                    icono="pi pi-trash"
                    tooltipProp="Eliminar"
                    idTipoProyectoProp={rowData.id}

                />
            </>
        )

    }

    var permisosAcciones
    if (permisoTypeProjectUpdate === false && permisoTypeProjectDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable ref={dt} value={servicio} className="p-datatable-responsive-demo" paginator rows={10} globalFilter={globalFilter} header={header}
                    emptyMessage="Tipo de proyecto no registrado en el sistema" sortField="id" sortOrder={1} loading={loading}>
                    <Column className="columna" field="id" sortable header="Id Tipo Proyecto" />
                    <Column className="columna" field="nombretipoproyecto" sortable header="Tipo de Proyecto" />
                    {permisosAcciones ?
                        <Column header="Acciones" body={Acciones} />
                        :
                        <Column />
                    }
                </DataTable>
            </div>
        </div>
    )
}

export default ConsultarTiposDeProyectos