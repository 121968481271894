import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import Services from '../../service/Services';
import ConfirmBtn from './ConfirmBtn'
import '../../assets/css/Forms.css'
import '../../assets/css/Btn.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents, Validarfechas } from '../Funciones';
import axios from 'axios';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const BtnCrearProyecto = ({ proyectosProp }) => {

    const toast = useRef(null)
    const [servicioCliente, setServicioCliente] = useState([]);
    const [servicesTypesProjects, setServicesTypeProjects] = useState([]);

    const getServices = new Services();

    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        let source;
        if (displayBasic === true) {
            source = axios.CancelToken.source();
            getServices.getClientes().then(data => {
                if (data!==undefined) {
                    setServicioCliente(data)
                }
            });
            getServices.getTypesProjects().then(data =>{
                if (data !==undefined) {
                    setServicesTypeProjects(data)
                }
            })
        }
        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        setInputProyecto({
            proyecto: ''
        })
        setSelectedCliente({
            cliente: '',
            id: ''
        })
        setDateStartError(false)
        setMessageDateStart('')
        setDateStart(null)
        setDateEnd(null)
        setValidarCambio(false)
        setSelectedTypeProject(null)
        setDisplayBasic(false)
        dialogFuncMap[`${name}`](false);
    }

    //Obtener datos del input proyecto

    const [inputProyecto, setInputProyecto] = useState({
        proyecto: ''
    })

    const [validarCambio, setValidarCambio] = useState(false)

    const onProyectoChange = (event) => {

        setInputProyecto({
            ...inputProyecto,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del select tipo de clientes

    const [selectedCliente, setSelectedCliente] = useState({
        cliente: '',
        id: ''
    });

    const clientes = servicioCliente.map(item => {
        const av = { cliente: item.razonsocial, id: item.id }


        return av


    })

    const onClienteChange = (e) => {
        setSelectedCliente(e.value);
        setValidarCambio(true)
    }

    //Tipo de proyectos

    const [selectedTypeProject, setSelectedTypeProject] = useState(null);

    const onTypeProjectCompleteChange = (e) => {
        setSelectedTypeProject(e.value);
        setValidarCambio(true)

    }

    const [dateStartError, setDateStartError] = useState(false)
    const [messageDateStart, setMessageDateStart] = useState('')

    /// calendar de fecha  inicio

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let fecha = e.value
        setDateStart(fecha)
        setValidarCambio(true)
        if (dateEnd !== null) {
            var validateDate = Validarfechas(fecha, dateEnd)
            setDateStartError(validateDate.setFechaError)
            setMessageDateStart(validateDate.setMensajeFecha)
        }
    }

    /// calendar de fecha fin

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let fecha = e.value
        setDateEnd(fecha)
        setValidarCambio(true)
        if (dateStart !== null) {
            var validateDate = Validarfechas(dateStart, fecha)
            setDateStartError(validateDate.setFechaError)
            setMessageDateStart(validateDate.setMensajeFecha)
        }
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }


    //Almacenamos los valores para enviarlos por props

    const proyecto = inputProyecto.proyecto
    const cliente = selectedCliente.cliente
    const idCliente = selectedCliente.id
    const usuario = cookies.get('usuario')
    const idTipoProyecto = selectedTypeProject?.id


    /* console.log(proyectoRepetido) */

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {

            setInputProyecto({
                proyecto: ''
            })
            setSelectedCliente({
                cliente: '',
                id: ''
            })
            setDateStartError(false)
            setMessageDateStart('')
            setDateStart(null)
            setDateEnd(null)
            setValidarCambio(false)
            setSelectedTypeProject(null)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);

        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Registro"
                            mensaje="¿Estás seguro de registrar este Proyecto?"
                            accion="Crear"
                            label="Guardar"
                            icono="pi pi-plus-circle"
                            idClienteProp={idCliente}
                            proyectoProp={proyecto}
                            dateStartProp={dateStart}
                            dateEndProp={dateEnd}
                            usuarioProp={usuario}
                            idTipoProyectoProp={idTipoProyecto}
                        />
                        :
                        ""
                }

            </div>
        );
    }

    //validaciones

    var proyectoError = false
    var mensajeProyecto = ''
    if (proyecto.length < 3 && proyecto !== '') {
        proyectoError = true
        mensajeProyecto = 'El nombre del proyecto debe ser mayor a 3 dígitos'
    } else if (proyecto.length > 100) {
        proyectoError = true
        mensajeProyecto = 'El nombre del proyecto debe ser menor a 100 dígitos'
    }
    var validateErrorDuplicity = false
    function validateDuplicity() {
        proyectosProp.forEach(element => {
            const nameProject = removeAccents(element.nombreproyecto)
            const newNameProject = removeAccents(proyecto)

            if (nameProject.toUpperCase() === newNameProject.toUpperCase() &&   
            parseInt(element.cliente.id) === parseInt(idCliente)) {
                validateErrorDuplicity = true
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: 'Proyecto pre-existente' });
            }
        })
    }
    if (proyecto !== '') {
        validateDuplicity()
    }
    var clienteError = false
    var mensajeCliente = ''

    var permisoRegistrar = false
    if (proyectoError === false && proyecto !== '' && clienteError === false && cliente !== '' &&
        dateStartError === false && dateStart !== null && dateEnd !== null
        && selectedTypeProject !== null && selectedTypeProject !== undefined && validateErrorDuplicity === false) {
        permisoRegistrar = true
    }


    return (

        <div className="">
            <div>
                <Toast ref={toast} />
                <button className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Agregar Proyecto</button>

                <Dialog className="modal" header="Registrar Proyecto" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => {onHide('displayBasic')}}>
                    <div className="p-fluid p-formgrid p-grid">

                        <form style={{ display: 'contents' }} >

                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Cliente<span className="require">*</span></label>
                                <Dropdown value={selectedCliente} options={clientes} onChange={onClienteChange} name="cliente" optionLabel="cliente"
                                    placeholder="Seleccione un cliente" filter
                                    className={clienteError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{clienteError ? mensajeCliente : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Proyecto<span className="require">*</span></label>
                                <InputText id="proyecto" type="text" name="proyecto" keyfilter={/^[^<>*!"=°|(#$%)+._/?¡¿'{}]+$/} onChange={onProyectoChange}
                                    placeholder="Registre el nombre del proyecto"
                                    className={proyectoError || validateErrorDuplicity ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{proyectoError ? mensajeProyecto : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Tipo Proyecto<span className="require">*</span></label>
                                <Dropdown filter value={selectedTypeProject} options={servicesTypesProjects} optionLabel="nombretipoproyecto" onChange={onTypeProjectCompleteChange}
                                    dropdown className='auto' placeholder='Seleccione un tipo de proyecto' field='nombretipoproyecto' showClear
                                    emptyFilterMessage='"No se encontraron resultados"' emptyMessage="No se encontraron resultados" resetFilterOnHide />
                            </div>


                            <div className='p-field p-col-12 p-md-6'>
                                <label className="labels" >Fecha inicio vigencia<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                    className={dateStartError ? "p-invalid" : ""} />
                                <p className="mensajeError">{dateStartError ? messageDateStart : ""}</p>

                            </div> 
                            <div className='p-field p-col-12 p-md-6'>
                                <label className="labels" >Fecha fin vigencia<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es" />
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearProyecto