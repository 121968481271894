import React, { useState, useEffect, useRef, memo } from "react";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { UseMedia } from '../Funciones'
import DataTable1 from "../Home/Indicadores/DetalleTipoActividad/DataTable1";
import Chart1 from "../Home/Indicadores/DetalleTipoActividad/Chart1";
import Services from "../../service/Services";
import Cookies from 'universal-cookie';
import axios from "axios";
const cookies = new Cookies();

const Block5 = memo(({ dateStartDBProp, dateEndDBProp, getProjectsProp, setChartDataProp, setDataOfficialProp, individualMonthReportProp, readReportProp, userSelectedProp,
    setDataUserProp, monthlyReportProp, listReportsUsersProp, projectsUserSessionProp, setClients, setLoadingProp }) => {

    const [dataFuncionariosTable, setDatafuncionariosTable] = useState([]);
    const [funcionarioSelected, setFuncionarioSelected] = useState("");

    const getService = new Services();

    const userDocSession = cookies.get('numeroDocumento')
    const [previousValue, setPreviousValue] = useState(null);
    const getPrincipalData = (dateStartDBProp, dateEndDBProp, getProjectsProp, document, informeOtroFuncionario, servicioTipoNovedad) => {
        var projectClient = []
        let result = []
        if (informeOtroFuncionario === true) {
            const result = getProjectsProp.filter(item => {
                const idClient = parseInt(item.proyecto.cliente.id)
                return idClient === setClients.id
            })
            result.forEach(element => {
                var selectedProject = {}
                selectedProject.id = element.proyecto.id
                selectedProject.nombre = element.proyecto.nombreproyecto
                selectedProject.fechainiciovigencia = element.fechainiciovigencia
                selectedProject.fechafinvigencia = element.fechafinvigencia
                projectClient.push(selectedProject)

            });
        } else {
            result = getProjectsProp.filter(function (object) {
                var idClient = parseInt(object.cliente.id)
                return idClient === setClients.id
            })
            result.forEach(element => {
                var selectedProject = {}
                selectedProject.id = element.id
                selectedProject.nombre = element.nombre
                selectedProject.fechainiciovigencia = element.fechainiciovigencia
                selectedProject.fechafinvigencia = element.fechafinvigencia
                projectClient.push(selectedProject)

            });
        }

        projectClient.sort(function (a, b) {
            var textA = a.nombre;
            var textB = b.nombre;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        var newGetProjectsProp = projectClient.map(item => {
            return parseInt(item.id)
        })

        const data = {
            fecFinal: dateEndDBProp,
            fecInicial: dateStartDBProp,
            idActividades: [],
            idCargo: [],
            idClientes: [],
            idEquipoTrabajo: [],
            idProyectos: newGetProjectsProp,
            idUsuarios: readReportProp ? document !== undefined ? ["" + document + ""] : ["" + userDocSession + ""] : ["" + userDocSession + ""],
        };

        if (data.idProyectos.length !== 0) {
            setPreviousValue(data)
            if (JSON.stringify(previousValue) !== JSON.stringify(data)) {
                getService.dashboardTypeActivityProject(data).then((res) => {
                    
                    if (res.status === 200) {


                        const newData = res.data.map(item => {
  
                                return {

                                    detalle: item.detalle.map(j => {

                                        return {
                                            categoria: j.categoria,
                                            horas: j.horas.toFixed(2),
                                            porcentajeHoras: j.porcentajeHoras,
                                            tipoActividad: j.tipoActividad,
                                        }
                                    }),
                                    horasNovedades: item.detalle.reduce((sum, z) => {
                                        servicioTipoNovedad.forEach(tp => {
                                            if (tp.tipoActividad.id === z.idTipoActividad) {
                                                sum += z?.horas || 0;
                                            }
                                        });
                                        return sum;
                                    }, 0),
                                    id: item.id,
                                    idproyecto: item.idproyecto,
                                    proyecto: item.proyecto,
                                    totalHoras: item.totalHoras.toFixed(2),
                                    totalPorcentaje: item.totalPorcentaje,
                                    usuario: item.usuario,
                                }
                            
                        })

                        setDatafuncionariosTable(newData);
                        if (setDataOfficialProp !== undefined) {
                            setDataOfficialProp(newData)
                        } else {
                            setDataOfficialProp([])
                        }
                        setLoadingProp(false)

                    } else {
                        setLoadingProp(false)
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }; // eslint-disable-next-line react-hooks/exhaustive-deps

    const toast = useRef(null);
    useEffect(() => {
        const servicioTipoNovedad = getService.getTipoNovedad().then(data => {
            if (data !== undefined) {
                return data
            }
        });
        const source = axios.CancelToken.source()

        servicioTipoNovedad.then(tipoNovedad => {


            if (dateStartDBProp && dateEndDBProp && projectsUserSessionProp.lenght !== 0) {
                if (readReportProp === true && (monthlyReportProp === 'monthlyReportOtherOfficial' || monthlyReportProp === 'allMonthlyReportOfOfficial') && userSelectedProp.id !== '') {
                    getService.getUsuarios().then(data => {
                        if (data !== undefined) {

                            var filterUserData = data.filter(function (object) {
                                var idUser = object.idUsuario
                                return parseInt(idUser) === parseInt(userSelectedProp.id)
                            })
                            setDataUserProp(filterUserData)
                            /*  setLoadingProp(true) */
                            if (tipoNovedad !== undefined) {

                                getPrincipalData(dateStartDBProp, dateEndDBProp, filterUserData[0].proyectos, filterUserData[0].nrodocumento, true, tipoNovedad);
                            }
                        }

                    })
                } else {
                    /*  setLoadingProp(true) */
                    /*  console.log('execute'); */
                    let arrayProjects = []
                    getProjectsProp.forEach(e => {
                        const filterProjects = projectsUserSessionProp.filter(obj => {
                            return parseInt(obj.id) === parseInt(e.id)
                        })
                        filterProjects.forEach(e => {
                            arrayProjects.push(e)
                        })
                    })

                    if (arrayProjects.length !== 0 && tipoNovedad !== undefined) {
                        getPrincipalData(dateStartDBProp, dateEndDBProp, arrayProjects, '', false, tipoNovedad);
                    }

                }

            }
        });

        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [dateStartDBProp, dateEndDBProp, getProjectsProp, projectsUserSessionProp, readReportProp, userSelectedProp, monthlyReportProp]); // eslint-disable-line react-hooks/exhaustive-deps



    const chartDataDummy = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#8500e7",
                    "#ffe601",
                    "#de880a",
                    "#42A5F5",
                    "#66BB6A",
                    "#8500e7",
                    "#ffe601",
                    "#de880a",
                    "#42A5F5",
                    "#66BB6A",
                    "#8500e7",
                    "#ffe601",
                    "#de880a",
                ],
                hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
            },
        ],
    };

    const [chartData, setChartData] = useState(chartDataDummy);
    const [chartDataCategory, setChartDataCategory] = useState()

    let small = UseMedia("(max-width: 1450px)");

    return (
        <>
            <div className="p-grid">

                <Toast ref={toast} />
                <div className={small ? "p-col-12" : "p-col-6"}>
                    <Panel header="Tabla de datos">
                        {
                            <DataTable1
                                data={dataFuncionariosTable}
                                setDataChart={setChartData}
                                setChartDataProp={setChartDataProp}
                                dataChart={chartData}
                                setChartDataCategory={setChartDataCategory}
                                chartDataCategory={chartDataCategory}
                                setFuncionarioSelected={setFuncionarioSelected}
                                individualMonthReportProp={individualMonthReportProp}
                            />
                        }
                    </Panel>
                </div>
                <div className={small ? "p-col-12" : "p-col-6"}>
                    <Panel header={"Horas - Tipo actividad - " + funcionarioSelected}>
                        <Chart1 chartData={chartData} />
                    </Panel>
                </div>
            </div>
        </>
    )
})

export default Block5