import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../../index.css';

import React from 'react';
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.fr'

const Calendario = ({ fechasProp }) => {

    const currentYear = new Date().getFullYear()
    const minDate = null
    const maxDate = null
    const language = 'es'
    const styles = { background: "background" }
    const allowOverlap = true
    const enableRangeSelection = false
    const displayWeekNumber = false
    const roundRangeLimits = false
    const alwaysHalfDay = false

    Calendar.locales['es'] = {
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        weekShort: 'S',
        weekStart: 1
    };

    const dataSource = []
    const Dates = () => {
        fechasProp.forEach(element => {
            var a = new Date(element)

            let month = a.getMonth() + 1;
            let day = a.getDate();
            let monthNumber = Number(month - 1);
            let dayNumber = Number(day);
            let year = a.getFullYear();

            if (month < 10) {
                month = '0' + month;
            }

            if (day < 10) {
                day = '0' + day;
            }
            
            var datesInCalendar = {}
            datesInCalendar.startDate = new Date(year, monthNumber, dayNumber)
            datesInCalendar.endDate = new Date(year, monthNumber, dayNumber)
            dataSource.push(datesInCalendar)
        });
    }


    if (fechasProp !== undefined) {
        Dates()
    }




    return (
        <div style={{ marginTop: '25px' }}>
            <Calendar
                year={currentYear}
                minDate={minDate}
                maxDate={maxDate}
                language={language}
                style={styles.background}
                allowOverlap={allowOverlap}
                enableRangeSelection={enableRangeSelection}
                displayWeekNumber={displayWeekNumber}
                roundRangeLimits={roundRangeLimits}
                alwaysHalfDay={alwaysHalfDay}
                dataSource={dataSource}
            />
        </div>

    );
}

export default Calendario

/* const currentYear = new Date().getFullYear();

class Calendario extends React.Component {
    constructor() {
        super();
        this.state = {
            currentYear: new Date().getFullYear(),
            minDate: null,
            maxDate: null,
            language: 'en',
            style: 'background',
            allowOverlap: true,
            enableRangeSelection: false,
            displayWeekNumber: false,
            roundRangeLimits: false,
            alwaysHalfDay: false
        };
    }



    render() {
        return (

            <Calendar
                year={currentYear}
                minDate={minDate}
                maxDate={maxDate}
                language={language}
                style={style}
                allowOverlap={allowOverlap}
                enableRangeSelection={enableRangeSelection}
                displayWeekNumber={displayWeekNumber}
                roundRangeLimits={roundRangeLimits}
                alwaysHalfDay={alwaysHalfDay}
                dataSource={[
                    {
                        startDate: new Date(currentYear, 1, 4),
                        endDate: new Date(currentYear, 1, 15)
                    },
                    {
                        startDate: new Date(currentYear, 3, 5),
                        endDate: new Date(currentYear, 5, 15)
                    }
                ]}
            />
        )
    }
} */