import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import axios from "axios";
const AssingProject = ({ companyProp, nameProp, lastNameProp, idPositionProp, numberProp, documentProp, projectsOldProp, idInterestedPersonProp, creationDateProp,
    createdByProp, individualMonthReportProp, selectedInterestedPersonCompleteProp, idProjectProp, projectProp, setRunRenderProp, runRenderProp,
    runRenderBlockProp, setRunRenderBlockProp, sendInterestedPersonsProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setSelectedProjectUser(null)
    }


    const [projectUserSession, setProjectUserSession] = useState([])
    const getServices = new Services();

    useEffect(() => {
        const source = axios.CancelToken.source()
        getServices.getProyectosUsuarios().then(data => {
            if (data !== undefined) {
                var newData = []
                data.forEach(element => {
                    newData.push(element)
                });
                if (selectedInterestedPersonCompleteProp !== null) {
                    if (typeof selectedInterestedPersonCompleteProp === 'object') {
                        selectedInterestedPersonCompleteProp.proyectos.forEach(element => {
                            newData.push({
                                id: String(element.proyecto.id),
                                nombre: element.proyecto.nombreproyecto,
                                cliente: element.proyecto.cliente
                            })
                        })
                        setSelectedProjectUser([{
                            id: String(idProjectProp),
                            name: projectProp
                        }])
                    }
                }
                const projectsTable = {}
                const uniqueProjects = newData.filter(function (object) {
                    return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                })

                setProjectUserSession(uniqueProjects)
            }
        })
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [selectedInterestedPersonCompleteProp]); // eslint-disable-line react-hooks/exhaustive-deps

    // select de proyectos para asignación

    const [selectedProjectUser, setSelectedProjectUser] = useState(null)

    const projectsUsers = projectUserSession.map(item => {
        const av = { id: item.id, name: item.nombre }
        return av
    })

    const onProjectUserChange = (e) => {
        setSelectedProjectUser(e.value)
    }

    var registerPermission = false
    if (selectedProjectUser !== null) {
        if (selectedProjectUser.length !== 0) {
            registerPermission = true
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                {registerPermission ?
                    <ConfirmBtn
                        tittle="Confirmar Registro"
                        menssage="¿Está seguro de asignar este proyecto?"
                        action="Actualizar"
                        label="Asignar"
                        icon="pi pi-plus-circle"
                        companyProp={companyProp}
                        nameProp={nameProp}
                        lastNameProp={lastNameProp}
                        idPositionProp={idPositionProp}
                        numberProp={numberProp}
                        documentProp={documentProp}
                        projectsProp={selectedProjectUser}
                        projectsOldProp={projectsOldProp}
                        idInterestedPersonProp={idInterestedPersonProp}
                        creationDateProp={creationDateProp}
                        createdByProp={createdByProp}
                        individualMonthReportProp={individualMonthReportProp}
                        idProjectProp={idProjectProp}
                        assingedNewProjectProp={true}
                        setRunRenderProp={setRunRenderProp}
                        runRenderProp={runRenderProp}
                        runRenderBlockProp={runRenderBlockProp}
                        setRunRenderBlockProp={setRunRenderBlockProp}
                        setDisplayBasicProp={setDisplayBasic}
                        validateChangeProp={true}
                        sendInterestedPersonsProp={sendInterestedPersonsProp}
                        assingNewProjectInterestedPerson={true}
                    />
                    :
                    ""
                }

            </div>
        );
    }
    /* console.log(projectUserSession) */
    return (
        <>
            <button style={{ marginBottom: '10px' }} className="btnAdd" onClick={() => onClick('displayBasic')}><span className="pi pi-plus Add"></span>Asignar nuevo proyecto</button>

            <Dialog className="modal" header="Asignar proyecto" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Empresa</label>
                            <InputText value={companyProp} type="text" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Nombres</label>
                            <InputText value={nameProp} type="text" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Apellidos</label>
                            <InputText value={lastNameProp} type="text" disabled />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Proyecto<span className="require">*</span></label>
                            <MultiSelect value={selectedProjectUser} options={projectsUsers} onChange={onProjectUserChange} placeholder="Seleccione un proyecto"
                                className="p-column-filter " emptyMessage="No hay resultados" name="project" optionLabel="name" disabled
                            />
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    )
}


export default AssingProject