import React, { useState, useEffect } from "react";
import { InputTextarea } from 'primereact/inputtextarea';

const Block6 = ({ setObservationsProp, objectMonthlyReportDataProp,
    setErrorObjectMonthlyReportDataProp, readReportProp, listReportsUsersProp, clientsProp }) => {

    useEffect(() => {
        if (readReportProp === true) {
            if (listReportsUsersProp.length !== 0) {
                var observations = listReportsUsersProp[0].observaciones
                setObservations(observations)
                setObservationsProp(observations)
            }
        } else {
            if (objectMonthlyReportDataProp !== undefined) {
                if (objectMonthlyReportDataProp.cliente.id === clientsProp.id) {
                    setObservations(objectMonthlyReportDataProp.observaciones)
                    setObservationsProp(objectMonthlyReportDataProp.observaciones)
                }else{
                    setObservations('')
                    setObservationsProp('')
                }
            } else {
                setObservations('')
            }
        }

    }, [objectMonthlyReportDataProp, setObservationsProp, readReportProp, listReportsUsersProp, clientsProp])

    const [observations, setObservations] = useState('');

    const [observationsError, setObservationsError] = useState(false)
    const [messageObservations, setMessageObservations] = useState('')

    const onObservatiosChange = (e) => {
        setObservations(e.target.value)
        if (e.target.value.length > 3000) {
            setErrorObjectMonthlyReportDataProp(true)
            setObservationsError(true)
            setMessageObservations('La observación escrita supera los 3000 caracteres permitidos')
        } else {
            setErrorObjectMonthlyReportDataProp(false)
            setObservationsError(false)
            setMessageObservations('')
        }
        if (readReportProp !== true) {
            setObservationsProp(e.target.value)
        }
    }

    return (
        <>
            <div>
                <h3 className="labels" >Observaciones/Recomendaciones</h3>
            </div>

            <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                <div style={{ display: 'contents' }} >
                    <div className="p-field p-col-12 p-md-12" style={{ marginTop: '5px' }}>
                        <div style={{ marginTop: '10px' }}>
                            <div className="p-inputgroup">
                                <InputTextarea value={observations} onChange={(e) => onObservatiosChange(e)} rows={100} cols={90} autoResize
                                    className={observationsError ? "p-invalid" : ""} disabled={readReportProp}
                                />
                            </div>
                        </div>
                    </div>
                    <p className="mensajeError">{observationsError ? messageObservations : ""}</p>
                </div>
            </div>
        </>
    )
}

export default Block6