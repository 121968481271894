import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'

const BtnEliminar = ({ icono, clase, titulo, mensaje, mensajeAlerta, accion, label, fechaProp, clienteProp, idClienteProp, proyectoProp,
    idProyectoProp, tipoActividadProp, idTipoActividadProp, ticketProp, areaProp, idAreaProp, sistemaProp,
    idSistemaProp, actividadProp, horasProp, idColaboradorProp, idActividadProp, idActividadesProp, usuarioProp, fechaCorreoProp, coordinadoConProp, creadoPorProp, fechaCreacionProp,
    esAdicionalProp, horasHoyProp, indicadorProp, tooltipProp, disabledProp, actividadSeleccionadaProp, advertenciaMesCerradoProp, horasMaxUsuarioProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "Eliminar") {
            setDisabled(true)
            EliminarActividad()
        } else if (accion === "EliminarNovedad") {
            setDisabled(true)
            EliminarNovedades()
        }
    }

    const indicador = indicadorProp

    var textIds = ""
    if (idActividadesProp !== undefined) {
        idActividadesProp.forEach(element => {
            textIds = textIds + "activitys=" + element + "&"

        });

    }



    const urlEliminarCheck = process.env.REACT_APP_BASE_URL + '/v1/api/activity/deleteList?' + textIds
    const EliminarActividad = async () => {

        await axios.post(urlEliminarCheck, idActividadesProp,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const urlEliminarNovedadCheck = `${process.env.REACT_APP_BASE_URL}/v1/api/parameter/news/delete`
    const EliminarNovedades = async () => {

        await axios.post(urlEliminarNovedadCheck, idActividadesProp,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })



    }


    var a = parseInt(horasProp)
    var b = parseInt(horasHoyProp)
    var validarHorasHoy = a + b
    var permisosDuplicar = true

    if (validarHorasHoy > 24) {
        permisosDuplicar = false
    }

    const renderFooter = (name) => {
        return (
            <>
                {
                    advertenciaMesCerradoProp
                        ?
                        <div>
                            <Button label="Entendido" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />

                        </div>

                        :

                        actividadSeleccionadaProp ?
                            <div>
                                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled} />
                            </div>
                            :
                            <div>
                                <Button label="Entendido" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />

                            </div>


                }

            </>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} disabled={disabledProp}
            />


            <Dialog className="dialog-demo" header={permisosDuplicar ? titulo : "Advertencia"} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>{permisosDuplicar ? mensaje : "No puedes Duplicar esta actividad, porque superaría el limite de horas permitido "}</span>

                </div>
            </Dialog>

        </>
    )
}
export default BtnEliminar 