import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
const BtnModalAdvertencia = ({ lengthObjectivesProp, setSelectedAcceptProp,runRenderProp, setRunRenderProp }) => {

    const [displayBasic, setDisplayBasic] = useState(true);

    const onHide = () => {
        setDisplayBasic(false);
        setRunRenderProp(runRenderProp + 1)
        setSelectedAcceptProp(false)
    }

    const onAccept = () => {
        setDisplayBasic(false);
        setSelectedAcceptProp(true)
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => onAccept(name)} autoFocus />
            </div>
        );
    }
    return (
        <div className="dialog-demo">
            <div className="card">
                <Dialog className="modalConfirm" header="Advertencia" visible={displayBasic} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>Se han seleccionado {lengthObjectivesProp} objetivos asociados a este proyecto, que se detallan en las páginas de esta ventana, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

export default BtnModalAdvertencia