import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

import Cookies from 'universal-cookie'
import { CambiarFormatoFecha, SumarDias } from '../Funciones';


const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/contract/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/contract/create'
const urlCreateOther = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/other/create'
const urlUpdateOther = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/other/update'


const ConfirmBtn = ({ idProp, contractProp, objectContractProp, dateStartProp, dateEndProp, dateContractProp, idClientProp, setShowModal, setRegisteredContractProp,
    setRunRenderProp, runRenderProp, setDisplayBasicProp, createdByProp, creationDateProp, statusProp, setValidateChangeProp, servicesProp, updateDetailProp,
    idContractServiceProp, statusContractService, serviceCreationDateProp, idServiceProp, dateStartServiceProp, dateEndServiceProp, deleteDetailProp,

    idContractProp, otherProp, OtherServiceStatusProp, idOtherProp, idOtherServiceProp,
    tittle, menssage, action, icon, classProp, label, tooltipProp }) => {

    const cookies = new Cookies()
    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabled, setDisabled] = useState(false)
    const service = () => {
        if (action === "Create") {
            createContract()
            setDisabled(true)
        } else if (action === "Update") {
            updateContract()
            setDisabled(true)
        } else if (action === "Delete") {
            deleteContract()
            setDisabled(true)
        } else if (action === "CreateOther") {
            createOther()
            setDisabled(true)
        } else if (action === "UpdateOther") {
            updateOther()
            setDisabled(true)
        } else if (action === "DeleteOther") {
            deleteOther()
            setDisabled(true)
        }
    }

    const changeDates = (date) => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        // console.log(gmtHours)
        var sendDate
        if (gmtHours < 0) {
            sendDate = SumarDias(date, +1)
        } else {
            sendDate = date
        }
        return sendDate
    }

    const usuario = cookies.get('usuario')
    const createContract = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: '',
                cliente: {
                    id: idClientProp,
                },
                contrato: contractProp,
                creadopor: usuario,
                estado: 1,
                fechaactualizado: '',
                fechacreacion: new Date(),
                fechafinvigencia: CambiarFormatoFecha(changeDates(dateEndProp)),
                fechafirmacontrato: CambiarFormatoFecha(changeDates(dateContractProp)),
                fechainiciovigencia: CambiarFormatoFecha(changeDates(dateStartProp)),
                objetocontrato: objectContractProp,
                servicios: servicesProp
            }
        )

        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setShowModal(true)
                setRegisteredContractProp(true)
                setDisplayConfirmation(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }


    const updateContract = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                cliente: {
                    id: idClientProp,
                },
                contrato: contractProp,
                creadopor: createdByProp,
                estado: statusProp,
                fechaactualizado: new Date(),
                fechacreacion: creationDateProp,
                fechafinvigencia: dateEndProp,
                fechafirmacontrato: dateContractProp,
                fechainiciovigencia: dateStartProp,
                id: idProp,
                objetocontrato: objectContractProp,
                servicios: updateDetailProp ?
                    [{
                        estado: statusContractService,
                        fechacreacion: serviceCreationDateProp,
                        fechafinvigencia: dateEndServiceProp,
                        fechainiciovigencia: dateStartServiceProp,
                        id: idContractServiceProp,
                        servicio: {
                            id: idServiceProp
                        }
                    }]
                    :
                    []
            }
        )

        /* console.log(json) */

        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    if (deleteDetailProp === true) {
                        setRunRenderProp(runRenderProp + 1)
                        setDisplayConfirmation(false)
                    } else {
                        setRunRenderProp(runRenderProp + 1)
                        setDisplayBasicProp(false)
                        setDisplayConfirmation(false)
                    }

                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sitema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/contract/delete?id=' + idProp
    const deleteContract = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setRunRenderProp(runRenderProp + 1)
                setDisplayConfirmation(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }
    const createOther = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                contrato: {
                    id: idContractProp
                },
                creadopor: usuario,
                descripcion: otherProp,
                estado: statusProp,
                fechaactualizado: "",
                fechacreacion: new Date(),
                fechafinvigencia: CambiarFormatoFecha(dateEndProp),
                fechainiciovigencia: CambiarFormatoFecha(dateStartProp),
                servicios: servicesProp
            }
        )
        /* console.log(json) */
        await axios.post(urlCreateOther, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setShowModal(true)
                setDisplayConfirmation(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const updateOther = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                contrato: {
                    id: idContractProp
                },
                creadopor: createdByProp,
                descripcion: otherProp,
                estado: statusProp,
                fechaactualizado: new Date(),
                fechacreacion: creationDateProp,
                fechafinvigencia:dateEndProp,
                fechainiciovigencia:dateStartProp,
                id: idOtherProp,
                servicios: updateDetailProp ?
                    [{
                        estado: OtherServiceStatusProp,
                        fechacreacion: new Date(),
                        fechafinvigencia: dateEndServiceProp,
                        fechainiciovigencia: dateStartServiceProp,
                        id: idOtherServiceProp,
                        servicio: {
                            id: idServiceProp
                        },
                    }]
                    :
                    []
            }
        )
        /* console.log(json) */
        await axios.post(urlUpdateOther, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    if (deleteDetailProp === false) {
                        setDisplayBasicProp(false)
                    }
                    if (updateDetailProp === false) {
                        setDisplayBasicProp(false)
                    }
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const urlDeleteOther = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/other/delete?id=' + idOtherProp
    const deleteOther = async () => {
        await axios.delete(urlDeleteOther,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>

                <>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabled}/>
                </>
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content-modal">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{menssage}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn