import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ConfirmBtn from "./ConfirmBtn";
import Cookies from 'universal-cookie'
import { removeAccents } from "../Funciones";
const cookies = new Cookies()


const BtnActualizarEntregable = ({ idDeliverableProp, deliverableProp, creationDateProp, createdByProp,
    setRunRenderProp, runRenderProp, runRenderBlockProp, setRunRenderBlockProp, listDeliverableProp, listObjectivesProp, dataDeliverableProp, projectsProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input entregable

    const [inputDeliverable, setInputDeliverable] = useState({
        deliverable: ''
    })

    const onDeliverableChange = (event) => {

        setInputDeliverable({
            ...inputDeliverable,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setInputDeliverable({
            deliverable: deliverableProp
        })
    }, [deliverableProp])

    //Almacenamos los valores para enviarlos por props

    const deliverable = inputDeliverable.deliverable

    const resetData = () => {
        setInputDeliverable({
            deliverable: deliverableProp
        })
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const back = (name) => {
            resetData()
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const usuario = cookies.get('usuario');

    const renderFooter = (name) => {
        var createNewDeliverable = false
        if (usuario !== createdByProp) {
            createNewDeliverable = true
        }
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage={createNewDeliverable ? "El registro editado no fue creado con su usuario, por lo tanto, se creará un nuevo registro con los datos actualizados, tenga en cuenta seleccionar este nuevo registro para su informe." : "¿Estás seguro de guardar esta información?"}
                            action={createNewDeliverable ? "Create" : "Update"}
                            label="Actualizar"
                            icon="pi pi-pencil"
                            idDeliverableProp={idDeliverableProp}
                            deliverableProp={deliverable}
                            creationDateProp={creationDateProp}
                            createdByProp={createdByProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            assingnedNewObjectiveDeliverableProp={false}
                            deleteDetailProp={false}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            createNewDeliverableProp={createNewDeliverable}
                            listObjectivesProp={listObjectivesProp}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    // validaciones

    var deliverableError = false
    var deliverableMessage = ''

    if (deliverable !== '') {
        if (deliverable.length < 10) {
            deliverableError = true
            deliverableMessage = 'La descripción del entregable es demasiado corta'
        } else if (deliverable.length > 110) {
            deliverableError = true
            deliverableMessage = 'La descripción del entregable supera el limite permitido de 110 caracteres'
        }
    }

    const [duplicityError, setDuplicityError] = useState(false)
    const [duplicityMessage, setDuplicityMessage] = useState('')

    useEffect(() => {
        try {
            if (inputDeliverable.deliverable !== '') {
                if (inputDeliverable.deliverable !== deliverableProp) {
                    var getProjectsDeliverable = []
                    dataDeliverableProp.forEach(element => {
                        let nameComlete = removeAccents(inputDeliverable.deliverable)
                        let newNameComplete = removeAccents(element.descripcion)
                        if (nameComlete.toLowerCase() === newNameComplete.toLowerCase()) {
                            getProjectsDeliverable.push(element)
                        }
                    })
                    if (getProjectsDeliverable.length !== 0) {
                        getProjectsDeliverable.forEach(element => {
                            element.objetivos.forEach(i => {
                                projectsProp.forEach(z => {
                                    if (i.proyecto.id === z.proyecto.id) {
                                        setDuplicityError(true)
                                        setDuplicityMessage('El entregable ya se encuentra creado con el mismo proyecto')
                                    }
                                })

                            })
                        })
                    } else {
                        setDuplicityError(false)
                        setDuplicityMessage('')
                    }
                }

            }
        } catch (error) {
           /*  console.log(error); */
        }

    }, [inputDeliverable.deliverable, dataDeliverableProp]) // eslint-disable-line react-hooks/exhaustive-deps

    var updatePermission = false

    if (deliverable !== '' && deliverableError === false && duplicityError === false) {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar Entregable" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" >Entregable<span className="require">*</span></label>
                            <InputText id="name" type="text" name="deliverable" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onDeliverableChange}
                                placeholder="Ingrese un nombre" value={deliverable}
                                className={deliverableError || duplicityError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{deliverableError ? deliverableMessage : ""}{duplicityError ? duplicityMessage : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnActualizarEntregable