import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ConfirmBtn from './ConfirmBtn.js'
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import Cookies from 'universal-cookie';
import { removeAccents } from '../Funciones.js';
const cookies = new Cookies();

const BtnActualizarTipoProyecto = ({ fechacreacionProp, idTipoProyectoProp, nombreTipoProyectoProp, listTypeProjectProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    useEffect(() => {
        setInputTypeProject(nombreTipoProyectoProp)
    }, [nombreTipoProyectoProp]); // eslint-disable-line react-hooks/exhaustive-deps

    const [validarCambio, setValidarCambio] = useState(false)

    const [inputTypeProject, setInputTypeProject] = useState('')
    const [errorTypeProject, setErrorTypeProject] = useState(false)
    const [messageErrorTypeProject, setMessageErrorTypeProject] = useState('')

    const onTypeProjectChange = (e) => {
        setInputTypeProject(e.target.value)
        setValidarCambio(true)
        if (e.target.value !== '') {
            if (e.target.value.length > 0 && e.target.value.length < 4) {
                setErrorTypeProject(true)
                setMessageErrorTypeProject('El nombre de tipo de proyecto deber ser mayor a 3 dígitos')
            }
            else if (e.target.value.length > 100) {
                setErrorTypeProject(true)
                setMessageErrorTypeProject('El nombre de tipo de proyecto deber ser menor a 100 dígitos')
            }
            else {
                setErrorTypeProject(false)
                setMessageErrorTypeProject('')
            }
        }
    }
    //Almacenamos los valores para enviarlos por props

    const usuario = cookies.get('usuario')

    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            setInputTypeProject(nombreTipoProyectoProp)
            setValidarCambio(false)
            setDisplayBasic(false)
            setValidateNameEqualityError(false)
            setMessageNameEquality('')
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    const [validateNameEqualityError, setValidateNameEqualityError] = useState(false)
    const [messageNameEquality, setMessageNameEquality] = useState('')
    //Validación de duplicidad
    useEffect(() => {
        let arr = []
        if (inputTypeProject !== nombreTipoProyectoProp) {
            listTypeProjectProp.forEach(element => {
                const nameTypeProject = removeAccents(element.nombretipoproyecto)
                const newNameTypeProject = removeAccents(inputTypeProject)
                if (nameTypeProject.toUpperCase() === newNameTypeProject.toUpperCase()) {
                    arr.push(newNameTypeProject)
                }
            })
        }
        if (arr.length !== 0) {
            setValidateNameEqualityError(true)
            setMessageNameEquality('Ya hay un tipo de proyecto creado con este nombre')
        }else{
            setValidateNameEqualityError(false)
            setMessageNameEquality('')
        }

    }, [inputTypeProject, nombreTipoProyectoProp])// eslint-disable-line react-hooks/exhaustive-deps

    //Validaciones
    var permisoRegistrar = false
    if (errorTypeProject === false && validateNameEqualityError === false) {
        permisoRegistrar = true
    }
    const renderFooter = (name) => {
        return (
            <div>
                {validarCambio ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    permisoRegistrar ?
                        <ConfirmBtn
                            titulo="Confirmar Actualización"
                            mensaje="¿Estás seguro de guardar esta información?"
                            accion="Actualizar"
                            label="Actualizar"
                            icono="pi pi-pencil"
                            fechacreacionProp={fechacreacionProp}
                            idTipoProyectoProp={idTipoProyectoProp}
                            usuarioProp={usuario}
                            nombreTipoProyectoProp={inputTypeProject}
                        />
                        :
                        ''
                }
            </div>
        );
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar Tipo de Proyecto" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Id Tipo Proyecto </label>
                            <InputText id="id" type="text" name="id" disabled value={idTipoProyectoProp} />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" > Tipo de Proyecto<span className="require">*</span></label>
                            <InputText id="tipoActividad" type="text" name="tipoActividad" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/}
                                placeholder="Registre el tipo de proyecto" value={inputTypeProject} onChange={onTypeProjectChange}
                                className={errorTypeProject || validateNameEqualityError ? "p-invalid" : ''}
                            />
                            <p className="mensajeError">{errorTypeProject ? messageErrorTypeProject : ""}{validateNameEqualityError ? messageNameEquality : ''}</p>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}
export default BtnActualizarTipoProyecto