import React from 'react';
import { Chart } from 'primereact/chart';
import { GenerarNuevoColor } from '../../../Funciones'

const Chart1 = ({ dashBoardDataProp, pageProp, datatableProp }) => {


    // Función para ordenar los datos por fechas
    const ordenarPorFechas = (datos) => {
        return datos.sort((a, b) => {
            const fechaA = new Date(a.label);
            const fechaB = new Date(b.label);
            return fechaA - fechaB;
        });
    }

    var arrayLabels = []
    var arrayData1 = []
    var arrayData2 = []
    var arrayData3= []

    datatableProp.forEach(element => {
        arrayLabels.push(element.funcionario)
        arrayData1.push(element.mes1)
        arrayData2.push(element.mes2)
        arrayData3.push(element.mes3)
    });

    var newArrayData1 = {
        label:datatableProp[0].label1,
        data: arrayData1
    }
    var newArrayData2 = {
        label:datatableProp[0].label2,
        data: arrayData2        
    }
    var newArrayData3 = {
        label:datatableProp[0].label3,
        data: arrayData3
    }

    var orderDatasets = [newArrayData1,newArrayData2,newArrayData3]

    // Ordenar los datos por fechas
    orderDatasets = ordenarPorFechas(orderDatasets);

    var labels = arrayLabels
	var labelsReduced = [];
    var n = 5

    for (let i = 0; i < labels.length; i += n) {
        let reduced = labels.slice(i, i + n);
        labelsReduced.push(reduced);
    }
    
    var newDatasets = orderDatasets.map(item => {
        return {
            label: item.label,
            backgroundColor: GenerarNuevoColor(),
            data: item.data
        }
    })

    var dataReduced = newDatasets.map(item => {
        var datos = item.data
        var datosReduced = []
        for (let i = 0; i < datos.length; i += n) {
            let reduced = datos.slice(i, i + n);
            datosReduced.push(reduced);
        }
        return datosReduced
    });
    var newData = newDatasets.map((item, index) => {
        return {
            label: item.label,
            backgroundColor: item.backgroundColor,
            data: dataReduced[index]
        }
    });

    var newArrayDatos = []

    newData.forEach(element => {
        newArrayDatos.push({
            label: element.label,
            backgroundColor: element.backgroundColor,
            data: element.data[pageProp]
        })
    });

    
     //console.log(newArrayDatos) 

    const basicData = {
        labels: labelsReduced[pageProp],
        datasets: newArrayDatos
    }

    
    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let stackedOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        max: 100,
                        color: '#495057'
                    }
                }
            }
        };

        return {
            basicOptions,
            horizontalOptions,
            stackedOptions,
            multiAxisOptions
        }
    }

    const { horizontalOptions } = getLightTheme();

    return (
        <div>
            <div className="card">
                <Chart type="bar" data={basicData} options={horizontalOptions} />
            </div>
        </div>
    )
}
export default Chart1