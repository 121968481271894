import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import { CambiarFormatoFecha, removeAccents } from "../Funciones";
import axios from "axios";
const BtnCrearPersonaInteresada = ({ individualMonthReportProp, getClientsProp, idProjectProp, projectProp, setRunRenderProp, runRenderProp,
    runRenderBlockProp, setRunRenderBlockProp, positionServiceProp, documentTypeServiceProp, clientServiceProp, projectsUserSessionProp,
    sendInterestedPersonsProp, dataInterestedPersonsProp }) => {

    const toast = useRef(null)
    const [positionService, setPositionService] = useState([]);
    const [documentTypeService, setDocumentTypeService] = useState([]);
    const [clientService, setClientService] = useState([])
    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const [clientsUserSession, setClientsUserSession] = useState([]);

    const getService = new Services()
    const [displayBasic, setDisplayBasic] = useState(false);
    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            getService.getCargos().then(data => {
                if (data !== undefined) {
                    setPositionService(data)
                }
            })
            getService.getTiposDocumento().then(data => {
                if (data !== undefined) {
                    setDocumentTypeService(data)
                }
            })

            getService.getProyectosUsuarios().then(data => {
                if (data !== undefined) {

                    var nameClients = []
                    data.forEach(element => {
                        var clients = {}
                        clients.id = element.cliente.id
                        clients.name = element.cliente.nombre
                        nameClients.push(clients)
                    })

                    const clientsTable = {}
                    const uniqueClients = nameClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })
                    const tableProjects = {}
                    const uniqueProjects = data.filter(function (object) {
                        return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
                    })
                    setClientService(uniqueClients)
                    setProjectsUserSession(uniqueProjects)
                    setClientsUserSession(uniqueClients)
                }
            });
        }

        return () => {
            if (source) {
                getService.cancelRequest()
            }
        }
    }, [displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (displayBasic === true) {
            if (individualMonthReportProp === true) {
                var nameClients = []
                projectsUserSessionProp.forEach(element => {
                    var clients = {}
                    clients.id = element.cliente.id
                    clients.name = element.cliente.nombre
                    nameClients.push(clients)
                })

                const clientsTable = {}
                const uniqueClients = nameClients.filter(function (object) {
                    return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                })
                setClientsUserSession(uniqueClients)
                const projectsTable = {}
                const uniqueProjects = projectsUserSessionProp.filter(function (object) {
                    return projectsTable.hasOwnProperty(object.id) ? false : (projectsTable[object.id] = true)
                })
                setProjectsUserSession(uniqueProjects)
            }

            if (getClientsProp !== undefined) {
                setSelectedClient([{
                    id: parseInt(getClientsProp.id),
                    name: getClientsProp.client
                }])
            }
            if (idProjectProp !== undefined && projectProp !== undefined) {
                setSelectedProject([{
                    id: idProjectProp,
                    name: projectProp
                }])
            }
        }
        

    }, [displayBasic, positionService, clientService, documentTypeService])// eslint-disable-line react-hooks/exhaustive-deps


    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setRunRenderProp(runRenderProp + 1)
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input Nombre

    const [inputName, setInputName] = useState({
        name: ''
    })

    const onNameChange = (event) => {

        setInputName({
            ...inputName,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del input Apellido

    const [inputLastName, setInputLastName] = useState({
        lastName: ''
    })

    const onLastNameChange = (event) => {

        setInputLastName({
            ...inputLastName,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }


    //Obtener datos del select Tipo de documento


    const [inputDocumentType, setInputDocumentType] = useState({
        documentType: '',
        id: ''
    })

    const documentTypes = documentTypeService.map(item => {
        const av = { documentType: item.nombretipodocumento, id: item.id }
        return av
    })

    const onDocumentTypeChange = (e) => {
        setInputDocumentType(e.value)
        setValidateChange(true)
    }


    //Obtener datos del input Numero de documento

    const [inputDocumentNumber, setInputDocumentNumber] = useState({
        number: ''
    })
    const onDocumentNumberChange = (event) => {

        setInputDocumentNumber({
            ...inputDocumentNumber,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }


    /* console.log(inputDocumentNumber.number) */

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState(null);

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: parseInt(item.id) }
        return av

    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    //Obtener datos del select tipo de proyecto

    const [selectedProject, setSelectedProject] = useState(null);

    var projectClient = []
    var proyectoFechaVigente = []
    var d = new Date()
    var dateV = CambiarFormatoFecha(d)

    const loadProjects = () => {
        if (selectedClient !== undefined) {
            if (selectedClient.length !== 0) {
                selectedClient.forEach(element => {
                    const result = projectsUserSession.filter(function (object) {
                        var idClient = parseInt(object.cliente.id)
                        return idClient === parseInt(element.id)
                    })
                    result.forEach(element => {
                        var selectedProject = {}
                        selectedProject.id = element.id
                        selectedProject.nombre = element.nombre
                        selectedProject.fechafinvigencia = element.fechafinvigencia
                        projectClient.push(selectedProject)
                    });
                    projectClient.sort(function (a, b) {
                        var textA = a.nombre;
                        var textB = b.nombre;
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                })

                const filterDate = projectClient.filter(element => {
                    return element.fechafinvigencia >= dateV
                })
                filterDate?.forEach(element => {
                    proyectoFechaVigente.push(element)
                })

            }
            return proyectoFechaVigente
        }
    }

    var dateValidError = false
    var messageDateValidError = ''

    if (selectedClient !== null) {
        loadProjects()
        if (proyectoFechaVigente.length === 0) {
            dateValidError = true
            messageDateValidError = 'No existe un proyecto con fecha fin de vigencia mayor a la actual'
        } else {
            dateValidError = false
            messageDateValidError = ''
        }
        if (selectedClient.length === 0) {
            dateValidError = false
            messageDateValidError = ''
        }
    }

    const projects = proyectoFechaVigente.map(item => {
        const av = { name: item.nombre, id: parseInt(item.id) }

        return av

    })

    const onProjectChange = (e) => {
        setSelectedProject(e.value);
        setValidateChange(true)
    }

    //Obtener datos del select tipo de cargo

    const [selectedPosition, setSelectedPosition] = useState({
        position: '',
        id: ''
    });

    const positions = positionService.map(item => {
        const av = { position: item.cargo, id: item.id }
        return av
    })

    const onPositionChange = (e) => {
        setSelectedPosition(e.value);
        setValidateChange(true)
    }

    //Obtener datos del input empresa

    const [selectedCompanyComplete, setSelectedCompanyComplete] = useState(null);
    const [filteredCompany, setFilteredCompany] = useState(null)

    const searchCompany = (event) => {
        setTimeout(() => {
            let _filteredCompany;
            if (!event.query.trim().length) {
                _filteredCompany = [...clientService];
            }
            else {
                _filteredCompany = clientService.filter((object) => {
                    return object.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCompany(_filteredCompany);
        }, 250);
    }

    const onCompanyChange = (e) => {
        setSelectedCompanyComplete(e.value)
        setValidateChange(true)
    }


    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    ////validaciones proyectos
    var projectsError = false
    var menssageProjects = ''

    /// cuando ingrese un cliente 
    var disabledProject

    if (projectClient.length === 0) {
        disabledProject = true
    }

    //Almacenamos los valores para enviarlos por props

    const fullName = inputName.name
    const fullLastName = inputLastName.lastName
    const position = selectedPosition.id
    var company
    if (selectedCompanyComplete !== null) {
        if (typeof selectedCompanyComplete === 'object') {
            company = selectedCompanyComplete.name
        }
        if (typeof selectedCompanyComplete === 'string') {
            company = selectedCompanyComplete
        }
    }
    const number = inputDocumentNumber.number === '' ? null : inputDocumentNumber.number
    const document = inputDocumentType.id
    const project = selectedProject

    const resetData = () => {
        setInputName({
            name: ''
        })
        setInputLastName({
            lastName: ''
        })
        setInputDocumentType({
            documentType: '',
            id: ''
        })
        setInputDocumentNumber({
            number: ''
        })
        setSelectedPosition({
            position: '',
            id: ''
        })
        setSelectedCompanyComplete(null)
        if (individualMonthReportProp === false) {
            setSelectedClient(null)
            setSelectedProject(null)
        }
        setValidateChange(false)
    }


    // Modal para el botón regresar
    const [registeredPerson, setRegisteredPerson] = useState(false)

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Back = (name) => {
            if (registeredPerson === true) {
                if (individualMonthReportProp === true) {
                    setRunRenderProp(runRenderProp + 1)
                    setRunRenderBlockProp(runRenderBlockProp + 1)
                    setDisplayBasic(false)
                    resetData()
                } else {
                    window.location.href = "/registroActividades/personasInteresadas"
                }
            } else {
                setRunRenderProp(runRenderProp + 1)
                if (runRenderBlockProp) {
                    setRunRenderBlockProp(runRenderBlockProp + 1)
                }
                setDisplayBasic(false)
                resetData()
                dialogFuncMap[`${name}`](false);
            }
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>

            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreInterestedPerson = () => {

        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            if (individualMonthReportProp === true) {
                setRunRenderProp(runRenderProp + 1)
                setRunRenderBlockProp(runRenderBlockProp + 1)
                setDisplayBasic(false)
                setShowModal(false)
                resetData()
            } else {
                window.location.href = "/registroActividades/personasInteresadas"
            }
        }

        const AddMore = (name) => {
            resetData()
            setShowModal(false)
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">

                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar otra persona interesada?</span>

                        </div>
                    </Dialog>

                </div>
            </div>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    registerPermission ?
                        <ConfirmBtn
                            tittle="Confirmar Registro"
                            menssage="¿Está seguro de Crear esta persona interesada?"
                            action="Crear"
                            label="Guardar"
                            icon="pi pi-plus-circle"
                            companyProp={company}
                            nameProp={fullName}
                            lastNameProp={fullLastName}
                            idPositionProp={position}
                            numberProp={number}
                            documentProp={document}
                            projectsProp={project}
                            individualMonthReportProp={individualMonthReportProp}
                            setShowModal={setShowModal}
                            setRegisteredPerson={setRegisteredPerson}
                            validateChangeProp={validateChange}
                            sendInterestedPersonsProp={sendInterestedPersonsProp}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    //validaciones para nombre

    var nameError = false
    var menssageName = ''
    if (fullName.length < 3 && fullName !== '') {
        nameError = true
        menssageName = 'El nombre debe ser mayor a 3 dígitos'
    } else if (fullName.length > 30) {
        nameError = true
        menssageName = 'El nombre debe ser menor a 30 dígitos'
    }

    //validaciones para apellido

    var lastNameError = false
    var menssageLastName = ''
    if (fullLastName.length < 3 && fullLastName !== '') {
        lastNameError = true
        menssageLastName = 'El apellido debe ser mayor a 3 dígitos'
    } else if (fullLastName.length > 30) {
        lastNameError = true
        menssageLastName = 'El apellido debe ser menor a 30 dígitos'
    }


    //Validaciones para Numero de Documento
    var numberError = false
    var messageNumber = ''
    if (number !== null) {
        if (number.length < 5) {
            numberError = true
            messageNumber = 'El número de documento debe ser mayor a 5 dígitos '
        }
        else if (number.length > 12) {
            numberError = true
            messageNumber = 'El número de documento debe ser menor a 12 dígitos'
        }
    }

    //validar cuando es pasaporte o cedula de extranjería
    var validarPasaporte = false

    if (inputDocumentType.documentType === "Pasaporte" || inputDocumentType.documentType === "Cédula Extranjería") {
        validarPasaporte = true
    }

    var documentError = false
    var documentMessage = ''
    if (inputDocumentType.documentType !== 'Pasaporte' && inputDocumentType.documentType !== "Cédula Extranjería") {
        if (isNaN(number) === true) {
            documentError = true
            documentMessage = 'Este tipo de documento no puede contener letras'
        }
    }

    //validaciones para tipo de documento
    var documentTypeError = false
    var messageDocumentType = ''

    //validaciones para cargo

    var positionError = false
    var messagePosition = ''

    //validaciones para empresa

    var businessError = false
    var messageBusiness = ''

    var disabledClient = false

    if (individualMonthReportProp === true) {
        disabledProject = true
        disabledClient = true
    }

    //Validar duplicidad por nombre o por documento cuando sea necesario
    var validateErrorDuplicity = false
    const validateDuplicity = () => {
        dataInterestedPersonsProp.forEach(i => {
            var newNameComplete = removeAccents(i.nombre + ' ' + i.apellido)
            var nameComplete = removeAccents(fullName + ' ' + fullLastName)
            if (nameComplete.toLowerCase() === newNameComplete.toLowerCase()) {
                if (i.numerodocumento === number) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Persona interesada pre-existente', life: 3000 });
                    validateErrorDuplicity = true
                }
            }
        })
    }
    if (fullName !== '' && fullLastName !== '') {
        validateDuplicity()
    }

    var registerPermission = false
    if (nameError === false && fullName !== '' && lastNameError === false && fullLastName !== '' && position !== '' && project !== null && project.length !== 0 && validateErrorDuplicity === false && documentError === false) {
        if (selectedCompanyComplete !== null) {
            if (selectedCompanyComplete !== '') {
                registerPermission = true
            }
        }
    }
    return (
        <div>
            <Toast ref={toast} />
            <button style={{ marginBottom: '10px' }} className="btnAdd" onClick={() => {
                onClick('displayBasic')
                resetData()
            }}><span className="pi pi-plus Add"></span>Crear persona interesada</button>
            <AddMoreInterestedPerson />
            <Dialog className="modal" header="Crear persona interesada" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents' }} >

                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Empresa<span className="require">*</span></label>
                            <AutoComplete value={selectedCompanyComplete} suggestions={filteredCompany} completeMethod={searchCompany} field="name"
                                dropdown filterMatchMode="startsWith" onChange={onCompanyChange} placeholder="Ingrese una empresa"
                                className={businessError ? "p-invalid auto" : "auto"}
                            />
                            <p className="mensajeError">{businessError ? messageBusiness : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cargo<span className="require">*</span></label>
                            <Dropdown value={selectedPosition} options={positions} onChange={onPositionChange} name="position" optionLabel="position"
                                placeholder="Seleccione un Cargo" filter
                                className={positionError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{positionError ? messagePosition : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Nombres<span className="require">*</span></label>
                            <InputText id="name" type="text" name="name" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onNameChange}
                                placeholder="Ingrese un nombre" value={fullName}
                                className={nameError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{nameError ? menssageName : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Apellidos<span className="require">*</span></label>
                            <InputText id="lastName" type="text" name="lastName" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onLastNameChange}
                                placeholder="Ingrese un apellido" value={fullLastName}
                                className={lastNameError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{lastNameError ? menssageLastName : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Tipo documento</label>
                            <Dropdown value={inputDocumentType} options={documentTypes} onChange={onDocumentTypeChange} name="documentTypes" optionLabel="documentType"
                                placeholder="Seleccione un tipo de documento "
                                className={documentTypeError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{documentTypeError ? messageDocumentType : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Número documento</label>
                            <InputText id="number" type="text" name="number" keyfilter={validarPasaporte ? "alphanum" : "pint"} onChange={onDocumentNumberChange}
                                placeholder="Ingrese un número de documento" value={number}
                                className={numberError || documentError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{numberError ? messageNumber : ""}{documentError ? documentMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <MultiSelect value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name" showClear disabled={disabledClient}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Proyecto<span className="require">*</span></label>
                            <MultiSelect value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                className={projectsError || dateValidError ? "p-invalid" : ""} emptyMessage="No hay resultados" name="project" optionLabel="name" showClear
                                disabled={disabledProject}
                            />
                            <p className="mensajeError">{projectsError ? menssageProjects : ""}</p>
                            <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                        </div>
                    </form>

                </div>
            </Dialog>
        </div>

    )

}

export default BtnCrearPersonaInteresada