import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import Services from '../../service/Services';

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/interestedPerson/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/interestedPerson/create'


const ConfirmBtn = ({ companyProp, nameProp, lastNameProp, idPositionProp, numberProp, documentProp, idInterestedPerson, sendProyectProp, setShowModal, setRegisteredPerson,
    idInterestedPersonProp, projectsProp, projectsOldProp, individualMonthReportProp, creationDateProp, createdByProp, assingedNewProjectProp, idProjectProp, setRunRenderProp,
    runRenderProp, setDisplayBasicProp, validateChangeProp, runRenderBlockProp, setRunRenderBlockProp, sendInterestedPersonsProp, noDeleteInterestedPersonProp,
    tittle, menssage, action, icon, classProp, label, tooltipProp, projectProp, assingNewProjectInterestedPerson,
    sendArrayProjectProp, usuarioProp, apellidoProp, idCargoProp, creadoPorProp, empresaProp, fechaCreacionProp,
    nombreProp, numeroDocumentoProp, idPersonInterestedProp, idTipoDocumentoProp, newUsuarioProp,
    newSetDisplayBasicProp, getDataProjectsProp, isDeletedProp }) => {

    const [existingInterestedPerson, setExistingInterestedPerson] = useState(false)
    const getServices = new Services();


    useEffect(() => {
        if (individualMonthReportProp === true) {
            if (assingNewProjectInterestedPerson === false) {
                var newData = sendInterestedPersonsProp.map(item => {
                    return {
                        company: item.empresa,
                        position: {
                            id: item.cargo.id
                        },
                        name: item.nombre,
                        lastName: item.apellido,
                        numerodocumento: item.numerodocumento,
                        tipodocumento: item.tipodocumento?.id,
                    }
                })
                const json = {
                    company: companyProp,
                    position: {
                        id: idPositionProp
                    },
                    name: nameProp,
                    lastName: lastNameProp,
                    numerodocumento: numberProp === '' ? null : numberProp,
                    tipodocumento: documentProp,
                }
                var trueElement = []
                newData.forEach(element => {
                    if (JSON.stringify(json) === JSON.stringify(element)) {
                        /* if (assingedNewProjectProp !== true) {
                            setExistingInterestedPerson(true)
                        } */
                        trueElement.push(element)
                    }
                })

                /*   if (trueElement.length !== 0) {
                      setExistingInterestedPerson(true)
                  } else {
                      setExistingInterestedPerson(false)
                  } */
            }
        } else {
            if (assingNewProjectInterestedPerson === false) {
                getServices.getInterestedPerson().then(data => {
                    var newData = data.map(item => {
                        return {
                            company: item.empresa,
                            position: {
                                id: item.cargo.id
                            },
                            name: item.nombre,
                            lastName: item.apellido,
                            numerodocumento: item.numerodocumento,
                            tipodocumento: item.tipodocumento?.nombretipodocumento,
                        }
                    })
                    const json = {
                        company: companyProp !== undefined ? companyProp.name : companyProp,
                        position: {
                            id: idPositionProp
                        },
                        name: nameProp,
                        lastName: lastNameProp,
                        numerodocumento: numberProp,
                        tipodocumento: documentProp,
                    }
                    var trueElement = []
                    newData.forEach(element => {
                        if ((JSON.stringify(json) === JSON.stringify(element) === true)) {
                            if (assingedNewProjectProp !== true) {
                                setExistingInterestedPerson(true)
                            }
                            trueElement.push(element)
                        }
                    })
                    /* if (assingedNewProjectProp !== true) {
                        if (trueElement.length !== 0) {
                            if ((JSON.stringify(json) === JSON.stringify(trueElement[0]) === true)) {
                                setExistingInterestedPerson(true)
                            } else {
                                setExistingInterestedPerson(false)
                            }
                        } else {
                            setExistingInterestedPerson(false)
                        }
                    } */
                })
            }
        }
    }, [companyProp, idPositionProp, nameProp, lastNameProp, numberProp, documentProp]); // eslint-disable-line react-hooks/exhaustive-deps

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabledButton, setDisabledButton] = useState(false);

    const service = () => {
        if (action === "Crear") {
            createInterestedPerson()
            setDisabledButton(true)
        } else if (action === "Actualizar") {
            updateInterestedPerson()
            setDisabledButton(true)
        } else if (action === "ActualizarVigencia") {
            actualizarVigenciaUsuario()
            setDisabledButton(true)
        }
        else if (action === "Eliminar") {
            setDisabledButton(true)
            actualizarVigenciaUsuario()
        }
    }
    const usuario = cookies.get('usuario')
    const createInterestedPerson = async () => {
        var newProjectsProp = projectsProp.map(item => {
            return {
                proyecto: {
                    id: item.id
                },
                estado: 1
            }
        })
        const json = JSON.stringify(
            {
                actualizadopor: "",

                apellido: lastNameProp,
                cargo: {
                    id: idPositionProp
                },
                creadopor: usuario,
                empresa: companyProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                nombre: nameProp,
                numerodocumento: numberProp === "" || numberProp === null ? null : numberProp,
                proyectos: newProjectsProp,
                tipodocumento: documentProp === '' || documentProp === undefined ?
                    null
                    :
                    {
                        id: documentProp
                    },
                usuario: null

            }
        )
        /* console.log(json) */
        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (setShowModal) {
                    setShowModal(true)
                }
                if (setRegisteredPerson) {
                    setRegisteredPerson(true)
                }
                if (setDisplayBasicProp) {
                    setDisplayBasicProp(false)
                }
                if (setRunRenderProp) {
                    setRunRenderProp(runRenderProp + 1)
                }
                if (setRunRenderBlockProp) {
                    setRunRenderBlockProp(runRenderBlockProp + 1)
                }
                setDisplayConfirmation(false)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response?.data.mensaje, life: 6000 });
            })
    }
    const projects = []
    if (projectsProp !== null) {
        if (projectsProp !== undefined) {
            projectsProp.forEach(element => {
                var selectProjects = {}
                selectProjects.id = ""
                selectProjects.estado = 1
                selectProjects.fechacreacion = ""
                selectProjects.proyecto = {
                    id: parseInt(element.id)
                }
                projects.push(selectProjects)
            });

        }
    }

    const projectsOld = []
    if (projectsOldProp !== undefined) {
        projectsOldProp?.forEach(element => {
            var selectProjects = {}
            selectProjects.id = element.idR
            selectProjects.estado = 1
            selectProjects.fechacreacion = ""
            selectProjects.proyecto = {
                id: element.id
            }
            projectsOld.push(selectProjects)
            /* console.log(projectsOld) */
        })

    }

    const uniqueProjects = []

    if (projects !== null) {
        projects.forEach(i => {
            var exists = false
            projectsOld.forEach(j => {
                !exists && i.proyecto.id === j.proyecto.id && (exists = true)
            })
            !exists && uniqueProjects.push({
                estado: 1,
                proyecto: {
                    id: i.proyecto.id
                }
            })
        })

    }


    if (projects !== null) {
        projectsOld.forEach(i => {
            var exists = false
            projects.forEach(j => {
                !exists && i.proyecto.id === j.proyecto.id && (exists = true)
            })
            !exists && uniqueProjects.push({
                id: i.id,
                estado: 3,
                fechacreacion: "",
                proyecto: {
                    id: i.proyecto.id
                }
            })
        })

    }
    const updateInterestedPerson = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                apellido: lastNameProp,
                cargo: {
                    id: idPositionProp
                },
                creadopor: createdByProp,
                empresa: companyProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: creationDateProp,
                nombre: nameProp,
                numerodocumento: numberProp === "" || numberProp === null ? null : numberProp,
                proyectos: assingedNewProjectProp ? [{
                    estado: 1,
                    proyecto: {
                        id: parseInt(idProjectProp)
                    }
                }] : uniqueProjects,
                id: idInterestedPersonProp,
                tipodocumento: documentProp === '' || documentProp === undefined || !documentProp ?

                    null
                    :
                    {
                        id: documentProp
                    },
                usuario: null
            })

        /* console.log(json) */

        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (individualMonthReportProp === true) {
                    setTimeout(() => {
                        setRunRenderProp(runRenderProp + 1)
                        setRunRenderBlockProp(runRenderBlockProp + 1)
                        setDisplayBasicProp(false)
                        setDisplayConfirmation(false)
                    }, [500])
                } else {
                    window.location.href = "/registroActividades/personasInteresadas"
                }
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sitema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    /* const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/interestedPerson/delete?id=' + idInterestedPerson
    const deleteInterestedPerson = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (individualMonthReportProp === true) {
                    setRunRenderProp(runRenderProp + 1)
                    setRunRenderBlockProp(runRenderBlockProp + 1)
                    window.location.href = "/registroActividades/informeMensualIndividual"
                    setDisplayConfirmation(false)
                } else {
                    window.location.href = "/registroActividades/personasInteresadas"
                }
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    } */

    const actualizarVigenciaUsuario = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                apellido: apellidoProp,
                cargo: {
                    id: idCargoProp
                },
                creadopor: creadoPorProp,
                empresa: empresaProp,
                estado: isDeletedProp ? 3 : 1,
                fechaactualizado: new Date(),
                fechacreacion: fechaCreacionProp,
                nombre: nombreProp,
                numerodocumento: numeroDocumentoProp,
                proyectos: isDeletedProp ? getDataProjectsProp.map(item => {
                    return {
                        ...item,
                        estado: 3,
                        proyecto: {
                            id: item.proyecto.id
                        }
                    }
                }) : sendArrayProjectProp,
                id: idPersonInterestedProp,
                tipodocumento: idTipoDocumentoProp===undefined?null: {
                    id: idTipoDocumentoProp
                },
                usuario: newUsuarioProp
            })

        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    if (newSetDisplayBasicProp) {
                        newSetDisplayBasicProp(false)
                    }
                }, [500]);
                if (individualMonthReportProp === true) {
                    setRunRenderBlockProp(runRenderBlockProp + 1)
                }

            })
            .catch(function (error) {
                if (error?.response?.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response?.data?.mensaje, life: 6000 });
                }
            })
    }

    const renderFooter = (name) => {
        return (
            <div>
                {existingInterestedPerson || noDeleteInterestedPersonProp ?
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    :
                    <>
                        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                    </>
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={existingInterestedPerson ? "Advertencia" : tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content-modal">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>
                                {existingInterestedPerson ?
                                    "Datos ingresados identicos a una persona intertesada existente"
                                    :
                                    menssage
                                }
                            </span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn