import React, { useState, useEffect, useRef } from "react";
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { RadioButton } from "primereact/radiobutton";
import { EstandarFormatoFecha, SumarDias } from "../Funciones";
const BtnHistoricoLiderYContactoCliente = ({ leaderHistoryProp, contactClientHistoryProp, idTeamWorkProp, runRenderProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false)

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
            </div>
        )
    }

    const dt = useRef(null);

    const [selectedMode, setSelectedMode] = useState('leaderHistory');

    const onselectedModeChange = (e) => {
        setSelectedMode(e.value)
    }

    const [newLeaderHistory, setNewLeaderHistory] = useState([]);
    const [newContactClientHistory, setNewContactClientHistory] = useState([]);

    // función para cambiar formato de fecha y validar zona horaria
    const changeDates = (date) => {
        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;
        var newDate = new Date(date)
        var sendDate
        if (gmtHours < 0) {
            sendDate = SumarDias(newDate, +1)
        } else {
            sendDate = newDate
        }
        return sendDate
    }

    useEffect(() => {
        const result = leaderHistoryProp.filter(function (obj) {
            return idTeamWorkProp === obj.equipoTrabajo.id
        })

        const getLeaderHistory = result?.map(item => {
            var newDateStart = null
            var newDateEnd = null
            if (item.fechainicioasignacion !== null) {
                newDateStart = changeDates(item.fechainicioasignacion)
            }
            if (item.fechafinasignacion !== null) {
                newDateEnd = changeDates(item.fechafinasignacion)
            }
            return {
                nombrecompleto: item.colaborador.nombrecompleto,
                fechainicioasignacion: newDateStart !== null ? EstandarFormatoFecha(newDateStart) : null,
                fechafinasignacion: newDateEnd !== null ? EstandarFormatoFecha(newDateEnd) : null
            }
        })
        setNewLeaderHistory(getLeaderHistory)

        const getContactClientHistory = contactClientHistoryProp?.map(item => {
            var newDateStart = null
            var newDateEnd = null
            if (item.fechainicioasignacion !== null) {
                newDateStart = changeDates(item.fechainicioasignacion)
            }
            if (item.fechafinasignacion !== null) {
                newDateEnd = changeDates(item.fechafinasignacion)
            }
            return {
                nombrecompleto: item.personainteresada.nombre + ' ' + item.personainteresada.apellido,
                fechainicioasignacion: newDateStart !== null ? EstandarFormatoFecha(newDateStart) : null,
                fechafinasignacion: newDateEnd !== null ? EstandarFormatoFecha(newDateEnd) : null
            }
        })
        setNewContactClientHistory(getContactClientHistory)

    }, [leaderHistoryProp, contactClientHistoryProp, idTeamWorkProp, displayBasic]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Button tooltip="Histórico Lider y Contacto Cliente" icon="pi pi-history" style={{ marginTop: '5px' }} onClick={() => onClick('displayBasic')}
                className="p-button-secondary btnEditar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            <Dialog className="modal" header="Histórico Lider y Contacto Cliente" visible={displayBasic} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3">
                            <div className="p-field-radiobutton" style={{ marginBottom: '5px' }}>
                                <RadioButton inputId="leaderHistory" name="leaderHistory" value="leaderHistory" onChange={onselectedModeChange} checked={selectedMode === 'leaderHistory'} />
                                <label htmlFor="leaderHistory" style={{ fontWeight: '100' }}>Histórico de Líderes</label>
                            </div>
                            <div className="p-field-radiobutton" style={{ marginTop: '10px' }}>
                                <RadioButton inputId="ContactClientHistory" name="ContactClientHistory" value="ContactClientHistory" onChange={onselectedModeChange} checked={selectedMode === 'ContactClientHistory'} />
                                <label htmlFor="ContactClientHistory" style={{ fontWeight: '100' }}>Histórico de Contactos Clientes</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable ref={dt} value={selectedMode === 'leaderHistory' ? newLeaderHistory : newContactClientHistory} className="p-datatable-responsive-demo" paginator rows={10}
                            emptyMessage={selectedMode === 'leaderHistory' ? "No hay líderes no vigentes" : "No hay contactos clientes no vigentes"}>
                            <Column className="columna" sortable field="nombrecompleto" header={selectedMode === 'leaderHistory' ? "Líder" : "Contacto Cliente"} />
                            <Column className="columna" sortable field="fechainicioasignacion" header="Fecha inicio asignación" />
                            <Column className="columna" sortable field="fechafinasignacion" header="Fecha fin asignación" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default BtnHistoricoLiderYContactoCliente